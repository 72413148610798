<template>

    <v-card class="rounded-lg" :class="isPostAudio ? 'originalAudio black--text' : $vuetify.theme.dark ? 'allOtherAudioDarkTheme' : ''" :style="selectedAudioId === audioObject.audioDataId ? isPostAudio && $vuetify.theme.dark ? 'border: 3px solid var(--v-accent-base);' : 'border: 2px solid var(--v-accent-base);' : ''">
        <!-- @click.stop="(isMobile ? mobileClick() : null)" -->
        <v-row justify="space-around" align="center" no-gutters style="cursor: pointer;" class="d-flex mx-0 pt-2" @click.stop="setAudioTrack(), incrementAudioPlays()">
            <v-col v-if="anonymousMode && !owner" cols="2" align="center" class="my-0 flex-shrink-1">
                <v-avatar size="55" class="hidden-md-and-down">
                    <v-img v-if="isPostAudio" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username" referrerpolicy="no-referrer"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioDataId"></v-img>
                </v-avatar>

                <v-avatar size="50" class="hidden-lg-and-up">
                    <v-img v-if="isPostAudio" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username" referrerpolicy="no-referrer"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioDataId"></v-img>
                </v-avatar>
            </v-col>

            <v-col v-else cols="2" align="center" class="my-0 flex-shrink-1">
                <v-avatar size="55" class="hidden-md-and-down">
                    <v-img v-if="audioObject.audioData.userProfilePhoto != null" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username" referrerpolicy="no-referrer"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>

                <v-avatar size="50" class="hidden-lg-and-up">
                    <v-img v-if="audioObject.audioData.userProfilePhoto != null" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username" referrerpolicy="no-referrer"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>
            </v-col>

            <v-col cols="6" class="my-0 flex-grow-1">
                <v-row v-if="anonymousMode && !owner" dense class="mx-0 ml-1">
                    <span v-if="audioObject.audioData.userId == userData.id" class="text-truncate text-subtitle-1 cursor--pointer" @click="$router.push({ path: 'profile'})">@{{audioObject.audioData.username}}</span>
                    <span v-else-if="isPostAudio" class="text-truncate text-subtitle-1 cursor--pointer" @click="$router.push({ path: 'view-user-profile', query: {id: audioObject.audioData.userId} })">@{{audioObject.audioData.username}}</span>
                    <span v-else class="text-truncate text-subtitle-2 cursor--pointer" @click="copyId()">@{{audioObject.audioDataId}}</span>
                </v-row>

                <v-row v-else dense class="mx-0 ml-1">
                    <span v-if="audioObject.audioData.userId == userData.id" class="text-truncate text-subtitle-1 cursor--pointer" @click="$router.push({ path: 'profile'})">@{{audioObject.audioData.username}}</span>
                    <span v-else class="text-truncate text-subtitle-1 cursor--pointer" @click="$router.push({ path: 'view-user-profile', query: {id: audioObject.audioData.userId} })">@{{audioObject.audioData.username}}</span>
                </v-row>

                <v-row dense class="mx-0 ml-1">
                    <span class="font-weight-light font-italic date" v-bind:class="[ isPostAudio ? 'black--text' : $vuetify.theme.dark ? 'inverse--text' : 'inverse--text']">{{audioObject.audioData.date.toDate().toDateString().slice(4)}}</span>
                </v-row>
                <v-row dense class="mx-0 ml-1">
                    <div class="font-weight-light text-truncate language">{{audioObject.audioData.languageName}}</div>
                </v-row>
            </v-col>

            <v-col cols="3" align="end" align-self="start" class="my-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <!-- The loading prop for v-btn below can be added to both play buttons below to show which AudioElement is be loaded into video/audio player(s) -->
                        <!-- :loading="videoLoading && (selectedAudioId === audioObject.audioDataId)" -->
                        <!-- :disabled="videoLoading && (selectedAudioId != audioObject.audioDataId)" -->
                        <v-btn v-on="on" color="accent" icon small outlined :loading="videoLoading && (selectedAudioId === audioObject.audioDataId)" :disabled="!isCreating && videoLoading && (selectedAudioId != audioObject.audioDataId)" @click.native.stop="setAudioTrack(), incrementAudioPlays()" class="hidden-md-and-down">
                            <v-icon v-if="selectedAudioId === audioObject.audioDataId && audioElementHasBegunPlaying" medium>mdi-restart</v-icon>
                            <v-icon v-else medium>mdi-play</v-icon>
                        </v-btn>
                        <v-btn v-on="on" color="accent" icon small outlined :loading="videoLoading && (selectedAudioId === audioObject.audioDataId)" :disabled="!isCreating && videoLoading && (selectedAudioId != audioObject.audioDataId)" @click.native.stop="setAudioTrack(), incrementAudioPlays()" class="hidden-lg-and-up">
                            <v-icon v-if="selectedAudioId === audioObject.audioDataId && audioElementHasBegunPlaying">mdi-restart</v-icon>
                            <v-icon v-else>mdi-play</v-icon>
                        </v-btn>
                    </template>
                    <span>Play Audio</span>
                </v-tooltip>
                <v-menu v-model="menu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" close-on-content-click>
                    <template v-slot:activator="{ on }">
                        <v-btn :ripple="false" v-on="on" color="accent" small icon @click.stop="menu = !menu">
                            <v-icon medium>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded>
                        <v-list-item @click.stop="reportDialog = true, menu = !menu"><v-icon class="pr-2">mdi-flag</v-icon>Report</v-list-item>
                        <v-list-item v-if="editTitleDownloadVttAndAddOrRemoveEditors && onRouteOwnerCanEditFrom && !isPostAudio" @click.stop="$store.dispatch('downloadVttFile', {post: null, audio: audioObject}), menu = !menu"><v-icon class="pr-2">mdi-file-download</v-icon>Download VTT</v-list-item>
                        <v-list-item v-if="editTitleDownloadVttAndAddOrRemoveEditors && owner && onRouteOwnerCanEditFrom && !isPostAudio" @click.stop="(editState = true, isInEditState = true, menu = !menu)"><v-icon class="pr-2">mdi-pencil</v-icon>Edit Title</v-list-item>
                        <v-list-item v-if="editor && onRouteOwnerCanEditFrom && !isPostAudio" @click.stop="(editState = true, isInEditState = true, menu = !menu)"><v-icon class="pr-2">mdi-pencil</v-icon>Edit Title</v-list-item>
                        <v-list-item v-if="editTitleDownloadVttAndAddOrRemoveEditors && owner && onRouteOwnerCanEditFrom && !isPostAudio" @click.stop="addOrRemoveEditorDialog = true, menu = !menu"><v-icon class="pr-2">mdi-human-edit</v-icon>Add or Remove Editor</v-list-item>
                        <v-list-item v-if="owner && onRouteOwnerCanEditFrom && !isPostAudio" @click.stop="$emit('delete-selected-contribution', audioObject), menu = !menu"><v-icon class="pr-2">mdi-delete</v-icon>Delete Contribution</v-list-item>
                    </v-list>
                </v-menu>
                <Report v-if="reportDialog" :dialog="reportDialog" page="audioElement" :post="audioObject" @close-report="reportDialog = false"/>
                <AddOrRemoveEditorDialog v-if="addOrRemoveEditorDialog" :dialog="addOrRemoveEditorDialog" :audioObject="audioObject" :postData="postData" :addingFailedList="friendsBeingAdded" :removingFailedList="friendsBeingRemoved" @addEditor="addEditor" @removeEditor="removeEditor" @removeFromFailedList="removeFromFailedList" @closeDialog="addOrRemoveEditorDialog = false"/>
            </v-col>
        </v-row>

        <v-row justify="space-around" align="center" no-gutters style="cursor: pointer;" class="mx-0 py-1" @click.stop="!isInEditState ? (show = !show) : null">
            <v-col cols="1" align="center" class="my-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="hidden-sm-and-down" icon v-on="on" small>
                            <!-- removed by Scott Brady on May 22, 2024 to allow for clicking of the entire row to show AudioSegments -->
                            <!-- @click="show = !show" -->
                            <v-icon class="grey--text text--darken-1" large>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn>
                        <v-btn class="hidden-md-and-up" icon small>
                            <!-- removed by Scott Brady on May 22, 2024 to allow for clicking of the entire row to show AudioSegments -->
                            <!-- @click="show = !show" -->
                            <v-icon class="grey--text text--darken-1" large>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn>
                    </template>
                    <span justify="center">Show Audio Segments</span>
                </v-tooltip>
            </v-col>
            <v-col cols="10" align="start" class="my-0 text-truncate">
                <span v-if="!editState" class="text-h6 font-weight-bold ml-2">{{audioObject.audioData.title}}</span>
                <v-text-field v-else v-model="audioObject.audioData.title" :rules="titleRules" :counter="50" hide-details outlined no-resize auto-grow append-outer-icon="mdi-content-save-edit" @click:append-outer="emitSaveEditContributionTitle(audioObject)" @change="hasBeenEdited = true" @keydown.enter="emitSaveEditContributionTitle(audioObject)"></v-text-field>
            </v-col>
            <v-expand-transition>
                <v-container v-show="show" fluid>
                    <v-divider :light="isPostAudio" :dark="!isPostAudio && $vuetify.theme.dark"/>
                    <AudioSegment
                        v-for="(obj, index) in audioObject.audioData.audio"
                        :key="index"
                        :index="index"
                        :audioSeg="obj"
                        :caption="audioObject.audioData.subtitles[index]"
                        :audioUserId="audioObject.audioData.userId"
                        :onRouteOwnerCanEditFrom="onRouteOwnerCanEditFrom"
                        :isGroupAdmin="isGroupAdmin"
                        :postType="postType"
                        :isPostAudioData="isPostAudio"
                        :editor="editor"
                        :videoLoading="videoLoading"
                        @setIsInEditState="setIsInEditState"
                        @setAudioSegment="emitSegment"
                        @edit-caption="emitEditCaption"
                    />
                </v-container>
            </v-expand-transition>
        </v-row>

    </v-card>
</template>

<script>
import fb from "firebase/compat/app";
import firebase from "../../firebaseConfig.js";
import AudioSegment from "./AudioSegment.vue";
import Report from "./Report.vue";
import AddOrRemoveEditorDialog from "./AddOrRemoveEditorDialog.vue";

export default {
    name: 'AudioElement',
    props: ["audioObject", "postAudioDataId", "postOwner", "userData", "selectedAudioId", "isGroupAdmin", "postType", "postData", "videoLoading", "audioElementHasBegunPlaying", "anonymousMode", "isCreating"],
    components: {
        AudioSegment,
        Report,
        AddOrRemoveEditorDialog,
    },
    data: function() {
        return {
            reportDialog: false,
            addOrRemoveEditorDialog: false,
            menu:false,
            show: false,
            owner: false,
            editor: false,
            friendsBeingAdded: [],
            friendsBeingRemoved: [],
            titleRules: [(v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be 50 characters or less",],
            editTitleDownloadVttAndAddOrRemoveEditors: false,
            editState: false,
            hasBeenEdited: false,
            isPostAudio: false,
            isInEditState: false,
            // localDownVote: 0,
            // localUpVote: 0,
        };
    },
    methods: {
        // All up or down voted code was commented out on August 1, 2023: this can be used to add up/down voting on AudioElements
        // changeUserUpVote () {
        //     this.localUpVote === 1 ? this.localUpVote = 0 : this.localUpVote = 1;
        //     if (this.localDownVote === 1) this.localDownVote = 0;
        //     this.$store.dispatch('userUpVotedContribution', { userId: firebase.auth.currentUser.uid, audioDataId: this.audioObject.audioDataId, alreadyUpVoted: this.userUpVoted, alreadyDownVoted: this.userDownVoted  } );
        // },
        // changeUserDownVote(){
        //     this.localDownVote === 1 ? this.localDownVote = 0 : this.localDownVote = 1;
        //     if (this.localUpVote === 1) this.localUpVote = 0;
        //     this.$store.dispatch('userDownVotedContribution', { userId: firebase.auth.currentUser.uid, audioDataId: this.audioObject.audioDataId, alreadyUpVoted: this.userUpVoted, alreadyDownVoted: this.userDownVoted } );
        // },
        mobileClick: function () {
            // This function can be potentially used so that a user can click on an AudioElement to play it.
            // This would make is so a user doesn't just have to click on the play button.
            // There is a lot of work that would need to be done for this to work properly, including...
            // 1. making sure this doesn't get called when a user clicks on cheveron down to expand view, or any other buttons on the AudioElement,
            // 2. what happens when a user clicks on the AudioElement multiple times,
            console.log("mobileClick");
        },
        setAudioTrack: function() {
            this.$emit("audioChanged", this.audioObject.audioData, this.audioObject.audioDataId);
        },
        copyId: function () {
            this.$navigator.clipboard.writeText(this.audioObject.audioDataId);
            this.$store.commit('alertUser', { show: true, text: `Copied ${this.audioObject.audioDataId} to Clipboard.`, type: 'snackbar' });
        },
        incrementAudioPlays: function() {
            if (process.env.NODE_ENV === 'production'){
                const segRef = firebase.db.collection("audioSegments").doc(this.audioObject.audioDataId)
                segRef.update({audioViewed: fb.firestore.FieldValue.increment(1),}).catch((error) => {
                    console.error("error incrementing the postViewed:", error.message);
                })
            }
        },
        addEditor: async function (friend) {
            if (this.audioObject.audioData.editors) {
                this.audioObject.audioData.editors.push(friend.userId);
            } else {
                this.audioObject.audioData.editors = [];
                this.audioObject.audioData.editors.push(friend.userId);
            }

            await this.$http.put(`editor/${friend.userId}/${firebase.auth.currentUser.uid}`, {data: {postId: this.audioObject.audioData.postId, audioDataId: this.audioObject.audioDataId, postType: this.postType}}).then((response) => {
                if (response.status === 200) {
                    friend.loading = false;
                    this.$store.commit('alertUser', { show: true, text: "Friend added as editor.", type: 'snackbar' });
                } else {
                    const index = this.audioObject.audioData.editors.indexOf(friend.userId);
                    this.audioObject.audioData.editors.splice(index, 1);
                    friend.loading = false;
                    this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `${error.message}. Please try again!`, type: 'snackbar' });
                this.friendsBeingAdded.push(friend);
                const index = this.audioObject.audioData.editors.indexOf(friend.userId);
                this.audioObject.audioData.editors.splice(index, 1);
                friend.loading = false;
            });
        },
        removeEditor: async function (friend) {
            const index = this.audioObject.audioData.editors.indexOf(friend.userId);
            this.audioObject.audioData.editors.splice(index, 1);

            await this.$http.delete(`editor/${friend.userId}/${firebase.auth.currentUser.uid}`, {headers: {postId: this.audioObject.audioData.postId, audioDataId: this.audioObject.audioDataId, postType: this.postType}}).then((response) => {
                if (response.status === 200) {
                    friend.loading = false;
                    friend.editor = false;
                    this.$store.commit('alertUser', { show: true, text: "Friend removed as editor.", type: 'snackbar' });
                } else {
                    friend.loading = false;
                    friend.editor = true;
                    this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `${error.message}. Please try again!`, type: 'snackbar' });
                this.friendsBeingRemoved.push(friend);
                this.audioObject.audioData.editors.unshift(friend.userId);
                friend.loading = false;
            });
        },
        removeFromFailedList: function (val) {
            if (val === 'add') {
                this.friendsBeingAdded.shift();
            } else {
                // val === 'remove'
                this.friendsBeingRemoved.shift();
            }
        },
        canEditTitleDownloadVttAndAddOrRemoveEditors: function() {
            if (( this.audioObject.audioData.userId == firebase.auth.currentUser.uid /*|| firebase.auth.currentUser.uid  == this.postOwner*/ ) && !this.isPostAudio) {
                this.editTitleDownloadVttAndAddOrRemoveEditors = true;
            }
        },
        isEditor: function () {
            if (this.audioObject.audioData.editors && !this.isPostAudio) {
                if (this.audioObject.audioData.editors.includes(firebase.auth.currentUser.uid)) this.editor = true;
            }
        },
        isOwner: function () {
            if ((this.audioObject.audioData.userId == firebase.auth.currentUser.uid || firebase.auth.currentUser.uid  == this.postOwner ) && !this.isPostAudio) this.owner = true;
        },
        isPostAudioData: function() {
            // is the original post audio
            this.isPostAudio = (this.audioObject.audioDataId == this.postAudioDataId) ? true : false;
        },
        emitSegment: function(audioSeg) {
            audioSeg.username = this.audioObject.audioData.username;
            this.$emit("changeAudioElement", { audioData: this.audioObject.audioData, audioSeg: audioSeg, audioId: this.audioObject.audioDataId});
        },
        emitEditCaption: function(caption) {
            this.$emit("edit-caption", ({caption: caption, audioSegId: this.audioObject.audioDataId}));
        },
        emitSaveEditContributionTitle: function (audioObject) {
            // Send the audioObject to the parent component (ViewPost), so that we know not only the new title
            // but also the audioDataId so we know which audioSegment to update in the firestore database.
            this.isInEditState = false;
            if (this.hasBeenEdited) {
                this.editState = false;
                this.$emit("saveEditContributionTitle", ({audioObject: audioObject}));
            } else {
                this.editState = false;
            }
        },
        setIsInEditState: function (value) {
            this.isInEditState = value;
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        // userUpVoted(){
        //     if ( typeof this.$store.state.userData.contributionUpVotes === 'undefined' ) return 0;
        //     return this.$store.state.userData.contributionUpVotes.indexOf(this.audioObject.audioDataId) === -1 ? 0 : 1;
        // },
        // userDownVoted(){
        //     if ( typeof this.$store.state.userData.contributionDownVotes === 'undefined' ) return 0;
        //     return this.$store.state.userData.contributionDownVotes.indexOf(this.audioObject.audioDataId) === -1 ? 0 : 1;
        // },
        onRouteOwnerCanEditFrom(){
            return this.$route.name === 'new-contribution' ? false : true;
        }
    },
    mounted: function() {
        this.canEditTitleDownloadVttAndAddOrRemoveEditors();
        this.isEditor();
        this.isOwner();
    },
    created: function() {
        this.isPostAudioData();
    },
    beforeDestroy(){},
};
</script>

<style>
.originalAudio {
    background-color: #e3e3e3 !important;
    /* background-color: green !important; */
    /* border: 2px solid var(--v-accent); */
    /* background-color: #bdbdbd !important; */
    /* background-color: #424242 !important; */
}
.allOtherAudioDarkTheme {
    background-color: #424242 !important;
    /* background-color: green !important; */
}
.selectedAudioId {
    border: 2px solid var(--v-accent);
}
.date {
  font-size: .60em;
}
.language {
  font-size: .70em;
}
</style>