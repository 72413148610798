<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="dismissCancelFriendRequest">
        <v-card class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Are you sure you want to cancel request to {{ friend.username }}</span>
                <!-- <p class="text-wrap text-h5 font-weight-bold">Are you sure you want to cancel request to <span class="font-weight-light">{{ friend.username }}</span></p> -->
            </v-card-title>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click.stop="dismissCancelFriendRequest">Close</v-btn>
                    <v-btn color="primary" text @click.stop="cancelFriendRequest">Cancel Request</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import firebase from "../../../firebaseConfig.js";


export default {
    name: "cancel-friend",
    props: ["dialog", "friend"],
    data() {
        return {};
    },
    methods: {
        dismissCancelFriendRequest: function () {
            this.$emit("dismissCancelFriendRequest");
        },
        cancelFriendRequest: function () {
            this.$emit("cancelFriendRequest");
        },
    },
};
</script>