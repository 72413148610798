<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="6" lg="6" md="8" sm="8">

            <v-row justify="center" no-gutters>
                <span v-if="youtubeUrlSelected" class="text-h6 text-sm-h5 text-center">Paste in a YouTube Url</span>
                <span v-else-if="fileSelected" class="text-h6 text-sm-h5 text-center">Select an Audio or Video File
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" class="text-decoration-none">mdi-information-outline</v-icon>
                        </template>
                        <span>Some audio files are NOT supported in every browser.<br/>If the file you are trying to use cannot be selected,<br/>try using a different browser.</span>
                    </v-tooltip>
                </span>
                <span v-else class="text-h6 text-sm-h5 text-center">Select <span class="font-weight-black text-decoration-underline">Url</span> to use a YouTube Url or <span class="font-weight-black text-decoration-underline">File</span> to upload from your device</span>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="mt-8">
                <v-hover v-slot="{hover}">
                    <div class="d-flex flex-column justify-end align-center cursor--pointer" @click="createPost.transcoded ? [fileSelected = true, youtubeUrlSelected = false] : [fileSelected = false, youtubeUrlSelected = true]">
                        <v-icon size="100" class="red--text" :class="[ hover ? 'text--lighten-2' : youtubeUrlSelected ? 'text--lighten-3' : fileSelected ? 'opacity--low' : 'red--text']">mdi-youtube</v-icon>
                        <div class="text-subtitle-1 text-center">Url</div>
                    </div>
                </v-hover>

                <v-hover v-slot="{hover}">
                    <div class="d-flex flex-column justify-end align-center ml-8 cursor--pointer" @click="fileSelected = true, youtubeUrlSelected = false">
                        <v-icon size="100" class="primary--text" :class="[ hover ? 'text--lighten-1' : fileSelected ? 'text--lighten-2' : youtubeUrlSelected ? 'opacity--low' : 'primary--text']">mdi-file-upload</v-icon>
                        <div class="text-subtitle-1 text-center">File</div>
                    </div>
                </v-hover>
            </v-row>

            <v-form v-model="isFormValid">
                <v-row v-if="fileSelected || youtubeUrlSelected" justify="center" no-gutters class="mt-4">
                    <transition enter-active-class="slideDown" leave-active-class="slideUp" appear>
                        <v-col cols="12" xl="8" lg="8" md="10" sm="10">

                            <div v-if="userNeedsToReselectVideo" class="text-subtitle-2 accent--text mb-4">*Select '{{draftPost.draftVideo.name}}' for optimal results.</div>
                            <div v-if="showAlertText" class="text-subtitle-2 accent--text mb-4">{{alertText}}</div>

                            <transition enter-active-class="slideRight">
                                <v-file-input
                                    v-if="fileSelected"
                                    v-show="!youtubeUrlSelected"
                                    v-model="video"
                                    :rules="videoRules"
                                    :show-size="true"
                                    id="input"
                                    accept="audio/mp3,audio/aac,audio/wav,audio/ogg,audio/m4a,audio/x-m4a,audio/webm,audio/mp4,audio/mpeg,audio/flac,video/*"
                                    flat
                                    outlined
                                    clearable
                                    prepend-icon=""
                                    prepend-inner-icon="$file"
                                    required
                                    label="Select an Audio or Video File"
                                    :error="videoCodecCompatible === false || audioFileError"
                                    :loading="videoCodecCheckLoading"
                                    :disabled="createPost.transcoded"
                                    @change="fileChanged()"
                                ></v-file-input>

                                <v-text-field
                                    v-if="youtubeUrlSelected"
                                    v-show="!fileSelected"
                                    v-model="videoUrl"
                                    :rules="urlRules"
                                    clearable
                                    flat
                                    outlined
                                    label="Paste in your YouTube video URL"
                                    @change="urlChanged()"
                                ></v-text-field>
                            </transition>

                            <v-autocomplete
                                v-model="languageSelected"
                                :label="!hasAudio && !hasCaptions ? 'Select a language (required)' : 'Delete your tawq segments to change language.'"
                                :items="languageCodes"
                                item-text="text"
                                item-value="value"
                                return-object
                                outlined
                                persistent-hint
                                :disabled="hasAudio || hasCaptions"
                                :hint="languageSelected.value === 'null' ? '' : '*This will be the langauge you speak while recording'"
                            ></v-autocomplete>

                            <v-text-field
                                v-if="languageSelected.value === 'null'"
                                v-model="noCaptionLanguageSelected"
                                outlined
                                :label="!hasAudio && !hasCaptions ? 'Type in the language you will speak' : 'Delete your tawq segments to change language.'"
                                :disabled="hasAudio || hasCaptions"
                            ></v-text-field>

                        </v-col>
                    </transition>
                </v-row>
            </v-form>

        </v-col> 
    </v-row>
</template>
<script>

// import axios from "axios";
import mp4box from "mp4box"
// import LanguageDialog from "./LanguageDialog.vue"

export default {
    name: 'PostContentMediaSelection',
    props: [ "isDraftPost", "draftPost", "languageCodes",],
    // components: {
    //     LanguageDialog,
    // },
    data(){
        return{
            isFormValid: true,
            fileSelected: false,
            youtubeUrlSelected: false,
            // RULES
            // videoFileLimit: 5000000000, // 5GB
            videoFileLimit: 2000000000, // 2GB
            // videoFileLimit: 1000000000, // 1GB
            // videoFileLimit: 500000000, // 500MB
            // videoFileLimit: 100000000, // 100MB
            // videoFileLimit: 50000000, // 50MB
            // videoFileLimit: 5000000, // 5MB
            urlRules: [
                (v) => {
                    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                    const youtubeShortsRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)(?:\S+)?$/;
                    return youtubeRegex.test(v) || youtubeShortsRegex.test(v) || "Must be a valid YouTube URL or YouTube Shorts URL";
                },
            ],
            // urlRules: [
            //     (v) => /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(v) || "Must be valid YouTube URL",
            // ],
            videoRules: [
                (v) => !!v || "Must select a file",
                (value) =>
                !value ||
                value.size < this.videoFileLimit ||
                "Video size must be under 5GB",
            ],
            // videoSelectionRules: [(v) => !!v || "Must select either upload video or paste in a url"],
            userNeedsToReselectVideo: false,
            showAlertText: false,
            alertText: "",
            videoCodecCheckLoading: false,
            audioFileError: false,
            // hasBeenValidated: false,
            showLanguageDialog: false,
        }
    },
    methods: {
        showOrCloseLanguageDialog: function () {
            // This function is called when the 'i' icon on the None (Disabled Auto Captions) select the language you will speak v-autocomplete is clicked
            this.showLanguageDialog = true;
        },
        checkForMediaSelectionDifferences: function () {
            this.alertText = "";
            this.showAlertText = false;
            // console.log("checkForMediaSelectionDifferences");
            if ( typeof this.video == 'undefined' || typeof this.videoUrl === 'undefined' && !isDraftPost ){ // if they clear the input
                // console.log("cleared the input and fell into the undefined area...")
                return;
            }
            // if the draft was a youtube video, make sure it isn't a file now and make sure it is the same url
            if ( this.draftPost.draftVideo.type === 'video/youtube' ){
                if(this.youtubeUrlSelected){
                    if(this.videoUrl != this.draftPost.draftVideo.url && (this.hasCaptions || this.hasAudio) ){
                        this.alertText = `*You recorded audio segments for a different YouTube video than the url below. Use the same video for optimal results.`;
                        this.showAlertText = true;
                    }
                }
                else if (this.fileSelected && (this.hasCaptions || this.hasAudio)){ // they now have a file selected
                    this.alertText = `*You recorded audio segments for a YouTube video with this post, and have now selected a file. Use the same YouTube video for optimal results.`;
                    this.showAlertText = true;
                }
            }
            // if the draft was a file, make sure it isn't a youtubue video and make sure it is the same file
            else if ( this.draftPost.draftVideo.type === 'video/mp4' ){
                // console.log("video", this.video);
                // console.log("createPost.video", this.createPost.video);
                // if they have file selected and it isn't the same video and the draft has captions already recorded...
                if( (this.fileSelected && (this.draftPost.draftVideo.name != this.video.name && this.draftPost.draftVideo.size != this.video.size) ) && (this.hasCaptions || this.hasAudio) ){
                    this.alertText = `*You recorded audio segments for '${this.draftPost.draftVideo.name}' which is different than the selected video. Use the same file for optimal results.`;
                    this.showAlertText = true;
                    this.userNeedsToReselectVideo = false;
                }
                else if (this.youtubeUrlSelected && (this.hasCaptions || this.hasAudio) ){
                    this.alertText = `*You recorded audio segments for '${this.draftPost.draftVideo.name}' and now are choosing a YouTube Video. Use the same file for optimal results.`;
                    this.showAlertText = true;
                    this.userNeedsToReselectVideo = false;
                }
            } else {
                if( (this.fileSelected && (this.draftPost.draftVideo.name != this.video.name && this.draftPost.draftVideo.size != this.video.size) ) && (this.hasCaptions || this.hasAudio) ){
                    this.alertText = `*You recorded audio segments for '${this.draftPost.draftVideo.name}' which is different than the selected audio. Use the same file for optimal results.`;
                    this.showAlertText = true;
                    this.userNeedsToReselectVideo = false;
                }
                else if (this.youtubeUrlSelected && (this.hasCaptions || this.hasAudio) ){
                    this.alertText = `*You recorded audio segments for '${this.draftPost.draftVideo.name}' and now are choosing a YouTube Video. Use the same file for optimal results.`;
                    this.showAlertText = true;
                    this.userNeedsToReselectVideo = false;
                }
            }
        },
        urlChanged: function () {
            if (this.isDraftPost) {
                this.checkForMediaSelectionDifferences();
            }
            if (this.videoUrl != null) {
                var youtuBeRegex = /https:\/\/youtu\.be\/[^?]+/;
                var match = this.videoUrl.match(youtuBeRegex);
                if (match) {
                    const urlSplit = this.videoUrl.split('?')[0];
                    this.videoUrl = urlSplit;
                }
                this.videoUrl = this.modifyYoutubeShortsUrl(this.videoUrl);
            }
            this.createPost.video = {}; // reset the .video var because it only holds data for files. if they clicked a file first we want to reset that
            return;
        },
        modifyYoutubeShortsUrl(url) {
            const shortsPattern = /https:\/\/(www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;
            const match = url.match(shortsPattern);

            if (match && match[2]) {
                return `https://www.youtube.com/embed/${match[2]}`;
            }
            return url;
        },
        fileChanged: function () {
            if (typeof this.createPost.video === 'undefined' || this.createPost.video === null) {
                this.videoCodecCompatible = null;
                this.createPost.videoUrl = "";
            }
            if (this.isDraftPost) {
                if (!this.createPost.transcoded) {
                    this.checkForMediaSelectionDifferences();
                }
                if (this.createPost.video) {
                    if (this.createPost.video.name === this.draftPost.draftVideo.name) {
                        this.userNeedsToReselectVideo = false;
                    }
                } else {
                    this.userNeedsToReselectVideo = true;
                }
            }
            if (this.createPost.video) {
                // console.log("file changed: ");//, this.createPost.video);
                this.videoCodecCompatible = null;
                this.createPost.videoUrl = "";
                let lastIndex = this.createPost.video.name.lastIndexOf(".");
                let filename = this.createPost.video.name.substring(0,lastIndex);
                let ext = this.createPost.video.name.substring(lastIndex+1);
                // console.log("ext: ", ext);
                if (ext == "mov" || ext == "MOV") {
                    // console.log("MOV");
                    // if the video is a mov (taken on a iPhone) then convert it to mp4
                    // THIS IS NOT WORKING ANYMORE AND IT MIGHT REQUIRE US TO HAVE TO CONVERT IN A DIFFERENT WAY!!!
                    // ^^^ Working as of December 30, 2022. Not removing above comment so I will check later and make sure
                    // this doesn't become an issue.
                    filename = filename + ".mp4";
                    let blob = this.createPost.video.slice(0,this.createPost.video.size,"video/mp4");
                    let newFile = new File([blob], filename, { type: "video/mp4" });
                    this.createPost.video = newFile;
                    this.videoCodecCompatible = true;
                } else if (ext == "mp4" || ext == "MP4") {
                    // console.log("mp4");
                    // NEEDS TO HAVE A CHECK TO SEE IF THE MIME IS video/mp4. IF SO THEN IT NEEDS TO RUN THE validateVideo()
                    // FUNCTION TO SEE IF IT IS AN AUDIO FILE AND CONVERT IT TO audio/mp4 SO THE VideoPlayer WILL DISPLAY
                    // THE AUDIO VISUALIZER CORRECTLY.
                    if (this.audioFileError) {
                        // console.log(this.audioFileError);
                        this.createPost.video = null;
                        this.audioFileError = false;
                    } else {
                        this.videoCodecCompatible = true;
                    }
                } else {
                    this.validateVideo();
                }
            }
        },
        validateVideo: async function () {
            // console.log("validateVideo");
            this.audioFileError = false;
            // Check to make sure there is a file.
            if (this.createPost.video && this.videoCodecCompatible === null) {
                // Start the local loading process on the file select while we check the video file for compatiblity
                this.videoCodecCheckLoading = true;

                if (this.createPost.video.size < this.videoFileLimit) {
                    // if the video size is within the file limit
                    // console.log("createPost.video: ", this.createPost.video);
                    let mimeType = this.createPost.video.type;//.split("/")[0];
                    // console.log("mimeType: ", this.createPost.video.type);


                    // Not Supported Chrome: audio/x-m4a,
                    // Audio file types:
                    // .m4a - audio/x-m4a (Apple Audio File); ***alac codec only works on Safari, mp4a.40.2 works on other browsers***
                    // .flac - audio/flac (Free Lossless Audio Codec); ***Not ideal to use. Requires special downloads***
                    // .acc - audio/aac (AAC Audio)
                    // .mid .midi - audio/midi audio/x-midi (Musical Instrument Digital Interface MIDI)
                    // .mp3 - audio/mpeg (MP3 Audio)
                    // .mp4 - video/mp4 audio/mp4 (MP4 Video/Audio) ***This can be used for audio as well and is most likely supported by all browsers*** This is important to note as MP4 files require different codecs to implement the code artificially and allow it to be read.
                    // .ogg .oga .ogv - audio/ogg video/ogg (OGG Audio/Video) ***The OGG Video is only called in Firefox every other browser uses OGG Audio. Safari DOES NOT allow OGG files***
                    // ^^^ NOT WORKING WITH CHROME AND SAFARI IN IOS!!!
                    // .opus - audio/opus (Opus Audio)
                    // .wav - audio/wav (Waveform Audio Format)
                    // .webm - audio/webm (WEBM Audio)
                    // .3gp - video/3gpp audio/3gpp if it doesn't contain video (3GPP audio/video container)
                    // .3g2 - video/3gpp2 audio/3gpp2 if it doesn't contain video (3GPP2 audio/video container)



                    let lastIndex = this.createPost.video.name.lastIndexOf(".");
                    let filename = this.createPost.video.name.substring(0,lastIndex);
                    let ext = this.createPost.video.name.substring(lastIndex+1);
                    let newAudioFilename = this.createPost.video.name;
                    let mp4Blob;
                    let newMp4AudioFile;

                    if (mimeType != "audio/x-m4a" && mimeType.split("/")[0] === "audio") {
                        // console.log("audio/");
                        if (ext != 'mp4') {
                            // console.log("ext is NOT mp4");
                            // console.log("filename BEFORE: ", newAudioFilename);
                            newAudioFilename = filename + ".mp4";
                            // filename += '.mp4';
                            // 
                            mp4Blob = this.createPost.video.slice(0,this.createPost.video.size,mimeType);
                            // 
                            newMp4AudioFile = new File([mp4Blob], newAudioFilename, { type: mimeType });
                            this.createPost.video = newMp4AudioFile;
                            // console.log("filename AFTER: ", newAudioFilename);
                            // console.log("createPost.video: ", this.createPost.video);
                        }
                        this.videoCodecCompatible = true;
                    } else {
                        // console.log("audio/x-m4a or video file");
                        if (ext == "mov" || ext == "MOV") {
                            // console.log("MOV");
                            // if the video is a mov (taken on a iPhone) then convert it to mp4
                            // THIS IS NOT WORKING ANYMORE AND IT MIGHT REQUIRE US TO HAVE TO CONVERT IN A DIFFERENT WAY!!!!!!!
                            filename = filename + ".mp4";
                            let blob = this.createPost.video.slice(0,this.createPost.video.size,"video/mp4");
                            let newFile = new File([blob], filename, { type: "video/mp4" });
                            this.createPost.video = newFile;
                            this.videoCodecCompatible = true;
                            this.videoCodecCheckLoading = false;
                            return;
                        }

                        // check the video file codec to make sure it will display properly.
                        // if the codec is not correct warn the user that we need to process
                        // the video more for it to show correctly and could take several minutes or more!
                        // console.log("Starting MP4BOX");
                        const buffer = await this.readFile(this.createPost.video);
                        // console.log("buffer: ", buffer);
                        buffer.fileStart = 0;
                        var mp4boxfile = mp4box.createFile();
                        // console.log("mp4boxfile: ", mp4boxfile);
                        mp4boxfile.onError = function(e) {
                            // if there is an error then set videoCodecCheckLoading to false and notify user of error
                            // console.log("We Have an ERROR!!!!!!");
                            console.log("Error: ", e);
                            this.videoCodecCheckLoading = false;
                        };
                        mp4boxfile.onMoovStart = function () {
                            // console.log("Starting to receive File Information");
                        }
                        // console.log("Should go into onReady!");
                        mp4boxfile.onReady = (info) => {

                            // console.log('Info: ', info);

                            if (info.videoTracks.length > 0/*typeof info.videoTracks[0] != 'undefined'*/) {
                                // console.log("Video File");
                                var codec = info.videoTracks[0].codec.substring(0,info.videoTracks[0].codec.indexOf('.'));
                                if (codec === "avc1") {
                                    // console.log("compatible");
                                    // video codec is compatible, set this.videoCodecCompatible to true
                                    this.videoCodecCompatible = true;
                                } else {
                                    // console.log("NOT compatible, do the thing!");
                                    // codec === "hvc1"
                                    // else video NOT compatible set this.videoCodecCompatible to false
                                    this.videoCodecCompatible = false;
                                }
                            } else {
                                // console.log("Audio File");
                                if (ext != 'mp4') {
                                    // console.log("NOT mp4, setting now");
                                    newAudioFilename = filename + ".mp4";
                                    mp4Blob = this.createPost.video.slice(0,this.createPost.video.size,mimeType);
                                    newMp4AudioFile = new File([mp4Blob], newAudioFilename, { type: mimeType });
                                    this.createPost.video = newMp4AudioFile;
                                }

                                if (mimeType === "video/mp4") {
                                    // console.log("convert mime from video to audio so it displays the audio visualizer in VideoPlayer.vue");
                                    let blobby = this.createPost.video.slice(0,this.createPost.video.size,"audio/mp4");
                                    let newAudioFile = new File([blobby], this.createPost.video.name, {type: "audio/mp4"});
                                    this.createPost.video = newAudioFile;
                                    this.videoCodecCompatible = true;
                                } else if (info.tracks[0].codec === "alac") {
                                    // console.log("audio is not compatible");
                                    // Currently this cannot be used on chrome, microsoft edge and firefox, but can be used in safari. No way of transcoding or converting so far.
                                    // Add the capability to detect the browser so this code won't skip when using safari
                                    // this.videoCodecCompatible = true;
                                    this.videoCodecCompatible = null;
                                    this.audioFileError = true;
                                    // Put file input into error state!
                                    // Also consider using browser detection (NOT SMART TO USE UNLESS ABSOLUTELY NECESSARY) https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
                                    this.$store.commit('alertUser', { show: true, text: "Audio File not supported in this browser", type: 'snackbar' });
                                } else {
                                    // console.log("audio is already compatible");
                                    this.videoCodecCompatible = true;
                                }
                            }
                            this.videoCodecCheckLoading = false;
                        };
                        // console.log("Appending file buffer to mp4boxfile!");
                        mp4boxfile.appendBuffer(buffer);
                        // console.log("Done appending file buffer to mp4boxfile!");
                        if (mimeType === "video/ogg" || mimeType === "video/mpeg") {
                            // console.log("video/ogg || video/mpeg");
                            if (mimeType === "video/ogg") {
                                // console.log("video/ogg");
                                // for some reason firefox reads in the ogg audio file as a video file but it isn't recognized as a video file for mp4boxfile,
                                // so we have to put this check here to change the mime type so it displays the audio visualizer in VideoPlayer.vue
                                let b = this.createPost.video.slice(0,this.createPost.video.size,"audio/ogg");
                                let newAudio = new File([b], this.createPost.video.name, {type: "audio/ogg"});
                                this.createPost.video = newAudio;
                                this.videoCodecCompatible = true;
                            } else {
                                // console.log("video/mpeg");
                                // console.log("filename BEFORE: ", newAudioFilename);
                                // newAudioFilename = filename + ".mp4";
                                // console.log("filename AFTER: ", newAudioFilename);
                                // mp4Blob = this.createPost.video.slice(0,this.createPost.video.size,"video/mpeg");
                                // // let newMp4AudioFile = new File([mp4Blob], this.createPost.video.name, {type: "audio/mpeg"});
                                // newMp4AudioFile = new File([mp4Blob], newAudioFilename, { type: "video/mpeg" });
                                // this.createPost.video = newMp4AudioFile;
                                // console.log("createPost.video: ", this.createPost.video);
                                // this.validateVideo();
                                this.videoCodecCompatible = false;
                                // this.videoCodecCompatible = null;
                                // this.audioFileError = true;
                                // this.$store.commit('alertUser', { show: true, text: "Audio File not supported in this browser", type: 'snackbar' });
                            }
                        }
                    }
                }
                // file is larger than limit, set this.videoCodecCheckLoading to false to stop loading
                // console.log("file is larger than limit");
                this.videoCodecCheckLoading = false;
                // this.hasBeenValidated = true;
                return;
            } else {
                // console.log("here");
            }
        },
        readFile: function (file) {
            // console.log("this.readFile called");
            return new Promise((resolve, reject) => {
                // Create file reader
                let reader = new FileReader();

                // Register event listeners
                // I don't think I need to turn off these event listeners because only the file reader has access to them!
                reader.addEventListener("loadend", e => resolve(e.target.result));
                reader.addEventListener("error", reject);

                // Read file
                reader.readAsArrayBuffer(file);
                // console.log("reader:", reader);
            })
        }
    },
    watch:{
        isFormValid (newValue) {
            if (newValue) {
                this.$store.commit('saveMediaSelectionStep', { complete: true, fileSelected: this.fileSelected, youtubeUrlSelected: this.youtubeUrlSelected });
            } else {
                this.$store.commit('saveMediaSelectionStep', { complete: false, fileSelected: this.fileSelected, youtubeUrlSelected: this.youtubeUrlSelected });
            }
        }
    },
    computed:{
        videoCodecCompatible: {
            get(){
                return this.$store.state.newPost.earlyUpload.videoCodecCompatible;
            },
            set(value){
                this.$store.commit('saveEarlyUploadVideoCodecCompatible', value);
            },
        },
        video: {
            get(){
                return this.$store.state.newPost.createPost.video;
            },
            set(value){
                this.$store.commit('saveCreatePostVideo', value);
            }
        },
        videoUrl: {
            get(){
                return this.$store.state.newPost.createPost.videoUrl;
            },
            set(value){
                this.$store.commit('saveCreatePostVideoUrl', value);
            }
        },
        languageSelected: {
            get(){
                return this.$store.state.newPost.createPost.languageSelected;
            },
            set(value){
                if (value != undefined) {
                    this.$store.commit('saveCreatePostLanguageSelected', value);
                }
            }
        },
        noCaptionLanguageSelected: {
            get(){
                return this.$store.state.newPost.createPost.noCaptionLanguageSelected;
            },
            set(value){
                // if (value != undefined) { 
                     this.$store.commit('saveCreatePostNoCaptionLanguageSelected', value);
                // }
            }
        },
        tawqData: {
            get(){
                return this.$store.state.newPost.tawqData;
            },
            set(value){
                this.$store.commit('saveTawqData', value);
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
        createPost: {
            get(){
                return this.$store.state.newPost.createPost;
            },
            set(value){
                this.$store.commit('saveCreatePost', value);
            }
        },
        mediaSelectionStep () {
            return this.$store.state.newPost.mediaSelectionStep;
        }
    },
    beforeDestroy () {
        this.$emit('setMediaSelectionData', { fileSelected: this.fileSelected, youtubeUrlSelected: this.youtubeUrlSelected } );
    },
    created () {
        // if (this.mediaSelectionStep.complete) {
        //     // console.log("in created it seems that media seleciton step is complete...");
        //     this.youtubeUrlSelected = this.mediaSelectionStep.youtubeUrlSelected;
        //     this.fileSelected = this.mediaSelectionStep.fileSelected;
        //     console.log("fileSelected: ", this.fileSelected);
        //     if (this.isDraftPost) {
        //         this.isFormValid = true; // TODO: PROB DON'T NEED THIS
        //         // this.videoUrl = typeof this.draftPost.draftVideo.url === 'undefined' ? "" : this.draftPost.draftVideo.url;
        //     }
        // }
        if (this.isDraftPost && (this.draftPost.draftVideo.type === 'video/mp4' || this.draftPost.draftVideo.type.split('/')[0] === 'audio') && !this.createPost.transcoded) {
            if (this.createPost.video) {
                if (this.createPost.video.name === this.draftPost.draftVideo.name) {
                    this.userNeedsToReselectVideo = false;
                } else {
                    this.userNeedsToReselectVideo = true;
                }
            } else {
                this.userNeedsToReselectVideo = true;
            }
            this.youtubeUrlSelected = false;
            this.fileSelected = true;
        } else {
            this.youtubeUrlSelected = true;
            this.fileSelected = false;
        }
        // if(this.isDraftPost){
        //     this.checkForMediaSelectionDifferences();
        // }
    }
    
}
</script>
<style scoped>
.v-tooltip__content {
    background-color: #424242 !important;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
        z-index: 0;
    }
    to {
        opacity: 1;
        z-index: 1;
    }
}
.slideDown {
    animation-name: slideDown;
    animation-duration: .4s;
}



@keyframes slideRight {
    from {
        opacity: 0;
        transform: translateX(-50px);
        z-index: 3;
    }
    to {
        opacity: 1;
        z-index: 0;
    }
}
.slideRight {
    animation-name: slideRight;
    animation-duration: .4s;
}


    
</style>