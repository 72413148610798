<template>
    <v-row justify="center" class="ma-0 pa-0">
        <Alert :key="NaN" v-if="deletePostDialog" :dialogVal="true" type="dialog" title="Are you sure you want to delete this post?" text="Your post will be permanently deleted. This action cannot be undone." @dialogClicked="deletePost"/>
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-h4 text-md-h3 font-weight-light">{{ username }}</span>
            </v-row>

            <v-row justify="center" no-gutters class="my-2">
                <v-col cols="12" align="center">
                    <span class="text-body-2 font-italic font-weight-light accent--text">{{ email }}</span><br/>
                    <v-btn @click.stop="$router.push({ path: 'settings' })" text small :ripple="false" class="primary--text">Edit</v-btn>
                </v-col>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="mx-5 mx-sm-10">
                <v-divider class="primary"/>
                <!-- MD AND UP SCREENS -->
                <v-avatar class="hidden-sm-and-down" size="120" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="118" max-width="118" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="118" max-width="118"></v-img>
                </v-avatar>

                <!-- SM SCREENS -->
                <v-avatar class="hidden-md-and-up hidden-xs-only" size="100" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="98" max-width="98" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="98" max-width="98"></v-img>
                </v-avatar>

                <!-- XS SCREENS -->
                <v-avatar class="hidden-sm-and-up" size="80" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="78" max-width="78" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="78" max-width="78"></v-img>
                </v-avatar>
                <v-divider class="primary"/>
            </v-row>

            <v-row justify="center" no-gutters class="my-5">
                <v-btn-toggle v-model="profilePageTab" rounded dense mandatory active-class="primary white--text">
                    <!-- TODO: make sure the atbottom listener doesn't get turned off then not turned back on? -->
                    <v-btn small @click="switchPosts('public')">Public</v-btn>
                    <v-btn small @click="switchPosts('group')">Group</v-btn>
                    <v-btn small @click="switchPosts('drafts')">Drafts</v-btn>
                </v-btn-toggle>
            </v-row>

            <v-row v-if="(publicPostsLoading || groupPostsLoading) && !emptySearch" justify="start" class="pa-0">
                <v-col v-for="index in 16" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
                    <v-skeleton-loader height="250" type="image" class="rounded-xl elevation-4"></v-skeleton-loader>
                </v-col>
            </v-row>

            <v-row v-if="emptySearch && !uploadingVideo" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">It seems that you have not posted anything yet.</span>
            </v-row>

            <v-row v-else-if="profilePageTab === 0" justify="start" class="pa-0">
                <v-col v-if="uploadingVideo && createPost.type === 'public' && !newPostExists()" cols="12" xl="3" lg="4" md="6" sm="6">
                    <div style="position: relative;">
                        <div class="milky-film d-flex flex-column justify-center align-center rounded-xl elevation-4" :class="[ $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2']">
                            <v-row align="end" justify="center" class="mb-n8"><v-progress-circular indeterminate size="30" :class="[ $vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--lighten-4']" ></v-progress-circular></v-row>
                            <v-row align="center" justify="start" style="width: 100%; opacity: 0.85;"><v-card-title class="text-h5 font-weight-bold d-block text-truncate white--text" style="z-index: 1; max-width: 100%;">{{createPost.title}}</v-card-title></v-row>
                        </div>
                        <v-img height="250" class="rounded-xl" :src="createPost.thumbnail && typeof createPost.thumbnail === 'object' ? $window.URL.createObjectURL(createPost.thumbnail) : createPost.thumbnail ? createPost.thumbnail : require('../assets/no-thumbnail.png')"></v-img>
                    </div>
                </v-col>

                <v-col v-for="(post, index) in publicPosts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                    <Post :post="post" :index="index" @delete-selected-post="deleteDialog" @retrieve-posts-again="retrievePostsAgain()"/>
                </v-col>
            </v-row>

            <v-row v-else-if="profilePageTab === 1" justify="start" class="pa-0">
                <v-col v-if="uploadingVideo && createPost.type === 'group' && !newPostExists()" cols="12" xl="3" lg="4" md="6" sm="6">
                    <div style="position: relative;">
                        <div class="milky-film d-flex flex-column justify-center align-center rounded-xl elevation-4" :class="[ $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2']">
                            <v-row align="end" justify="center" class="mb-n8"><v-progress-circular indeterminate size="30" :class="[ $vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--lighten-4']" ></v-progress-circular></v-row>
                            <v-row align="center" justify="start" style="width: 100%; opacity: 0.85;"><v-card-title class="text-h5 font-weight-bold d-block text-truncate white--text" style="z-index: 1; max-width: 100%;">{{createPost.title}}</v-card-title></v-row>
                        </div>
                        <v-img height="250" class="rounded-xl" :src="createPost.thumbnail && typeof createPost.thumbnail === 'object' ? $window.URL.createObjectURL(createPost.thumbnail) : createPost.thumbnail && typeof createPost.thumbnail != 'object' ? createPost.thumbnail : require('../assets/no-thumbnail.png')"></v-img>
                    </div>
                </v-col>

                <v-col v-for="(post, index) in groupPosts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                    <Post :post="post" :index="index" @delete-selected-post="deleteDialog" @retrieve-posts-again="retrievePostsAgain()" @move-post-to-another-group="$store.commit('alertUser', { show: true, text: `Successfully moved post to another group.`, type: 'snackbar' });"/>
                </v-col>
            </v-row>

            <Drafts v-else-if="profilePageTab === 2"/>

            <v-row v-show="scrollLoading" justify="center" align="end"  style="postion: fixed; bottom: 30px;">
                <v-progress-circular fixed size="30" color="primary" indeterminate></v-progress-circular>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import Post from "./Post.vue";
import firebase from "../../firebaseConfig.js";
import Alert from "./Alert.vue";
import Drafts from "./Drafts.vue";
// import fb from "firebase/app";

export default {
    name: "profile",
    metaInfo() {
        return {
            title: `${firebase.auth.currentUser.displayName} - tawq.in`,
            // ${this.$route.hash.substring(1)}
        }
    },
    components: {
        Post,
        Alert,
        Drafts,
    },
    data() {
        return {
            scrollLoading: false,
            emptySearch: null,
            publicPostsLoading: false,
            groupPostsLoading: false,
            publicPostListener: null,
            publicPosts: [],
            groupPosts: [],
            deletePostDialog: false,
            postToDelete: null,
            username: firebase.auth.currentUser.displayName,
            userProfilePic: firebase.auth.currentUser.photoURL,
            email: firebase.auth.currentUser.email,
            userId: firebase.auth.currentUser.uid,
            limit: 20,
            lastVisiblePublic: null,
            lastVisibleGroup: null,
            publicPostReference: "",
            groupPostReference: "",
            uploadingPostId: this.createPost ? this.createPost.postId : null,
            uploadingPostType: this.createPost ? this.createPost.type : "",
        };
    },
    methods: {
        deleteDialog: function (post) {
            this.deletePostDialog = true;
            this.postToDelete = post;
        },
        deletePost: async function(val) {
            if (!val) {
                this.deletePostDialog = false;
            } else {
                let post = this.postToDelete.post;
                let index = this.postToDelete.index;
                this.$store.dispatch('deletePostFromDb', { post: post, index: index, deleteFromStore: false } ); // profile posts are not saved in the store
                if (post.postType === 'group'){
                    this.groupPosts.splice(index, 1);
                } else {
                    this.publicPosts.splice(index, 1);
                }
                this.$store.commit('alertUser', { show: true, text: `Successfully deleted post.`, type: 'snackbar' });
                this.deletePostDialog = false;
            }
        },
        retrievePostsAgain: function(){
            /* THIS FUNCTION IS CURRENTLY ONLY USED AFTER WE HAVE CHANGED (From group to public or vice versa, or from group to another group) A POST. IF THAT CHANGES THEN CHANGE THE SNACKBAR STUFF */

            // it is necessary to reset the post references so it isn't based off the last visible.
            this.publicPostReference = firebase.db.collection("publicPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").limit(this.limit);
            this.groupPostReference = firebase.db.collection("groupPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").limit(this.limit);

            this.$store.commit('alertUser', { show: true, text: `Successfully changed post type.`, type: 'snackbar' });
            this.publicPosts = [];
            this.groupPosts = [];
            this.lastVisiblePublic = null;
            this.lastVisibleGroup = null;
            this.getPostsByUserID();
            this.getGroupPostsByUserId();
        },
        getPostsByUserID: function() {
            this.publicPostsLoading = true;
            this.publicPostReference.get().then((snapshot) => {
                if (!snapshot.empty) {
                    this.scrollLoading = true;
                    this.emptySearch = false;
                    this.lastVisiblePublic = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;;
                        this.publicPosts.push(post);
                    });
                    window.addEventListener('scroll', this.atBottom);
                } else {
                    if (this.lastVisiblePublic === null){
                        this.emptySearch = true;
                    }
                    window.removeEventListener('scroll', this.atBottom); // turns off listener once all posts have loaded
                }
                this.scrollLoading = false;
                this.publicPostsLoading = false;
            }).catch((err) => {
                console.error(err.message);
                this.$store.commit('alertUser', { show: true, text: `Couldn't retrieve your posts.`, type: 'snackbar' });
            });
        },
        getGroupPostsByUserId: function() {
            if (this.groupPosts.length === 0){ // only show the skeleton loaders on the first time loading
                this.groupPostsLoading = true;
            }
            this.groupPostReference.get().then((snapshot) => {
                if (!snapshot.empty) {
                    this.scrollLoading = true;
                    this.emptySearch = false;
                    this.lastVisibleGroup = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;;
                        this.groupPosts.push(post);
                    });
                    window.addEventListener('scroll', this.atBottom);
                } else {
                    if (this.lastVisibleGroup === null){
                        this.emptySearch = true;
                    }
                    window.removeEventListener('scroll', this.atBottom); // turns off listener once all posts have loaded
                }
                this.scrollLoading = false;
                this.groupPostsLoading = false;
            }).catch((err) => {
                console.error(err.message);
                this.$store.commit('alertUser', { show: true, text: `Couldn't retrieve your posts.`, type: 'snackbar' });
            });
        
        },
        switchPosts: function(type){
            // we have to turn the atBottom listener off whenever the postReference doesn't return any posts. But we must turn it back on here, so that if all the posts have been loaded
            // for one post reference but not the other, we still have a way to retrieve more posts.
            if (type === 'public' && this.profilePageTab != 0){
                this.emptySearch = false;
                this.profilePageTab = 0;
                this.$router.replace({path: this.$route.name, hash: 'public'});
                if (this.publicPosts.length === 0) {
                    this.getPostsByUserID();
                } else {
                    window.addEventListener('scroll', this.atBottom);
                }
            } else if (type === 'group' && this.profilePageTab != 1) {
                this.emptySearch = false;
                this.profilePageTab = 1;
                this.$router.replace({path: this.$route.name, hash: 'groups'});
                if (this.groupPosts.length === 0) {
                    this.getGroupPostsByUserId();
                } else {
                    window.addEventListener('scroll', this.atBottom);
                }
            } else if (type === 'drafts' && this.profilePageTab != 2) {
                this.emptySearch = false;
                this.$router.replace({path: this.$route.name, hash: 'drafts'});
            }
        },
        atBottom: function() {
            if (!this.publicPostsLoading && !this.groupPostsLoading) {
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                if (bottomOfWindow) {
                    this.scrollLoading = true;
                    if (this.profilePageTab === 0){
                        this.publicPostReference = firebase.db.collection("publicPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").startAfter(this.lastVisiblePublic).limit(this.limit);
                        this.getPostsByUserID();
                    } else {
                        this.groupPostReference = firebase.db.collection("groupPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").startAfter(this.lastVisibleGroup).limit(this.limit);
                        this.getGroupPostsByUserId();
                    }
                    window.removeEventListener('scroll', this.atBottom);
                }
            }
        },
        newPostExists: function() {
            let exists;
            if (this.uploadingPostType === 'public') {
                exists = this.publicPosts.find( post => {
                    return post.postId === this.uploadingPostId;
                });
            } else {
                exists = this.groupPosts.find( post => {
                    return post.postId === this.uploadingPostId;
                });
            }
            return exists;
        }
    },
    computed: {
        uploadingVideo(){
            this.$store.state.newPost.uploading_video; /* && this.$store.state.newPostStore.resumable_progress > 0 */
        },
        createPost(){
            return this.$store.state.newPost.createPost;
        },
        profilePageTab: {
            get(){
                return this.$store.state.profilePageTab;
            },
            set(value){
                this.$store.commit('changeProfilePageTab', value);
            }
        }
    },
    watch: {
        '$store.state.newPost.uploading_video' (newValue, oldValue){
            if (oldValue === true && newValue === false){ // this means that the upload is complete, re request the vids... TODO: set a store module for profile posts...
                // retrieve the post that is being uploaded, and add it to the front of the publicPosts list. this will keep us from reading all the posts again and from getting duplicate keys
                /* if it is a youtube video, sometimes the post has been uploaded by the time the user is sent back to profile page. Therefore we need to check to see if the post is already in the list*/
                if ( !this.newPostExists() ){
                    firebase.db.collection(this.uploadingPostType === 'public' ? "publicPosts" : "groupPosts").where("postId", "==", this.uploadingPostId).get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            // var date = doc.data().postDate.toDate().toLocaleDateString();
                            let day = doc.data().postDate.toDate().getDate();
                            let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                            let year = doc.data().postDate.toDate().getFullYear();
                            let formattedDate = `${day} ${month} ${year}`;
                            var post = doc.data();
                            post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;;
                            if (this.uploadingPostType === 'public'){
                                this.publicPosts.unshift(post);
                            } else {
                                this.groupPosts.unshift(post);
                            }
                            this.emptySearch = false;
                        });
                    }).catch((error) => {
                        console.log("error retrieving the newly uploaded post", error);
                    });
                }
            }
        },
        '$store.state.newPost.audioCaptionsUploadComplete' (newValue, oldValue) {
            // this value will change, then have 8 seconds before createPost will be reset.
            if (oldValue === false && newValue === true){
                this.uploadingPostId = this.createPost.postId;
                this.uploadingPostType = this.createPost.type;
            }
        }
    },
    created: function() {
        this.userProfilePic = firebase.auth.currentUser.photoURL;
        this.publicPostReference = firebase.db.collection("publicPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").limit(this.limit);
        this.groupPostReference = firebase.db.collection("groupPosts").where("userId", "==", this.userId).orderBy("postDate", "desc").limit(this.limit);
        if (this.$route.hash) {
            if (this.$route.hash.substring(1) == 'public') {
                this.profilePageTab = 0;
                this.getPostsByUserID();
            } else if (this.$route.hash.substring(1) == 'groups') {
                this.profilePageTab = 1;
                this.getGroupPostsByUserId();
            } else {
                // this.$route.hash.substring(1) == 'drafts'
                // draft component should do the work.
                this.profilePageTab = 2;
            }
        } else {
            if (this.profilePageTab === 0) {
                this.$router.replace({path: this.$route.name, hash: 'public'});
                this.getPostsByUserID();
            } else if (this.profilePageTab === 1) {
                this.$router.replace({path: this.$route.name, hash: 'groups'});
                this.getGroupPostsByUserId();
            } else if (this.profilePageTab === 2) {
                // draft component should do the work.
                this.$router.replace({path: this.$route.name, hash: 'drafts'});
            }
        }
    },
    mounted: function() {
        window.addEventListener('scroll', this.atBottom);
    },
    beforeDestroy: function(){
        window.removeEventListener('scroll', this.atBottom);
    },
};
</script>

<style>
.milky-film {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: red; */
    opacity: .85;
    z-index: 1;
    cursor: progress;
    border: solid red 1px;
}

.v-skeleton-loader__image {
    height: 100%;
}
</style>
