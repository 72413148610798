import axios from "axios";
import firebase from "../../firebaseConfig";
// import fb from "firebase/compat/app";

const newTextPostStore = {
    namespaced: true,
    state: () => ({
        newTextPostStep: {
            MEDIA_SELECTION: 1, // THIS STEP MUST ALWAYS COME BEFORE CONTENT_CREATION
            CONTENT_CREATION: 2,
            CONTENT_METADATA: 3,
        },
        mediaSelectionStep: {
            complete: false,
            manualTextInputSelected: false,
            textFileUploadSelected: false,
        },
        contentCreationStep: {
            complete: false,
        },
        contentMetadataStep: {
            completed: false,
        },
        postAnalytics: { // ***UPDATE THESE VALUES BEFORE FINAL PUSH - SCOT BRADY AUGUST 18, 2024***
            startTimes: [], // a user may start it, save it as a draft, then restart it.
            endTimes: [],
            pushToTawq: true, // should always be pushToTawq in this creation style
            // pickUpToTawq: null,
            isMobileDevice: null,
            isIOS: null,
            totalAudioTime: 0,
            stepOrder: {}, // might not need this
            postType: null, // either 'public' or 'group'
            // dualDevice: null, 
            total_contributions: 0,
        },
        textPost: {
            currentStep: 1,
            textAdded: [],
            title: "",
            description: "",
            group: "",
            groupLanguage: null,
            tags: [],
            type: null, // postType: null, // either 'public' or 'group'
            file: {},
            fileTextSeparationType: null, // default type is '  (Space)' (space - word separation) // types are: | ' (Space)' (space - for word separation) | ', (Comma)' (comma - for csv files separation) | '; (Semicolon)' (semicolon - for certain files? separation) | '/  (Forward Slash)' (forward slash - for csv files and certain other files? separation) | '\n (New Line Character)' (new line character - for new line separation) | '. (Period)' (period - for sentence separation)
            userId: "",
            username: "",
            userProfilePhoto: "",
            postId: "",
            audioDataId: "",
            // category: null,
            languageSelected: {
              text: "English (United States)",
              value: "en-US",
              model: 0,
              location: 0,
              recognizerEnum: 0,
              recognizer: "gl-en-us-en-in-en-gb",
            },
            noCaptionLanguageSelected: "",
        },
        tawqData: {}, // tawqData will have keys that are audioSegId's. each key will be an object containing 3 arrays - videoSegArray, audio, caption
        postMetaDataUploadComplete: false, // the whole post has been completely uploaded
        audioCaptionsUploadComplete: false, // set to true when audio Captions have been uploaded
        uploading_text: false, // set true during the upload process
        resumable_progress: 0, // the progress for the video file being uploaded to storage
        isPublicPost: false,
        fbUser: firebase.auth.currentUser,
        processTextAttempts: 0,
    }),
    mutations: {
        saveFirebaseUser( state){
            state.textPost.userId = firebase.auth.currentUser.uid;
            state.textPost.username = firebase.auth.currentUser.displayName;
            state.textPost.userProfilePhoto = firebase.auth.currentUser.profilePhoto;
            state.fbUser = firebase.auth.currentUser;
            // console.log(user.uid);
        },
        saveTextPostLanguageSelected(state, payload){
            state.textPost.languageSelected.text = payload.text;
            state.textPost.languageSelected.value = payload.value;
            state.textPost.languageSelected.model = payload.model;
            state.textPost.languageSelected.location = payload.location;
            state.textPost.languageSelected.recognizerEnum = payload.recognizerEnum;
            state.textPost.languageSelected.recognizer = payload.recognizer;
        },
        saveIsPostPublic (state, isPublic){
            state.isPublicPost = isPublic;
        },
        saveResumableProgress (state, progress){
            state.resumable_progress = progress;
        },
        saveTextPostCurrentStep(state, payload){
            state.textPost.currentStep = payload;
        },
        saveMediaSelectionStep(state, payload){
            state.mediaSelectionStep = Object.assign(state.mediaSelectionStep, payload);
            // state.mediaSelectionStep.complete = payload;
        },
        saveMediaSelectionType(state, payload){
            state.textPost.type = payload;
        },
        saveContentMetadataStep(state, payload){
            state.contentMetadataStep = payload;
        },
        saveContentCreationStep(state, payload){
            state.contentCreationStep = payload;
        },
        saveTawqData(state, payload){
            // console.log("the payload in saveTawqData: ", payload);
            // state.tawqData = Object.assign({}, state.tawqData, { [payload.key]: payload.value } );
            state.tawqData = Object.assign({}, state.tawqData, payload );
        },
        deleteKeyFromTawqData(state, payload){
            // console.log("payload key: ", payload.key);
            delete state.tawqData[payload.key];
        },
        saveTextPost(state, payload){
            state.textPost = Object.assign(state.textPost, payload);
        },
        saveTextAdded (state, data){
            state.textAdded = data;
        },
        resetStoreState (state){
            state.postMetaDataUploadComplete = false;
            state.audioCaptionsUploadComplete = false;
            state.textFileUploadComplete = false;
            state.uploading_text = false;
            state.resumable_progress = 0;
            state.isPublicPost = false;
            state.fbUser = firebase.auth.currentUser;
            state.textPost = {
                currentStep: 1,
                textAdded:[],
                title: "",
                description: "",
                group: "",
                groupLanguage: null,
                tags: [],
                type: null, // postType: null, // either 'public' or 'group'
                userId: "",
                username: "",
                userProfilePhoto: "",
                postId: "",
                audioDataId: "",
                // category: null,
                languageSelected: {
                text: "English (United States)",
                value: "en-US",
                model: 0,
                location: 0,
                recognizerEnum: 0,
                recognizer: "gl-en-us-en-in-en-gb",
                },
                noCaptionLanguageSelected: "",
            };
            state.tawqData = {};
            state.processTextAttempts = 0;
        }
    },
    actions: {
        setFirebaseUser ({commit}, user) {
            commit('saveFirebaseUser', user);
        },
        setIsPostPublic ({commit}, isPublic) {
            commit('saveIsPostPublic', isPublic);
        },
        async saveTextPost ({state, commit, dispatch}, post) {
            state.textPost = post;
            state.textFileUploadComplete = true;
            // state.processing_text = true;
            commit('saveResumableProgress', 100);
            state.uploading_text = true;

            let audioDataIds = [];
            audioDataIds.push(state.textPost.audioDataId);

            dispatch('setTextData');
            let imageUrl = await dispatch('uploadTextThumbnail');

            let newPostRef = firebase.db.collection( state.isPublicPost ? "publicPosts" : "groupPosts").doc(state.textPost.postId);
            newPostRef.set({
                mediaType: "text",
                title: state.textPost.title,
                description: state.textPost.description,
                postDate: state.textPost.date,
                postType: state.textPost.type,
                tags: state.textPost.tags,
                postId: state.textPost.postId,
                audioDataId: state.textPost.audioDataId,
                userId: state.textPost.userId,
                username: state.textPost.username,
                userProfilePhoto: state.textPost.userProfilePhoto,
                thumbnail: imageUrl, // ???
                languageName: state.textPost.languageSelected.text,
                groupId: state.textPost.group ? state.textPost.group.value : null,
                groupName: state.textPost.group ? state.textPost.group.text : null,
                // canContribute: state.textPost.canContribute,
            }, {merge: true}).catch((err) => {
                console.error("Error saving the post metadata", err.message);
            })

            newPostRef.collection("contributions").doc(state.textPost.userId).set({
                audioDataId: audioDataIds,
            }, {merge: true}).catch((err) => {
                console.error("Error setting contributions for the post.", err.message);
            });
            state.postMetaDataUploadComplete = true;
            dispatch('setTextData');
        },
        async setTextData ({state, dispatch}) {
            state.audioCaptionsUploadComplete = false;
            var textAdded = await dispatch('createTextObjects');
            // let date = new Date();
            if (textAdded.length === 0) {
                console.log("audo.length is = 0");
                state.audioCaptionsUploadComplete = true;
                dispatch('processText');
                // state.setTimeoutForProcessing();
                return;
            }
            if (typeof state.textPost.audioDataId === 'undefined' || typeof state.textPost.userId === 'undefined') {
                console.log("either state.textPost.audioDataId or .userId is undefined");
                console.log("audioDataId: ", state.textPost.audioDataId);
                console.log("userId: ", state.textPost.userId);
            }
            var newAudioRef = firebase.db.collection("audioSegments").doc(state.textPost.audioDataId);
            newAudioRef.set({
                  title: state.textPost.title,
                  userId: state.textPost.userId,
                  username: state.textPost.username,
                  userProfilePhoto: state.textPost.userProfilePhoto,
                  postId: state.textPost.postId,
                  text: textAdded,
                  date: state.textPost.date,
                  languageSelected: state.textPost.languageSelected,
                  audioDataId: state.textPost.audioDataId,
            },{ merge: true }).then(() => {
                state.audioCaptionsUploadComplete = true;
                dispatch('processText'); // because setAudioCaptionData is called last, it should be the last thing to finish
            }).catch((error) => {
                console.error("Error uploading audio metadata", error);
                state.audioCaptionsUploadComplete = true;
            dispatch('processText');
            });
        },
        updateTextAdded ({commit}, data) {
            commit('saveTextAdded', data);
        },
        createTextObjects ({state}) {
             // state creates metadata for the actual audio file. state is not creating
            // the audio file itself.
            return new Promise ((resolve) => { // add a reject for a fail case???
                var objects = [];
                // console.log(state.textAdded);
                state.textAdded.forEach((text) => {
                    var object = {
                        text: text.text,
                        date: text.date,
                        languageSelected: text.languageSelected,
                        recordingTime: text.recordingTime,
                        audioURL: text.audioURL,
                        segment: text.segment,
                        caption: text.caption,
                        rect: text.rect,
                        style: text.style,
                    };
                    objects.push(object);
                });
                resolve(objects);
            });
            // return new Promise((resolve) => {
            //     var objects = [];
            //     state.audioCaptionData.audio.forEach((audio) => {
            //         var newObj = {
            //         audioURL: audio.audioURL,
            //         audioStart: audio.audioStart,
            //         audioDur: audio.audioDur,
            //         segment: audio.segment,
            //         };
            //         objects.push(newObj);
            //     });
            //     resolve(objects);
            // });
            
        },
        async uploadTextThumbnail ({state}) {
            // get the thumbnail from the file input
            var data = new FormData();
            data.append("file", state.textPost.photoURL);
            data.append("postId", state.textPost.postId);
            data.append("mediaType", true);
            let response = await axios.post(`uploadpic`, data);
            return response.data.url;
        },
        processText ({state, dispatch}) {
            /* state function will be called when PostUploadComplete = true, unless the image hasn't been resized yet,
            in which case it will be called once the image is done being resized */
            if (state.audioCaptionsUploadComplete && state.postMetaDataUploadComplete && state.textFileUploadComplete) {
                setTimeout(() => {
                    dispatch('resetStoreState');
                  }, 8000);
            } else {
                console.error("Processing didn't work, if this is the first time, we will try again.");
                if ( state.processTextAttempts > 0 ) { // if we have tried processing already
                    dispatch('deleteIncompletePostAudioFilesFromStorage');
                }
            }
            state.processTextAttempts += 1;
        },
        deleteIncompletePostAudioFilesFromStorage ({state}) {
            let audioDataRef = firebase.storage.ref(`audioSegments/${state.textPost.postId}/${state.textPost.audioDataId}`);
              audioDataRef.list().then((res) => {
                res.items.forEach(function (itemRef) {
                  /* retrieve the file names of each segment, so that we can delete each file
                    individually from the storage bucket. GCS doesn't allow us to delete the full bucket */
        
                  let filename = itemRef.name;
                  let audioRef = audioDataRef.child(filename);
                  audioRef.delete().then(() => {
                      return;
                    }).catch((error) => {
                      console.error(`Error Deleting Audio Segment File ${filename} from Storage:`, error);
                      return;
                    });
                });
            });
        },
        resetStoreState({commit}){
            /* this method will reset the data being stored for newPost */
            commit('resetStoreState');
        }
    } // actions
} // store

export default newTextPostStore