<template>
    <v-row justify="center" style="overflow-y:scroll;">
        <v-virtual-scroll :items="items" :bench="2" item-height="70">
            <template v-slot:default="{item, index}">
                <v-list-item :key="item.userId" v-bind:class="[isOdd(index) ? 'odd' : $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" style="width: 100%" class="py-2 my-0 pr-0 justify-space-between rounded-xl">
                    <v-row justify="space-between" align="center" no-gutters>
                        <v-col cols="2" class="flex-grow-0 flex-shrink-1">
                            <v-avatar size="50" color="primary" @click.stop="openSearchedUsersPage(item.userId)" style="cursor: pointer;" class="hidden-xs-only">
                                <v-img :src="item.userProfilePhoto != '' && item.userProfilePhoto != null ? item.userProfilePhoto : require('../../assets/no-user.png')" :alt="item.username" referrerpolicy="no-referrer" @error="item.userProfilePhoto=null"></v-img>
                            </v-avatar>
                            <v-avatar size="40" color="primary" @click.stop="openSearchedUsersPage(item.userId)" style="cursor: pointer;" class="hidden-sm-and-up">
                                <v-img :src="item.userProfilePhoto != '' && item.userProfilePhoto != null ? item.userProfilePhoto : require('../../assets/no-user.png')" :alt="item.username" referrerpolicy="no-referrer" @error="item.userProfilePhoto=null"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="7" class="flex-grow-1 flex-shrink-0">
                            <div v-bind:class="[isOdd(index) ? 'black--text' : $vuetify.theme.dark ? 'white--text' : 'black--text']" @click.stop="openSearchedUsersPage(item.userId)" style="cursor: pointer;" class="font-weight-medium text-caption text-lg-subtitle-1 text-truncate">
                                {{ item.username }}
                            </div>
                        </v-col>
                        <v-col cols="3" class="flex-grow-0 flex-shrink-1">
                            <v-row justify="end" align="center" no-gutters class="pr-5">
                                <v-menu v-model="item.menu" rounded="lg" offset-y left origin="center" transition="scale-transition" close-on-content-click>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" @click.stop="item.menu = !item.menu" icon>
                                            <v-icon :color="$vuetify.theme.dark ? !isOdd(index) ? 'primaryAccent' : 'primary' : 'accent'">mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list rounded>
                                        <v-list-item v-if="item.accepted" @click="openRemoveFriendDialog(item, index)">
                                            <v-icon class="pr-2">mdi-account-multiple-remove</v-icon>Remove Friend<br>
                                        </v-list-item>
                                        <v-list-item v-else @click="openCancelFriendDialog(item)">
                                            <v-icon class="pr-2">mdi-account-cancel</v-icon>Cancel Request
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>
        </v-virtual-scroll>
        <RemoveFriend :dialog="removeFriendDialog" :friend="friend" @removeFriend="removeFriend" @cancelRemoveFriend="removeFriendDialog = false"/>
        <CancelFriend :dialog="cancelFriendDialog" :friend="friend" @cancelFriendRequest="cancelFriend" @dismissCancelFriendRequest="cancelFriendDialog = false"/>
    </v-row>
</template>

<script>
import RemoveFriend from "./RemoveFriend.vue";
import CancelFriend from "./CancelFriend.vue";

export default {
    name: "friends",
    props: ["friends"],
    components: {
        RemoveFriend,
        CancelFriend,
    },
    data: function () {
        return {
            removeFriendDialog: false,
            cancelFriendDialog: false,
            friendIndex: null,
            friend: {},
        };
    },
    methods: {
        isOdd: function (id) {
            if (id % 2 == 1) {
                return true;
            }
            return false;
        },
        openSearchedUsersPage: function (id) {
            this.$router.push({name: 'view-user-profile', query: { id: id }});
            // window.open(`https://tawq.in/view-user-profile?id=${id}`, "_blank"); // uncomment to open friends profile in new window
        },
        openRemoveFriendDialog: function (friend, index) {
            friend.menu = !friend.menu;
            this.friend = friend;
            this.friendIndex = index;
            this.removeFriendDialog = true;
        },
        openCancelFriendDialog: function (friend) {
            friend.menu = !friend.menu;
            this.friend = friend;
            this.cancelFriendDialog = true;
        },
        removeFriend: function () {
            this.$emit("removeFriend", this.friend, this.friendIndex);
            this.removeFriendDialog = false;
        },
        cancelFriend: function () {
            this.$emit("cancelFriend", this.friend);
            this.cancelFriendDialog = false;
        },
    },
    computed: {
        items() {
            this.friends.forEach((friend) => {
                friend.menu = false;
            });
            return this.friends;
        },
    },
};
</script>

<style>
.odd {
    background: #dddddd !important;
}
</style>
