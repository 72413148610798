<template>
    <div class="mt-16 pt-16 pt-sm-0 mt-sm-8">

        <!-- WRAPPER - GRID ELEMENT -->
        <div id="user-analytics-grid" class="full-width px-4 px-md-0">

            <!-- TOTAL USERS GRAPH -->
            <div class="pa-4 d-flex flex-row flex-lg-column justify-space-around align-center grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">

                <v-col xl="4" lg="2" md="2" sm="2" xs="2" class="d-flex justify-md-center justify-start align-center px-0 mr-5 mr-sm-0">
                    <v-icon class="accent pa-1 rounded-circle smoke--text" medium>mdi-account</v-icon>
                </v-col>

                <div class="d-flex flex-row justify-space-between justify-md-center justify-lg-space-between align-center full-width">
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Total Users</div>
                        <div v-if="adminAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-show="!adminAnalytics.isLoading" class="text-h5 primary--text text-center">{{ adminAnalytics.data != null ? adminAnalytics.data.totalUsers :  0 }}</div>
                    </div>

                    <v-divider vertical class="mx-0 mx-sm-6 mx-md-12 mx-lg-2"></v-divider>

                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Since {{new Date(dateFilter.startDate).toLocaleDateString()}}</div>
                        <div v-if="userAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-show="!userAnalytics.isLoading" class="text-h5 primary--text text-center">{{ getUserCountWithinDate }}</div>
                    </div>
                </div>

            </div>

            <!-- SUBSCRIPTIONS -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']" >
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="userAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!userAnalytics.isLoading" id="graph--user-subscriptions"></canvas>
            </div>

            <!-- SIGN UP METHOD  -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-timer-sand-paused</v-icon>
                <div v-if="userAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!userAnalytics.isLoading" id="graph--user-sign-up-method"></canvas>
            </div>

            <!-- SIGN UP DATE -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="userAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!userAnalytics.isLoading" id="graph--user-sign-up-date"></canvas>
            </div>

            <!-- SPECIFIC USER DATA -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <!-- <v-skeleton-loader v-if="userAnalytics.isLoading" type="image"></v-skeleton-loader> -->
                <!-- <div v-if="userAnalytics.isLoading" class="loading-data"></div> -->
                <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Selected User</div>
                <div v-show="!userAnalytics.isLoading" id="selected-user-data" class="mt-3 full-width d-flex flex-column justify-space-evenly">
                    <div class="d-flex flex-row justify-center full-width">
                        <v-avatar size="20">
                            <v-img :src="selectedUser.profilePicture"></v-img>
                        </v-avatar>
                    </div>
                    <div class="selectedUser-data-point">
                        <span>Username</span>
                        <span class="primary--text" style="cursor: pointer;" @click="selectedUser.userId != userData.userId ? $router.push({name: 'view-user-profile', query: { id: selectedUser.userId }}) : $router.push({path: 'profile'})">{{selectedUser.username}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Full Name</span> 
                        <span>{{selectedUser.fullname}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>ID</span> 
                        <span>{{selectedUser.userId}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Admin</span> 
                        <span>{{selectedUser.admin ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Caption Audio</span>
                        <span>{{selectedUser.captionAudio ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Down Votes</span>
                        <span v-if="selectedUser">{{selectedUser.contributionDownVotes ? selectedUser.contributionDownVotes.length : 0}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Up Votes</span>
                        <span v-if="selectedUser">{{selectedUser.contributionUpVotes ? selectedUser.contributionUpVotes.length : 0}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Theme</span>
                        <span>{{selectedUser.darkTheme ? "Dark" : "Light"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Dual Device</span>
                        <span>{{selectedUser.dualDevice ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Email Verified</span>
                        <span>{{selectedUser.emailVerified ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Language</span>
                        <span>{{selectedUser.languageSelected ? selectedUser.languageSelected.text : "None"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Posts Created</span>
                        <span>{{selectedUser.postsCreated}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Push To Tawq</span>
                        <span>{{selectedUser.pushToTawq ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Sign Up Method</span>
                        <span>{{selectedUser.signUpMethod}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Customer Id</span>
                        <span>{{selectedUser.stripeCustomerId}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Active Subscription</span>
                        <span>{{selectedUser.stripeIsActive ? "Yes" : "No"}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                    <div class="selectedUser-data-point">
                        <span>Subscription</span>
                        <span>{{stripeSubscriptions[selectedUser.stripeProductId]}}</span>
                    </div>
                    <v-divider class="full-width"></v-divider>
                </div>
                <!-- <canvas v-show="!userAnalytics.isLoading" id="graph--user-specific-data"></canvas> -->
            </div>


        </div>

        
    </div>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.defaults.plugins.title.font.size = 14;
Chart.defaults.plugins.title.font.lineHeight = 1.375;
Chart.defaults.plugins.title.font.weight = 500;
// Chart.defaults.plugins.title.font.color = window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base');
import {mapGetters, mapState} from 'vuex';
export default {
    name: "UserAnalytics",
    components: {},
    props: ["adminAnalytics", "userAnalytics", "selectedUser"],
    data(){
        return {
            colors: {
                primary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphPrimary-base'),
                secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphSecondary-darken1'),
                accent: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphAccent-base'),
                success: window.getComputedStyle(document.documentElement).getPropertyValue('--v-success-base'),
                invert: window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base'),
                smoke: '#F5F5F5',
                Facebook: "#1976D2",
                Google: "#E53935"
            },
            charts: [],
            stripeSubscriptions: {
                "prod_IajkUya9uO1dPu": "Basic", // BASIC MEMBERSHIP
                "prod_IZbAIZAAdOP5RZ": "Plus", // PLUS MEMBERSHIP
                "prod_IZb2wxixqzmdO5": "Premium", // PREMIUM MEMBERSHIP
            }
            
        }
    },
    methods: {
        setColors(){ // this will reset the colors if the theme is changed....
            // console.log("userAnalytics.setColors()")
            this.colors = {
                primary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphPrimary-base'),
                secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphSecondary-darken1'),
                accent: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphAccent-base'),
                success: window.getComputedStyle(document.documentElement).getPropertyValue('--v-success-base'),
                invert: window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base'),
                smoke: '#F5F5F5',
                Facebook: "#1976D2",
                Google: "#E53935"
            }
        },
        hexToRGB(h, opacity) {
            h = h.replace(' ', ''); // with chrome it reads them in with a space before the #
            let r = 0, g = 0, b = 0;
            // 3 digits
            if (h.length == 4) {
                r = "0x" + h[1] + h[1];
                g = "0x" + h[2] + h[2];
                b = "0x" + h[3] + h[3];

            // 6 digits
            } else if (h.length == 7) {
                r = "0x" + h[1] + h[2];
                g = "0x" + h[3] + h[4];
                b = "0x" + h[5] + h[6];
            }
            // return "rgb("+ +r + "," + +g + "," + +b + ")";
            return "rgb("+ +r + "," + +g + "," + +b + "," + +opacity + ")";
        },
        destroyCharts(){
            // console.log("destroying charts")
            this.charts.forEach((chart) => {
                chart.destroy();
            })
            this.charts = [];
        },
        drawGraph(context, graphType, graphData, graphOptions){
            // console.log("drawing graph")
            const chart = new Chart(context, {
                type: graphType,
                data: graphData,
                plugins: [ChartDataLabels],
                options: graphOptions ? graphOptions : {}
            })
            this.charts.push(chart);
        },
        drawUserAnalyticsGraphs(){
            // subscriptions graph
            const subscriptionsGraph = { 
                context: document.getElementById('graph--user-subscriptions').getContext('2d'),
                type: 'bar',
                data: {
                    labels: [ ...this.getUserSubscriptionTypes.labels ],
                    datasets: [{
                        // barPercentage: 0.5,
                        // barThickness: 15,
                        minBarThickness: 20,
                        maxBarThickness: 60,
                        minBarLength: 4,
                        indexAxis: 'y',
                        label: "Subscriptions",
                        data: [ ...this.getUserSubscriptionTypes.totals ],
                        backgroundColor: ['#424242', this.colors.primary, this.colors.secondary],
                    }]
                },
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    animation: {
                        duration: 1200,
                        easing: 'linear',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke,
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Subscription Type',
                            color: this.colors.invert
                        },
                        label:{
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        }
                    }
                },
            }
            this.drawGraph(subscriptionsGraph.context, subscriptionsGraph.type, subscriptionsGraph.data, subscriptionsGraph.options);
            // SIGN UP METHOD GRAPH
            const signUpMethodGraph = { 
                context: document.getElementById('graph--user-sign-up-method').getContext('2d'),
                type: 'doughnut',
                data: {
                    labels: [ ...this.getSignUpMethodData.labels ], // email, fb, google
                    datasets: [{
                        label: "Sign Up Method",
                        data: [ ...this.getSignUpMethodData.totals ],
                        borderColor: this.hexToRGB(this.colors.invert, 0.5),
                        backgroundColor: [ this.colors.primary, this.colors.Facebook, this.colors.Google ],
                        hoverOffset: 5,
                    }]
                },
                options: {
                    responsive: true,
                    animation: {
                        duration: 1500,
                        easing: 'ease-out',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            formatter: (value, context) => {
                                const total = context.chart.data.datasets[0].data.reduce( (accumulator, curr) => accumulator + curr)
                                const percentage = (value / total) * 100;
                                return percentage.toFixed(2) + '%';
                            },
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke
                        },
                        legend: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Sign Up Method',
                            color: this.colors.invert
                        },
                        label:{
                            display: true,
                        }
                    },
                },
            }

            this.drawGraph(signUpMethodGraph.context, signUpMethodGraph.type, signUpMethodGraph.data, signUpMethodGraph.options);

            // sign up date graph
            const signUpDateGraph = {
                context: document.getElementById('graph--user-sign-up-date').getContext('2d'),
                type: 'line',
                data: {
                    labels: [ ...this.getSignUpDateData.labels ],
                    datasets: [{
                        label: "Sign Up Date",
                        data: [ ...this.getSignUpDateData.totals ],
                        fill: true,
                        borderColor: this.colors.primary,
                        backgroundColor: this.hexToRGB(this.colors.primary, 0.2),
                        tension: 0.3,
                    }]
                },
                options: {
                    responsive: true,
                    animation: this.getLineGraphProgessiveAnimation(this.getSignUpDateData.totals.length),
                    interaction: {
                        intersect: false,
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Sign Up Date',
                            color: this.colors.invert
                        },
                        label:{
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        }
                    },
                },
            }

            this.drawGraph(signUpDateGraph.context, signUpDateGraph.type, signUpDateGraph.data, signUpDateGraph.options );

        },
        getLineGraphProgessiveAnimation(dataLength){
            const totalDuration = 2000; // 10 seconds
            const delayBetweenPoints = totalDuration / (dataLength);
            const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
            const animation = {
                // delay: 300,
                x: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: NaN, // the point is initially skipped
                    delay(ctx) {
                    if (ctx.type != 'data' || ctx.xStarted) {
                        return 0;
                    }
                    ctx.xStarted = true;
                        return ctx.index * delayBetweenPoints;
                    }
                },
                y: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: previousY,
                    delay(ctx) {
                    if (ctx.type != 'data' || ctx.yStarted) {
                        return 0;
                    }
                    ctx.yStarted = true;
                        return ctx.index * delayBetweenPoints;
                    }
                }
            };
            return animation;
        }
    },
    computed: {
        ...mapGetters('adminAnalyticsStore', [
            'getUserCountWithinDate',
            'getUserSubscriptionTypes',
            'getSignUpMethodData',
            'getSignUpDateData',
        ]),
        ...mapState('adminAnalyticsStore', {
            dateFilter: state => state.dateFilter,
            randomData: state => state.randomData,
        }),
        userData(){
            return this.$store.state.userData;
        },
    },
    watch: {
        "userAnalytics.isLoading": {
            handler: function (newValue) {
                // console.log("loading ", newValue)
                if(!newValue){
                    /* IF THE CHARTS HAVEN'T BEEN CREATED YET, CREATE AND DRAW THEM. IF THEY HAVE, JUST UPDATE THEM */
                    // if(this.charts.length === 0){
                    //     this.drawUserAnalyticsGraphs();
                    // }
                    // else {
                    //     this.destroyCharts();
                        this.drawUserAnalyticsGraphs();
                    // }
                    // console.log("no longer loading, draw the graphs associated with userAnalytics!")
                }
            }
        }
    },
    beforeMount(){
        this.setColors();
    },
    mounted(){
        if(this.randomData){
            this.drawUserAnalyticsGraphs();
        }
        // console.log("primary color: ", this.colors.primary)
        // console.log("User analytics component created()", this.adminAnalytics)
    },
    created(){
        // console.log("user analytics created() ", this.$vuetify.theme.isDark)
    }

    
}
</script>
<style lang="scss" scoped>

.graph-type-icon{
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.8;
    padding: 12px;
}

canvas {
    object-fit: contain;
    // fill their container
    height: 100%;
    max-height: 100%;
    // width: 100%;
    // max-width: 100%;
}



#user-analytics-grid {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(12, 1fr);
    height: 400px;
    column-gap: 1em;
    row-gap: 1em;
    * {
        box-sizing: border-box !important;
    }

    .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        border-radius: 12px;
        background-color: var(--v-inverseGrey-base);
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        border: 1px solid rgba(255, 255, 255, 0.125);

        &:nth-child(1){
            grid-column: 1 / span 3;
            grid-row: 1 / span 3;
        }
        &:nth-child(2){
            grid-column: 1 / span 3;
            grid-row: 4 / span 3;
        }

        &:nth-child(3){
            grid-column: 4 / span 3;
            grid-row: 1 / span 6;
        }

        &:nth-child(4){
            grid-column: 7 / span 4;
            grid-row: 1 / span 6;
        }

        &:nth-child(5){
            grid-column: 11 / span 2;
            grid-row: 1 / span 6;
        }

    }
  
}

#selected-user-data {
    overflow: scroll;
}

.selectedUser-data-point{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: .9em;
}

/* XS */
@media (max-width: 600px){
    #user-analytics-grid {
        grid-template-rows: repeat(8, 1fr);
        min-height: 1500px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 12;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                grid-column: 1 / span 12;
                grid-row: 2 / span 1;
            }

            &:nth-child(4){
                grid-column: 1 / span 12;
                grid-row: 3 / span 1;
            }

            &:nth-child(3){
                grid-column: 1 / span 12;
                grid-row: 4 / span 2;
            }

            &:nth-child(5){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 6 / span 3;
            }
        }
    }
    
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    #user-analytics-grid{
        height: 700px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 7;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                grid-column: 7 / span 6;
                grid-row: 5 / span 2;
            }

            &:nth-child(4){
                grid-column: 1 / span 7;
                grid-row: 2 / span 3;
            }

            &:nth-child(3){
                // display: none;
                grid-column: 1 / span 6;
                grid-row: 5 / span 2;
            }

            &:nth-child(5){
                grid-column: 8 / span 5;
                grid-row: 1 / span 4;
            }
        }
    }
    
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    #user-analytics-grid{
        height: 700px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 7;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                grid-column: 7 / span 6;
                grid-row: 5 / span 2;
            }

            &:nth-child(4){
                grid-column: 1 / span 7;
                grid-row: 2 / span 3;
            }

            &:nth-child(3){
                grid-column: 1 / span 6;
                grid-row: 5 / span 2;
            }

            &:nth-child(5){
                grid-column: 8 / span 5;
                grid-row: 1 / span 4;
            }
        }
    }
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    #user-analytics-grid{
        height: 600px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 3;
                grid-row: 1 / span 2;
            }
            &:nth-child(2){
                grid-column: 4 / span 6;
                grid-row: 1 / span 2;
            }

            &:nth-child(4){
                grid-column: 1 / span 5;
                grid-row: 3 / span 4;
            }

            &:nth-child(3){
                grid-column: 6 / span 4;
                grid-row: 3 / span 4;
            }

            &:nth-child(5){
                grid-column: 10 / span 3;
                grid-row: 1 / span 6;
            }
        }
    }
    
}

.grid-item-dark {
     background-color: rgba(17, 25, 40, 0.75) !important;
}

.grid-item-light {
    background-color: rgba(218, 218, 218, 0.75) !important;
}

    
</style>