<template>
    <v-dialog persistent :value="true" max-width="700" overlay-color="grey darken-1" overlay-opacity=".5">
        <v-card color="primary" class="rounded-xl">
            <v-carousel hide-delimiters hide-delimiter-background progress progress-color="white" :continuous="false" class="custom-carousel rounded-xl">
                <v-carousel-item v-for="(scene, i) in scenes" :key="i" :transition="false" :reverse-transition="false">
                    <v-row justify="center" align="center" no-gutters style="height: 100%">
                        <v-col cols="12" style="height: 65%" class="flex-grow-1 white">
                            <v-img contain max-height="90%" min-height="90%" :src="scene.src"></v-img>
                        </v-col>
                        <v-col cols="12" style="height: 40%" class="flex-shrink-1">
                            <v-card-title style="word-break: normal;" class="justify-center align-center">
                                <span class="text-h5 font-weight-bold accent--text text-center mb-n5">{{scene.title}}</span>
                                <!-- <span class="text-subtitle-2 font-weight-medium white--text text-center">{{scene.text}}</span> -->
                                <!-- <v-btn v-if="scene.slide === 5" text color="accent" @click.stop="$emit('tour-complete')" style="position: absolute; bottom:1%; right:0;" >DONE</v-btn> -->
                            </v-card-title>
                            <v-card-title style="word-break: normal;" class="justify-center align-center">
                                <span class="text-subtitle-2 font-weight-medium white--text text-center">{{scene.text}}</span>
                                <v-btn v-if="scene.slide === 5" text color="accent" @click.stop="$emit('tour-complete')" style="position: absolute; bottom:1%; right:0;" >DONE</v-btn>
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "WelcomeModal",
    props: [],
    data: function() {
        return {
            scenes: [
                // ADD: slide to show that you need to click the plus button top right
                { 
                    slide: 1,
                    title: "Thanks for using Tawq.in!",
                    text: "This is a brief explaination showing you how to use the service.",
                    src: require('../assets/tour/welcome.png'),
                },
                { 
                    slide: 2,
                    title: "Choose Media and Language.",
                    text: "Upload Audio or Video from your device, or paste in a URL from YouTube. Then choose the language you want to speak.",
                    // ^ reword: video or audio directly from device, or copy and paste a video url from youtube to get started!
                    src: require('../assets/tour/1.png'),
                },
                { 
                    slide: 3,
                    title: "Speak. Caption. And Edit.",
                    text: "Press record and speak, press stop to finish. Your voice will convert and the caption will appear. Press the pencil to edit.",
                    // text: "Connect your mobile device, and start tawqin when you want.",
                    // ^ this needs more info and you don't have to connect your phone anymore.
                    src: require('../assets/tour/2.png'),
                },
                { 
                    slide: 4,
                    title: "Upload your Post.",
                    text: "Allow it to be seen by everyone, or just a group of your friends.",
                    src: require('../assets/tour/3.png'),
                },
                { 
                    slide: 5,
                    title: "Contribute.",
                    text: "Add your voice to other posts or have others add to yours.",
                    // maybe add more info about how to contribute???
                    src: require('../assets/tour/4.png'),
                },
                // Groups???
                // if so...include basic info.
                // groups posts are private and can only be seen by those in a group, ability for private contributions which can only be seen by the admin and uploaded user, admin can make comments on contributions.
                // { 
                //     slide: 6,
                //     title: "Need Help?",
                //     text: "If you ever have questions, just tap the help icon in the sidebar.",
                //     src: require('../assets/tour/9.png'),
                // },
            ],
        };
    },
    methods: {},
}
</script>

<style>
.custom-carousel .v-carousel__controls__item.v-btn.v-btn--active {
  color: #FFC107 !important;
}

.custom-carousel .v-carousel__controls__item.v-btn {
  color: white !important;
}

.v-dialog {
  box-shadow: none !important;
}

</style>