<template>
    <v-row justify="center" align="center" fluid class="ma-0 px-0 pt-14 pt-md-16 pb-8 grey darken-4">
        <v-app-bar app flat fixed style="max-width: 100%; z-index: 9;" class="primary">
            <v-col cols="4" class="ma-0">
                <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'intro'})"></v-img>
            </v-col>
            <v-spacer/>
            <v-col cols="8" align="end" class="ma-0 pa-0">
                <v-btn color="white" outlined class="mr-2 font-weight-normal" @click.stop="buttonClick('login')">Login</v-btn>
                <v-btn color="white" outlined class="mr-2 font-weight-normal" @click.stop="buttonClick('sign-up')">Sign Up</v-btn>
                <v-btn v-if="!isMobile" color="white" outlined class="font-weight-normal vp-btn" @click.stop="buttonClick('view-post')">View a Post</v-btn>
            </v-col>

            <transition v-if="!isMobile && showViewPostIndicator" name="fade">
                <v-row style="position: absolute; bottom: -65px; right: 25px;" class="ma-0 pa-0 transparent rounded-lg">
                    <v-col cols="12">
                        <span class="rounded-xl primary py-1 px-3">Want to see a post for yourself? Click here.</span>
                        <v-icon color="primary" large class="align-self-end">mdi-arrow-up-right-bold</v-icon>
                    </v-col>
                </v-row>
            </transition>
        </v-app-bar>

        <v-row justify="center" :align="isMobile ? 'start' : 'center'" fluid class="ma-0 pa-0 fill-height">
            <v-row v-if="isMobile" justify="space-between" align="center" :style="isMobile ? 'height: 10%' : ''" class="ma-0 pa-0">
                <v-col cols="8" align="start">
                    <span class="rounded-xl primary py-1 px-3">See for yourself!</span>
                    <v-icon color="primary" class="align-self-end" large>mdi-arrow-right-bold</v-icon>
                </v-col>
                <v-col cols="4" align="end">
                    <v-btn color="white" outlined small class="font-weight-normal vp-btn" @click.stop="buttonClick('view-post')">View Post</v-btn>
                </v-col>
            </v-row>

            <!-- :align="isMobile ? 'end' : 'end'" -->
            <v-col cols="12" align="center" :style="isMobile ? 'height: 90%' : ''" :class="isMobile ? '' : 'fill-height'"  class="ma-0 pa-0">
                 <!-- class="ma-0 pa-0 fill-height" -->
                <!-- :style="isMobile ? 'height: 90%' : 'height: 95%'" :class="isMobile ? '' : ''" -->
                <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRj0nTiMVe3l8u4-xK-tsZU9cee0ivRnFg3FZJpiVHC27cLww9CWxeeLLDjMATQIYVNQPQ46pBJYz9W/embed?start=false&loop=false&delayms=3000" frameborder="10px" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">Loading…</iframe>
                <!-- <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRj0nTiMVe3l8u4-xK-tsZU9cee0ivRnFg3FZJpiVHC27cLww9CWxeeLLDjMATQIYVNQPQ46pBJYz9W/pub?start=false&loop=true&delayms=30000" frameborder="10px" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">Loading…</iframe> -->
            </v-col>
        </v-row>

        <v-footer class="primary" absolute>
            <v-row class="d-flex ma-0 pa-0 white--text" justify="center">
                <v-btn style="cursor: default;" :ripple="false" text x-small color="white">tawq.in&nbsp;&copy; {{ new Date().getFullYear() }}&nbsp;<br></v-btn>
                <v-divider vertical color="white"></v-divider>
                <v-btn text x-small color="white" :to="{path: '/privacy'}">{{isMobile ? 'Privacy' : 'Privacy Policy'}}</v-btn>
                <v-divider vertical color="white"></v-divider>
                <v-btn text x-small color="white" :to="{path: '/terms'}">{{isMobile ? 'Terms' : 'Terms and Conditions'}}</v-btn>
            </v-row>
        </v-footer>
    </v-row>
</template>

<script>
export default {
    name: 'Calico',
    metaInfo: {
        title: "FEL - tawqin"
    },
    data: function() {
        return {
            showViewPostIndicator: false,
        }
    },
    methods: {
        buttonClick: function (navigateTo) {
            console.log("buttonClick: ", navigateTo);
            if (navigateTo == "login") {
                this.$router.push({ name: "login" });
            } else if (navigateTo == "sign-up") {
                this.$router.push({ name: "sign-up" });
            } else if (navigateTo == "view-post") {
                this.$router.push({ path: "/post-view?pid=rXm4nZfBwDP9DIeycLzS&postType=public&aid=mZPZULE8MeRWy0unUlJ2" });
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: function(){
        setTimeout(() => {
            this.showViewPostIndicator = false;
        }, 12000);
    },
    mounted: function(){
        setTimeout(() => {
            this.showViewPostIndicator = true;
        }, 2000);
    },
}
</script>

<style>
.vp-btn {
    /* text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-accent-base); */
    text-shadow: 0 0 .1em var(--v-accent-base), 0 0 .1em var(--v-accent-base), 0 0 .1em var(--v-accent-base);
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>