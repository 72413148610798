import axios from "axios";
import firebase from "../../firebaseConfig";
// import fb from "firebase/app";


const groupsPageStore = {
    // namespaced: true,
    state: () => ({
        groupPosts: {}, //  objects holding an array posts for each group indexed by a groupId key. Allow 100 posts at a time.
        lastVisibles: {}, // last visible post loaded for each of the groups
        // groupPostsAreLoading: false,
        selectedGroupId: null,
        selectedGroupIndex: null,
        MAX_LOADED: 40,
        deleteOrRemoveMember: {
            dialog: false, // open or close the removeMemberDialog
            remove: null, // this is used to show either 'Removing' or 'Deleting' in removeMemberDialog
            loading: false, // loading value for removeMemberDialog
            deletedMember: null, // used to refresh the groups posts when done deleting
        },
        deleteOrLeaveGroup: {
            dialog: false, // open or close the leaveGroupDialog
            loading: false, // loading value for leaveGroupDialog
            model: null, // show either 'User' or 'Admin' dialong in leaveGroupDialog
            leaving: null, // show either 'Leaving' or 'Deleting' in leaveGroupDialog
        },
        isLeavingGroup: false,
        leavingGroupId: null,
        groupSettingsDialog: false,
    }),
    mutations: {
        saveRemoveMemberDialog: function(state, payload) {
            state.deleteOrRemoveMember.dialog = payload;
        },
        saveRemoveLoadingAndDeleted: function(state, payload) {
            state.deleteOrRemoveMember.remove = payload.remove;
            state.deleteOrRemoveMember.loading = payload.loading;
            state.deleteOrRemoveMember.deletedMember = payload.deletedMember;
        },
        saveLeaveGroupDialog: function(state, payload) {
            state.deleteOrLeaveGroup.dialog = payload;
        },
        saveModelLeavingAndLoading: function(state, payload) {
            state.deleteOrLeaveGroup.loading = payload.loading;
            state.deleteOrLeaveGroup.model = payload.model;
            state.deleteOrLeaveGroup.leaving = payload.leaving;
        },
        saveIsLeavingGroup: function(state, payload) {
            state.isLeavingGroup = payload;
        },
        saveLeavingGroupId: function(state, payload) {
            state.leavingGroupId = payload;
        },
        saveGroupSettingsDialog: function(state, payload) {
            state.groupSettingsDialog = payload;
        },
        savePostsForGroup: function(state, payload){
            if (payload.totalPostsLoaded + payload.posts.length > state.MAX_LOADED){
                // console.log("the total posts ARE larger than MAX_LOADED, we need to delete some")
                /* if the total posts loaded are > N, we want to clear up some memory. We do this by taking the group with the LEAST amount of posts saved and deleting it 
                   from the groupPosts object, BUT only if that group isn't the current SelectedGroupId 
                */
               // 1. find the group with the least amount of posts loaded that isn't the current selectedGroupId
               var leastAmountId = Object.keys(state.groupPosts)[0];
               if ( leastAmountId === state.selectedGroupId ){ // if this is true, that means that the first key in the object has the least amount of posts. jsut pick the next key.
                    leastAmountId = Object.keys(state.groupPosts)[1];
                }
               var leastAmount = payload.totalPostsLoaded;
               for ( let group in state.groupPosts ){
                   if (state.groupPosts[group].length < leastAmount && state.groupPosts[group].length > 0 && group != state.selectedGroupId){
                    //    console.log("setting the leastAmountId")
                       leastAmountId = group;
                       leastAmount = state.groupPosts[group].length;
                   }
               }
            //    console.log("deleting posts from group id: ", leastAmountId);
               delete state.groupPosts[leastAmountId];
               state.groupPosts[payload.groupId] = payload.posts;
            }
            else {
                // console.log("TOTAL POSTS ARE NOT LARGER THAN MAX_LOADED. saving the posts to the store.")
                state.groupPosts[payload.groupId] = payload.posts;
            }
        },
        setLastVisibleForGroup: function(state, payload){
            state.lastVisibles[payload.groupId] = payload.lastVisible;
        },
        deletePostFromGroup: function(state, payload){
            if (typeof state.groupPosts[payload.groupId] != 'undefined'){
                state.groupPosts[payload.groupId].splice(payload.index, 1);
            }
        },
        addPostToGroup: async function(state, payload){
            if( typeof state.groupPosts[payload.newGroup.groupId] != 'undefined' && state.groupPosts[payload.newGroup.groupId].length > 0 ){
                let updatedPost;
                await firebase.db.collection("groupPosts").doc(payload.postId)
                .get()
                .then((doc) => {
                    if (!doc.exists){
                        return;
                    }
                    updatedPost = doc.data();
                    updatedPost.postDate = doc.data().postDate.toDate().toLocaleDateString();
                })
                .catch((err) => {
                    console.log("Error retrieving updated post document to switch over.", err);
                })
                let indexStartPoint = 0;
                let indexEndPoint = state.groupPosts[payload.newGroup.groupId].length;
                let length = state.groupPosts[payload.newGroup.groupId].length;
                if ( new Date(state.groupPosts[payload.newGroup.groupId][Math.floor(length / 2)].postDate) > new Date(updatedPost.postDate) ){
                    indexStartPoint = Math.round(length / 2);
                }
                else {
                    indexEndPoint = Math.round(length / 2);
                }
                // console.log("index end point: ", indexEndPoint, "index Start point: ", indexStartPoint);
                for (var i = indexStartPoint; i < indexEndPoint; i++ ){
                    if (new Date(state.groupPosts[payload.newGroup.groupId][i].postDate) < new Date(updatedPost.postDate) ){
                        state.groupPosts[payload.newGroup.groupId].splice(i, 0, updatedPost);
                        break;
                    }
                    /* if it is the oldest post in the list then we just add it to the end of the list */
                    else if(i === indexEndPoint - 1 ){
                        state.groupPosts[payload.newGroup.groupId].splice( indexEndPoint, 0, updatedPost);
                    }
                }
            }
            return;
        },
        deleteGroupPost(state, payload){ // we have this mutation as well because it will be called from the profile page on a group post.
            // check if we have the post in the store, if so, delete it.
            // console.log("payload in deleteGroupPost: ", payload)
            if (typeof state.groupPosts[payload.post.groupId] != 'undefined' && state.groupPosts[payload.post.groupId].length > 0){
                let index = state.groupPosts[payload.post.groupId].findIndex((p) => p.postId === payload.post.postId);
                if (index != -1){
                    // console.log("the group post to be deleted has been found.")
                    state.groupPosts[payload.post.groupId].splice(index, 1);
                }
            }
        },
        deleteMemberPostsFromGroup(state, payload){
            console.log("deleteMemberPostsFromGroup: ", payload);
            console.log("state.groupPosts: ", state.groupPosts);
            console.log("state.groupPosts[payload.groupId]: ", state.groupPosts[payload.groupId]);
            if (typeof state.groupPosts[payload.groupId] != 'undefined' && state.groupPosts[payload.groupId].length > 0) {
                // loop through the group posts and remove any post from member being deleted
                let posts = state.groupPosts[payload.groupId];
                posts.forEach((post, index) => {
                    if (post.userId === payload.member.userId){
                        state.groupPosts[payload.groupId].splice(index, 1);
                    }
                });
                console.log("state.groupPosts: ", state.groupPosts[payload.groupId]);
                // let posts = [];
                // state.groupPosts[payload.groupId].forEach((post) => {
                //     if (post.userId != payload.member.userId){
                //         posts.push(post);
                //     }
                // });
                // state.groupPosts[payload.groupId] = posts;
                // console.log("state.groupPosts: ", state.groupPosts[payload.groupId]);
            }
        },
        setSelectedGroupIndex(state,payload){
            state.selectedGroupIndex = payload;
        },
        setSelectedGroupId(state,payload){
            state.selectedGroupId = payload;
        },
        clearState(state){
            let newState = {
                groupPosts: {},
                lastVisibles: {},
                selectedGroupId: null,//state.selectedGroupId,
                selectedGroupIndex: null,//state.selectedGroupIndex,
                MAX_LOADED: 40,
                deleteOrRemoveMember: {
                    dialog: false,
                    remove: null,
                    loading: false,
                    deletedMember: null,
                },
                deleteOrLeaveGroup: {
                    dialog: false,
                    loading: false,
                    model: null,
                    leaving: null,
                },
                isLeavingGroup: false,
                groupSettingsDialog: false,
            }
            Object.assign(state, newState);
            // console.log("state.groupPosts imediatly after resetting: ", state.groupPosts)
        },
        clearMostOfTheState(state){
            let newState = {
                groupPosts: {},
                lastVisibles: {},
                MAX_LOADED: 40,
            }
            Object.assign(state, newState);
            // console.log("GroupsPage state imediatly after resetting: ", state);
        }
    },
    actions: {
        deleteLocalGroup: function(context, payload){
            context.commit('deleteGroup', payload.index);
            context.commit('setSelectedGroupIndex', payload.newIndex);
            context.commit('setSelectedGroupId', payload.groupId);
        },
        deleteMemberPostAndContributionData: async function(context, payload) {
            if (payload.component === 'leaveGroup') context.commit('saveModelLeavingAndLoading', {loading: true, model: null, leaving: (payload.action === 'leaving' ? true : false)});
            else {context.commit('saveRemoveLoadingAndDeleted', {remove: false, loading: true, deletedMember: payload.member,}); /*context.commit('deleteMemberPostsFromGroup', {groupId: payload.groupId, member: payload.member});*/}

            let response = await axios.delete(`groups/user/${payload.groupId}/${payload.member.userId}`, {data: {member: payload.member}});
            if (response.status === 200) {
                if (payload.component === 'leaveGroup'){
                    context.commit('saveModelLeavingAndLoading', {loading: false, model: null, leaving: null});
                    context.commit('saveLeaveGroupDialog', false);
                    context.commit('saveGroupSettingsDialog', false);
                    context.commit('saveLeavingGroupId', null);
                    context.commit('saveIsLeavingGroup', false);
                } else {
                    context.commit('saveRemoveLoadingAndDeleted', {remove: null, loading: false, deletedMember: null,});
                    context.commit('saveRemoveMemberDialog', false);
                    if (payload.action === 'delete') {
                        context.commit('alertUser', { show: true, text: `Member deleted`, type: 'snackbar' });
                    }
                }
            } else context.commit('alertUser', { show: true, text: `Something went wrong.`, type: 'snackbar' });
        },

        removeUserDataFromGroup: async function(context, payload) {
            if (payload.action === 'remove') context.commit('saveRemoveLoadingAndDeleted', {remove: true, loading: true, deletedMember: null,});
            let response = await axios.delete(`groups/${payload.groupId}/${payload.userId}`, {data: {group: payload.group}});
            if (response.status == 200) {
                if (payload.action === 'remove') {
                    context.commit('saveRemoveLoadingAndDeleted', {remove: null, loading: false, deletedMember: null,});
                    context.commit('saveRemoveMemberDialog', false);
                    context.commit('alertUser', { show: true, text: `Member removed`, type: 'snackbar' });
                } else if (payload.action === 'cancel') {
                    // cancel request in GroupSettings or Appbar
                    context.commit('alertUser', { show: true, text: `Request canceled`, type: 'snackbar' });
                } else if (payload.action === 'request') {
                    // user cancels their own request to join group
                    context.commit('saveLeavingGroupId', null);
                    context.commit('saveIsLeavingGroup', false);
                    context.commit('alertUser', { show: true, text: `Request canceled`, type: 'snackbar' });
                    context.commit('saveGroupSettingsDialog', false);
                } else if (payload.action === 'reject') {
                    // reject request in GroupSettings or Appbar
                    context.commit('alertUser', { show: true, text: `Request rejected`, type: 'snackbar' });
                }
            } else {
                context.commit('alertUser', { show: true, text: `Something went wrong.`, type: 'snackbar' });
            }
        },

        setPostsForGroup: function(context, payload){
            // pass in the length of total posts the store has
            var length = 0;
            for ( let group in context.state.groupPosts ){
                length += context.state.groupPosts[group].length;
            }
            payload['totalPostsLoaded'] = length;
            // payload['totalPostsLoaded'] = context.getters.totalPostsLoaded;
            context.commit('savePostsForGroup', payload);
        },
        setLastVisibleForGroup: function(context, payload){
            context.commit('setLastVisibleForGroup', payload);
        },
        deletePostFromGroup: function(context, payload){
            context.commit('deletePostFromGroup', payload);
        },
        movePostToAnotherGroup: function(context, payload){
            // G -> P | newGroupId will be null
            // P -> G | oldGroupId will be null
            // G -> G | neither will be new
            if(payload.fromType === 'group' && payload.toType === 'public'){
                // if we are going from group to public then check to see if the group has posts loaded in the store. if so delete it and return.
                if ( typeof context.state.groupPosts[payload.oldGroupId] != 'undefined' && context.state.groupPosts[payload.oldGroupId].length > 0 ){
                    let oldGroupIndex = context.state.groupPosts[payload.oldGroupId].findIndex((p) => p.postId === payload.postId);
                    if (oldGroupIndex != -1){
                        context.commit( 'deletePostFromGroup', { groupId: payload.oldGroupId, index: oldGroupIndex } );
                    }
                }
                return;
            }
            else if ( payload.fromType === 'public' && payload.toType === 'group' ){
                // if we are going from public to group: check to see if we have group posts saved for the group the post is being transferred to. if so then we will add it to the posts by date
                if( typeof context.state.groupPosts[payload.newGroup.groupId] != 'undefined' && context.state.groupPosts[payload.newGroup.groupId].length > 0 ){
                    context.commit('addPostToGroup', payload);
                }
                return;
            }
            else if ( payload.fromType === 'group' && payload.toType === 'group' ) {
                // if neither of the groups have posts saved
                if ( typeof context.state.groupPosts[payload.newGroup.groupId] === 'undefined' && typeof context.state.groupPosts[payload.oldGroupId] === 'undefined' ){
                    return;
                }
                // if new group doesn't haven't saved posts but the old group does
                else if ( typeof context.state.groupPosts[payload.newGroup.groupId] === 'undefined' && typeof context.state.groupPosts[payload.oldGroupId] != 'undefined' ){
                    // todo - just delete the post from the store from the old group
                    if (context.state.groupPosts[payload.oldGroupId].length > 0){
                        let oldGroupIndex = context.state.groupPosts[payload.oldGroupId].findIndex((p) => p.postId === payload.postId);
                        if (oldGroupIndex != -1){
                            context.commit( 'deletePostFromGroup', { groupId: payload.oldGroupId, index: oldGroupIndex } );
                        }
                    }
                }
                // if new group does have posts saved to store and old group does too
                else if ( typeof context.state.groupPosts[payload.newGroup.groupId] != 'undefined' && typeof context.state.groupPosts[payload.oldGroupId] != 'undefined' ){
                    // todo - add the post to the store in for the new group and delete it from the old group
                    if ( context.state.groupPosts[payload.newGroup.groupId].length > 0 ){
                        context.commit('addPostToGroup', payload);
                    }
                    if ( context.state.groupPosts[payload.oldGroupId].length > 0 ){
                        let oldGroupIndex = context.state.groupPosts[payload.oldGroupId].findIndex((p) => p.postId === payload.postId);
                        if (oldGroupIndex != -1){
                            context.commit( 'deletePostFromGroup', { groupId: payload.oldGroupId, index: oldGroupIndex } );
                        }
                    }
                }
                // if new group has posts saved and old group doesn't 
                else {
                    // todo - just add the post to the store for the new group
                    if ( context.state.groupPosts[payload.newGroup.groupId].length > 0 ){
                        context.commit('addPostToGroup', payload);
                    }
                }
            }
            return;
        },
        // setSelectedGroupId: function({commit}, id){
        //     commit('saveSelectedGroupId', id);
        // },
        clearGroupsPageStoreState({commit}){
            /* this method will reset the data being stored for the groupsPageStore */
            commit('clearState');
        },
        resetGroupsPageStoreState({commit}){
            /* reset MOST of the data to clear space but we dont want to clear the state that relates to deleting/leaving or removing members from a group */
            commit('clearMostOfTheState');
        },
    },
    getters: {
       
    }
}

export default groupsPageStore