<template>
<div class="py-0 px-4 ma-0">
    <v-row justify="center">
        <p class="text-md-h3 text-h4 font-weight-light text-center justify-self-center">Manage Subscription</p>
    </v-row>
    <v-row v-if="displayErrors" justify="center" class="mt-4" >
        <div class="red--text text--lighten-2 font-italic text-caption">*{{errors}}</div>
    </v-row>
    <v-row v-if="loading" justify="center" class="py-4">
        <!-- <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular> -->
        <v-col xl="5">
            <v-skeleton-loader max-height="300" min-height="100" type="article"></v-skeleton-loader>
        </v-col>
    </v-row>

    <!-- container for account overview and edit states -->
    <v-container v-else-if="!loading" class="pa-0 ma-0"> 
        <v-row  justify="center" class="py-4" >
            <v-col xl="5" lg="7" md="8" sm="10" xs="12">
                <v-row v-if="payBillButton && !loading" justify="end" class="my-4 px-4" >
                    <v-btn small depressed class="primary" @click="payBill()">Pay Bill</v-btn>
                </v-row>
                <v-sheet outlined class="px-4 py-2 elevation-1">
                    <v-row class="px-4 pb-2" justify="space-between">
                        <div class="text-h6">Account</div>
                        <div class="d-flex flex-row d-inline align-center">
                            <v-icon size="13" :class="[ statusColor === 'y' ?  'yellow--text text--darken-1' : statusColor === 'g' ? 'primary--text' : statusColor === 'r' ? 'red--text text--darken-2' : '' ]" class="mr-1">mdi-checkbox-blank-circle</v-icon>
                            <div :class="[ statusColor === 'y' ?  'yellow--text text--darken-1' : statusColor === 'g' ? 'primary--text' : statusColor === 'r' ? 'red--text text--darken-2' : '' ]">{{statusConversion(stripeCustomer.subscription.status)}}</div>
                        </div>
                    </v-row>
                    <!-- CUSTOMER ACCOUNT SUMMARY -->
                    <v-row class="px-4" justify="space-between" >
                        <div class="text-subtitle-1">Current Subscription</div>
                        <div :class="[updatedSubscription ? 'updated-info accent--text' : '' ]" class="text-subtitle-1" v-if="stripeCustomer.product && stripeCustomer.subscription.plan" >{{stripeCustomer.product.name}}/{{stripeCustomer.subscription.plan.nickname}}</div>
                        <div class="text-subtitle-1" v-else>---</div>
                    </v-row>
                    <v-row class="px-4" justify="space-between" >
                        <div class="text-subtitle-1">Start Date</div>
                        <div class="text-subtitle-1" v-if="stripeCustomer.subscription">{{dateConversion(stripeCustomer.subscription.created)}}</div>
                        <div class="text-subtitle-1" v-else>---</div>
                    </v-row>
                    <v-row class="px-4" justify="space-between" >
                        <div class="text-subtitle-1">Next Billing Date</div>
                        <div class="text-subtitle-1" v-if="stripeCustomer.subscription">{{dateConversion(stripeCustomer.subscription.current_period_end)}}</div>
                        <div class="text-subtitle-1" v-else>---</div>
                    </v-row>
                    <v-row class="px-4" justify="space-between">
                        <div class="text-subtitle-1 ">Payment Method</div>
                        <div :class="[updatedPayment ? 'updated-info accent--text' : '' ]" class="text-subtitle-1 " v-if="stripeCustomer.payment_method">{{capitalizeFirstLetter(stripeCustomer.payment_method.card.brand)}} **** {{stripeCustomer.payment_method.card.last4}}</div>
                        <div v-else class="text-subtitle-1">---</div>
                    </v-row>
                    <!-- <v-row class="px-4" justify="space-between">
                        <div class="text-subtitle-1 ">Invoices</div>
                        
                    </v-row> -->

                    <!-- CUSTOMER EDIT OPTIONS -->
                    <v-divider class="my-4"></v-divider>
                    <v-row class="px-4" justify="space-between">
                        <div class="customer-options" :class="[editType === 'invoices' ? 'accent--text' : '']" @click="editSubscription('invoices')">View Invoices</div>
                    </v-row>

                    <v-row class="px-4" justify="space-between">
                        <div v-if="stripeCustomer.payment_method" :class="[editType === 'payment_method' ? 'accent--text' : '']" class="text-subtitle-1 customer-options" @click="editSubscription('payment_method')">Change Payment Method</div>
                        <div v-else :class="[editType === 'payment_method' ? 'accent--text' : '']" class="text-subtitle-1 customer-options"  @click="editSubscription('payment_method')">Add Payment Method</div>
                    </v-row>

                    <v-row class="px-4" justify="space-between">
                        <div :class="[editType === 'subscription_plan' ? 'accent--text' : '']" class="text-subtitle-1 customer-options"  @click="editSubscription('subscription_plan')">Change Subscription</div>
                    </v-row>

                    <v-row class="px-4" justify="space-between">
                        <div v-if="stripeCustomer.subscription && hasSubscription() && stripeCustomer.product.name != 'Basic Plan'" :class="[editType === 'cancel_subscription' ? 'accent--text' : '']" class="text-subtitle-1 customer-options"  @click="editSubscription('cancel_subscription')">Cancel Subscription</div>
                    </v-row>
                    
                
                </v-sheet>
            </v-col>
                <!--    MODAL TO ASK IF USER WANTS TO CHARGE CURRENT CARD OR NEW CARD ON UNPAID INVOICE     -->
            <v-dialog v-model="askUserWhichCardDialog" :overlay-color="'$vuetify.theme.dark' ? 'grey' : ''"  persistent width="320" max-width="500" overlay-opacity=".9">
                <v-card>
                    <v-card-title>Payment Information</v-card-title>
                    <v-card-text>Your current payment method on file was declined when trying to charge for your last invoice.</v-card-text>
                    <v-card-text>If this was due to insufficient funds, and you now have those funds, you can use your current payment method to pay the outstanding balance.</v-card-text>
                    <v-card-actions class="d-flex flex-row justify-space-between">
                        <v-btn depressed small text class="primary--text" @click="payOutstandingInvoice()">Use Current</v-btn>
                        <v-btn depressed small text class="primary--text" @click="handlePaymentThatRequiresPaymentMethod()">Add New</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

        </v-row>

    

        <v-row justify="center" class="py-4" >
            <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" >
                <transition name="slidey" enter-active-class="slideDown" leave-active-class="slideUp" appear>
                    <EditPaymentMethod v-if="editType === 'payment_method' && stripeCustomer.payment_method" :type="'edit'"  @updateCustomer="updatePaymentMethodOnCustomer"/>
                    <EditPaymentMethod v-else-if="editType === 'payment_method' && !stripeCustomer.payment_method " :type="'add'"  @updateCustomer="updatePaymentMethodOnCustomer"/>
                    <EditPaymentMethod v-else-if="addNewPaymentMethod" :type="'add'" @updateCustomer="payOutstandingInvoice" />
                </transition>
                <transition name="slidey" enter-active-class="slideDown" leave-active-class="slideUp" appear>
                    <ChangeSubscription v-if="editType === 'subscription_plan'"  @updateSubscription="updateSubscriptionOnCustomer" @updatePaymentMethod="updatePaymentMethodOnCustomer"/>
                </transition>
                <transition name="slidey" enter-active-class="slideDown" leave-active-class="slideUp" appear>
                    <CancelSubscription v-if="editType === 'cancel_subscription' && stripeCustomer.product.name != 'Basic Plan'"  @keep="keepSubscription" @updateSubscription="updateSubscriptionOnCustomer" />
                </transition>

                <transition name="slidey" enter-active-class="slideDown" leave-active-class="slideUp" appear>
                    <Invoices v-if="viewInvoices && editType === 'invoices'" :invoices="stripeCustomer.invoices" />
                </transition>

                <!-- <transition name="slidey" enter-active-class="slideDown" leave-active-class="slideUp" appear>
                    <Invoices v-if="viewInvoices && editType === 'invoices'" :invoices="stripeCustomer.invoices" />
                </transition> -->
            </v-col>
        </v-row>
        
    </v-container>


    

</div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
// import firebase from "../../firebaseConfig.js";
import EditPaymentMethod from "../components/EditPaymentMethod.vue";
import ChangeSubscription from "../components/ChangeSubscription.vue";
import CancelSubscription from "../components/CancelSubscription.vue";
import Invoices from "../components/Invoices.vue";
export default {
    name: 'manageSubscription',
    metaInfo: {
        title: "Manage Account - tawqin"
    },
    props: ["product"],
    components: { 
        EditPaymentMethod,
        ChangeSubscription,
        CancelSubscription,
        Invoices
    },
    data: function() {
        return {
            stripe: null,
            loading: true,
            editAccount: false,
            editType: "",
            updatedPayment: false,
            updatedSubscription: false,
            timeout: null,
            status: "",
            statusColor: "",
            viewInvoices: false,
            payBillButton: false,
            lastInvoice: null,
            displayErrors: false,
            errors: "",
            failedPaymentIntent: null,
            addNewPaymentMethod: false,
            askUserWhichCardDialog: false,
        }
    },
    methods: {
        async getStripeStatus(){
            /* check if customer, if not create a new one with a subscription */
            if (this.userData.stripeCustomerId){
                this.getStripeCustomer();
            }
        },
        getStripeCustomer(){
            this.$http.get(`stripe/customer/${this.userData.stripeCustomerId}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Couldn't retrieve customer account.`, type: 'snackbar' });
                } else {
                    this.$store.dispatch('setStripeCustomer', response.data.customer);
                    if ( response.data.customer.subscriptions.total_count > 0){ // there may not be a subscription
                        this.getStripeSubscription(response.data.customer.subscriptions.data[0].id);
                    }
                    else {
                        this.status = "No Subscription";
                        this.loading = false;
                    }
                    this.getStripeInvoices(response.data.customer.id);
                    if(response.data.customer.invoice_settings.default_payment_method != null){
                        this.getDefaultPaymentMethod(response.data.customer.invoice_settings.default_payment_method);
                    }
                }
            })
            .catch((error) => {
                console.error("Error getting stripe customer: ", error);
            })
        },
        getStripeSubscription(subscription_id){
            this.$http.get(`stripe/subscriptions/${subscription_id}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                }
                this.$store.dispatch('setStripeSubscription', response.data.subscription);
                this.statusConversion(this.stripeCustomer.subscription.status);
                this.getStripeProduct(response.data.subscription.plan.product);
            })
            .catch((error) => {
                console.error("the error retrieving the stripe subscription is: ", error);
            })
        },
        getStripeInvoices(customer_id){
            this.$http.get(`stripe/invoices/${customer_id}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                }
                this.stripeCustomer.invoices = response.data.invoices;
            });
        },
        getDefaultPaymentMethod(payment_id){
            this.$http.get(`stripe/payment-method/${payment_id}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                }
                this.$store.dispatch('setStripePaymentMethod', response.data.payment_method);
            })
            this.$forceUpdate();
        },
        getStripeProduct(product_id){
            this.$http.get(`stripe/products/${product_id}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                }
                this.stripeCustomer.product = response.data.product;
                this.loading = false;
            })
        },
        getStripePaymentIntent(id){
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.$http.get(`stripe/payment-intent/${id}`)
                .then((response) => {
                    if (response.data.error){
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                    }
                    this.failedPaymentIntent = response.data.intent;
                    this.loading = false;
                    resolve();
                })
                .catch((error) => {
                    console.error(error.message);
                    this.loading = false;
                    this.$store.commit('alertUser', { show: true, text: `Error finding your bill, try again.`, type: 'snackbar' });
                    reject();
                })
            })
        },
        editSubscription(type){
            /* switch to edit account mode. if the user clicks on the same edit button, we want to close the component so we set edit account to false and reset the edit type */
            this.editAccount = true;
            switch(type){
                case 'payment_method':
                    this.viewInvoices = false;
                    if(this.editType == type){
                        this.editAccount = false;
                        this.editType = "";
                        return;
                    }
                    this.editType = "payment_method";
                    break;

                case 'subscription_plan':
                    this.viewInvoices = false;
                    if(this.editType == type){
                        this.editAccount = false;
                        this.editType = "";
                        return;
                    }
                    this.editType = "subscription_plan";
                    break;

                case 'cancel_subscription':
                    this.viewInvoices = false;
                    if(this.editType == type){
                        this.editAccount = false;
                        this.editType = "";
                        return;
                    }
                    this.editType = "cancel_subscription";
                    break;

                case 'invoices':
                    this.viewInvoices = true;
                    if(this.editType == type){
                        this.editAccount = false;
                        this.editType = "";
                        return;
                    }
                    this.editType = "invoices";
                    break;

                default:
                    this.editType = "";
                    this.editAccount = false;
                    return;
            }
            return;
        },
        updatePaymentMethodOnCustomer(){
            this.$store.commit('alertUser', { show: true, text: `Successfully Updated Payment Method.`, type: 'snackbar' });
            this.updatedPayment = true;
            this.editAccount = false;
            this.editType = "";
            this.$forceUpdate();
        },
        updateSubscriptionOnCustomer(data){
            if (data.type === 'cancel'){
                this.$store.commit('alertUser', { show: true, text: `Successfully Canceled Your Subscription. Free Basic Plan Enabled`, type: 'snackbar' });
            }
            else if (data.type === 'edit'){
                 this.$store.commit('alertUser', { show: true, text: `Successfully Changed The Subscription.`, type: 'snackbar' });
                this.setIsBillPaid();
            }
            else {
                 this.$store.commit('alertUser', { show: false, text: ``, type: '' });
            }
            this.updatedSubscription = true;
            this.editAccount = false;
            this.editType = "";
            this.$forceUpdate();
        },
        setIsBillPaid(){
            // if subscription status === past due, then enable the button and find the last_invoice object
            if (this.stripeCustomer.subscription.status === 'past_due'){ //TODO: handle other scenarios
                this.payBillButton = true;
            }
            else {
                return;
            }
            // if(this.stripeCustomer.invoices.data.length > 0){
            //     this.stripeCustomer.invoices.data.forEach((invoice) => {
            //         if (invoice.id === this.stripeCustomer.subscription.latest_invoice){
            //             this.lastInvoice = invoice;
            //         }
            //     })
            // }
            
        },
        async payBill(){
            /* determine whether the bill is past due, requires authentication or requires payment method */
            // 1. get paymentintent
            let paymentIntent = this.stripeCustomer.invoices.data[0] ? this.stripeCustomer.invoices.data[0].payment_intent : this.stripeCustomer.subscription.latest_invoice.payment_intent;
            await this.getStripePaymentIntent(paymentIntent);
            // 2. check why the payment intent failed
            switch(this.failedPaymentIntent.status){
                case 'requires_action':
                    
                   /* Notify the customer that authentication is required
                       Complete authentication using stripe.ConfirmCardPayment */
                    this.handlePaymentThatRequiresCustomerAction();
                    break;
                
                case 'requires_payment_method':
                    /*  Notify the customer, collect new payment information, and create a new payment method
                        Attach the payment method to the customer
                        Update the default payment method
                        Pay the invoice using the new payment method
                    */
                    this.askUserWhichCardDialog = true;
                    // this.handlePaymentThatRequiresPaymentMethod();
                    break;

                case 'succeeded':
                    this.$store.commit('alertUser', { show: true, text: `No unpaid bill.`, type: 'snackbar' });
                    this.payBillButton = false;
                    break;

                default:
                    this.$store.commit('alertUser', { show: true, text: `Cannot find unpaid bill.`, type: 'snackbar' });
                    this.payBillButton = false;
                    break;
            }

        },
        async handlePaymentThatRequiresCustomerAction(){
            this.stripe = await Stripe('pk_test_51HtI2rC3oDEbxLPSnM56fK0ldrHxhS48Z2EbKStM51enTdrclGA9H9Ae1EzOiYHh9uzZ79Ox4oFNtQeQbslrrDWT0093vBQxra');
            this.stripe.confirmCardPayment(this.failedPaymentIntent.client_secret, {
                payment_method: this.stripeCustomer.payment_method.id
            })
            .then((result) => {
                if (result.error){
                    console.error("Error confirming card payment that requires customer action.", result.error);
                    this.displayErrors = true;
                    this.errors = result.error;
                }
                else {
                    if ( result.paymentIntent.status === 'succeeded' ){
                        this.$store.dispatch('getStripeSubscription', this.stripeCustomer.subscription.id)
                        this.$store.dispatch('getStripeInvoices', this.stripeCustomer.customer.id);
                        this.$store.commit('alertUser', { show: true, text: `Successfully paid your outstanding bill.`, type: 'snackbar' });
                    }
                }
            })
            .catch((error) => {
                console.error("Error confirming card payment that requires customer action.", error);
            })
        },
        async handlePaymentThatRequiresPaymentMethod(){
            this.askUserWhichCardDialog = false;
            this.addNewPaymentMethod = true;
            this.displayErrors = true;
            this.errors = "Your payment method needs to be updated. Please set up a new payment method to pay your bill. This new payment method will become your default.";
        },
        payOutstandingInvoice(){
            this.askUserWhichCardDialog = false;
            this.updatedPayment = true;
            this.editAccount = false;
            this.editType = "";
            // the default payment has been updated, now just use that new payment method to pay the outstanding bill
            this.$http.post(`stripe/pay-invoice`, {
                customerId: this.stripeCustomer.customer.id,
                paymentMethodId: this.stripeCustomer.payment_method.id,
                invoiceId: this.failedPaymentIntent.invoice
            })
            .then((response) => {
                if(response.data.error){
                    this.dispalyErrors = true;
                    this.error = `Error processing payment: ${response.data.error}`;
                    return;
                }
                this.addNewPaymentMethod = false;
                this.$store.commit('alertUser', { show: true, text: `Successfully paid your outstanding bill.`, type: 'snackbar' });
                this.displayErrors = false;
                this.payBillButton = false;
                // this.$store.dispatch('getStripeSubscription', this.stripeCustomer.subscription.id);
                this.$store.dispatch('getStripeInvoices', this.stripeCustomer.customer.id);
                // console.log("paid the outstanding bill, ", response.status);
            })
            .catch((error) => {
                this.addNewPaymentMethod = false;
                this.$store.commit('alertUser', { show: true, text: `Error paying your outstanding bill. ${error.message}`, type: 'snackbar' });
                console.error("Error paying the outstanding invoice.", error);
            })
        },
        keepSubscription(){
            this.editType = "";
            this.editAccount = false;
            this.$forceUpdate();
        },
        capitalizeFirstLetter(string) {
            let tempString = string.toLowerCase();
            return tempString.charAt(0).toUpperCase() + tempString.slice(1);
        },
        hasSubscription(){
            if (Object.keys(this.stripeCustomer.subscription).length != 0){
                return true;
            }
            return false;
        },
        dateConversion(seconds){
            var date = new Date( seconds * 1000) // javascript's date object is in milliseconds, not seconds. Stripe sends us seconds since unix epoch.
            // return `${date.getMonth() + 1}/${date.getFullYear()}`
            date = date.toLocaleString().split(',')[0];
            if (date === "Invalid Date"){
                date = "---";
            }
            return date.toLocaleString().split(',')[0];
        },
        statusConversion(status){
            // console.log("in converting status, status: ", status)
            // yellow = y | green = g | red = r
            let s;
            let color;
            switch(status){
                case 'incomplete':
                    s = "Incomplete";
                    color = "y";
                    break;
                case 'incomplete_expired':
                    s = "Expired";
                    color = "r";
                    break;
                case 'trialing':
                    s = "Active Trial";
                    color = "g";
                    break;
                case 'active':
                    s =  "Active";
                    color = "g";
                    break;
                case 'past_due':
                    s = "Past Due";
                    color = "r";
                    break;
                case 'canceled':
                    s = "Cancelled";
                    color = "r";
                    break;
                case 'unpaid':
                    s = "Unpaid";
                    color = "r";
                    break;
                default:
                    s = "No subscription"
                    color = "y";
                    break;
            }
            // this.status = s;
            this.statusColor = color;
            return s;
        },
        updateClassReset(){
            this.updatedPayment = false;
            this.updatedSubscription = false;
        },
    },
    updated: function() {
        this.timeout = setTimeout(this.updateClassReset, 400);
    },
    created: function(){
        // this.stripeCustomer = this.storeStripeCustomer;
        if (typeof this.stripeCustomer.customer != 'undefined'){
            this.statusConversion(this.stripeCustomer.subscription.status);
            // this.getStripeInvoices(this.stripeCustomer.customer.id);
            // if(this.stripeCustomer.customer.invoice_settings.default_payment_method != null){
            //     console.log("getting default payment method");
            //     this.getDefaultPaymentMethod(this.stripeCustomer.customer.invoice_settings.default_payment_method);
            // }
            this.loading = false;
        }
        else {
            this.getStripeStatus();
        }
        /*  we send a query param to manage-subscription if the user has clicked to subscripe to a plan. if this is undefined, they got to the page via clicking on manage subscription somewhere */
        // if (typeof this.product != 'undefined'){
        //     this.editSubscription('subscription_plan'); 
        // }
      
    },
    computed: {
        stripeCustomer() {
            return this.$store.state.stripeCustomer;
        },
        userData() {
            return this.$store.state.userData;
        }
    },
    mounted: function() {
        this.setIsBillPaid();
    },
    beforeDestroy: function() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>

.customer-options:hover{
    color: var(--v-accent-base) !important;
    cursor: pointer;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
        z-index: 0;
    }
    to {
        opacity: 1;
        z-index: 1;
    }
}
.slideDown {
    animation-name: slideDown;
    animation-duration: .5s;
}

@keyframes slideUp {
    from {
        opacity: 1;
        z-index: 0;
    }
    to {
        opacity: 0;
        transform: translateY(-50px);
        z-index: 0;
    }
}
.slideUp {
    animation-name: slideUp;
    animation-duration: .1s;
}

@keyframes updatedInfoAnimation {
    
    50% {
        transform: translateY(-4px);
    }
    
}

.updated-info {
    animation-name: updatedInfoAnimation;
    animation-duration: .3s;
}

</style>