<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelChangePostDialog">
        <v-card v-if="!loading" class="rounded-xl">
            <!-- <div v-if="!loading" class="ma-0 pa-0" style="height: 100%; width: 100; top: 0; left: 0;">
                <v-overlay absolute color="grey darken-2">
                    <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                </v-overlay>
            </div> -->
            <v-card-title style="word-break: normal" class="justify-center align-center">
                <span v-if="type != dialogTypeEnum.TO_ANOTHER_GROUP" class="mb-2 font-weight-bold text-h5 text-center">Change from {{post.postType[0].toUpperCase() + post.postType.slice(1)}} to {{ post.postType === 'group' ? 'Public' : 'Group'}}</span>
                <span v-else class="mb-2 font-weight-bold text-h5 text-center">Move Post To Another Group</span>
            </v-card-title>

            <v-row justify="center" class="mx-0">
                    <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                        <span v-if="post.postType === 'public'" class="font-weight-light text-subtitle-2 text-center">
                            Your post <b><i>{{post.title}}&nbsp</i></b> is currently a Public post; meaning that all tawq.in users are able to see and contribute to it. 
                            If you change the post to a Group post, only members of that group will be able to view and contribute to the post.
                        </span>
                        <span v-else-if="post.postType === 'group' && type != dialogTypeEnum.TO_ANOTHER_GROUP" class="font-weight-light text-subtitle-2 text-center">
                            Your post <b><i>{{post.title}}&nbsp</i></b> is currently a Group post; meaning that only members of the group are able to see and contribute to it. If you
                            change the post to a Public post, all tawqin users will be able to view and contribute to the post.
                        </span>
                        <span v-else-if="post.postType === 'group' && type === dialogTypeEnum.TO_ANOTHER_GROUP" class="font-weight-light text-subtitle-2 text-center">
                            Your post <b><i>{{post.title}}&nbsp</i></b> is currently a Group post; belonging to the group <b><i>{{post.groupName}}.</i></b> By changing the group to which the post belongs to, the group 
                            <b><i>{{post.groupName}}&nbsp</i></b> will not be able to see this post anymore.
                        </span>
                        <v-select v-if="post.postType === 'public' || type === dialogTypeEnum.TO_ANOTHER_GROUP" v-model="groupToSwitchPostTo" :items="userGroups" item-text="groupName" item-value="groupId" persistent-hint label="Group to share post with" return-object no-data-text="No Groups to Select"></v-select>
                    </v-col>
            </v-row>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click.stop="cancelChangePostDialog">Cancel</v-btn>
                    <v-btn text :color="$vuetify.theme.dark ? 'amber' : 'primary'" :disabled="(post.postType === 'public' || type === dialogTypeEnum.TO_ANOTHER_GROUP) && groupToSwitchPostTo === null" @click.stop="changePostType()">Change</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span v-if="groupToSwitchPostTo === null" class="font-weight-bold text-h5 text-center">Changing post to <b>{{post.postType === 'group' ? 'Public' : 'Group'}}</b></span>
                <span v-else class="font-weight-bold text-h5 text-center">Changing post to <b>{{groupToSwitchPostTo.groupName}}</b></span>
            </v-card-title>
            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-bold text-subtitle-2 text-center">Please wait...</span><br/>
                    <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: "changePost",
    props: ["post", "dialog", "type", "groupsPage", "dialogTypeEnum"],
    data: function() {
        return {
            loading: false,
            groupToSwitchPostTo: null,
        };
    },
    methods: {
        changePostType(){
            this.loading = true;
            /* Step 1: let the server do the work so that the client cant refresh the page and 
                    completely lose their post or duplicate it between group and public. If it is a public post switching
                    to a group post, send in groupToSwitchPostTo. If we are just moving the post to another group,
                    all we need to do is change groupName and groupId in the groupPosts collection
            */
            if (this.type === this.dialogTypeEnum.TO_ANOTHER_GROUP) {
                firebase.db.collection("groupPosts").doc(this.post.postId).update({
                    groupId: this.groupToSwitchPostTo.groupId,
                    groupName: this.groupToSwitchPostTo.groupName,
                }).then(() => {
                    this.loading = false;
                    if (this.groupsPage) {
                        this.$emit('move-to-another-group', { postId: this.post.postId, oldGroupId: this.post.groupId, newGroup: this.groupToSwitchPostTo, fromType: this.post.postType, toType: 'group' }); 
                    } else {
                        this.$emit('retrieve-posts-again');
                        this.$emit('move-to-another-group', { postId: this.post.postId, oldGroupId: this.post.groupId, newGroup: this.groupToSwitchPostTo, fromType: this.post.postType, toType: 'group' });
                    }
                    this.cancelChangePostDialog();
                }).catch((err) => {
                    console.log("error moving the group post to a new group: ", err)
                    this.loading = false;
                })

            } else {
                let body;
                if (this.post.postType === 'public'){
                    body = {
                        switchToType: 'group',
                        group: this.groupToSwitchPostTo,
                    }
                } else {
                    body = {
                        switchToType: 'public',
                        group: null,
                    }
                }
                this.$http.put(`changePostTypes/${this.post.postId}`, body).then((response) => {
                    this.loading = false;
                    if (response.status === 200) {
                        this.$emit('retrieve-posts-again');
                        /* we need to emit move-to-another-group here because if there are posts loaded for the group in the store they need to have the updated data */
                        if ( this.post.postType === 'public' ){
                            this.$emit('move-to-another-group', { postId: this.post.postId, oldGroupId: null, newGroup: this.groupToSwitchPostTo, fromType: this.post.postType, toType: 'group' }); 
                        } else if ( this.post.postType === 'group' ){
                            this.$emit('move-to-another-group', { postId: this.post.postId, oldGroupId: this.post.groupId, newGroup: null, fromType: this.post.postType, toType: 'public' }); 
                        }
                    }
                    this.cancelChangePostDialog();
                }).catch((err) => {
                    console.log("Error in contacting the server to change the post type: ", err);
                    this.loading = false;
                })
            }
        },
        cancelChangePostDialog: function () {
            if (!this.loading) {
                this.$emit('close-dialog')
            }
        },
    },
    computed: {
        userGroups(){
            return this.$store.state.userGroups.filter( group => group.groupId != this.post.groupId); // only return the groups that aren't the group that post is currently apart of. if it is a public post this will be null so it will return the whole array
        },
    },
}
</script>