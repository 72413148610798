import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import firebase from "../../firebaseConfig.js"
import fb from 'firebase/compat/app'
// import 'firebase/firestore'

import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import Vtt from "vtt-creator";
import newPostStore from './NewPost';
import groupsPageStore from './GroupsPage';
import newTextPostStore from './NewTextPost';
import newPhotoPostStore from './NewPhotoPost';
import adminAnalyticsStore from './AdminAnalytics';
import newContributionStore from './NewContribution';

var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)


const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ['userData', 'userGroups', 'userFriends', 'userNotifications', 'currentPost', 'stripeCustomer'],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
    state: {
        provision: 0, // this var is used to keep track of their account type. 0 = basic, 1 = plus, 2 = premium
        userData: {},
        userGroups: [],
        userFriends: [],
        userNotifications: {},
        stripeCustomer: {},
        drawerOpen: true,
        searchParams: '',
        publicPosts: [],
        lastLoadedPublicPost: null,
        currentPost: {},
        profilePageTab: 0,
        alertUser: {
            show: false, // show the alert
            type: "", // snackbar 
            text: "", // the text for the alert
        },
        isIOSDevice: false,
        isMobileDevice: false,
    },
    mutations: {
        // changeDrawerOpen (state) {
        //   state.drawerOpen = !state.drawerOpen
        // },
        changeDrawer (state, value) {
            state.drawerOpen = value;
        },
        updateSearchParams (state, newParams) {
            state.searchParams = newParams
        },
        ...vuexfireMutations,
        saveStripeCustomer: (state, customer) => {
            state.stripeCustomer.customer = customer;
        },
        saveStripeSubscription: (state, subscription) => {
            state.stripeCustomer.subscription = subscription;
        },
        saveStripeProduct: (state,product) => {
            state.stripeCustomer.product = product;
        },
        saveStripePaymentMethod: (state,payment) => {
            state.stripeCustomer.payment_method = payment;
        },
        saveStripeInvoices: (state,invoices) => {
            state.stripeCustomer.invoices = invoices;
        },
        saveAccountType: (state, provision)=> {
            state.provision = provision;
        },
        setPublicPosts: (state, posts) => {
            state.publicPosts = posts;
        },
        setLastLoadedPublicPost: (state, post) => {
            state.lastLoadedPublicPost = post;
        },
        setCurrentPost: (state, post) => {
            state.currentPost = post;
        },
        saveFriendRequestNotifications: (state, payload) => {
            state.userNotifications.friendRequests = payload;
        },
        saveGroupRequestNotifications: (state, payload) => {
            state.userNotifications.groupRequests = payload;
        },
        deletePublicPost: (state, payload) => {
            if (typeof state.publicPosts[payload.index] != 'undefined'){
                state.publicPosts.splice(payload.index, 1);
            }
        },
        deleteGroup: (state, payload) => {
            if (typeof state.userGroups[payload] != 'undefined'){
                state.userGroups.splice(payload, 1);
            }
        },
        changeProfilePageTab: (state, payload) => {
            state.profilePageTab = payload;
        },
        setIsIOSDevice: (state) => {
            var isMobile = {
                iOS: function() {
                    // console.log("iPhone, iPod");// or iPad
                    return navigator.userAgent.match(/iPhone|iPod|iPad/i);// for some reason Surface Pro 7 is being recognized here.
                },
                // iPadOS: function() {
                //     // console.log("iPad");
                //     return navigator.userAgent.match(/Mac/i) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
                // }
            };
            if (isMobile.iOS()) { //|| isMobile.iPadOS()) {
                // console.log("isIOSDevice");
                state.isIOSDevice = true;
            } 
            else {
                // console.log("not isIOSDevice");
                state.isIOSDevice = false;
            }
        },
        setIsMobileDevice: (state) => {
            var isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i);
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPod|iPad/i);
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i);
                },
                any: function () {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() ||isMobile.Windows());
                },
            };
            if (isMobile.any()) {
                // console.log("isMobileDevice");
                state.isMobileDevice = true;
            } 
            else {
                // console.log("not isMobileDevice");
                state.isMobileDevice = false;
            }
        },
        alertUser: (state, payload) => {
            /* used just for the snackbars. the dialogs will still import the alert component so they can handle the emits */
            /* also is only used for components/pages that are part of the dashboard! */
            // first clear the current one so it pops up with the new one, showing a little activity to the user so they know it is a new one */
            state.alertUser = {
                show: false,
                text: "",
                type: "",
            }
            state.alertUser.show = payload.show;
            state.alertUser.text = payload.text;
            state.alertUser.type = payload.type;
        },
        clearStorage: ( state ) => {
            let newState = {
                userData: {},
                userGroups: [],
                userFriends: [],
                userNotifications: {},
                provision: 0,
                stripeCustomer: {},
                drawerOpen: state.drawerOpen,
                searchParams: '',
                publicPosts: [],
                lastLoadedPublicPost: null,
                currentPost: {},
                profilePageTab: 0,
                newPost: state.newPost, // get the newpost module and reset the state with it.
                newPhotoPost: state.newPhotoPost,
                groupsPageStore: state.groupsPageStore,
                newContributionStore: state.newContributionStore,
                adminAnalyticsStore: state.adminAnalyticsStore,
                alertUser: {
                    show: false, // show the alert
                    type: "", // snackbar
                    text: "", // the text for the alert
                },
                isIOSDevice: false,
                isMobileDevice: false,

            };
            store.replaceState(newState);
            ls.removeAll();
        },
        updatePostData: (state, payload) => {
            // This mutation is used when the user updates the title or description of a post they have created.
            // It passes the new postData to the store so the saved posts can be updated for the homescreen.
            var index = state.publicPosts.findIndex(p => p.postId === payload.postId)
            if (index != -1) {
                state.publicPosts[index] = payload;
            }
        },

    },
    getters: {
        getUserData(state){
            return state.userData;
        },
        getStripeCustomer(state){
            return state.stripeCustomer;
        },
        getUserGroupsLength(state){
            return state.userGroups.length;
        },
    },
    actions: {
        bindUserData: firestoreAction(({ bindFirestoreRef }, currentUser) => {
            return bindFirestoreRef("userData", firebase.db.collection("userData").doc(currentUser.uid));
        }),
        unbindUserData: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('userData');
        }),
        bindUserGroupsData: firestoreAction(({ bindFirestoreRef}, currentUser) => {
            return bindFirestoreRef("userGroups", firebase.db.collection("userData").doc(currentUser.uid).collection("groups").orderBy("groupName"));
        }),
        unbindUserGroupsData: firestoreAction(({ unbindFirestoreRef} ) => {
            unbindFirestoreRef('userGroups');
        }),
        bindUserFriendsData: firestoreAction(({ bindFirestoreRef}, currentUser) => {
            return bindFirestoreRef("userFriends", firebase.db.collection("userData").doc(currentUser.uid).collection("friends").orderBy("username"));
        }),
        unbindUserFriendsData: firestoreAction(({ unbindFirestoreRef} ) => {
            unbindFirestoreRef('userFriends');
        }),
        initializeStore({state, dispatch}, currentUser){
            dispatch('bindUserData', currentUser).then(() => {
                dispatch('bindUserGroupsData', currentUser);
                dispatch('bindUserFriendsData', currentUser);
                /* on signup it won't have the stripe info just yet so we have to check if it is there before calling the dispatch mutations */
                if (state.userData && state.userData.stripeProductId && state.userData.stripeIsActive){
                    dispatch('setAccountType', { product: state.userData.stripeProductId, active: state.userData.stripeIsActive });
                }
                if (state.userData && typeof state.userData.stripeCustomerId != 'undefined' && state.userData.stripeCustomerId != null) {
                    dispatch('getStripeCustomer', state.userData);
                }
                return;
            })
        },
        getStripeCustomer({commit, dispatch}, userData) {
            // return; -> Added by Scott Brady on November 9, 2022. To see if price of server goes down. High prices
            // on November 1 and 2, 2022. Astronomically higher than normal for Frontend Instances!
            // I did this to check and see if it is actually need to get the stripe information. We are currently not
            // using it on the website. When a user is created (sign-up) it will create a stripe customer_id for them
            // still and this can always be added back in.
            if (userData.stripeIsActive) {
                // This is set to true after the first time all the info underneath is run through!
                // console.log("stripeIsActive!");
                return;
            }
            let customerId = userData.stripeCustomerId;
            // console.log("stripe customer id: ", customerId)
            axios.get(`stripe/customer/${customerId}`).then((response) => {
                commit('saveStripeCustomer', response.data.customer);
                if (process.env.NODE_ENV === 'development'){
                    // console.log("response.data.customer for stripe", response.data.customer);
                }
                // console.log("response.data.customer for stripe", response.data.customer);
                if (typeof response.data.customer.subscriptions != 'undefined' && typeof response.data.customer.subscriptions.total_count != 'undefined' ){ // there may not be a subscription
                    if( response.data.customer.subscriptions.total_count > 0 ){
                        dispatch('getStripeSubscription', response.data.customer.subscriptions.data[0].id);
                    }
                } 
                if( typeof response.data.customer.invoice_settings != 'undefined' && typeof response.data.customer.invoice_settings.default_payment_method != 'undefined' && response.data.customer.invoice_settings.default_payment_method != null){
                    dispatch('getStripePaymentMethod', response.data.customer.invoice_settings.default_payment_method);
                }
                else {
                    commit('saveStripePaymentMethod', null);
                }
                dispatch('getStripeInvoices', response.data.customer.id);
            });
        },
        getStripeSubscription({commit, dispatch},subscription_id){
            axios.get(`stripe/subscriptions/${subscription_id}`).then((response) => {
                commit('saveStripeSubscription', response.data.subscription);
                dispatch('getStripeProduct', response.data.subscription.plan.product);
            })
            .catch((error) => {
                console.error("the error retrieving the stripe subscription is: ", error);
            })
        },
        getStripeProduct({commit, dispatch},product_id){
            axios.get(`stripe/products/${product_id}`).then((response) => {
                commit('saveStripeProduct', response.data.product);
                /* in the case that our server is asleep when a webhook is fired off (will be retried later on), we need to verify that our firestore data
                    and the stripe data are the same for the customer's subscription and product. If they are not, we will fall back to the stripe's data because
                    it will always be up to date. In this case, we will make an update request to firebase to change the user's data */
                    dispatch('verifyFirebaseWithStripe', response.data.product.id);
            })
        },
        getStripeInvoices({commit},customer_id){
            axios.get(`stripe/invoices/${customer_id}`)
            .then((response) => {
                commit('saveStripeInvoices', response.data.invoices);
            })
        },
        getStripePaymentMethod({commit}, payment_id){
            axios.get(`stripe/payment-method/${payment_id}`)
            .then((response) => {
                commit('saveStripePaymentMethod', response.data.payment_method);
            })
        },
        setStripeSubscription({commit, dispatch}, subscription){
            commit('saveStripeSubscription', subscription);
            dispatch('getStripeProduct', subscription.plan.product);
        },
        setStripeCustomer({commit}, customer){
            commit('saveStripeCustomer', customer);
        },
        setStripePaymentMethod({commit}, payment_method){
            commit('saveStripePaymentMethod', payment_method)
        },
        setAccountType({commit}, stripe){
            // console.log("in set Account type, stripe.active: ", stripe.active)
            var provision = 0;
            if (stripe.product === 'prod_IajkUya9uO1dPu' && stripe.active){
                provision = 0;
            }
            else if (stripe.product === 'prod_IZbAIZAAdOP5RZ' && stripe.active){
                provision = 1;
            }
            else if (stripe.product === 'prod_IZb2wxixqzmdO5' && stripe.active){
                provision = 2;
            }
            commit('saveAccountType', provision);
        },
        verifyFirebaseWithStripe({state, dispatch}, product_id){
            // console.log("verifyFirebaseWithStripe: ", state.stripeCustomer.subscription.plan.active);
            if (state.userData.stripeProductId != product_id){
                dispatch('setAccountType', { product: product_id, active: state.stripeCustomer.subscription.plan.active });

                if(state.userData.stripeIsActive != state.stripeCustomer.subscription.plan.active){ // check to see if the subscription is active
                    //update firebase data
                    firebase.db.collection('userData').doc(state.userData.userId)
                    .update({
                        stripeProductId: product_id,
                        stripeIsActive: state.stripeCustomer.subscription.plan.active
                    })
                    .then(() => {
                        // console.log("updated the userData to have the correct stripe product id and subscription status");
                    })
                    .catch((error) => {
                        console.error("Could not update userData collection to the correct stripe product id and subscription status", error);
                    })
                }
                else {
                    firebase.db.collection('userData').doc(state.userData.userId)
                    .update({
                        stripeProductId: product_id,
                    })
                    .then(() => {
                        // console.log("updated the userData to have the correct stripe product id");
                    })
                    .catch((error) => {
                        console.error("Could not update userData collection to the correct stripe product id", error);
                    })
                }
            }
        },
        // changeDrawerState({commit}, value) {
        //     commit("changeDrawer", value);
        // },
        async landPageButtonClicked({}, payload) {
            // console.log("store landPageButtonClicked: ", payload);
            const docId = '72688472665'; //scottiscool on numberpad
            fetch("https://api64.ipify.org?format=json").then(response => response.json()).then(data => {
                fetch(`https://ipinfo.io/${data.ip}/json?token=${payload.apiKey}`).then(response => response.json()).then(data => {
                    firebase.db.collection("landPageClicks").doc(docId).collection(payload.section === 'login' ? 'login' : 'signUp').doc().set({
                        date: new Date(),
                        deviceInfo: payload.deviceInfo,
                        error: null,
                        ipInfo: data,
                        route: payload.route,
                        section: payload.section,
                        timeSpentOnPageInSeconds: payload.timeSpentOnPage,
                        userLanguage: payload.userLang,
                    }, {merge: true}).then(() => {});
                }).catch(error => {
                    console.error(error.message);
                    firebase.db.collection("landPageClicks").doc(docId).collection(payload.section === 'login' ? 'login' : 'signUp').doc().set({
                        date: new Date(),
                        deviceInfo: payload.deviceInfo,
                        error: error,
                        ipInfo: null,
                        route: payload.route,
                        section: payload.section,
                        timeSpentOnPageInSeconds: payload.timeSpentOnPage,
                        userLanguage: payload.userLang,
                    }, {merge: true}).then(() => {});
                });
            }).catch(error => {
                console.error("Error fetching IP address: " + error);
                firebase.db.collection("landPageClicks").doc(docId).collection(payload.section === 'login' ? 'login' : 'signUp').doc().set({
                    date: new Date(),
                    deviceInfo: payload.deviceInfo,
                    error: error,
                    ipInfo: null,
                    route: payload.route,
                    section: payload.section,
                    timeSpentOnPageInSeconds: payload.timeSpentOnPage,
                    userLanguage: payload.userLang,
                }, {merge: true}).then(() => {});
            });
        },
        async downloadVttFile({commit}, payload) {
            var audioData;
            let file = new Vtt();
            let newTitle
            if (payload.post !== null) { // v-menu from Post.vue or ViewPost.vue
                if (payload.post.languageName === 'None (Disable Auto Captions)') {
                    commit('alertUser', { show: true, text: `Captions have been disabled on this post. Can't create vtt file.`, type: 'snackbar' });
                    return;
                }
                // ELSE has captions
                newTitle = payload.post.title.replace(/\s/g, '').toLowerCase();
                if (payload.audio !== null) { // v-menu from ViewPost.vue
                    audioData = payload.audio.find((audio) => {if(audio.audioDataId === payload.post.postAudioDataId) return audio});
                    audioData = JSON.parse(JSON.stringify(audioData));
                    for (let i = 0; i < audioData.audioData.audio.length; i++) {
                        let start = audioData.audioData.audio[i].audioStart;
                        let end = audioData.audioData.audio[i].audioStart + audioData.audioData.audio[i].audioDur;
                        let caption = audioData.audioData.subtitles[i].subtitle;
                        file.add(start,end,caption);
                    }
                } else { // v-menu from Post.vue
                    var doc = await firebase.db.collection('audioSegments').doc(payload.post.postAudioDataId).get();
                    if (doc.exists) {
                        audioData = doc.data();
                        for (let i = 0; i < audioData.audio.length; i++) {
                            let start = audioData.audio[i].audioStart;
                            let end = audioData.audio[i].audioStart + audioData.audio[i].audioDur;
                            let caption = audioData.subtitles[i].subtitle;
                            file.add(start,end,caption);
                        }
                    }
                }
            } else if (payload.post === null ) { // v-menu from AudioElement.vue
                if (payload.audio.audioData.languageName === 'None (Disable Auto Captions)') {
                    commit('alertUser', { show: true, text: `Captions have been disabled on this contribution. Can't create vtt file.`, type: 'snackbar' });
                    return;
                }
                // ELSE has captions
                newTitle = payload.audio.audioData.title.replace(/\s/g, '').toLowerCase(); //replace so there are no spaces
                audioData = JSON.parse(JSON.stringify(payload.audio));
                for (let i = 0; i < audioData.audioData.audio.length; i++) {
                    let start = audioData.audioData.audio[i].audioStart;
                    let end = audioData.audioData.audio[i].audioStart + audioData.audioData.audio[i].audioDur;
                    let caption = audioData.audioData.subtitles[i].subtitle;
                    if (caption === null) {
                        caption = ""; // null caption, setting to blank!
                    }
                    file.add(start,end,caption);
                }
            }
            // VTT file download
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/vtt;charset=utf-8,' + encodeURIComponent(file));
            element.setAttribute('download', `${newTitle}-captions.vtt`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        savePublicPosts({commit}, posts){
            commit('setPublicPosts', posts)
        },
        saveLastLoadedPublicPost({commit}, post){
            commit('setLastLoadedPublicPost', post);
        },
        saveCurrentPost({commit}, post){ // designed so if the user reloads the page while in ViewPost, the post can be grabbed from the store
            // console.log("saving current post")
            commit('setCurrentPost', post);
        },
        deletePostFromDb({commit}, payload){
            // console.log("index.js DeletePostFromDB() payload to delete", payload);
            // MAKE SURE WE HAVE POST TYPE HERE. IT MAY JUST BE POST IF CALLED FROM NEW POST
            // console.log(commit);
            /* First we check to see which kind of post it is, and whether or not we need to delete it from the local store list */
            if (payload.post.postType === 'group' && ( typeof payload.isDraft != 'undefined' && !payload.isDraft ) ){ // drafts won't be stored locally in the store
                if (payload.deleteFromStore  && payload.index != null){
                    commit('deleteGroupPost', payload); // just deletes the post from the list on front end
                }
            }
            else if (payload.post.postType === 'public' && ( typeof payload.isDraft != 'undefined' && !payload.isDraft ) ) { // drafts won't be stored locally in the store
                if (payload.deleteFromStore && payload.index != null){
                    commit('deletePublicPost', payload); // just deletes the post from the list on front end
                }
            }
            
            /* maybe try contacting the server for three calls. 
            1. the server call will handle everything deletion wise.
            */
            let postType;
            if( typeof payload.isDraft != 'undefined' && payload.isDraft ){
                // console.log("calling the post delete for draft...");
                postType = 'drafts';
                axios.delete(`post/${postType}/${payload.post.postId}`, { data: {
                    mediaType: payload.post.mediaType,
                }})
                .then(() => {
                    commit('alertUser', { show: true, text: `Successfully deleted post.`, type: 'snackbar' });
                })
                .catch((err) => {
                    console.error("Error deleting the post: ", err);
                })
            } else {
                if (typeof payload.post.postType == 'undefined'){
                    postType = 'public';
                }
                else {
                    postType = payload.post.postType;
                }
                axios.delete(`post/${postType}/${payload.post.postId}`, { data: {
                    mediaType: payload.post.mediaType,
                }})
                .then(async () => {
                    let total_contributions = typeof payload.post.postAnalytics === 'undefined' ? 0 : typeof payload.post.postAnalytics.total_contributions === 'number' /*&& payload.post.postAnalytics.total_contributions >= 0*/ ? payload.post.postAnalytics.total_contributions : 0; 
                    total_contributions = Math.abs(total_contributions) * -1;
                    let videoType = payload.post.videoType === 'video/youtube' ? 'youtube' : 'file';
                    const analyticsRef = firebase.db.collection("admin").doc("analytics");
                    if (postType == 'public'){
                        if(videoType === 'youtube'){
                            await analyticsRef.update({
                                total_public_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_youtube_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_contributions: fb.firestore.FieldValue.increment(total_contributions) 
                            })
                            .catch((err) => {
                                console.log("error decrementing public post for analytics", err)
                            })
                        }
                        else {
                            await analyticsRef.update({
                                total_public_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_file_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_contributions: fb.firestore.FieldValue.increment(total_contributions) 
                            })
                            .catch((err) => {
                                console.log("error decrementing public post for analytics", err)
                            })

                        }
                    }
                    else if (postType == 'group'){
                        if(videoType === 'youtube'){
                            await analyticsRef.update({
                                total_group_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_youtube_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_contributions: fb.firestore.FieldValue.increment(total_contributions) 
                            })
                            .catch((err) => {
                                console.log("error decrementing group post for analytics", err)
                            })
                        }
                        else {
                            await analyticsRef.update({
                                total_group_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_file_video_posts: fb.firestore.FieldValue.increment(-1),
                                total_contributions: fb.firestore.FieldValue.increment(total_contributions) 
                            })
                            .catch((err) => {
                                console.log("error decrementing group post for analytics", err)
                            })

                        }
                    }
                })
                .catch((err) => {
                    console.error("Error deleting the post: ", err);
                })
            }
        },
        async userUpVotedContribution({state}, payload){
            // if upvoting, that means we get rid of their downvote if payload.alreadyDownvoted.
            // if payload.alreadyUpvoted is already there, then we just set it to false
            // console.log("payload: ", payload);
            const userDataRef = firebase.db.collection("userData").doc(state.userData.userId);
            const audioDataRef = firebase.db.collection("audioSegments").doc(payload.audioDataId);
            var batch = firebase.db.batch();
            if ( payload.alreadyDownVoted ){ 
                batch.update( userDataRef, { contributionDownVotes: fb.firestore.FieldValue.arrayRemove(payload.audioDataId) } ) // if already downvoted, remove their downvote. can't downvote and upvote the same contribution
                batch.update( audioDataRef, { userDownVotes:  fb.firestore.FieldValue.arrayRemove(state.userData.userId), totalDownVotes: fb.firestore.FieldValue.increment(-1) } ) // if the user has downvoted, remove their downvote.
                // batch.update( audioDataRef, { totalDownVotes: fb.firestore.FieldValue.increment(-1) } ) // decrement the downvotes by 1
            }
            if( payload.alreadyUpVoted ){ 
                batch.update( userDataRef, { contributionUpVotes: fb.firestore.FieldValue.arrayRemove(payload.audioDataId) } ) // if already upvoted, remove their upnvote
                batch.update( audioDataRef, { userUpVotes:  fb.firestore.FieldValue.arrayRemove(state.userData.userId), totalUpVotes: fb.firestore.FieldValue.increment(-1)  } ) // if alraedy upvoted, remove their upvote
                // batch.update( audioDataRef, { totalUpVotes: fb.firestore.FieldValue.increment(-1) } ) // decrement the up votes by 1
            }
            else { 
                batch.update( userDataRef, { contributionUpVotes: fb.firestore.FieldValue.arrayUnion(payload.audioDataId) } ) // if they haven't already upvoted it, add it to the array
                batch.update( audioDataRef, { userUpVotes:  fb.firestore.FieldValue.arrayUnion(state.userData.userId), totalUpVotes: fb.firestore.FieldValue.increment(1) } )
                // batch.update( audioDataRef, { totalUpVotes: fb.firestore.FieldValue.increment(1) } ) // increment the up votes by 1
            }
            await batch.commit().catch((err) => { console.log ("Error committing the up vote batch.", err)});
            return;
        },
        async userDownVotedContribution({state}, payload){
            // console.log("payload: ", payload);
            // if downvoting, that means we get rid of their upvote if payload.alreadyUpvoted .
            // if payload.alreadyDownvoted is already there, then we just set it to false
            const userDataRef = firebase.db.collection("userData").doc(state.userData.userId);
            const audioDataRef = firebase.db.collection("audioSegments").doc(payload.audioDataId);
            var batch = firebase.db.batch();
            if ( payload.alreadyUpVoted ){ 
                batch.update( userDataRef, { contributionUpVotes: fb.firestore.FieldValue.arrayRemove(payload.audioDataId) } ) // if already upvoted, remove their upvote. can't upvote and downvote the same contribution
                batch.update( audioDataRef, { userUpVotes:  fb.firestore.FieldValue.arrayRemove(state.userData.userId), totalUpVotes: fb.firestore.FieldValue.increment(-1) } ) // if the user has upvoted, remove their upvote.
                // batch.update( audioDataRef, { totalUpVotes: fb.firestore.FieldValue.increment(-1) } ) // decrement the up votes by 1
            }
            if( payload.alreadyDownVoted ){ 
                batch.update( userDataRef, { contributionDownVotes: fb.firestore.FieldValue.arrayRemove(payload.audioDataId) } ) // if already downvoted, remove their downvote
                batch.update( audioDataRef, { userDownVotes:  fb.firestore.FieldValue.arrayRemove(state.userData.userId), totalDownVotes: fb.firestore.FieldValue.increment(-1) } ) // if alraedy downvoted, remove their downvote
                // batch.update( audioDataRef, { totalDownVotes: fb.firestore.FieldValue.increment(-1) } ) // decrement the up votes by 1
            }
            else { 
                batch.update( userDataRef, { contributionDownVotes: fb.firestore.FieldValue.arrayUnion(payload.audioDataId) } ) // if they haven't already downvoted it, add it to the array
                batch.update( audioDataRef, { userDownVotes:  fb.firestore.FieldValue.arrayUnion(state.userData.userId), totalDownVotes: fb.firestore.FieldValue.increment(1) } )
                // batch.update( audioDataRef, { totalDownVotes: fb.firestore.FieldValue.increment(1) } ) // increment  the downvotes by 1
            }
            await batch.commit().catch((err) => { console.log ("Error committing the down vote batch.", err)});
            return;
        },
        replaceState({commit}){
            // console.log("resetting the homefeed state")
            /* this method will clear the local storage, resetting the data being stored there */
            commit('clearStorage');
        }
    },
    modules: {
        newTextPost: newTextPostStore,
        newPhotoPost: newPhotoPostStore,
        newPost: newPostStore,
        groupsPageStore: groupsPageStore,
        adminAnalyticsStore: adminAnalyticsStore,
        newContributionStore: newContributionStore,
    },
})

export default store
