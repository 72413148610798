<template>
    <v-row justify="center" align="center" style="max-width: 100%; max-height: 100%;" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9; direction: rtl;" :class="isMobile ? 'primary' : 'transparent'">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'ar-intro'})"></v-img>
                </v-col>
                <v-spacer/>
                <v-col cols="8" align="end" class="ma-0 pa-0">
                    <v-btn color="white" outlined class="mr-2 font-weight-normal" :to="{name: 'ar-login'}">تسجيل الدخول</v-btn><!-- Sign In (Login) -->
                    <v-btn color="white" outlined class="font-weight-normal" :to="{name: 'ar-sign-up'}">يسجل</v-btn><!-- Register (Arabic Sign Up) -->
                </v-col>
            </v-app-bar>

            <v-main :class="isMobile ? '' : 'ma-0 pa-0'">
                <v-row v-if="!signUpComplete" justify="center" class="ma-0 pa-0">
                    <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                        <v-card elevation="8" light class="rounded-xl">

                            <v-overlay v-if="loading" absolute>
                                <v-progress-circular size="100" width="5" indeterminate color="primary"></v-progress-circular>
                            </v-overlay>
                            <v-overlay v-if="showTC" absolute>
                                <TCModal :dialog="true"  @setAgree="setAgree"/>
                            </v-overlay>

                            <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="8" align="center">
                                        <span class="text-wrap text-h4 text-md-h3 text-lg-h3 font-weight-light">يسجل</span><!-- Register (Arabic Sign Up) -->
                                    </v-col>
                                </v-row>
                            </v-card-title>

                            <v-row justify="center" no-gutters class="ma-0 pa-0">
                                <v-col cols="10" lg="8" md="8" align="center">
                                    <v-form v-model="isSignUpFormValid">
                                        <v-row justify="center" align="center" no-gutters class="ma-0 pb-2 pb-lg-2">
                                            <!-- :class="addImage && isMobile ? 'mb-2' : ''" -->
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <v-avatar :size="isMobile ? '80' : '120'" style="cursor: pointer;" :rules="profileImageRules" @click.stop="openProfileImageFile">
                                                    <v-img :src="profileImageURL ? previewImage : require('../../../assets/no-user.png')"></v-img>
                                                    <v-overlay v-if="!showTC && !loading" absolute :opacity="profileImageURL ? '.25' : '.50'" :color="profileImageError ? 'red': 'grey darken-4'">
                                                        <v-icon :style="profileImageURL ? 'opacity: .75;' : ''">{{profileImageURL ? 'mdi-file-image-remove' : 'mdi-file-image-plus'}}</v-icon>
                                                        <v-file-input v-model="profileImageInput" ref="fileInput" type="file" accept="image/*" @change="getProfileImageFile()" style="display: none;"/>
                                                    </v-overlay>
                                                </v-avatar>
                                            </v-col>
                                            <v-col cols="12">
                                                <span :class="profileImageError ? 'red--text' : 'transparent--text'" class="text-caption font-weight-light" style="cursor: default;">*يجب أن يكون حجم ملف الصورة أقل من 6 ميجابايت</span><!-- *Image file must be less than 6 MB. -->
                                            </v-col>
                                            <!-- <transition v-if="addImage" name="fade">
                                                <div v-if="isMobile" class="d-flex flex-column px-8">
                                                    <v-icon color="red" class="align-self-center">mdi-arrow-up</v-icon>
                                                    <span class="rounded-xl red white--text px-2">Add a Profile Image?</span>
                                                    <span class="rounded-xl primary white--text pa-0 ma-0" style="cursor: pointer;" @click.stop="signUpWithoutImage">No Thanks</span>
                                                </div>
                                                <div v-else class="d-flex flex-column px-8">
                                                    <v-icon color="red" class="align-self-center">mdi-arrow-up</v-icon>
                                                    <span class="rounded-xl red white--text pl-2">Add a Profile Image? <span class="rounded-xl primary white--text px-1" style="cursor: pointer;" @click.stop="signUpWithoutImage">No Thanks</span></span>
                                                </div>
                                            </transition> -->
                                        </v-row>
                                        <v-text-field v-model="newFullname" label="الاسم الكامل" reverse outlined required validate-on-blur prepend-inner-icon="mdi-account-details" color="primary" :rules="fullnameRules" @change="removeHintAndHelpIfNecessary()"></v-text-field><!-- Fullname -->
                                        <v-text-field v-model="newEmail" label="بريد إلكتروني" reverse outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" type="email" :rules="emailRules" @change="removeHintAndHelpIfNecessary()"></v-text-field><!-- Email -->
                                        <v-text-field v-model="newPassword" :type="showPassword ? 'text' : 'password'" label="كلمة المرور" reverse outlined required validate-on-blur prepend-inner-icon="mdi-lock" color="primary" :rules="passwordRules" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" @change="removeHintAndHelpIfNecessary()" @click:append="showPassword = !showPassword"></v-text-field><!-- Password -->
                                        <v-row justify="end" align="center" class="mx-0 mb-0 mt-n5 pa-0">
                                            <span class="text-caption text-sm-subtitle-1">أنا أوافق على <i style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="showTC = true">الأحكام والشروط</i></span><!-- I agree to the terms and conditions -->
                                            <v-checkbox v-model="acceptsTC" :ripple="false"/>
                                        </v-row>

                                        <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light">{{hintText}}</p>
                                        <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> <!-- Need Help? Contact Us-->
                                    </v-form>
                                </v-col>
                            </v-row>

                            <v-card-actions>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <v-btn rounded large color="primary" @click="signUp()" :disabled="!validateInputs()" class="">إنشاء حساب</v-btn> <!-- Create Account -->
                                        <v-row align="center" no-gutters class="my-0 mx-10">
                                            <v-divider class="primary"/>
                                                <span class="pa-1 mx-2 mt-2 rounded-xl">أو</span> <!-- OR -->
                                            <v-divider class="primary"/>
                                        </v-row>
                                        <v-btn icon @click="signInWithGoogle()">
                                            <img src="../../../assets/google-icon.svg" alt="google-logo" width="35">
                                        </v-btn>
                                        <!-- <v-btn icon class="ml-2" @click="signInWithFacebook()">
                                            <v-icon color="blue darken-2" size="45">mdi-facebook</v-icon>
                                        </v-btn> -->
                                    </v-col>

                                    <v-layout column align-center justify-center class="mt-1">
                                        <span class="text-subtitle-1 text-lg-h6 font-weight-light">هل لديك حساب؟
                                            <router-link to="/ar-login" style="color:primary;">تسجيل الدخول</router-link>
                                        </span> <!-- Already Have an Account? Sign In (Login) -->
                                    </v-layout>
                                </v-row>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="signUpComplete" justify="center" align="start" style="max-width: 100%;" class="mx-0">
                    <v-col cols="11" xl="3" lg="4" md="8" sm="10">
                        <v-card elevation="8" light class="rounded-xl">
                            <v-row justify="center" no-gutters class="py-2">
                                <v-col cols="8" align="center">
                                    <v-btn outlined x-large icon :ripple="false" style="cursor: default; background-color: #61892F !important;">
                                        <v-btn outlined icon large color="primary" :ripple="false" style="cursor: default; background-color: white !important;">
                                            <v-icon large color="primary">mdi-email</v-icon>
                                        </v-btn>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-title style="word-break: normal" class="mb-5">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-h6 text-sm-h5 font-weight-light">تفقد بريدك الالكتروني من فضلك</span> <!-- Please check your email (Please Verify Your Email) -->
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-subtitle style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">شكرا لانضمامك!</span><br/> <!-- Thank you for joining! -->
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">لقد قمنا بإرسال بريد إلكتروني إلى</span><br/> <!-- We have sent a email to -->
                                        <span class="black--text text-wrap text-subtitle-1 font-weight-medium">{{this.newEmail}}</span>
                                    </v-col>
                                </v-row>
                            </v-card-subtitle>
                            <v-card-text style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">اتبع الرابط الموجود في بريدنا الإلكتروني للتأكيد والتسجيل</span><br/> <!-- Follow the link in our email to confirm and register (Just click on the link in that email to complete your signup) -->
                                        <span>إذا كنت لا ترى ذلك،<span class="black--text text-wrap text-subtitle-2 font-weight-medium">تحقق من البريد العشوائي الخاص بك</span>مجلد</span><br/><br/> <!-- If you don't see it, check your spam folder (If you don't see it you may need to check your spam folder.)-->
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">هل ما زلت لا تستطيع العثور على بريدنا الإلكتروني؟</span><br/> <!-- Still can't find our email? (Still can't find the email?) -->
                                        <v-btn small text class="primary mt-2" @click.stop="resendVerificationEmail">إعادة إرسال البريد الإلكتروني</v-btn> <!-- Resend the email (Resend Email) -->
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">تحتاج مساعدة؟ <i style="cursor: pointer;" class="primary--text text-subtitle-2 text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</i></span> <!-- Need Help? Contact Us-->
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-main>
        </v-col>
    </v-row>
</template>

<script src="https://js.stripe.com/v3/" defer></script>
<script>
import firebase from "../../../../firebaseConfig.js";
import TCModal from "../TCModal.vue";
export default {
    name: "ar-sign-up",
    metaInfo: {
        // title: 'Sign Up - tawqin'
        title: 'يسجل - tawqin'
    },
    components: {
        TCModal,
    },
    data: function() {
        return {
            profileImageURL: "",
            profileImageInput: null,
            profileImageRules: [(value) => !value || value.size < this.profileImageFileLimit || "Profile Image must be less than 6 MB",],
            profileImageFileLimit: 6000000, // 6MB
            profileImageError: false,
            previewImage: "",
            newFullname: "",
            fullnameRules: [
                (v) => !!v || "الاسم الكامل (مطلوب", // Full Name is required
                (v) => v.length <= 50 || "يجب أن يكون الاسم الكامل 50 حرفًا أو أقل", // Full Name must be 50 characters or less
                // (v) => /^[A-Za-z ]+$/.test(v) || "Full Name must only contain alphabet characters and spaces"
            ],
            newEmail: "",
            emailRules: [
                (v) => !!v || "البريد الإلكتروني (مطلوب", // Email is required
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "يجب أن يكون البريد الإلكتروني صالحا", // E-mail must be valid
            ],
            newPassword: "",
            passwordRules: [
                (v) => v.length >= 8 || "يجب أن تتكون كلمة المرور من 8 أحرف أو أكثر", // Password Must Be 8 Characters Or More
            ],
            showPassword: false,
            acceptsTC: false,
            showTC: false,
            loading: false,
            hint: false,
            hintText: "",
            signUpComplete: false,
            isSignUpFormValid: false,
            needHelp: false,
            // helpMessage: null, // for updating message sent in emailKeep()
            // addImage: false, // can be used to suggest a user add a profile image
        };
    },
    methods: {
        validateInputs: function() {
            return !(this.newFullname === "" || this.profileImageError || this.profileImageURL.size > this.profileImageFileLimit || this.newEmail == "" || this.newPassword == "" || this.newPassword.length < 8 || !this.acceptsTC || !this.isSignUpFormValid);
        },
        removeHintAndHelpIfNecessary: function () {
            if (this.hint || this.needHelp) {
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
        },
        openProfileImageFile: function() {
            // if (this.addImage) this.addImage = false;
            if (!this.previewImage) { // called when user clicks to add an image
                if (this.profileImageError) this.profileImageError = false;
                this.$refs.fileInput.$refs.input.click(); // open fileInput for image select
            } else { // called when user has clicked to remove the selected image
                this.$window.URL.revokeObjectURL(this.previewImage);
                this.profileImageURL = "";
                this.profileImageInput = null;
                this.previewImage = "";
            }
        },
        getProfileImageFile: function() { // called each time a user selects an image
            if (this.previewImage) { // should not get called // called when a user has selected an image that exceeds this.profileImageFileLimit
                this.$window.URL.revokeObjectURL(this.previewImage);
                this.profileImageURL = "";
            } else { // called when a user has selected an image
                if (!this.profileImageInput) { // i think this has to do with addImage feature
                    // if (this.validateInputs()) this.addImage = true;
                    return;
                }
                if (this.profileImageInput !== null && this.profileImageInput.size > this.profileImageFileLimit) { // image exceeds this.profileImageFileLimit
                    // if (this.addImage) this.addImage = false;
                    this.profileImageError = true;
                    this.profileImageInput = null;
                } else { // image selected meets the criteria, set it
                    this.previewImage = this.$window.URL.createObjectURL(this.profileImageInput);
                    this.profileImageURL = this.profileImageInput;
                    // if (this.addImage) this.signUp();
                }
            }
        },
        setAgree: function(agree) {
            this.acceptsTC = agree;
            this.showTC = false;
        },
        // uncomment below when ready to use addImage feature
        // checkProfileImageForCorrectSignUp: function() { 
        //     if (this.profileImageURL !== "") { // user is trying to upload a profile image
        //         this.signUp();
        //     } else { // no image
        //         if (!this.addImage) {// ask user if they want to upload an image
        //             this.addImage = true;
        //         } else { // user has been asked, and clicked 'create account' again.
        //             this.signUpWithoutImage();
        //         }
        //     }
        // },
        signUp: async function () {
            let userCredentials = {};
            try {
                // if (this.addImage) this.addImage = false
                this.loading = true;
                userCredentials = await this.createUserWithEmailAndPassword();
                const userData = await this.setUserData(1, userCredentials.user, false);
                await this.updateUserProfile(userCredentials.user, userData.profilePicture);
                await this.setFirebaseUserData(userCredentials.user.uid, userData);
                await this.sendEmailVerification();
            } catch (error) {
                console.error(error);
                switch (error.code) { // moved here on February 15, 2024. This is because when a error is thrown in the above try statements it falls to this catch.
                    // if we want the below case statements to run (ie the retry logic) we have to have these statements in the main catch.
                    case "auth/email-already-in-use": // thrown if there already exists an account with the given email address
                        await this.checkAndRetrySignInWithEmailAndPassword();
                        break;
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*البريد الإلكتروني الذي أدخلته غير صالح. حاول ثانية.*"; // The email you entered is invalid. Try again.
                        this.newEmail = "";
                        this.newPassword = "";
                        break;
                    case "auth/weak-password": // thrown if the password is not strong enough
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*كلمة المرور التي اخترتها ليست قوية بما فيه الكفاية. حاول ثانية*"; // *The password you chose is not strong enough. Try again.*
                        this.newPassword = "";
                        break;
                    default:
                        console.error("An error occurred during sign-up:", error.message);
                        // console.error("");
                        // console.error("error while creating the user with email and password: ", error.message);
                        this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                        // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetSignUpState(); // reset and try again.
                        break;
                }
            }
        },
        // uncomment below when ready to use addImage feature
        // signUpWithoutImage: async function () {
        //     try {
        //         // if (this.addImage) this.addImage = false;
        //         this.loading = true;
        //         let userCredentials = await this.createUserWithEmailAndPassword();
        //         await this.updateUserProfile(userCredentials, uploadedProfileImageURL);
        //         const userData = await this.setUserData(2, userCredentials, false);
        //         await this.setFirebaseUserData(userCredentials.user.uid, userData);
        //         await this.sendEmailVerification();
        //     } catch (error) {
        //         console.error("An error occurred during sign-up:", error);
        //         this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
        //         this.resetSignUpState(); // Reset any necessary state variables
        //     }
        // },
        createUserWithEmailAndPassword: async function () {
            try {
                const userCredentials = await firebase.auth.createUserWithEmailAndPassword(this.newEmail, this.newPassword);
                return userCredentials;
            } catch (error) {
                throw error;
            }
        },
        signInWithGoogle: function() {
            this.loading = true;
            var provider = new firebase.authentication.GoogleAuthProvider();
            firebase.auth.useDeviceLanguage(); // will open the popup in the language the user has selected as their default browser language.
            this.signInWithPopup(provider);
        },
        signInWithFacebook: function(){
            this.loading = true;
            var provider = new firebase.authentication.FacebookAuthProvider();
            // provider.addScope('user_email');
            this.signInWithPopup(provider);
        },
        signInWithPopup: async function (provider) {
            // this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء إنشاء حسابك، يرجى المحاولة مرة أخرى`, type: 'ar-snackbar' }); // an error occurred while creating your account, please try again
            try {
                let signInWithPopup = await firebase.auth.signInWithPopup(provider);
                const user = signInWithPopup.user;
                let userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists) { // user has already signed up
                    if (!userInDatabase.data().emailVerified) { // check if user has verified email, if not, set to verified
                        await firebase.db.collection("userData").doc(user.uid).update({ emailVerified: true });
                    }
                    this.$router.replace({ path: "/home" }); // user is logged in, set route to /home
                } else { // user does not exist, create them
                    let responseFromServerForVerifiedEmail = await this.$http.put(`emailVerified/${user.uid}`);
                    const userData = await this.setUserData(3, user, responseFromServerForVerifiedEmail.data.emailVerified);
                    await this.setFirebaseUserData(user.uid, userData);
                    this.loading = false;
                    this.$router.replace({ path: "/home", query: { newUser: true} });
                }
            } catch (error) {
                console.error(error);
                this.loading = false;
                switch (error.code) {
                    case "auth/account-exists-with-different-credential": // thrown when a user tries to sign in with Facebook or Google when they have already signed in with one or the other.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم بالفعل إنشاء حساب بهذا البريد الإلكتروني. إذا قمت بالفعل بإنشاء حساب مع Google، فيرجى تحديده أدناه. بخلاف ذلك، حاول تسجيل الدخول باستخدام بريدك الإلكتروني وكلمة المرور. إذا كنت تشعر أن هذا خطأ، يرجى الاتصال بنا أدناه.*"; // An account has already been created with this email. If you have already created an account with Google, please select it below. Otherwise, try logging in using your email and password. If you feel this is an error, please contact us below.
                        break;
                    case "auth/cancelled-popup-request": // thrown if multiple popup operations are triggered, only one allowed at a time. All fail if thrown.
                        this.loading = true;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد قمت بفتح عدد كبير جدًا من النوافذ المنبثقة. يرجى الإغلاق والمحاولة مرة أخرى.*"; // You have opened too many pop-up windows. Please close and try again.
                        break;
                    case "auth/popup-blocked": // thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*تم حظر النافذة المنبثقة بواسطة المتصفح. يرجى السماح للنوافذ المنبثقة بالتسجيل في Google.*"; // The pop-up has been blocked by your browser. Please allow pop-ups to register with Google.
                        break;
                    case "auth/popup-closed-by-user": // thrown if the popup window is closed by the user without completing the sign in to the provider.
                        this.resetSignUpState();
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم تعطيل المستخدم من استخدام موقع tawq.in. يرجى الاتصال بالدعم للحصول على المساعدة.*"; // The user has been disabled from using the tawq.in website. Please contact support for assistance.
                        // this.helpMessage = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
                        break;
                    default:
                        this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                        this.resetSignUpState();
                        break;
                }
            }
        },
        setUserData: async function (from, user, emailVerified) {
            // console.log("setUserData: ", from, user, emailVerified);
            // KEY - from (location): 1. signUp() 2. signUpWithoutImage() 3. signInWithPopup() 4. checkAndRetrySignInWithEmailAndPassword()
            return new Promise (async (resolve, reject) => {
                const userData = {
                    userId: user.uid,
                    fullname: (from === 3) ? user.displayName : this.newFullname,
                    email: from === 3 ? user.email : this.newEmail,
                    username: from === 3 ? user.displayName : this.newFullname,
                    profanityFilter: false,
                    darkTheme: true,
                    emailVerified: emailVerified,
                    pushToTawq: true,
                    postsCreated: 0,
                    playbackStyle: false, // default to pauseplay
                    captionAudio: true,
                    dualDevice: false,
                    signUpMethod: from === 3 ? user.providerData[0].providerId : "email", 
                    signUpDate: new Date(),
                }
                userData.profilePicture = from === 3 ? user.photoURL : ((this.profileImageURL !== "") ? await this.uploadProfileImage(user).catch((error) =>{return reject(false)}) : "");/*(from === 1) ? await this.uploadProfileImage(userCredentials) : "" ;*/
                return resolve(userData);
            });
        },
        uploadProfileImage: function (userCredentials) {
            return new Promise ((resolve, reject) => {
                const metadata = {
                    cacheControl: 'public,max-age=604800' // 1 week
                }
                var file = this.profileImageURL;
                var storageRef = firebase.storage.ref("userProfilePics");
                var photoRef = storageRef.child("/" + userCredentials.uid + "/1");
                var uploadTask = photoRef.put(file, metadata);
                uploadTask.on("state_changed",() => {/*NEXT*/},(error) => {
                    /*ERROR*/
                    console.error("error uploading the profile image: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                },() => {
                    /*COMPLETE*/
                    var downloadURL = uploadTask.snapshot.ref.getDownloadURL();
                    resolve(downloadURL);
                });
            });
        },
        updateUserProfile: function (userCredentials, uploadedProfileImageURL) {
            // console.log("updateUserProfile: ", userCredentials, uploadedProfileImageURL);
            return new Promise((resolve, reject) => {
                userCredentials.updateProfile({
                    displayName: this.newFullname, //this.newUsername,
                    photoURL: uploadedProfileImageURL,
                }).then(() =>{
                    resolve(true);
                }).catch((error) => {
                    console.error("error updating the profile info: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                    this.resetSignUpState(); // reset the sign up state
                    reject(false);
                });
            });
        },
        setFirebaseUserData: function (docId, userData) {
            // console.log("setFirebaseUserData: ", docId, userData);
            return new Promise((resolve, reject) => {
                firebase.db.collection("userData").doc(docId).set(userData).then(() => {
                    resolve(true);
                }).catch((error) => {
                    console.error("error creating firebase userData: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                });
            });
        },
        sendEmailVerification: function () {
            return new Promise((resolve, reject) => {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: (process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true'),
                }
                firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(() => {
                    this.signUpComplete = true;
                    this.loading = false;
                    resolve(true);
                }).catch((error) => {
                    console.error("error sending email verification: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                });
            });
        },
        checkAndRetrySignInWithEmailAndPassword: async function () { // IMPLEMENTION OF RETRY LOGIC
            try {
                let signInWithEmailAndPassword = await firebase.auth.signInWithEmailAndPassword(this.newEmail, this.newPassword);
                if (signInWithEmailAndPassword.user) { // user has already been created via email and password and has now been signed in
                    let user = await firebase.db.collection("userData").doc(signInWithEmailAndPassword.user.uid).get();
                    if (!user.exists) { // User does NOT exist in the 'userData' collection of the database, we will try and create them.
                        const userData = await this.setUserData(4, signInWithEmailAndPassword.user, false);
                        await this.updateUserProfile(signInWithEmailAndPassword.user, userData.profilePicture);
                        await this.setFirebaseUserData(signInWithEmailAndPassword.user.uid, userData);
                        await this.sendEmailVerification();
                    } else { // User exists in the 'userData' collection of the database.
                        if (this.profileImageInput) { // user is trying to sign up with a new profile image, over write the one that is saved or create new if it doesn't exist.
                            let profilePictureDownloadURL = await this.uploadProfileImage(signInWithEmailAndPassword.user);
                            await this.updateUserProfile(signInWithEmailAndPassword.user, profilePictureDownloadURL);
                            await firebase.db.collection("userData").doc(signInWithEmailAndPassword.user.uid).set({
                                profilePicture: profilePictureDownloadURL
                            }, {merge: true});
                        }
                        if (!signInWithEmailAndPassword.user.emailVerified || !user.data().emailVerified) { // User has NOT verified email, send them a new verification email link.
                            await this.sendEmailVerification();
                        } else { // Email is verified, login user. Replace route with '/home'.
                            this.loading = false;
                            this.$router.replace({ path: "/home" });
                        }
                    }
                } else { // User exists in Firebase Authentication, but we couldn't sign them in. Reset and retry sign up!
                    this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                    this.resetSignUpState(); // reset the sign up state
                    return false;
                }
            } catch (error) {
                // this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                console.error(error);
                switch (error.code) {
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*البريد الإلكتروني الذي أدخلته غير صالح. حاول ثانية.*"; // The email you entered is invalid. Try again.
                        this.newEmail = "";
                        this.newPassword = "";
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم تعطيل المستخدم من استخدام موقع tawq.in. يرجى الاتصال بالدعم للحصول على المساعدة.*"; // The user has been disabled from using the tawq.in website. Please contact support for assistance.
                        break;
                    case "auth/user-not-found": // thrown if there is no user corresponding to the given email
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لا يمكن العثور على المستخدم الذي لديه هذا البريد الإلكتروني وكلمة المرور.*"; // The user with this email and password cannot be found.
                        break;
                    case "auth/wrong-password": // thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        // this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, Google or Facebook, please select the one you used below.*"; // uncomment when ready to use Facebook signup/login.
                        this.hintText = "*كلمة المرور التي أدخلتها خاطئة. إذا قمت بتسجيل الدخول مسبقًا باستخدام حساب جهة خارجية، Google، فيرجى تحديده أدناه.*"; // The password you entered is incorrect. If you're previously signed in with a third-party Google account, please select it below.
                        this.newPassword = "";
                        break;
                    default:
                        console.error("error signing in with email and password!", error.message);
                        // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.$store.commit('alertUser', { show: true, text: `*حدث خطأ أثناء إنشاء حسابك. حاول ثانية*`, type: 'snackbar' }); // *An error occurred while creating your account. Try again.*
                        this.resetSignUpState();
                        break;
                }
            }
        },
        emailKeep: async function(){
            if (this.needHelp) {
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
            var domain = this.newEmail.split('@')[1];
            const subject = "Sign Up Help";
            const message = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                case 'gmail.com':
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                default:
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
            } // end of email/gmail switch
        },
        resendVerificationEmail: function() {
            const user = firebase.auth.currentUser;
            if (user) {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
                }
                user.sendEmailVerification(actionCodeSettings).then(() => {
                    // this.$store.commit('alertUser', { show: true, text: `Verification email has been sent.`, type: 'ar-snackbar' });
                    this.$store.commit('alertUser', { show: true, text: `تم إرسال البريد الإلكتروني للتحقق`, type: 'ar-snackbar' }); // Verification email has been sent
                }).catch((err) => {
                    // this.$store.commit('alertUser', { show: true, text: `Something went wrong sending the verification email. Please try again.`, type: 'ar-snackbar' });
                    this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء إرسال رسالة التحقق بالبريد الإلكتروني. حاول ثانية`, type: 'ar-snackbar' }); // An error occurred while sending the verification email. Try again
                });
            } else {
                // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'ar-snackbar' });
                this.$store.commit('alertUser', { show: true, text: `هناك شيء غير صحيح. قم بتحديث الصفحة وحاول مرة أخرى`, type: 'ar-snackbar' }); // Something is not right. Refresh the page and try again
                console.error("No user! Refresh the page and try again.");
            }
        },
        resetSignUpState: function () {
            // if (this.addImage) this.addImage = false;
            this.profileImageURL = "";
            this.newFullname = "";
            this.newEmail = "";
            this.newPassword = "";
            this.acceptsTC = false;
            this.loading = false;
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
            this.profileImageInput= null;
            this.previewImage= "";
            this.profileImageError= false;
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: async function(){
        // this.stripe = await Stripe('pk_test_51HtI2rC3oDEbxLPSnM56fK0ldrHxhS48Z2EbKStM51enTdrclGA9H9Ae1EzOiYHh9uzZ79Ox4oFNtQeQbslrrDWT0093vBQxra');
        if (firebase.auth.currentUser) firebase.auth.signOut();
    }
};
</script>

<style>
  p i {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
