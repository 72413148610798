<template>
    <v-row justify="center" class="ma-0 pa-0">
        <Alert :key="NaN" v-if="deletePostDialog" :dialogVal="true" type="dialog" title="Are you sure you want to delete this post?" text="Your post will be permanently deleted. This action cannot be undone." @dialogClicked="deletePost"/>
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-h4 text-md-h3 font-weight-light">{{searchQuery}}</span>
            </v-row>

            <v-row v-if="postsLoading" justify="start" class="pa-0">
                <v-col v-for="(load,index) in posts.length > 0 ? posts.length : hitsPerPage" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
                    <v-skeleton-loader height="250" type="image" class="rounded-xl"></v-skeleton-loader>
                </v-col>
            </v-row>

            <v-row v-else-if="!postsLoading && emptySearch" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">Sorry, we couldn't find any posts matching your search.</span>
            </v-row>

            <v-row v-else justify="start" class="pa-0">
                <v-col v-for="(post, index) in posts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                    <Post :post="post" :homefeed="true" :index="index" :isAdmin="$store.state.userData.admin" @delete-selected-post="deleteDialog" />
                </v-col>
            </v-row>

            <v-row v-show="scrollLoading" justify="center" align="end"  style="postion: fixed; bottom: 30px;">
                <v-progress-circular fixed size="30" color="primary" indeterminate></v-progress-circular>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import Post from "./Post.vue";
// import firebase from "../../firebaseConfig.js";
import Alert from "./Alert.vue";
import algoliasearch from "algoliasearch";
// import algoliasearch from "algoliasearch/lite";

export default {
    name: "search",
    props: ["searchQuery"],
    components: {
        Post,
        Alert,
    },
    data: function() {
        return {
            posts: [],
            postsLoading: false,
            emptySearch: true,
            client: null,
            algoliaIndex: null,
            page: 0,
            hitsPerPage: 20,
            deletePostDialog: false,
            postToDelete: null,
            scrollLoading: false,
        };
    },
    methods: {
        getAlgoliaResults: function() {
            if (this.page == 0) this.postsLoading = true;
            this.algoliaIndex.search(this.searchQuery, {
                page: this.page,
                hitsPerPage: this.hitsPerPage,
            }).then(({ hits }) => {
                if (hits.length != 0) {
                    this.emptySearch = false;
                    this.page++;
                    hits.forEach((hit) => {
                        var time = hit.postDate;
                        var d = time.seconds ? new Date(time.seconds * 1000) : new Date(time);
                        let day = d.getDate();
                        let month = d.toLocaleDateString('default', {month: 'short'});
                        let year = d.getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var newDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                        hit.postDate = newDate;
                        this.posts.push(hit);
                    });
                    window.addEventListener("scroll", this.atBottom);
                } else if (this.page >= 1) {
                    window.removeEventListener("scroll", this.atBottom);
                } else {
                    this.emptySearch = true;
                    window.removeEventListener("scroll", this.atBottom);
                }
                if (this.emptySearch || this.page == 1) this.postsLoading = false;
                this.scrollLoading = false;
            });
        },
        atBottom: function () {
            if(!this.postsLoading) {
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                if (bottomOfWindow) {
                    this.scrollLoading = true;
                    window.removeEventListener("scroll", this.atBottom);
                    this.getAlgoliaResults();
                } 
            }
        },
        deleteDialog: function (post) {
            this.deletePostDialog = true;
            this.postToDelete = post;
        },
        deletePost: async function(val) {
            if (!val) {
                this.deletePostDialog = false;
            } else {
                let post = this.postToDelete.post;
                let index = this.postToDelete.index;
                this.$store.dispatch('deletePostFromDb', { post: post, index: index, deleteFromStore: false } ); // profile posts are not saved in the store
                this.posts.splice(index, 1);
                this.$store.commit('alertUser', { show: true, text: `Successfully deleted post.`, type: 'snackbar' });
                this.deletePostDialog = false;
            }
        },
    },
    watch: {
        'searchQuery' (newValue, oldValue) {
            if (newValue != oldValue) {
                this.page = 0;
                this.posts = [];
                this.getAlgoliaResults();
            }
        }
    },
    created: function() {
        // 0699bd7776423913c0bcb964b4d69afd
        this.client = algoliasearch('VBLINR2SBK','b6d53c3533c83a447e8a0fe9280db287');
        this.algoliaIndex = this.client.initIndex('search_POSTS');
        this.getAlgoliaResults();
    },
    mounted: function () {
        window.addEventListener("scroll", this.atBottom);
    },
    beforeDestroy: function () {
        window.removeEventListener("scroll", this.atBottom);
    },
};
</script>
