<template>
<v-row justify="center">
    <v-col xl="5" lg="6" md="7" sm="7" xs="12">
        <v-sheet outlined class="px-8 py-4">
            <div class="text-center mb-8 text-h6">Cancel Subscription</div>
            <v-row>
                <div class="mb-8 text-subtitle-1">You are cancelling your {{stripeCustomer.product.name}} subscription. This will downgrade you to the Basic account, which does not have the features you are currently able to access. You will also be charged for the last invoice, if unpaid, when cancelling the subscription.</div>
            </v-row>
            <v-row justify="space-between">
                <v-btn depressed small text class="primary--text" @click="$emit('keep')">Keep</v-btn>
                <v-btn depressed small text class="text-darken-2 red--text" @click="cancelSubscription()">Cancel</v-btn>
            </v-row>
        </v-sheet>
    </v-col>
</v-row>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from "../../firebaseConfig.js";
import Alert from "../components/Alert.vue";
export default {
    name: 'CancelSubscription',
    components: { 
        Alert,
    },
    data: function() {
        return {
            snackbar: false,
            snackbarText: "",
            userData: this.$store.state.getUserData,
        }
    },
    methods: {
        cancelSubscription(){
            this.$http.post(`/stripe/cancel-subscription/${this.stripeCustomer.subscription.id}`)
            .then((response) => {
                if (response.data.error){
                    this.snackbar = true;
                    this.snackbarText = response.data.error + ", Try again.";
                }
                this.$store.dispatch('setStripeSubscription', response.data.subscription);
                this.$emit('updateSubscription', { subscription: response.data, type: 'cancel' });
            })
        },
    },
    computed: {
        stripeCustomer(){
            return this.$store.state.stripeCustomer;
        }
    },
    created: function(){
        // if (this.userData.id === undefined){
        //     firebase.db.collection("userData").doc(firebase.auth.currentUser.uid).get()
        //     .then((user) => {
        //         if (user.exists){
        //             this.userData = user.data();
        //             this.getStripeStatus();

        //         }
        //     })
        // } else {
        //     this.getStripeStatus();
        // }
    },
    mounted: function() {

    },
}
</script>


    