<template>
    <div>
        <v-row justify="space-around" align="center" no-gutters class="mx-0">
            <v-col cols="2" align="center" class="my-0 py-2">
                <span class="text-wrap text-subtitle-1 font-weight-black">{{index+1}}.</span>
            </v-col>
            <v-col cols="10" class="my-0 py-2">
                <v-textarea v-if="edit && onRouteOwnerCanEditFrom" @change="hasBeenEdited = true" v-model="localCaption.subtitle" class="text-subtitle-1 font-weight-black pr-1" rows="1" :light="isPostAudioData" hide-details outlined no-resize auto-grow></v-textarea>
                <span v-else class="text-wrap text-subtitle-1 font-weight-black">{{caption.subtitle}}</span>
            </v-col>
            <v-row v-if="postType === 'group' && localCaption.audioSegFeedback.hasFeedback && !feedback" justify="center" class="mx-0">
                <v-col cols="2" align="center" class="my-0 pt-0">
                    <v-icon class="pl-2">mdi-arrow-right-bottom</v-icon>
                </v-col>
                <v-col cols="8" class="my-0 pt-0">
                    <span class="text-wrap text-subtitle-2 font-weight-medium">{{caption.audioSegFeedback.feedback}}</span>
                </v-col>
            </v-row>
            <v-row v-else-if="postType === 'group' && feedback" justify="center" class="mx-0">
                <v-col cols="2" align="center" class="my-0 pt-0">
                    <v-icon class="pl-2" :light="isPostAudioData">mdi-arrow-right-bottom</v-icon>
                </v-col>
                <v-col cols="9" class="my-0 pt-2">
                    <v-textarea @change="feedbackEditted = true" v-model="localCaption.audioSegFeedback.feedback" class="text-subtitle-2 font-weight-medium pr-1" :light="isPostAudioData" label="Enter feedback here..." rows="1" hide-details outlined no-resize auto-grow></v-textarea>
                </v-col>
            </v-row>
        </v-row>

        <v-row justify="space-around" align="start" no-gutters class="mx-0 pb-2">
            <v-col cols="8" class="my-0">
                <v-row justify="start" no-gutters class="mx-0">
                    <span class="text-caption font-weight-normal pr-1">Start:</span>
                    <span class="text-caption font-weight-light pr-2"><span v-if="hours > 0">{{hours}}h:</span><span v-if="minutes > 0">{{minutes}}m:</span><span>{{seconds}}s</span></span>
                    <span class="text-caption font-weight-normal pr-1">Duration:</span>
                    <span class="text-caption font-weight-light">{{ audioSeg.audioDur.toFixed(1) }}s</span>
                </v-row>
            </v-col>
            <v-col cols="3" class="my-0">
                <v-row justify="end" no-gutters class="mx-0">
                    <!-- this is for the owner of a contribution to be able to edit their audio segments subtitles -->
                    <v-tooltip v-if="owner && onRouteOwnerCanEditFrom && (isGroupAdmin === null || isGroupAdmin === false)" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="editCaption" v-on="on" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-sm-and-down pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                            <v-btn @click.stop="editCaption" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-md-and-up pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{edit ? 'Save caption' : 'Edit caption'}}</span>
                    </v-tooltip>

                    <!-- this is for an editor of a contribution to be able to edit the audio segments subtitles -->
                    <v-tooltip v-if="editor && !owner && onRouteOwnerCanEditFrom" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="editCaption" v-on="on" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-sm-and-down pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                            <v-btn @click.stop="editCaption" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-md-and-up pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{edit ? 'Save caption' : 'Edit caption'}}</span>
                    </v-tooltip>

                    <!-- This div is for a group post where the owner of the AudioSegment is also a group admin. In this case we only
                    want to show the edit button because a group admin shouldn't need to give feedback on their own segment -->
                    <v-tooltip v-if="owner && onRouteOwnerCanEditFrom && isGroupAdmin" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="editCaption" v-on="on" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-sm-and-down pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                            <!-- :disabled="edit" -->
                            <v-btn @click.stop="editCaption" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-md-and-up pr-1">
                                <v-icon>{{edit ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{edit ? 'Save caption' : 'Edit caption'}}</span>
                    </v-tooltip>

                    <!-- This div is for a group post where the user is the group admin and not the owner of the post. In this case we
                    will show a comment button so the group admin can give feedback to the post owner -->
                    <v-tooltip v-else-if="!owner && onRouteOwnerCanEditFrom && isGroupAdmin" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="giveFeedback" v-on="on" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-sm-and-down pr-1">
                                <v-icon>{{feedback ? 'mdi-comment-plus' : 'mdi-comment'}}</v-icon>
                            </v-btn>
                            <v-btn @click.stop="giveFeedback" icon x-small :color="isPostAudioData ? $vuetify.theme.dark ? '#5d5d5d' : '' : ''" class="hidden-md-and-up pr-1">
                                <v-icon >{{feedback ? 'mdi-comment-plus' : 'mdi-comment'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{feedback ? 'Save Feedback' : 'Give Feedback'}}</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon outlined color="accent" :disabled="videoLoading" v-on="on" x-small @click.native.stop="setAudioSegment()" class="hidden-md-and-down">
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                            <v-btn icon outlined color="accent" :disabled="videoLoading" x-small @click.native.stop="setAudioSegment()" class="hidden-lg-and-up">
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                        </template>
                        <span>Play this segment</span>
                    </v-tooltip>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :light="isPostAudioData" :dark="!isPostAudioData && $vuetify.theme.dark"/>
    </div>
</template>

<script>

import firebase from "../../firebaseConfig.js";

export default {
    name: 'AudioSegment',
    props: ["index", "audioSeg", "caption", "audioUserId", "onRouteOwnerCanEditFrom", "isGroupAdmin", "postType", "isPostAudioData", "editor", "videoLoading"],
    data: function () {
        return {
            feedback: false,
            feedbackEditted: false,
            hours: 0,
            minutes: 0,
            seconds: 0,
            edit: false,
            hasBeenEdited: false,
            owner: false,
            localCaption: {
                // audioSegFeedback: {},
            },
        }
    },
    methods: {
        setAudioSegment: function () {
            var audioObj = {
                audioSeg: this.audioSeg,
                caption: this.caption.subtitle,
                index: this.index,
            };
            this.$emit('setAudioSegment', audioObj);
        },
        secondsToTime: function(secs){
            secs = Math.round(secs);
            this.hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            this.minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            this.seconds = Math.ceil(divisor_for_seconds);
        },
        editCaption: function () {
            this.edit = !this.edit;
            this.$emit("setIsInEditState", true);
            // Save button has been pressed
            if (!this.edit) {
                // caption has been changed continue with process
                if (this.hasBeenEdited) {
                    if (this.localCaption.subtitle != "") {
                        // emit caption all the way up to parent element
                        this.$emit("edit-caption", this.localCaption);
                        this.hasBeenEdited = false;
                    }
                }
                this.$emit("setIsInEditState", false);
            }
        },
        giveFeedback: function () {
            this.feedback = !this.feedback;
            this.$emit("setIsInEditState", true);
            if (!this.feedback) {
                if (this.feedbackEditted) {
                    if(this.localCaption.audioSegFeedback.feedback != "") {
                        this.localCaption.audioSegFeedback.date = new Date();
                        this.localCaption.audioSegFeedback.userId = firebase.auth.currentUser.uid;
                        this.localCaption.audioSegFeedback.username = firebase.auth.currentUser.displayName;
                        this.localCaption.audioSegFeedback.hasFeedback = true;
                        this.$emit("edit-caption", this.localCaption);
                        this.feedbackEditted = false;
                    }
                }
                this.$emit("setIsInEditState", false);
            }
        },
        isOwner: function() {
            if (this.audioUserId === firebase.auth.currentUser.uid ) this.owner = true;
        },
    },
    created: function() {
        this.secondsToTime(this.audioSeg.audioStart);
        this.isOwner();
        if (this.postType === 'group') {
            if (typeof this.caption.audioSegFeedback !== 'undefined') {
                this.localCaption = this.caption;
            } else {
                var audioSegFeedback = {
                    userId: null,
                    username: null,
                    date: null,
                    feedback: null,
                    hasFeedback: false,
                };
                this.localCaption = this.caption;
                this.localCaption.audioSegFeedback = audioSegFeedback;
            }
        } else {
            this.localCaption = this.caption;
        }
    },
}
</script>

<style scoped>
</style>