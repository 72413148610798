<template>
    <!-- class="rounded-lg" :class="[$vuetify.theme.dark ? 'grey lighten-1' : 'grey darken-4' ]" -->
    <v-card class="pa-0" v-bind:class="[ isPostAudioData() ? 'originalAudio black--text' : $vuetify.theme.dark ? 'grey darken-3' : '' ]">
        <v-row class="mx-2 py-1" no-gutters justify="space-between" align="center">
            <v-avatar size="55">
                <v-img lazy-src="../../assets/no-user.png" :src="audio.audioData.userProfilePhoto ? audio.audioData.userProfilePhoto : require('../../assets/no-user.png')" :alt="audio.audioData.username"></v-img>
            </v-avatar>
            <div class="d-flex flex-column" style="max-width: 50%;">
                <v-card-title class="d-inline-block text-truncate text-lg-h6 text-body-1 text-sm-subtitle-2">
                {{audio.audioData.title}}
                </v-card-title>
                <v-card-subtitle class="hidden-sm-and-down text-center font-light font-italic text-caption" v-bind:class="[ isPostAudioData() ? 'black--text' : $vuetify.theme.dark ? 'inverse--text' : 'inverse--text']">{{audio.audioData.date.toDate().toLocaleDateString()}}</v-card-subtitle>
            </div>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <!-- hidden small and down needs to be added -->
                    <v-btn v-on="on" @click.stop="$emit('setSelectedAudio',audio.audioData)" icon color="accent">
                        <v-icon x-large>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Update text on image</span>
            </v-tooltip>
        </v-row>
        <v-row no-gutters justify="space-between">
            <v-btn icon @click="audio.showMore = !audio.showMore" :ripple="false">
                <v-icon large class="grey--text text--darken-1">{{ audio.showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <router-link v-if="audio.audioData.userId == currentUserId" :to="{ path: 'profile' }" class="mr-3 mt-2 text-decoration-none primary--text font-weight-light font-italic text-caption text-center text-truncate" style="cursor: pointer;">@{{audio.audioData.username}}</router-link>
            <router-link v-else :to="{name: 'view-user-profile', query: {id: audio.audioData.userId}}" class="mr-3 mt-2 text-decoration-none primary--text font-weight-light font-italic text-caption text-center text-truncate" style="cursor: pointer;">@{{audio.audioData.username}}</router-link>
            <v-icon v-if="isPostAudioData()" color="amber" class="mr-2 text--darken-1">mdi-star</v-icon>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="isOwner() && !isPostAudioData()"
                        icon
                        v-on="on"
                        v-on:mouseup="
                        $emit('deleteSelectedAudio', audio)
                        "
                    >
                        <v-icon class="grey--text text--darken-1">mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Delete Contribution</span>
            </v-tooltip>
        </v-row>
        <v-row v-if="audio.showMore" class="mx-0">
            <v-row class="mx-2" justify="space-between">
                <span v-if="audio.audioData.languageSelected" class="text-truncate text-no-wrap">{{audio.audioData.languageSelected.text}}</span>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="hidden-sm-and-down" v-on="on" @click.stop="reportDialog = true" small icon><v-icon class="grey--text text--darken-1">mdi-flag</v-icon></v-btn>
                        <v-btn class="hidden-md-and-up" @click.stop="reportDialog = true" small icon><v-icon class="grey--text text--darken-1">mdi-flag</v-icon></v-btn>
                    </template>
                    <span>Report Audio</span>
                </v-tooltip>
                <Report v-if="reportDialog" :dialog="reportDialog" page="audioData" :post="audio" @close-report="reportDialog = false"/>
            </v-row>
            <v-col v-for="(text,i) in audio.audioData.text" :key="i" cols="12">
                <v-card class="rounded-lg">
                    <v-row @click.stop="$emit('textClicked',text, audio.audioData)" justify="space-between" class="mx-0" style="cursor: pointer;">
                        <v-card-title >{{text.text}}</v-card-title>
                        <v-btn class="my-2 mx-2" icon large  color="accent" :ripple="false">
                            <v-icon>{{selectedText == text ? 'mdi-playlist-minus' : 'mdi-playlist-plus'}}</v-icon>
                        </v-btn>
                    </v-row>
                    <v-divider class="mx-3"/>
                    <v-card-subtitle>{{text.caption ? text.caption : 'no caption'}}</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
// import fb from "firebase/app";
import firebase from "../../../firebaseConfig.js";
import Report from "../Report.vue";

export default {
    name: "audio-data",
    props: ["audio", "selectedText", "postAudioDataId",],
    components: {
        Report,
    },
    data: function () {
        return {
            reportDialog: false,
            originalPost: false,
            currentUserId: firebase.auth.currentUser.uid,
        };
    },
    methods: {
        isOwner: function() {
            if (this.audio.audioData.userId == this.currentUserId && !this.isPostAudioData()) {
                return true;
            } else {
                return false;
            }
        },
        isPostAudioData: function() {
            if (this.audio.audioData.audioDataId == this.postAudioDataId) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {},
    computed: {},
    beforeCreate: function () {},
    created: function () {
        // console.log(this.audio.audioData);
    },
    mounted: function () {},
    updated: async function () {},
    beforeDestroy: function () {},
    destroyed: function () {},
}
</script>

<style scoped>
.originalAudio {
    background-color: #e3e3e3 !important;
}
</style>