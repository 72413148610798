<template>
    <div>
        <h1>FAQ</h1>
    </div>
</template>

<script>
import firebase from "../../firebaseConfig.js";

export default {
    name: "faq",
    metaInfo: {
        title: 'FAQ - tawqin'
    },
    props: [],
    components: { },
    data() {
        return {
            signedIn: false,
        }
    },
    methods:{},
    created: function() {
        if(firebase.auth.currentUser){
            this.signedIn = true;
        }
    }
}
</script>