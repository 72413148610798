<template>
<v-row justify="center">
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
    origin="bottom center"
  >
    <!-- transition="scale-transition" -->
    <Alert
      :key="NaN"
      v-if="backDialog"
      :dialogVal="true"
      type="dialog"
      title="Are you sure you want to continue?"
      text="Your audio/subtitles will be deleted and you will have to recreate them."
      @dialogClicked="cancelAudioCreation"
    />
    
    <v-card>

    <v-toolbar flat color="primary">
        <v-btn v-if="connected" icon large @click.stop="notConnectedCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-else icon large @click.stop="notConnectedCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h4 class="white--text">PICKUP TO TAWQ</h4>
    </v-toolbar>
    
      <div
        v-if="!connected"
        class="d-flex flex-column align-center justify-center px-2 mr-0 ml-0 fill-height"
        style="height: 90vh; width: 100%; word-break: normal"
      >
        <h2 class="text-center">You are currently NOT connected</h2>
        <br />
        <p class="text-center">Your Phone is your Remote!</p>

        <p class="text-center" style="word-break: normal">
          To get started simply open a browser <br/>on your computer and navigate to
          our website
          <span class="font-weight-bold text-h6">tawq.in</span>.
        </p>
        <p class="text-center">
          Locate the '
          <span class="font-weight-bold">+ </span>' in the top right corner <br/>of
          the screen and follow the steps to get connected.
        </p>
      </div>
      <Pickup ref="pickup" v-else :dbRefs="dbRefs" :segNumToStart="startSegId"/>
    </v-card>
  </v-dialog>
  </v-row>
</template>

<script>
import Pickup from "../../components/Mobile/Pickup.vue";
import Alert from "../../components/Alert.vue";


export default {
  name: "fullscreen-mobile",
  components: {
    Pickup,
    Alert,
  },
  props: ["dialog", "dbRefs"],
  data() {
    return {
      connected: false,
      pickupDialog: false,
      backDialog: false,
      postId: null,
      audioDataId: null,
      startSegId: 0,
    };
  },
  methods: {
    cancelAudioCreation: function (val) {
      if (val) {
        // this.cancelFullscreenMobile();
        this.backDialog = false;
      } else {
        this.backDialog = false;
      }
    },
    turnOffRTDB: function () {
      this.dbRefs.matchRef.off();
      this.dbRefs.tawqinRef.off();
      // const allRefs = firebase.rtDb.ref(userId);
      // allRefs.off();
      // allRefs.remove();
    },
    notConnectedCancel: function () {
      this.$emit("notConnectedCancelFullscreenMobile");
    },
    cancelFullscreenMobile: function () {
      this.$emit("cancelFullscreenMobile", this.postId, this.audioDataId);
    },
    disableMicrophone: function() {
      if (this.$refs.pickup != undefined){
        this.$refs.pickup.disableMicrophone();
      }
    },
  },
  created: function () {
    // console.log("created full screen mobile")
  },
  mounted: function () {
    // TODO: check RTDB to see what the connection status for both mobile and desktop is.
    // if they are both connected, then send a both connected status to the RTDB. The desktop
    // will be waiting for the connection status.

    this.dbRefs.matchRef.on("value", (snapshot) => {
      var connectedOn;
      if (snapshot.val() != null){
        // TODO: check to make sure we don't need to have the mobileConnect here.
        // My thoughts are that if a user opens FullscreenMobile it implies that
        // they are connected on a mobile device and we don't "technically" need
        // to check for that. This should prevent errors if they exit out of creation
        // on desktop but are still in Pickup to Tawq on mobile, and will still allow
        // them to reconnect when reopening. We are not checking for moibleConnect anywhere
        // else in the app currently.
        if (snapshot.val().desktopConnect){// && snapshot.val().mobileConnect) {
          this.connected = true;
          connectedOn = {
            connected: true,
          }
          // console.log(this.connected);
          // this.dbRefs.matchRef.update(connectedOn);
        } else {
          this.connected = false;
          connectedOn = {
            connected: false,
          }
          // console.log(this.connected);
        }
        this.dbRefs.matchRef.update(connectedOn);
      } else {
        this.connected = false;
        var connectedOff = {
          connected: false,
        }
        this.dbRefs.matchRef.update(connectedOff);
      }
    });

    this.dbRefs.tawqinRef.on(
      "value",
      (snapshot) => {
        // console.log("Tawqin Data: ", snapshot.val());
        if (snapshot.val() != null) {
          this.postId = snapshot.val().data.postId;
          this.audioDataId = snapshot.val().data.audioDataId;
          this.startSegId = snapshot.val().data.startSegId;
        }
      },
      (err) => {
        console.log(err.message);
        //show error message somewhere on page?
      }
    );
  },
  updated: function () {},
  beforeDestroy: function () {
    // console.log("destroyed full screen mobile")
    // before we destroy this component we want to make sure we send a matchRef to
    // the RTDB to let it know that the connection with both the mobile and desktop
    // has been disconnected and we need to reset that value to false. This will let
    // the desktop know that the connection between it and mobile has been broken.

    this.turnOffRTDB();
    var connectedOff = {
      connected: false,
    }
    this.dbRefs.matchRef.update(connectedOff);
    this.disableMicrophone();
  },
};
</script>

<style>
  .v-btn::before{
    background-color: transparent !important;
  }
</style>