<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelPromoteMember">
        <v-card v-if="!loading" class="rounded-xl">
            <v-card-title v-if="member.role == 'admin' && member.userId != currentUser" style="word-break: normal" class="text-center justify-center">
                <span class="font-weight-bold text-h5">Demote <span class="font-weight-black">{{ member.username }}</span> from Admin?</span>
                <span class="font-weight-light text-subtitle-2 mt-3">By demoting a user, you remove all admin access from that user.</span>
            </v-card-title>
            <v-card-title v-else style="word-break: normal" class="text-center justify-center">
                <span class="font-weight-bold text-h5">Promote <span class="font-weight-black">{{ member.username }}</span> to Admin?</span>
                <span class="font-weight-light text-subtitle-2 mt-3">By promoting you give this user access to all admin features for this group.
                    These include adding and deleting users, accepting or rejecting join requests, promoting or demoting users, updating group thumbnail,
                    toggle private contributions and deleting the group.
                </span>
            </v-card-title>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelPromoteMember">Cancel</v-btn>
                    <v-btn v-if="member.accepted && member.role != 'admin' && member.userId != currentUser" color="primary" text @click="promoteMemberToAdmin">Promote</v-btn>
                    <v-btn v-if="member.accepted && member.role == 'admin' && member.userId != currentUser" color="primary" text @click="demoteMemberFromAdmin">Demote</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="font-weight-bold text-h5">{{ !promote ? 'Demoting' : 'Promoting'}} <span class="font-weight-black">{{ member.username }}</span> {{ !promote ? 'from' : 'to'}} Admin</span>
            </v-card-title>
            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-bold text-subtitle-2 text-center">Please wait...</span><br/>
                    <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";


export default {
    name: "promote-member",
    props: ["dialog", "groupId", "groupName", "member"],
    data() {
        return {
            currentUser: firebase.auth.currentUser.uid,
            loading: false,
            promote: null,
        };
    },
    methods: {
        promoteMemberToAdmin: function () {
            this.loading = true;
            this.promote = true;
            var invitingAdminName = firebase.auth.currentUser.displayName;
            this.$http.put(`groups/${this.groupId}/admin`, {
                adminName: invitingAdminName,
                member: this.member,
                promote: true,
            }).then((response) => {
                if (response.status == 200) {
                    this.loading = false;
                    this.promote = null;
                    this.cancelPromoteMember();
                }
            }).catch((err) => {
                this.loading = false;
                this.promote = null;
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                this.cancelPromoteMember();
            });
        },
        demoteMemberFromAdmin: function () {
            this.loading = true;
            this.promote = false;
            this.$http.put(`groups/${this.groupId}/admin`, {
                member: this.member,
                promote: false,
            }).then((response) => {
                if (response.status == 200) {
                    this.loading = false;
                    this.promote = null;
                    this.cancelPromoteMember();
                }
            }).catch((err) => {
                this.loading = false;
                this.promote = null;
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                this.cancelPromoteMember();
            });
        },
        cancelPromoteMember: function () {
            if(!this.loading) {
                this.$emit("cancelPromoteMember");
            }
        },
    },
};
</script>
