<template>
    <div class="elevation-4 rounded-xl" @click="$store.commit('setCurrentPost', post)">
        <div style="position: relative;">
            <router-link :to="{ name: 'new-post', params: { draftPost: post, isDraftPost: true } }" class="text-decoration-none">
                <v-img :src="thumbnailExists() ? post.thumbnail : getDefaultThumbnail" height="250" class="rounded-xl">
                    <div style="position: absolute; bottom:0; left:0; width:100%;" class="image-overlay">
                        <v-card-title class="text-h5 font-weight-bold d-block text-truncate white--text">{{post.title}}</v-card-title>
                        <div class="date-language pl-4 mb-1">
                            <span class="caption white--text text-truncate">{{post.languageSelected.text}}</span>
                            <span class="caption white--text">{{post.postDate}}</span>
                        </div>
                        <div style="position: relative;">
                            <span style="position: absolute; bottom:0; right:0;" class="caption pr-4 mb-1" :class="[ $vuetify.theme.dark ? 'amber--text' : 'primary--text' ]">@{{post.username}}</span>
                            <span style="position: absolute; bottom: 17px; right:0;" class="caption pr-4 mb-1" :class="[ $vuetify.theme.dark ? 'amber--text' : 'primary--text' ]">{{post.groupName}}</span>
                        </div>
                    </div>
                </v-img>
                <v-icon style="position: absolute; top:0; left:0;" dark class="my-3 mx-3 text-shadow" small>{{post.draftVideo.type.includes('video') ? 'mdi-video' : 'mdi-waveform'/* mdi-waveform, mdi-microphone, mdi-microphone-variant, mdi-headphones, mdi-headset, mdi-volume-high, mdi-account-voice, */}}</v-icon>
            </router-link>


            <v-menu rounded="lg" offset-y bottom origin="center" transition="scale-transition" style="position: absolute; top:0; right:0;" class="py-1 px-1">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon style="position: absolute; top:0; right:0;" class="py-1 px-1 button-shadow">
                        <v-icon :color="$vuetify.theme.dark ? 'amber' : 'primary'">mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list rounded>
                    <v-list-item @click.stop="$router.push({ name: 'new-post', params: { draftPost: post, isDraftPost: true } })"><v-icon class="pr-2">mdi-progress-upload</v-icon>Continue</v-list-item>
                    <v-list-item v-if="permissionToDelete()" @click.stop="$emit('delete-selected-post', {post: post, index: index, isDraft: true})"><v-icon class="pr-2">mdi-delete</v-icon>Delete</v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import firebase from "../../firebaseConfig.js";


export default {
    name: "DraftPost",
    props: ["post", "index", "userData"],
    data: function() {
        return {};
    },
    methods: {
        thumbnailExists: function() {
            if (this.post.thumbnail === "" || this.post.thumbnail === null) {
                return false;
            } else {
                return true;
            }
        },
        permissionToDelete: function() {
            return this.post.userId === firebase.auth.currentUser.uid;
        },
    },
    computed: {
        getDefaultThumbnail(){
            if(this.post.videoType === 'video/youtube'){
                if (typeof this.post.videoUrl.split('v=')[1] != 'undefined'){
                    const videoId = this.post.videoUrl.split('v=')[1].split('&ab_channel')[0];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    return youtube_static_url;
                } else if (typeof this.post.videoUrl.split('be/')[1] != 'undefined'){
                    const videoId = this.post.videoUrl.split('be/')[1];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    return youtube_static_url;
                } else if (typeof this.post.videoUrl.split('embed/')[1] != 'undefined') {
                    const videoId = this.post.videoUrl.split('embed/')[1];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    return youtube_static_url;
                } else {
                    return require('../assets/no-thumbnail.png');
                }
            } else {
                if (this.post.videoType.split('/')[0] === 'audio') {
                    return require('../assets/audio-only.jpeg');
                } else {
                    return require('../assets/no-thumbnail.png');
                }
            }
        }
    },
};
</script>

<style>
.image-overlay {
    height: auto;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 0) 0%,rgba(0,0,0, .9) 100%);
}
.button-shadow:hover {
    text-shadow: 1px 1px 2px black;
}
.date-language {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
</style>
