<template>
    <div style="max-width: 100%;">

        <v-app-bar app flat fixed style="max-width: 100%; z-index: 9;" :class="[scrollPosition < headerHeight && (!showPrivacyPolicy && !showTermsConditions) ? 'transparent' : showPrivacyPolicy || showTermsConditions ? 'primary' : 'primary elevation-1']">
            <v-col cols="4" class="ma-0">
                <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../assets/whitetransparent.png" style="cursor: pointer;"></v-img>
            </v-col>
            <v-spacer/>
            <v-col cols="8" align="end" class="ma-0 pa-0">
                <v-btn color="white" outlined class="mr-2 font-weight-normal" @click.stop="buttonClick('login')">Login</v-btn>
                <v-btn color="white" outlined class="font-weight-normal" @click.stop="buttonClick('appbar')">Sign Up</v-btn>
            </v-col>
        </v-app-bar>

        <div>
            <!-- class="mb-8" -->
            <div class="pa-0 ma-0" :class="isMobile ? 'header-mobile' : 'header'" ref="header">
                <v-row align="center" justify="center" style="height: 100%; width: 100%;" class="ma-0 pa-0">
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                        <!-- <v-img :max-width="isMobile ? '300px' : '550px'" src="../assets/whitetransparent.png"/> -->

                        <!-- <p style="text-shadow: 1px 1px 2px #588157; font-family: 'Mukta', sans-serif !important;" class="white--text text-h2 text-lg-h1 font-weight-bold my-0">tawq.in</p>
                        <p style="text-shadow: 1px 1px 2px #588157;" class="white--text font-italic font-weight-normal text-subtitle-1 text-lg-h5 my-0 mt-5">Empower Voices, Unlock Possibilities.</p> -->
                        <p style="text-shadow: 10px 10px 20px #588157; font-family: 'Mukta', sans-serif !important;" :style="isMobile ? 'font-size: 100px' : 'font-size: 150px'" class="white--text font-weight-bold">tawq.in</p>
                        <p style="text-shadow: 10px 10px 20px #588157;" class="white--text font-italic font-weight-normal text-subtitle-1 text-lg-h5">Empower Voices, Unlock Possibilities.</p>

                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">Your voice, over any video.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5" style="text-shadow: 1px 1px 2px #588157;">Empower Voices, Share Cultures.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5" style="text-shadow: 1px 1px 2px #588157;">Connect, Caption, Create: Your Voice, Your Way.</p> -->

                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">Your Voice, Guiding the World's Ears.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">Your voice, guiding our journey.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">Your Voice, Your Story.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">With your voice, the sky is the limit.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5">Speak, inspire, and change the world with your voice.</p> -->
                        <!-- <p class="secondary--text text--lighten-1 font-italic font-weight-light text-subtitle-1 text-lg-h5 my-0 mt-5"></p> -->


                        <v-btn color="white" x-large class="mt-8 primary--text action-button hidden-sm-and-down" @click.stop="buttonClick('header')">Get Started, It's Free!</v-btn>
                        <v-btn color="white" medium class="mt-5 primary--text action-button hidden-md-and-up" @click.stop="buttonClick('header')">Get Started, It's Free!</v-btn>
                        <!-- <v-btn class="mt-8 primary--text action-button" color="white" x-large :to="{name: 'sign-up'}">Join Us, It's Free!</v-btn> -->
                        <!-- <v-btn class="mt-8 primary--text action-button" color="white" x-large :to="{name: 'sign-up'}">Sign Up, It's Free!</v-btn> -->
                    </v-col>
                </v-row>
                <!-- Waves Container -->
                <div>
                    <svg class="waves" ref="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="wave-parallax">
                            <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                            <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
                <!-- Waves Container -->
            </div>

            <!-- SECTION 0 : QUICK INFO -->
            <v-row class="ma-0 pa-0" justify="center" align="center">
                <!-- CREATE -->
                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <v-btn icon :ripple="false" color="white" class="hib-btn" @click.stop="scrollToContent('section-1')">
                        <v-icon size="100" class="hib">mdi-head-lightbulb</v-icon>
                    </v-btn>
                    <div class="text-h4 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-1')">Create</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>
                <v-col cols="3" align="center" class="mt-5 hidden-md-and-up">
                    <v-btn icon :ripple="false" color="white" class="hib-btn-sm" @click.stop="scrollToContent('section-1')">
                        <v-icon size="50" class="hib">mdi-head-lightbulb</v-icon>
                    </v-btn>
                    <div class="text-h6 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-1')">Create</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>


                <!-- EXPLORE -->
                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <v-btn icon :ripple="false" color="white" class="hib-btn" @click.stop="scrollToContent('section-2')">
                        <v-icon size="100" class="hib">mdi-map-search</v-icon>
                    </v-btn>
                    <div class="text-h4 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-2')">Explore</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>
                <v-col cols="3" align="center" class="mt-5 hidden-md-and-up">
                    <v-btn icon :ripple="false" color="white" class="hib-btn-sm" @click.stop="scrollToContent('section-2')">
                        <v-icon size="50" class="hib">mdi-map-search</v-icon>
                    </v-btn>
                    <div class="text-h6 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-2')">Explore</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>


                <!-- CONTRIBUTE -->
                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <v-btn icon :ripple="false" color="white" class="hib-btn" @click.stop="scrollToContent('section-3')">
                        <v-icon size="100" class="hib">mdi-account-multiple-plus</v-icon>
                    </v-btn>
                    <div class="text-h4 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-3')">Contribute</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>
                <v-col cols="3" align="center" class="mt-5 hidden-md-and-up">
                    <v-btn icon :ripple="false" color="white" class="hib-btn-sm" @click.stop="scrollToContent('section-3')">
                        <v-icon size="50" class="hib">mdi-account-multiple-plus</v-icon>
                    </v-btn>
                    <div class="ml-n3 mr-n3 text-h6 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-3')">Contribute</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>


                <!-- SHARE -->
                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <!-- cols="12" lg="3" md="6" sm="6" -->
                    <v-btn icon :ripple="false" color="white" class="hib-btn" @click.stop="scrollToContent('section-4')">
                        <v-icon size="100" class="hib">mdi-share</v-icon>
                    </v-btn>
                    <div class="text-h4 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-4')">Share</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>
                <v-col cols="3" align="center" class="mt-5 hidden-md-and-up">
                    <!-- cols="12" lg="3" md="6" sm="6" -->
                    <v-btn icon :ripple="false" color="white" class="hib-btn-sm" @click.stop="scrollToContent('section-4')">
                        <v-icon size="50" class="hib">mdi-share</v-icon>
                    </v-btn>
                    <div class="text-h6 text-center htb" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-4')">Share</div>
                    <!-- <p class="text-subtitle-1 text-center font-weight-light mx-8 pt-2"></p> -->
                </v-col>
            </v-row>

            <!-- SECTION 1 : STEP 1 - CREATE -->
            <div id="section-1" class="section1-container section">
                <div class="mt-lg-10 mt-5 skewed-div skew-positive accent"></div>
                <div class="mt-lg-10 mt-5 skewed-div skew-negative primary">
                    <div class="s1-shape"></div>
                    <div class="s1-skew-shape"></div>
                </div>

                <v-row justify="center" align="center" class="ma-0 pa-0 non-skew">
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="order-1 order-sm-2">
                        <v-lazy v-model="showSection1" transition="lslide-fade" :options="{ threshold: 1.0 }">
                            <div>
                                <!-- <p class="pb-5 accent--text text-h3 font-weight-bold s1tb">Create Using <span class="text-decoration-underline">YOUR</span> Voice</p> -->
                                <!-- <p style="text-shadow: 1px 1px 1px black;" class="pb-5 accent--text text-h2 font-weight-black">Use <span class="text-decoration-underline">YOUR</span> Voice.</p> -->
                                <!-- <p class="s1tb text-lg-h2 text-md-h3 text-h4 accent--text font-weight-black pb-lg-5">Use <span class="text-decoration-underline">YOUR</span> Voice.</p> -->
                                <p class="s1tb text-lg-h2 text-md-h3 text-h4 accent--text font-weight-black pb-lg-5">Unlock the Power of Your Voice.</p>
                                <!-- <p class="text-subtitle-1 s1tb">Unlock the power of your voice to create meaningful content. Whether you're speaking an unwritten language, coaching athletes, or expressing yourself in unique ways, our platform empowers you to add your voice and captions to audio and video files. Tell your story, teach, inspire, and connect with the world like never before.</p> -->
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Use <span class="text-decoration-underline">YOUR</span> Voice.</p> -->
                                <p class="s1tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Our platform empowers you to add your voice and captions to audio and video files.</p>
                                <p class="s1tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Create meaningful content, and express yourself in unique ways.</p>
                                <p class="s1tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Tell your story, teach, inspire, and connect with the world like never before.</p>
                                <!-- <p class="text-h5 font-weight-bold">Unlock the power of your voice.</p>
                                <p class="text-h5 font-weight-bold">Create meaningful content, and express yourself in unique ways.</p>
                                <p class="text-h5 font-weight-bold">Our platform empowers you to add your voice and captions to audio and video files.</p>
                                <p class="text-h5 font-weight-bold">Tell your story, teach, inspire, and connect with the world like never before.</p> -->
                                <p class="text-h5 font-weight-bold s1tb"></p>
                                <p class="text-h5 font-weight-bold s1tb"></p>
                                <v-btn x-large class="mt-8 action-button-s1 hidden-sm-and-down" @click.stop="buttonClick('create')">Try It Out</v-btn>
                                <!-- <v-btn x-large class="mt-8 action-button-s1 hidden-sm-and-down" @click.stop="buttonClick('create')">Try It Now</v-btn> -->
                                <v-btn medium class="mt-5 action-button-s1 hidden-md-and-up" @click.stop="buttonClick('create')">Try It Out</v-btn>
                            </div>
                        </v-lazy>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2 order-sm-1">
                        <v-lazy v-model="showSection1" transition="rslide-fade" :options="{ threshold: 1.0 }">
                            <img style="max-width: 100%;" src="../assets/land_page/b/s1_3x.png" srcset=" ../assets/land_page/b/s1_2x.png 1x, ../assets/land_page/b/s1_3x.png 2x">
                        </v-lazy>
                    </v-col>
                </v-row>
                <!-- </v-container> -->
            </div>
            <!-- END SECTION 1 : STEP 1 - CREATE -->

            <!-- SECTION 2 : STEP 2 - EXPLORE -->
            <div id="section-2" class="section2-container section">
                <div :class="isMobile ? 's2-svg-container-mobile' : 's2-svg-container'">
                    <svg :id="isMobile ? 's2-svg-mobile' : 's2-svg'" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 678.55 768" >
                        <defs>
                            <!-- #edb100 // one shade darker than accent color -->
                            <!-- #ffc721 // one shade lighter than accent color -->
                            <!-- <linearGradient id="s2-linear-gradient" x1="269.45" y1="384" x2="948" y2="384" gradientTransform="matrix(1, 0, 0, -1, -4.45, 768)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#edb100"/>  -->
                            <linearGradient id="s2-linear-gradient" x1="269.45" y1="384" x2="948" y2="384" gradientTransform="matrix(1, 0, 0, -1, -4.45, 768)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#ffc107"/>
                                <!-- <stop offset="1" stop-color="#edb100"/> -->
                                <stop offset="1" stop-color="#ffc107"/>
                            </linearGradient>
                            <linearGradient id="s2-linear-gradient-2" x1="695" y1="665.49" x2="939" y2="665.49" gradientTransform="matrix(1, 0, 0, -1, -4.45, 768)" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#ffc107" stop-opacity="0"/>
                                <stop offset="0.11" stop-color="#ffcd38" stop-opacity="0.1"/>
                                <stop offset="0.29" stop-color="#ffdf7f" stop-opacity="0.24"/>
                                <stop offset="0.47" stop-color="#ffedb7" stop-opacity="0.36"/>
                                <stop offset="0.65" stop-color="#fff7df" stop-opacity="0.44"/>
                                <stop offset="0.83" stop-color="#fffdf7" stop-opacity="0.48"/>
                                <stop offset="1" stop-color="#fff" stop-opacity="0.5"/>
                            </linearGradient>
                            <linearGradient id="s2-linear-gradient-3" x1="283" y1="102" x2="527" y2="102" gradientTransform="matrix(1, 0, 0, -1, -4.45, 768)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#ffc107"/>
                                <stop offset="1" stop-color="#fff" stop-opacity="0"/>
                            </linearGradient>
                        </defs>
                        <path id="curve" d="M316.9,0S187.38,319.16,337.55,475c106,110,36.71,293,36.71,293H943.55V0Z" transform="translate(-265)" style="fill:url(#s2-linear-gradient)"/>
                        <path d="M723.15,0A122,122,0,1,0,902,0Z" transform="translate(-265)" style="fill:url(#s2-linear-gradient-2)"/>
                        <path d="M490.88,768a122,122,0,1,0-180.66,0Z" transform="translate(-265)" style="fill:url(#s2-linear-gradient-3)"/>
                    </svg>
                </div>

                <v-row justify="center" align="center" class="ma-0 pa-0 non-svg">
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="order-1 order-sm-1">
                        <v-lazy v-model="showSection2" transition="scroll-y" :options="{ threshold: 1.0 }">
                            <div>
                                <!-- <p class="pb-5 primary--text text-h3 font-weight-bold s2tb">Explore tawq.in</p> -->
                                <!-- <p class="pb-5 primary--text text-h3 font-weight-bold s2tb">Dive into a world of discovery on tawq.in.</p> -->
                                <p class="s2tb text-lg-h2 text-md-h3 text-h4 primary--text font-weight-black pb-lg-5">Dive into a World of Discovery.</p>
                                <!-- <p class="text-subtitle-1 s2tb">Dive into a world of discovery on tawq.in. Uncover posts that resonate with you, connect with friends and groups, and explore diverse topics through tags. Your journey of exploration begins here.</p> -->
                                <!-- <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Uncover posts that resonate with you,</p> -->
                                <p class="s2tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Uncover posts that resonate with you,</p>
                                <p class="s2tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">explore diverse topics through tags,</p>
                                <p class="s2tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">and connect with friends and groups.</p>
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Your journey of exploration begins here.</p> -->
                                <!-- <p class="text-subtitle-1 s2tb">Uncover posts that resonate with you, connect with friends and groups, and explore diverse topics through tags.</p> -->
                                <!-- <p class="text-subtitle-1 s2tb">Your journey of exploration begins here.</p> -->
                                <p class="text-subtitle-1 s2tb"></p>
                                <p class="text-subtitle-1 s2tb"></p>
                                <v-btn x-large class="mt-8 action-button-s2 hidden-sm-and-down" @click.stop="buttonClick('explore')">Explore tawq.in</v-btn>
                                <v-btn medium class="mt-5 action-button-s2 hidden-md-and-up" @click.stop="buttonClick('explore')">Explore tawq.in</v-btn>
                            </div>
                        </v-lazy>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2 order-sm-2">
                        <v-lazy v-model="showSection2" transition="scroll-y-reverse" :options="{ threshold: 1.0 }">
                                <img style="max-width: 80%;" src="../assets/land_page/b/s2_3x.png" srcset=" ../assets/land_page/b/s2_2x.png 1x, ../assets/land_page/b/s2_3x.png 2x">
                        </v-lazy>
                    </v-col>
                </v-row>
            </div>
            <!-- END SECTION 2 : STEP 2 - EXPLORE -->

            <!-- SECTION 3 : STEP 3 - CONTRIBUTE -->
            <div id="section-3" class="section3-container section">
                <div :class="isMobile ? 's3-svg-container-mobile' : 's3-svg-container'">
                    <svg id="s3-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 678.55 768" preserveAspectRatio="none">
                        <defs>
                            <linearGradient id="linear-gradient" x1="99.57" y1="525.91" x2="770.24" y2="309.93" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#86c232"/>
                                <stop offset="1" stop-color="#61892f"/>
                            </linearGradient>
                            <linearGradient id="linear-gradient-2" x1="204.78" y1="157.76" x2="375.68" y2="157.76" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#86c232"/>
                                <stop offset="0.39" stop-color="#b7c221" stop-opacity="0.8"/>
                                <stop offset="1" stop-color="#ffc107" stop-opacity="0.5"/>
                            </linearGradient>
                            <linearGradient id="linear-gradient-3" x1="139.39" y1="778.91" x2="69.54" y2="709.06" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#61892f" stop-opacity="0.5"/>
                                <stop offset="0.3" stop-color="#8e9924" stop-opacity="0.64"/>
                                <stop offset="1" stop-color="#ffc107"/>
                            </linearGradient>
                            <linearGradient id="linear-gradient-4" x1="496.93" y1="570.5" x2="676.04" y2="694" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#61892f" stop-opacity="0.5"/>
                                <stop offset="1" stop-color="#86c232" stop-opacity="0.3"/>
                            </linearGradient>
                        </defs>
                        <path id="curve" d="M96.43,768S47.52,484.42,206.51,356.42,356.73,0,356.73,0h367V768Z" transform="translate(-44.77)" style="fill:url(#linear-gradient)"/>
                        <ellipse id="circle" cx="287.03" cy="157.76" rx="100.97" ry="108.2" style="fill:url(#linear-gradient-2)"/>
                        <path id="half-circle" d="M165.6,768a77.05,77.05,0,0,0,1.17-13.44c0-37.88-27.31-68.59-61-68.59s-61,30.71-61,68.59A77,77,0,0,0,46,768Z" transform="translate(-44.77)" style="fill:url(#linear-gradient-3)"/>
                        <path id="square" d="M723.77,768V504.11a22.8,22.8,0,0,0-5.18-.6h-234c-14.83,0-26.86,14.13-26.86,31.56V754.83A36.12,36.12,0,0,0,460.23,768Z" transform="translate(-44.77)" style="fill:url(#linear-gradient-4)"/>
                    </svg>
                </div>

                <v-row justify="center" align="center" class="ma-0 pa-0 non-slanted-box">
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="order-1 order-sm-2">
                        <v-lazy v-model="showSection3" transition="scroll-y-reverse" :options="{ threshold: 1.0 }">
                            <div>
                                <!-- <p class="pb-5 accent--text text-h3 font-weight-bold s3tb">Contribute to the Community</p> -->
                                <!-- <p class="pb-5 accent--text text-h3 font-weight-bold s3tb">Contribute</p> -->
                                <!-- <p class="s3tb text-lg-h2 text-md-h3 text-h4 accent--text font-weight-black pb-lg-5">With tawq.in, Your Voice Matters.</p> -->
                                <p class="s3tb text-lg-h2 text-md-h3 text-h4 accent--text font-weight-black pb-lg-5">Be Part of Something Bigger.</p>
                                <!-- <p class="text-subtitle-1 s3tb">Be part of something bigger. With tawq.in, your contributions matter. Create posts for others to voice over, fostering a community-driven experience. Your voice can bridge languages, provide insights, and connect experiences. Join hands with fellow users in an engaging exchange of voices and stories.</p> -->
                                <p class="s3tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Join hands with fellow users in an engaging exchange of voices and stories.</p>
                                <p class="s3tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Create posts for others to voice over,</p>
                                <p class="s3tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">or add your voice to others posts.</p>
                                <p class="s3tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Fostering a community-driven experience.</p>
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Your voice can bridge languages, provide insights, and connect experiences.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">Be part of something bigger.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">With tawq.in, your voice matters.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">Be part of something bigger. With tawq.in, your contributions matter.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">Create posts for others to voice over, fostering a community-driven experience.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">Your voice can bridge languages, provide insights, and connect experiences.</p> -->
                                <!-- <p class="text-subtitle-1 s3tb">Join hands with fellow users in an engaging exchange of voices and stories.</p> -->
                                <p class="text-subtitle-1 s3tb"></p>
                                <p class="text-subtitle-1 s3tb"></p>
                                <v-btn x-large class="mt-8 action-button-s3 hidden-sm-and-down" @click.stop="buttonClick('contribute')">Join the Community</v-btn>
                                <v-btn medium class="mt-5 action-button-s3 hidden-md-and-up" @click.stop="buttonClick('contribute')">Join the Community</v-btn>
                            </div>
                        </v-lazy>
                    </v-col>
                    
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2 order-sm-1">
                        <v-lazy v-model="showSection3" transition="rslide-fade" :options="{ threshold: 1.0 }">
                            <img style="max-width: 60%; object-fit: contain; height: 100%;" src="../assets/land_page/b/s3_3x.png" srcset="../assets/land_page/b/s3_2x.png 1x, ../assets/land_page/b/s3_3x.png 2x" >
                        </v-lazy>
                    </v-col>
                </v-row>
            </div>
            <!-- END SECTION 3 : STEP 3 - CONTRIBUTE -->

            <!-- SECTION 4 : STEP 4 - SHARE -->
            <div id="section-4" class="section4-container section">
                <div id="s4-svg-container">
                    <svg :id="isMobile ? 's4-svg-mobile' : 's4-svg'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 802 212.69" preserveAspectRatio="none">
                        <defs>
                            <linearGradient id="s4-linear-gradient" gradientTransform="matrix(1, 0, 0, -1, -4.45, 768)" gradientUnits="userSpaceOnUse">
                                <stop offset="5%" stop-color="#ffc107"/>
                                <stop offset="95%" stop-color="#61892F"/>
                            </linearGradient>
                        </defs>
                        <!-- <path id="footer-svg" d="M 10,177.63
                            s 72.85 -34.71,10.5 -34.13
                            c 32.5.5,110.81,27.33,193.9,16.66
                            s 142.07 -47,191.1 -46.66
                            c 64.5.5,147 -21,179 -61
                            S 900.5,1.5,802,12
                            V 222
                            H 0Z" 
                            transform="translate(0 -9.31)" style="fill:url(#s4-linear-gradient)"/> -->
                        <path id="footer-svg" d="M0, 187.63s72.85-34.71, 110.5-34.13c32.5.5, 130.81,27.33,193.9, 16.66s142.07-47, 191.1-46.66c64.5.5, 147-21, 179-61S780.5,1.5,802, 12V222H0Z" transform="translate(0 -9.31)" style="fill:url(#s4-linear-gradient)"/>
                    </svg>
                </div>

                <v-row justify="center" align="center" class="ma-0 pa-0 non-s4-svg">
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="order-1 order-sm-1">
                        <v-lazy v-model="showSection4" transition="rslide-fade" :options="{ threshold: 1.0 }">
                            <div>
                                <!-- <p class="pb-5 primary--text text-h3 font-weight-bold s4tb">Share With Anyone</p> -->
                                <!-- <p class="s4tb text-lg-h2 text-md-h3 text-h4 accent--text font-weight-black pb-lg-5">Share With Anyone</p> -->
                                <!-- <p class="s4tb text-lg-h2 text-md-h3 text-h4 primary--text font-weight-black pb-lg-5">Spread Your Voice, Your Stories, Your Way.</p> -->
                                <p class="s4tb text-lg-h2 text-md-h3 text-h4 primary--text font-weight-black pb-lg-5">Share Your Voice, Your Stories, Your Way.</p>
                                <!-- <p class="text-subtitle-1 s4tb">Spread your voice, your stories, your way. Share your posts across social media platforms, from public posts for all to enjoy to private groups for those closest to you. Use our unique hashtags to connect your tawq.in journey with others, sparking conversations and creating a global network of shared experiences.</p> -->
                                <p class="s4tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">From sharing public posts that everyone can enjoy,</p>
                                <p class="s4tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">to creating private groups for those closest to you,</p>
                                <p class="s4tb text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">and even extending your reach to other social media platforms.</p>
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Share your posts across social media platforms, from public posts for all to enjoy to private groups for those closest to you.</p> -->
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Make your post public for all to enjoy or create private groups for those closest to you.</p> -->
                                <!-- <p style="text-shadow: 0.1px 0.1px 0.1px black;" class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">Use our unique hashtags to connect your tawq.in journey with others, sparking conversations and creating a global network of shared experiences.</p> -->
                                <!-- <p class="text-subtitle-1 s4tb">Spread your voice, your stories, your way.</p> -->
                                <!-- <p class="text-subtitle-1 s4tb">Share your posts across social media platforms, from public posts for all to enjoy to private groups for those closest to you.</p> -->
                                <!-- <p class="text-subtitle-1 s4tb">Use our unique hashtags to connect your tawq.in journey with others, sparking conversations and creating a global network of shared experiences.</p> -->
                                <!-- <p class="text-subtitle-1 s4tb">#tawqinmyway</p> -->
                                <p class="text-subtitle-1 s4tb"></p>
                                <p class="text-subtitle-1 s4tb"></p>
                                <v-btn x-large class="mt-8 action-button-s4 hidden-sm-and-down" @click.stop="buttonClick('share')">Share Today</v-btn>
                                <v-btn medium class="mt-5 action-button-s4 hidden-md-and-up" @click.stop="buttonClick('share')">Share Today</v-btn>
                            </div>
                        </v-lazy>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2 order-sm-2">
                        <v-lazy v-model="showSection4" transition="rotate-in" :options="{ threshold: 1.0 }">
                            <img style="max-width: 80%;" src="../assets/land_page/b/s4_3x.png" srcset=" ../assets/land_page/b/s4_2x.png 1x, ../assets/land_page/b/s4_3x.png 2x">
                        </v-lazy>
                    </v-col>
                </v-row>
            </div>
            <!-- END SECTION 4 : STEP 4 - SHARE -->
        </div>

        <v-footer class="primary" absolute>
            <v-row class="d-flex ma-0 pa-0 white--text" justify="center">
                <v-btn style="cursor: default;" :ripple="false" text x-small color="white">tawq.in&nbsp;&copy; {{ new Date().getFullYear() }}&nbsp;<br></v-btn>
                <v-divider vertical color="white"></v-divider>
                <v-btn text x-small color="white" :to="{path: '/privacy'}">Privacy Policy</v-btn>
                <v-divider vertical color="white"></v-divider>
                <v-btn text x-small color="white" :to="{path: '/terms'}">Terms and Conditions</v-btn>
            </v-row>
        </v-footer>
    </div>
</template>



<script>
// import Privacy from "../components/Privacy.vue";
// import About from "../components/About.vue";
// import TC from "../components/TC.vue";
import { UAParser } from "ua-parser-js";
// node-ipinfo api token 44c69e4f1c6691;
// import firebase from "../../firebaseConfig.js";

export default {
    name: "LandPage",
    metaInfo: {
        title: "Intro - tawq.in"
    },
    components: {
        //   About,
        //   Privacy,
        //   TC
    },
    data () { 
        return {
            showSection1: false,
            showSection2: false,
            showSection3: false,
            showSection4: false,
            showPrivacyPolicy: false,
            showAbout: false,
            showTermsConditions: false,
            scrollPosition: 0,
            headerHeight: null,
            pageTimer: null,
            startTime: null,
            endTime: null,
            timeSpentOnPage: 0,
            parser: new UAParser(),
            apiKey: "44c69e4f1c6691",
            deviceInfo: {
                browser: {}, // browser in use
                device: {
                    model: "",
                    vendor: "",
                }, // device, we only want model and vendor.
                engine: {
                    name: "",
                },
                os: {}, // operating system
                ua: "", // user-agent
            },
        };
    },
    methods: {
        scrollToContent: function (id) {
            const contentElement = document.getElementById(id);
            // if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "center" });
            if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        },
        updateScroll: function(){
            this.scrollPosition = window.scrollY;
        },
        setHeaderHeight() {
            this.headerHeight = this.$refs.header.clientHeight - this.$refs.waves.clientHeight;
        },
        buttonClick: function(section) {
            if (section === "login") this.$router.push({ name: "login" });
            else this.$router.push({ name: "sign-up" });
            // console.log("buttonClick: ", section);
            // if (process.env.NODE_ENV !== 'production') {
            //     // console.log("not production, skipping writing to database.");
            //     if (section === "login") this.$router.push({ name: "login" });
            //     else this.$router.push({ name: "sign-up" });
            //     return;
            // }
            // let parserResults = this.parser.getResult();
            // // console.log("parserResults: ", parserResults);
            // this.deviceInfo.browser = parserResults.browser;
            // this.deviceInfo.device.model = (typeof parserResults.device.model != 'undefined') ? parserResults.device.model : '';
            // this.deviceInfo.device.vendor = (typeof parserResults.device.vendor != 'undefined') ? parserResults.device.vendor : '';
            // this.deviceInfo.engine.name = parserResults.engine.name;
            // this.deviceInfo.os = parserResults.os;
            // this.deviceInfo.ua = parserResults.ua;
            // // console.log("deviceInfo: ", this.deviceInfo);
            // const userLang = navigator.userLanguage || navigator.language;
            // const route = this.$route.path;

            // this.$store.dispatch('landPageButtonClicked', {
            //     section: section,
            //     route: route,
            //     userLang: userLang,
            //     timeSpentOnPage: this.timeSpentOnPage,
            //     deviceInfo: this.deviceInfo,
            //     apiKey: this.apiKey,
            // });
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    updated(){
        if (!this.showAbout && !this.showTermsConditions && !this.showPrivacyPolicy) {
            this.setHeaderHeight();
        }
    },
    mounted: function() {
        this.setHeaderHeight();
        window.addEventListener('scroll', this.updateScroll);
        this.startTime = new Date();
        if (this.pageTimer == null) {
            this.pageTimer = setInterval(() => {
                this.endTime = new Date();
                const timeDifference = (this.endTime - this.startTime) / 1000;
                this.timeSpentOnPage = Math.floor(timeDifference);
            }, 1000);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.updateScroll);
        clearInterval(this.pageTimer);
    },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600;700;800&display=swap'); */

.s1tb {
    text-shadow: 0 0 0.5em var(--v-primary-base), 0 0 1em var(--v-primary-base), 0 0 0.5em var(--v-primary-base);
}
.s2tb {
    /* two shades darker than accent */
    text-shadow: 0 0 0.5em #d39e00, 0 0 1em #d39e00, 0 0 0.5em #d39e00;

    /* one shade darker than accent */
    /* text-shadow: 0 0 0.5em #edb100, 0 0 1em #edb100, 0 0 0.5em #edb100; */

    /* text-shadow: 0 0 0.5em var(--v-accent-base), 0 0 1em var(--v-accent-base), 0 0 0.5em var(--v-accent-base); */
}
.s3tb {
    text-shadow: 0 0 0.5em var(--v-primary-base), 0 0 1em var(--v-primary-base), 0 0 0.5em var(--v-primary-base);
}
.s4tb {
    /* text-shadow: 0 0 0.1em var(--v-primary-base), 0 0 1em var(--v-primary-base), 0 0 0.5em var(--v-primary-base); */
    /* text-shadow: 0 0 0.5em black, 0 0 1em black, 0 0 0.5em black; */
    /* text-shadow: 0 0 0.5em #1a1a1a, 0 0 1em #1a1a1a, 0 0 0.5em #1a1a1a; */
    text-shadow: 0 0 0.5em #262626, 0 0 1em #262626, 0 0 0.5em #262626;
}
.hib {
    text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-accent-base);
    /* text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base); */
    /* text-shadow: 0 0 .1em var(--v-accent-base), 0 0 .1em var(--v-accent-base), 0 0 .1em var(--v-accent-base); */
}
.hib-btn {
    width: 100px;
    height: 100px;
}
.htb {
    /* text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base); */
    text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-accent-base), 0 0 .1em var(--v-primary-base);
}


.action-button:hover{
    background-color: var(--v-accent-base) !important;
    transition: background-color .4s ease;
    color: white !important;
}


.action-button-s1{
    background-color: var(--v-accent-base) !important;
    color: white !important;
}
.action-button-s1:hover{
    background-color: white !important;
    transition: background-color .4s ease;
    color: var(--v-primary-base) !important;
}


.action-button-s2{
    background-color: var(--v-primary-base) !important;
    /* transition: background-color .4s ease; */
    color: white !important;
}
.action-button-s2:hover{
    background-color: white !important;
    transition: background-color .4s ease;
    color: var(--v-primary-base) !important;
}


.action-button-s3{
    background-color: var(--v-accent-base) !important;
    color: white !important;
}
.action-button-s3:hover{
    background-color: white !important;
    transition: background-color .4s ease;
    color: var(--v-primary-base)  !important;
}


.action-button-s4{
    background-color: var(--v-primary-base) !important;
    transition: background-color .4s ease;
    color: white !important;
}
.action-button-s4:hover{
    background-color: white !important;
    transition: background-color .4s ease;
    color: var(--v-primary-base) !important;
}


.lslide-fade-enter-active {
    transition: all 1s ease;
}

.lslide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.lslide-fade-enter, .lslide-fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
    transition-delay: .2s;
}

.rslide-fade-enter-active {
    transition: all 1s ease;
}

.rslide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.rslide-fade-enter, .rslide-fade-leave-to {
    transform: translateX(-30px);
    opacity: 0;
    transition-delay: .2s;
}

.scroll-y-reverse-enter-active {
    transition: all 1s;
}

.scroll-y-reverse-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.scroll-y-reverse-enter, .scroll-y-reverse-leave-to {
    transform: translateY(40px);
    opacity: 0;
    transition-delay: .2s;
} 

.scroll-y-enter-active {
    transition: all 1s;
}

.scroll-y-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.scroll-y-enter, .scroll-y-leave-to {
    transform: translateY(-40px);
    opacity: 0;
    transition-delay: .2s;
} 


.rotate-in-enter-active {
     transition: all 1s;
}

.rotate-in-leave-active {
    transform: rotate3d(-2,1,1,0.2turn) scaleY(0);
    transition: all 1s;
}

.rotate-in-enter, .rotate-leave-to {
    transform: rotate3d(2,-1,-1, -0.2turn) scaleY(1);
    opacity: 0;
    transition-delay: .2s;
}

.section {
    /* height: 100%; */
    min-height: 75vh;
    width: 100%;
    z-index: 1;
}

/* SECTION 1 */
.section1-container {
    position: relative;
    overflow: hidden;
}

.skewed-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: top;
}

.skew-positive {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skewY(3deg);
    z-index: 1;
}

.skew-negative {
    transform: skewY(-3deg);
    z-index: 2;
}

.s1-shape {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    height: 65%;
    background: linear-gradient(135deg, rgba(134, 194, 50, 1) 35%, rgba(97, 137, 47, 1) );
    z-index: 2;
}

.s1-skew-shape {
    position: absolute;
    background: linear-gradient(240deg, rgba(134, 194, 50, 1) 35%, rgba(97, 137, 47, 1) );
    height: 35%;
    width: 50%;
    top: 0;
    left: 0;
    z-index: 2;
}

.non-skew {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-origin: bottom;
    z-index: 3;
}
/* END SECTION 1 */

/* SECTION 2 */
.section2-container {
    position: relative;
    overflow: hidden;
}

.s2-svg-container {
    position: absolute;
    width: 50%;
    height: 0%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: top;
    transform: scaleX(-1);
    z-index: 1;
}
.s2-svg-container-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    transform-origin: top;
    /* transform: scaleX(-1); */
    z-index: 1;
}

#s2-svg{
    position: absolute;
    width: 100%;
    /* height: 100%; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: top;
    /* overflow: hidden; */
    /* z-index: 1; */
}
#s2-svg-mobile{
    position: absolute;
    /* width: 100%; */
    height: 100%;
    top: 0;
    /* left: 0; */
    bottom: 0;
    right: 0;
    transform-origin: top;
    /* overflow: hidden; */
    /* z-index: 1; */
}

.non-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: bottom;
    z-index: 3;
}
/* END SECTION 2 */

/* SECTION 3 */
.section3-container {
    position: relative;
    overflow: hidden;
}

.s3-svg-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    /* transform: scaleX(-1); */
    /* overflow: hidden; */
}
.s3-svg-container-mobile {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transform: scaleX(-1) scaleY(-1);
    /* overflow: hidden; */
}


#s3-svg{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    /* overflow: hidden; */
}

.non-slanted-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: bottom;
    z-index: 2;
}
/* END SECTION 3 */

/* SECTION 4 */
.section4-container {
    position: relative;
    overflow: hidden;
}

#s4-svg-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    /* right: 0;
    left: 0; */
    z-index: -1;
}

#s4-svg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: bottom;
    /* transform: scaleX(-1); */
}
#s4-svg-mobile{
    position: absolute;
    width: 100%;
    height: 50%;
    /* top: 0; */
    right: 0;
    bottom: 0;
    left: 0;
    /* transform-origin: top; */
    /* transform: scaleY(-1); */
}

.non-s4-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: bottom;
    z-index: 2;
}
/* END SECTION 4 */

.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    height: calc(100vh - 200px);
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%);
  background-size: 400% 400%;
  animation: gradient 25s ease infinite;
  height: calc(100vh - 200px);
  width: 100%;
}
.header-mobile {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%);
  background-size: 400% 400%;
  animation: gradient 25s ease infinite;
  height: calc(100vh - 150px);
  width: 100%;
}

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation */
.wave-parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}
.wave-parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.wave-parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.wave-parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.wave-parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}




/* XS */
@media (max-width: 600px){
    .parallax { 
        background-image: url("../assets/land_page/header-image1x.jpg"); 
        background-attachment: scroll;
    }
    .section { min-height: 100vh; }
    .waves {
        height: 40px;
        min-height: 40px;
    }
    .s1-shape { width: 65%;}
    .s1-skew-shape { width: 60%;}
    #s2-svg { top: 0;}
    #s3-svg-container { width: 100%; }
    #s3-svg { width: 120%; }
    #s4-svg { width: 120%;  height: 80%;}
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    .parallax { background-image: url("../assets/land_page/header-image2x.jpg"); }
    .s1-shape { width: 55%;}
    #s2-svg { top: 1.1%;}
    #s3-svg-container { width: 85%; }
    #s3-svg { width: 100%; }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .parallax { background-image: url("../assets/land_page/header-image2x.jpg"); }
    .s1-shape { width: 55%;}
    #s2-svg { top: 1.4%;}
    #s3-svg { width: 76%;}
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .parallax { background-image: url("../assets/land_page/header-image3x.jpg"); }
    #s2-svg { top: 2.3%;}
    #s3-svg { width: 60%;}
}

/* XL */
@media (min-width: 1905px){
    .parallax { background-image: url("../assets/land_page/header-image4x.jpg"); }
    #s2-svg { top: 2.9%;}
    #s3-svg { width: 50%;}
    #s4-svg { width: 110%; }
}

</style>