<template>
<!-- <v-hover v-slot="{hover}"> -->
<v-row justify="center" class="ml-0 mr-0" style="max-width: 100%;">
    <v-sheet :class="[!$vuetify.theme.dark && 'on-hover' ?  'hover' : $vuetify.theme.dark && 'on-hover' ? 'dark-elevation' : '']" :elevation="hover ? 10 : 0" outlined width="100%" :color="plan.color">
        <div class="py-2 white--text">
            <p class="text-center text-h6">{{plan.name}}</p>
            <!-- basic plan (only has one price element) -->
            <p v-if="plan.prices[0].recurring.interval === 'month' && annual != true || plan.name === 'Basic Plan'" class="text-center text-h2">${{ plan.prices[0].unit_amount != 0 ? plan.prices[0].unit_amount / 100 : plan.prices[0].unit_amount}}<span v-if="plan.name != 'Basic Plan'" class="font-weight-light text-body-2">/monthly</span></p>
            <!-- other plans -->
            <p v-else-if="annual != true" class="text-center text-h2">${{plan.prices[1].unit_amount / 100}}<span class="font-weight-light text-body-2">/monthly</span></p>
            <p v-else class="text-center text-h2">${{plan.prices[0].unit_amount / 100}}<span class="font-weight-light text-body-2">/annually</span></p>
            <!-- <v-row justify="center" class="mb-4"><v-btn depressed class="text-center">Try It For Free</v-btn></v-row> -->
            <!-- <p class="text-center mb-0">{{plan.briefDescription}}</p> -->
        </div>

        <v-list>
            <!-- <v-subheader>Features</v-subheader> -->
            <v-list-item v-for="(feature, key) in plan.metadata" :key="key" class="d-flex align-center">
                <v-list-item-icon class="my-0 py-0 align-self-center mr-md-2">
                    <v-icon v-if="feature === 'True' " :color="plan.color">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="my-0 py-0">
                    <v-list-item-title :class="[feature === 'True' ? '' : $vuetify.theme.dark ? 'grey--text text--darken-2': 'invert--text text--lighten-4']" v-text="key"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-sheet> 

    <!-- <router-link :to="{name: 'manageSubscription', params: { 'plan': plan } }" class="text-decoration-none"> -->
        <!-- <v-btn :to="{name: 'manageSubscription', query: { 'planUID': plan.uid } }" depressed block class="mt-4" :class="[!$vuetify.theme.dark && 'on-hover' ?  'hover' : $vuetify.theme.dark && 'on-hover' ? 'dark-elevation' : '']" :elevation="hover ? 10 : 0" :color="plan.color">{{plan.actionText}}</v-btn> -->
    <!-- </router-link> -->

</v-row>
<!-- </v-hover> -->

</template>

<script>
export default {
    name: "PriceSheet",
    props: ['plan', 'features', 'annual', 'hover'],
    data() {
        return {
        }
    },
    methods:{
        
    },
    created: function() {
        // console.log(this.plan)
    }
}
</script>

<style>
   

</style>


    