// Below is Google Speech to Text V2 Supported Languages, and includes the model and location for each language.
// Where possible, the location 'global' is used, and, the model 'long' is used.

// Model value priority is, in order, 'long', 'chirp', 'chirp_2', 'telephony'
// Model KEY: 0 = 'LONG', 1 = 'CHIRP', 2 = 'CHIRP_2', 3 = 'TELEPHONY'

// Location value priority is, in order, 'global', 'us-central1', all others (only one case English (Canada) - en-CA)
// Location KEY: 0 = 'GLOBAL', 1 = 'US_CENTRAL_1', 2 = 'NORTHAMERICANORTHEAST1'

// Any individual language that is commented out below is because the Language Code is not in V2 Supported list

const modelEnum = {
  LONG: 0,
  CHIRP: 1,
  CHIRP_2: 2,
  // TELEPHONY: 3,
};

const locationEnum = {
  GLOBAL: 0,
  US_CENTRAL_1: 1,
  // NORTHAMERICANORTHEAST1: 2,
};

const recognizerEnum = {
  GLOBAL_LONG_RECOGNIZER: 0,
  US_CENTRAL_1_CHIRP_RECOGNIZER: 1,
  US_CENTRAL_1_CHIRP_2_RECOGNIZER: 2,
  // GLOBAL_TELEPHONY_RECOGNIZER: 3,
};

const languageCodes = [
  {
    text: "English (United States)",
    value: "en-US",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-us-en-in-en-gb",
  },
  {
    text: "English (India)",
    value: "en-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-us-en-in-en-gb",
  },
  {
    text: "English (United Kingdom)",
    value: "en-GB",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-us-en-in-en-gb",
  },
  {
    text: "English (Australia)",
    value: "en-AU",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-au-es-us-es-es",
  },
  // {
  //   text: "English (Canada)",
  //   value: "en-CA",
  //   model: modelEnum.TELEPHONY,
  //   location: locationEnum.NORTHAMERICANORTHEAST1,
  //   recognizerEnum: recognizerEnum.GLOBAL_TELEPHONY_RECOGNIZER,
  // },
  // {
  //   text: "English (Ghana)",
  //   value: "en-GH",
  // },
  // {
  //   text: "English (Ireland)",
  //   value: "en-IE",
  // },
  // {
  //   text: "English (Kenya)",
  //   value: "en-KE",
  // },
  // {
  //   text: "English (New Zealand)",
  //   value: "en-NZ",
  // },
  // {
  //   text: "English (Nigeria)",
  //   value: "en-NG",
  // },
  // {
  //   text: "English (Philippines)",
  //   value: "en-PH",
  // },
  // {
  //   text: "English (South Africa)",
  //   value: "en-ZA",
  // },
  // {
  //   text: "English (Tanzania)",
  //   value: "en-TZ",
  // },
  // {
  //   text: "English (Hong Kong)",
  //   value: "en-HK",
  // },
  // {
  //   text: "English (Pakistan)",
  //   value: "en-PK",
  // },
  // {
  //   text: "English (Singapore)",
  //   value: "en-SG",
  // },
  {
    text: "Español (latinoamericano) | Spanish (Latin American)",
    value: "es-419",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-es-419",
  },
  {
    text: "Español (Estados Unidos) | Spanish (United States)",
    value: "es-US",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-au-es-us-es-es",
  },
  // Removed 'es-MX' and 'es-CO' - on July 18, 2024 by Scott Brady,
  // because model: 'telephony' does not work for our purposes,
  // instead we will use Spanish (Latin American)
  // {
  //   text: "Español (México) | Spanish (Mexico)",
  //   value: "es-MX",
  //   model: modelEnum.TELEPHONY,
  //   location: locationEnum.GLOBAL,
  //   recognizerEnum: recognizerEnum.GLOBAL_TELEPHONY_RECOGNIZER,
  // },
  // {
  //   text: "Español (Colombia) | Spanish (Colombia)",
  //   value: "es-CO",
  //   model: modelEnum.TELEPHONY,
  //   location: locationEnum.GLOBAL,
  //   recognizerEnum: recognizerEnum.GLOBAL_TELEPHONY_RECOGNIZER,
  // },

  // {
  //   text: "Español (Perú) | Spanish (Peru)",
  //   value: "es-PE",
  // },
  // {
  //   text: "Español (Argentina) | Spanish (Argentina)",
  //   value: "es-AR",
  // },
  // {
  //   text: "Español (Bolivia) | Spanish (Bolivia)",
  //   value: "es-BO",
  // },
  // {
  //   text: "Español (Chile) | Spanish (Chile)",
  //   value: "es-CL",
  // },
  // {
  //   text: "Español (Costa Rica) | Spanish (Costa Rica)",
  //   value: "es-CR",
  // },
  // {
  //   text: "Español (República Dominicana) | Spanish (Dominican Republic)",
  //   value: "es-DO",
  // },
  // {
  //   text: "Español (Ecuador) | Spanish (Ecuador)",
  //   value: "es-EC",
  // },
  // {
  //   text: "Español (El Salvador) | Spanish (El Salvador)",
  //   value: "es-SV",
  // },
  // {
  //   text: "Español (Guatemala) | Spanish (Guatemala)",
  //   value: "es-GT",
  // },
  // {
  //   text: "Español (Honduras) | Spanish (Honduras)",
  //   value: "es-HN",
  // },
  // {
  //   text: "Español (Nicaragua) | Spanish (Nicaragua)",
  //   value: "es-NI",
  // },
  // {
  //   text: "Español (Panamá) | Spanish (Panama)",
  //   value: "es-PA",
  // },
  // {
  //   text: "Español (Paraguay) | Spanish (Paraguay)",
  //   value: "es-PY",
  // },
  // {
  //   text: "Español (Puerto Rico) | Spanish (Puerto Rico)",
  //   value: "es-PR",
  // },
  {
    text: "Español (España) | Spanish (Spain)",
    value: "es-ES",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-en-au-es-us-es-es",
  },
  // {
  //   text: "Español (Uruguay) | Spanish (Uruguay)",
  //   value: "es-UY",
  // },
  // {
  //   text: "Español (Venezuela) | Spanish (Venezuela)",
  //   value: "es-VE",
  // },
  {
    text: "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)",
    value: "af-ZA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-af-za",
    recognizer: "ucc2-af-za",
  },
  {
    text: "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)",
    value: "am-ET",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-am-et",
    recognizer: "ucc2-am-et",
  },
  {
    text: "العربية (الجزائر)‏ | Arabic (Algeria)",
    value: "ar-DZ",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-dz-ar-bh-ar-eg",
  },
  {
    text: "العربية (البحرين)‏ | Arabic (Bahrain)",
    value: "ar-BH",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-dz-ar-bh-ar-eg",
  },
  {
    text: "العربية (مصر)‏ | Arabic (Egypt)",
    value: "ar-EG",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-dz-ar-bh-ar-eg",
  },
  {
    text: "العربية (العراق) | Arabic (Iraq)",
    value: "ar-IQ",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-iq-ar-il-ar-jo",
  },
  {
    text: "العربية (إسرائيل)‏ | Arabic (Israel)",
    value: "ar-IL",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-iq-ar-il-ar-jo",
  },
  {
    text: "العربية (الأردن)‏ | Arabic (Jordan)",
    value: "ar-JO",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-iq-ar-il-ar-jo",
  },
  {
    text: "العربية (الكويت) | Arabic (Kuwait)",
    value: "ar-KW",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-kw-ar-om-ar-lb",
  },
  {
    text: "العربية (عُمان) | Arabic (Oman)",
    value: "ar-OM",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-kw-ar-om-ar-lb",
  },
  {
    text: "العربية (لبنان) | Arabic (Lebanon)",
    value: "ar-LB",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-kw-ar-om-ar-lb",
  },
  {
    text: "العربية (موريتانيا)| Arabic (Mauritania)",
    value: "ar-MR",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-mr-ar-ma-ar-qa",
  },
  {
    text: "العربية (المغرب) | Arabic (Morocco)",
    value: "ar-MA",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-mr-ar-ma-ar-qa",
  },
  {
    text: "العربية (قطر)‏ | Arabic (Qatar)",
    value: "ar-QA",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-mr-ar-ma-ar-qa",
  },
  {
    text: "العربية (السعودية)‏ | Arabic (Saudi Arabia)",
    value: "ar-SA",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-sa-ar-ps-ar-tn",
  },
  {
    text: "العربية (فلسطين) | Arabic (State of Palestine)",
    value: "ar-PS",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-sa-ar-ps-ar-tn",
  },
  {
    text: "العربية (تونس)‏ | Arabic (Tunisia)",
    value: "ar-TN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-sa-ar-ps-ar-tn",
  },
  {
    text: "العربية (الإمارات)‏ | Arabic (United Arab Emirates)",
    value: "ar-AE",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-ae-ar-ye-bg-bg",
  },
  {
    text: "العربية (اليمن) | Arabic (Yemen)",
    value: "ar-YE",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-ae-ar-ye-bg-bg",
  },
  {
    text: "Հայ (Հայաստան) | Armenian (Armenia)",
    value: "hy-AM",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-hy-am",
    recognizer: "ucc2-hy-am",
  },
  {
    text: "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)",
    value: "az-AZ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-az-az",
    recognizer: "ucc2-az-az",
  },
  {
    text: "Euskara (Espainia) | Basque (Spain)",
    value: "eu-ES",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-eu-es",
    recognizer: "ucc2-eu-es",
  },
  {
    text: "Български (България) | Bulgarian (Bulgaria)",
    value: "bg-BG",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ar-ae-ar-ye-bg-bg",
  },
  {
    text: "Català (Espanya) | Catalan (Spain)",
    value: "ca-ES",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ca-es",
    recognizer: "ucc2-ca-es",
  },
  {
    text: "Hrvatski (Hrvatska) | Croatian (Croatia)",
    value: "hr-HR",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-hr-hr",
    recognizer: "ucc2-hr-hr",
  },
  {
    text: "Čeština (Česká republika) | Czech (Czech Republic)",
    value: "cs-CZ",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-cs-cz-da-dk-nl-nl",
  },
  {
    text: "Dansk (Danmark) | Danish (Denmark)",
    value: "da-DK",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-cs-cz-da-dk-nl-nl",
  },
  {
    text: "Nederlands (Nederland) | Dutch (Netherlands)",
    value: "nl-NL",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-cs-cz-da-dk-nl-nl",
  },
  // {
  //   text: "Nederlands (België) | Dutch (Belgium)",
  //   value: "nl-BE",
  // },
  {
    text: "Filipino (Pilipinas) | Filipino (Philippines)",
    value: "fil-PH",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-fil-ph",
    recognizer: "ucc2-fil-ph",
  },
  {
    text: "Français (France) | French (France)",
    value: "fr-FR",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fr-fr-fr-ca-de-de",
  },
  {
    text: "Français (Canada) | French (Canada)",
    value: "fr-CA",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fr-fr-fr-ca-de-de",
  },
  // {
  //   text: "Français (Belgique) | French (Belgium)",
  //   value: "fr-BE",
  // },
  // {
  //   text: "Français (Suisse) | French (Switzerland)",
  //   value: "fr-CH",
  // },
  {
    text: "Galego (España) | Galician (Spain)",
    value: "gl-ES",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-gl-es",
    recognizer: "ucc2-gl-es",
  },
  {
    text: "ქართული (საქართველო) | Georgian (Georgia)",
    value: "ka-GE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ka-ge",
    recognizer: "ucc2-ka-ge",
  },
  {
    text: "Deutsch (Deutschland) | German (Germany)",
    value: "de-DE",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fr-fr-fr-ca-de-de",
  },
  // {
  //   text: "Deutsch (Österreich) | German (Austria)",
  //   value: "de-AT",
  // },
  // {
  //   text: "Deutsch (Schweiz) | German (Switzerland)",
  //   value: "de-CH",
  // },
  {
    text: "Ελληνικά (Ελλάδα) | Greek (Greece)",
    value: "el-GR",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-el-gr",
    recognizer: "ucc2-el-gr",
  },
  {
    text: "ગુજરાતી (ભારત) | Gujarati (India)",
    value: "gu-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-gu-in",
    recognizer: "ucc2-gu-in",
  },
  {
    text: "Íslenska (Ísland) | Icelandic (Iceland)",
    value: "is-IS",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-is-is",
    recognizer: "ucc2-is-is",
  },
  {
    text: "Italiano (Italia) | Italian (Italy)",
    value: "it-IT",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-it-it-ja-jp-kn-in",
  },
  // {
  //   text: "Italiano (Svizzera) | Italian (Switzerland)",
  //   value: "it-CH",
  // },
  {
    text: "日本語（日本) | Japanese (Japan)",
    value: "ja-JP",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-it-it-ja-jp-kn-in",
  },
  {
    text: "Jawa (Indonesia) | Javanese (Indonesia)",
    value: "jv-ID",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-jv-id",
    recognizer: "ucc2-jv-id",
  },
  {
    text: "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)",
    value: "kn-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-it-it-ja-jp-kn-in",
  },
  {
    text: "한국어 (대한민국) | Korean (South Korea)",
    value: "ko-KR",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ko-kr-ml-in-mr-in",
  },
  {
    text: "ລາວ (ລາວ) | Lao (Laos)",
    value: "lo-LA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-lo-la",
    recognizer: "ucc2-lo-la",
  },
  {
    text: "Latviešu (latviešu) | Latvian (Latvia)",
    value: "lv-LV",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-lv-lv",
    recognizer: "ucc2-lv-lv",
  },
  {
    text: "Lietuvių (Lietuva) | Lithuanian (Lithuania)",
    value: "lt-LT",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-lt-lt",
    recognizer: "ucc2-lt-lt",
  },
  {
    text: "Bahasa Melayu (Malaysia) | Malay (Malaysia)",
    value: "ms-MY",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ms-my",
    recognizer: "ucc2-ms-my",
  },
  {
    text: "മലയാളം (ഇന്ത്യ) | Malayalam (India)",
    value: "ml-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ko-kr-ml-in-mr-in",
  },
  {
    text: "मराठी (भारत) | Marathi (India)",
    value: "mr-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ko-kr-ml-in-mr-in",
  },
  {
    text: "Norsk bokmål (Norge) | Norwegian Bokmål (Norway)",
    value: "no-NO",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-no-no-pt-br-pt-pt",
  },
  {
    text: "Português (Brasil) | Portuguese (Brazil)",
    value: "pt-BR",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-no-no-pt-br-pt-pt",
  },
  {
    text: "Português (Portugal) | Portuguese (Portugal)",
    value: "pt-PT",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-no-no-pt-br-pt-pt",
  },
  {
    text: "Română (România) | Romanian (Romania)",
    value: "ro-RO",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ro-ro-ta-in-te-in",
  },
  {
    text: "Српски (Србија) | Serbian (Serbia)",
    value: "sr-RS",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sr-rs",
    recognizer: "ucc2-sr-rs",
  },
  {
    text: "සිංහල (ශ්රී ලංකාව) | Sinhala (Sri Lanka)",
    value: "si-LK",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-si-lk",
    recognizer: "ucc2-si-lk",
  },
  {
    text: "Slovenčina (Slovensko) | Slovak (Slovakia)",
    value: "sk-SK",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sk-sk",
    recognizer: "ucc2-sk-sk",
  },
  {
    text: "Slovenščina (Slovenija) | Slovenian (Slovenia)",
    value: "sl-SI",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sl-si",
    recognizer: "ucc2-sl-si",
  },
  {
    text: "Kiswahili (Kenya) | Swahili (Kenya)",
    value: "sw-KE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-sw-ke",
  },
  // {
  //   text: "Kiswahili (Tanzania) | Swahili (Tanzania)",
  //   value: "sw-TZ",
  // },
  {
    text: "தமிழ் (இந்தியா) | Tamil (India)",
    value: "ta-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ro-ro-ta-in-te-in",
  },
  // {
  //   text: "தமிழ் (மலேசியா) | Tamil (Malaysia)",
  //   value: "ta-MY",
  // },
  // {
  //   text: "தமிழ் (சிங்கப்பூர்) | Tamil (Singapore)",
  //   value: "ta-SG",
  // },
  // {
  //   text: "தமிழ் (இலங்கை) | Tamil (Sri Lanka)",
  //   value: "ta-LK",
  // },
  {
    text: "తెలుగు (భారతదేశం) | Telugu (India)",
    value: "te-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-ro-ro-ta-in-te-in",
  },
  {
    text: "ไทย (ประเทศไทย) | Thai (Thailand)",
    value: "th-TH",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-th-th-tr-tr-vi-vn",
  },
  {
    text: "Türkçe (Türkiye) | Turkish (Turkey)",
    value: "tr-TR",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-th-th-tr-tr-vi-vn",
  },
  {
    text: "IsiZulu (Ningizimu Afrika) | Zulu (South Africa)",
    value: "zu-ZA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-zu-za",
    recognizer: "ucc2-zu-za",
  },
  {
    text: "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)",
    value: "vi-VN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-th-th-tr-tr-vi-vn",
  },
  {
    text: "Suomi (Suomi) | Finnish (Finland)",
    value: "fi-FI",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fi-fi-ru-ru-pl-pl",
  },
  {
    text: "Русский (Россия) | Russian (Russia)",
    value: "ru-RU",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fi-fi-ru-ru-pl-pl",
  },
  {
    text: /*"中文、*/"普通话（简体中文）| Chinese, Mandarin (Simplified China)",
    value: "cmn-Hans-CN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-cmn-hans-cn",
    recognizer: "ucc2-cmn-hans-cn",
  },
  {
    text: /*"中文、*/"粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)",
    value: "yue-Hant-HK",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-yue-hant-hk",
    recognizer: "ucc2-yue-hant-hk",
  },
  {
    text: "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)",
    value: "cmn-Hant-TW",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-cmn-hant-tw",
    recognizer: "ucc2-cmn-hant-tw",
  },
  {
    text: "Polski (Polska) | Polish (Poland)",
    value: "pl-PL",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-fi-fi-ru-ru-pl-pl",
  },
  {
    text: "Українська (Україна) | Ukrainian (Ukraine)",
    value: "uk-UA",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-uk-ua-sv-se-hi-in",
  },
  {
    text: "Svenska (Sverige) | Swedish (Sweden)",
    value: "sv-SE",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-uk-ua-sv-se-hi-in",
  },
  {
    text: "हिन्दी (भारत) | Hindi (India)",
    value: "hi-IN",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-uk-ua-sv-se-hi-in",
  },
  {
    text: "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)",
    value: "bn-BD",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-bn-bd-hu-hu-km-kh",
  },
  {
    text: "বাংলা (ভারত) | Bengali (India)",
    value: "bn-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-bn-in",
  },
  {
    text: "עברית (ישראל) | Hebrew (Israel)",
    value: "iw-IL",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-iw-il",
    recognizer: "ucc2-iw-il",
  },
  {
    text: "Magyar (Magyarország) | Hungarian (Hungary)",
    value: "hu-HU",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-bn-bd-hu-hu-km-kh",
  },
  {
    text: "नेपाली (नेपाल) | Nepali (Nepal)",
    value: "ne-NP",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ne-np",
    recognizer: "ucc2-ne-np",
  },
  {
    text: "فارسی (ایران) | Persian (Iran)",
    value: "fa-IR",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-fa-ir",
    recognizer: "ucc2-fa-ir",
  },
  // {
  //   text: "اردو (بھارت)‏ | Urdu (India)",
  //   value: "ur-IN",
  // },
  {
    text: "اردو (پاکستان) | Urdu (Pakistan)",
    value: "ur-PK",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ur-pk",
    recognizer: "ucc2-ur-pk",
  },
  {
    text: "ဗမာ (မြန်မာ) | Burmese (Myanmar)",
    value: "my-MM",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-my-mm",
    recognizer: "ucc2-my-mm",
  },
  {
    text: "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)",
    value: "km-KH",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-bn-bd-hu-hu-km-kh",
  },
  {
    text: "Shqip (shqiperi) | Albanian (Albania)",
    value: "sq-AL",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sq-al",
    recognizer: "ucc2-sq-al",
  },
  {
    text: "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)",
    value: "bs-BA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-bs-ba",
    recognizer: "ucc2-bs-ba",
  },
  {
    text: "Eesti (eesti) | Estonian (Estonia)",
    value: "et-EE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-et-ee",
    recognizer: "ucc2-et-ee",
  },
  {
    text: "Indonesia (Indonesia) | Indonesian (Indonesia)",
    value: "id-ID",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-id-id-mk-mk",
  },
  {
    text: "Қазақ (Қазақстан) | Kazakh (Kazakhstan)",
    value: "kk-KZ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-kk-kz",
    recognizer: "ucc2-kk-kz",
  },
  {
    text: "Македонски (северна Македонија) | Macedonian (North Macedonia)",
    value: "mk-MK",
    model: modelEnum.LONG,
    location: locationEnum.GLOBAL,
    recognizerEnum: recognizerEnum.GLOBAL_LONG_RECOGNIZER,
    recognizer: "gl-id-id-mk-mk",
  },
  {
    text: "Монгол (Монгол) | Mongolian (Mongolia)",
    value: "mn-MN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-mn-mn",
    recognizer: "ucc2-mn-mn",
  },
  {
    text: "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ ਇੰਡੀਆ) | Punjabi (Gurmukhi India)",
    value: "pa-Guru-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-pa-guru-in",
    recognizer: "ucc2-pa-guru-in",
  },
  {
    text: "Basa Sunda (Indonésia) | Sundanese (Indonesia)"/*Sundan (Indonesia)*/,
    value: "su-ID",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-su-id",
    recognizer: "ucc2-su-id",
  },
  {
    text: "O'zbek (O'zbekiston) | Uzbek (Uzbekistan)",
    value: "uz-UZ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-uz-uz",
    recognizer: "ucc2-uz-uz",
  },

  // Below was added at Marty request on July 1, 2024 by Scott Brady
  // These are from "Speech-to-Text V2 supported languages"
  // Commented out on July 3, 2024 until we can implement functionality for V2
  {
    text: "অসমীয়া (ভাৰত) | Assamese (India)",
    value: "as-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-as-in",
    recognizer: "ucc2-as-in",
  },
  {
    text: "Asturian (España) | Asturian (Spain)",
    value: "ast-ES",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ast-es",
    recognizer: "ucc2-ast-es",
  },
  {
    text: "Cebuano (Pilipinas) | Cebuano (Philippines)",
    value: "ceb-PH",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ceb-ph",
    recognizer: "ucc2-ceb-ph",
  },
  {
    text: "کوردی ناوەڕاست (عێراق) | Central Kurdish (Iraq)",
    value: "ckb-IQ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ckb-iq",
    recognizer: "ucc2-ckb-iq",
  },
  {
    text: "Hausa (Nigeria)",
    value: "ha-NG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ha-ng",
    recognizer: "ucc2-ha-ng",
  },
  {
    text: "Kabuverdianu (Cape Verde)",
    value: "kea-CV",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-kea-cv",
    recognizer: "ucc2-kea-cv",
  },
  {
    text: "Kamba (Kenya)",
    value: "kam-KE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-kam-ke",
    recognizer: "ucc2-kam-ke",
  },
  {
    text: "Кыргызча (кириллица) | Kyrgyz (Cyrillic)",
    value: "ky-KG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ky-kg",
    recognizer: "ucc2-ky-kg",
  },
  {
    text: "Lingala (Kongo-Kinshasa) | Lingala (Congo-Kinshasa)",
    value: "ln-CD",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ln-cd",
    recognizer: "ucc2-ln-cd",
  },
  {
    text: "Luo (Kenya)",
    value: "luo-KE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-luo-ke",
    recognizer: "ucc2-luo-ke",
  },
  {
    text: "Lëtzebuergesch (Lëtzebuerg) | Luxembourgish (Luxembourg)",
    value: "lb-LU",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-lb-lu",
    recognizer: "ucc2-lb-lu",
  },
  {
    text: "Malti (Malta) | Maltese (Malta)",
    value: "mt-MT",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-mt-mt",
    recognizer: "ucc2-mt-mt",
  },
  {
    text: "Maori (New Zealand)",
    value: "mi-NZ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-mi-nz",
    recognizer: "ucc2-mi-nz",
  },
  {
    text: "Nyanja (Malawi)",
    value: "ny-MW",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ny-mw",
    recognizer: "ucc2-ny-mw",
  },
  {
    text: "Occitan (França) | Occitan (France)",
    value: "oc-FR",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-oc-fr",
    recognizer: "ucc2-oc-fr",
  },
  {
    text: "پښتو | Pashto",
    value: "ps-AF",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-ps-af",
    recognizer: "ucc2-ps-af",
  },
  {
    text: "Sepedi (Afrika Borwa) | Sepedi (South Africa)",
    value: "nso-ZA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-nso-za",
    recognizer: "ucc2-nso-za",
  },
  {
    text: "Shona (Zimbabwe)",
    value: "sn-ZW",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sn-zw",
    recognizer: "ucc2-sn-zw",
  },
  {
    text: "سنڌي (ڀارت) | Sindhi (India)",
    value: "sd-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-sd-in",
    recognizer: "ucc2-sd-in",
  },
  {
    text: "Soomaali | Somali",
    value: "so-SO",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-so-so",
    recognizer: "ucc2-so-so",
  },
  {
    text: "тоҷикӣ (Тоҷикистон) | Tajik (Tajikistan)",
    value: "tg-TJ",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-tg-tj",
    recognizer: "ucc2-tg-tj",
  },
  {
    text: "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)",
    value: "cy-GB",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-cy-gb",
    recognizer: "ucc2-cy-gb",
  },
  {
    text: "Wolof (Senegaal) | Wolof (Senegal)",
    value: "wo-SN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    // recognizer: "ucc-wo-sn",
    recognizer: "ucc2-wo-sn",
  },
  {
    text: "Èdè Yorùbá (Nàìjíríà) | Yoruba (Nigeria)",
    value: "yo-NG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc-yo-ng", // this recognizer was deleted by Scott Brady on August 5, 2024 for testing. When tested it still ran without issue. I might need to delete all recognizers and remake them.
    // recognizer: "ucc2-yo-ng",
  },


  // Added by Scott Brady on August 5, 2024. These languages were not added when Marty wanted the new V2 languages added
  {
    text: "Aromanian (Bulgaria)",
    value: "rup-BG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-rup-bg",
  },
  {
    text: "Belarusian (Belarus)",
    value: "be-BY",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-be-by",
  },
  {
    text: "Fulah (Senegal)",
    value: "ff-SN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-ff-sn",
  },
  {
    text: "Ganda (Uganda)",
    value: "lg-UG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-lg-ug",
  },
  {
    text: "Igbo (Nigeria)",
    value: "ig-NG",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-ig-ng",
  },
  {
    text: "Irish (Ireland)",
    value: "ga-IE",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-ga-ie",
  },
  {
    text: "Oriya (India)",
    value: "or-IN",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-or-in",
  },
  {
    text: "Oromo (Ethiopia)",
    value: "om-ET",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-om-et",
  },
  {
    text: "Swahili",
    value: "sw",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-sw",
  },
  {
    text: "Umbundu (Angola)",
    value: "umb-AO",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-umb-ao",
  },
  {
    text: "Xhosa (South Africa)",
    value: "xh-ZA",
    model: modelEnum.CHIRP_2,
    location: locationEnum.US_CENTRAL_1,
    recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
    recognizer: "ucc2-xh-za",
  },
  // {
  //   text: "",
  //   value: "",
  //   model: modelEnum.CHIRP_2,
  //   location: locationEnum.US_CENTRAL_1,
  //   recognizerEnum: recognizerEnum.US_CENTRAL_1_CHIRP_2_RECOGNIZER,
  //   recognizer: "ucc2-",
  // },
];

  export default { languageCodes };



// Commented out on July 15, 2024 by Scott Brady
// These are Google Speech to Text V1 Supported Languages

// const languageCodes = [
//     // {
//     //   text: "",
//     //   value: "",
//     // },
//     // {
//     //   text: "None (Disable Auto Captions)",
//     //   value: "null",
//     // },
//     {
//       text: "English (United States)",
//       value: "en-US",
//     },
//     {
//       text: "English (India)",
//       value: "en-IN",
//     },
//     {
//       text: "English (United Kingdom)",
//       value: "en-GB",
//     },
//     {
//       text: "English (Australia)",
//       value: "en-AU",
//     },
//     {
//       text: "English (Canada)",
//       value: "en-CA",
//     },
//     {
//       text: "English (Ghana)",
//       value: "en-GH",
//     },
//     {
//       text: "English (Ireland)",
//       value: "en-IE",
//     },
//     {
//       text: "English (Kenya)",
//       value: "en-KE",
//     },
//     {
//       text: "English (New Zealand)",
//       value: "en-NZ",
//     },
//     {
//       text: "English (Nigeria)",
//       value: "en-NG",
//     },
//     {
//       text: "English (Philippines)",
//       value: "en-PH",
//     },
//     {
//       text: "English (South Africa)",
//       value: "en-ZA",
//     },
//     {
//       text: "English (Tanzania)",
//       value: "en-TZ",
//     },
//     {
//       text: "English (Hong Kong)",
//       value: "en-HK",
//     },
//     {
//       text: "English (Pakistan)",
//       value: "en-PK",
//     },
//     {
//       text: "English (Singapore)",
//       value: "en-SG",
//     },
//     {
//       text: "Español (Estados Unidos) | Spanish (United States)",
//       value: "es-US",
//     },
//     {
//       text: "Español (México) | Spanish (Mexico)",
//       value: "es-MX",
//     },
//     {
//       text: "Español (Perú) | Spanish (Peru)",
//       value: "es-PE",
//     },
//     {
//       text: "Español (Argentina) | Spanish (Argentina)",
//       value: "es-AR",
//     },
//     {
//       text: "Español (Bolivia) | Spanish (Bolivia)",
//       value: "es-BO",
//     },
//     {
//       text: "Español (Chile) | Spanish (Chile)",
//       value: "es-CL",
//     },
//     {
//       text: "Español (Colombia) | Spanish (Colombia)",
//       value: "es-CO",
//     },
//     {
//       text: "Español (Costa Rica) | Spanish (Costa Rica)",
//       value: "es-CR",
//     },
//     {
//       text: "Español (República Dominicana) | Spanish (Dominican Republic)",
//       value: "es-DO",
//     },
//     {
//       text: "Español (Ecuador) | Spanish (Ecuador)",
//       value: "es-EC",
//     },
//     {
//       text: "Español (El Salvador) | Spanish (El Salvador)",
//       value: "es-SV",
//     },
//     {
//       text: "Español (Guatemala) | Spanish (Guatemala)",
//       value: "es-GT",
//     },
//     {
//       text: "Español (Honduras) | Spanish (Honduras)",
//       value: "es-HN",
//     },
//     {
//       text: "Español (Nicaragua) | Spanish (Nicaragua)",
//       value: "es-NI",
//     },
//     {
//       text: "Español (Panamá) | Spanish (Panama)",
//       value: "es-PA",
//     },
//     {
//       text: "Español (Paraguay) | Spanish (Paraguay)",
//       value: "es-PY",
//     },
//     {
//       text: "Español (Puerto Rico) | Spanish (Puerto Rico)",
//       value: "es-PR",
//     },
//     {
//       text: "Español (España) | Spanish (Spain)",
//       value: "es-ES",
//     },
//     {
//       text: "Español (Uruguay) | Spanish (Uruguay)",
//       value: "es-UY",
//     },
//     {
//       text: "Español (Venezuela) | Spanish (Venezuela)",
//       value: "es-VE",
//     },
//     {
//       text: "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)",
//       value: "af-ZA",
//     },
//     {
//       text: "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)",
//       value: "am-ET",
//     },
//     {
//       text: "العربية (الجزائر)‏ | Arabic (Algeria)",
//       value: "ar-DZ",
//     },
//     {
//       text: "العربية (البحرين)‏ | Arabic (Bahrain)",
//       value: "ar-BH",
//     },
//     {
//       text: "العربية (مصر)‏ | Arabic (Egypt)",
//       value: "ar-EG",
//     },
//     {
//       text: "العربية (العراق) | Arabic (Iraq)",
//       value: "ar-IQ",
//     },
//     {
//       text: "العربية (إسرائيل)‏ | Arabic (Israel)",
//       value: "ar-IL",
//     },
//     {
//       text: "العربية (الأردن)‏ | Arabic (Jordan)",
//       value: "ar-JO",
//     },
//     {
//       text: "العربية (الكويت) | Arabic (Kuwait)",
//       value: "ar-KW",
//     },
//     {
//       text: "العربية (عُمان) | Arabic (Oman)",
//       value: "ar-OM",
//     },
//     {
//       text: "العربية (لبنان) | Arabic (Lebanon)",
//       value: "ar-LB",
//     },
//     {
//       text: "العربية (المغرب) | Arabic (Morocco)",
//       value: "ar-MA",
//     },
//     {
//       text: "العربية (قطر)‏ | Arabic (Qatar)",
//       value: "ar-QA",
//     },
//     {
//       text: "العربية (السعودية)‏ | Arabic (Saudi Arabia)",
//       value: "ar-SA",
//     },
//     {
//       text: "العربية (فلسطين) | Arabic (State of Palestine)",
//       value: "ar-PS",
//     },
//     {
//       text: "العربية (تونس)‏ | Arabic (Tunisia)",
//       value: "ar-TN",
//     },
//     {
//       text: "العربية (الإمارات)‏ | Arabic (United Arab Emirates)",
//       value: "ar-AE",
//     },
//     {
//       text: "العربية (اليمن) | Arabic (Yemen)",
//       value: "ar-YE",
//     },
//     {
//       text: "Հայ (Հայաստան) | Armenian (Armenia)",
//       value: "hy-AM",
//     },
//     {
//       text: "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)",
//       value: "az-AZ",
//     },
//     {
//       text: "Euskara (Espainia) | Basque (Spain)",
//       value: "eu-ES",
//     },
//     {
//       text: "Български (България) | Bulgarian (Bulgaria)",
//       value: "bg-BG",
//     },
//     {
//       text: "Català (Espanya) | Catalan (Spain)",
//       value: "ca-ES",
//     },
//     {
//       text: "Hrvatski (Hrvatska) | Croatian (Croatia)",
//       value: "hr-HR",
//     },
//     {
//       text: "Čeština (Česká republika) | Czech (Czech Republic)",
//       value: "cs-CZ",
//     },
//     {
//       text: "Dansk (Danmark) | Danish (Denmark)",
//       value: "da-DK",
//     },
//     {
//       text: "Nederlands (Nederland) | Dutch (Netherlands)",
//       value: "nl-NL",
//     },
//     {
//       text: "Nederlands (België) | Dutch (Belgium)",
//       value: "nl-BE",
//     },
//     {
//       text: "Filipino (Pilipinas) | Filipino (Philippines)",
//       value: "fil-PH",
//     },
//     {
//       text: "Français (France) | French (France)",
//       value: "fr-FR",
//     },
//     {
//       text: "Français (Canada) | French (Canada)",
//       value: "fr-CA",
//     },
//     {
//       text: "Français (Belgique) | French (Belgium)",
//       value: "fr-BE",
//     },
//     {
//       text: "Français (Suisse) | French (Switzerland)",
//       value: "fr-CH",
//     },
//     {
//       text: "Galego (España) | Galician (Spain)",
//       value: "gl-ES",
//     },
//     {
//       text: "ქართული (საქართველო) | Georgian (Georgia)",
//       value: "ka-GE",
//     },
//     {
//       text: "Deutsch (Deutschland) | German (Germany)",
//       value: "de-DE",
//     },
//     {
//       text: "Deutsch (Österreich) | German (Austria)",
//       value: "de-AT",
//     },
//     {
//       text: "Deutsch (Schweiz) | German (Switzerland)",
//       value: "de-CH",
//     },
//     {
//       text: "Ελληνικά (Ελλάδα) | Greek (Greece)",
//       value: "el-GR",
//     },
//     {
//       text: "ગુજરાતી (ભારત) | Gujarati (India)",
//       value: "gu-IN",
//     },
//     {
//       text: "Íslenska (Ísland) | Icelandic (Iceland)",
//       value: "is-IS",
//     },
//     {
//       text: "Italiano (Italia) | Italian (Italy)",
//       value: "it-IT",
//     },
//     {
//       text: "Italiano (Svizzera) | Italian (Switzerland)",
//       value: "it-CH",
//     },
//     {
//       text: "日本語（日本) | Japanese (Japan)",
//       value: "ja-JP",
//     },
//     {
//       text: "Jawa (Indonesia) | Javanese (Indonesia)",
//       value: "jv-ID",
//     },
//     {
//       text: "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)",
//       value: "kn-IN",
//     },
//     {
//       text: "한국어 (대한민국) | Korean (South Korea)",
//       value: "ko-KR",
//     },
//     {
//       text: "ລາວ (ລາວ) | Lao (Laos)",
//       value: "lo-LA",
//     },
//     {
//       text: "Latviešu (latviešu) | Latvian (Latvia)",
//       value: "lv-LV",
//     },
//     {
//       text: "Lietuvių (Lietuva) | Lithuanian (Lithuania)",
//       value: "lt-LT",
//     },
//     {
//       text: "Bahasa Melayu (Malaysia) | Malay (Malaysia)",
//       value: "ms-MY",
//     },
//     {
//       text: "മലയാളം (ഇന്ത്യ) | Malayalam (India)",
//       value: "ml-IN",
//     },
//     {
//       text: "मराठी (भारत) | Marathi (India)",
//       value: "mr-IN",
//     },
//     {
//       text: "Norsk bokmål (Norge) | Norwegian Bokmål (Norway)",
//       value: "no-NO",
//     },
//     {
//       text: "Português (Brasil) | Portuguese (Brazil)",
//       value: "pt-BR",
//     },
//     {
//       text: "Português (Portugal) | Portuguese (Portugal)",
//       value: "pt-PT",
//     },
//     {
//       text: "Română (România) | Romanian (Romania)",
//       value: "ro-RO",
//     },
//     {
//       text: "Српски (Србија) | Serbian (Serbia)",
//       value: "sr-RS",
//     },
//     {
//       text: "සිංහල (ශ්රී ලංකාව) | Sinhala (Sri Lanka)",
//       value: "si-LK",
//     },
//     {
//       text: "Slovenčina (Slovensko) | Slovak (Slovakia)",
//       value: "sk-SK",
//     },
//     {
//       text: "Slovenščina (Slovenija) | Slovenian (Slovenia)",
//       value: "sl-SI",
//     },
//     {
//       text: "Kiswahili (Kenya) | Swahili (Kenya)",
//       value: "sw-KE",
//     },
//     {
//       text: "Kiswahili (Tanzania) | Swahili (Tanzania)",
//       value: "sw-TZ",
//     },
//     {
//       text: "தமிழ் (இந்தியா) | Tamil (India)",
//       value: "ta-IN",
//     },
//     {
//       text: "தமிழ் (மலேசியா) | Tamil (Malaysia)",
//       value: "ta-MY",
//     },
//     {
//       text: "தமிழ் (சிங்கப்பூர்) | Tamil (Singapore)",
//       value: "ta-SG",
//     },
//     {
//       text: "தமிழ் (இலங்கை) | Tamil (Sri Lanka)",
//       value: "ta-LK",
//     },
//     {
//       text: "తెలుగు (భారతదేశం) | Telugu (India)",
//       value: "te-IN",
//     },
//     {
//       text: "ไทย (ประเทศไทย) | Thai (Thailand)",
//       value: "th-TH",
//     },
//     {
//       text: "Türkçe (Türkiye) | Turkish (Turkey)",
//       value: "tr-TR",
//     },
//     {
//       text: "IsiZulu (Ningizimu Afrika) | Zulu (South Africa)",
//       value: "zu-ZA",
//     },
//     {
//       text: "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)",
//       value: "vi-VN",
//     },
//     {
//       text: "Suomi (Suomi) | Finnish (Finland)",
//       value: "fi-FI",
//     },
//     {
//       text: "Русский (Россия) | Russian (Russia)",
//       value: "ru-RU",
//     },
//     {
//       text: /*"中文、*/"普通话（简体中文）| Chinese, Mandarin (Simplified China)",
//       value: "cmn-Hans-CN",
//     },
//     {
//       text: /*"中文、*/"粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)",
//       value: "yue-Hant-HK",
//     },
//     {
//       text: "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)",
//       value: "cmn-Hant-TW",
//     },
//     {
//       text: "Polski (Polska) | Polish (Poland)",
//       value: "pl-PL",
//     },
//     {
//       text: "Українська (Україна) | Ukrainian (Ukraine)",
//       value: "uk-UA",
//     },
//     {
//       text: "Svenska (Sverige) | Swedish (Sweden)",
//       value: "sv-SE",
//     },
//     {
//       text: "हिन्दी (भारत) | Hindi (India)",
//       value: "hi-IN",
//     },
//     {
//       text: "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)",
//       value: "bn-BD",
//     },
//     {
//       text: "বাংলা (ভারত) | Bengali (India)",
//       value: "bn-IN",
//     },
//     {
//       text: "עברית (ישראל) | Hebrew (Israel)",
//       value: "iw-IL",
//     },
//     {
//       text: "Magyar (Magyarország) | Hungarian (Hungary)",
//       value: "hu-HU",
//     },
//     {
//       text: "नेपाली (नेपाल) | Nepali (Nepal)",
//       value: "ne-NP",
//     },
//     {
//       text: "فارسی (ایران) | Persian (Iran)",
//       value: "fa-IR",
//     },
//     {
//       text: "اردو (بھارت)‏ | Urdu (India)",
//       value: "ur-IN",
//     },
//     {
//       text: "اردو (پاکستان) | Urdu (Pakistan)",
//       value: "ur-PK",
//     },
//     {
//       text: "ဗမာ (မြန်မာ) | Burmese (Myanmar)",
//       value: "my-MM",
//     },
//     {
//       text: "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)",
//       value: "km-KH",
//     },
//     {
//       text: "Shqip (shqiperi) | Albanian (Albania)",
//       value: "sq-AL",
//     },
//     {
//       text: "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)",
//       value: "bs-BA",
//     },
//     {
//       text: "Eesti (eesti) | Estonian (Estonia)",
//       value: "et-EE",
//     },
//     {
//       text: "Indonesia (Indonesia) | Indonesian (Indonesia)",
//       value: "id-ID",
//     },
//     {
//       text: "Қазақ (Қазақстан) | Kazakh (Kazakhstan)",
//       value: "kk-KZ",
//     },
//     {
//       text: "Македонски (северна Македонија) | Macedonian (North Macedonia)",
//       value: "mk-MK",
//     },
//     {
//       text: "Монгол (Монгол) | Mongolian (Mongolia)",
//       value: "mn-MN",
//     },
//     {
//       text: "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ ਇੰਡੀਆ) | Punjabi (Gurmukhi India)",
//       value: "pa-Guru-IN",
//     },
//     {
//       text: "Basa Sunda (Indonésia) | Sundanese (Indonesia)"/*Sundan (Indonesia)*/,
//       value: "su-ID",
//     },
//     {
//       text: "O'zbek (O'zbekiston) | Uzbek (Uzbekistan)",
//       value: "uz-UZ",
//     },

//     // Below was added at Marty request on July 1, 2024 by Scott Brady
//     // These are from "Speech-to-Text V2 supported languages"
//     // Commented out on July 3, 2024 until we can implement functionality for V2
//     // {
//     //   text: "অসমীয়া (ভাৰত) | Assamese (India)",
//     //   value: "as-IN",
//     // },
//     // {
//     //   text: "Asturian (España) | Asturian (Spain)",
//     //   value: "ast-ES",
//     // },
//     // {
//     //   text: "Cebuano (Pilipinas) | Cebuano (Philippines)",
//     //   value: "ceb-PH",
//     // },
//     // {
//     //   text: "کوردی ناوەڕاست (عێراق) | Central Kurdish (Iraq)",
//     //   value: "ckb-IQ",
//     // },
//     // {
//     //   text: "Hausa (Nigeria)",
//     //   value: "ha-NG",
//     // },
//     // {
//     //   text: "Kabuverdianu (Cape Verde)",
//     //   value: "kea-CV",
//     // },
//     // {
//     //   text: "Kamba (Kenya)",
//     //   value: "kam-KE",
//     // },
//     // {
//     //   text: "Кыргызча (кириллица) | Kyrgyz (Cyrillic)",
//     //   value: "ky-KG",
//     // },
//     // {
//     //   text: "Lingala (Kongo-Kinshasa) | Lingala (Congo-Kinshasa)",
//     //   value: "ln-CD",
//     // },
//     // {
//     //   text: "Luo (Kenya)",
//     //   value: "luo-KE",
//     // },
//     // {
//     //   text: "Lëtzebuergesch (Lëtzebuerg) | Luxembourgish (Luxembourg)",
//     //   value: "lb-LU",
//     // },
//     // {
//     //   text: "Malti (Malta) | Maltese (Malta)",
//     //   value: "mt-MT",
//     // },
//     // {
//     //   text: "Maori (New Zealand)",
//     //   value: "mi-NZ",
//     // },
//     // {
//     //   text: "Nyanja (Malawi)",
//     //   value: "ny-MW",
//     // },
//     // {
//     //   text: "Occitan (França) | Occitan (France)",
//     //   value: "oc-FR",
//     // },
//     // {
//     //   text: "پښتو | Pashto",
//     //   value: "ps-AF",
//     // },
//     // {
//     //   text: "Sepedi (Afrika Borwa) | Sepedi (South Africa)",
//     //   value: "nso-ZA",
//     // },
//     // {
//     //   text: "Shona (Zimbabwe)",
//     //   value: "sn-ZW",
//     // },
//     // {
//     //   text: "سنڌي (ڀارت) | Sindhi (India)",
//     //   value: "sd-IN",
//     // },
//     // {
//     //   text: "Soomaali | Somali",
//     //   value: "so-SO",
//     // },
//     // {
//     //   text: "тоҷикӣ (Тоҷикистон) | Tajik (Tajikistan)",
//     //   value: "tg-TJ",
//     // },
//     // {
//     //   text: "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)",
//     //   value: "cy-GB",
//     // },
//     // {
//     //   text: "Wolof (Senegaal) | Wolof (Senegal)",
//     //   value: "wo-SN",
//     // },
//     // {
//     //   text: "Èdè Yorùbá (Nàìjíríà) | Yoruba (Nigeria)",
//     //   value: "yo-NG",
//     // },
//     // {
//     //   text: "",
//     //   value: "",
//     // },
//   ];

//   export default { languageCodes };


// const languageCodes = [
//     {
//       text: "Shqip (shqiperi) | Albanian (Albania)",
//       value: "sq-AL",
//     },
//     {
//       text: "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)",
//       value: "af-ZA",
//     },
//     {
//       text: "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)",
//       value: "am-ET",
//     },
//     {
//       text: "العربية (الجزائر)‏ | Arabic (Algeria)",
//       value: "ar-DZ",
//     },
//     {
//       text: "العربية (البحرين)‏ | Arabic (Bahrain)",
//       value: "ar-BH",
//     },
//     {
//       text: "العربية (مصر)‏ | Arabic (Egypt)",
//       value: "ar-EG",
//     },
//     {
//       text: "العربية (العراق) | Arabic (Iraq)",
//       value: "ar-IQ",
//     },
//     {
//       text: "العربية (إسرائيل)‏ | Arabic (Israel)",
//       value: "ar-IL",
//     },
//     {
//       text: "العربية (الأردن)‏ | Arabic (Jordan)",
//       value: "ar-JO",
//     },
//     {
//       text: "العربية (الكويت) | Arabic (Kuwait)",
//       value: "ar-KW",
//     },
//     {
//       text: "العربية (عُمان) | Arabic (Oman)",
//       value: "ar-OM",
//     },
//     {
//       text: "العربية (لبنان) | Arabic (Lebanon)",
//       value: "ar-LB",
//     },
//     {
//       text: "العربية (المغرب) | Arabic (Morocco)",
//       value: "ar-MA",
//     },
//     {
//       text: "العربية (قطر)‏ | Arabic (Qatar)",
//       value: "ar-QA",
//     },
//     {
//       text: "العربية (السعودية)‏ | Arabic (Saudi Arabia)",
//       value: "ar-SA",
//     },
//     {
//       text: "العربية (فلسطين) | Arabic (State of Palestine)",
//       value: "ar-PS",
//     },
//     {
//       text: "العربية (تونس)‏ | Arabic (Tunisia)",
//       value: "ar-TN",
//     },
//     {
//       text: "العربية (الإمارات)‏ | Arabic (United Arab Emirates)",
//       value: "ar-AE",
//     },
//     {
//       text: "العربية (اليمن) | Arabic (Yemen)",
//       value: "ar-YE",
//     },
//     {
//       text: "Հայ (Հայաստան) | Armenian (Armenia)",
//       value: "hy-AM",
//     },
//     {
//       text: "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)",
//       value: "az-AZ",
//     },
//     {
//       text: "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)",
//       value: "bn-BD",
//     },
//     {
//       text: "বাংলা (ভারত) | Bengali (India)",
//       value: "bn-IN",
//     },
//     {
//       text: "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)",
//       value: "bs-BA",
//     },
//     {
//       text: "Български (България) | Bulgarian (Bulgaria)",
//       value: "bg-BG",
//     },
//     {
//       text: "ဗမာ (မြန်မာ) | Burmese (Myanmar)",
//       value: "my-MM",
//     },
//     {
//       text: "Català (Espanya) | Catalan (Spain)",
//       value: "ca-ES",
//     },
//     {
//       text: "Cebuano (Pilipinas) | Cebuano (Philippines)",
//       value: "ceb-PH",
//     },
//     {
//       text: "普通话（简体中文）| Chinese, Mandarin (Simplified China)",
//       value: "zh",
//     },
//     {
//       text: "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)",
//       value: "zh-TW",
//     },
//     {
//       text: "粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)",
//       value: "yue-Hant-HK",
//     },
//     {
//       text: "کوردی ناوەڕاست (عێراق) | Central Kurdish (Iraq)",
//       value: "ckb-IQ",
//     },
//     {
//       text: "Čeština (Česká republika) | Czech (Czech Republic)",
//       value: "cs-CZ",
//     },
//     {
//       text: "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)",
//       value: "cy-GB",
//     },
//     {
//       text: "Dansk (Danmark) | Danish (Denmark)",
//       value: "da-DK",
//     },
//     {
//       text: "Deutsch (Deutschland) | German (Germany)",
//       value: "de-DE",
//     },
//     {
//       text: "Deutsch (Österreich) | German (Austria)",
//       value: "de-AT",
//     },
//     {
//       text: "Deutsch (Schweiz) | German (Switzerland)",
//       value: "de-CH",
//     },
//     {
//       text: "Ελληνικά (Ελλάδα) | Greek (Greece)",
//       value: "el-GR",
//     },
//     {
//       text: "English (Australia)",
//       value: "en-AU",
//     },
//     {
//       text: "English (Canada)",
//       value: "en-CA",
//     },
//     {
//       text: "English (Ghana)",
//       value: "en-GH",
//     },
//     {
//       text: "English (Hong Kong)",
//       value: "en-HK",
//     },
//     {
//       text: "English (India)",
//       value: "en-IN",
//     },
//     {
//       text: "English (Ireland)",
//       value: "en-IE",
//     },
//     {
//       text: "English (Kenya)",
//       value: "en-KE",
//     },
//     {
//       text: "English (New Zealand)",
//       value: "en-NZ",
//     },
//     {
//       text: "English (Nigeria)",
//       value: "en-NG",
//     },
//     {
//       text: "English (Pakistan)",
//       value: "en-PK",
//     },
//     {
//       text: "English (Philippines)",
//       value: "en-PH",
//     },
//     {
//       text: "English (Singapore)",
//       value: "en-SG",
//     },
//     {
//       text: "English (South Africa)",
//       value: "en-ZA",
//     },
//     {
//       text: "English (Tanzania)",
//       value: "en-TZ",
//     },
//     {
//       text: "English (United Kingdom)",
//       value: "en-GB",
//     },
//     {
//       text: "English (United States)",
//       value: "en-US",
//     },
//     {
//       text: "Español (Argentina) | Spanish (Argentina)",
//       value: "es-AR",
//     },
//     {
//       text: "Español (Bolivia) | Spanish (Bolivia)",
//       value: "es-BO",
//     },
//     {
//       text: "Español (Chile) | Spanish (Chile)",
//       value: "es-CL",
//     },
//     {
//       text: "Español (Colombia) | Spanish (Colombia)",
//       value: "es-CO",
//     },
//     {
//       text: "Español (Costa Rica) | Spanish (Costa Rica)",
//       value: "es-CR",
//     },
//     {
//       text: "Español (República Dominicana) | Spanish (Dominican Republic)",
//       value: "es-DO",
//     },
//     {
//       text: "Español (Ecuador) | Spanish (Ecuador)",
//       value: "es-EC",
//     },
//     {
//       text: "Español (El Salvador) | Spanish (El Salvador)",
//       value: "es-SV",
//     },
//     {
//       text: "Español (España) | Spanish (Spain)",
//       value: "es-ES",
//     },
//     {
//       text: "Español (Estados Unidos) | Spanish (United States)",
//       value: "es-US",
//     },
//     {
//       text: "Español (Guatemala) | Spanish (Guatemala)",
//       value: "es-GT",
//     },
//     {
//       text: "Español (Honduras) | Spanish (Honduras)",
//       value: "es-HN",
//     },
//     {
//       text: "Español (México) | Spanish (Mexico)",
//       value: "es-MX",
//     },
//     {
//       text: "Español (Nicaragua) | Spanish (Nicaragua)",
//       value: "es-NI",
//     },
//     {
//       text: "Español (Panamá) | Spanish (Panama)",
//       value: "es-PA",
//     },
//     {
//       text: "Español (Paraguay) | Spanish (Paraguay)",
//       value: "es-PY",
//     },
//     {
//       text: "Español (Perú) | Spanish (Peru)",
//       value: "es-PE",
//     },
//     {
//       text: "Español (Puerto Rico) | Spanish (Puerto Rico)",
//       value: "es-PR",
//     },
//     {
//       text: "Español (Uruguay) | Spanish (Uruguay)",
//       value: "es-UY",
//     },
//     {
//       text: "Español (Venezuela) | Spanish (Venezuela)",
//       value: "es-VE",
//     },
//     {
//       text: "Eesti (Eesti) | Estonian (Estonia)",
//       value: "et-EE",
//     },
//     {
//       text: "Euskara (Espainia) | Basque (Spain)",
//       value: "eu-ES",
//     },
//     {
//       text: "Filipino (Pilipinas) | Filipino (Philippines)",
//       value: "fil-PH",
//     },
//     {
//       text: "Français (Belgique) | French (Belgium)",
//       value: "fr-BE",
//     },
//     {
//       text: "Français (Canada) | French (Canada)",
//       value: "fr-CA",
//     },
//     {
//       text: "Français (France) | French (France)",
//       value: "fr-FR",
//     },
//     {
//       text: "Français (Suisse) | French (Switzerland)",
//       value: "fr-CH",
//     },
//     {
//       text: "Galego (España) | Galician (Spain)",
//       value: "gl-ES",
//     },
//     {
//       text: "ქართული (საქართველო) | Georgian (Georgia)",
//       value: "ka-GE",
//     },
//     {
//       text: "ગુજરાતી (ભારત) | Gujarati (India)",
//       value: "gu-IN",
//     },
//     {
//       text: "Hausa (Najeriya) | Hausa (Nigeria)",
//       value: "ha-NG",
//     },
//     {
//       text: "עברית (ישראל) | Hebrew (Israel)",
//       value: "he-IL",
//     },
//     {
//       text: "हिन्दी (भारत) | Hindi (India)",
//       value: "hi-IN",
//     },
//     {
//       text: "Hrvatski (Hrvatska) | Croatian (Croatia)",
//       value: "hr-HR",
//     },
//     {
//       text: "Kreyòl Ayisyen (Ayiti) | Haitian Creole (Haiti)",
//       value: "ht-HT",
//     },
//     {
//       text: "Magyar (Magyarország) | Hungarian (Hungary)",
//       value: "hu-HU",
//     },
//     {
//       text: "Íslenska (Ísland) | Icelandic (Iceland)",
//       value: "is-IS",
//     },
//     {
//       text: "Igbo (Nigeria) | Igbo (Nigeria)",
//       value: "ig-NG",
//     },
//     {
//       text: "Bahasa Indonesia (Indonesia) | Indonesian (Indonesia)",
//       value: "id-ID",
//     },
//     {
//       text: "Italiano (Italia) | Italian (Italy)",
//       value: "it-IT",
//     },
//     {
//       text: "Italiano (Svizzera) | Italian (Switzerland)",
//       value: "it-CH",
//     },
//     {
//       text: "日本語（日本) | Japanese (Japan)",
//       value: "ja-JP",
//     },
//     {
//       text: "Jawa (Indonesia) | Javanese (Indonesia)",
//       value: "jv-ID",
//     },
//     {
//       text: "ქართული (საქართველო) | Georgian (Georgia)",
//       value: "ka-GE",
//     },
//     {
//       text: "Қазақ Тілі (Қазақстан) | Kazakh (Kazakhstan)",
//       value: "kk-KZ",
//     },
//     {
//       text: "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)",
//       value: "km-KH",
//     },
//     {
//       text: "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)",
//       value: "kn-IN",
//     },
//     {
//       text: "한국어 (대한민국) | Korean (South Korea)",
//       value: "ko-KR",
//     },
//     {
//       text: "Кыргызча (Кыргызстан) | Kyrgyz (Kyrgyzstan)",
//       value: "ky-KG",
//     },
//     {
//       text: "ລາວ (ລາວ) | Lao (Laos)",
//       value: "lo-LA",
//     },
//     {
//       text: "Latviešu (latvija) | Latvian (Latvia)",
//       value: "lv-LV",
//     },
//     {
//       text: "Lietuvių (Lietuva) | Lithuanian (Lithuania)",
//       value: "lt-LT",
//     },
//     {
//       text: "Македонски (Северна Македонија) | Macedonian (North Macedonia)",
//       value: "mk-MK",
//     },
//     {
//       text: "Bahasa Melayu (Malaysia) | Malay (Malaysia)",
//       value: "ms-MY",
//     },
//     {
//       text: "മലയാളം (ഇന്ത്യ) | Malayalam (India)",
//       value: "ml-IN",
//     },
//     {
//       text: "मराठी (भारत) | Marathi (India)",
//       value: "mr-IN",
//     },
//     {
//       text: "Монгол (Монгол) | Mongolian (Mongolia)",
//       value: "mn-MN",
//     },
//     {
//       text: "नेपाली (नेपाल) | Nepali (Nepal)",
//       value: "ne-NP",
//     },
//     {
//       text: "Nederlands (Nederland) | Dutch (Netherlands)",
//       value: "nl-NL",
//     },
//     {
//       text: "Nederlands (België) | Dutch (Belgium)",
//       value: "nl-BE",
//     },
//     {
//       text: "Norsk bokmål (Norge) | Norwegian Bokmål (Norway)",
//       value: "nb-NO",
//     },
//     {
//       text: "پښتو (افغانستان) | Pashto (Afghanistan)",
//       value: "ps-AF",
//     },
//     {
//       text: "فارسی (ایران) | Persian (Iran)",
//       value: "fa-IR",
//     },
//     {
//       text: "Polski (Polska) | Polish (Poland)",
//       value: "pl-PL",
//     },
//     {
//       text: "Português (Brasil) | Portuguese (Brazil)",
//       value: "pt-BR",
//     },
//     {
//       text: "Português (Portugal) | Portuguese (Portugal)",
//       value: "pt-PT",
//     },
//     {
//       text: "Română (România) | Romanian (Romania)",
//       value: "ro-RO",
//     },
//     {
//       text: "Русский (Россия) | Russian (Russia)",
//       value: "ru-RU",
//     },
//     {
//       text: "Српски (Србија) | Serbian (Serbia)",
//       value: "sr-RS",
//     },
//     {
//       text: "සිංහල (ශ්රී ලංකාව) | Sinhala (Sri Lanka)",
//       value: "si-LK",
//     },
//     {
//       text: "Slovenčina (Slovensko) | Slovak (Slovakia)",
//       value: "sk-SK",
//     },
//     {
//       text: "Slovenščina (Slovenija) | Slovenian (Slovenia)",
//       value: "sl-SI",
//     },
//     {
//       text: "Somali (Soomaaliya) | Somali (Somalia)",
//       value: "so-SO",
//     },
//     {
//       text: "Kiswahili (Tanzania) | Swahili (Tanzania)",
//       value: "sw-TZ",
//     },
//     {
//       text: "Kiswahili (Kenya) | Swahili (Kenya)",
//       value: "sw-KE",
//     },
//     {
//       text: "Svenska (Sverige) | Swedish (Sweden)",
//       value: "sv-SE",
//     },
//     {
//       text: "Tagalog (Pilipinas) | Tagalog (Philippines)",
//       value: "tl-PH",
//     },
//     {
//       text: "தமிழ் (இந்தியா) | Tamil (India)",
//       value: "ta-IN",
//     },
//     {
//       text: "తెలుగు (భారతదేశం) | Telugu (India)",
//       value: "te-IN",
//     },
//     {
//       text: "ไทย (ประเทศไทย) | Thai (Thailand)",
//       value: "th-TH",
//     },
//     {
//       text: "Türkçe (Türkiye) | Turkish (Turkey)",
//       value: "tr-TR",
//     },
//     {
//       text: "Українська (Україна) | Ukrainian (Ukraine)",
//       value: "uk-UA",
//     },
//     {
//       text: "اردو (پاکستان) | Urdu (Pakistan)",
//       value: "ur-PK",
//     },
//     {
//       text: "O'zbek (Oʻzbekiston) | Uzbek (Uzbekistan)",
//       value: "uz-UZ",
//     },
//     {
//       text: "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)",
//       value: "vi-VN",
//     },
//     {
//       text: "isiZulu (Ningizimu Afrika) | Zulu (South Africa)",
//       value: "zu-ZA",
//     },
// ];



//   const languageCodes = [
//     {
//       text: "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)",
//       value: "af-ZA",
//     },
//     {
//       text: "অসমীয়া (ভাৰত) | Assamese (India)",
//       value: "as-IN",
//     },
//     {
//       text: "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)",
//       value: "am-ET",
//     },
//     {
//       text: "العربية (الجزائر)‏ | Arabic (Algeria)",
//       value: "ar-DZ",
//     },
//     {
//       text: "العربية (البحرين)‏ | Arabic (Bahrain)",
//       value: "ar-BH",
//     },
//     {
//       text: "العربية (مصر)‏ | Arabic (Egypt)",
//       value: "ar-EG",
//     },
//     {
//       text: "العربية (العراق) | Arabic (Iraq)",
//       value: "ar-IQ",
//     },
//     {
//       text: "العربية (إسرائيل)‏ | Arabic (Israel)",
//       value: "ar-IL",
//     },
//     {
//       text: "العربية (الأردن)‏ | Arabic (Jordan)",
//       value: "ar-JO",
//     },
//     {
//       text: "العربية (الكويت) | Arabic (Kuwait)",
//       value: "ar-KW",
//     },
//     {
//       text: "العربية (لبنان) | Arabic (Lebanon)",
//       value: "ar-LB",
//     },
//     {
//       text: "العربية (المغرب) | Arabic (Morocco)",
//       value: "ar-MA",
//     },
//     {
//       text: "العربية (عُمان) | Arabic (Oman)",
//       value: "ar-OM",
//     },
//     {
//       text: "العربية (فلسطين) | Arabic (State of Palestine)",
//       value: "ar-PS",
//     },
//     {
//       text: "العربية (قطر)‏ | Arabic (Qatar)",
//       value: "ar-QA",
//     },
//     {
//       text: "العربية (السعودية)‏ | Arabic (Saudi Arabia)",
//       value: "ar-SA",
//     },
//     {
//       text: "العربية (تونس)‏ | Arabic (Tunisia)",
//       value: "ar-TN",
//     },
//     {
//       text: "العربية (الإمارات)‏ | Arabic (United Arab Emirates)",
//       value: "ar-AE",
//     },
//     {
//       text: "العربية (اليمن) | Arabic (Yemen)",
//       value: "ar-YE",
//     },
//     {
//       text: "Asturian (España) | Asturian (Spain)",
//       value: "ast-ES",
//     },
//     {
//       text: "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)",
//       value: "az-AZ",
//     },
//     {
//       text: "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)",
//       value: "bn-BD",
//     },
//     {
//       text: "বাংলা (ভারত) | Bengali (India)",
//       value: "bn-IN",
//     },
//     {
//       text: "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)",
//       value: "bs-BA",
//     },
//     {
//       text: "Български (България) | Bulgarian (Bulgaria)",
//       value: "bg-BG",
//     },
//     {
//       text: "Català (Espanya) | Catalan (Spain)",
//       value: "ca-ES",
//     },
//     {
//       text: "Cebuano (Pilipinas) | Cebuano (Philippines)",
//       value: "ceb-PH",
//     },
//     {
//       text: "中文、普通话（简体中文）| Chinese, Mandarin (Simplified China)",
//       value: "zh",
//     },
//     {
//       text: "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)",
//       value: "zh-TW",
//     },
//     {
//       text: "中文、粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)",
//       value: "yue-Hant-HK",
//     },
//     {
//       text: "Hrvatski (Hrvatska) | Croatian (Croatia)",
//       value: "hr-HR",
//     },
//     {
//       text: "Čeština (Česká republika) | Czech (Czech Republic)",
//       value: "cs-CZ",
//     },
//     {
//       text: "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)",
//       value: "cy-GB",
//     },
//     {
//       text: "Dansk (Danmark) | Danish (Denmark)",
//       value: "da-DK",
//     },
//     {
//       text: "Nederlands (België) | Dutch (Belgium)",
//       value: "nl-BE",
//     },
//     {
//       text: "Nederlands (Nederland) | Dutch (Netherlands)",
//       value: "nl-NL",
//     },
//     {
//       text: "English (Australia)",
//       value: "en-AU"
//     },
//     {
//       text: "English (Canada)",
//       value: "en-CA",
//     },
//     {
//       text: "English (Ghana)",
//       value: "en-GH",
//     },
//     {
//       text: "English (Hong Kong)",
//       value: "en-HK",
//     },
//     {
//       text: "English (India)",
//       value: "en-IN",
//     },
//     {
//       text: "English (Ireland)",
//       value: "en-IE",
//     },
//     {
//       text: "English (Kenya)",
//       value: "en-KE",
//     },
//     {
//       text: "English (New Zealand)",
//       value: "en-NZ",
//     },
//     {
//       text: "English (Nigeria)",
//       value: "en-NG",
//     },
//     {
//       text: "English (Pakistan)",
//       value: "en-PK",
//     },
//     {
//       text: "English (Philippines)",
//       value: "en-PH",
//     },
//     {
//       text: "English (Singapore)",
//       value: "en-SG",
//     },
//     {
//       text: "English (South Africa)",
//       value: "en-ZA",
//     },
//     {
//       text: "English (Tanzania)",
//       value: "en-TZ",
//     },
//     {
//       text: "English (United Kingdom)",
//       value: "en-GB",
//     },
//     {
//       text: "English (United States)",
//       value: "en-US",
//     },
//     {
//       text: "Español (Argentina) | Spanish (Argentina)",
//       value: "es-AR",
//     },
//     {
//       text: "Español (Bolivia) | Spanish (Bolivia)",
//       value: "es-BO",
//     },
//     {
//       text: "Español (Chile) | Spanish (Chile)",
//       value: "es-CL",
//     },
//     {
//       text: "Español (Colombia) | Spanish (Colombia)",
//       value: "es-CO",
//     },
//     {
//       text: "Español (Costa Rica) | Spanish (Costa Rica)",
//       value: "es-CR",
//     },
//     {
//       text: "Español (Cuba) | Spanish (Cuba)",
//       value: "es-CU",
//     },
//     {
//       text: "Español (Ecuador) | Spanish (Ecuador)",
//       value: "es-EC",
//     },
//     {
//       text: "Español (El Salvador) | Spanish (El Salvador)",
//       value: "es-SV",
//     },
//     {
//       text: "Español (España) | Spanish (Spain)",
//       value: "es-ES",
//     },
//     {
//       text: "Español (Estados Unidos) | Spanish (United States)",
//       value: "es-US",
//     },
//     {
//       text: "Español (Guatemala) | Spanish (Guatemala)",
//       value: "es-GT",
//     },
//     {
//       text: "Español (Honduras) | Spanish (Honduras)",
//       value: "es-HN",
//     },
//     {
//       text: "Español (México) | Spanish (Mexico)",
//       value: "es-MX",
//     },
//     {
//       text: "Español (Nicaragua) | Spanish (Nicaragua)",
//       value: "es-NI",
//     },
//     {
//       text: "Español (Panamá) | Spanish (Panama)",
//       value: "es-PA",
//     },
//     {
//       text: "Español (Paraguay) | Spanish (Paraguay)",
//       value: "es-PY",
//     },
//     {
//       text: "Español (Perú) | Spanish (Peru)",
//       value: "es-PE",
//     },
//     {
//       text: "Español (Puerto Rico) | Spanish (Puerto Rico)",
//       value: "es-PR",
//     },
//     {
//       text: "Español (República Dominicana) | Spanish (Dominican Republic)",
//       value: "es-DO",
//     },
//     {
//       text: "Español (Uruguay) | Spanish (Uruguay)",
//       value: "es-UY",
//     },
//     {
//       text: "Español (Venezuela) | Spanish (Venezuela)",
//       value: "es-VE",
//     },
//     {
//       text: "Euskara (Espainia) | Basque (Spain)",
//       value: "eu-ES",
//     },
//     {
//       text: "Filipino (Pilipinas) | Filipino (Philippines)",
//       value: "fil-PH",
//     },
//     {
//       text: "Français (Belgique) | French (Belgium)",
//       value: "fr-BE",
//     },
//     {
//       text: "Français (Canada) | French (Canada)",
//       value: "fr-CA",
//     },
//     {
//       text: "Français (France)",
//       value: "fr-FR",
//     },
//     {
//       text: "Français (Suisse) | French (Switzerland)",
//       value: "fr-CH",
//     },
//     {
//       text: "Gaeilge (Éire) | Irish (Ireland)",
//       value: "ga-IE",
//     },
//     {
//       text: "Galego (España) | Galician (Spain)",
//       value: "gl-ES",
//     },
//     {
//       text: "ગુજરાતી (ભારત) | Gujarati (India)",
//       value: "gu-IN",
//     },
//     {
//       text: "Hausa (Nijeriya) | Hausa (Nigeria)",
//       value: "ha-NG",
//     },
//     {
//       text: "עברית (ישראל) | Hebrew (Israel)",
//       value: "iw-IL",
//     },
//     {
//       text: "हिन्दी (भारत) | Hindi (India)",
//       value: "hi-IN",
//     },
//     {
//       text: "Hmong (United States)",
//       value: "hmn-US",
//     },
//     {
//       text: "Magyar (Magyarország) | Hungarian (Hungary)",
//       value: "hu-HU",
//     },
//     {
//       text: "Icelandic (Iceland)",
//       value: "is-IS",
//     },
//     {
//       text: "Igbo (Nigeria)",
//       value: "ig-NG",
//     },
//     {
//       text: "Bahasa Indonesia (Indonesia) | Indonesian (Indonesia)",
//       value: "id-ID",
//     },
//     {
//       text: "Italiano (Italia) | Italian (Italy)",
//       value: "it-IT",
//     },
//     {
//       text: "日本語（日本）| Japanese (Japan)",
//       value: "ja-JP",
//     },
//     {
//       text: "Javanese (Indonesia)",
//       value: "jv-ID",
//     },
//     {
//       text: "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)",
//       value: "kn-IN",
//     },
//     {
//       text: "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)",
//       value: "km-KH",
//     },
//     {
//       text: "한국어 (대한민국) | Korean (South Korea)",
//       value: "ko-KR",
//     },
//     {
//       text: "ລາວ (ລາວ) | Lao (Laos)",
//       value: "lo-LA",
//     },
//     {
//       text: "Latviešu (Latvija) | Latvian (Latvia)",
//       value: "lv-LV",
//     },
//     {
//       text: "Lietuvių (Lietuva) | Lithuanian (Lithuania)",
//       value: "lt-LT",
//     },
//     {
//       text: "Македонски (Северна Македонија) | Macedonian (North Macedonia)",
//       value: "mk-MK",
//     },
//     {
//       text: "Malagasy (Madagascar)",
//       value: "mg-MG",
//     },
//     {
//       text: "മലയാളം (ഇന്ത്യ) | Malayalam (India)",
//       value: "ml-IN",
//     },
//     {
//       text: "मराठी (भारत) | Marathi (India)",
//       value: "mr-IN",
//     },
//     {
//       text: "Bahasa Melayu (Malaysia) | Malay (Malaysia)",
//       value: "ms-MY",
//     },
//     {
//       text: "Malti (Malta) | Maltese (Malta)",
//       value: "mt-MT",
//     },
//     {
//       text: "Hmong Daw (Moob Leeg)",
//       value: "mww",
//     },
//     {
//     {
//       text: "Монгол (Монгол) | Mongolian (Mongolia)",
//       value: "mn-MN",
//     },
//     {
//       text: "မြန်မာ (မြန်မာ) | Burmese (Myanmar)",
//       value: "my-MM",
//     },
//     {
//       text: "नेपाली (नेपाल) | Nepali (Nepal)",
//       value: "ne-NP",
//     },
//     {
//       text: "Norsk (Norge) | Norwegian (Norway)",
//       value: "nb-NO",
//     },
//     {
//       text: "Odia (India)",
//       value: "or-IN",
//     },
//     {
//       text: "فارسی (ایران) | Persian (Iran)",
//       value: "fa-IR",
//     },
//     {
//       text: "Polski (Polska) | Polish (Poland)",
//       value: "pl-PL",
//     },
//     {
//       text: "Português (Brasil) | Portuguese (Brazil)",
//       value: "pt-BR",
//     },
//     {
//       text: "Português (Portugal)",
//       value: "pt-PT",
//     },
//     {
//       text: "Punjabi (Gurmukhi India)",
//       value: "pa-Guru-IN",
//     },
//     {
//       text: "Română (România) | Romanian (Romania)",
//       value: "ro-RO",
//     },
//     {
//       text: "Русский (Россия) | Russian (Russia)",
//       value: "ru-RU",
//     },
//     {
//       text: "Српски (Србија) | Serbian (Serbia)",
//       value: "sr-RS",
//     },
//     {
//       text: "සිංහල (ශ්‍රී ලංකාව) | Sinhalese (Sri Lanka)",
//       value: "si-LK",
//     },
//     {
//       text: "Slovenčina (Slovensko) | Slovak (Slovakia)",
//       value: "sk-SK",
//     },
//     {
//       text: "Slovenščina (Slovenija) | Slovenian (Slovenia)",
//       value: "sl-SI",
//     },
//     {
//       text: "Somali (Soomaaliya) | Somali (Somalia)",
//       value: "so-SO",
//     },
//     {
//       text: "Kiswahili (Kenya)",
//       value: "sw-KE",
//     },
//     {
//       text: "Kiswahili (Tanzania)",
//       value: "sw-TZ",
//     },
//     {
//       text: "Svenska (Sverige) | Swedish (Sweden)",
//       value: "sv-SE",
//     },
//     {
//       text: "Tagalog (Pilipinas) | Tagalog (Philippines)",
//       value: "tl-PH",
//     },
//     {
//       text: "தமிழ் (இந்தியா) | Tamil (India)",
//       value: "ta-IN",
//     },
//     {
//       text: "తెలుగు (భారతదేశం) | Telugu (India)",
//       value: "te-IN",
//     },
//     {
//       text: "ภาษาไทย (ไทย) | Thai (Thailand)",
//       value: "th-TH",
//     },
//     {
//       text: "Türkçe (Türkiye) | Turkish (Turkey)",
//       value: "tr-TR",
//     },
//     {
//       text: "Українська (Україна) | Ukrainian (Ukraine)",
//       value: "uk-UA",
//     },
//     {
//       text: "اردو (بھارت) | Urdu (India)",
//       value: "ur-IN",
//     },
//     {
//       text: "اردو (پاکستان) | Urdu (Pakistan)",
//       value: "ur-PK",
//     },
//     {
//       text: "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)",
//       value: "vi-VN",
//     },
//     {
//       text: "Yoruba (Nigeria)",
//       value: "yo-NG",
//     },
//     {
//       text: "isiZulu (Ningizimu Afrika) | Zulu (South Africa)",
//       value: "zu-ZA",
//     },
//     {
// ];

// export default { languageCodes };

// [
//   {"text": "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)", "value": "af-ZA"},
//   {"text": "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)", "value": "am-ET"},
//   {"text": "العربية (الجزائر)‏ | Arabic (Algeria)", "value": "ar-DZ"},
//   {"text": "العربية (البحرين)‏ | Arabic (Bahrain)", "value": "ar-BH"},
//   {"text": "العربية (مصر)‏ | Arabic (Egypt)", "value": "ar-EG"},
//   {"text": "العربية (العراق) | Arabic (Iraq)", "value": "ar-IQ"},
//   {"text": "العربية (إسرائيل)‏ | Arabic (Israel)", "value": "ar-IL"},
//   {"text": "العربية (الأردن)‏ | Arabic (Jordan)", "value": "ar-JO"},
//   {"text": "العربية (الكويت) | Arabic (Kuwait)", "value": "ar-KW"},
//   {"text": "العربية (لبنان) | Arabic (Lebanon)", "value": "ar-LB"},
//   {"text": "العربية (المغرب) | Arabic (Morocco)", "value": "ar-MA"},
//   {"text": "العربية (عُمان) | Arabic (Oman)", "value": "ar-OM"},
//   {"text": "العربية (فلسطين) | Arabic (State of Palestine)", "value": "ar-PS"},
//   {"text": "العربية (قطر)‏ | Arabic (Qatar)", "value": "ar-QA"},
//   {"text": "العربية (السعودية)‏ | Arabic (Saudi Arabia)", "value": "ar-SA"},
//   {"text": "العربية (تونس)‏ | Arabic (Tunisia)", "value": "ar-TN"},
//   {"text": "العربية (الإمارات)‏ | Arabic (United Arab Emirates)", "value": "ar-AE"},
//   {"text": "العربية (اليمن) | Arabic (Yemen)", "value": "ar-YE"},
//   {"text": "Asturian (España) | Asturian (Spain)", "value": "ast-ES"},
//   {"text": "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)", "value": "az-AZ"},
//   {"text": "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)", "value": "bn-BD"},
//   {"text": "বাংলা (ভারত) | Bengali (India)", "value": "bn-IN"},
//   {"text": "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)", "value": "bs-BA"},
//   {"text": "Български (България) | Bulgarian (Bulgaria)", "value": "bg-BG"},
//   {"text": "Català (Espanya) | Catalan (Spain)", "value": "ca-ES"},
//   {"text": "Cebuano (Pilipinas) | Cebuano (Philippines)", "value": "ceb-PH"},
//   {"text": "中文、普通话（简体中文）| Chinese, Mandarin (Simplified China)", "value": "zh"},
//   {"text": "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)", "value": "zh-TW"},
//   {"text": "中文、粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)", "value": "yue-Hant-HK"},
//   {"text": "Hrvatski (Hrvatska) | Croatian (Croatia)", "value": "hr-HR"},
//   {"text": "Čeština (Česká republika) | Czech (Czech Republic)", "value": "cs-CZ"},
//   {"text": "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)", "value": "cy-GB"},
//   {"text": "Dansk (Danmark) | Danish (Denmark)", "value": "da-DK"},
//   {"text": "Nederlands (België) | Dutch (Belgium)", "value": "nl-BE"},
//   {"text": "Nederlands (Nederland) | Dutch (Netherlands)", "value": "nl-NL"},
//   {"text": "English (Australia)", "value": "en-AU"},
//   {"text": "English (Canada)", "value": "en-CA"},
//   {"text": "English (Ghana)", "value": "en-GH"},
//   {"text": "English (Hong Kong)", "value": "en-HK"},
//   {"text": "English (India)", "value": "en-IN"},
//   {"text": "English (Ireland)", "value": "en-IE"},
//   {"text": "English (Kenya)", "value": "en-KE"},
//   {"text": "English (New Zealand)", "value": "en-NZ"},
//   {"text": "English (Nigeria)", "value": "en-NG"},
//   {"text": "English (Pakistan)", "value": "en-PK"},
//   {"text": "English (Philippines)", "value": "en-PH"},
//   {"text": "English (Singapore)", "value": "en-SG"},
//   {"text": "English (South Africa)", "value": "en-ZA"},
//   {"text": "English (Tanzania)", "value": "en-TZ"},
//   {"text": "English (United Kingdom)", "value": "en-GB"},
//   {"text": "English (United States)", "value": "en-US"},
//   {"text": "Español (Argentina) | Spanish (Argentina)", "value": "es-AR"},
//   {"text": "Español (Bolivia) | Spanish (Bolivia)", "value": "es-BO"},
//   {"text": "Español (Chile) | Spanish (Chile)", "value": "es-CL"},
//   {"text": "Español (Colombia) | Spanish (Colombia)", "value": "es-CO"},
//   {"text": "Español (Costa Rica) | Spanish (Costa Rica)", "value": "es-CR"},
//   {"text": "Español (Cuba) | Spanish (Cuba)", "value": "es-CU"},
//   {"text": "Español (Ecuador) | Spanish (Ecuador)", "value": "es-EC"},
//   {"text": "Español (El Salvador) | Spanish (El Salvador)", "value": "es-SV"},
//   {"text": "Español (España) | Spanish (Spain)", "value": "es-ES"},
//   {"text": "Español (Estados Unidos) | Spanish (United States)", "value": "es-US"},
//   {"text": "Español (Guatemala) | Spanish (Guatemala)", "value": "es-GT"},
//   {"text": "Español (Honduras) | Spanish (Honduras)", "value": "es-HN"},
//   {"text": "Español (México) | Spanish (Mexico)", "value": "es-MX"},
//   {"text": "Español (Nicaragua) | Spanish (Nicaragua)", "value": "es-NI"},
//   {"text": "Español (Panamá) | Spanish (Panama)", "value": "es-PA"},
//   {"text": "Español (Paraguay) | Spanish (Paraguay)", "value": "es-PY"},
//   {"text": "Español (Perú) | Spanish (Peru)", "value": "es-PE"},
//   {"text": "Español (Puerto Rico) | Spanish (Puerto Rico)", "value": "es-PR"},
//   {"text": "Español (República Dominicana) | Spanish (Dominican Republic)", "value": "es-DO"},
//   {"text": "Español (Uruguay) | Spanish (Uruguay)", "value": "es-UY"},
//   {"text": "Español (Venezuela) | Spanish (Venezuela)", "value": "es-VE"},
//   {"text": "Euskara (Espainia) | Basque (Spain)", "value": "eu-ES"},
//   {"text": "فارسی (ایران) | Persian (Iran)", "value": "fa-IR"},
//   {"text": "Filipino (Pilipinas) | Filipino (Philippines)", "value": "fil-PH"},
//   {"text": "Français (Belgique) | French (Belgium)", "value": "fr-BE"},
//   {"text": "Français (Canada) | French (Canada)", "value": "fr-CA"},
//   {"text": "Français (France)", "value": "fr-FR"},
//   {"text": "Français (Suisse) | French (Switzerland)", "value": "fr-CH"},
//   {"text": "Galego (España) | Galician (Spain)", "value": "gl-ES"},
//   {"text": "ქართული (საქართველო) | Georgian (Georgia)", "value": "ka-GE"},
//   {"text": "Deutsch (Deutschland) | German (Germany)", "value": "de-DE"},
//   {"text": "Deutsch (Österreich) | German (Austria)", "value": "de-AT"},
//   {"text": "Deutsch (Schweiz) | German (Switzerland)", "value": "de-CH"},
//   {"text": "Ελληνικά (Ελλάδα) | Greek (Greece)", "value": "el-GR"},
//   {"text": "ગુજરાતી (ભારત) | Gujarati (India)", "value": "gu-IN"},
//   {"text": "Hausa (Najeriya) | Hausa (Nigeria)", "value": "ha-NG"},
//   {"text": "עברית (ישראל) | Hebrew (Israel)", "value": "iw-IL"},
//   {"text": "हिन्दी (भारत) | Hindi (India)", "value": "hi-IN"},
//   {"text": "Hmong Daw (Pob Zeb) | Hmong Daw (Pob Zeb)", "value": "mww"},
//   {"text": "Magyar (Magyarország) | Hungarian (Hungary)", "value": "hu-HU"},
//   {"text": "Íslenska (Ísland) | Icelandic (Iceland)", "value": "is-IS"},
//   {"text": "Igbo (Nigeria) | Igbo (Nigeria)", "value": "ig-NG"},
//   {"text": "Bahasa Indonesia (Indonesia) | Indonesian (Indonesia)", "value": "id-ID"},
//   {"text": "Gaeilge (Éire) | Irish (Ireland)", "value": "ga-IE"},
//   {"text": "Italiano (Italia)", "value": "it-IT"},
//   {"text": "Italiano (Svizzera) | Italian (Switzerland)", "value": "it-CH"},
//   {"text": "日本語（日本）| Japanese (Japan)", "value": "ja-JP"},
//   {"text": "Javanese (Indonesia)", "value": "jv-ID"},
//   {"text": "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)", "value": "kn-IN"},
//   {"text": "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)", "value": "km-KH"},
//   {"text": "한국어 (대한민국) | Korean (South Korea)", "value": "ko-KR"},
//   {"text": "Кыргызча (Кыргызстан) | Kyrgyz (Kyrgyzstan)", "value": "ky-KG"},
//   {"text": "ລາວ (ສ.ປ.ປ.ລາວ) | Lao (Laos)", "value": "lo-LA"},
//   {"text": "Latviešu (Latvija) | Latvian (Latvia)", "value": "lv-LV"},
//   {"text": "Lietuvių (Lietuva) | Lithuanian (Lithuania)", "value": "lt-LT"},
//   {"text": "македонски (Македонија) | Macedonian (Macedonia)", "value": "mk-MK"},
//   {"text": "Bahasa Melayu (Malaysia) | Malay (Malaysia)", "value": "ms-MY"},
//   {"text": "Malti (Malta) | Maltese (Malta)", "value": "mt-MT"},
//   {"text": "Монгол (Монгол) | Mongolian (Mongolia)", "value": "mn-MN"},
//   {"text": "Burmese (Myanmar)", "value": "my-MM"},
//   {"text": "नेपाली (नेपाल) | Nepali (Nepal)", "value": "ne-NP"},
//   {"text": "Norsk bokmål (Norge) | Norwegian Bokmål (Norway)", "value": "nb-NO"},
//   {"text": "پښتو (افغانستان) | Pashto (Afghanistan)", "value": "ps-AF"},
//   {"text": "Polski (Polska) | Polish (Poland)", "value": "pl-PL"},
//   {"text": "Português (Brasil) | Portuguese (Brazil)", "value": "pt-BR"},
//   {"text": "Português (Portugal)", "value": "pt-PT"},
//   {"text": "Română (România) | Romanian (Romania)", "value": "ro-RO"},
//   {"text": "Русский (Россия) | Russian (Russia)", "value": "ru-RU"},
//   {"text": "Српски (Србија) | Serbian (Serbia)", "value": "sr-RS"},
//   {"text": "Slovenčina (Slovensko) | Slovak (Slovakia)", "value": "sk-SK"},
//   {"text": "Slovenščina (Slovenija) | Slovenian (Slovenia)", "value": "sl-SI"},
//   {"text": "Kiswahili (Tanzania) | Swahili (Tanzania)", "value": "sw-TZ"},
//   {"text": "Kiswahili (Kenya) | Swahili (Kenya)", "value": "sw-KE"},
//   {"text": "Svenska (Sverige) | Swedish (Sweden)", "value": "sv-SE"},
//   {"text": "Тоҷикӣ (Тоҷикистон) | Tajik (Tajikistan)", "value": "tg-TJ"},
//   {"text": "தமிழ் (இந்தியா) | Tamil (India)", "value": "ta-IN"},
//   {"text": "தமிழ் (சிங்கப்பூர்) | Tamil (Singapore)", "value": "ta-SG"},
//   {"text": "தமிழ் (இலங்கை) | Tamil (Sri Lanka)", "value": "ta-LK"},
//   {"text": "తెలుగు (భారత దేశం) | Telugu (India)", "value": "te-IN"},
//   {"text": "ภาษาไทย (ไทย) | Thai (Thailand)", "value": "th-TH"},
//   {"text": "Türkçe (Türkiye) | Turkish (Turkey)", "value": "tr-TR"},
//   {"text": "Українська (Україна) | Ukrainian (Ukraine)", "value": "uk-UA"},
//   {"text": "اردو (پاکستان) | Urdu (Pakistan)", "value": "ur-PK"},
//   {"text": "O'zbekcha (Oʻzbekiston Respublikasi) | Uzbek (Uzbekistan)", "value": "uz-UZ"},
//   {"text": "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)", "value": "vi-VN"},
//   {"text": "IsiZulu (Ningizimu Afrika) | Zulu (South Africa)", "value": "zu-ZA"}
// ]