<template>
    <v-row justify="center" align="center" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9; direction: rtl;" :class="isMobile ? 'primary' : 'transparent'">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'ar-intro'})"></v-img>
                </v-col>
                <v-spacer/>
                <v-col cols="8" align="end" class="ma-0 pa-0">
                    <v-btn color="white" outlined class="mr-2 font-weight-normal" :to="{name: 'ar-login'}">تسجيل الدخول</v-btn><!-- Sign In (Login) -->
                    <v-btn color="white" outlined class="font-weight-normal" :to="{name: 'ar-sign-up'}">يسجل</v-btn><!-- Register (Arabic Sign Up) -->
                </v-col>
            </v-app-bar>

            <v-row justify="center" align="center" class="ma-0 pa-0">

                <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                    <v-card v-if="!resetPassword" elevation="8" rounded light style="position: relative;" class="white rounded-xl">

                        <v-overlay v-if="loading" absolute>
                            <v-progress-circular size="100" width="5" indeterminate color="primary"></v-progress-circular>
                        </v-overlay>

                        <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h4 text-md-h3 text-lg-h3 font-weight-light">تسجيل الدخول</span> <!-- Sign In (Login)-->
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form>
                                    <v-text-field v-model="userEmail" label="بريد إلكتروني" type="email" reverse outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" :rules="emailRules" @change="removeHintAndHelpIfNecessary()"></v-text-field><!-- Email -->
                                    <v-text-field v-model="userPassword" label="كلمة المرور" :type="showPassword ? 'text' : 'password'" reverse outlined required validate-on-blur prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" hide-details :rules="passwordRules" @change="removeHintAndHelpIfNecessary()" @click:append="showPassword = !showPassword" @keypress.enter="login()"></v-text-field><!-- Password -->
                                    <p class="text-end font-italic font-weight-light text-decoration-underline primary--text my-1"><span style="cursor: pointer;" @click.stop="resetPassword = true">نسيت كلمة السر؟</span></p> <!-- Forgot your password? -->

                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mt-2" :class="(!resendVerification && !needHelp) ? 'mb-2' : 'mb-0'">*{{hintText}}*</p>
                                    <p v-if="resendVerification" class="darken-1 text-center font-italic font-weight-light mb-2">لا يمكن العثور عليه؟ <span class="primary--text text-decoration-underline" style="cursor: pointer;" @click.stop="resendVerificationEmail">إعادة ارسال بريد التحقق.</span></p> <!-- Can't find it? Resend Verification Email. -->
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> <!-- Need Help? Contact Us-->
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-card-actions class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-btn rounded large color="primary" @click.stop="login" class="">تسجيل الدخول</v-btn> <!-- sign in (Log In) -->
                                    <v-row align="center" no-gutters class="my-0 mx-10">
                                        <v-divider class="primary"/>
                                                <span class="pa-1 mx-2 mt-2 rounded-xl">أو</span> <!-- OR -->
                                            <v-divider class="primary"/>
                                    </v-row>
                                    <v-btn icon class="mr-1" @click.stop="signInWithGoogle()">
                                        <img src="../../../assets/google-icon.svg" alt="google-logo" width="35">
                                    </v-btn>
                                    <!-- <v-btn icon class="ml-2" @click="signInWithFacebook()">
                                        <v-icon color="blue darken-2" size="45">mdi-facebook</v-icon>
                                    </v-btn> -->
                                </v-col>

                                <v-layout column align-center justify-center class="mt-1">
                                    <span class="text-subtitle-1 text-lg-h6 font-weight-light">ليس لديك حساب؟
                                        <router-link to="/ar-sign-up" style="color:primary;">يسجل</router-link>
                                    </span> <!-- Don't Have an Account? Register (Sign Up) -->
                                </v-layout>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else elevation="8" rounded light style="position: relative;" class="white rounded-xl">
                        <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">إعادة تعيين كلمة المرور؟</span> <!-- Password Reset -->
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form v-model="formIsValid" @submit.prevent="sendPasswordReset">
                                    <v-text-field v-model="userEmail" label="بريد إلكتروني" type="Email" reverse outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" :rules="emailRules"></v-text-field><!-- Email -->
                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mt-2" :class="(!resendVerification && !needHelp) ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> <!-- Need Help? Contact Us-->
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-card-actions class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-btn rounded large color="primary" @click.stop="sendPasswordReset">إعادة تعيين كلمة المرور</v-btn> <!-- password reset -->
                                </v-col>

                                <v-col cols="10" align="end" class="ma-0 pa-0">
                                    <p style="cursor: pointer;" class="primary--text text-subtitle-1 text-lg-h6 font-weight-light" @click.stop="resetLoginState">{{'< عودة'}}</p> <!-- < Back -->
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script src="https://js.stripe.com/v3/" defer></script>
<script>
import firebase from "../../../../firebaseConfig.js";
// import fb from 'firebase/compat/app'
export default {
    name: "login",
    metaInfo: {
        // title: 'Login - tawqin'
        title: 'تسجيل الدخول - tawqin' // sign in (Login)
    },
    props: ["alertEmailSent"], // FIND OUT WHERE IS PROP IS COMING FROM!!!
    components: {},
    data() {
        return {
            loading: false,
            formIsValid: false,
            userEmail: "",
            emailRules: [
                (v) => !!v || "البريد الإلكتروني (مطلوب", // Email is required
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "يجب أن يكون البريد الإلكتروني صالحا", // E-mail must be valid
            ],
            userPassword: "",
            passwordRules: [
                (v) => v.length >= 8 || "يجب أن تتكون كلمة المرور من 8 أحرف أو أكثر", // Password Must Be 8 Characters Or More
            ],
            showPassword: false,
            resetPassword: false,
            resendVerification: false,
            hint: false,
            hintText: "",
            needHelp: false,
        };
    },
    methods: {
        login: async function() {
            try {
                const { user } = await firebase.auth.signInWithEmailAndPassword(this.userEmail, this.userPassword);
                const userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists && userInDatabase.data().emailVerified && user.emailVerified) { // user exists in database and has verified email, send to /home
                    this.$router.replace({ path: "/home" });
                } else { // email is NOT verified, notify user and allow for verification email to be sent
                    this.resendVerification = true;
                    this.hint = true;
                    this.hintText = "لم يتم التحقق من هذا البريد الإلكتروني."; // This email has not been verified.
                }
            } catch (error) {
                console.error(error);
                switch (error.code) {
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*البريد الإلكتروني الذي أدخلته غير صالح. حاول ثانية.*"; // The email you entered is invalid. Try again.
                        this.userEmail = "";
                        this.userPassword = "";
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم تعطيل المستخدم من استخدام موقع tawq.in. يرجى الاتصال بالدعم للحصول على المساعدة.*"; // The user has been disabled from using the tawq.in website. Please contact support for assistance.
                        break;
                    case "auth/user-not-found": // thrown if there is no user corresponding to the given email
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لا يمكن العثور على المستخدم الذي لديه هذا البريد الإلكتروني وكلمة المرور.*"; // The user with this email and password cannot be found.
                        break;
                    case "auth/wrong-password": // thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        // this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, Google or Facebook, please select the one you used below.*"; // uncomment when ready to use Facebook signup/login.
                        this.hintText = "*كلمة المرور التي أدخلتها خاطئة. إذا قمت بتسجيل الدخول مسبقًا باستخدام حساب جهة خارجية، Google، فيرجى تحديده أدناه.*"; // The password you entered is incorrect. If you're previously signed in with a third-party Google account, please select it below.
                        this.userPassword = "";
                        break;
                    default:
                        console.error("error logging in with email and password! DEFAULT: ", error.message);
                        this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء تسجيل الدخول. حاول مرة أخرى.`, type: 'snackbar' }); // An error occurred while logging in. Try again.
                        this.resetLoginState();
                        break;
                }
            }
        },
        signInWithGoogle: function() {
            this.loading = true;
            var provider = new firebase.authentication.GoogleAuthProvider();
            firebase.auth.useDeviceLanguage(); // will open the popup in the language the user has selected as their default browser language.
            this.signInWithPopup(provider);
        },
        signInWithFacebook: function() {
            this.loading = true;
            var provider = new firebase.authentication.FacebookAuthProvider();
            // provider.addScope('user_email');
            this.signInWithPopup(provider)
        },
        signInWithPopup: async function (provider) {
            try {
                const { user } = await firebase.auth.signInWithPopup(provider);
                const userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists) { // user has already signed up
                    if (!userInDatabase.data().emailVerified || !user.emailVerified) { // check if user has verified email, if not, set to verified
                        await firebase.db.collection("userData").doc(user.uid).update({ emailVerified: true }, { merge: true });
                    }
                    this.$router.replace({ path: "/home" }); // user is logged in and email is verified, set route to /home
                } else { // user does not exist, create them
                    const responseFromServerForVerifiedEmail = await this.$http.put(`emailVerified/${user.uid}`);
                    const userData = await this.setUserData(user, responseFromServerForVerifiedEmail.data.emailVerified);
                    await this.setFirebaseUserData(user.uid, userData);
                    this.loading = false;
                    this.$router.replace({ path: "/home", query: { newUser: true} });
                }
            } catch (error) {
                console.error(error);
                this.loading = false;
                switch (error.code) {
                    case "auth/account-exists-with-different-credential": // thrown when a user tries to sign in with Facebook or Google when they have already signed in with one or the other.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم بالفعل إنشاء حساب بهذا البريد الإلكتروني. إذا قمت بالفعل بإنشاء حساب مع Google، فيرجى تحديده أدناه. بخلاف ذلك، حاول تسجيل الدخول باستخدام بريدك الإلكتروني وكلمة المرور. إذا كنت تشعر أن هذا خطأ، يرجى الاتصال بنا أدناه.*"; // An account has already been created with this email. If you have already created an account with Google, please select it below. Otherwise, try logging in using your email and password. If you feel this is an error, please contact us below.
                        break;
                    case "auth/cancelled-popup-request": // thrown if multiple popup operations are triggered, only one allowed at a time. All fail if thrown.
                        this.loading = true;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد قمت بفتح عدد كبير جدًا من النوافذ المنبثقة. يرجى الإغلاق والمحاولة مرة أخرى.*"; // You have opened too many pop-up windows. Please close and try again.
                        break;
                    case "auth/popup-blocked": // thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*تم حظر النافذة المنبثقة بواسطة المتصفح. يرجى السماح للنوافذ المنبثقة بالتسجيل في Google.*"; // The pop-up has been blocked by your browser. Please allow pop-ups to register with Google.
                        break;
                    case "auth/popup-closed-by-user": // thrown if the popup window is closed by the user without completing the sign in to the provider.
                        this.resetLoginState();
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*لقد تم تعطيل المستخدم من استخدام موقع tawq.in. يرجى الاتصال بالدعم للحصول على المساعدة.*"; // The user has been disabled from using the tawq.in website. Please contact support for assistance.
                        // this.helpMessage = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
                        break;
                    default:
                        this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء تسجيل الدخول. حاول مرة أخرى.`, type: 'snackbar' }); // An error occurred while logging in. Try again.
                        this.resetLoginState();
                        break;
                }
            }
        },
        setUserData: async function (user, emailVerified) {
            const userData = {
                userId: user.uid,
                fullname: user.displayName,
                email: user.email,
                username: user.displayName,
                profilePicture: user.photoURL,
                profanityFilter: false,
                darkTheme: true,
                emailVerified: emailVerified,
                pushToTawq: true,
                postsCreated: 0,
                playbackStyle: false, // default to pauseplay
                captionAudio: true,
                dualDevice: false,
                signUpMethod: user.providerData[0].providerId,
                signUpDate: new Date(),
            }
            return userData;
        },
        setFirebaseUserData: function (docId, userData) {
            return new Promise((resolve, reject) => {
                firebase.db.collection("userData").doc(docId).set(userData).then(() => {
                    resolve(true);
                }).catch((error) => {
                    console.error("error creating firebase userData: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء تسجيل الدخول. حاول مرة أخرى.`, type: 'snackbar' }); // An error occurred while logging in. Try again.
                    this.resetLoginState(); // reset the login state
                    return reject(false);
                });
            });
        },
        sendPasswordReset: async function() {
            if (this.userEmail == "" || !this.formIsValid) return;
            let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
            firebase.auth.languageCode = userLang;
            var actionCodeSettings = {
                // url: 'https://tawq.in/login',
                url: process.env.NODE_ENV === 'production' ? 'https://tawq.in/login' : 'http://localhost:8080/login',
                handleCodeInApp: true,
            }

            try {
                await firebase.auth.sendPasswordResetEmail(this.userEmail, actionCodeSettings);
                this.$store.commit('alertUser', { show: true, text: `تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور.`, type: 'ar-snackbar' }); // The password reset email has been sent.
                this.resetLoginState();
            } catch (error) {
                console.error("ERROR: ", error);
                // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'snackbar' });
                switch (error.code) {
                    case "auth/user-not-found": // thrown if there is no user corresponding to the email address.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*البريد الإلكتروني الذي أدخلته غير صالح. حاول ثانية.*"; // The email you entered is invalid. Try again.
                        this.userEmail = "";
                        break;
                    default:
                        console.error("Error sending password reset: ", error);
                        this.$store.commit('alertUser', { show: true, text: `حدث خطأ أثناء تسجيل الدخول. حاول مرة أخرى.`, type: 'snackbar' }); // An error occurred while logging in. Try again.
                        this.resetLoginState();
                        break;
                }
            }
        },
        resendVerificationEmail: function() {
            const user = firebase.auth.currentUser;
            if (user) {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
                }
                user.sendEmailVerification(actionCodeSettings).then(() => {
                    // this.$store.commit('alertUser', { show: true, text: `Verification email has been sent.`, type: 'snackbar' });
                    this.$store.commit('alertUser', { show: true, text: `تم إرسال البريد الإلكتروني للتحقق.`, type: 'ar-snackbar' }); // Verification email has been sent.
                    this.userEmail = "";
                    this.userPassword = "";
                    this.hint = false;
                    this.hintText = "";
                    this.resendVerification = false;
                }).catch((err) => {
                    // this.$store.commit('alertUser', { show: true, text: `Something went wrong sending the verification email. Please try again.`, type: 'snackbar' });
                    this.$store.commit('alertUser', { show: true, text: `لم نتمكن من التحقق من هذا البريد الإلكتروني. حاول ثانية.`, type: 'ar-snackbar' }); // We were unable to verify this email. Try again.
                });
            } else {
                console.error("No user! Refresh the page and try again.");
                this.$store.commit('alertUser', { show: true, text: `هناك شيء غير صحيح. قم بتحديث الصفحة وحاول مرة أخرى.`, type: 'ar-snackbar' }); // Something is not right. Refresh the page and try again.
            }
        },
        emailKeep: async function(){
            var domain = this.userEmail.split('@')[1];
            const subject = "Login Help";
            const message = `Hello tawq.in team, ${'%0D%0A'} I am having trouble accessing my account for tawq.in, please help me! ${'%0D%0A'} Thanks!`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com':
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                default:
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
            } // end of email/gmail switch
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
        },
        removeHintAndHelpIfNecessary: function () {
            if (this.hint || this.needHelp) {
                this.needHelp = false;
                this.hint = false;
                this.hintText = "";
            }
        },
        resetLoginState: function () {
            if (this.resetPassword) this.resetPassword = false;
            this.userEmail = "";
            this.userPassword = "";
            this.loading = false;
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: function() {
        if (this.alertEmailSent) this.$store.commit('alertUser', { show: true, text: `تم إرسال بريد إلكتروني للتحقق. يرجى التحقق من بريدك الإلكتروني للبدء.`, type: 'ar-snackbar' }); // A verification email has been sent. Please check your email to get started.
    },
};
</script>

<style>
</style>