<template>
    <v-row justify="center" align="center" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9;" class="transparent">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'intro'})"></v-img>
                </v-col>
            </v-app-bar>

            <v-row justify="center" align="center" class="ma-0 pa-0">
                <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                    <v-card v-if="mode == 'resetPassword'" light elevation="8" class="white rounded-xl pb-4">
                        <v-card-title v-if="!showHintOnly" style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <!-- <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">Account Recovery</span> -->
                                    <!-- <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">Reset Password</span> -->
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">New Password</span>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row v-if="!showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form v-if="passwordForm" @submit.prevent="setNewPassword()">
                                    <v-text-field v-model="newPassword" label="Password" :type="showPassword ? 'text' : 'password'" placeholder="Enter new password" required outlined prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" :rules="newPasswordRules" @click:append="showPassword = !showPassword"></v-text-field>
                                    <v-text-field v-model="confirmNewPassword" label="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'" placeholder="Confirm password" required outlined prepend-inner-icon="mdi-lock" :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" :rules="confirmPasswordRules" @click:append="showConfirmPassword = !showConfirmPassword" @keypress.enter="setNewPassword()"></v-text-field>
                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light" :class="needHelp ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-0">Need Help? <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep">Contact Us</span></p>
                                </v-form>
                                <v-form v-else @submit.prevent="sendPasswordReset()">
                                    <v-text-field v-model="userEmail" label="Email" type="email" required outlined prepend-inner-icon="mdi-at" color="primary" :rules="emailRules"></v-text-field>
                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mb-0">{{hintText}}</p>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>

                        <v-card-actions v-if="!showHintOnly" class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-btn v-if="passwordForm" rounded large depressed color="primary" class="my-5" @click="setNewPassword()">Reset</v-btn>
                                    <v-btn v-else rounded large depressed color="primary" class="my-5" @click="sendPasswordReset()">Resend Link</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else-if="mode == 'verifyEmail'" light elevation="8" class="white rounded-xl pb-4">
                        <v-card-title v-if="!showHintOnly" style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <!-- <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">Account Recovery</span> -->
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">Verifying Email...</span>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row v-if="!showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light" :class="needHelp ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-0">Need Help? <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep">Contact Us</span></p>
                                <v-btn v-if="showRetrySendVerificationLink" rounded large depressed color="primary" class="my-5" @click="resendEmailVerification()">Resend Link</v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>

                        <v-card-actions v-if="!showHintOnly" class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-progress-circular v-if="!hint" :indeterminate="true" size="100" width="5" color="primary"></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else light elevation="8" class="white rounded-xl pb-4">
                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "manage-account",
    metaInfo: {
        title: 'Account Recovery - tawqin'
    },
    props: ["code", "mode", "continueURL"],
    data() {
        return {
            userEmail: "",
            emailRules: [ (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid", ],
            passwordForm: true,
            newPassword: "",
            newPasswordRules: [ (v) => v.length >= 8 || "Password Must Be 8 Characters Or More", ],
            showPassword: false,
            confirmNewPassword: "",
            confirmPasswordRules: [ (v) => v == this.newPassword || "Passwords Do Not Match." ],
            showConfirmPassword: false,
            showRetrySendVerificationLink: false,
            showHintOnly: false,
            hint: false,
            hintText: "",
            needHelp: false,
        };
    },
    methods: {
        setNewPassword: function() {
            if (this.newPassword == this.confirmNewPassword) { // double check passwords match
                firebase.auth.confirmPasswordReset(this.code, this.newPassword).then(() => {
                    // password has been reset successfully, go to login page
                    this.$store.commit('alertUser', { show: true, text: `Your password has been reset.`, type: 'snackbar' });
                    this.$router.replace( {path: '/login'} );
                }).catch((error) => {
                    this.hint = true;
                    this.hintText = `${this.setHintText(error.code)}`;
                });
            }
        },
        sendPasswordReset: function() {
            var actionCodeSettings = {
                url: 'https://tawq.in/login',
                handleCodeInApp: true,
            }
            firebase.auth.sendPasswordResetEmail(this.userEmail, actionCodeSettings).then(() => {
                this.showHintOnly = true;
                this.hint = true;
                this.hintText = "Password reset link sent.";
            }).catch((error)=> {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
            })
        },
        verifyEmail: function() {
            firebase.auth.checkActionCode( this.code ).then(() => {
                // is the correct code, switch the user to verified.
                firebase.auth.applyActionCode( this.code ).then( async () => {
                    /*  the verification hasn't set 'currentUser.emailVerified' true for a couple seconds (minimum)
                        this function has completed. So the router ends up sending the user to the login page instead of the home
                        page. therefore we will wait until it has been set, then re route them
                    */
                   window.location.href = this.continueURL;
                }).catch((error) => {
                    this.hint = true;
                    this.hintText = `${this.setHintText(error.code)}`;
                });
            }).catch((error) => {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
            });
        },
        resendEmailVerification: function() {
            // var actionCodeSettings = {
            //     url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
            // }
            // firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(() => {
            //     this.showHintOnly = true;
            //     this.hintText = `New email verification link sent! You may now close this page.`;
            //     this.showRetrySendVerificationLink = false;
            //     // firebase.auth.signOut();
            // }).catch((err) => {
            //     this.hint = true;
            //     this.hintText = `${this.setHintText(err.code)}`;
            // })
            const user = firebase.auth.currentUser;
            if (user) {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
                }
                user.sendEmailVerification(actionCodeSettings).then(() => {
                    this.showHintOnly = true;
                    this.hintText = `New email verification link sent! You may now close this page.`;
                    this.showRetrySendVerificationLink = false;
                }).catch((error) => {
                    // this.showHintOnly = true;
                    // this.$store.commit('alertUser', { show: true, text: `Something went wrong sending the verification email. Please try again.`, type: 'snackbar' });
                    this.hint = true;
                    this.hintText = `${this.setHintText(error.code)}`;
                });
            } else {
                // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'snackbar' });
                // console.error("No user! Refresh the page and try again.");

                // this.showHintOnly = true;
                // this.hintText = `Something went wrong. No User, Refresh the page and try again.`;
                // this.showRetrySendVerificationLink = false;

                this.hint = true;
                this.hintText = "No User can be found. Refresh the page and try again."
                this.needHelp = true;
                this.showRetrySendVerificationLink = false;
            }
        },
        emailKeep: async function() {
            var domain = this.userEmail.split('@')[1];
            const subject = "Manage Account";
            const message = `Hello tawq.in team, ${'%0D%0A'} I am having trouble accessing my account for tawq.in, please help me! ${'%0D%0A'} Thanks!`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com': {
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                }

                default: {
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                }
            } // end of email/gmail switch
            if (this.mode == "verifyEmail") {
                this.hintText = "You may now close this page and try again.";
                this.needHelp = false;
            } else { // this.mode = "resetPassword"
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
        },
        setHintText: function(error) {
            if ( error == 'auth/expired-action-code' || error == 'auth/invalid-action-code' ){
                this.passwordForm = false;
                this.showRetrySendVerificationLink = true;
                return `This link is invalid or expired. Need a new one?`;
            } else if (error == 'auth/user-disabled') {
                this.needHelp = true;
                return "The user has been disabled from using tawq.in. Contact support for help.";
            } else if (error == 'auth/weak-password') {
                return "The password you entered is too weak. For security purposes, please try again";
                // return "The Password Is Considered A Weak Password. For Security Purposes, Please Use Another";
            } else if (error == 'auth/user-not-found') {
                this.passwordForm = false;
                return "The email address provided is not registered with tawq.in. Please try again.";
                // return "The Email Address Provided Has Not Registered An Account With Tawq.in. Please Select Create Account Below.";
            } else {
                this.passwordForm = false;
                this.showRetrySendVerificationLink = true;
                return `There has been an error! Get another email link and try again.`;
            }

        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: function() {
        if (this.mode == 'resetPassword') {
            firebase.auth.verifyPasswordResetCode(this.code).then(() => { // if confirmation code matches
                this.passwordForm = true;
            }).catch((error) => {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
                this.passwordForm = false;
            });
        } else if ( this.mode == 'verifyEmail') {
            this.verifyEmail();
        } else { // '/manage-account' only
            // this.mode = 'verifyEmail';
            this.showHintOnly = true;
            this.hint = true;
            this.hintText = "Something is not right. Please close the page and try again.";
        }
    },
}
</script>