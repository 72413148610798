<template>
    <v-row class="full-height full-width ma-0 mt-n3 mt-md-0 pa-0">
         <!-- :class="[$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4' ]" -->
        <v-col cols="12" class="bordered">

            <v-row justify="end" align="center" no-gutters class="mx-0 mt-4 px-5">
                <v-col cols="12" xl="7" lg="11" md="3" sm="4" xs="12" align="center">
                    <v-autocomplete v-if="filter.languageCodes.length > 1 && !audioLoading" v-model="filter.languageSelected" :items="filter.languageCodes" label="Filter by language" append-icon="mdi-filter-menu" hide-details class="children-cursor--pointer"/>
                    <v-skeleton-loader v-else type="text@2" class="px-2 pt-2"></v-skeleton-loader>
                    <!-- <v-autocomplete v-if="filter.languageCodes.length > 1" v-model="filter.languageSelected" :items="filter.languageCodes" label="Filter by language" append-icon="mdi-filter-menu" hide-details class="children-cursor--pointer"/> -->
                </v-col>
            </v-row>

            <v-row class="mx-0 mt-4 px-0 px-lg-2">
                <!-- XL, LG & XS SCREENS -->
                <v-row v-if="audioLoading" no-gutters class="hidden-md-only hidden-sm-only">
                    <v-col v-for="i in 5" :key="i" cols="12" class="my-0 pt-0 px-0 pb-5">
                        <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-col v-for="audio in languageFilteredAudio" :key="audio.audioDataId" cols="12" class="hidden-md-only hidden-sm-only my-0 pt-0 px-0 pb-5">
                    <!-- :isGroupAdmin="isGroupAdmin" -->
                    <AudioElement
                        v-if="audio.audioData.audio.length > 0"
                        :audioObject="audio"
                        :postAudioDataId="currentPost.postAudioDataId"
                        :postOwner="currentPost.userId"
                        :userData="userData"
                        :selectedAudioId="selectedAudioId"
                        :postType="currentPost.postType"
                        :postData="currentPost"
                        :videoLoading="videoLoading"
                        :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                        :anonymousMode="currentPost.anonymousMode || false"
                        :isCreating="isCreating"
                        @audioChanged="emitAudioChange"
                        @changeAudioElement="emitChangeAudioElement"
                    />
                </v-col>

                <!-- SM & MD SCREENS -->
                <v-col v-if="leftAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pl-0 py-0 justify-end">
                    <v-container fluid v-for="audio in leftAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                        <AudioElement
                            v-if="audio.audioData.audio.length > 0"
                            :key="audio.audioDataId"
                            :audioObject="audio"
                            :postAudioDataId="currentPost.postAudioDataId"
                            :postOwner="currentPost.userId"
                            :userData="userData"
                            :selectedAudioId="selectedAudioId"
                            :postType="currentPost.postType"
                            :postData="currentPost"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="currentPost.anonymousMode || false"
                            :isCreating="isCreating"
                            @audioChanged="emitAudioChange"
                            @changeAudioElement="emitChangeAudioElement"
                        />
                    </v-container>
                </v-col>

                <v-col v-if="rightAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pr-0 py-0 justify-end">
                    <v-container fluid v-for="audio in rightAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                        <AudioElement
                            v-if="audio.audioData.audio.length > 0"
                            :key="audio.audioDataId"
                            :audioObject="audio"
                            :postAudioDataId="currentPost.postAudioDataId"
                            :postOwner="currentPost.userId"
                            :userData="userData"
                            :selectedAudioId="selectedAudioId"
                            :postType="currentPost.postType"
                            :postData="currentPost"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="currentPost.anonymousMode || false"
                            :isCreating="isCreating"
                            @audioChanged="emitAudioChange"
                            @changeAudioElement="emitChangeAudioElement"
                        />
                    </v-container>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import AudioElement from "../AudioElement.vue";
// import firebase from "../../../firebaseConfig.js";
export default {
    name: 'ContributionCollection',
    props: ['audioData', 'filter', 'selectedAudioId', 'audioLoading', 'videoLoading', 'audioElementHasBegunPlaying', 'isCreating'],
    components: {
        AudioElement
    },
    data(){
        return {};
    },
    methods: {
        emitChangeAudioElement(data, id){
            this.$emit('changeAudioElement', data, id)
        },
        emitAudioChange(data, id){
            this.$emit('audioChanged', data, id);
        }
        
    },
    computed: {
        currentPost(){
            return this.$store.state.currentPost;
        },
        userData(){
            return this.$store.state.userData;
        },
        languageFilteredAudio(){
            if (this.filter.languageSelected === "All") {
                return this.audioData;
            } else {
                return this.audioData.filter(audio => audio.audioData.languageName === this.filter.languageSelected);
            }
        },
        leftAudioData(){
            if (this.filter.languageSelected === "All") {
                return this.audioData.filter((num, index) => index%2 === 0);
            } else {
                return this.audioData.filter((audio,index) => audio.audioData.languageName === this.filter.languageSelected && index%2 === 0);
            }
        },
        rightAudioData(){
            if (this.filter.languageSelected === "All") {
                return this.audioData.filter((num, index) => index%2 != 0);
            } else {
                return this.audioData.filter((audio,index) => audio.audioData.languageName === this.filter.languageSelected && index%2 != 0);
            }
        },
    },
    // created: function () {
    //     // console.log("this.videoLoading: ", this.videoLoading);
    //     // console.log("this.audioLoading: ", this.audioLoading);
    //     // console.log("audioElementHasBegunPlaying: ", this.audioElementHasBegunPlaying);
    //     // console.log("postData: ", this.postData);
    //     // console.log("audioData: ", this.audioData);
    // },
}
</script>
<style>
.bordered {
    background-color: var(--v-background-base);
    /* border: 5px solid var(--v-background-base); */
    border-radius: 25px;
}
</style>