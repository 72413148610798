<template>
    <!-- Put the following line of code into either/both dialog(s) below so the user can click outside of the dialog to close it instead if clicking an 'X' or Cancel button -->
    <!-- @click:outside="clickOutsideDialog" -->
    <v-dialog v-if="!processingVideo" v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
        <v-card class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center align-center">
                <span class="text-wrap text-h6 font-weight-bold text-center">This Video is <span class="text-decoration-underline">NOT</span> Compatible with the Web Browser!</span>
                <div class="ml-5 mr-5 text-center">
                    <p class="font-weight-bold text-subtitle-2 mt-3">
                        - In order for you to use this video it must be uploaded, processed and re-encoded in the correct format
                        for the browser. This can take some time, depending on the size of the video file you are trying to upload.
                    </p>
                    <p class="font-weight-bold text-subtitle-2 mt-n1">
                        - Once the video is uploaded, processed and re-encoded you will <span class="text-decoration-underline">NOT</span> be able to select a different video and will have to
                        delete the post in order to select a new video.
                    </p>
                </div>
                <p class="font-weight-medium text-body-2 mt-3 text-center">Click <span class="text-decoration-underline">Cancel</span> to select a different video.</p>
                <p class="font-weight-medium text-body-2 mt-0 text-center">Click <span class="text-decoration-underline">Upload & Process</span> to begin uploading, processing and re-encoding the video.</p>
            </v-card-title>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(false)">Cancel</v-btn>
                    <!-- <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(false)">Close</v-btn> -->
                    <v-btn color="primary" text @click="processVideo">Upload & Process</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-else v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
        <v-card v-if="uploadingVideo && !videoFileUploadComplete" class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <v-col cols="12">

                    <p class="text-center text-wrap text-h5 font-weight-bold"> We are Uploading your Video!</p>

                    <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- While the video is being uploaded <span class="font-weight-bold">Do Not</span> refresh the page!</p>
                    <!-- <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- You can end the upload process by clicking <span class="font-weight-bold">Cancel</span>.</p> -->
                    <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- You may exit out of the page by clicking <span class="font-weight-bold">Save Draft & Exit</span>. 
                        This will still allow your video to upload and can be found in <span class="font-weight-bold">Drafts</span> in the navigation drawer, when completed.
                    </p>

                    <v-row justify="center" class="mx-0 mt-10">
                        <v-progress-circular
                            :rotate="-90"
                            :value="resumableProgress"
                            size="75"
                            color="primary"
                        >{{ resumableProgress }}</v-progress-circular>
                    </v-row>

                </v-col>

            </v-card-title>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <!-- <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(true)">Cancel</v-btn> -->
                    <v-btn color="primary" text @click="earlyUploadSaveAsDraft">Save Draft & Exit</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-else-if="!uploadingVideo && videoFileUploadComplete" class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <v-col cols="12">

                    <p class="text-center text-wrap text-h5 font-weight-bold"> We are Processing your Video!</p>

                    <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- While the video is being processed <span class="font-weight-bold">Do Not</span> refresh the page!</p>
                    <!-- <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- You can end the upload process by clicking <span class="font-weight-bold">Cancel</span>.</p> -->
                    <p class="text-center text-wrap font-weight-light text-body-2 ma-2">- You may exit out of the page by clicking <span class="font-weight-bold">Save Draft & Exit</span>. 
                        This will still allow your video to upload and can be found in <span class="font-weight-bold">Drafts</span> in the navigation drawer, when completed.
                    </p>

                    <v-row justify="center" class="mx-0 mt-10">
                        <v-progress-circular size="75" color="primary" indeterminate></v-progress-circular>
                    </v-row>

                </v-col>

            </v-card-title>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <!-- Change the name of this button to reflect that it will exit to home screen but -->
                    <!-- still upload video and save to draftPost when done -->
                    <!-- <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(true)">Cancel</v-btn> -->
                    <v-btn color="primary" text @click="earlyUploadSaveAsDraft">Save Draft & Exit</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <v-col cols="12">
                    <h3 class="text-center text-wrap">
                        Something went wrong. Please try again!
                    </h3>
                    <!-- <v-row justify="center" class="mx-0 mt-10">
                        <v-progress-circular size="75" color="primary" indeterminate></v-progress-circular>
                    </v-row> -->
                </v-col>
            </v-card-title>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(null)">Close & Exit</v-btn>
                    <!-- <v-btn color="primary" text @click="cancelVideoCodecProcessingDialog(false)">Cancel</v-btn> -->
                    <!-- <v-btn
                        color="primary"
                        text
                        @click="processVideo"
                    >Process</v-btn> -->
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "videoCodecProcessingDialog",
    props: ["dialog",],
    components: {},
    data() {
        return {}
    },
    methods:{
        clickOutsideDialog: function () {
            this.$emit("cancelVideoCodecProcessingDialog");
        },
        cancelVideoCodecProcessingDialog: function (processing) {
            // This case happens before the user has clicked 'Upload & Process'
            // if processing = false, then clear the video when clicking close/cancel
            // All the code to clear and reset is done in NewPost.vue

            // This case happens when the user clicks 'Cancel' while the video is being uploaded and processed
            // if processing = true, then the video is being processed so close the 
            // dialog and go back to the home screen. Make sure to delete what has been saved
            // to delete a resumable upload request we have to call the sessionURI with a DELETE
            // request. var res = await axios({url: uri, method: 'delete', data: slice, headers: headers});
            // with "Content-Length: 0", at least I think that should work.

            if (processing && !this.videoFileUploadComplete) {
                console.log("Resumable");
                this.$store.dispatch('cancelResumableUpload');
            } else if (processing && this.videoFileUploadComplete) {
                console.log("Transcoding");
                this.$store.dispatch('deleteTranscodingJob');
            }

            // This case happens when there has been an error uploading and processing the video. IN this case a few different things
            // could have happened. 1. The video failed to uploaded 2. The video failed to create a transcode job correctly
            // if processing = null, then delete what has been saved. Similar to the if processing = true;
            // TODO: check to see if we can just set processing = true and it will run the correct process!

            if (!processing && this.processingVideo && this.uploadingVideo && this.videoFileUploadComplete) {
                // Something went wrong deleting the unsable video file. We can either call a function to delete all
                // video files on server or we can recall the function to delete the unsable file.
                console.log("Unusable video file didn't delete. Continue?");
                // this.$store.dispatch('transcodingComplete');
            }
            this.$emit("cancelVideoCodecProcessingDialog", processing);
        },
        processVideo: function () {
            // console.log("Processing video");
            // console.log("createPost: ", this.createPost);
            this.$store.dispatch('earlyUploadForVideoCodec', { post: this.createPost, draftPost: this.isDraftPost ?  this.draftPost : null, isDraft: this.isDraftPost ? this.isDraftPost : false, deleteDraftPostThumbnail: this.deleteDraftPostThumbnail });
        },
        earlyUploadSaveAsDraft: function () {
            // console.log("EarlyUploadSaveAsDraft VideoCodecProcessingDialog");
            // Set saveAsDraft in earlyUpload in the Store. The last thing we will do in the store is check to see if this value
            // is set to true, if true then we will save as draft. else its set to false so we will just return the correct value
            // to use in the creation process.
            // We should never have any audioData or any metadata for the post because it will
            // only be uploading the video and transcoding it. And the user cannot click on anything but this dialog box.
            // because we are setting the following value to true the upload process will complete in the store, when it is done
            // uploading and transcoding we check for this value and call a different function/action in the store itself.
            // so there is no need to dispatch a function to the store from this component(dialog box)
            this.saveAsDraft = true;
            this.uploadingVideo = true;

            // Emit to the parent component (NewPost.vue) to set this.complete to true, this will make sure that the post info is not
            // deleted when NewPost.vue beforeDestroy lifecycle method gets called.
            // We will also route back to homefeed from the parent component.
            this.$emit("earlyUploadSaveAsDraft");
        },
    },
    computed: {
        createPost () {
            return this.$store.state.newPost.createPost;
        },
        processingVideo () {
            return this.$store.state.newPost.earlyUpload.processingVideo;
        },
        resumableProgress () {
            return this.$store.state.newPost.resumable_progress;
        },
        uploadingVideo: {
            get () {
                return this.$store.state.newPost.uploading_video;
            },
            set (value) {
                this.$store.commit('saveUploadingVideo', value);
            }
        },
        videoFileUploadComplete () {
            return this.$store.state.newPost.videoFileUploadComplete;
        },
        saveAsDraft: {
            get () {
                return this.$store.state.newPost.earlyUpload.saveAsDraft;
            },
            set(value){
                this.$store.commit('saveEarlyUploadSaveAsDraft', value);
            }
        },
    },
    created: function() {},
    mounted: function() {},
    beforeDestroy: function () {},
}
</script>

<style scoped>
</style>