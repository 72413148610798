// import axios from "axios";
import firebase from "../../firebaseConfig";
import fb from "firebase/compat/app";

const newContributionStore = {
    namespaced: true,
    state: () => ({
        newAudioStep: {
            CONTENT_METADATA: 1,
            CONTENT_CREATION: 2,
        },
        currentStep: 1,
        savingContribution: false,
        contributionMetadataStep: {
            complete: false,
        },
        contributionCreationStep: {
            complete: false,
            videoDuration: 0,
        },
        newAudioData: { // the metadata that will be saved to audioSegments collection
            audioDataId: "",
            postId: "",
            title: "",
            languageSelected: {
                text: "English (United States)",
                value: "en-US",
                model: 0,
                location: 0,
                recognizerEnum: 0,
                recognizer: "gl-en-us-en-in-en-gb",
            },
            noCaptionLanguageSelected: "",
        }, // audio metadata
        tawqData: {}, // captions, audio, and video segs
        creationMode: true, // this will be set to false when listening to other captions! set to true by default and whenever the user clicks the record button
    }),
    mutations: {
        setSavingContribution(state, payload){
            state.savingContribution = payload;
        },
        saveVideoDuration(state, payload){
            state.contributionCreationStep.videoDuration = payload;
        },
        saveNewContributionLanguageSelected(state, payload){
            state.newAudioData.languageSelected.text = payload.text;
            state.newAudioData.languageSelected.value = payload.value;
            state.newAudioData.languageSelected.model = payload.model;
            state.newAudioData.languageSelected.location = payload.location;
            state.newAudioData.languageSelected.recognizerEnum = payload.recognizerEnum;
            state.newAudioData.languageSelected.recognizer = payload.recognizer;
        },
        saveNewContributionNoLanguageSelected(state, payload){
            state.newAudioData.noCaptionLanguageSelected = payload;
        },
        saveNewContributionCurrentStep(state, payload){
            state.currentStep = payload;
        },
        saveContributionMetadataStep(state, payload){
            state.contributionMetadataStep.complete = payload.complete;
        },
        saveContributionCreationStep(state, payload){
            state.contributionCreationStep.complete = payload.complete;
        },
        saveNewAudioData(state, payload){
            // console.log("newContributionStore.saveNewAudio()", state, payload);
            state.newAudioData = Object.assign({}, state.newAudioData, payload );
        },
        saveTawqData(state, payload){
            console.log('newContributionStore.save tawq data() called ')
            state.tawqData = Object.assign({}, state.tawqData, payload );
        },
        deleteKeyFromTawqData(state, payload){
            // console.log("payload key: ", payload.key);
            delete state.tawqData[payload.key];
        },
        setCreationMode(state, payload){
            state.creationMode = payload;
        },
        resetStoreState(state){
            state.newAudioStep = {
                CONTENT_METADATA: 1,
                CONTENT_CREATION: 2,
            };
            state.currentStep =  1,
            state.savingContribution = false,
            state.contributionMetadataStep = {
                complete: false,
            };
            state.contributionCreationStep = {
                complete: false,
                videoDuration: 0,
            };
            state.newAudioData = { // the metadata that will be saved to audioSegments collection
                audioDataId: "",
                title: "",
                languageSelected: {
                    text: "English (United States)",
                    value: "en-US",
                    model: 0,
                    location: 0,
                    recognizerEnum: 0,
                    recognizer: "gl-en-us-en-in-en-gb",
                },
                noCaptionLanguageSelected: "",
            }; // audio metadata
            state.tawqData = {}; // captions, audio, and video segs
        }
    },
    actions: {
        async saveContribution ({ state, dispatch }, payload) {
            try {
                const data = await dispatch('bubbleSortByAudioStartTime');
                const audio = data.audio;
                const captions = data.captions;

                if (audio.length === 0) { // we require a user to create at least one segment to save, therefore this case should never happen.
                    console.log("There is no audio. We cannot save this contribution.");
                    return false;
                }

                const newAudioRef = firebase.db.collection("audioSegments").doc(state.newAudioData.audioDataId);
                await newAudioRef.set({
                    noCaptionLanguageSelected: state.newAudioData.noCaptionLanguageSelected,
                    languageName: state.newAudioData.languageSelected.text,
                    languageCode: state.newAudioData.languageSelected.value,
                    title: state.newAudioData.title,
                    userId: firebase.auth.currentUser.uid,
                    username: firebase.auth.currentUser.displayName,
                    userProfilePhoto: firebase.auth.currentUser.photoURL,
                    postId: state.newAudioData.postId,
                    audio: audio,
                    subtitles: captions,
                    date: new Date(),
                    analytics: payload.analytics,
                });

                let audioDataIdArray = [state.newAudioData.audioDataId];

                const postTypeCollection = payload.post.postType === 'public' ? "publicPosts" : "groupPosts";
                const newContributionRef = firebase.db.collection(postTypeCollection).doc(state.newAudioData.postId).collection("contributions").doc(firebase.auth.currentUser.uid);
                const doc = await newContributionRef.get();

                if (!doc.exists) {
                    await newContributionRef.set({ audioDataId: audioDataIdArray });
                } else {
                    await newContributionRef.update({ audioDataId: fb.firestore.FieldValue.arrayUnion(state.newAudioData.audioDataId) });
                }

                return true;
            } catch (error) {
                console.log("Error saving the contribution:", error);
                return false;
            }
        },
        bubbleSortByAudioStartTime ({state}) {
            return new Promise((resolve) => {
                const audioSegFeedback = {
                    username: null,
                    userId: null,
                    feedback: null,
                    date: null,
                    hasFeedback: false,
                };

                const data = Object.values(state.tawqData).map((x, index) => {
                    const audioObj = typeof x.audio !== 'undefined' ? x.audio : {
                        audioURL: null,
                        audioStart: null,
                        audioDur: null,
                        segment: index + 1,
                    };

                    const captionObj = {
                        subtitle: typeof x.caption !== 'undefined' ? x.caption : "",
                        audioSegId: index + 1,
                        audioSegFeedback: audioSegFeedback,
                    };

                    return { audio: audioObj, caption: captionObj };
                });

                data.sort((a, b) => a.audio.audioStart - b.audio.audioStart); // Sort the data array based on audioStart property

                const sortedAudio = data.map(obj => obj.audio);
                const sortedCaptions = data.map(obj => obj.caption);

                resolve({ audio: sortedAudio, captions: sortedCaptions });
            });
        },
        resetStoreState({commit}){
            /* this method will reset the data being stored for the new contribution */
            commit('resetStoreState');
        }
    }, // END actions
    getters: {
        getOverlappingSegsForAudioSeg: (state) => (audioSegId) => {
            let overlappingSegs = [];
            for(const key in state.tawqData){
                if(typeof state.tawqData[key].audio === 'undefined' || state.tawqData[key].audio === null){
                    return overlappingSegs;
                }
                const audio = state.tawqData[audioSegId].audio;
                if ( 
                    (audio.segment != state.tawqData[key].audio.segment) // if it isn't the same audioSeg that this caption card is using
                    &&
                    ( 
                        ( (state.tawqData[key].audio.audioStart < audio.audioEnd) && (state.tawqData[key].audio.audioStart > audio.audioStart) )
                        || 
                        ( (state.tawqData[key].audio.audioEnd > audio.audioStart) && (state.tawqData[key].audio.audioStart < audio.audioStart) )
                        ||
                        ( (state.tawqData[key].audio.audioStart >= audio.audioStart) && (state.tawqData[key].audio.audioEnd >= audio.audioEnd) && (state.tawqData[key].audio.audioStart < audio.audioEnd) ) 
                        ||
                        ( (state.tawqData[key].audio.audioStart == audio.audioStart) && (state.tawqData[key].audio.audioEnd <= audio.audioEnd) ) 
                    )
                ){
                    // if it overlaps, add it to the array
                    overlappingSegs.push( parseInt(key) );// this will add the INDEX number that the segment is overlapping
                }

            }
            return overlappingSegs;
        },
        getCaptionsForContribution: (state) => {
            if(Object.keys(state.tawqData).length > 0){
               let captions = [];
               for (const key in state.tawqData){
                   if(typeof state.tawqData[key].caption != 'undefined' && state.tawqData[key].caption != null){
                       captions.push( {
                           subtitle: state.tawqData[key].caption,
                           audioSegId: key,
                       });
                   }
                   else if (typeof state.tawqData[key].caption != 'undefined'){
                    captions.push( {
                        // subtitle: state.tawqData[key].caption,
                        audioSegId: key,
                    });
                   }
               } 
               return captions;
            }
            return [];
        },
        getAudioForContribution: (state) => {
            if(Object.keys(state.tawqData).length > 0){
               let audio = [];
               for (const key in state.tawqData){
                   if(typeof state.tawqData[key].audio != 'undefined' && state.tawqData[key].audio != null){
                       audio.push(
                            {
                                audioURL: state.tawqData[key].audio.audioURL,
                                audioStart: state.tawqData[key].audio.audioStart,
                                audioDur: state.tawqData[key].audio.audioDur,
                                segment: state.tawqData[key].audio.segment,
                            }
                        );
                   }
               } 
               return audio;
            }
            return [];
        }
    }
} // store

export default newContributionStore