<template>
    <v-row justify="center">
        <v-col cols="12" class="ma-0 pa-0">

            <div style="" :class="isMobile ? 'main-header-mobile': 'main-header'" class="ma-0 pa-0 d-flex flex-column">
                <v-row justify="center" align="center" class="ma-0 pa-0">
                    <v-divider class="primary"/>
                    <v-col cols="10" md="6" align="center" class="ma-0 pa-0">
                        <span class="text-h3 text-md-h1 font-weight-medium">معلومات عنا</span>
                    </v-col>
                    <v-divider class="primary"/>
                </v-row>

                <v-row justify="center" align="center" style="height: 50vh;" class="ma-0 pa-0 hidden-sm-and-down">
                    <v-col cols="6" align="center" class="ma-0 pa-5">
                        <!-- <span class="text-h4 text-md-h3 font-weight-normal">We are <span class="text-decoration-underline font-weight-normal">tawq.in</span></span><br/><br/> -->
                        <span class="text-h4 text-md-h3 font-weight-normal">في <span class="font-weight-normal primary--text">tawq.in</span> أنت جزء من العائلة!</span><br/><br/>
                         <!-- <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to their cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </p> -->
                         <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">نحن ملتزمون بالحفاظ على اللغات غير المكتوبة وتمكين المجتمعات من خلال الاستفادة من التكنولوجيا والإنترنت. تتيح منصتنا للمستخدمين مشاركة هذه اللغات وتوثيقها، والمساهمة في تراثهم الثقافي مع دعم المجتمعات النامية في التكيف مع التحديات الحديثة وتعزيز الحياة اليومية.</p>
                    </v-col>
                    <v-col cols="6" align="center" class="ma-0 pa-10 fill-height">
                        <v-carousel height="100%" hide-delimiter-background cycle interval="3500" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom">
                            <v-carousel-item
                                v-for="(photo, i) in sectionZeroCarouselPhotos"
                                :key="i"
                                :src="photo.photoURL"
                                contain
                                class="pa-2"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>

                <v-row justify="center" align="center" style="height: 60vh;" class="ma-0 pa-0 hidden-md-and-up">
                    <v-col cols="12" align="center" class="ma-0 px-0 pt-0 pb-0 fill-height">
                        <!-- <span class="text-h4 text-md-h3 font-weight-normal">At <span class="font-weight-normal primary--text">tawq.in</span> <span class="text-decoration-underline font-weight-black">YOU</span> are Family!</span><br/><br/> -->
                        <!-- <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to the cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </p> -->
                        <v-carousel height="100%" hide-delimiter-background cycle interval="3500" style="border: 2px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom">
                            <v-carousel-item
                                v-for="(photo, i) in sectionZeroCarouselPhotos"
                                :key="i"
                                :src="photo.photoURL"
                                contain
                                class="pa-2"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
            </div>

            <v-divider class="primary hidden-sm-and-down"/>

            <v-row justify="center" align="center" class="ma-0 pa-md-0 px-0 py-3">
                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <div class="hib-htb-light-up" @click.stop="scrollToContent('section-1')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn">
                            <v-icon size="100">mdi-crowd</v-icon>
                        </v-btn>
                        <div class="text-h4 text-center hib-htb-light-up" style="word-break: normal; cursor: pointer;">تعرف على فريقنا</div>
                    </div>
                </v-col>
                <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                    <div class="hib-htb" @click.stop="scrollToContent('section-1')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                            <v-icon size="50">mdi-crowd</v-icon>
                        </v-btn>
                        <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">فريقنا</div>
                    </div>
                </v-col>

                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <div class="hib-htb-light-up" @click.stop="scrollToContent('section-2')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn">
                            <v-icon size="100">mdi-book-open-page-variant</v-icon>
                        </v-btn>
                        <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;">تعلم قصتنا</div>
                    </div>
                </v-col>
                <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                    <div class="hib-htb" @click.stop="scrollToContent('section-2')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                            <v-icon size="50">mdi-book-open-page-variant</v-icon>
                        </v-btn>
                        <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">قصتنا</div>
                    </div>
                </v-col>

                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <div class="hib-htb-light-up" @click.stop="scrollToContent('section-3')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn">
                            <v-icon size="100">mdi-hand-heart</v-icon>
                        </v-btn>
                        <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-3')">من ألهمنا؟</div>
                    </div>
                </v-col>
                <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                    <div class="hib-htb" @click.stop="scrollToContent('section-3-mobile')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                            <v-icon size="50">mdi-hand-heart</v-icon>
                        </v-btn>
                        <div class="ml-n3 mr-n3 mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">إلهامنا</div>
                    </div>
                </v-col>

                <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                    <div class="hib-htb" @click.stop="scrollToContent('section-4')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                            <v-icon size="50">mdi-bullseye-arrow</v-icon>
                        </v-btn>
                        <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">هدفنا</div>
                    </div>
                </v-col>

                <v-col cols="3" align="center" class="hidden-sm-and-down">
                    <div class="hib-htb-light-up" @click.stop="scrollToContent('section-5')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn">
                            <v-icon size="100">mdi-eye</v-icon>
                        </v-btn>
                        <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;">انظر رؤيتنا</div>
                    </div>
                </v-col>
                <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                    <div class="hib-htb" @click.stop="scrollToContent('section-5')">
                        <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                            <v-icon size="50">mdi-eye</v-icon>
                        </v-btn>
                        <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">رؤيتنا</div>
                    </div>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

            <v-row v-if="!isMobile" id="section-1" justify="center" align="center" class="ma-0 pa-0" :class="isMobile ? 'header-mobile' : 'header'">
                <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-10 order-1 order-sm-2">
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div>
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black">{{ currentSectionOneData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">{{ currentSectionOneData.subtitle }}</p>
                            <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph1 }}</p>
                            <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph2 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-10 fill-height order-2 order-sm-1">
                    <v-lazy height="100%" :options="{ threshold: 0.5 }">
                        <v-carousel height="100%" hide-delimiter-background cycle interval="15000" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom" @change="sectionOneCarouselPhotoChanged">
                            <v-carousel-item
                                v-for="(photo, index) in sectionOneCarouselPhotos"
                                :key="index"
                                :src="photo.photoURL"
                                contain
                                class="pa-2 section-1-carousel-image"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-lazy>
                </v-col>
            </v-row>
            <v-row v-else id="section-1" justify="center" align="end" no-gutters class="ma-0 pa-0" :class="isMobile ? 'header-mobile' : 'header'">
                <!-- :style="sectionOneBackgroundStyle" -->
                <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-5 order-1">
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div>
                            <!-- class="overlay" -->
                            <p class="text-lg-h3 text-md-h4 text-h6 accent--text font-weight-black">{{ currentSectionOneData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold">{{ currentSectionOneData.subtitle }}</p>
                            <p class="text-lg-subtitle-1 text-md-subtitle-1 text-caption font-weight-medium">{{ currentSectionOneData.paragraph1 }}</p>
                            <!-- <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph2 }}</p> -->
                        </div>
                    </v-lazy>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2">
                    <v-lazy height="100%" :options="{ threshold: 0.5 }">
                        <v-carousel height="350px" hide-delimiter-background cycle interval="15000" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom" @change="sectionOneCarouselPhotoChanged">
                            <v-carousel-item
                                v-for="(photo, index) in sectionOneCarouselPhotos"
                                :key="index"
                                :src="photo.photoURL"
                                contain
                                class="pa-2 section-1-carousel-image"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-lazy>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

            <v-row v-if="!isMobile" id="section-2" justify="center" align="center" :style="sectionTwoBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                <v-col cols="12" lg="10" md="10"  align="center" class="ma-0 pa-5 pa-md-10 order-1 order-sm-2">
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionTwoData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold s2tb">{{ sectionTwoData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
            </v-row>
            <v-row v-else id="section-2" justify="center" align="center" :style="sectionTwoMobileBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                <v-col cols="12" lg="10" md="10"  align="center" class="ma-0 pa-5 pa-md-10 order-1 order-sm-2">
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionTwoData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold s2tb">{{ sectionTwoData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionTwoData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

            <v-row v-if="!isMobile" id="section-3" justify="center" align="center" class="ma-0 pa-5" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-1 ">
                    <!-- order-sm-1 -->
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionThreeData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s2tb">{{ sectionThreeData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
                    <!-- order-sm-1 -->
                <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-0 fill-height fill-width order-2 section-3-image-grid-container">
                    <div class="section-3-image-grid">
                        <v-img v-for="(photo, index) in sectionThreePhotos" :key="index" :src="photo.photoURL" class="section-3-image-item" />
                    </div>
                </v-col>
            </v-row>
            <v-row v-else id="section-3-mobile" justify="center" align="center" class="ma-0 pa-5" :style="sectionThreeMobileBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                <v-col cols="12" lg="12" md="12" sm="12" align="center" class="ma-0 pa-10 order-1 ">
                    <!-- order-sm-1 -->
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionThreeData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s2tb">{{ sectionThreeData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionThreeData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
                    <!-- order-sm-1 -->
                <v-col v-if="!isMobile" cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-0 fill-height fill-width order-2 section-3-image-grid-container">
                    <div class="section-3-image-grid">
                        <v-img v-for="(photo, index) in sectionThreePhotos" :key="index" :src="photo.photoURL" class="section-3-image-item" />
                    </div>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

            <v-row id="section-4" justify="center" align="center" :style="sectionFourMobileBackgroundStyle" class="ma-0 pa-5 hidden-md-and-up header-mobile">
                <v-col cols="12" lg="12" md="12" sm="12" align="center" class="ma-0 pa-0 overlay">
                    <!-- <span class="text-h4 text-md-h3 font-weight-normal">At <span class="font-weight-normal primary--text">tawq.in</span> <span class="text-decoration-underline font-weight-black">YOU</span> are Family!</span><br/><br/> -->
                    <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionFourData.title }}</p>
                    <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s2tb">{{ sectionFourData.subtitle }}</p>
                    <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFourData.paragraph1 }}</p>
                    <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFourData.paragraph2 }}</p>
                    <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFourData.paragraph3 }}</p>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

            <v-row v-if="!isMobile" id="section-5" justify="center" align="center" class="ma-0 pa-5" :style="sectionFiveBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-2 ">
                    <!-- order-sm-1 -->
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionFiveData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s2tb">{{ sectionFiveData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFiveData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFiveData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium s2tb">{{ sectionFiveData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
            </v-row>
            <v-row v-else id="section-5" justify="center" align="center" class="ma-0 pa-5" :style="sectionFiveMobileBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                <v-col cols="12" lg="12" md="12" sm="12" align="center" class="ma-0 pa-5 order-2 ">
                    <!-- order-sm-1 -->
                    <v-lazy :options="{ threshold: 0.5 }">
                        <div class="overlay">
                            <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s2tb">{{ sectionFiveData.title }}</p>
                            <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s2tb">{{ sectionFiveData.subtitle }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionFiveData.paragraph1 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionFiveData.paragraph2 }}</p>
                            <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium s2tb">{{ sectionFiveData.paragraph3 }}</p>
                        </div>
                    </v-lazy>
                </v-col>
            </v-row>

            <v-divider class="primary"/>

        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "about",
    metaInfo: {
        title: 'About - tawqin'
    },
    props: [],
    components: {},
    data() {
        return {
            userTheme: null,
            sectionZeroCarouselPhotos: [],
            sectionOneCarouselPhotos: [],
            sectionTwoPhotos: [],
            sectionThreePhotos: [],
            sectionFivePhotos: [],

            sectionTwoBackgroundPhoto: [],
            sectionThreeBackgroundPhoto: [],
            sectionFourBackgroundPhoto: [],
            sectionFiveBackgroundPhoto: [],
            currentSectionOneData: {},
            sectionOneData: [
                {
                    index: 0,
                    title: "فاطمة عامر المشاني (Fatima Amer Al-Mashani)",
                    subtitle: "المؤسس المشارك",
                    paragraph1: "لقد كانت فاطمة جزءًا أساسيًا من tawq.in. وبكلماتها الخاصة، 'هدفي هو الحفاظ على اللغات التي لا تُقرأ أو تُكتب والمساهمة في دعم المجتمعات النامية لاستخدام التكنولوجيا والإنترنت لتسهيل الحياة اليومية والتكيف مع جميع التحديات الحديثة.",
                    paragraph2: "",
                },
                {
                    index: 1,
                    title: "مارتي هيتون (marty heaton)",
                    // subtitle: "المؤسس والعقل المدبر وراء tawq.in!",
                    // paragraph1: "مارتي هو السبب وراء وجود tawq.in. لقد جاءت له الفكرة بينما كان مع أصدقائه في عُمان.",
                    // paragraph2: "",
                    // paragraph3: "",
                    subtitle: "المؤسس والعقل المدبر خلف tawq.in",
                    paragraph1: "مارتن هو السبب في وجود tawq.in. جاءت الفكرة له أثناء زيارته لأصدقاء في عمان. عندما قضى وقتًا مع عائلاتهم، أدرك أن اللغات غير المكتوبة مثل لغة الحاكلي مهددة بالضياع بين الأجيال. وهذا ما ألهمه لتصور منصة يمكنها سد الفجوة بين كبار السن والشباب، مما يسمح بالحفاظ على القصص والثقافة واللغات.",
                    paragraph2: "مارتن مسؤول عن الرؤية الأساسية لـ tawq.in وكيفية تحقيقها. لديه مهارة في جمع الأشخاص والموارد المناسبة لتحقيق أحلامه. من خلال التعاون مع سكوت وديلان، حرص على أن تكون tawq.in ليست فقط عملية، ولكن أيضًا سهلة الاستخدام، مما يمكّن الناس من جميع الفئات من سرد قصصهم ومشاركة أصواتهم.",
                    paragraph3: "في وقت فراغه، مارتن دائم التفكير في طرق جديدة لتحسين tawq.in، لضمان بقائها موردًا قيمًا لأولئك الذين يرغبون في مشاركة تراثهم والتعلم من الآخرين. سواء من خلال إضافة ميزات جديدة أو التفاعل مع المجتمع، فهو ملتزم بجعل المنصة أداة أساسية لأي شخص شغوف باللغة ورواية القصص وبناء الروابط."
                },
                {
                    index: 2,
                    title: "سكوت برادي (Scott Brady)",
                    subtitle: "مطور برمجيات رئيسي ومؤسس مشارك",
                    paragraph1: "سكوت يعمل على tawq.in منذ خمس سنوات واستمتع برؤية كيف يمكن أن يغير حياة الآخرين. وهو مسؤول عن الكثير مما تراه على الشاشة وكيفية تفاعلك مع الموقع. يحب إضافة ميزات جديدة تجعل تجربة استخدام tawq.in أكثر متعة!",
                    paragraph2: "في أوقات فراغه، يعمل سكوت كمساعد مدرب التشجيع في جامعة يوتا للتكنولوجيا في سانت جورج، يوتا، وهو المنصب الذي شغله لمدة أربع سنوات. يحب مشاهدة أداء فريقه والمنافسة والنمو! إنه يستمتع بالتواجد في الخارج، والتسكع مع أصدقائه، وممارسة الرياضة، وتناول وجبة جيدة، ولعب ألعاب الفيديو.",
                },
                {
                    index: 3,
                    // title: "ديلان جونسون (Dillan Johnson)",
                    // subtitle: "مطور برامج مشارك سابق ومؤسس مشارك",
                    // paragraph1: "",
                    // paragraph2: "",
                    title: "ديلان جونسون (Dillan Johnson)",
                    subtitle: "مطور برامج مشارك سابقًا والمؤسس المشارك",
                    paragraph1: "عمل ديلان مع tawq.in لمدة 3 سنوات. يعمل حاليًا في شركة مقرها سانت جورج، يوتا تُدعى TCN. كانت أفكاره وعمله أساسية في جعل tawq.in ما هو عليه اليوم.",
                    paragraph2: "في وقت فراغه، يستمتع ديلان بقضاء الوقت مع صديقته كينز وكلبه كينا. صحته النفسية والجسدية مهمة جدًا له. هو مصور رائع ويحب مشاركة صوره وتجربته."
                },
            ],
            sectionTwoData: {
                // Arabic Hakli: حكلي
                // Arabic Jibbali: جبلي
                // title: "ميلاد موقع tawq.in",
                // subtitle: "بدأت منذ أكثر من 15 عاماً، في عام 2009، على شواطئ المحيط الهندي.",
                // paragraph1: "عندما قام مؤسسنا، مارتي، برحلته الأولى إلى الشرق الأوسط لزيارة بعض الأصدقاء، أصبح مفتونًا باللغة غير المكتوبة التي لم يسمع بها من قبل، وهي لغة هاكلي، المستخدمة في جنوب عمان. نظرًا لوجود أدوات محدودة لتعلم اللغات غير المكتوبة، بدأ مارتي في تصور تكنولوجيا سهلة الاستخدام لن تساعده في تعلم هاكلي فحسب، بل ستسمح لأصدقائه متعددي اللغات بإنشاء المحتوى ومشاركته معه.",
                // paragraph2: "لتحقيق رؤيته إلى واقع، تواصل مارتي مع الجامعة المحلية في سانت جورج، يوتا، ووجد مطورين شابين للبرمجيات، سكوت وديلان. وبحسب قول مارتي: 'بعد الاستماع بصبر للعديد من الطلبات'، بدأ كل من سكوت وديلان بكتابة البرمجيات بعناية. وبعد عشرات الاجتماعات وإعادة التصميم والمزيد من الاجتماعات، تم نشر موقع tawq.in على الويب وأصبح متاحًا للجميع!",
                // paragraph3: "هذا البرنامج السهل الاستخدام يمنح أي شخص القدرة على إضافة صوته الخاص إلى ملف صوتي أو فيديو. سواء كنت مترجمًا، معلمًا، مدربًا، أو ترغب فقط في سرد قصتك، فإن tawq.in هو المكان المناسب لك.",
                // paragraph4: "",
                title: "ولادة tawq.in",
                subtitle: "بدأت الرحلة قبل أكثر من 15 عامًا، في عام 2009، على شواطئ المحيط الهندي.",
                paragraph1: "خلال رحلته الأولى إلى الشرق الأوسط لزيارة أصدقائه، اكتشف مؤسسنا، مارتن، لغة غير مكتوبة لم يكن يعرفها من قبل - وهي لغة جبالي التي تتحدث بها قبيلة الحاكلي في جنوب عمان. أُعجب بهذه اللغة، وأدرك نقص الموارد المتاحة لتعلمها، فتصور مارتن تقنية سهلة الاستخدام لا تساعده فقط في تعلم لغة الجبالي، بل تمكن أصدقائه متعددي اللغات من إنشاء المحتوى ومشاركته بلغاتهم الخاصة.",
                paragraph2: "عازمًا على تحقيق رؤيته، تواصل مارتن مع الجامعة المحلية في سانت جورج، يوتا، حيث وجد مطورين موهوبين، سكوت وديلان. وفقًا لكلمات مارتن: 'بعد الاستماع بصبر لطلبات لا تحصى'، بدأ سكوت وديلان في كتابة الأكواد بعناية. وبعد العديد من الاجتماعات، وإعادة التصميم، والمزيد من جلسات العصف الذهني، تم إطلاق tawq.in وجعله متاحًا للجميع.",
                paragraph3: "يتيح هذا البرنامج البديهي للمستخدمين إضافة أصواتهم بسهولة إلى الملفات الصوتية أو الفيديو. سواء كنت مترجمًا، معلمًا، مدربًا، أو ببساطة شخصًا لديه قصة يود سردها، فإن tawq.in هو المنصة المناسبة لك."
            },
            sectionThreeData: {
                // title: "مستوحى من الجدات في جنوب عمان!",
                // subtitle: "",
                // paragraph1: "",
                // paragraph2: "",
                // paragraph3: "",
                // paragraph4: "",
                title: "مستوحى من الجدات في جنوب عمان!",
                subtitle: "حفظ القصص، وبناء جسور بين الأجيال",
                paragraph1: "وُلد tawq.in من الإدراك بأن اللغة لا ينبغي أن تكون حاجزًا بين الأجيال. من خلال تمكين الجدات من مشاركة قصصهن بلغتهن الأم، وتمكين أبنائهن من ترجمتها، تساهم المنصة في ربط الماضي بالحاضر.",
                paragraph2: "قصص هؤلاء الشيوخ ليست مجرد كلمات - إنها ذكريات، تقاليد، وحكم. يمنح tawq.in الجدات فرصة لنقل تراثهن الثقافي الغني إلى الجيل القادم، بلغة يفهمونها."
            },
            sectionFourData: {
                title: "في tawq.in أنت جزء من العائلة!",
                subtitle: "نحن ملتزمون بالحفاظ على اللغات غير المكتوبة وتمكين المجتمعات.",
                paragraph1: "ونحن نفعل ذلك من خلال الاستفادة من التكنولوجيا والإنترنت. تتيح منصتنا للمستخدمين مشاركة هذه اللغات وتوثيقها، والمساهمة في التراث الثقافي مع دعم المجتمعات النامية في التكيف مع التحديات الحديثة وتعزيز الحياة اليومية.",
                paragraph2: "",
                paragraph3: "",
                paragraph4: "",
            },
            sectionFiveData: {
                // title: "تساعد على تسهيل التعلم",
                // subtitle: "",
                // paragraph1: "",
                // paragraph2: "",
                // paragraph3: "",
                // paragraph4: "",
                title: "المساهمة في تسهيل التعلم",
                subtitle: "تمكين المستخدمين لاكتشاف إمكانيات جديدة",
                paragraph1: "رؤيتنا لمنصة tawq.in تتجاوز مجرد الحفاظ على اللغة. لقد صممنا المنصة لتكون مرنة قدر الإمكان، مما يتيح للمستخدمين تكييفها مع احتياجاتهم الفريدة. سواء كان ذلك لمشاركة القصص العزيزة، تسجيل وترجمة المحتوى، أو التدريب والتعليم بلغات مختلفة، فإن الاحتمالات لا حدود لها. نحن نؤمن أنه كلما استكشفت منصة tawq.in، ستكتشف طرقًا جديدة لجعلها تعمل لصالحك.",
                paragraph2: "تم بناء tawq.in مع التركيز على سهولة الوصول. سواء كنت جدًا تسجل قصة لأحفادك، أو معلماً يدرّس مفاهيم جديدة، أو مترجمًا يجسر الفجوة بين الثقافات، فإن منصتنا تمنحك القدرة على مشاركة صوتك. يمكن للمستخدمين بسهولة إضافة تسميات توضيحية، وتعليقات صوتية، وترجمات، مما يجعل المحتوى جذابًا ومفهومًا لجمهور متنوع.",
                paragraph3: "نحن نتخيل عالماً يمكن فيه للتعلم، وسرد القصص، وتبادل الثقافات أن تزدهر دون حواجز. يوفر tawq.in الأدوات اللازمة لإنشاء ومشاركة والتواصل بطرق ذات معنى لكل مستخدم. بينما تستكشف منصتنا، نشجعك على العثور على طريقتك الفريدة لاستخدامها - سواء للتعليم، أو الحفاظ على التراث، أو ببساطة لمشاركة تجاربك مع الآخرين."
            },
        };
    },
    methods: {
        getAboutPhotosForCarousel: async function () {
            firebase.db.collection("about").get().then((snapshot) => {
                let sectionZeroCounter = 0;
                let sectionOnePhotos = [];
                let sectionTwo = [];
                if (!this.isMobile) {
                    snapshot.forEach((doc) => {
                        if (doc.data().section == 0) {
                            this.sectionZeroCarouselPhotos.index = sectionZeroCounter;
                            this.sectionZeroCarouselPhotos.push(doc.data());
                            sectionZeroCounter++;
                        } else if (doc.data().section == 1) {
                            sectionOnePhotos.push(doc.data());
                        } else if (doc.data().section == 2) {
                            sectionTwo.push(doc.data());
                        } else if (doc.data().section == 3) {
                            this.sectionThreePhotos.push(doc.data());
                        }  else if (doc.data().section == 5) {
                            this.sectionFivePhotos.push(doc.data());
                        }
                    });
                    this.sortSectionOneCarouselPhotosByName(sectionOnePhotos);
                    this.sortSectionTwoPhotosByName(sectionTwo);
                } else {
                    const sectionTwoBackgroundPhotoName = "marty_with_friends_middle_east.jpg";
                    const sectionThreeBackgroundPhotoName = "middle_east_desert.jpg";
                    const sectionFourBackgroundPhotoName = "oman_friends.jpg";
                    const sectionFiveBackgroundPhotoName = "camels.jpg";
                    snapshot.forEach((doc) => {
                        if (doc.data().section == 0) {
                            this.sectionZeroCarouselPhotos.index = sectionZeroCounter;
                            this.sectionZeroCarouselPhotos.push(doc.data());
                            sectionZeroCounter++;
                        } else if (doc.data().section == 1) {
                            sectionOnePhotos.push(doc.data());
                        } else if (doc.data().section == 2 && doc.data().name == sectionTwoBackgroundPhotoName) {
                            this.sectionTwoBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 3 && doc.data().name == sectionThreeBackgroundPhotoName) {
                            this.sectionThreeBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 4 && doc.data().name == sectionFourBackgroundPhotoName) {
                            this.sectionFourBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 5 && doc.data().name == sectionFiveBackgroundPhotoName) {
                            this.sectionFiveBackgroundPhoto.push(doc.data());
                        }
                    });
                    this.sortSectionOneCarouselPhotosByName(sectionOnePhotos);
                }
            });
        },
        sortSectionOneCarouselPhotosByName: function (sectionOnePhotos) {
            const customOrder = ['fatima.jpeg', 'marty.jpeg', 'scott.jpeg', 'dillan.jpg'];
            sectionOnePhotos.sort((a, b) => { // Sort section 1 photos by custom order in 'name' field
                return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
            });

            sectionOnePhotos.forEach((photo, index) => { // Add sorted photos to the sectionOneCarouselPhotos array
                let photoData = {};
                photoData.index = index;
                photoData.name = photo.name;
                photoData.photoId = photo.photoId;
                photoData.photoURL = photo.photoURL;
                photoData.section = photo.section;
                this.sectionOneCarouselPhotos.push(photoData);
            });
        },
        sortSectionTwoPhotosByName: function (sectionTwo) {
            const customOrder = ['camels.jpg', 'marty_camel.jpg', 'marty_with_friends_middle_east.jpg', 'oman_friends.jpg', 'hamdan_learning.jpg', 'tawq.in_aip_big_room.jpg', 'aip_full_team.jpg', 'group_aip_big_room_meeting.jpg', 'marty_google_meet.jpg', 'international_students.jpg', 'shadman_bashir_using_tawq.in.jpg', 'tawqin_logo.png'];
            sectionTwo.sort((a, b) => { // Sort section 2 photos by custom order in 'name' field
                return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
            });

            sectionTwo.forEach((photo, index) => { // Add sorted photos to the sectionOneCarouselPhotos array
                let photoData = {};
                photoData.index = index;
                photoData.name = photo.name;
                photoData.photoId = photo.photoId;
                photoData.photoURL = photo.photoURL;
                photoData.section = photo.section;
                this.sectionTwoPhotos.push(photoData);
            });
        },
        sectionOneCarouselPhotoChanged: function (index) {
            this.currentSectionOneData = this.sectionOneData[index];
        },
        scrollToContent: function (id) {
            const contentElement = document.getElementById(id);
            if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "center" });
            // if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        },
    },
    computed: {
        // isAdmin(){
        //     return firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.id === "ex18uSUvXReInwQRqaAZxO6Syer2";
        // },
        isMobile () {
            return this.$store.state.isMobileDevice;
        },
        sectionOneBackgroundStyle() {
            const backgroundImages = this.sectionOneCarouselPhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImages,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionTwoBackgroundStyle() {
            const backgroundImages = this.sectionTwoPhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImages,
                // backgroundSize: '16.66% 33.32%', // Adjust to fit 6 images per row, 2 rows
                backgroundSize: '16.66% 50%', // Adjust to fit 6 images per row, 2 rows
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `
                0% 0%, 20% 0%, 40% 0%, 60% 0%, 80% 0%, 100% 0%,
                0% 100%, 20% 100%, 40% 100%, 60% 100%, 80% 100%, 100% 100%
                `
            };
        },
        sectionTwoMobileBackgroundStyle() {
            const backgroundImage = this.sectionTwoBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionThreeMobileBackgroundStyle() {
            const backgroundImage = this.sectionThreeBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFourMobileBackgroundStyle() {
            const backgroundImage = this.sectionFourBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFiveMobileBackgroundStyle() {
            const backgroundImage = this.sectionFiveBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFiveBackgroundStyle() {
            const backgroundImage = this.sectionFivePhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'fit',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
    },
    created: async function () {
        await this.getAboutPhotosForCarousel();
        this.$vuetify.theme.dark = true; // This will force the app to be in dark mode when a user is on about page.
    },
    mounted: async function () {
        this.userTheme = this.$vuetify.theme.isDark;
        // console.log("theme: ", this.userTheme);
    },
    beforeDestroy: async function () {
        if (this.userTheme == false) {
            this.$vuetify.theme.dark = false; // This set the app to be in light mode if a user theme was light before coming to the page.
        }
    },
}
</script>

<style scoped>
.main-header {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 250px);
  width: 100%;
  /* width: 100vw; */
}
.main-header-mobile {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 200px);
  width: 100%;
  /* width: 100vw; */
}

.header {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 250px);
  width: 100%;
  /* width: 100vw; */
}

.header-mobile {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 0px);
  width: 100%;
  /* width: 100vw; */
}

::v-deep .custom .v-carousel__controls__item.v-btn {
  color: white;
}

::v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active {
  color: var(--v-primary-base);
  /* color: var(--v-accent-base); */
}

::v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active:before {
  opacity: 0;
}

::v-deep .custom .v-carousel__controls__item.v-btn:hover {
  color: var(--v-accent-base);
  /* color: var(--v-primary-base); */
}

::v-deep .custom .v-carousel__controls__item.v-btn:hover:before {
  opacity: 0;
}

.hib-btn {
    width: 100px;
    height: 100px;
}
.hib-htb-light-up:hover .v-icon,
.hib-htb-light-up:hover .text-h4 {
    text-shadow: 0 0 .15em var(--v-primary-base), 0 0 .15em var(--v-primary-base), 0 0 .15em var(--v-accent-base);
}
.hib-htb .v-icon,
.hib-htb .text-subtitle-2 {
    text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-accent-base);
}

/* SECTION 1 */
.section-1-carousel-image {
    background-size: contain; /* Ensures the full image fits within the item */
    background-position: center;
    background-repeat: no-repeat;
    height: 100%; /* Forces the image to fill the height of the carousel */
}
/* #section-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
}
#section-1 .overlay {
    position: relative;
    z-index: 2;
    color: white;
} */

/* SECTION 2 */
#section-2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-2 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}
.s2tb {
    text-shadow: 10px 10px 1em black, 10px 10px 1em black, 10px 10px 1em black;
}

/* SECTION 3 */
.section-3-image-grid-container {
    background-color: transparent;
    display: flex;
    border: 2px solid var(--v-primary-base);
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
}

.section-3-image-grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px;
    width: 100%;
    height: 100%;
    /* max-width: 500px; */
}

.section-3-image-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#section-3-mobile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-3-mobile .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}

/* SECTION 4 */
#section-4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-4 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}

/* SECTION 5 */
#section-5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-5 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}
</style>