<template>
    <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="12" xl="5" lg="5" md="6" sm="8">
            <v-row justify="center" no-gutters class="mb-n10">
                <span class="text-h4 font-weight-light">My Friends</span>
            </v-row>

            <v-row justify="end" align="center" no-gutters>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" fab small color="primary" @click.stop="newFriendDialog = true" class="hidden-sm-and-down"><v-icon>mdi-account-plus</v-icon></v-btn>
                        <v-btn fab small color="primary" @click.stop="newFriendDialog = true" class="hidden-md-and-up"><v-icon>mdi-account-plus</v-icon></v-btn>
                    </template>
                    <span>Add a New Friend</span>
                </v-tooltip>
            </v-row>
            <NewFriend v-if="newFriendDialog" :dialog="newFriendDialog" :friends="friends" @cancelFriend="cancelFriend" @cancelNewFriend="newFriendDialog = false"/>

            <v-row v-show="friends.length === 0" justify="center" no-gutters>
                <span class="text-subtitle-1 text-center py-5">Your friends will show up here when you add or accept a friend.</span>
            </v-row>
            <Friends :friends="friends" @cancelFriend="cancelFriend" @removeFriend="removeFriend"/>

        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
import Friends from "./Friends.vue";
import NewFriend from "./NewFriend.vue";


export default {
    name: "FriendsPage",
    metaInfo: {
            title: 'Friends - tawq.in'
        },
    components: {
        Friends,
        NewFriend,
    },
    data: function () {
        return {
            newFriendDialog: false,
        };
    },
    methods: {
        cancelFriend: async function (friend) {
            var notif = {
                id: friend.notifId,
                data: {
                    email: friend.email,
                    username: friend.username,
                    type: 'friend',
                    userId: friend.userId, //firebase.auth.currentUser.uid,
                    userProfilePhoto: friend.userProfilePhoto,
                }
            }
            let response = await this.$http.delete(`friends/${friend.userId}/${firebase.auth.currentUser.uid}`, {data: {notif: notif, accepted: friend.accepted}});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Canceled", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
        },
        removeFriend: async function (friend/*, index*/) {
            // index value can be used to remove the friend to be deleted from the local list so we don't have to wait until we get a return value to remove them.
            var notif = {
                id: friend.notifId,
                data: {
                    email: friend.email,
                    username: friend.username,
                    type: 'friend',
                    userId: friend.userId, //firebase.auth.currentUser.uid,
                    userProfilePhoto: friend.userProfilePhoto,
                }
            }
            let response = await this.$http.delete(`friends/${friend.userId}/${firebase.auth.currentUser.uid}`, {data: {notif: notif, accepted: friend.accepted}});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Removed", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
        },
    },
    computed: {
        friends(){
            return this.$store.state.userFriends;
        },
    },
};
</script>
