<template>
    <div>
        <Alert
            :key="NaN"
            v-if="alert"
            :dialogVal="alertIsDialog"
            :snackbarVal="alertIsSnackbar"
            :action="alertAction"
            :type="alertType"
            :title="alertTitle"
            :text="alertText"
            @dialogClicked="alertClicked"
            @snackbarClicked="alertClicked"
        ></Alert>

        <!-- Nav Bar Code -->
        <v-container class="mb-10">
            <v-app-bar v-bind:class="[$vuetify.theme.dark ? '' : 'grey lighten-3']" fixed flat app>
                <v-row justify="space-between" align="center">
                    <div class="flex-grow-1 d-flex flex-row align-center">
                        <v-btn icon @click.stop="homeDialog">
                            <v-icon color="primary" x-large>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text font-weight-light">tawq.in Photo Contribution</v-toolbar-title>
                    </div>
                    <div>
                        <router-link class="pr-8" :to="{ name: 'help', query: {loadTopic: 'createPost', loadSubTopic: (1) }  }" target="_blank" style="text-decoration: none;">
                            <v-icon :class="[$vuetify.theme.dark ? 'white--text' : 'primary--text']" size="20">mdi-help</v-icon>
                        </router-link>
                    </div>
                </v-row>
                <!-- HELP BUTTON -->
                <transition name="fade">
                    <div v-if="userData.postsCreated < 3 && showHelpIndicator" class="d-flex flex-column px-8"  style="position: absolute; bottom: -60px; right: 0px;">
                        <v-icon class="align-self-end">mdi-arrow-up</v-icon>
                        <span class="rounded-xl primary py-1 px-3">Need help? Click here.</span> 
                    </div>
                </transition>
                <!-- END HELP BUTTON -->
            </v-app-bar>
        </v-container>
        <!-- End Nav Bar Code -->

        <!-- TITLE AND HOW YOURE CONTRIBUTING -->
        <v-row v-if="!stepOneComplete" justify="center" align="center" class="mx-0">
            <v-col :cols="isMobile ? '12' : '4'" align="center">
                <v-row justify="center" class="mb-5">
                    <h1 class="font-weight-light text-center">Enter a Title</h1>
                </v-row>
                <!-- @submit.prevent="next" -->
                <v-form v-model="isFormValid">
                    <v-text-field
                        clearable
                        required
                        flat
                        outlined
                        :counter="50"
                        :rules="titleRules"
                        v-model="newPhoto.title"
                        label="Enter Title (required)"
                    ></v-text-field>
                    <v-row justify="center" class="mt-5">
                        <h2 class="font-weight-bold text-center">Choose how you'll contribute</h2>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon small class="mx-2" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span>You can contribute with new text that you place on the image <br/> or add your voice to the original text used by the creator of the post.</span>
                            <!-- <span>Contributions allow other users to add their<br/> own audio and captions to the text you've added; <br/> or create new text, audio and captions to your photo. <br/> Unselect the box below to disable contributions.</span> -->
                        </v-tooltip>
                    </v-row>
                    <v-row justify="center">
                        <v-radio-group
                            :rules="radioGroupRules"
                            v-model="radioGroup"
                            row>
                            <v-radio
                                label="Original Text"
                                value="original"
                            ></v-radio>
                            <v-radio
                                label="New Text"
                                value="new"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- END TITLE AND HOW YOURE CONTRIBUTING -->

        <!-- MAIN CONTENT -->

        <!-- NEW TEXT ON IMAGE -->
        <v-row v-show="stepOneComplete && radioGroup=='new'" justify="center" class="mx-0" :class="isMobile ? 'pb-10' : null">

            <!-- TEXT BOXES LEFT SIDE -->
            <v-col :cols="isMobile ? '12' : '4'" class="border-box" :class="isMobile ? 'order-2' : 'order-1'">

                <!-- IMAGE (MAIN/TOP) TEXT BOX -->
                <v-col align="center" class="width: 100%">

                    <v-btn v-if="reverseTextAdded.length > 0" @click="showAllTheText" :disabled="uploadingRecording || recording">{{showAllText ? 'hide all text' : 'show all text'}}</v-btn>

                    <v-card class="rounded-lg">
                        <v-card-title @click="showMoreText(true,imageText)" :ripple="false" :style="imageText.showMoreText ? 'cursor: pointer' : null" class="rounded-lg" :class="$vuetify.theme.dark ? 'primary' : 'primary'">{{imageText.text == "" ? "Enter text below" : imageText.text.length >= 23 ? imageText.text.match(/.{23}/)[0] + "..." : imageText.text}}</v-card-title>
                        <v-col v-if="!imageText.showMoreText" cols="12">
                            <v-text-field
                                :error="noText"
                                v-model="imageText.text"
                                outlined
                                placeholder="Enter text here"
                                @keypress.enter="addText"
                                @keypress="noText = false"
                                counter="30"
                                maxlength="30"
                            ></v-text-field>

                            <!-- RECORDING CODE -->
                            <v-col cols="12">
                                <v-row v-if="selectedText.audioURL == null && !uploadingRecording" class="mx-0" align="center" justify="space-between">
                                    <v-btn class="px-0" v-if="recording" @click.stop="pauseRecording" :color="$vuetify.theme.dark ? 'white' : 'black'" text :ripple="false">
                                        {{recording && recordingPaused ? "Play" : "Pause"}}
                                        <v-icon>{{recording && recordingPaused ? "mdi-play" : "mdi-pause"}}</v-icon>
                                    </v-btn>
                                    <v-btn class="px-0" :ripple="false" @click.stop="recordingTime" :color="recording ? 'red' : $vuetify.theme.dark ? 'white' : 'black'" text>
                                        {{recording ? "Stop" : "Record"}}
                                        <v-icon color="red">{{recording ? "mdi-stop" : "mdi-record"}}</v-icon>
                                    </v-btn>
                                    <h4>{{secondsToTime(timePlaying).h}}{{secondsToTime(timePlaying).m}}:{{secondsToTime(timePlaying).s}}</h4>
                                </v-row>
                                <v-row v-else align="center" justify="space-around" class="mx-0">
                                    <!-- :duration="100000" -->
                                    <!-- :src="selectedText.audioURL" -->                                    
                                    <AudioPlayer v-if="!uploadingRecording" style="width: 100%;" :src="selectedText.audioURL"/>
                                    <v-btn class="px-0" v-if="!uploadingRecording" large @click.stop="deleteRecording(imageText)" :ripple="false" :color="$vuetify.theme.dark ? 'white' : 'black'" text>
                                        {{"Re-record Audio"}}
                                        <v-icon small :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-replay</v-icon>
                                    </v-btn>
                                    <div v-else class="d-flex flex-column align-center">
                                        <pre class="invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                                    </div>
                                </v-row>
                                <!-- :style="[$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;']" -->
                                <v-row v-show="recording && !recordingPaused" align="center" justify="center">
                                    <canvas class="visualizer" height="40px"></canvas>
                                </v-row>
                            </v-col>
                            <!-- END RECORDING CODE -->

                            <v-textarea v-show="!uploadingRecording && !recording" v-model="selectedText.caption" :placeholder="selectedText.caption == '' ? 'Record or type to add caption' : ''" rows="2" outlined></v-textarea>

                            <!-- ADVANCED SETTINGS -->
                            <v-row v-show="!uploadingRecording && !recording" justify="center" align="center" class="mx-0" :class="[!selectedText.showMoreOptions ? 'pb-0' : 'pb-0']">
                                <h4 text @click.stop="selectedText.showMoreOptions = !selectedText.showMoreOptions" style="cursor:pointer;" class="font-weight-light accent--text">More Options</h4>
                                <v-btn icon @click="selectedText.showMoreOptions = !selectedText.showMoreOptions" :ripple="false">
                                    <v-icon>{{ selectedText.showMoreOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-row>
                            <v-col cols="12" v-if="selectedText.showMoreOptions">
                                <v-autocomplete
                                    v-show="!uploadingRecording && !recording && selectedText.audioURL == null"
                                    label="Select a language"
                                    v-model="selectedText.languageSelected"
                                    :items="$languageCodes"
                                    no-data-text="No Groups to Select"
                                    item-text="text"
                                    item-value="value"
                                    return-object
                                    outlined
                                    hint="*This will be the langauge you speak while recording"
                                    persistent-hint
                                    @change="languageChanged">
                                </v-autocomplete>
                                <v-slider
                                    v-model="selectedText.style.fontSize"
                                    class="mt-3 font-weight-bold"
                                    label="Text Size:"
                                    thumb-label="always"
                                    thumb-size="25"
                                    min="1"
                                    max="10"
                                    step=".5"
                                ></v-slider>
                                <v-color-picker
                                    :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                                    v-model="selectedText.style.textColor"
                                    mode="hexa"
                                    dot-size="20"
                                    canvas-height="75"
                                    hide-inputs
                                    hide-sliders
                                ></v-color-picker>
                            </v-col>
                            <!-- END ADVANCED SETTINGS -->

                        </v-col>

                        <v-row v-show="!uploadingRecording && !recording" v-if="selectedText == imageText">
                            <v-col>
                                <v-btn class="add-text" @click="addText" text> <v-icon>mdi-plus</v-icon>Add</v-btn>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
                <!-- END IMAGE (MAIN/TOP) TEXT BOX -->

                <!-- TEXT ADDED BOX -->
                <v-col v-for="(text,i) in reverseTextAdded" :key='i' style="position: relative;">
                    <v-hover v-slot="{hover}">
                        <div>
                            <v-card class="rounded-lg">
                                <v-card-title @click="showMoreText(false, text)" :ripple="false" style="cursor: pointer" class="justify-space-between">{{text.text.length >= 20 ? text.text.match(/.{20}/)[0] + "..." : text.text}}
                                    <v-btn icon :ripple="false">
                                        <v-icon>{{ reverseTextAdded[i].showMoreText ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-col v-if="reverseTextAdded[i].showMoreText" cols="12">
                                    <v-text-field
                                        v-model="text.text"
                                        outlined
                                        :placeholder="text.text"
                                        counter="30"
                                        maxlength="30"
                                    ></v-text-field>

                                    <!-- RECORDING CODE -->
                                    <v-col cols="12">
                                        <!-- <h4>{{selectedText.segment}}</h4> -->
                                        <v-row v-if="selectedText.audioURL == null && !uploadingRecording" class="mx-0" align="center" justify="space-between">
                                            <v-btn class="px-0" v-if="recording" @click.stop="pauseRecording" :color="$vuetify.theme.dark ? 'white' : 'black'" text :ripple="false">
                                                {{recording && recordingPaused ? "Play" : "Pause"}}
                                                <v-icon>{{recording && recordingPaused ? "mdi-play" : "mdi-pause"}}</v-icon>
                                            </v-btn>
                                            <v-btn class="px-0" :ripple="false" @click.stop="recordingTime" :color="recording ? 'red' : $vuetify.theme.dark ? 'white' : 'black'" text>
                                                {{recording ? "Stop" : "Record"}}
                                                <v-icon color="red">{{recording ? "mdi-stop" : "mdi-record"}}</v-icon>
                                            </v-btn>
                                            <h4>{{secondsToTime(timePlaying).h}}{{secondsToTime(timePlaying).m}}:{{secondsToTime(timePlaying).s}}</h4>
                                        </v-row>
                                        <v-row v-else align="center" justify="space-around" class="mx-0">
                                            <AudioPlayer v-if="!uploadingRecording" style="width: 100%;" :src="text.audioURL"/>
                                            <v-btn class="px-0" v-if="!uploadingRecording" large @click.stop="deleteRecording(text)" :ripple="false" :color="$vuetify.theme.dark ? 'white' : 'black'" text>
                                                {{"Re-record Audio"}}
                                                <v-icon small :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-replay</v-icon>
                                            </v-btn>
                                            <div v-else class="d-flex flex-column align-center">
                                                <pre class="invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                                            </div>
                                        </v-row>
                                        <!-- :style="[$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;']" -->
                                        <v-row v-show="recording && !recordingPaused" align="center" justify="center">
                                            <canvas class="visualizer" height="40px"></canvas>
                                        </v-row>
                                    </v-col>
                                    <!-- END RECORDING CODE -->

                                    <v-textarea v-show="!uploadingRecording && !recording" v-model="text.caption" :label="text.caption == '' ? 'Record or type to add caption here' : ''" rows="2" outlined></v-textarea>

                                    <!-- ADVANCED SETTINGS -->
                                    <v-row v-show="!uploadingRecording && !recording" v-model="selectedText.caption" justify="center" align="center" class="mx-0 pb-0">
                                        <h4 text @click.stop="selectedText.showMoreOptions = !selectedText.showMoreOptions" style="cursor:pointer;" class="font-weight-light accent--text">More Options</h4>
                                        <v-btn icon @click="selectedText.showMoreOptions = !selectedText.showMoreOptions" :ripple="false">
                                            <v-icon>{{ selectedText.showMoreOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                    </v-row>
                                    <v-col cols="12" v-if="selectedText.showMoreOptions">
                                        <v-autocomplete
                                            v-show="!uploadingRecording && !recording && selectedText.audioURL == null"
                                            label="Select a language"
                                            v-model="selectedText.languageSelected"
                                            :items="$languageCodes"
                                            no-data-text="No Groups to Select"
                                            item-text="text"
                                            item-value="value"
                                            return-object
                                            outlined
                                            hint="*This will be the langauge you speak while recording"
                                            persistent-hint
                                            @change="languageChanged">
                                        </v-autocomplete>
                                        <v-slider
                                            v-model="selectedText.style.fontSize"
                                            class="mt-3 font-weight-bold"
                                            label="Text Size:"
                                            thumb-label="always"
                                            thumb-size="25"
                                            min="1"
                                            max="10"
                                            step=".5"
                                        ></v-slider>
                                        <v-color-picker
                                            :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                                            v-model="selectedText.style.textColor"
                                            mode="hexa"
                                            dot-size="20"
                                            canvas-height="75"
                                            hide-inputs
                                            hide-sliders
                                        ></v-color-picker>
                                    </v-col>
                                    <!-- END ADVANCED SETTINGS -->

                                </v-col>
                            </v-card>
                            <v-icon v-if="hover" @click="deleteTextAdded(/*text,*/i)" style="position: absolute; top:0; left:0;" class="pl-1 pt-1">mdi-close-circle-outline</v-icon>
                        </div>
                    </v-hover>
                </v-col>
                <!-- END TEXT ADDED BOX -->

            </v-col>
            <!-- END TEXT BOXES LEFT SIDE -->

            <!-- IMAGE RIGHT SIDE -->
             <!-- style="border: 1px solid red;" -->
            <v-col :cols="isMobile ? '12' : '7'" class="image-div" :class="isMobile ? 'order-1' : 'order-2'" id="image-div">
                <!-- height='80vh'-->
                <!-- contain
                :aspect-ratio="16/9" -->
                <!-- @load="imageLoaded()" -->
                <v-img
                    id="image"
                    ref="image"
                    class="image"
                    style="position: relative;"
                    contain
                    :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'"
                    :src="postData.thumbnail ? postData.thumbnail : require('../assets/no-thumbnail.png')"
                >
                    <!-- :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'" -->
                    <Moveable
                        v-if="!showAllText"
                        class="moveable"
                        :style="`font-size: ${selectedText.style.fontSize}vw; color: ${selectedText.style.textColor}; transform: ${selectedText.style.transform};`"
                        id="moveable"
                        ref="moveable"
                        v-bind="moveable"
                        @drag="handleDrag"
                        @rotate="handleRotate"
                        @scale="handleScale"
                        @resize="handleResize"
                        @pinch="handlePinch"
                    >
                        <!-- :class="`${selectedText.style.textColor}--text`" -->
                        <span :style="`border: 1px solid ${selectedText.style.textColor};`">{{selectedText.text == "" ? "Enter text to begin" : selectedText.text}}</span>
                        <!-- <span :class="`${reverseTextAdded[i].textColor}--text`" :style="`font-size: ${reverseTextAdded[i].textSize}px;`">{{reverseTextAdded[i].text}}</span> -->
                    </Moveable>
                    <div v-else-if="showAllText && reverseTextAdded.length > 0">
                        <Moveable
                            v-for="(text,i) in reverseTextAdded" :key='i'
                            :style="`font-size: ${text.style.fontSize}vw; color: ${text.style.textColor}; transform: ${text.style.transform};`"
                            class="moveable"
                        >
                            <span @click="changeSelectedText(text)" :style="`border: 1px solid ${text.style.textColor};`">{{text.text == "" ? "Enter text to begin" : text.text}}</span>
                        </Moveable>
                    </div>
                    <span class="water-mark">tawq.in</span>
                </v-img>
            </v-col>
            <!-- END IMAGE RIGHT SIDE -->

        </v-row>
        <!-- END NEW TEXT ON IMAGE -->

        <!-- ORIGNAL TEXT ON IMAGE -->
        <v-row v-show="stepOneComplete && radioGroup=='original'" justify="center" class="mx-0">
            <v-col :cols="isMobile ? '12' : '4'" class="border-box" :class="isMobile ? 'order-2' : 'order-1'">

                <!-- IMAGE (MAIN/TOP) TEXT BOX -->
                <v-col align="center" class="width: 100%">

                    <v-btn v-if="textAdded.length > 0" @click="showAllTheText" :disabled="uploadingRecording || recording">{{showAllText ? 'hide all text' : 'show all text'}}</v-btn>

                    <!-- TEXT ADDED BOX -->
                    <v-col v-for="(text,i) in textAdded" :key='i' style="position: relative;">
                        <v-card class="rounded-lg">
                            <v-card-title @click="showMoreText(false, text)" :ripple="false" style="cursor: pointer" class="justify-space-between">{{text.text.length >= 20 ? text.text.match(/.{20}/)[0] + "..." : text.text}}
                                <v-btn icon :ripple="false">
                                    <v-icon>{{ text.showMoreText ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-col v-if="text.showMoreText" cols="12">
                                <v-text-field
                                    v-model="text.text"
                                    outlined
                                    :placeholder="text.text"
                                    counter="30"
                                    maxlength="30"
                                    :disabled="true"
                                ></v-text-field>

                                <!-- RECORDING CODE -->
                                <v-col cols="12">
                                    <!-- <h4>{{selectedText.segment}}</h4> -->
                                    <v-row v-if="selectedText.audioURL == null && !uploadingRecording" class="mx-0" align="center" justify="space-between">
                                        <v-btn class="px-0" v-if="recording" @click.stop="pauseRecording" :color="$vuetify.theme.dark ? 'white' : 'black'" text :ripple="false">
                                            {{recording && recordingPaused ? "Play" : "Pause"}}
                                            <v-icon>{{recording && recordingPaused ? "mdi-play" : "mdi-pause"}}</v-icon>
                                        </v-btn>
                                        <v-btn class="px-0" :ripple="false" @click.stop="recordingTime" :color="recording ? 'red' : $vuetify.theme.dark ? 'white' : 'black'" text>
                                            {{recording ? "Stop" : "Record"}}
                                            <v-icon color="red">{{recording ? "mdi-stop" : "mdi-record"}}</v-icon>
                                        </v-btn>
                                        <h4>{{secondsToTime(timePlaying).h}}{{secondsToTime(timePlaying).m}}:{{secondsToTime(timePlaying).s}}</h4>
                                    </v-row>
                                    <v-row v-else align="center" justify="space-around" class="mx-0">
                                        <AudioPlayer v-if="!uploadingRecording" style="width: 100%;" :src="text.audioURL"/>
                                        <v-btn class="px-0" v-if="!uploadingRecording" large @click.stop="deleteRecording(text)" :ripple="false" :color="$vuetify.theme.dark ? 'white' : 'black'" text>
                                            {{"Re-record Audio"}}
                                            <v-icon small :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-replay</v-icon>
                                        </v-btn>
                                        <div v-else class="d-flex flex-column align-center">
                                            <pre class="invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                                        </div>
                                    </v-row>
                                    <!-- :style="[$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;']" -->
                                    <v-row v-show="recording && !recordingPaused" align="center" justify="center">
                                        <canvas class="visualizer" height="40px"></canvas>
                                    </v-row>
                                </v-col>
                                <!-- END RECORDING CODE -->

                                <v-textarea v-show="!uploadingRecording && !recording" v-model="text.caption" :label="text.caption == '' ? 'Record or type to add caption here' : ''" rows="2" outlined></v-textarea>

                            </v-col>
                        </v-card>
                    </v-col>
                    <!-- END TEXT ADDED BOX -->

                </v-col>

            </v-col>

            <!-- IMAGE RIGHT SIDE -->
             <!-- style="border: 1px solid red;" -->
            <v-col :cols="isMobile ? '12' : '7'" class="image-div" :class="isMobile ? 'order-1' : 'order-2'" id="image-div">
                <!-- height='80vh'-->
                <!-- contain
                :aspect-ratio="16/9" -->
                <v-img
                    id="image2"
                    ref="image"
                    class="image"
                    @load="imageLoaded()"
                    :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'"
                    contain
                    :src="postData.thumbnail ? postData.thumbnail : require('../assets/no-thumbnail.png')"
                >
                    <!-- :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'" -->
                    <div
                        v-if="!showAllText"
                        class="non-moveable"
                        :style="`font-size: ${selectedText.style.fontSize}vw; color: ${selectedText.style.textColor}; transform: ${selectedText.style.transform};`"
                        id="moveable"
                        ref="moveable"
                    >
                        <!-- :class="`${selectedText.style.textColor}--text`" -->
                        <span :style="`border: 1px solid ${selectedText.style.textColor};`">{{selectedText.text == "" ? null : selectedText.text}}</span>
                        <!-- <span :class="`${textAdded[i].textColor}--text`" :style="`font-size: ${textAdded[i].textSize}px;`">{{textAdded[i].text}}</span> -->
                    </div>
                    <div v-else-if="showAllText && textAdded.length > 0">
                        <div
                            v-for="(text,i) in textAdded" :key='i'
                            :style="`font-size: ${text.style.fontSize}vw; color: ${text.style.textColor}; transform: ${text.style.transform};`"
                            class="non-moveable"
                        >
                            <span @click="changeSelectedText(text)" :style="`border: 1px solid ${text.style.textColor};`">{{text.text == "" ? "Enter text to begin" : text.text}}</span>
                        </div>
                    </div>
                    <span class="water-mark">tawq.in</span>
                </v-img>
            </v-col>
            <!-- END IMAGE RIGHT SIDE -->
        </v-row>
        <!-- END ORIGINAL TEXT ON IMAGE -->

        <!-- END MAIN CONTENT -->

        <!-- DONE/NEXT/PREVIOUS BUTTONS -->
        <div class="bottom-div">
            <!-- @click="doneDialog" -->
            <v-btn v-if="stepOneComplete" @click="beforeDone" :disabled="recording || uploadingRecording">done</v-btn>
            <v-btn v-else :disabled="!isFormValid" @click="next">next</v-btn>
        </div>
        <div v-if="stepOneComplete" class="bottom-div-2">
            <v-btn @click="previous">previous</v-btn>
        </div>
        <!-- END DONE/NEXT/PREVIOUS BUTTONS -->

    </div>
</template>

<script>
// import axios from "axios";
import firebase from "../../firebaseConfig.js";
import fb from "firebase/compat/app";
import Alert from "../components/Alert.vue";
import Moveable from "vue-moveable";
import AudioPlayer from "../components/AudioPlayer.vue";
export default {
    name: "photo-contribution",
    props: ["postId", "postType", "groupId", "post", "audio"],
    components: {
        Alert,
        Moveable,
        AudioPlayer
    },
    data: function () {
        return {
            postData: this.post,
            userData: {},
            audioData: this.audio,
            showHelpIndicator: false,
            isFormValid: false,
            stepOneComplete: false,
            hasChanged: false,
            recording: false,
            recordingPaused: false,
            uploadingRecording: false,
            recordedChunks: [],
            segment: 0,
            audioStorageReference: null,
            convertingCaption: "Converting   ",
            convertingInterval: null,
            INTERVAL: 1000,
            audioTimer: null,
            timePlaying: 0,
            recorder: null,
            canvas: null,
            canvasCtx: null,
            analyser: null,
            bufferLength: 0,
            dataArray: null,
            dbRefs: {
                subtitleRef: null,
                conversionRef: null,
            },
            moveable: {
                draggable: true,
                throttleDrag: 0.01,
                rotatable: false,
                throttleRotate: 0.01,
                scalable: false,
                throttleScale: 0.01,
                resizable: false,
                throttleResize: 0.01,
                pinchable: false, // ["draggable", "resizable", "scalable", "rotatable"]
                origin: false,//true, // set true if you wanna see dot in center
                keepRatio: false,
            },
            photoPost: {
                postId: null,
                audioDataId: null,
                userId: firebase.auth.currentUser.uid,
                username: firebase.auth.currentUser.displayName,
                userProfilePhoto: firebase.auth.currentUser.photoURL,
                photoURL: null,
                title: "",
                description: "",
                date: new Date(),
                type: null,
                tags: [],
                thumbnail: null,
                group: null,
                // isPublicPost: false,
                canContribute: true,
                languageSelected: {
                    text: "English (United States)",
                    value: "en-US",
                },
                imageRect: {
                    height: '',
                    width: '',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    x: '',
                    y: '',
                },
            },
            newPhoto: {},
            imageText: {
                text: "",
                date: new Date(),
                languageSelected: {
                    text: "English (United States)",
                    value: "en-US",
                },
                recordingTime: 0,// Saved in milliseconds
                audioURL: null,
                segment: 0,
                caption: "",
                rect: {},
                style: {
                    transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                    fontSize: "2",
                    textColor: "#ffffff",
                },
                showMoreText: false,
                showMoreOptions: false,
            },
            selectedText: {},
            textAdded: [],
            showAllText: false,
            noText: false,
            titleRules: [(v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be 50 characters or less",],
            radioGroupRules: [(v) => !!v || "Must select either public or group"],
            radioGroup: null,
            alert: false,
            alertIsDialog: false,
            alertIsSnackbar: false,
            alertType: "",
            alertTitle: "",
            alertText: "",
            alertAction: "",
        };
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        userGroups(){
            return this.$store.state.userGroups;
        },
        reverseTextAdded: function() {
            return this.textAdded.slice().reverse();
        },
    },
    methods: {
        next: function () {
            if(this.isFormValid) {
                this.stepOneComplete = true;
                if(this.radioGroup === "original") {
                    this.audioData.text.forEach((text) => {
                        // console.log("TEXT: ",text);
                        // console.log("AUDIO.TEXT[0]: ", this.audioData.text[0]);
                        var data = {
                            text: text.text,
                            style: text.style,
                            rect: text.rect,
                            segment: text.segment,
                            audioURL: null,
                            recordingTime: 0,
                            caption: "",
                            languageSelected: this.userData.languageSelected,
                            showMoreText: text == this.audioData.text[0/*this.audioData.text.length - 1*/] ? true : false,
                        }
                        this.textAdded.push(data);
                    })
                    // console.log(this.textAdded);
                    // console.log(this.reverseTextAdded);
                    this.selectedText = this.textAdded[0];
                    // this.imageLoaded();
                } else {
                    this.selectedText = this.imageText;
                }
            }
        },
        previous: function () {
            this.stepOneComplete = false;
            this.textAdded = [],
            this.selectedText = this.imageText;
            this.showAllText = false;
        },
        beforeDone: function () {
            this.hasChanged = false;
            if(this.imageText.text != ""){
                this.hasChanged = true;
                this.doneDialog();
                return;
            }
            for(var i = 0; i < this.textAdded.length; i++){
                if (this.radioGroup === "new") {
                    if(this.imageText.text != "" || this.textAdded[i].text != ""){
                        this.hasChanged = true;
                        this.doneDialog();
                        break;
                    }
                } else{
                    if(this.textAdded[i].caption != "" || this.textAdded[i].audioURL != null){
                        this.hasChanged = true;
                        this.doneDialog();
                        break;
                    }
                }
            }
            if(!this.hasChanged){
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = `${this.radioGroup == "original" ? "You must add to a text before you can save" : "Title must be included to continue"}`;
            }
        },
        doneDialog: function () {
            this.alert = true;
            this.alertIsDialog = true;
            this.alertAction = "done";
            this.alertType = "dialog";
            this.alertTitle = "Finish and save work?";
            this.alertText = "Are you sure you want to continue?";
        },
        done: async function () {
            if(this.radioGroup === "original") {
                // console.log(this.textAdded);
                // saveAudioData
                this.saveAudioData();
            } else {
                if (this.imageText.text != "" && this.imageText.text.length <= 30) {
                    await this.addTextAsync();
                }
                // console.log(this.textAdded);
                // saveAudioData
                await this.imageLoaded();
                this.saveAudioData();
            }
        },
        createTextObjects: function () {
            return new Promise((resolve) => {
                var objects = [];
                this.textAdded.forEach((text) => {
                    var object = {
                        text: text.text,
                        date: new Date(),
                        languageSelected: text.languageSelected,
                        recordingTime: text.recordingTime,
                        audioURL: text.audioURL,
                        segment: text.segment,
                        caption: text.caption,
                        rect: text.rect,
                        style: text.style,
                    };
                    objects.push(object);
                });
                resolve(objects);
            });
        },
        saveAudioData: async function () {
            console.log("Audio Data ID: ", this.newPhoto.audioDataId);
            // console.log("newPhoto: ", this.newPhoto);
            // console.log("postData: ", this.postData);
            // console.log("photoPost: ",this.photoPost);
            var textAdded = await this.createTextObjects();
            // console.log("textAdded: ", textAdded);
            var newAudioRef = firebase.db.collection("audioSegments").doc(this.newPhoto.audioDataId);
            // text objects and info we need saved for the audioSegments to display correctly
            newAudioRef.set({
                title: this.newPhoto.title,
                userId: this.photoPost.userId,
                username: this.photoPost.username,
                userProfilePhoto: this.photoPost.userProfilePhoto,
                postId: this.postData.postId,
                imageRect: this.newImageRect,
                text: textAdded,
                date: new Date(),
                languageSelected: this.newPhoto.languageSelected,
                audioDataId: this.newPhoto.audioDataId,
            }).then(() => {
                this.saveContributionData();
            }).catch((error) => {
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = "Something went wrong." + error;
            });
        },
        saveContributionData: async function() {
            let audioDataIdArray = [];
            let newContributionRef = firebase.db.collection(this.postData.postType == "public" ? "publicPosts" : "groupPosts").doc(this.postData.postId).collection("contributions").doc(this.photoPost.userId);
            const doc = await newContributionRef.get();
            if (!doc.exists) {
                // if there aren't any contributions on the post yet
                audioDataIdArray.push(this.newPhoto.audioDataId);
                newContributionRef.set({
                    audioDataId: audioDataIdArray 
                }).then(() => {
                    this.$router.go(-1);
                    // this.$router.replace({
                    // name: "view-photo-post",
                    // query: { id: this.postId, postType: this.postType, gId: this.groupId, post: this.post, audio: this.audio },
                    // });
                });
            } else {
                // the user has already contributed to this post, therefore just add the new audioDataId to the array
                newContributionRef.update({
                    audioDataId: fb.firestore.FieldValue.arrayUnion(this.newPhoto.audioDataId),
                }).then(() => {
                    this.$router.go(-1);
                    // this.$router.replace({
                    // name: "view-photo-post",
                    // query: { id: this.postId, postType: this.postType, gId: this.groupId, post: this.post, audio: this.audio },
                    // });
                });
            }
        },
        homeDialog: function () {
            this.alert = true;
            this.alertIsDialog = true;
            this.alertAction = "home";
            this.alertType = "dialog";
            this.alertTitle = "Your work will be deleted.";
            this.alertText = "Are you sure you want to quit?";
        },
        alertClicked: function (value) {
            if (this.alertAction == "home"){
                if (value) {
                    if(this.textAdded.length>0){
                        this.deleteIncompletePostAudioFilesFromStorage();
                    }
                    this.$router.go(-1);
                }
            } else if (this.alertAction == "done") {
                if (value) {
                    this.done();
                }
            }
            this.alert = false;
            this.alertIsDialog = false;
            this.alertIsSnackbar = false;
            this.alertType = "";
            this.alertTitle = "";
            this.alertText = "";
            this.alertAction = "";
        },
        secondsToTime: function(secs){
            // secs = Math.round(secs);
            secs = secs/1000
            var hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            var seconds = Math.ceil(divisor_for_seconds);
            if(seconds < 10){
                seconds = "0" + seconds;
            }

            var obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
            return obj;
        },
        languageChanged: function () {
            if (this.newPhoto.languageSelected.value != null && this.newPhoto.languageSelected != this.selectedText.languageSelected) {
                this.newPhoto.languageSelected.text = "Multiple Languages";
                this.newPhoto.languageSelected.value = null;
            } 
            // console.log(this.photoPost);
        },
        recordingTime: function () {
            this.recording = !this.recording;
            if (this.selectedText.showMoreOptions) {
                this.selectedText.showMoreOptions = false;
            }
            if(this.recording) {
                this.recorder.start();
                this.timePlaying = 0;
                this.audioTimer = setInterval(() => {
                this.timePlaying = (this.timePlaying + this.INTERVAL);
                }, this.INTERVAL);
            } else {
                this.recorder.stop();
                this.selectedText.recordingTime = this.timePlaying;
                this.timePlaying = 0;
                clearInterval(this.audioTimer);
            }
        },
        pauseRecording: function () {
            this.recorder.pause();
            this.recordingPaused = !this.recordingPaused;
            if(this.recordingPaused) {
                clearInterval(this.audioTimer);
            } else {
                this.recorder.resume();
                this.audioTimer = setInterval(() => {
                this.timePlaying = (this.timePlaying + this.INTERVAL);
                }, this.INTERVAL);
            }
        },
        handleDataAvailable: function (event) {
            if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
                this.uploadAudioRec();
            }
        },
        uploadAudioRec: async function () {
            // console.log("Upload Audio Recording");
            this.uploadingRecording = true;
            var dotCount = 1;
            this.convertingInterval = setInterval(() => {
                if (dotCount > 3) {
                dotCount = 1;
                this.convertingCaption = "Converting   ";
                return;
                } else {
                dotCount++;
                this.convertingCaption = this.convertingCaption.replace(" ", ".");
                return;
                }
            }, 400);
            if (this.selectedText.recordingTime > 500) {
                // console.log("Long enough to continue");
                var blob = new Blob(this.recordedChunks, { type: "audio/webm" });
                var metaData = {
                    contentType: "audio/webm",
                    customMetadata: {
                        duration: this.selectedText.recordingTime,
                        userId: firebase.auth.currentUser.uid,
                        langCode: this.selectedText.languageSelected.value,
                    },
                };
                var storageRef = firebase.storage.ref();
                await storageRef
                    .child(this.audioStorageReference + "/" + this.selectedText.segment)
                    .put(blob, metaData)
                    .catch((err) => {
                        this.alert = true;
                        this.alertIsSnackbar = true;
                        this.alertAction = "snackbar";
                        this.alertType = "snackbar";
                        this.alertText = "Something went wrong. " + err.message;
                    });
            }
            this.clearAudioBuffer();
        },
        clearAudioBuffer: function () {
            // console.log("clearAudioBuffer");
            this.recordedChunks.length = 0;
            this.recordedChunks = [];
        },
        setAudioURL: function (filename, location) {
            // console.log("setAudioURL");
            var storageRef = firebase.storage.ref();
            // storageRef.child(`audioSegments/${this.postData.postId}/${this.postData.audioDataId}/${filename}`)
            // console.log(location);
            // console.log(location, '/', filename);
            storageRef.child(`${location}`).getDownloadURL().then((url) => {
                // console.log(url);
                this.selectedText.audioURL = url;
                this.uploadingRecording = false;
                clearInterval(this.convertingInterval);
            }).catch((error) => {
                // console.log('ref', ref);
                console.log("The error is: ", error.message);
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText =
                    "Oops. Couldn't retrieve your audio segments at this time. Try again." +
                    error.message;
            });
        },
        showAllTheText: function () {
            if(!this.uploadingRecording && !this.recording){
                this.showAllText = !this.showAllText;
                if(this.showAllText === false){
                    this.selectedText = this.imageText;
                    this.showMoreText(false,this.imageText);
                } else {
                    this.selectedText.showMoreText = false;
                    this.imageText.showMoreText = true;
                    this.selectedText = {};
                    // this.showMoreText(false,{},this.reverseTextAdded);
                }
            }
        },
        changeSelectedText: function (text) {
            if(!this.uploadingRecording && !this.recording){
                this.selectedText = text;
                this.showMoreText(false, text, this.reverseTextAdded);
                this.showAllText = false;
                // console.log(text);
            }
        },
        addText: function () {
            if(!this.uploadingRecording) {
                if (this.recording) {
                    this.recordingTime();
                }
                else if (this.imageText.text != "" && this.imageText.text.length <= 30){
                    this.segment++;
                    this.$refs.moveable.updateRect();
                    this.imageText.rect = this.$refs.moveable.getRect();
                    // console.log("moveable getrect(): ", this.imageText.rect);
                    this.textAdded.push(this.imageText);
                    this.imageText =  {
                        text: "",
                        date: new Date(),
                        languageSelected: this.selectedText.languageSelected,
                        recordingTime: 0,
                        audioURL: null,
                        segment: this.segment,
                        caption: "",
                        rect: {},
                        style: {
                            transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                            fontSize: "2",
                            textColor: "#ffffff",
                        },
                        showMoreText: false,
                        showMoreOptions: false,
                    }
                    this.selectedText = this.imageText;
                    // console.log(this.textAdded);
                } else {
                    this.noText = true;
                }
            }
        },
        addTextAsync: async function () {
            return new Promise((resolve) => {
                this.segment++;
                this.$refs.moveable.updateRect();
                this.imageText.rect = this.$refs.moveable.getRect();
                this.textAdded.push(this.imageText);
                this.imageText =  {
                    text: "",
                    date: new Date(),
                    languageSelected: this.selectedText.languageSelected,
                    recordingTime: 0,
                    audioURL: null,
                    segment: this.segment,
                    caption: "",
                    rect: {},
                    style: {
                        transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                        fontSize: "2",
                        textColor: "#ffffff",
                    },
                    showMoreText: false,
                    showMoreOptions: false,
                }
                this.selectedText = this.imageText;
                // console.log(this.textAdded);
                resolve(true);
            });
        },
        showMoreText: function (top, text){
            if(this.showAllText === true) {
                this.showAllText = false;
            }
            //true == top(imageText), false == bottom(addedText)
            if(!this.recording && !this.uploadingRecording) {
                if(top && !text.showMoreText) {
                    // if the top text box is already open
                    this.selectedText = this.imageText;
                } else if (top && text.showMoreText) {
                    // if top text box is closed, another is open and must be closed before reopening top text box
                    this.reverseTextAdded.forEach((text)=>{
                        if(text.showMoreText) {
                            text.showMoreText = !text.showMoreText;
                        }
                    });
                    this.imageText.showMoreText = false;
                    this.selectedText = this.imageText;
                } else {
                    // bottom box is clicked
                    if(!text.showMoreText) {
                        // closed bottom text box is clicked. Check all other text boxes and close if open.
                        this.reverseTextAdded.forEach((text)=>{
                            if(text.showMoreText) {
                                text.showMoreText = !text.showMoreText;
                            }
                        });
                        // then open clicked text box, close top text box, set selectedText to clicked text box
                        text.showMoreText = !text.showMoreText;
                        this.imageText.showMoreText = true;
                        this.selectedText = text;
                    } else {
                        // open bottom text box is clicked.
                        // close text box, open top text box, set selectedText to top text box
                        text.showMoreText = !text.showMoreText;
                        this.imageText.showMoreText = false;
                        this.selectedText = this.imageText;
                    }
                }
            }
        },
        deleteRecording: function (text) {
            // console.log(text);
            // console.log(this.selectedText.audioURL);
            var storageRef = firebase.storage.ref("audioSegments");
            var audioRef = storageRef.child(
                `/${this.postId}/${this.newPhoto.audioDataId}/${text.segment}`
            );
            audioRef
                .delete()
                .then(() => {
                    // this.$emit("audioData", this.audioData);
                    // console.log("Deleted");
                }) //end of .then()
                .catch((err) => {
                    // alert("Error deleting audio segment", err);
                    console.log(err.message);
                    this.alert = true;
                    this.alertIsSnackbar = true;
                    this.alertAction = "snackbar";
                    this.alertType = "snackbar";
                    this.alertText = "Something went wrong." + err;
                });
            // 
            // go delete the local list of audioData
            // 
            this.selectedText.audioURL = null;
            this.selectedText.caption = "";
            this.selectedText.recordingTime = 0;
        },
        deleteTextAdded: function (/*text,*/ index) {
            // console.log(this.textAdded[(this.textAdded.length-1) - index].segment);
            if (this.textAdded[(this.textAdded.length-1) - index].audioURL != null) {
                this.deleteRecording(this.textAdded[(this.textAdded.length-1) - index])
            }
            this.textAdded.splice(((this.textAdded.length-1) - index), 1);
            this.imageText.showMoreText = false;
            this.selectedText = this.imageText;
        },
        deleteIncompletePostAudioFilesFromStorage: function () {
            // delete the audiofile from audioSegments/postId/audioDataId
            let audioDataRef = firebase.storage.ref(
                `audioSegments/${this.postId}/${this.newPhoto.audioDataId}`
            );
            audioDataRef.list().then((res) => {
                res.items.forEach(function (itemRef) {
                /* retrieve the file names of each segment, so that we can delete each file
                    individually from the storage bucket. GCS doesn't allow us to delete the full bucket */

                let filename = itemRef.name;
                let audioRef = audioDataRef.child(filename);
                audioRef
                    .delete()
                    .then(() => {
                    return;
                    })
                    .catch(function (error) {
                    console.error(
                        `Error Deleting Audio Segment File ${filename} from Storage:`,
                        error
                    );
                    return;
                    });
                });
            });
        },
        visualize: function (stream) {
            if(!this.audioCtx) {
                this.audioCtx = new AudioContext();
            }

            var source = this.audioCtx.createMediaStreamSource(stream);

            this.analyser = this.audioCtx.createAnalyser();
            this.analyser.fftSize = 2048;
            this.bufferLength = this.analyser.frequencyBinCount;
            this.dataArray = new Uint8Array(this.bufferLength);

            source.connect(this.analyser);
            // this.analyser.connect(this.audioCtx.destination);

            this.draw();
        },
        draw: function () {
            this.canvas = document.querySelector('.visualizer');
            if(this.canvas == null) {
                return;
            } else {
                this.canvasCtx = this.canvas.getContext("2d");
                var WIDTH = this.canvas.width
                var HEIGHT = this.canvas.height;

                requestAnimationFrame(this.draw);

                this.analyser.getByteTimeDomainData(this.dataArray);

                this.canvasCtx.fillStyle = this.$vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'rgb(256, 256, 256)';
                this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

                this.canvasCtx.lineWidth = 2;
                this.canvasCtx.strokeStyle = this.$vuetify.theme.dark ? 'rgb(256, 256, 256)' : 'rgb(28, 30, 30)';

                this.canvasCtx.beginPath();

                let sliceWidth = WIDTH * 1.0 / this.bufferLength;
                let x = 0;


                for(let i = 0; i < this.bufferLength; i++) {
                    let v = this.dataArray[i] / 128.0;
                    let y = v * HEIGHT/2;
                    if(i === 0) {
                        this.canvasCtx.moveTo(x, y);
                    } else {
                        this.canvasCtx.lineTo(x, y);
                    }
                    x += sliceWidth;
                }
                
                this.canvasCtx.lineTo(this.canvas.width, this.canvas.height/2);
                this.canvasCtx.stroke();
            }
        },
        disableMicrophone: function() {
            if(this.recorder) {
                if (this.recorder.stream != undefined) {
                    this.recorder.stream.getTracks().forEach((track) => track.stop());
                }
            }
            delete this.recorder;
        },
        updateRefs: function (userId) {
            this.dbRefs.subtitleRef = firebase.rtDb.ref(`${userId}/subtitle`);
            this.dbRefs.conversionRef = firebase.rtDb.ref(`${userId}/conversion`);
        },
        clearRTDB: function (userId) {
            this.dbRefs.subtitleRef.off();
            this.dbRefs.conversionRef.off();
            const allRefs = firebase.rtDb.ref(userId);
            allRefs.remove();
        },
        handleDrag( {target, transform} ) {
            // console.log(transform);
            // console.log(this.$refs.image);
            // this.selectedText.rect = this.$refs.moveable.getRect();
            // if(this.selectedText.rect.left >= this.$refs.image.$el.offsetLeft){
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log(this.selectedText.style.transform);
        },
        handleRotate({ target, /*dist,*/ transform }) {
            // console.log('onRotate', dist);
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
        },
        handleScale({ target, transform/*, scale*/ }) {
            console.log('handleScale called');
            // console.log('onScale scale', scale);
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
        },
        handleResize({target, width, height, delta,}) {
            // console.log("Width: ", width);
            // console.log('onResize', width, height);
            // console.log('onResize', delta);
            // console.log(target);
            delta[0] && (target.style.width = `${width}px`);
            delta[1] && (target.style.height = `${height}px`);
            this.selectedText.style.width = `${width}px`;
            this.selectedText.style.height = `${height}px`;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log(this.selectedText.rect.width);
        },
        handlePinch({ target }) {
            console.log('onPinch', target);
        },
        // handleWarp({ target, transform }) {
        //     console.log('onWarp', transform);
        //     target.style.transform = transform;
        // },
        isUserPartOfGroup: function(groups) {
            if(groups.length <= 0){
                return false;
            }
            for ( var i = 0; i < groups.length; i++ ){
                if (groups[i].groupId === this.postData.groupId){
                    return true;
                }
            }
            return false;
        },
        getGroupPostData: function () {
            const groups = [...this.userGroups];

            let groupPostRef = firebase.db.collection("groupPosts").doc(this.postId);
            groupPostRef.get().then((doc) => {
                if (!doc.exists) {
                    alert("No post with this ID exists.");
                    this.snackbar = true;
                    this.snackbarText = "This post does not exist.";
                    return;
                }
                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                this.getAudioData(doc.data().audioDataId);
                var proceed = this.isUserPartOfGroup(groups);
                if(!proceed){
                    this.letUserSeePost = false;
                    this.snackbarText = "This post can only be seen members of the group it belongs to.";
                    this.snackbar = true;
                }
            }).catch((err) => {
                // alert("Error getting document: " + err);
                this.snackbar = true;
                this.snackbarText = "Something went wrong." + err;
            });
        },
        getPostData: function () {
            let publicPostRef = firebase.db.collection("publicPosts").doc(this.postId);
            publicPostRef.get().then((doc) => {
                if (!doc.exists) {
                    alert("No post with this ID exists.");
                    this.snackbar = true;
                    this.snackbarText = "No post with this ID exists.";
                    return;
                }
                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                this.getAudioData(doc.data().audioDataId);
            }).catch((err) => {
                alert("Error getting document: " + err);
                this.snackbar = true;
                this.snackbarText = "Something went wrong." + err;
            });
        },
        getAudioData: function (id) {
            let audioRef = firebase.db.collection("audioSegments").doc(id);
            audioRef.get().then((doc) => {
                if (!doc.exists) {
                    return;
                }
                this.audioData = doc.data();
            }).catch((err) => {
                console.error(err);
                this.snackbar = true;
                this.snackbarText = "Something went wrong." + err.message;
            });
        },
        getImageRect: function () {
            return new Promise((resolve) => {
                var rect = {
                    height: '',
                    width: '',
                    top: '',
                    left: '',
                    x: '',
                    y: '',
                };
                // var image = document.getElementById(this.radioGroup == "original" ? "image2" : "image").getBoundingClientRect();
                // console.log("Image: ", image);
                // rect.height = image.height;
                // rect.width = image.width;
                // rect.top = image.top;
                // rect.left = image.left;
                // rect.x = image.x;
                // rect.y = image.y;
                // console.log("Rect: ", rect);
                // resolve(rect);
                var image = document.getElementById(this.radioGroup == "original" ? "image2" : "image");//.getBoundingClientRect();
                console.log("Image: ", image);
                rect.height = image.clientHeight;
                rect.width = image.clientWidth;
                rect.top = image.offsetTop;
                rect.left = image.offsetLeft;
                rect.x = image.offsetLeft;
                rect.y = image.offsetTop;
                console.log("Rect: ", rect);
                resolve(rect);
            });
        },
        imageLoaded: async function () {
            console.log("Image loaded called");
            this.newImageRect = await this.getImageRect();
            console.log("Done get image rect");

            this.textAdded.forEach((text) => {
                var l = (text.rect.left - this.postData.imageRect.left) / this.postData.imageRect.width;
                var t = (text.rect.top - this.postData.imageRect.top) / this.postData.imageRect.height;
                var newLeft = l * this.newImageRect.width;
                var newTop = t * this.newImageRect.height;
                var hp = text.rect.height / this.postData.imageRect.height;
                var wp = text.rect.width / this.postData.imageRect.width;
                var newTextHeight = hp * this.newImageRect.height;
                var newTextWidth = wp * this.newImageRect.width;

                // var values = text.style.transform.split('(')[1];
                //     values = values.split(')')[0];
                //     values = values.split(',');
                // matrix(scaleX(), skewY(), skewX(), scaleY(),translateX(), translateY());
                // var scaleX = values[0];
                // var skewY = values[1];
                // var skewX = values[2];
                // var scaleY = values[3];
                // var translateX = values[4];
                // var translateY = values[5];
                // console.log(scaleX, scaleY, skewY, skewX,/* translateX, translateY*/);
                // text.style.transform = `matrix(${scaleX},${skewY},${skewX},${scaleY},${translateX}px,${translateY}px)`;
                // text.style.transform = `translate(${translateX}, ${translateY}) skew(${skewX}, ${skewY}) scale(${scaleX},${scaleY})`;

                text.style.transform = `translate(${newLeft}px, ${newTop}px)`;// skew(${skewX}, ${skewY}) scale(${scaleX},${scaleY})
                text.style.height = `${newTextHeight}px`;
                text.style.width = `${newTextWidth}px`;
            });
        },
        imageResize: function () {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                // Run code here, resizing has "stopped"
                this.imageLoaded();
            }, 300);
        },
    },
    mounted: function () {
        this.$window.addEventListener("resize", this.imageResize);
        this.canvas = document.querySelector('.visualizer');
        this.canvasCtx = this.canvas.getContext("2d");
        this.updateRefs(firebase.auth.currentUser.uid);
        this.audioStorageReference = `audioSegments/${
            this.postId
          }/${this.newPhoto.audioDataId}`;
        if (this.$navigator.mediaDevices){
            // console.log('getUserMedia supported.');
            var constraints = { audio: true };
            this.$navigator.mediaDevices.getUserMedia( constraints )
            .then((stream) => {
                var options = {
                    mimeType: "audio/webm",
                    audioBitsPerSecond: 128000,
                };
                // console.log("about to call MediaRecorder() in mounted, stream is ready")
                // console.log(MediaRecorder.isTypeSupported('audio/webm'))
                this.recorder = new MediaRecorder(stream, options);
                this.visualize(stream);
                // console.log("the media recorder in mounted: ", this.recorder);
                this.recorder.addEventListener('dataavailable', this.handleDataAvailable);
                // this.recorder.ondataavailable = this.handleDataAvailable;
            })
            .catch((err) => {
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = "Something went wrong. " + err.message;
            });

        } // if the user device allows us to get their media
        
        // REALTIME DB LISTENERS
        this.dbRefs.subtitleRef.on("child_added", (snapshot) => {
                // console.log("snapshot val = ", snapshot.val() );
                // console.log(snapshot.val());
                if (snapshot.val() == null) {
                    return;
                } else {
                    // this.loadingCaption = false;
                    // this.$emit('loadingCaption', false);
                    // clearInterval(this.convertingInterval);
                    this.selectedText.caption = snapshot.val().subtitle;
                }
            }, (err) => {
                console.log(err.code);
                //display an error message
            }
        );

        this.dbRefs.conversionRef.on("child_added", (snapshot) => {
                if (snapshot.val() == null) {
                    return;
                } else {
                    let filename = snapshot.val().filename;
                    let location = snapshot.val().location;
                    /* we need to retrieve the url's for the audioSegments here, because the cloud function converts them to mp4 
                    which will change the the download URL that they originally have when uploaded to storage */
                    this.setAudioURL(filename, location);
                }
            }, (err) => {
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = err.message;
            }
        );
    },
    created: function() {
        this.newPhoto.audioDataId = firebase.db.collection(`audioSegments`).doc().id;
        console.log("audioDataId: ",this.newPhoto.audioDataId);
        this.userData = this.$store.state.userData;
        this.newPhoto.languageSelected = this.userData.languageSelected;
        this.imageText.languageSelected = this.userData.languageSelected;
        this.updateRefs(firebase.auth.currentUser.uid);
        this.clearRTDB(firebase.auth.currentUser.uid);
        this.selectedText = this.imageText;
        // console.log("PostId: ", this.postId);
        // console.log("PostType: ", this.postType);
        // console.log("GroupId: ", this.groupId);
        // console.log("Post: ", this.post);
        // console.log("Audio: ", this.audio);
        if (this.postType == "group") {
            if(typeof this.postData.title === 'undefined'){
                this.getGroupPostData();
            } else{
                var proceed = this.isUserPartOfGroup(this.userGroups);
                if(!proceed){
                    this.letUserSeePost = false;
                    this.snackbarText = "This post can only be seen members of the group it belongs to.";
                    this.snackbar = true;
                }
            }
        } else { // public post
            if(typeof this.postData.title === 'undefined'){
                this.getPostData();
            }
        }
    },
    beforeDestroy: function() {
        this.$window.removeEventListener("resize", this.imageResize);
    },
    destroyed: function() {
        this.disableMicrophone();
    },
}
</script>

<style>
.add-text {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 5px;
    margin-right: 5px;
}
.image {
    position: relative;
    max-height: 85vh;
}
.moveable-control {
    display: none !important;
}
.moveable-line {
    display: none !important;
}
.moveable-line.moveable-rotation-line {
    display: none !important;
}
.moveable {
    display: inline-block !important;
    position: absolute !important;
    cursor: move !important;
    /* border: 1px solid var(--v-primary-base) !important; */
    /* font-family: "Verdana", sans-serif; */
    /* font-size: auto !important;
    width: auto !important;
    text-align: center; */
    top: 50% !important;
    left: 50% !important;
}
.non-moveable {
    /* display: inline-block !important; */
    position: absolute !important;
    cursor: pointer !important;
    /* border: 1px solid var(--v-primary-base) !important; */
    /* font-family: "Verdana", sans-serif; */
    /* font-size: auto !important;
    width: auto !important;
    text-align: center; */
    /* top: 50% !important;
    left: 50% !important; */
}
</style>