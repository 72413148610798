<template>
    <v-row justify="center" align="center" class="ma-0 pa-0 grey darken-4">
        <!-- BELOW ARE SURVEYS WE HAVE USED TO GET FEEDBACK AND FOR MARTYS RESEARCH (COMMENTED OUT ON MARCH 11, 2024) -->
        <!-- <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeJuKCi4f64UnuU40iq3bY6jO5qp580Hit7zzGxK1LfVGw7Eg/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
        <!-- <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfbSZ9S2Sl4KVhF2SVku6H9xtw4DkIyhKg2TLYwi9yXmfsJqA/viewform?embedded=true" width="640" height="2089" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
        <!-- <iframe src="https://unt.az1.qualtrics.com/jfe/form/SV_8dhw7H5chbd9Tgy?Q_CHL=qr" width="100%" height="100%">Loading…</iframe> -->
        <!-- <iframe src="https://docs.google.com/presentation/d/1xiwS__a5TT2vLe9mdBRipVNa3aklIf7LUFSHfwI9JZc/" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
        <!-- <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSBeyeCH6y6hUzA1xChd2GhX_5XnoNoS8wwNFYoEaxylZ3kZkLbprIQJnbrYpP-cf8_4CeCStbwcZay/embed?start=true&loop=true&delayms=5000" frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">Loading…</iframe> -->

        <!-- THE FOLLOWING CODE WAS ADDED ON MARCH 11, 2024 AT MARTYS REQUEST -->
        <v-col cols="12" xl="4" lg="6" md="8" sm="10" align="center">
           <span class="text-h5">تم إغلاق نافذة هذه الدراسة. شكرا لك على استعدادك للمشاركة. لأية أسئلة ، يرجى الاتصال بـ marty على <a href="https://wa.me/14357671177" target="_blank">Whatsapp +1 435 767 1177</a> أو زيارة <a href="https://tawq.in" target="_blank">tawq.in</a> لاستخدام البرنامج.</span><br/><br/><br/>
           <span class="text-h5">The window for this study has closed. Thank you for being willing to participate. For any questions please contact Marty on <a href="https://wa.me/14357671177" target="_blank">Whatsapp +1 435 767 1177</a> or visit <a href="https://tawq.in" target="_blank">tawq.in</a> to use the software.</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'survey',
    metaInfo: {
        title: "Survey - tawqin"
    },
    data: function() {
        return {}
    },
    methods: {},
    created: function(){},
    mounted: function(){},
}
</script>