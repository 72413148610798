<template>
    <div>
        <v-row justify="space-around" align="center" no-gutters class="mx-0">
            <v-col cols="2" align="center" class="my-0 py-2">
                <span class="text-wrap text-subtitle-1 font-weight-black">{{index+1}}.</span>
            </v-col>
            <v-col cols="10" class="my-0 py-2">
                <span class="text-wrap text-subtitle-1 font-weight-black">{{caption.subtitle}}</span>
                <!-- <span class="text-wrap text-subtitle-2 font-weight-black">{{caption.subtitle != "" ? caption.subtitle : 'could not caption'}}</span> -->
            </v-col>
        </v-row>

        <v-row justify="space-around" align="start" no-gutters class="mx-0 pb-2">
            <v-col cols="8" class="my-0">
                <v-row justify="start" no-gutters class="mx-0">
                    <span class="text-caption font-weight-normal pr-1">Start:</span>
                    <span class="text-caption font-weight-light pr-2"><span v-if="hours > 0">{{hours}}h:</span><span v-if="minutes > 0">{{minutes}}m:44</span><span>{{seconds}}s</span></span>
                    <span class="text-caption font-weight-normal pr-1">Duration:</span>
                    <span class="text-caption font-weight-light">{{ audioSeg.audioDur.toFixed(1) }}s</span>
                </v-row>
            </v-col>
            <v-col cols="3" class="my-0">
                <v-row justify="end" no-gutters class="mx-0">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="hidden-md-and-down"
                                icon
                                outlined
                                color="accent"
                                v-on="on"
                                x-small
                                :disabled="videoLoading"
                                @click.native.stop="setAudioSegment()"
                            >
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                            <v-btn
                                class="hidden-lg-and-up"
                                icon
                                outlined
                                color="accent"
                                x-small
                                :disabled="videoLoading"
                                @click.native.stop="setAudioSegment()"
                            >
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                        </template>
                        <span>Play this segment</span>
                    </v-tooltip>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :light="isPostAudioData" :dark="!isPostAudioData && $vuetify.theme.dark"/>
    </div>
</template>

<script>
export default {
    name: 'SegmentAudio',
    props: ["index", "audioSeg", "caption", "userData", "isPostAudioData", "videoLoading"],
    data: function () {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    methods: {
        setAudioSegment: function () {
            var audioObj = {
                audioSeg: this.audioSeg,
                caption: this.caption.subtitle,
                index: this.index,
            };
            this.$emit('setAudioSegment', audioObj);
        },
        secondsToTime: function(secs){
            secs = Math.round(secs);
            this.hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            this.minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            this.seconds = Math.ceil(divisor_for_seconds);
        },
    },
    created: function() {
        this.secondsToTime(this.audioSeg.audioStart);
    },
}
</script>