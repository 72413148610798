<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="closeReport">
        <v-card v-if="!thanks" class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center align-center">
                <span v-if="page==='audioElement'" class="font-weight-bold text-h5 text-center">Report Title, Language, or Audio Content</span>
                <span v-else class="font-weight-bold text-h5 text-center">Report Thumbnail, Title, Description, or Tags</span>
            </v-card-title>

            <v-row justify="center" class="mx-0">
                <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                    <v-radio-group v-model="radioGroup" column>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Sexual or Pornographic Content"
                            value="sexual/pornographic"
                        ></v-radio>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Harmful or Dangerous Acts"
                            value="harmful/dangerous"
                        ></v-radio>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Violent or Graphic Content"
                            value="violent/graphic"
                        ></v-radio>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Spam or Misleading Information"
                            value="spam/misleading"
                        ></v-radio>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Suicide or Self-harm"
                            value="suicide/self-harm"
                        ></v-radio>
                        <v-radio
                            :color="$vuetify.theme.dark ? 'amber' : 'primary'"
                            label="Other"
                            value="other"
                        ></v-radio>
                    </v-radio-group>
                    <v-textarea placeholder="Explain more..." counter="250" outlined v-model="explanation" :rules="explanationRules" @keydown.enter="saveReport"></v-textarea>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeReport">Cancel</v-btn>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="saveReport" :disabled="explanation.length > 250 || radioGroup == ''">Save</v-btn>
                </v-row>
            </v-card-actions>

        </v-card>
        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center align-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Your report has been submitted</span>
            </v-card-title>

            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-light text-subtitle-1 mt-3">Thanks for helping keep our community safe! <span class="font-weight-bold"></span></span><br/>
                    <span class="font-weight-light text-subtitle-1 mt-3">If you have any questions feel free to email us at </span><br/>
                    <span @click.stop="emailKeep()" style="cursor:pointer;" class="text-subtitle-1 font-weight-bold text-decoration-underline primary--text">keep@tawq.in</span>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeReport">Close</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: "report",
    props: ["dialog", "page", "post"],
    data: function() {
        return {
            radioGroup: "",
            explanation: "",
            thanks: false,
            thanksTimeout: null,
            explanationRules: [
                (v) => v.length <= 250 || "Explanation must be 250 characters or less",
            ],
        };
    },
    methods: {
        saveReportTest: function () {
            console.log(this.explanation);
        },
        saveReport: function () {
            const url = this.getPostUrl();
            var reportRef = firebase.db.collection("reports").doc();
            reportRef.set({
                reportingUserId: firebase.auth.currentUser.uid,
                reportingUsername: firebase.auth.currentUser.displayName,
                reportDate: new Date(),
                reason: this.radioGroup,
                audioDataId: this.page === 'audioElement' ? this.post.audioDataId : this.page === 'audioData' ? this.post.audioData.audioDataId : null,
                postId: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.postId : this.post.audioData.postId,
                postDate: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.postDate : this.post.audioData.date,
                postTitle: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.title : this.post.audioData.title,
                postThumbnail: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.thumbnail : null,
                postDescription: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.description : null,
                postTags: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.tags : null,
                postUserId: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.userId : this.post.audioData.userId,
                postUsername: (this.page != 'audioElement' && this.page != 'audioData') ? this.post.username : this.post.audioData.username,
                explanation: this.explanation,
                url: url,
            }, {merge: true}).then(() => {
                this.thanks = true;
                this.thanksTimeout = setTimeout(() =>{
                    this.$emit('close-report');
                    this.thanks = false;
                    this.radioGroup = "";
                    this.explanation = "";
                }, 3000);
            });
        },
        closeReport: function () {
            clearTimeout(this.thanksTimeout);
            this.thanksTimeout = null;
            this.thanks = false;
            this.radioGroup = "";
            this.explanation = "";
            this.$emit('close-report')
        },
        getPostUrl: function () {
            var url;
            // console.log("route: ", `https://tawq.in${this.$route.path}?pid=${this.$route.query.pid}&postType=${this.$route.query.postType}`);
            switch (this.page) {
                case 'home':
                case 'groups':
                case 'profile': {
                    url = `https://tawq.in/view-post?pid=${this.post.postId}&postType=${this.post.postType}`
                    break;
                }

                case 'viewPost': {
                    if (this.$route.hash){
                        url = `https://tawq.in${this.$route.path}?pid=${this.$route.query.pid}&postType=${this.$route.query.postType}`;
                    } else {
                        url = `https://tawq.in${this.$route.fullPath}`;
                    }
                    break;
                }

                case 'audioElement': {
                    var audioId =  this.post.audioDataId;
                    if (this.$route.hash){
                        var hash = this.$route.hash.substring(1);
                        if (hash === audioId) {
                            url = `https://tawq.in${this.$route.fullPath}`;
                        } else {
                            url = `https://tawq.in${this.$route.path}?pid=${this.$route.query.pid}&postType=${this.$route.query.postType}&aid${audioId}`;
                        }
                    } else {
                        url = `https://tawq.in${this.$route.path}?pid=${this.$route.query.pid}&postType=${this.$route.query.postType}&aid${audioId}`;
                    }
                    break;
                }

                case 'viewPhotoPost': {
                    url = `https://tawq.in${this.$route.fullPath}`
                    break;
                }

                case 'audioData': {
                    url = `https://tawq.in${this.$route.fullPath}`
                    break;
                }

                case 'default': {
                    url = `https://tawq.in${this.$route.fullPath}`
                    break;
                }
            } // end of url switch
            return url;
        },
        emailKeep: async function(){
            const url = this.getPostUrl();
            var domain = this.userEmail.split('@')[1];
            
            const message = `URL of reporting post: ${'%0D%0A'} ${encodeURIComponent(url)}`;
            const subject = encodeURIComponent(this.page === 'audioElement' ? `Reporting Audio - ${this.post.audioData.title}` : `Reporting Post - ${this.post.title}`);
            switch(domain){
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com': {
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                }

                default: {
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                }
            } // end of email/gmail switch
        },
    },
    computed: {
        userEmail () {
            return this.$store.state.userData.email;
        },
    },
}
</script>