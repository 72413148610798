<template>
   <v-row class="justify-center justify-xl-start">
        <v-col v-for="invoice in invoices.data" :key="invoice.id"  cols="12" xl="3" lg="5" md="6" sm="6" xs="10" >
            <v-sheet outlined class="px-6 pb-4 pt-2 elevation-1">

                <div v-if="invoice.lines.data.length > 0" class="text-center font-weight-medium text-subtitle-2">{{invoice.lines.data[0].description}}</div>
                <v-row justify="space-between" class="mt-4">
                    <div class="text-caption">Paid</div>
                    <!-- <div class="text-caption">{{statusConversion(invoice.status)}}</div> -->
                    <v-icon small :class="[invoice.paid ? 'primary--text' : 'red--text text-lighten-2']">{{invoice.paid ? 'mdi-check' : 'mdi-close'}}</v-icon>
                </v-row>
                <v-row justify="space-between">
                    <div class="text-caption">Start</div>
                    <div class="text-caption">{{dateConversion(invoice.lines.data[0].period.start)}}</div>
                </v-row>
                <v-row justify="space-between" >
                    <div class="text-caption">End</div>
                    <div class="text-caption">{{dateConversion(invoice.lines.data[0].period.end)}}</div>
                </v-row>
                <v-row justify="space-between" >
                    <div class="text-caption">Amount</div>
                    <div class="text-caption red--text text--lighten-1"  v-if="invoice.lines.data[0].amount >= 0">-${{invoice.lines.data[0].amount / 100}}</div>
                    <div class="text-caption red--text text--lighten-1" v-else>-${{Math.abs(invoice.lines.data[0].amount) / 100}}</div>
                </v-row>
                <v-row justify="space-between" >
                    <div class="text-caption">Ending Balance on Account</div>
                    <div class="text-caption" :class="[invoice.ending_balance <= 0 ? 'primary--text' : 'red--text text-lighten-2']" v-if="invoice.ending_balance >= 0"><span v-if="invoice.ending_balance > 0">-</span>${{invoice.ending_balance / 100}}</div>
                    <div class="text-caption" :class="[invoice.ending_balance <= 0 ? 'primary--text' : 'red--text text-lighten-2']" v-else>+${{Math.abs(invoice.ending_balance) / 100}}</div>
                </v-row>

                <!-- <v-row justify="center" class="mt-4" >
                    <v-btn v-if="payButton(invoice.status)" small depressed class="primary">Pay</v-btn>
                </v-row> -->

            </v-sheet>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "Invoices",
    props: ["invoices"],
    data: () => {
        return {
        }
    },
    computed: {
        status(invoice) {
           return invoice.status != 'paid' ? 'Unpaid' : 'Paid'
        }
    },
    methods: {
        dateConversion(seconds){
            var date = new Date( seconds * 1000) // javascript's date object is in milliseconds, not seconds. Stripe sends us seconds since unix epoch.
            // return `${date.getMonth() + 1}/${date.getFullYear()}`
            date = date.toLocaleString().split(',')[0];
            if (date === "Invalid Date"){
                date = "---";
            }
            return date.toLocaleString().split(',')[0];
        },
        statusConversion(status){
            
            return status != 'paid' ? 'Unpaid' : 'Paid'
        },
        payButton(status){
            if(status != 'paid'){
                return true;
            }
            return false;
        }
    }
}
</script>
<style>
    
</style>