<template>
    <div :class="isMobile ? 'mt-15' : 'mt-10'">
        <v-app-bar app flat fixed style="max-width: 100%; z-index: 9;" class="primary elevation-1">
            <v-col cols="4" class="ma-0">
                <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="scrollToContent('header')"></v-img>
            </v-col>
            <v-spacer/>
            <v-col cols="8" align="end" class="ma-0 pa-0">
                <v-btn color="white" outlined class="mr-2 font-weight-normal" @click.stop="buttonClick('login')">Login</v-btn>
                <v-btn color="white" outlined class="font-weight-normal" @click.stop="buttonClick('appbar')">Sign Up</v-btn>
            </v-col>
        </v-app-bar>
        <v-row justify="center">
            <v-col cols="12" class="ma-0 pa-0">

                <div style="" :class="isMobile ? 'main-header-mobile': 'main-header'" class="ma-0 pa-0 d-flex flex-column">
                    <v-row justify="center" align="center" :class="isMobile ? 'ma-0 pb-0 pt-2' : 'ma-0 px-0 pb-0 pt-10'">
                        <v-divider class="primary"/>
                        <v-col cols="10" md="6" align="center" class="ma-0 pa-0">
                            <span class="text-h3 text-md-h1 font-weight-medium">About Us</span>
                        </v-col>
                        <v-divider class="primary"/>
                    </v-row>

                    <v-row justify="center" align="center" style="height: 50vh;" class="ma-0 pa-0 hidden-sm-and-down">
                        <v-col cols="6" align="center" class="ma-0 pa-5">
                            <!-- <span class="text-h4 text-md-h3 font-weight-normal">We are <span class="text-decoration-underline font-weight-normal">tawq.in</span></span><br/><br/> -->
                            <span class="text-h4 text-md-h3 font-weight-normal">At <span class="font-weight-normal primary--text">tawq.in</span> <span class="text-decoration-underline font-weight-black">YOU</span> are Family!</span><br/><br/>
                            <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to their cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </p>
                            <!-- <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to the cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </p> -->
                            <!-- <span class="white--text font-italic font-weight-normal text-subtitle-1">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to the cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </span> -->
                        </v-col>
                        <v-col cols="6" align="center" class="ma-0 pa-10 fill-height">
                            <v-carousel height="100%" hide-delimiter-background cycle interval="3500" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom">
                                <v-carousel-item
                                    v-for="(photo, i) in sectionZeroCarouselPhotos"
                                    :key="i"
                                    :src="photo.photoURL"
                                    contain
                                    class="pa-2"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" style="height: 60vh;" class="ma-0 pa-0 hidden-md-and-up">
                        <v-col cols="12" align="center" class="ma-0 px-0 pt-0 pb-0 fill-height">
                            <!-- <span class="text-h4 text-md-h3 font-weight-normal">At <span class="font-weight-normal primary--text">tawq.in</span> <span class="text-decoration-underline font-weight-black">YOU</span> are Family!</span><br/><br/> -->
                            <!-- <p class="white--text font-italic font-weight-normal text-subtitle-1 text-md-h6 px-md-10">We are dedicated to preserving unwritten languages and empowering communities by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to the cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life. </p> -->
                            <v-carousel height="100%" hide-delimiter-background cycle interval="3500" style="border: 2px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom">
                                <v-carousel-item
                                    v-for="(photo, i) in sectionZeroCarouselPhotos"
                                    :key="i"
                                    :src="photo.photoURL"
                                    contain
                                    class="pa-2"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-col>
                    </v-row>
                </div>

                <v-divider class="primary hidden-sm-and-down"/>

                <v-row justify="center" align="center" class="ma-0 pa-md-0 px-md-0 px-1 py-3">
                    <v-col cols="3" align="center" class="hidden-sm-and-down">
                        <div class="hib-htb-light-up" @click.stop="scrollToContent('section-1')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn">
                                <v-icon size="100">mdi-crowd</v-icon>
                            </v-btn>
                            <div class="text-h4 text-center hib-htb-light-up" style="word-break: normal; cursor: pointer;">Meet Our Team</div>
                        </div>
                    </v-col>
                    <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-1')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-crowd</v-icon>
                            </v-btn>
                            <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Team</div>
                        </div>
                    </v-col>

                    <v-col cols="3" align="center" class="hidden-sm-and-down">
                        <div class="hib-htb-light-up" @click.stop="scrollToContent('section-2')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn">
                                <v-icon size="100">mdi-book-open-page-variant</v-icon>
                            </v-btn>
                            <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;">Learn Our Story</div>
                        </div>
                    </v-col>
                    <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-2')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-book-open-page-variant</v-icon>
                            </v-btn>
                            <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Story</div>
                        </div>
                    </v-col>

                    <v-col cols="3" align="center" class="hidden-sm-and-down">
                        <div class="hib-htb-light-up" @click.stop="scrollToContent('section-3')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn">
                                <v-icon size="100">mdi-hand-heart</v-icon>
                            </v-btn>
                            <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;" @click.stop="scrollToContent('section-3')">Who Inspirated Us?</div>
                        </div>
                    </v-col>
                    <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-3-mobile')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-hand-heart</v-icon>
                            </v-btn>
                            <div class="ml-n3 mr-n3 mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Inspiration</div>
                        </div>
                    </v-col>

                    <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-4')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-bullseye-arrow</v-icon>
                            </v-btn>
                            <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Goal</div>
                        </div>
                    </v-col>

                    <v-col cols="3" align="center" class="hidden-sm-and-down">
                        <div class="hib-htb-light-up" @click.stop="scrollToContent('section-5')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn">
                                <v-icon size="100">mdi-eye</v-icon>
                            </v-btn>
                            <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;">See Our Vision</div>
                        </div>
                    </v-col>
                    <v-col cols="2.4" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-5')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-eye</v-icon>
                            </v-btn>
                            <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Vision</div>
                        </div>
                    </v-col>

                    <!-- <v-col cols="2.4" align="center" class="hidden-sm-and-down">
                        <div class="hib-htb-light-up" @click.stop="scrollToContent('section-6')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn">
                                <v-icon size="100">mdi-multicast</v-icon>
                            </v-btn>
                            <div class="text-h4 text-center" style="word-break: normal; cursor: pointer;">Why We're Different</div>
                        </div>
                    </v-col>
                    <v-col cols="2" align="center" class="mt-0 hidden-md-and-up">
                        <div class="hib-htb" @click.stop="scrollToContent('section-6')">
                            <v-btn icon :ripple="false" color="white" class="hib-btn-sm">
                                <v-icon size="50">mdi-multicast</v-icon>
                            </v-btn>
                            <div class="mt-2 text-subtitle-2 text-center" style="word-break: normal; cursor: pointer;">Our Difference</div>
                        </div>
                    </v-col> -->
                </v-row>

                <v-divider class="primary"/>

                <v-row v-if="!isMobile" id="section-1" justify="center" align="center" class="ma-0 pa-0" :class="isMobile ? 'header-mobile' : 'header'">
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-10 order-1 order-sm-2">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div>
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black">{{ currentSectionOneData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold">{{ currentSectionOneData.subtitle }}</p>
                                <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph1 }}</p>
                                <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph2 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-10 fill-height order-2 order-sm-1">
                        <v-lazy height="100%" :options="{ threshold: 0.5 }">
                            <v-carousel height="100%" hide-delimiter-background cycle interval="15000" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom" @change="sectionOneCarouselPhotoChanged">
                                <v-carousel-item
                                    v-for="(photo, index) in sectionOneCarouselPhotos"
                                    :key="index"
                                    :src="photo.photoURL"
                                    contain
                                    class="pa-2 section-1-carousel-image"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-lazy>
                    </v-col>
                </v-row>
                <v-row v-else id="section-1" justify="center" align="end" no-gutters class="ma-0 pa-0" :class="isMobile ? 'header-mobile' : 'header'">
                    <!-- :style="sectionOneBackgroundStyle" -->
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-5 order-1">
                        <v-lazy :options="{ threshold: 0 }">
                            <div>
                                <!-- class="overlay" -->
                                <p class="text-lg-h3 text-md-h4 text-h6 accent--text font-weight-black">{{ currentSectionOneData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold">{{ currentSectionOneData.subtitle }}</p>
                                <p class="text-lg-subtitle-1 text-md-subtitle-1 text-caption font-weight-medium">{{ currentSectionOneData.paragraph1 }}</p>
                                <!-- <p class="text-lg-subtitle-1 text-md-subtitle-1 text-subtitle-2 font-weight-medium">{{ currentSectionOneData.paragraph2 }}</p> -->
                            </div>
                        </v-lazy>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" align="center" class="ma-0 pa-0 order-2">
                        <v-lazy height="100%" :options="{ threshold: 0 }">
                            <v-carousel height="350px" hide-delimiter-background cycle interval="15000" style="border: 3px solid var(--v-primary-base); border-radius: 10px 10px 10px;" class="custom" @change="sectionOneCarouselPhotoChanged">
                                <v-carousel-item
                                    v-for="(photo, index) in sectionOneCarouselPhotos"
                                    :key="index"
                                    :src="photo.photoURL"
                                    contain
                                    class="pa-2 section-1-carousel-image"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-lazy>
                    </v-col>
                </v-row>

                <v-divider class="primary"/>

                <v-row v-if="!isMobile" id="section-2" justify="center" align="center" :style="sectionTwoBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                    <v-col cols="12" lg="10" md="10"  align="center" class="ma-0 pa-5 pa-md-10 order-1 order-sm-2">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionTwoData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold stb">{{ sectionTwoData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>
                <v-row v-else id="section-2" justify="center" align="center" :style="sectionTwoMobileBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                    <v-col cols="12" lg="10" md="10"  align="center" class="ma-0 pa-5 pa-md-10 order-1 order-sm-2">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionTwoData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-2 font-weight-bold stb">{{ sectionTwoData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionTwoData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>

                <v-divider class="primary"/>

                <v-row v-if="!isMobile" id="section-3" justify="center" align="center" class="ma-0 pa-5" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                    <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-1 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black s3tb">{{ sectionThreeData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold s3tb">{{ sectionThreeData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb s3pb">{{ sectionThreeData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb s3pb">{{ sectionThreeData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb s3pb">{{ sectionThreeData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                    <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-0 fill-height fill-width order-2 section-3-image-grid-container">
                        <div class="ma-0 pa-0 section-3-image-grid">
                            <v-img v-for="(photo, index) in sectionThreePhotos" :key="index" :src="photo.photoURL" class="section-3-image-item" />
                        </div>
                    </v-col>
                </v-row>
                <v-row v-else id="section-3-mobile" justify="center" align="center" class="ma-0 pa-5" :style="sectionThreeMobileBackgroundStyle" :class="['ma-0', 'pa-0', isMobile ? 'header-mobile' : 'header']">
                    <v-col cols="12" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-1 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionThreeData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionThreeData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionThreeData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionThreeData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionThreeData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>

                <v-divider class="primary"/>

                <v-row id="section-4" justify="center" align="center" :style="sectionFourMobileBackgroundStyle" class="ma-0 pa-5 hidden-md-and-up header-mobile">
                    <v-col cols="10" align="center" class="ma-0 pa-0 overlay">
                        <!-- <span class="text-h4 text-md-h3 font-weight-normal">At <span class="font-weight-normal primary--text">tawq.in</span> <span class="text-decoration-underline font-weight-black">YOU</span> are Family!</span><br/><br/> -->
                        <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionFourData.title }}</p>
                        <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionFourData.subtitle }}</p>
                        <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFourData.paragraph1 }}</p>
                        <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFourData.paragraph2 }}</p>
                        <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFourData.paragraph3 }}</p>
                    </v-col>
                </v-row>

                <v-divider class="primary"/>

                <v-row v-if="!isMobile" id="section-5" justify="center" align="center" class="ma-0 pa-5" :style="sectionFiveBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                    <v-col cols="12" lg="10" md="10" sm="10" align="center" class="ma-0 pa-0 order-2 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionFiveData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionFiveData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFiveData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFiveData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionFiveData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>
                <v-row v-else id="section-5" justify="center" align="center" class="ma-0 pa-2" :style="sectionFiveMobileBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                    <v-col cols="12" lg="6" md="6" sm="12" align="center" class="ma-0 pa-3 order-2 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionFiveData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionFiveData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionFiveData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionFiveData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-caption font-weight-medium stb">{{ sectionFiveData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>

                <v-divider class="primary"/>

                <!-- <v-row v-if="!isMobile" id="section-6" justify="center" align="center" class="ma-0 pa-5" :style="sectionSixBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                    <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-2 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionSixData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionSixData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>
                <v-row v-else id="section-6" justify="center" align="center" class="ma-0 pa-5" :style="sectionSixMobileBackgroundStyle" :class="isMobile ? 'header-mobile': 'header'">
                    <v-col cols="10" lg="6" md="6" sm="10" align="center" class="ma-0 pa-10 order-2 ">
                        <v-lazy :options="{ threshold: 0.5 }">
                            <div class="overlay">
                                <p class="text-lg-h3 text-md-h4 text-h5 accent--text font-weight-black stb">{{ sectionSixData.title }}</p>
                                <p class="text-lg-h5 text-md-h6 text-subtitle-1 font-weight-bold stb">{{ sectionSixData.subtitle }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph1 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph2 }}</p>
                                <p class="text-lg-h6 text-md-subtitle-1 text-subtitle-2 font-weight-medium stb">{{ sectionSixData.paragraph3 }}</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>

                <v-divider class="primary"/> -->

            </v-col>
        </v-row>
    </div>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "about",
    metaInfo: {
        title: 'About - tawqin'
    },
    props: [],
    components: {},
    data() {
        return {
            userTheme: null,
            sectionZeroCarouselPhotos: [],
            sectionOneCarouselPhotos: [],
            sectionTwoPhotos: [],
            sectionThreePhotos: [],
            sectionFivePhotos: [],
            sectionSixPhotos: [],

            sectionTwoBackgroundPhoto: [],
            sectionThreeBackgroundPhoto: [],
            sectionFourBackgroundPhoto: [],
            sectionFiveBackgroundPhoto: [],
            sectionSixBackgroundPhoto: [],
            currentSectionOneData: {},
            sectionOneData: [
                {
                    index: 0,
                    title: "Fatima Amer Al-Mashani",
                    subtitle: "Co-Founder",
                    paragraph1: "Fatima has been an essential part of tawq.in. In her own words, 'My goal is to preserve the languages that are not read or written and to contribute to supporting developing societies to use technology and the Internet to facilitate daily life and adapt to all modern challenges.'",
                    paragraph2: "",
                },
                {
                    index: 1,
                    // title: "marty heaton",
                    // subtitle: "Founder & The brains behind tawq.in!",
                    // paragraph1: "marty is the reason tawq.in exists. He came up with the idea while with friends in Oman.",
                    // paragraph2: "",
                    title: "marty heaton",
                    subtitle: "Founder & The Brains Behind tawq.in",
                    paragraph1: "marty is the reason tawq.in exists. The idea came to him while visiting friends in Oman. As he spent time with their families, he realized that unwritten languages like Hakli were at risk of being lost between generations. This sparked the vision for a platform that could bridge the gap between elders and younger generations, allowing stories, culture, and languages to be preserved.",
                    paragraph2: "marty is responsible for the foundational vision of tawq.in and how it came to life. He has a knack for bringing together the right people and resources to make his dreams a reality. By collaborating with Scott and Dillan, he ensured that tawq.in is not just functional, but also easy to use, enabling people from all walks of life to tell their stories and share their voices.",
                    paragraph3: "In his free time, marty is always thinking of new ways to improve tawq.in, ensuring that it remains a valuable resource for those who want to share their heritage and learn from others. Whether it's through adding new features or engaging with the community, he’s dedicated to making the platform an essential tool for anyone passionate about language, storytelling, and connection.",
                },
                {
                    index: 2,
                    // title: "Scott Brady",
                    // subtitle: "Lead Software Developer and Co-Founder",
                    // paragraph1: "Scott has been working on tawq.in for 5 years and has enjoyed seeing how it can change the lives of others. He is responsible for much of what you see on screen and how you interact with the website. He loves to add new features that make the experience using tawq.in more fun!",
                    // paragraph2: "In his spare time Scott is an assistant Cheerleading Coach at Utah Tech Universtity in Saint George, Utah, and has been for 4 years. He loves watching his team perform, compete and grow! He enjoys being outside, hanging out with his friends, exercising, a good meal and playing video games.",
                    title: "Scott Brady",
                    subtitle: "Lead Software Developer & Co-Founder",
                    paragraph1: "Scott has been dedicated to developing tawq.in for the past five years, driven by the impact it can have on people's lives. As the lead software developer, he’s responsible for much of the interface you see and the way users interact with the platform. His passion lies in continually adding new features to make the tawq.in experience more engaging and enjoyable.",
                    paragraph2: "When he's not immersed in coding, Scott serves as an assistant cheerleading coach at Utah Tech University in St. George, Utah—a role he’s held for four years. He finds joy in watching his team perform, compete, and grow. Beyond cheer, Scott loves spending time outdoors, hanging out with friends, staying active, enjoying great food, and indulging in video games.",
                },
                {
                    index: 3,
                    title: "Dillan Johnson",
                    subtitle: "Former Co-Lead Software Developer & Co-Founder",
                    paragraph1: "Dillan worked with tawq.in for 3 years. He is currently working for a company based out of St. George, Utah called, TCN. His ideas and work were essential in making tawq.in what it is today.",
                    paragraph2: "In his free time Dillan enjoy spending time with in girlfriend Kynz and his dog Kena. His mental and physical health is very important to him. He is an incredible photographer and loves to share his photos and experiences.",
                },
            ],
            sectionTwoData: {
                // title: "The Birth of tawq.in",
                // subtitle: "Began over 15 years ago, in 2009, on the shores of the Indian Ocean.",
                // paragraph1: "When our founder, marty, took his first trip to the Middle East to visit some friends, he became intrigued by an unwritten language he had never heard of, Hakli, which is spoken in Southern Oman. Since there are limited tools for the learning of unwritten languages, marty began to imagine an easy to use technology that could, not only help him learn Hakli, but allow his multilingual friends to create content and share it with him.",
                // paragraph2: "To make his vision into a reality, marty reached out to the local university in Saint George, Utah and found two young software developers, Scott and Dillan. To quote marty, 'After patiently listen to many requests', both Scott and Dillan carefully started coding the software. After dozens of meetings, redesigns and more meetings, tawq.in was published to the web and became usable for all!",
                // paragraph3: "This easy to use software gives anyone the ability to add their own voice to an audio or video file. Whether you are a translator, teacher, coach, or just want to tell your story, tawq.in is the place for you.",
                // paragraph4: "",
                title: "The Birth of tawq.in",
                subtitle: "The journey began over 15 years ago, in 2009, on the shores of the Indian Ocean.",
                paragraph1: "During his first trip to the Middle East to visit friends, our founder, marty, discovered an unwritten language he had never encountered before—Jibbali, spoken by the Hakli tribe in Southern Oman. Fascinated by this language and recognizing the lack of resources available to learn it, marty envisioned a user-friendly technology that would not only help him learn Jibbali but also empower his multilingual friends to create and share content in their own languages.",
                paragraph2: "Determined to bring his vision to life, marty reached out to the local university in Saint George, Utah, where he found two talented software developers, Scott and Dillan. In arty's words, 'After patiently listening to countless requests,' Scott and Dillan diligently began coding. Following numerous meetings, redesigns, and even more brainstorming sessions, tawq.in was finally launched, making it accessible to everyone.",
                paragraph3: "This intuitive software allows users to easily add their own voice to audio or video files. Whether you're a translator, educator, coach, or simply someone with a story to tell, tawq.in is the platform for you.",
            },
            sectionThreeData: {
                title: "Inspired by Grandmothers in Southern Oman!",
                subtitle: "Preserving Stories, Bridging Generations",
                paragraph1: "tawq.in was born from the realization that language should not be a barrier between generations. By empowering grandmothers to share their stories in their native tongue and enabling their children to translate them, the platform bridges the gap between the past and the present.",
                paragraph2: "The stories of these elders are not just words—they are memories, traditions, and wisdom. tawq.in gives them the opportunity to pass on their rich cultural heritage to the next generation, in a language they understand.",
                paragraph3: "",
                paragraph4: "",
            },
            sectionFourData: {
                title: "At tawq.in YOU are family",
                subtitle: "We are dedicated to preserving unwritten languages and empowering communities.",
                paragraph1: "We do this by leveraging technology and the internet. Our platform enables users to share and document these languages, contributing to cultural heritage while supporting developing societies in adapting to modern challenges and enhancing everyday life.",
                paragraph2: "",
                paragraph3: "",
                paragraph4: "",
            },
            sectionFiveData: {
                // title: "Helping Facilitate Learning",
                // subtitle: "",
                // paragraph1: "",
                // paragraph2: "",
                // paragraph3: "",
                // paragraph4: "",
                title: "Helping Facilitate Learning",
                subtitle: "Empowering Users to Discover New Possibilities",
                paragraph1: "Our vision for tawq.in goes beyond language preservation. We’ve designed the platform to be as flexible as possible, allowing users to adapt it to their unique needs. Whether it’s sharing cherished stories, recording and translating content, or coaching and teaching in different languages, the possibilities are endless. We believe that the more you explore tawq.in, the more you’ll discover new ways to make it work for you.",
                paragraph2: "tawq.in was built with ease of accessibility in mind. Whether you’re a grandparent recording a tale for your grandchildren, an educator teaching new concepts, or a translator bridging the gap between cultures, our platform empowers you to share your voice. Users can effortlessly add captions, voiceovers, and translations, making content engaging and understandable for diverse audiences.",
                paragraph3: "We envision a world where learning, storytelling, and cultural exchange can thrive without barriers. tawq.in provides the tools to create, share, and connect in ways that are meaningful to each user. As you explore our platform, we encourage you to find your own unique way to use it—whether it's for teaching, preserving heritage, or simply sharing your experiences with others.",
            },
            sectionSixData: {
                title: "We are Built Different",
                subtitle: "",
                paragraph1: "",
                paragraph2: "",
                paragraph3: "",
                paragraph4: "",
            },
        };
    },
    methods: {
        getAboutPhotosForCarousel: async function () {
            firebase.db.collection("about").get().then((snapshot) => {
                let sectionZeroCounter = 0;
                let sectionOnePhotos = [];
                let sectionTwo = [];
                if (!this.isMobile) {
                    snapshot.forEach((doc) => {
                        if (doc.data().section == 0) {
                            this.sectionZeroCarouselPhotos.index = sectionZeroCounter;
                            this.sectionZeroCarouselPhotos.push(doc.data());
                            sectionZeroCounter++;
                        } else if (doc.data().section == 1) {
                            sectionOnePhotos.push(doc.data());
                        } else if (doc.data().section == 2) {
                            sectionTwo.push(doc.data());
                        } else if (doc.data().section == 3) {
                            this.sectionThreePhotos.push(doc.data());
                        } else if (doc.data().section == 5) {
                            this.sectionFivePhotos.push(doc.data());
                        } else if (doc.data().section == 6) {
                            this.sectionSixPhotos.push(doc.data());
                        }
                    });
                    this.sortSectionOneCarouselPhotosByName(sectionOnePhotos);
                    this.sortSectionTwoPhotosByName(sectionTwo);
                } else {
                    const sectionTwoBackgroundPhotoName = "marty_with_friends_middle_east.jpg";
                    const sectionThreeBackgroundPhotoName = "middle_east_desert.jpg";
                    const sectionFourBackgroundPhotoName = "oman_friends.jpg";
                    const sectionFiveBackgroundPhotoName = "camels.jpg";
                    const sectionSixBackgroundPhotoName = "camels.jpg";
                    snapshot.forEach((doc) => {
                        if (doc.data().section == 0) {
                            this.sectionZeroCarouselPhotos.index = sectionZeroCounter;
                            this.sectionZeroCarouselPhotos.push(doc.data());
                            sectionZeroCounter++;
                        } else if (doc.data().section == 1) {
                            sectionOnePhotos.push(doc.data());
                        } else if (doc.data().section == 2 && doc.data().name == sectionTwoBackgroundPhotoName) {
                            this.sectionTwoBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 3 && doc.data().name == sectionThreeBackgroundPhotoName) {
                            this.sectionThreeBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 4 && doc.data().name == sectionFourBackgroundPhotoName) {
                            this.sectionFourBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 5 && doc.data().name == sectionFiveBackgroundPhotoName) {
                            this.sectionFiveBackgroundPhoto.push(doc.data());
                        } else if (doc.data().section == 6 && doc.data().name == sectionSixBackgroundPhotoName) {
                            this.sectionSixBackgroundPhoto.push(doc.data());
                        }
                    });
                    this.sortSectionOneCarouselPhotosByName(sectionOnePhotos);
                }
            });
        },
        sortSectionOneCarouselPhotosByName: function (sectionOnePhotos) {
            const customOrder = ['fatima.jpeg', 'marty.jpeg', 'scott.jpeg', 'dillan.jpg'];
            sectionOnePhotos.sort((a, b) => { // Sort section 1 photos by custom order in 'name' field
                return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
            });

            sectionOnePhotos.forEach((photo, index) => { // Add sorted photos to the sectionOneCarouselPhotos array
                let photoData = {};
                photoData.index = index;
                photoData.name = photo.name;
                photoData.photoId = photo.photoId;
                photoData.photoURL = photo.photoURL;
                photoData.section = photo.section;
                this.sectionOneCarouselPhotos.push(photoData);
            });
        },
        sortSectionTwoPhotosByName: function (sectionTwo) {
            const customOrder = ['camels.jpg', 'marty_camel.jpg', 'marty_with_friends_middle_east.jpg', 'oman_friends.jpg', 'hamdan_learning.jpg', 'tawq.in_aip_big_room.jpg', 'aip_full_team.jpg', 'group_aip_big_room_meeting.jpg', 'marty_google_meet.jpg', 'international_students.jpg', 'shadman_bashir_using_tawq.in.jpg', 'tawqin_logo.png'];
            sectionTwo.sort((a, b) => { // Sort section 2 photos by custom order in 'name' field
                return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
            });

            sectionTwo.forEach((photo, index) => { // Add sorted photos to the sectionOneCarouselPhotos array
                let photoData = {};
                photoData.index = index;
                photoData.name = photo.name;
                photoData.photoId = photo.photoId;
                photoData.photoURL = photo.photoURL;
                photoData.section = photo.section;
                this.sectionTwoPhotos.push(photoData);
            });
        },
        sectionOneCarouselPhotoChanged: function (index) {
            this.currentSectionOneData = this.sectionOneData[index];
        },
        scrollToContent: function (id) {
            const contentElement = document.getElementById(id);
            if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "center" });
            // if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        },
    },
    computed: {
        // isAdmin(){
        //     return firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.id === "ex18uSUvXReInwQRqaAZxO6Syer2";
        // },
        isMobile () {
            return this.$store.state.isMobileDevice;
        },
        sectionOneBackgroundStyle() {
            const backgroundImages = this.sectionOneCarouselPhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImages,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionTwoBackgroundStyle() {
            const backgroundImages = this.sectionTwoPhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImages,
                // backgroundSize: '16.66% 33.32%', // Adjust to fit 6 images per row, 2 rows
                backgroundSize: '16.66% 50%', // Adjust to fit 6 images per row, 2 rows
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `
                0% 0%, 20% 0%, 40% 0%, 60% 0%, 80% 0%, 100% 0%,
                0% 100%, 20% 100%, 40% 100%, 60% 100%, 80% 100%, 100% 100%
                `
            };
        },
        sectionTwoMobileBackgroundStyle() {
            const backgroundImage = this.sectionTwoBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionThreeMobileBackgroundStyle() {
            const backgroundImage = this.sectionThreeBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFourMobileBackgroundStyle() {
            const backgroundImage = this.sectionFourBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFiveBackgroundStyle() {
            const backgroundImage = this.sectionFivePhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'fit',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionFiveMobileBackgroundStyle() {
            const backgroundImage = this.sectionFiveBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionSixBackgroundStyle() {
            const backgroundImage = this.sectionSixPhotos.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'fit',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
        sectionSixMobileBackgroundStyle() {
            const backgroundImage = this.sectionSixBackgroundPhoto.map(photo => `url(${photo.photoURL})`).join(', ');
            return {
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
        },
    },
    created: async function () {
        await this.getAboutPhotosForCarousel();
        this.$vuetify.theme.dark = true; // This will force the app to be in dark mode when a user is on about page.
    },
    mounted: async function () {
        this.userTheme = this.$vuetify.theme.isDark;
        // console.log("theme: ", this.userTheme);
    },
    beforeDestroy: async function () {
        if (this.userTheme == false) {
            this.$vuetify.theme.dark = false; // This set the app to be in light mode if a user theme was light before coming to the page.
        }
    },
}
</script>

<style scoped>
.main-header {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 225px);
  width: 100%;
  /* width: 100vw; */
  overflow: scroll;
}
.main-header-mobile {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 200px);
  width: 100%;
  /* width: 100vw; */
  overflow: scroll;
}

.header {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 225px);
  width: 100%;
  /* width: 100vw; */
  overflow: scroll;
}

.header-mobile {
  position: relative;
  text-align: center;
  /* background: linear-gradient(60deg, rgba(97, 137, 47, 1), rgba(134, 194, 50, 1)40%, rgb(255, 193, 7, 1) 60%, rgb(113, 181, 27, 1) 80%,rgba(97, 137, 47, 1) 100%); */
  /* background: red; */
  background-size: 400% 400%;
  /* animation: gradient 25s ease infinite; */
  height: calc(100vh - 0px);
  width: 100%;
  /* width: 100vw; */
  overflow: scroll;
}

::v-deep .custom .v-carousel__controls__item.v-btn {
  color: white;
}

::v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active {
  color: var(--v-primary-base);
  /* color: var(--v-accent-base); */
}

::v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active:before {
  opacity: 0;
}

::v-deep .custom .v-carousel__controls__item.v-btn:hover {
  color: var(--v-accent-base);
  /* color: var(--v-primary-base); */
}

::v-deep .custom .v-carousel__controls__item.v-btn:hover:before {
  opacity: 0;
}

.hib-btn {
    width: 100px;
    height: 100px;
}
.hib-htb-light-up:hover .v-icon,
.hib-htb-light-up:hover .text-h4 {
    text-shadow: 0 0 .15em var(--v-primary-base), 0 0 .15em var(--v-primary-base), 0 0 .15em var(--v-accent-base);
}
.hib-htb .v-icon,
.hib-htb .text-subtitle-2 {
    text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-accent-base);
}

/* SECTION 1 */
.section-1-carousel-image {
    background-size: contain; /* Ensures the full image fits within the item */
    background-position: center;
    background-repeat: no-repeat;
    height: 100%; /* Forces the image to fill the height of the carousel */
}
/* #section-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
}
#section-1 .overlay {
    position: relative;
    z-index: 2;
    color: white;
} */

/* SECTION 2 */
#section-2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-2 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}
.stb {
    text-shadow: 10px 10px 1em black, 10px 10px 1em black, 10px 10px 1em black;
}

/* SECTION 3 */
.section-3-image-grid-container {
    background-color: transparent;
    display: flex;
    border: 2px solid var(--v-primary-base);
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
}

.section-3-image-grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px;
    width: 100%;
    height: 100%;
    /* max-width: 500px; */
}

.section-3-image-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#section-3-mobile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-3-mobile .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}
.s3tb {
    text-shadow: 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base), 0 0 .1em var(--v-primary-base);
}
/* .s3pb {
    text-shadow: 0 0 .01em white, 0 0 .01em white, 0 0 .01em white;
} */

/* SECTION 4 */
#section-4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-4 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}

/* SECTION 5 */
#section-5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-5 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}

/* SECTION 6 */
#section-6::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* black overlay with 50% opacity */
    z-index: 1;
}
#section-6 .overlay {
    position: relative;
    z-index: 2;
    color: white; /* Text color for readability */
}
</style>