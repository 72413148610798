<template>
    <div class="full-width d-flex flex-row justify-center align-center" style="position: relative;">
        <div v-if="loading" class="payment-loading-container ma-0 pa-0">
            <v-overlay absolute>
                <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </div>
        <v-col :class="[ $vuetify.theme.isDark ? 'grey darken-3' : 'grey lighten-4']" class="rounded-xl d-flex flex-column align-center" cols="12" xl="7" lg="8" md="10" sm="8" xs="10">

            <div class="text-h6 text-left mb-4">Advanced Settings</div>

            <v-avatar size="52">
                <v-img v-if="group.groupThumbnail != null && !loading" :src="group.groupThumbnail" :alt="group.groupName" max-height="50" max-width="50" referrerpolicy="no-referrer" @error="group.groupThumbnail=null"></v-img>
                <v-img v-else-if="group.groupThumbnail === null || loading" src="../../assets/no-user.png" :alt="group.groupName" max-height="118" max-width="118"></v-img>
            </v-avatar>

            <v-col class="mt-4" cols="12" xl="7" lg="9" md="10" sm="10">
                <v-divider class="my-2"/>

                <div class="d-flex flex-row justify-space-between align-center px-0 py-2 full-width">
                    <div>Private Contributions</div>
                    <v-switch hide-details class="mt-0" flat v-model="privateContributionsSwitch" :disabled="!group.admin" @change="privateContributionsSwitchHasChanged = !privateContributionsSwitchHasChanged"></v-switch>
                </div>
                <v-divider class="my-2"/>
                <div class="d-flex flex-row justify-space-between align-center px-0 py-2 full-width">
                    <v-autocomplete v-model="languageSelectedAutoComplete" label="Select a default contribution language" :items="languageCodes" item-text="text" item-value="value" return-object :disabled="!group.admin" outlined hint="*This will be the langauge preselected when contributing to a group post" persistent-hint @change="hasMadeChangesToLanguage"></v-autocomplete>
                </div>
                <v-divider class="my-2"/>
                <div class="d-flex flex-row justify-space-between align-center px-0 py-2 full-width">
                    <div>Group Thumbnail</div>
                    <v-btn text :disabled="!group.admin" color="primary" @click="$emit('uploadThumbnailDialog')" class="font-weight-light text-caption px-0">{{group.groupThumnail === '' ? 'Add' : 'Edit'}}</v-btn>
                </div>
                <v-divider class="my-2"/>
                <div class="d-flex flex-row justify-space-between align-center px-0 py-2 full-width">
                    <div>Leave Group</div>
                    <v-btn text color="primary" @click="$emit('leaveGroup')" class="font-weight-light text-caption px-0">Leave</v-btn>
                </div>
                <v-divider class="my-2"/>
                <div class="d-flex flex-row justify-center align-center px-0 py-2 full-width">
                    <v-btn class="primary" rounded depressed :disabled="(!langugageSelectedHasChanged && !privateContributionsSwitchHasChanged) || !group.admin" @click.stop="saveChanges()">Save</v-btn>
                </div>
            </v-col>
        </v-col>
    </div>
</template>
<script>
// import firebase from "../../../firebaseConfig.js";
export default {
    name: "EditGroupSettings",
    props: ["group"],
    data: () => ({
        loading: false,
        privateContributionsSwitchHasChanged: false,
        privateContributions: null,
        languageCodes: [{
            text: "None (Disable Auto Captions)",
            value: "null",
        }],
        oldLanguageSelected: null,
        langugageSelectedHasChanged: false,
        languageSelected: null,
    }),
    methods: {
        hasMadeChangesToLanguage: function () {
            if (this.languageSelected.value != this.oldLanguageSelected.value){
                this.langugageSelectedHasChanged = true;
            } else {
                this.langugageSelectedHasChanged = false;
            }
        },
        saveChanges(){
            this.loading = true;
            // call to server to make changes with a transaction. Make changes to the group itself, make changes to each of the group members group collection, maybe make the updates to the posts in that group too???
            if(!this.privateContributionsSwitchHasChanged && !this.langugageSelectedHasChanged){
                this.$store.commit('alertUser', { show: true, text: `There are no changes to save.`, type: 'snackbar' });
                this.loading = false;
                return;
            }
            const newData = {
                privateContributions: this.privateContributions === null ? this.privateContributionsSwitch : this.privateContributions,
                languageSelected: this.languageSelected === null ? this.languageSelectedAutoComplete : this.languageSelected,
            }
            this.$http.put(`/group/${this.group.groupId}`, newData).then((response) => {
                if(response.status === 200){
                    this.loading = false;
                    this.oldLanguageSelected = this.languageSelected === null ? this.languageSelectedAutoComplete : this.languageSelected;
                    this.privateContributionsSwitchHasChanged = false;
                    this.langugageSelectedHasChanged = false;
                    this.$store.commit('alertUser', { show: true, text: `Successfully updated settings.`, type: 'snackbar' });
                }
            }).catch((err) => {
                this.loading = false;
                this.$store.commit('alertUser', { show: true, text: `Failed to update settings.`, type: 'snackbar' });
                console.log("error saving group changes: ", err);
            });
        },
    },
    computed: {
        privateContributionsSwitch: {
            get: function() {
                return this.group.privateContributions;
            },
            set: function(newValue) {
                this.privateContributions = newValue;
            }
        },
        languageSelectedAutoComplete: {
            get(){
                if (typeof this.group.languageSelected === 'undefined') {
                    return {
                        text: "English (United States)",
                        value: "en-US",
                    };
                } else {
                    return this.group.languageSelected;
                }
                // return this.group.languageSelected;
            },
            set(value){
                this.languageSelected = value;
            }
        },
    },
    updated: function () {
        this.oldLanguageSelected = this.languageSelectedAutoComplete;
    },
    mounted: function () {
        this.languageCodes.push(...this.$languageCodes);
    },
}
</script>