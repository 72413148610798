<template>
    <!-- Formerly known as Navbar.vue -->
    <!-- Change on August 28, 2024 by Scott Brady to be more consistent in naming convention -->
    <v-app-bar class="primary" app clipped-left>
        <!-- :class="[userData.admin ? userData.appBarColor : 'primary']" -->

        <!-- DRAWER -->
        <v-app-bar-nav-icon v-if="isMobile || isViewPost || isAboutPage" @click.stop="$emit('toggleDrawer', true)" dark></v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-else @click.stop="$emit('toggleDrawer', true)" dark class="hidden-lg-and-up"></v-app-bar-nav-icon>
        <!-- END DRAWER -->

        <!-- LOGO -->
        <v-col cols="4" class="ma-0">
            <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../assets/whitetransparent.png" style="cursor: pointer" @click.stop="$emit('toggle-home')"></v-img>
        </v-col>
        <!-- END LOGO -->

        <v-spacer />

        <!-- DEVELOPMENT -->
        <!-- <v-btn v-if="!searchBar && !isMobile && this.$window.location.host === '0-0-0-dot-tawq-in-www.appspot.com'" icon :ripple="false">
            <v-icon>mdi-dev-to</v-icon>
        </v-btn> -->
        <!-- END DEVELOPMENT -->

        <!-- SEARCH FUNCTIONALITY COMMENTED OUT BECAUSE OF ISSUES ON NOVEMBER 21,2023 BY SCOTT BRADY -->

            <!-- DESKTOP SEARCH -->
            <!-- This code is for desktop search functionality -->
            <v-menu v-if="searchBar && !isMobile" rounded="lg" bottom offset-y origin="left" transition="slide-y-transition" open-on-focus close-on-content-click class="hidden-sm-and-down">
                <template v-slot:activator="{ on }">
                    <v-text-field v-on="on" v-model="searchParams" ref="searchField" validate-on-blur clearable solo single-line hide-details placeholder="Search Posts by Title" @keypress.enter="updateSearchParams" @focus="$event.target.select()" class="hidden-sm-and-down">
                        <template v-slot:append>
                            <v-icon color="primary" class="thick-icon" @click.native.stop="updateSearchParams">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </template>
            </v-menu>

            <v-btn v-if="!searchBar" class="hidden-sm-and-down" @click.native.stop="searchBar = true" icon dark>
                <v-icon class="thick-icon">mdi-magnify</v-icon>
            </v-btn>
            <!-- END DESKTOP SEARCH -->

            <!-- MOBILE SEARCH -->
            <!-- This code is for mobile search functionality -->
            <v-menu :value="searchBar && isMobile" rounded="lg" bottom offset-y origin="left" transition="slide-y-transition" :open-on-click="false" :open-on-focus="false" :close-on-click="false" :close-on-content-click="false" class="hidden-md-and-up">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" class="hidden-md-and-up" icon dark>
                        <v-icon class="thick-icon" @click.native.stop="searchBar = !searchBar">mdi-magnify</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-text-field v-model="searchParams" ref="searchField" validate-on-blur clearable outlined solo hide-details placeholder="Search by Title" @keypress.enter="updateSearchParams">
                        <template v-slot:append>
                            <v-btn color="primary" @click.native.stop="updateSearchParams" icon dark>
                                <v-icon color="primary" class="thick-icon">mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-card>
            </v-menu>
            <!-- END MOBILE SEARCH -->

        <!-- END SEARCH FUNCTIONALITY COMMENTED OUT BECAUSE OF ISSUES ON NOVEMBER 21,2023 BY SCOTT BRADY -->

        <!-- <v-icon @click.stop="" class="icon--text">mdi-qrcode-scan</v-icon> -->

        <!-- UPLOAD PROGRESS -->
        <v-btn v-if="uploadingVideo" @click="showUploadPostBar = !showUploadPostBar" icon dark>
            <v-progress-circular width="2" size="22" :color="uploadingVideo ? 'warning' : '' " :value="uploadingVideo ? resumableProgress : 0 ">
                <v-icon small class="white--text">mdi-arrow-up</v-icon>
            </v-progress-circular>
        </v-btn>

        <UploadBar v-if="showUploadPostBar" /> 
        <!-- END UPLOAD PROGRESS -->

        <!-- ADMIN -->
        <!-- <v-btn v-if="userData.admin && !isMobile" icon dark>
            <v-icon>mdi-crown</v-icon>
        </v-btn> -->
        <!-- END ADMIN -->

        <!-- NOTIFICATIONS -->
        <v-menu v-if="friendNotifications.length > 0 || groupNotifications.length > 0" absolute rounded="lg" bottom offset-y origin="top" transition="slide-y-transition" min-width="310" max-width="600" :close-on-content-click="false">

            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon dark>
                    <v-badge :content="friendNotifications.length + groupNotifications.length" color="red" overlap>
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-list rounded>
                <v-list-item-title v-if="friendNotifications.length > 0" class="text-center font-italic accent--text my-2">New Friend Requests</v-list-item-title>

                <v-list-item v-for="(notif, index) in friendNotifications" :key="notif.id" v-bind:class="[ $vuetify.theme.dark && isOdd(index) ? 'grey darken-3' : !$vuetify.theme.dark && isOdd(index) ? 'odd' : '']">
                    <v-row justify="space-between" align="center" no-gutters>
                        <v-col cols="2" class="flex-grow-0 flex-shrink-1">
                            <v-avatar size="25" color="primary" @click.stop="openSearchedUsersPage(notif.data.userId)" style="cursor: pointer;">
                                <v-img :src="notif.data.userProfilePhoto != '' && notif.data.userProfilePhoto != null ? notif.data.userProfilePhoto : require('../assets/no-user.png')" :alt="notif.data.username" referrerpolicy="no-referrer" @error="notif.data.userProfilePhoto=null"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="6" class="flex-grow-1 flex-shrink-0">
                            <div @click.stop="openSearchedUsersPage(notif.data.userId)" style="cursor: pointer;" class="font-weight-medium text-caption text-lg-subtitle-1 text-truncate text-left invert--text">
                                {{ notif.data.username }}
                            </div>
                        </v-col>
                        <v-col cols="4" class="flex-grow-0 flex-shrink-1">
                            <v-row justify="end" align="center" no-gutters>
                                <v-btn icon color="primary" @click.stop="acceptFriendRequest(notif, index)" class="font-weight-light text-caption"><v-icon small>mdi-check</v-icon></v-btn>
                                <v-btn icon color="red" @click.stop="deleteFriendRequest(notif, index)" class="font-weight-light text-caption"><v-icon small>mdi-close</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-list-item>

                <v-divider v-if="friendNotifications.length > 0" class="my-3"></v-divider>
                <v-list-item-title v-if="groupNotifications.length > 0" class="text-center font-italic accent--text my-4">New Group Requests</v-list-item-title>

                <v-list-item v-for="(notif, index) in groupNotifications" :key="notif.id" v-bind:class="[ $vuetify.theme.dark && isOdd(index) ? 'grey darken-3' : !$vuetify.theme.dark && isOdd(index) ? 'odd' : '']">
                    <v-row justify="space-between" align="center" no-gutters>
                        <v-col cols="2" class="flex-grow-0 flex-shrink-1">
                            <v-avatar v-if="notif.data.groupThumbnail != null" size="25" color="primary">
                                <v-img :src="notif.data.groupThumbnail" :alt="notif.data.groupName" referrerpolicy="no-referrer" @error="notif.data.groupThumbnail=null"></v-img>
                            </v-avatar>
                            <v-avatar v-else size="25" color="primary">
                                <v-icon size="15" class="invert--text text--lighten-3">mdi-account-group</v-icon>
                            </v-avatar>
                        </v-col>

                        <v-col cols="6" class="flex-grow-1 flex-shrink-1 pr-1 pl-0 py-0 justify-center">
                            <div class="font-weight-medium text-caption text-lg-subtitle-1 text-truncate text-left invert--text">
                                {{ notif.data.groupName }}
                            </div>
                            <div v-if="notif.data.type === 'invite'" class="font-weight-light text-caption text-truncate invert--text pl-2 pb-1">
                                Invited By {{notif.data.adminName}}
                            </div>
                            <div v-else class="font-weight-light text-caption text-truncate invert--text pl-2 pb-1">
                                Requested By {{notif.data.username}}
                            </div>
                        </v-col>

                        <v-col cols="4" class="pa-0 flex-grow-0 flex-shrink-1">
                            <v-row justify="end" align="center" no-gutters>
                                <v-btn icon color="primary" @click.stop="acceptGroupRequest(notif/*, index*/)" class="font-weight-light text-caption"><v-icon small>mdi-check</v-icon></v-btn>
                                <v-btn icon color="red" @click.stop="deleteGroupRequest(notif/*, index*/)" class="font-weight-light text-caption"><v-icon small>mdi-close</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
                <v-btn class="hidden-sm-and-up" icon dark :ripple="false">
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
                <v-btn v-on="on" class="hidden-xs-only" icon dark :ripple="false">
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </template>
            <span>No Notifications</span>
        </v-tooltip>
        <!-- END NOTIFICATIONS -->

        <!-- CREATE POST -->
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <router-link to="/new-post" class="text-decoration-none" :class="{ disabled : uploadingVideo }">
                    <v-btn v-if="!uploadingVideo" v-on="on" class="hidden-xs-only" icon dark>
                        <v-icon :class="$vuetify.theme.dark ? 'white-text': 'black-text'">mdi-plus-thick</v-icon>
                    </v-btn>
                    <v-btn v-if="!uploadingVideo" class="hidden-sm-and-up" icon dark>
                        <v-icon :class="$vuetify.theme.dark ? 'white-text': 'black-text'">mdi-plus-thick</v-icon>
                    </v-btn>
                </router-link>
            </template>
            <span>Create New Post</span>
        </v-tooltip> -->

        <v-menu v-model="creationMenu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" min-width="300" max-width="600" close-on-content-click>
            <template v-slot:activator="{ on }">
                <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn icon v-on="{ ...on, ...tooltipOn }" v-bind="tooltipAttrs" @click.stop="creationMenu = !creationMenu">
                        <v-icon class="white--text">mdi-plus-thick</v-icon>
                    </v-btn>
                </template>
                <span>Create New Post</span>
                </v-tooltip>
            </template>

            <v-list rounded>
                <v-list-item @click.stop="openNewPostRoute('new-post'), creationMenu = !creationMenu">
                    <v-list-item-icon><v-icon>mdi-waveform</v-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Audio - Video Creation</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item @click.stop="openNewPostRoute('new-text-post'), creationMenu = !creationMenu">
                    <v-list-item-icon><v-icon>mdi-text-recognition</v-icon><v-icon class="transparent--text">mdi-text-box-outline</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Text Creation</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <!-- <v-list-item @click.stop="openNewPostRoute('new-photo-post'), creationMenu = !creationMenu">
                    <v-list-item-icon><v-icon>mdi-camera</v-icon><v-icon class="transparent--text">mdi-image</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Photo Creation</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
            </v-list>
        </v-menu>
        <!-- END CREATE POST -->

        <!-- PROFILE PICTURE -->
        <v-menu v-model="profileMenu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" min-width="300" max-width="600" close-on-content-click>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.stop="profileMenu = !profileMenu">
                    <v-avatar size="30" color="white">
                        <v-img :src="(userData.profilePicture === null || typeof userData.profilePicture === 'undefined') ? require('../assets/no-user.png') : userData.profilePicture != '' ? userData.profilePicture : require('../assets/no-user.png')" :alt="username" referrerpolicy="no-referrer" @error="userData.profilePicture=null"></v-img>
                    </v-avatar>
                </v-btn>
            </template>

            <v-list rounded>
                <v-list-item>
                    <v-list-item-icon>
                        <v-avatar size="50" color="white">
                            <v-img :src="(userData.profilePicture === null || typeof userData.profilePicture === 'undefined') ? require('../assets/no-user.png') : userData.profilePicture != '' ? userData.profilePicture : require('../assets/no-user.png')" :alt="username" referrerpolicy="no-referrer" @error="userData.profilePicture=null"></v-img>
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-body-1 text-truncate">{{username}}</v-list-item-title>
                        <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click.stop="signOut()">
                    <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Sign out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-5 my-2"/>
                <v-list-item @click.stop="openSettingsPage(), profileMenu = !profileMenu">
                    <v-list-item-icon><v-icon>mdi-settings</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click.stop="openPrivacyPage(), profileMenu = !profileMenu">
                    <v-list-item-icon><v-icon>mdi-account-lock</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Privacy</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-5 my-2"/>
                <v-list-item @click.stop="openAboutPage(), profileMenu = !profileMenu">
                    <!-- <v-list-item-icon><v-icon>mdi-information-variant</v-icon></v-list-item-icon> -->
                    <v-list-item-icon><v-icon>mdi-information-outline</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>About Us</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item @click.stop="openHelpPage()">
                    <v-list-item-icon><v-icon>mdi-help</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Help</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item @click.stop="feedbackDialog = true, profileMenu = !profileMenu">
                    <v-list-item-icon><v-icon>mdi-text-box-check</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Send Feedback</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <Feedback v-if="feedbackDialog" :dialog="feedbackDialog" @closeFeedback="feedbackDialog = false"/>
            </v-list>
        </v-menu>
        <!-- PROFILE PICTURE -->

    </v-app-bar>
</template>


<script>
import firebase from "../../firebaseConfig.js";
import UploadBar from "./UploadBar.vue";
import Feedback from "./Feedback.vue";

export default {
    name: "Appbar",
    components: {
        UploadBar,
        Feedback,
    },
    data: function () {
        return {
            userData: {},
            userDataListener: null,
            searchParams: "",
            searchBar: false,
            username: firebase.auth.currentUser.displayName,
            email: firebase.auth.currentUser.email,
            notificationsListener: null,
            friendNotifications: [],
            groupNotifications: [],
            showUploadPostBar: false,
            creationMenu: false,
            profileMenu: false,
            feedbackDialog: false,
        };
    },
    methods: {
        isOdd: function (id) {
            if (id % 2 == 1) {
                return true;
            }
            return false;
        },
        openSearchedUsersPage: function (id) {
            this.$router.push({name: 'view-user-profile', query: { id: id }});
            // window.open(`https://tawq.in/view-user-profile?id=${id}`, "_blank");
        },
        updateSearchParams: function () {
            this.searchBar = !this.searchBar;
            if (!this.searchParams || this.$route.query.s === this.searchParams) return; // if there are no search params or the new search params are the same as the old then return
            if (this.$route.query.s === this.searchParams) return; // if the new search params are the same as the old then return
            this.$router.push({ path: "search", query: { s: this.searchParams } }); // push the search params to the router so the search can take place

            // if (!this.searchParams && this.$route.path !== "/home") { // if there are no search params, and the path is not already '/home', then push path '/home' to redirect user to home feed
            //     this.$router.push({ path: "home" });
            // } else if (this.searchParams) { // there are search params, so...
            //     this.$router.push({ path: "search", query: { s: this.searchParams } }); // push the search params to the router so the search can take place
            // }
        },
        handleClickOutside(event) {
            if (this.$refs.searchField && !this.$refs.searchField.$el.contains(event.target) && this.searchBar) this.searchBar = false;
        },
        openNewPostRoute: function (location) {
            this.$router.push({path: location});
        },
        signOut: function () {
            firebase.auth.signOut().then(() => {
                // console.log("Sign out");
            }).catch((err) => {
                console.error("Error: ", err);
            });
            this.$store.dispatch('clearGroupsPageStoreState');
            this.$store.dispatch('replaceState');
            this.$router.replace({ name: "login" });
        },
        openSettingsPage: function () {
            this.$router.push({path: "settings"});
        },
        openPrivacyPage: function () {
            this.$router.push({path: "privacy"});
        },
        openAboutPage: function () {
            this.$router.push({path: "about"});
        },
        openHelpPage: function () {
            this.$router.push({path: "help"});
        },
        getUserNotifications: function () {
            var userId = firebase.auth.currentUser.uid;
            var notifsRef = firebase.db.collection("userData").doc(userId).collection("notifs");

            this.notificationsListener = notifsRef.onSnapshot((snapshot) => {
                this.friendNotifications = [];
                this.groupNotifications = [];
                snapshot.forEach((doc) => {
                    var data = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    // We can add to these if statement if we ever want to give a notification another type and display it to the user.
                    if (doc.data().type == "join" || doc.data().type == "invite") {
                        this.groupNotifications.push(data);
                    } else {
                        this.friendNotifications.push(data);
                    }
                });
                this.$store.commit('saveFriendRequestNotifications', this.friendNotifications);
                this.$store.commit('saveGroupRequestNotifications', this.groupNotifications);
            });
        },
        acceptFriendRequest: async function (friend, index) {
            this.friendNotifications.splice(index, 1); // remove from local list so that user cannot click while accepting reqest
            let response = await this.$http.put(`friends/${friend.userId}/${firebase.auth.currentUser.uid}`, {friend: friend,});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Accepted", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
        },
        deleteFriendRequest: async function (notif, index) {
            this.friendNotifications.splice(index, 1); // remove from local list so that user cannot click while rejecting reqest
            let response = await this.$http.delete(`friends/${firebase.auth.currentUser.uid}/${notif.data.userId}`, {data: {notif: notif, accepted: false}});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Rejected", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
        },
        acceptGroupRequest: async function (notif) {
            // TODO: remove notification when it is clicked. If fail then reshow the request. Don't want a user to be able to
            // click the same one twice while it is running the process!
            var group = {
                id: notif.id,
                groupId: notif.data.groupId,
                groupName: notif.data.groupName,
                groupThumbnail: notif.data.groupThumbnail,
                requestToJoin: null,
                userId: null,
            };

            if (notif.data.type === 'invite') {
                // this notif will show up on a users account that has been invited to join a group
                group.userId = firebase.auth.currentUser.uid;
                group.requestToJoin = false;
            } else {
                // this notif will show up on the admins account of a group
                group.userId = notif.data.userId;
                group.requestToJoin = true;
            }

            this.$http.put(`groups/${group.groupId}/${group.userId}`, {
                group: group,
            }).then((response) => {
                if (response.status == 200) {
                if (this.$route.path === '/groups'){
                    this.$store.commit('setSelectedGroupId', group.groupId);
                }
                this.$store.commit('alertUser', { show: true, text: `Successfully joined ${group.groupName}`, type: 'snackbar' });
                }
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err}`, type: 'snackbar' });
            });
        },
        deleteGroupRequest: function (notif) {
            // accepted must always be set to false (because in this case the user will have NEVER accepted the request)
            // so that if the current user deletes
            // the request the server will have the
            // correct info to delete correctly

            var group = {
                id: notif.id,
                groupId: notif.data.groupId,
                groupName: notif.data.groupName,
                groupThumbnail: notif.data.groupThumbnail,
                requestToJoin: null,
                userId: null,
                member: {
                accepted: false,
                role: 'user',
                },
            };

            if (notif.data.type === 'invite') {
                // this notif will show up on a users account that has been invited to join a group
                group.userId = firebase.auth.currentUser.uid;
                group.requestToJoin = false;
            } else {
                // this notif will show up on the admins account of a group
                group.userId = notif.data.userId;
                group.requestToJoin = true;
            }

            this.$store.dispatch('removeUserDataFromGroup', {groupId: group.groupId, userId: group.userId, group: group, action: notif.data.type === 'invite' ? 'cancel' : 'reject'});
        },
        getUserData: function() {
            this.updateProfileListener = firebase.db.collection("userData").doc(firebase.auth.currentUser.uid).onSnapshot((snapshot) => {
                this.userData = snapshot.data();
                if(!this.userData.admin) {
                    this.userData.admin = false;
                }
            });
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        uploadingVideo(){
            return this.$store.state.newPost.uploading_video /* && this.$store.state.newPost.resumable_progress > 0 */;
        },
        resumableProgress() {
            return this.$store.state.newPost.resumable_progress;
        },
        createPost(){
            return this.$store.state.newPost.createPost;
        },
        isViewPost(){
            return (this.$route.path === '/view-post') ? true : false;
        },
        isAboutPage(){
            return (this.$route.path === '/about' || this.$route.path === '/ar-about') ? true : false;
        },
    },
    mounted: function () {
        this.showUploadPostBar = this.uploadingVideo; // this should open the upload bar by default if a post is being uploaded
        document.addEventListener('click', this.handleClickOutside);
    },
    created: function () {
        this.getUserData();
        this.getUserNotifications();
    },
    beforeDestroy: function () {
        this.updateProfileListener();
        this.notificationsListener();
        document.removeEventListener('click', this.handleClickOutside);
    },
};
</script>

<style>
.odd {
  background: #dddddd;
}
.v-btn::before {
  background-color: transparent !important;
}
.thick-icon {
    transform: scale(1.2); /* Slightly increase the scale, so icon looks 'bolder/thicker' to be more consistent with other icons size */
}
.search-item:hover {
  background-color: #c7c7c7;
  border-radius: 5px;
}

.disabled {
  pointer-events: none;
  cursor: pointer;
}

</style>