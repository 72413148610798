<template>
    <v-row justify="center" align="center" style="max-width: 100%; max-height: 100%;" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9;" :class="isMobile ? 'primary' : 'transparent'">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="!loading ? $router.push({ path: 'intro'}) : $store.commit('alertUser', { show: true, text: `Can't perform this action while in a loading state.`, type: 'snackbar' })"></v-img>
                </v-col>
                <v-spacer/>
                <v-col cols="8" align="end" class="ma-0 pa-0">
                    <v-btn color="white" outlined :disabled="loading" class="mr-2 font-weight-normal" :to="{name: 'login'}">Login</v-btn>
                    <v-btn color="white" outlined :disabled="loading" class="font-weight-normal" :to="{name: 'sign-up'}">Sign Up</v-btn>
                </v-col>
            </v-app-bar>

            <v-main :class="isMobile ? '' : 'ma-0 pa-0'">
                <v-row v-if="!signUpComplete" justify="center" class="ma-0 pa-0">
                    <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                        <v-card elevation="8" light class="rounded-xl">

                            <v-overlay v-if="loading" absolute>
                                <v-progress-circular size="100" width="5" indeterminate color="primary"></v-progress-circular>
                            </v-overlay>
                            <v-overlay v-if="showTC" absolute>
                                <TCModal :dialog="true"  @setAgree="setAgree"/>
                            </v-overlay>

                            <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="8" align="center">
                                        <span class="text-wrap text-h4 text-md-h3 text-lg-h3 font-weight-light">Sign Up</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>

                            <v-row justify="center" no-gutters class="ma-0 pa-0">
                                <v-col cols="10" lg="8" md="8" align="center">
                                    <v-form v-model="isSignUpFormValid">
                                        <v-row justify="center" align="center" no-gutters class="ma-0 pb-2 pb-lg-2">
                                            <!-- :class="addImage && isMobile ? 'mb-2' : ''" -->
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <v-avatar :size="isMobile ? '80' : '120'" style="cursor: pointer;" :rules="profileImageRules" @click.stop="openProfileImageFile">
                                                    <v-img :src="profileImageURL ? previewImage : require('../../assets/no-user.png')"></v-img>
                                                    <v-overlay v-if="!showTC && !loading" absolute :opacity="profileImageURL ? '.25' : '.50'" :color="profileImageError ? 'red': 'grey darken-4'">
                                                        <v-icon :style="profileImageURL ? 'opacity: .75;' : ''">{{profileImageURL ? 'mdi-file-image-remove' : 'mdi-file-image-plus'}}</v-icon>
                                                        <v-file-input v-model="profileImageInput" ref="fileInput" type="file" accept="image/*" @change="getProfileImageFile()" style="display: none;"/>
                                                    </v-overlay>
                                                </v-avatar>
                                            </v-col>
                                            <v-col cols="12">
                                                <span :class="profileImageError ? 'red--text' : 'transparent--text'" class="text-caption font-weight-light" style="cursor: default;">*Image file must be less than 6 MB.</span>
                                            </v-col>
                                            <!-- <transition v-if="addImage" name="fade">
                                                <div v-if="isMobile" class="d-flex flex-column px-8">
                                                    <v-icon color="red" class="align-self-center">mdi-arrow-up</v-icon>
                                                    <span class="rounded-xl red white--text px-2">Add a Profile Image?</span>
                                                    <span class="rounded-xl primary white--text pa-0 ma-0" style="cursor: pointer;" @click.stop="signUpWithoutImage">No Thanks</span>
                                                </div>
                                                <div v-else class="d-flex flex-column px-8">
                                                    <v-icon color="red" class="align-self-center">mdi-arrow-up</v-icon>
                                                    <span class="rounded-xl red white--text pl-2">Add a Profile Image? <span class="rounded-xl primary white--text px-1" style="cursor: pointer;" @click.stop="signUpWithoutImage">No Thanks</span></span>
                                                </div>
                                            </transition> -->
                                        </v-row>
                                        <v-text-field v-model="newFullname" label="Full Name" outlined required validate-on-blur prepend-inner-icon="mdi-card-account-details" color="primary" :rules="fullnameRules" @change="removeHintAndHelpIfNecessary()"></v-text-field>
                                        <v-text-field v-model="newEmail" label="Email" outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" type="email" :rules="emailRules" @change="removeHintAndHelpIfNecessary()"></v-text-field>
                                        <v-text-field v-model="newPassword" label="Password" :type="showPassword ? 'text' : 'password'" outlined required validate-on-blur prepend-inner-icon="mdi-lock" @change="removeHintAndHelpIfNecessary()" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" :rules="passwordRules" @click:append="showPassword = !showPassword"></v-text-field>
                                        <v-row justify="start" align="center" class="mx-0 mb-0 mt-n5 pa-0">
                                            <v-checkbox v-model="acceptsTC" :ripple="false"/>
                                            <span class="text-caption text-sm-subtitle-1">I agree to the <i style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="showTC = true">terms and conditions</i></span>
                                        </v-row>

                                        <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light">{{hintText}}</p>
                                        <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">Need Help? <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep">Contact Us</span></p>
                                    </v-form>
                                </v-col>
                            </v-row>

                            <v-card-actions>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center" class="pb-1">
                                        <!-- checkProfileImageForCorrectSignUp replace with signUp() below when ready to use addImage feature-->
                                        <v-btn rounded large color="primary" @click="signUp()" :disabled="!validateInputs()" class="">Create Account</v-btn>
                                        <v-row align="center" no-gutters class="my-0 mx-10 pb-1">
                                            <v-divider class="primary"/>
                                                <span class="pa-1 mx-2 mt-2 rounded-xl primary--text">OR</span>
                                            <v-divider class="primary"/>
                                        </v-row>
                                        <v-btn icon @click="signInWithGoogle()">
                                            <img src="../../assets/google-icon.svg" alt="google-logo" width="35">
                                        </v-btn>
                                        <!-- <v-btn icon class="ml-2" @click="signInWithFacebook()">
                                            <v-icon color="blue darken-2" size="45">mdi-facebook</v-icon>
                                        </v-btn> -->
                                    </v-col>

                                    <v-layout column align-center justify-center class="mt-1">
                                        <span class="text-subtitle-1 text-lg-h6 font-weight-light">Already have an Account?
                                            <router-link to="/login" style="color:primary;">Login</router-link>
                                        </span>
                                    </v-layout>
                                </v-row>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="signUpComplete" justify="center" align="start" style="max-width: 100%;" class="mx-0">
                    <v-col cols="11" xl="3" lg="4" md="8" sm="10">
                        <v-card elevation="8" light class="rounded-xl">
                            <v-row justify="center" no-gutters class="py-2">
                                <v-col cols="8" align="center">
                                    <v-btn outlined x-large icon :ripple="false" style="cursor: default; background-color: #61892F !important;">
                                        <v-btn outlined icon large color="primary" :ripple="false" style="cursor: default; background-color: white !important;">
                                            <v-icon large color="primary">mdi-email</v-icon>
                                        </v-btn>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-title style="word-break: normal" class="mb-5">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-h6 text-sm-h5 font-weight-light">Please Verify Your Email</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-subtitle style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">Thank you for joining! We've sent a verification email to</span><br/>
                                        <span class="black--text text-wrap text-subtitle-1 font-weight-medium">{{this.newEmail}}</span>
                                    </v-col>
                                </v-row>
                            </v-card-subtitle>
                            <v-card-text style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">Click the link in that email to complete your signup. If you don't see it you may need to <span class="black--text text-wrap text-subtitle-2 font-weight-medium">check your spam</span> folder.</span><br/><br/>
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">You can close this page once you have verified your email.</span><br/><br/>
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">Still can't find the email?</span><br/>
                                        <v-btn small text class="primary mt-2" @click.stop="resendVerificationEmail">Resend Email</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions style="word-break: normal" class="mb-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="10" align="center">
                                        <span class="black--text text-wrap text-subtitle-2 font-weight-light">Need Help? <i style="cursor: pointer;" class="primary--text text-subtitle-2 text-decoration-underline" @click.stop="emailKeep()">Contact Us</i></span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-main>
        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
import TCModal from "./TCModal.vue";
export default {
    name: "sign-up",
    metaInfo: {
        title: 'Sign Up - tawqin'
    },
    components: {
        TCModal,
    },
    data: function() {
        return {
            profileImageURL: "",
            profileImageInput: null,
            profileImageRules: [(value) => !value || value.size < this.profileImageFileLimit || "Profile Image must be less than 6 MB",],
            profileImageFileLimit: 6000000, // 6MB
            profileImageError: false,
            previewImage: "",
            newFullname: "",
            fullnameRules: [
                (v) => !!v || "Full Name is required",
                (v) => v.length <= 50 || "Full Name must be 50 characters or less",
                (v) => /^[A-Za-z ]+$/.test(v) || "Full Name must only contain alphabet characters and spaces"
            ],
            newEmail: "",
            emailRules: [
                (v) => !!v || "Email is required",
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
            ],
            newPassword: "",
            passwordRules: [
                (v) => v.length >= 8 || "Password Must Be 8 Characters Or More",
            ],
            showPassword: false,
            acceptsTC: false,
            showTC: false,
            loading: false,
            hint: false,
            hintText: "",
            signUpComplete: false,
            isSignUpFormValid: false,
            needHelp: false,
            // helpMessage: null, // for updating message sent in emailKeep()
            // addImage: false, // can be used to suggest a user add a profile image
        };
    },
    methods: {
        validateInputs: function() {
            return !(this.newFullname === "" || this.profileImageError || this.profileImageURL.size > this.profileImageFileLimit || this.newEmail == "" || this.newPassword == "" || this.newPassword.length < 8 || !this.acceptsTC || !this.isSignUpFormValid);
            // if (this.newFullname === "" || this.profileImageError || this.profileImageURL.size > this.profileImageFileLimit /*|| this.newUsername.length > 30 || this.newUsername == ""*/ || this.newEmail == "" || this.newPassword == "" || this.newPassword.length < 8 /*|| this.newPassword != this.confirmNewPassword*/ || !this.acceptsTC || !this.isSignUpFormValid) {
            //     return false;
            // } else {
            //     return true;
            // }
        },
        removeHintAndHelpIfNecessary: function () {
            if (this.hint || this.needHelp) {
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
        },
        openProfileImageFile: function() {
            // if (this.addImage) this.addImage = false;
            if (!this.previewImage) { // called when user clicks to add an image
                if (this.profileImageError) this.profileImageError = false;
                this.$refs.fileInput.$refs.input.click(); // open fileInput for image select
            } else { // called when user has clicked to remove the selected image
                this.$window.URL.revokeObjectURL(this.previewImage);
                this.profileImageURL = "";
                this.profileImageInput = null;
                this.previewImage = "";
            }
        },
        getProfileImageFile: function() { // called each time a user selects an image
            if (this.previewImage) { // should not get called // called when a user has selected an image that exceeds this.profileImageFileLimit
                this.$window.URL.revokeObjectURL(this.previewImage);
                this.profileImageURL = "";
            } else { // called when a user has selected an image
                if (!this.profileImageInput) { // i think this has to do with addImage feature
                    // if (this.validateInputs()) this.addImage = true;
                    return;
                }
                if (this.profileImageInput !== null && this.profileImageInput.size > this.profileImageFileLimit) { // image exceeds this.profileImageFileLimit
                    // if (this.addImage) this.addImage = false;
                    this.profileImageError = true;
                    this.profileImageInput = null;
                } else { // image selected meets the criteria, set it
                    this.previewImage = this.$window.URL.createObjectURL(this.profileImageInput);
                    this.profileImageURL = this.profileImageInput;
                    // if (this.addImage) this.signUp();
                }
            }
        },
        setAgree: function(agree) {
            this.acceptsTC = agree;
            this.showTC = false;
        },
        // uncomment below when ready to use addImage feature
        // checkProfileImageForCorrectSignUp: function() { 
        //     if (this.profileImageURL !== "") { // user is trying to upload a profile image
        //         this.signUp();
        //     } else { // no image
        //         if (!this.addImage) {// ask user if they want to upload an image
        //             this.addImage = true;
        //         } else { // user has been asked, and clicked 'create account' again.
        //             this.signUpWithoutImage();
        //         }
        //     }
        // },
        signUp: async function () {
            let userCredentials = {};
            try {
                // if (this.addImage) this.addImage = false
                this.loading = true;
                userCredentials = await this.createUserWithEmailAndPassword();
                const userData = await this.setUserData(1, userCredentials.user, false);
                await this.updateUserProfile(userCredentials.user, userData.profilePicture);
                await this.setFirebaseUserData(userCredentials.user.uid, userData);
                await this.sendEmailVerification();
            } catch (error) {
                console.error(error);
                switch (error.code) { // moved here on February 15, 2024. This is because when a error is thrown in the above try statements it falls to this catch.
                    // if we want the below case statements to run (ie the retry logic) we have to have these statements in the main catch.
                    case "auth/email-already-in-use": // thrown if there already exists an account with the given email address
                        await this.checkAndRetrySignInWithEmailAndPassword();
                        break;
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The email you entered is invalid. Please try again.*";
                        this.newEmail = "";
                        this.newPassword = "";
                        break;
                    // case "auth/operation-not-allowed": // thrown if email/password accounts are not enabled // removed by Scott Brady on February 15, 2024.
                    //     // should go to default case if commented out.
                    //     break;
                    case "auth/weak-password": // thrown if the password is not strong enough
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The password you chose is not strong enough. Please try a new one.*";
                        this.newPassword = "";
                        break;
                    default:
                        console.error("An error occurred during sign-up:", error.message);
                        // console.error("");
                        // console.error("error while creating the user with email and password: ", error.message);
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetSignUpState(); // reset and try again.
                        break;
                }
            }
        },
        // uncomment below when ready to use addImage feature
        // signUpWithoutImage: async function () {
        //     try {
        //         // if (this.addImage) this.addImage = false;
        //         this.loading = true;
        //         let userCredentials = await this.createUserWithEmailAndPassword();
        //         await this.updateUserProfile(userCredentials, uploadedProfileImageURL);
        //         const userData = await this.setUserData(2, userCredentials, false);
        //         await this.setFirebaseUserData(userCredentials.user.uid, userData);
        //         await this.sendEmailVerification();
        //     } catch (error) {
        //         console.error("An error occurred during sign-up:", error);
        //         this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
        //         this.resetSignUpState(); // Reset any necessary state variables
        //     }
        // },
        createUserWithEmailAndPassword: async function () {
            try {
                const userCredentials = await firebase.auth.createUserWithEmailAndPassword(this.newEmail, this.newPassword);
                return userCredentials;
            } catch (error) {
                throw error;
            }
        },
        signInWithGoogle: function () {
            this.loading = true;
            var provider = new firebase.authentication.GoogleAuthProvider();
            firebase.auth.useDeviceLanguage(); // will open the popup in the language the user has selected as their default browser language.
            this.signInWithPopup(provider);
        },
        signInWithFacebook: function() {
            this.loading = true;
            var provider = new firebase.authentication.FacebookAuthProvider();
            this.signInWithPopup(provider);
        },
        signInWithPopup: async function (provider) {
            try {
                let signInWithPopup = await firebase.auth.signInWithPopup(provider);
                const user = signInWithPopup.user;
                let userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists) { // user has already signed up
                    if (!userInDatabase.data().emailVerified) { // check if user has verified email, if not, set to verified
                        await firebase.db.collection("userData").doc(user.uid).update({ emailVerified: true });
                    }
                    this.$router.replace({ path: "/home" }); // user is logged in, set route to /home
                } else { // user does not exist, create them
                    let responseFromServerForVerifiedEmail = await this.$http.put(`emailVerified/${user.uid}`);
                    const userData = await this.setUserData(3, user, responseFromServerForVerifiedEmail.data.emailVerified);
                    await this.setFirebaseUserData(user.uid, userData);
                    this.loading = false;
                    this.$router.replace({ path: "/home", query: { newUser: true} });
                }
            } catch (error) {
                // ALL COMMENTED OUT CASES SHOULD BE CAUGHT IN DEFAULT CASE
                console.error(error);
                this.loading = false;
                switch (error.code) {
                    case "auth/account-exists-with-different-credential": // thrown when a user tries to sign in with Facebook or Google when they have already signed in with one or the other.
                        // console.log("auth/account-exists-with-different-credential");
                        // https://firebase.google.com/docs/auth/web/facebook-login#handling-account-exists-with-different-credential-errors
                        // https://cloud.google.com/identity-platform/docs/reference/rest/v1/accounts/signUp
                        // https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection#overview
                        // let pendingCred = error.credential; // 1. store this credential in temporary storage.
                        // 2. notify user that they have already linked this email with another credential.
                        // let result = await firebase.auth.signinWithPopup(userSelectedProvider); // 3. signin the user with their prefered provider (email and password, google, facebook).
                        // At this point the user has signed in and firebase.auth.currentUser should return the signed in user.
                        // 4. retrieve the pendingCred from temporary storage.
                        // let user = await linkWithCredential(result.user, pendingCred); // 5. link the accounts.
                        // 6. continue to app.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*An account has already been created with this email. If you have already created an account using Google, please select it below. Otherwise, try signing in with your email and password. If you feel this is a mistake please contact us below.*";
                        break;
                    // case "auth/auth-domain-config-required": // thrown if authDomain configuration is not provided when calling firebase.initializeApp().
                    //     break;
                    case "auth/cancelled-popup-request": // thrown if multiple popup operations are triggered, only one allowed at a time. All fail if thrown.
                        // console.log("auth/cancelled-popup-request");
                        this.loading = true;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*You have opened multiple popups. Please close them and retry.*";
                        break;
                    // case "auth/operation-not-allowed": // thrown if the type of account corresponding to the credential is not enabled, ie if user tries to signin with Apple when we have not allowed Apple signin.
                    //     break;
                    // case "auth/operation-not-supported-in-this-environment": // thrown if this operation is not supported in the environment your application is running on.
                    //     break;
                    case "auth/popup-blocked": // thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*Popup was blocked by the browser. Please allow popups to sign up with Google.*";
                        break;
                    case "auth/popup-closed-by-user": // thrown if the popup window is closed by the user without completing the sign in to the provider.
                        this.resetSignUpState();
                        break;
                    // case "auth/unauthorized-domain": // thrown if the app domain is not authorized for OAuth operations for your Firebase project.
                    //     break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*User has been disabled from using tawq.in. Please contact support for help.*";
                        // this.helpMessage = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
                        break;
                    default:
                        // this.hintText = "**";
                        // console.error("error signing in with third party!", error);
                        // console.error("error signing in with third party!");
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetSignUpState();
                        break;
                }
            }
        },
        setUserData: async function (from, user, emailVerified) {
            // console.log("setUserData: ", from, user, emailVerified);
            // KEY - from (location): 1. signUp() 2. signUpWithoutImage() 3. signInWithPopup() 4. checkAndRetrySignInWithEmailAndPassword()
            return new Promise (async (resolve, reject) => {
                const userData = {
                    userId: user.uid,
                    fullname: (from === 3) ? user.displayName : this.newFullname,
                    email: from === 3 ? user.email : this.newEmail,
                    username: from === 3 ? user.displayName : this.newFullname,
                    profanityFilter: false,
                    darkTheme: true,
                    emailVerified: emailVerified,
                    pushToTawq: true,
                    postsCreated: 0,
                    playbackStyle: false, // default to pauseplay
                    captionAudio: true,
                    dualDevice: false,
                    signUpMethod: from === 3 ? user.providerData[0].providerId : "email",
                    signUpDate: new Date(),
                }
                userData.profilePicture = from === 3 ? user.photoURL : ((this.profileImageURL !== "") ? await this.uploadProfileImage(user).catch((error) =>{return reject(false)}) : "");/*(from === 1) ? await this.uploadProfileImage(userCredentials) : "" ;*/
                return resolve(userData);
            });
        },
        uploadProfileImage: function (userCredentials) {
            return new Promise ((resolve, reject) => {
                const metadata = {
                    cacheControl: 'public,max-age=604800' // 1 week
                }
                var file = this.profileImageURL;
                var storageRef = firebase.storage.ref("userProfilePics");
                var photoRef = storageRef.child("/" + userCredentials.uid + "/1");
                var uploadTask = photoRef.put(file, metadata);
                uploadTask.on("state_changed",() => {/*NEXT*/},(error) => {
                    /*ERROR*/
                    console.error("error uploading the profile image: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                },() => {
                    /*COMPLETE*/
                    var downloadURL = uploadTask.snapshot.ref.getDownloadURL();
                    resolve(downloadURL);
                });
            });
        },
        updateUserProfile: function (userCredentials, uploadedProfileImageURL) {
            // console.log("updateUserProfile: ", userCredentials, uploadedProfileImageURL);
            return new Promise((resolve, reject) => {
                userCredentials.updateProfile({
                    displayName: this.newFullname, //this.newUsername,
                    photoURL: uploadedProfileImageURL,
                }).then(() =>{
                    resolve(true);
                }).catch((error) => {
                    console.error("error updating the profile info: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetSignUpState(); // reset the sign up state
                    reject(false);
                });
            });
        },
        setFirebaseUserData: function (docId, userData) {
            // console.log("setFirebaseUserData: ", docId, userData);
            return new Promise((resolve, reject) => {
                firebase.db.collection("userData").doc(docId).set(userData).then(() => {
                    resolve(true);
                }).catch((error) => {
                    console.error("error creating firebase userData: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                });
            });
        },
        sendEmailVerification: function () {
            // console.log("Send Verification Email");

            /* FOR TESTING ONLY */
            // this.signUpComplete = true;
            // this.loading = false;
            // return true;
            /* END FOR TESTING ONLY */

            return new Promise((resolve, reject) => {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: (process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true'),
                }
                firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(() => {
                    this.signUpComplete = true;
                    this.loading = false;
                    resolve(true);
                }).catch((error) => {
                    console.error("error sending email verification: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetSignUpState(); // reset the sign up state
                    return reject(false);
                });
            });
        },
        checkAndRetrySignInWithEmailAndPassword: async function () { // IMPLEMENTION OF RETRY LOGIC
            try {
                let signInWithEmailAndPassword = await firebase.auth.signInWithEmailAndPassword(this.newEmail, this.newPassword);
                if (signInWithEmailAndPassword.user) { // user has already been created via email and password and has now been signed in
                    let user = await firebase.db.collection("userData").doc(signInWithEmailAndPassword.user.uid).get();
                    if (!user.exists) { // User does NOT exist in the 'userData' collection of the database, we will try and create them.
                        const userData = await this.setUserData(4, signInWithEmailAndPassword.user, false);
                        await this.updateUserProfile(signInWithEmailAndPassword.user, userData.profilePicture);
                        await this.setFirebaseUserData(signInWithEmailAndPassword.user.uid, userData);
                        await this.sendEmailVerification();
                    } else { // User exists in the 'userData' collection of the database.
                        if (this.profileImageInput) { // user is trying to sign up with a new profile image, over write the one that is saved or create new if it doesn't exist.
                            let profilePictureDownloadURL = await this.uploadProfileImage(signInWithEmailAndPassword.user);
                            await this.updateUserProfile(signInWithEmailAndPassword.user, profilePictureDownloadURL);
                            await firebase.db.collection("userData").doc(signInWithEmailAndPassword.user.uid).set({
                                profilePicture: profilePictureDownloadURL
                            }, {merge: true});
                        }
                        if (!signInWithEmailAndPassword.user.emailVerified || !user.data().emailVerified) { // User has NOT verified email, send them a new verification email link.
                            await this.sendEmailVerification();
                        } else { // Email is verified, login user. Replace route with '/home'.
                            this.loading = false;
                            this.$router.replace({ path: "/home" });
                        }
                    }
                } else { // User exists in Firebase Authentication, but we couldn't sign them in. Reset and retry sign up!
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetSignUpState(); // reset the sign up state
                    return false;
                }
            } catch (error) {
                console.error(error);
                switch (error.code) {
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The email you entered is invalid. Please try again.*";
                        this.newEmail = "";
                        this.newPassword = "";
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*User has been disabled from using tawq.in. Please contact support for help.*";
                        break;
                    case "auth/user-not-found": // thrown if there is no user corresponding to the given email
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The User with this email and password can't be found.*";
                        break;
                    case "auth/wrong-password": // thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        // this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, Google or Facebook, please select the one you used below.*"; // uncomment when ready to use Facebook signup/login.
                        this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, Google, please select it below.*";
                        this.newPassword = "";
                        break;
                    default:
                        console.error("error signing in with email and password!", error.message);
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetSignUpState();
                        break;
                }
            }
        },
        emailKeep: async function () {
            if (this.needHelp) {
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
            // console.log("helpMessage: ", this.helpMessage);
            var domain = this.newEmail.split('@')[1];
            const subject = "Sign Up Help";
            const message = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                case 'gmail.com':
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                default:
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
            } // end of email/gmail switch
        },
        resendVerificationEmail: function () {
            const user = firebase.auth.currentUser;
            if (user) {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
                }
                user.sendEmailVerification(actionCodeSettings).then(() => {
                    this.$store.commit('alertUser', { show: true, text: `Verification email has been sent.`, type: 'snackbar' });
                }).catch((err) => {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong sending the verification email. Please try again.`, type: 'snackbar' });
                });
            } else {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'snackbar' });
                console.error("No user! Refresh the page and try again.");
            }
        },
        resetSignUpState: function () {
            // if (this.addImage) this.addImage = false;
            this.profileImageURL = "";
            this.newFullname = "";
            this.newEmail = "";
            this.newPassword = "";
            this.acceptsTC = false;
            this.loading = false;
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
            this.profileImageInput= null;
            this.previewImage= "";
            this.profileImageError= false;
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: async function(){
        if (firebase.auth.currentUser) firebase.auth.signOut();
    }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
p i {
    text-decoration: underline;
    cursor: pointer;
}
</style>