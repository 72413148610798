import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import minifyTheme from 'minify-css-string';


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#86C232',
        secondary: '#6B6E70',
        accent: '#61892F',
        info: '#d1d1d1',
        success: '#4CAF50',
        warning: '#FFC107',
        background: colors.grey.lighten4,
        // background: '#FFFFFF',
        smoke: '#F5F5F5',
        icon: '#FFFFFF',
        title: '#000000',
        subtitle: '#FFFFFF',
        invert: '#424242',
        reverseInvert: '#FFFFFF',
        inverseGrey: '#EEEEEE', // grey lighten 3
        inputPlaceholder: colors.grey.base,
        inputColor: colors.grey.darken4,
        // for chartjs graphs
        graphPrimary: '#86C232',
        graphSecondary: '#FFC107',
        graphAccent: '#61892F',
      },
      dark: {
        primary: '#61892F',
        primaryAccent: '#86c232', // lighter green color
        secondary: colors.grey.lighten2,
        accent: '#FFC107',
        info: '#000000',
        success: '#4CAF50',
        warning: '#FFC107',
        background: colors.grey.darken4,
        smoke: '#F5F5F5',
        icon: '#FFFFFF',
        title: '#FFC107',
        subtitle: '#FFFFFF',
        invert: '#FFFFFF',
        reverseInvert: '#000000',
        inverseGrey: '#424242', // grey darken 3
        inputPlaceholder: colors.grey.base,
        inputColor: colors.grey.lighten4,
        // for chartjs graphs
        graphPrimary: '#61892F',
        graphSecondary: '#FFC107',
        graphAccent: '#86C232',
      },
    },
    options: {
      customProperties: true,
      minifyTheme: minifyTheme,
      // themeCache: {
      //   get: key => localStorage.getItem(key),
      //   set: (key, value) => localStorage.setItem(key, value),
      // },
    },
  },
});
