<template>
    <v-container class="py-0">
        <v-row class="justify-center align-center">
            <span class="text-md-h3 text-h4 font-weight-light">Send Feedback</span>
        </v-row>
        <v-row justify="center">
        <p>* all fields required *</p>
        </v-row>
        <br/>
        <!-- <v-row class="justify-md-center justify-space-around flex-column">
            <v-col align="center">
                <span class="text-center">{{submitStatus}}</span>
                <br/>
                <span>Report an issue</span>
                <br/>
                <span>A problem and how it occured</span>
                <br/>
                <span>A feature you like and why</span>
                <br/>
                <span>Ways we can improve</span>
                <br/>
                <span>Features you would like to see in the future</span>
                <br/>
                <span>Etc.</span>
                <br/>
                <span>Any Feedback Helps.</span>
            </v-col>
        </v-row> -->
        <v-row justify="center">
            <v-col align="center" xl="4" md="6" sm="8" xs="12">
                <form>
                    <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    :counter="30"
                    label="Name"
                    required
                    outlined
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                    ></v-text-field>
                    <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    type="email"
                    required
                    outlined
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    ></v-text-field>
                    <!-- <v-text-field
                    v-model="number"
                    :error-messages="numberErrors"
                    :counter="16"
                    label="Phone Number (Include country code if NOT in US)"
                    outlined
                    @input="$v.number.$touch()"
                    @blur="$v.number.$touch()"
                    ></v-text-field> -->
                    <!-- <v-text-field
                    v-model="page"
                    :error-messages="pageErrors"
                    label="What page are you reporting feedback on?"
                    required
                    @input="$v.page.$touch()"
                    @blur="$v.page.$touch()"
                    ></v-text-field> -->

                    <!-- <v-select
                    v-model="select"
                    :items="pages"
                    :error-messages="selectErrors"
                    label="Select the page are you reporting feedback on"
                    required
                    outlined
                    @change="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                    ></v-select> -->

                    
                    <v-textarea
                    v-model="description"
                    :error-messages="descriptionErrors"
                    label="Description (If mobile please include device used.)"
                    required
                    outlined
                    @input="$v.description.$touch()"
                    @blur="$v.description.$touch()"
                    ></v-textarea>

                    <!-- <v-row justify="center">
                    <v-checkbox
                    v-model="checkbox"
                    label="Do you agree?"
                    @change="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                    class="justify-center"
                    ></v-checkbox>
                    </v-row> -->

                    <v-row>
                        <v-col align="center">
                            <span class="text-center red--text" v-if="submitStatus === 'ERROR'">Please fill out the form correctly.</span>
                            <span class="text-center " v-if="submitStatus === 'PENDING'">Sending...</span>
                            <span class="text-center " v-if="submitStatus === 'OK'">Thanks for your submission! We will contact you with any questions.</span>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn class="mr-4" v-if="currentUser == 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || 
                            currentUser == '9hg1CKOXMFefAWLkUMaBGv7j40u1' || currentUser == 'lXVXV6nvtLTRWJQbtvg9zH8C1hL2'"
                            color="primary" @click="show">Feedback</v-btn>
                            <v-btn class="mr-4" color="primary" @click="clear">clear</v-btn>
                            <v-btn color="primary" @click="submit">submit</v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
        <v-container v-if="showFeedback">
            <v-row justify="center">
                <!-- <span v-if="currentUser == 'lXVXV6nvtLTRWJQbtvg9zH8C1hL2'" class="text-center text-md-h3 text-h4 font-weight-light">Here is all the feedback we have so far</span> -->
                <span class="text-center text-md-h3 text-h4 font-weight-light">Here is all the feedback we have so far</span>
            </v-row>
            <v-row justify="center">
                <v-col v-for="(feed, index) in feedback" :key="index" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                    <v-card>
                        <v-card-title class="justify-center">{{feed.name}}</v-card-title>
                        <v-card-text>{{feed.email}}</v-card-text>
                        <!-- <v-card-text>{{feed.number}}</v-card-text> -->
                        <v-card-text>{{feed.date}}</v-card-text>
                        <!-- <v-card-text><span class="font-weight-bold text-decoration-underline">Page</span>: {{feed.page}}</v-card-text> -->
                        <v-card-text><span class="font-weight-bold text-decoration-underline">Description</span>: {{feed.description}}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import firebase from "../../firebaseConfig.js";
import { validationMixin } from 'vuelidate'
import { required, maxLength, email,/* numeric, minLength*/ } from 'vuelidate/lib/validators'
export default {
    metaInfo: {
        title: 'Feedback - tawqin'
    },
    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(30) },
        email: { required, email },
        // select: { required },
        description: { required },
    },
    components: {
    },
    data: () => ({
        currentUser: firebase.auth.currentUser.uid,
        showFeedback: false,
        feedbackListener: null,
        feedback: [],
        name: firebase.auth.currentUser.displayName,
        description: '',
        email: firebase.auth.currentUser.email,
        submitStatus: null,
        // select: null,
        // pages: [
        //     "Post Creation",
        //     "Viewing a Post",
        //     "Contributions",
        //     // "Mobile Controller (mobile create)",
        //     "Home",
        //     "Profile",
        //     "Groups",
        //     "Friends",
        //     "Settings",
        //     "Notifications",
        // ],
    }),
    computed: {
        descriptionErrors () {
            const errors = []
            if (!this.$v.description.$dirty) return errors
            !this.$v.description.required && errors.push('Description is required')
            return errors
        },
        // selectErrors () {
        //     const errors = []
        //     if (!this.$v.select.$dirty) return errors
        //     !this.$v.select.required && errors.push('Page is required')
        //     return errors
        // },
        nameErrors () {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.maxLength && errors.push('Name must be at most 30 characters long')
            !this.$v.name.required && errors.push('Name is required.')
            return errors
        },
        // numberErrors () {
            // const errors = []
            // if (!this.$v.number.$dirty) return errors
            // !this.$v.number.numeric && errors.push('Number must use numeric characters')
            // !this.$v.number.maxLength && errors.push('Number must be at most 16 characters long')
            // !this.$v.number.minLength && errors.push('Number must be at least 10 characters long')
            // !this.$v.number.required && errors.push('Number is required.')
            // return errors
        // },
        emailErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Must be valid e-mail')
            !this.$v.email.required && errors.push('E-mail is required')
            return errors
        },
    },
    methods: {
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                var feedbackRef = firebase.db.collection("feedback").doc();
                feedbackRef.set({
                    userId: this.currentUser,
                    name: this.name,
                    email: this.email,
                    // number: this.number,
                    // page: this.select,
                    description: this.description,
                    date: new Date(),
                });
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                this.submitStatus = 'OK'
                this.$v.$reset()
                this.name = ''
                this.email = ''
                // this.number = ''
                this.description = ''
                // this.select = null
                }, 500)
            }
        },
        clear () {
            this.$v.$reset()
            this.name = ''
            this.email = ''
            // this.number = ''
            this.description = ''
            this.submitStatus = null
            // this.select = null
            // this.checkbox = false
        },
        show: function () {
            this.showFeedback = !this.showFeedback;
            // if (this.showFeedback == true) {
            //     this.getFeedback();
            // }
        },
        getFeedback: function () {
            var feedbackRef = firebase.db.collection("feedback");
            this.feedbackListener = feedbackRef.onSnapshot((snapshot) => {
                this.feedback = [];
                if(!snapshot.empty) {
                    snapshot.forEach((doc) => {
                        var feed = {
                            name: doc.data().name,
                            email: doc.data().email,
                            // number: doc.data().number,
                            description: doc.data().description,
                            page: doc.data().page,
                            userId: doc.data().userId,
                            date: doc.data().date.toDate().toLocaleDateString(),
                        };
                        this.feedback.push(feed);
                    });
                }
            });
        },
    },
    created: function () {
        this.getFeedback();
    },
    beforeDestroy: function () {
        this.feedbackListener();
    },
    destroyed: function () {},
}
</script>
