import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import router from '../router/router'
import axios from 'axios';
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag"
import languageCodes from "./language-codes.js";
// import logger from "./logger.js";
// import { VueMasonryPlugin } from "vue-masonry";


// Vue.use(VueMasonryPlugin);
Vue.use(Vuex)
Vue.use(VueMeta)
/* google analytics tracking for vue */
Vue.use(VueGtag, {
    config: { id: "UA-59943727-3" },
    enabled: process.env.NODE_ENV === 'production' && window.location.host != '0-0-0-dot-tawq-in-www.appspot.com' ? true : false,
    // enabled: process.env.NODE_ENV === 'development' ? true : false,
    bootstrap: false,
}, router)

// import Hotjar from 'vue-hotjar'
// Vue.use (Hotjar, {
//     id: '2791229',
//     isProduction: process.env.NODE_ENV === 'production' ? true : false,
// })

import firebase from '../firebaseConfig.js'
import store from './store'

Vue.prototype.$navigator = window.navigator;
Vue.prototype.$window = window;
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
// Vue.prototype.$log = Logger;
// Vue.prototype.$log = (p1, p2, p3) => {
//     logger.log(p1,p2,p3);
// };

// Vue.prototype.$setLoggerAdmin = (admin) => {
//     logger.setAdmin(admin);
// }
// Any language that we would like to add from google can be inserted below
Vue.prototype.$languageCodes = languageCodes.languageCodes;

axios.defaults.baseURL = "https://tawq-in-create.appspot.com";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';


/* response interceptor */
axios.interceptors.response.use((response) => {
    if (response.status == 200 || response.status == 201 || response.status == 308){
        // This is called 4 times when initializing the store (index.js).
        // This is because on initialization, the store calls the server to get all the stripe
        // information for the current user. We get the CUSTOMER, SUBSCRIPTION, INVOICES and PRODUCT.
        return response;
    }
}, async (error) => {
    if (error.response){
        if (error.response.status == 308){
            return error.response;
        }
        const originalRequest = error.config;
        /* The server will send a 401 response if the api key has become expired. When it does, we will request a new one and resend the original request */
        if (error.response.status === 401 && !originalRequest._retry){
            if(firebase.auth.currentUser){
                /* get a new token, then re send the original request only one time! */
                originalRequest._retry = true;
                let token = await firebase.auth.currentUser.getIdToken();
                Vue.prototype.$apiKey = token;
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axios(originalRequest);
            } else {
                console.error("401 reponse but no firebase user");
            }
        }
        else if (error.response.status === 403){
            //TODO: log them out!
            console.error("Error:", error.response.status)
        }
    }
    return Promise.reject(error);
});

let app
firebase.auth.onAuthStateChanged(() => {
    /* only try to get a token if there is a current user, if there is not
        still load the app without the api token */
    if (firebase.auth.currentUser) {
        firebase.auth.currentUser.getIdTokenResult().then((token) => {
            Vue.prototype.$apiKey = token.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token.token}`;
            if (!app) {
                app = new Vue({ el: '#app', router, vuetify, store, render: h => h(App),}).$mount('#app');
            }
        }).catch(err => {
            console.error("API connection issues", err);
            if (!app) {
                app = new Vue({ el: '#app', router, vuetify, store, render: h => h(App),}).$mount('#app');
            }
        })
    } else {
        if (!app) {
            app = new Vue({ el: '#app', router, vuetify, store, render: h => h(App),}).$mount('#app');
        }
    }
})


