<template>
    <v-dialog persistent :value="true" max-width="700" overlay-color="grey darken-1" overlay-opacity=".5">
        <v-card color="primary" class="rounded-xl">
            <v-carousel reverse hide-delimiters hide-delimiter-background next-icon="mdi-chevron-left" prev-icon="mdi-chevron-right" :continuous="false" class="custom-ar-carousel rounded-xl">
                <v-carousel-item v-for="(scene, i) in scenes" :key="i">
                    <v-row justify="center" align="center" no-gutters style="height: 100%">
                        <v-col cols="12" style="height: 65%" class="flex-grow-1 white">
                            <v-img contain max-height="90%" min-height="90%" :src="scene.src"></v-img>
                        </v-col>
                        <v-col cols="12" style="height: 40%" class="flex-shrink-1">
                            <v-card-title style="word-break: normal;" class="justify-center align-center">
                                <span class="text-h5 font-weight-bold accent--text text-center mb-n5">{{scene.title}}</span>
                            </v-card-title>
                            <v-card-title style="word-break: normal;" class="justify-center align-center">
                                <span class="text-subtitle-2 font-weight-medium white--text text-center">{{scene.text}}</span>
                                <v-btn v-if="scene.slide === 5" text color="accent" @click.stop="$emit('tour-complete')" style="position: absolute; bottom:1%; left:0;">مكتمل</v-btn> <!-- COMPLETE -->
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ArabicWelcomeModal",
    props: [],
    data: function() {
        return {
            scenes: [
                { 
                    slide: 1,
                    title: "شكرًا لك على استخدام موقعنا، tawq.in!", // "Thanks for using our website, tawq.in!",
                    text: "وهذا شرح مختصر يوضح لك كيفية استخدام الخدمة.", // "This is a brief explanation that shows you how to use the service.",
                    src: require('../assets/tour/welcome.png'),
                },
                { 
                    slide: 2,
                    title: "اختر الوسائط واللغة.", //"Choose Media and Language.",
                    text: "قم بتحميل الصوت أو الفيديو من جهازك، أو الصق عنوان URL من YouTube. ثم اختر اللغة التي تريد التحدث بها.", // "Upload Audio or Video from your device, or paste in a URL from YouTube. Then choose the language you want to speak.",
                    src: require('../assets/tour/1.png'),
                },
                { 
                    slide: 3,
                    title: "التحدث والتسمية التوضيحية والتحرير.", // "Speak, caption and edit.",
                    text: `اضغط على "التسجيل والتحدث"، ثم اضغط على "إيقاف". سيتم تحويل صوتك وستظهر التسمية التوضيحية. اضغط على أيقونة القلم الرصاص للتحرير.`, // "Tap Record and Talk, then tap Stop. Your voice will be converted and the caption will appear. Tap the pencil icon to edit.",
                    src: require('../assets/tour/2.png'),
                },
                { 
                    slide: 4,
                    title: "قم بتحميل الإدخال الخاص بك.", // "Upload your entry.",
                    text: "دع الجميع يراها، أو مجرد مجموعة من أصدقائك.", // "Let everyone see it, or just a group of your friends.",
                    src: require('../assets/tour/3.png'),
                },
                { 
                    slide: 5,
                    title: "Contribute +", // "Contribute.",
                    text: "أضف صوتك إلى مشاركاتك الأخرى، أو اطلب من الآخرين إضافة أصواتهم إلى مشاركتك.", // "Add your voice to your other posts, or ask others to add their voices to your post.",
                    src: require('../assets/tour/4.png'),
                },
            ],
        };
    },
    methods: {},
}
</script>

<style>
.custom-ar-carousel .v-window__prev {
    position: absolute;
    right: 0;
    left: auto;
}

.custom-ar-carousel .v-window__next {
    position: absolute;
    left: 0;
    right: auto;
}

.v-dialog {
  box-shadow: none !important;
}

</style>