import firebase from 'firebase/compat/app';
// import "firebase/compat/functions";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';


var firebaseConfig = {
    apiKey: "AIzaSyDPGu9GnmxPZrpuIVgktc4auzgd6V7iT-Q",
    // authDomain: "radiant-inferno-red.firebaseapp.com",
    authDomain: "auth.tawq.in",
    databaseURL: "https://radiant-inferno-red.firebaseio.com",
    projectId: "radiant-inferno-red",
    storageBucket: "radiant-inferno-red.appspot.com",
    messagingSenderId: "1089438624557",
    appId: "1:1089438624557:web:ea124eee7e0e06fcbc41a4"
};

// "firebase=radiant-inferno-red"
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/*** ENABLES OFFLINE MODE ***/
// firebase.firestore().enablePersistence().catch((error) => {
//     if (error.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//     } else if (error.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//     }
// }); // Subsequent queries will use persistence, if it was enabled successfully
/*** END ENABLES OFFLINE MODE ***/

/***FOR TESTING ONLY***/
// firebase.functions().useEmulator("localhost", 5001);
// firebase.storage().useEmulator("localhost", 9199);
// firebase.functions().useEmulator("127.0.0.1", 5001);
// firebase.storage().useEmulator("127.0.0.1", 9199);
// firebase.database().useEmulator("127.0.0.1", 9000);
/***END FOR TESTING ONLY***/


const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const rtDb = firebase.database();
const authentication = firebase.auth;



export default {
    db,
    auth,
    storage,
    rtDb,
    authentication,
};

// export { db, auth, storage, rtDb }