<template>
    <div class="elevation-4 rounded-xl" @click="$store.commit('setCurrentPost', post)" :class="post.pinnedPost ? $vuetify.theme.dark ? 'accent-dark-border' : 'primary-light-border' : ''">
        <v-hover v-slot="{hover}">
            <div style="position: relative;">
                <router-link :to="post.postType === 'public' && (post.mediaType === 'video' || post.mediaType === 'audio') ? {name: 'view-post', query: { pid: post.postId, postType: post.postType, /*post: post, userData: userData*/ } /*, props: { post: post, userData: userData }*/}  : post.postType === 'group' && (post.mediaType === 'video' || post.mediaType === 'audio') ? {name: 'view-post', query: { pid: post.postId, postType: post.postType, /*gId: groupId, post: post, userData: userData */ }, /*props: { post: post, userData: userData }*/} : post.postType === 'public' && post.mediaType === 'photo' ? {name: 'view-photo-post', query: { id: post.postId, postType: post.postType, post: post } , props: { post: post }} : {name: 'view-photo-post', query: { id: post.postId, postType: post.postType, gId: groupId, post: post, userData: userData }, props: { post: post }}" class="text-decoration-none">
                    <v-img :src="thumbnailExists() ? post.mediaType == 'photo' ? `${post.thumbnail}-compressed` : post.thumbnail : getDefaultThumbnail/* require('../assets/no-thumbnail.png') */" height="250" class="rounded-xl">
                        <div v-if="(post.languageName.includes('None') && post.videoLanguageSelected)" style="position: absolute; bottom:0; left:0; width:100%;" :class="viewMore && post.tags.length == 0 && post.videoLanguageSelected.length > 38 ? 'image-overlay-language' : viewMore && post.tags.length > 0 ? 'image-overlay-hover' : 'image-overlay'">
                            <!-- <v-card-title :class="viewMore && post.tags.length == 0 && post.videoLanguageSelected.length > 38 ? 'title-drop' : 'mt-8'" class="text-h5 font-weight-bold d-block text-truncate white--text">{{post.title}}</v-card-title> -->
                            <v-card-title :class="viewMore && post.tags.length == 0 && post.videoLanguageSelected.length > 38 ? 'title-drop' : 'mt-8'" class="text-h5 font-weight-bold d-block text-truncate white--text">{{post.title}}</v-card-title>
                            <div class="date-language pl-4 mb-1" :class="hover ? null : null">
                                <span class="caption white--text text-wrap">{{(!viewMore && post.videoLanguageSelected.length > 38 && post.groupName) ? post.videoLanguageSelected.substring(0, 18) + "..." : (!viewMore && post.videoLanguageSelected.length > 38) ? post.videoLanguageSelected.substring(0, 38) + "..." : post.videoLanguageSelected}}</span>
                                <span class="caption white--text">{{post.postDate}}</span>
                            </div>
                            <v-row v-if="viewMore" class="mr-0 ml-5">
                                <div v-for="(tag,index) in post.tags" :key="index" class="mx-0 mt-2">
                                    <router-link :to="{ path: 'view-tag', query: {postType: post.postType, tag: tag,}, props: { tag: tag, postType: post.postType}}" class="text-decoration-none">
                                        <span :class="hover ? 'tag-class' : null" class="mr-1 caption white--text">{{addTag(tag)}}</span>
                                    </router-link>
                                </div>
                            </v-row>
                        </div>
                        <div v-else style="position: absolute; bottom:0; left:0; width:100%;" :class="viewMore && post.tags.length == 0 && post.languageName.length > 38 ? 'image-overlay-language' : viewMore && post.tags.length > 0 ? 'image-overlay-hover' : 'image-overlay'">
                            <!-- <v-card-title :class="viewMore && post.tags.length == 0 && post.languageName.length > 38 ? 'title-drop' : 'mt-8'" class="text-h5 font-weight-bold d-block text-truncate white--text">{{post.title}}</v-card-title> -->
                            <v-card-title :class="viewMore && post.tags.length == 0 && post.languageName.length > 38 ? 'title-drop' : 'mt-8'" class="text-h5 font-weight-bold d-block text-truncate white--text">{{post.title}}</v-card-title>
                            <div class="date-language pl-4 mb-1" :class="hover ? null : null">
                                <span class="caption white--text text-wrap">{{(!viewMore && post.languageName && post.languageName.length > 38 && post.groupName) ? post.languageName.substring(0, 18) + "..." : (!viewMore && post.languageName && post.languageName.length > 38) ? post.languageName.substring(0, 38) + "..." : post.languageName}}</span>
                                <span class="caption white--text">{{post.postDate}}</span>
                            </div>
                            <v-row v-if="viewMore" class="mr-0 ml-5">
                                <div v-for="(tag,index) in post.tags" :key="index" class="mx-0 mt-2">
                                    <router-link :to="{ path: 'view-tag', query: {postType: post.postType, tag: tag,}, props: { tag: tag, postType: post.postType}}" class="text-decoration-none">
                                        <span :class="hover ? 'tag-class' : null" class="mr-1 caption white--text">{{addTag(tag)}}</span>
                                    </router-link>
                                </div>
                            </v-row>
                        </div>
                        <div>
                            <router-link v-if="post.userId == userData.userId" :to="{ path: 'profile' }" class="text-decoration-none">
                                <span style="position: absolute; bottom:0; right:0;" class="caption username-shadow pr-4 mb-1" :class="[ $vuetify.theme.dark ? 'amber--text' : 'primary--text' ]">@{{post.username}}</span>
                            </router-link>
                            <router-link v-else :to="{name: 'view-user-profile', query: {id: post.userId}}" class="text-decoration-none">
                                <span style="position: absolute; bottom:0; right:0;" class="caption username-shadow pr-4 mb-1" :class="[ $vuetify.theme.dark ? 'amber--text' : 'primary--text' ]">@{{post.username}}</span>
                            </router-link>
                            <span style="position: absolute; bottom: 15px; right:0;" class="caption pr-4 mb-1" :class="[ $vuetify.theme.dark ? 'amber--text' : 'primary--text' ]">{{post.groupName}}</span>
                        </div>
                    </v-img>
                </router-link>

                <!-- View more/less button for tags -->
                <v-btn v-if="(post.languageName.includes('None') && post.videoLanguageSelected && (post.tags.length > 0 || post.videoLanguageSelected.length > 38))" style="position: absolute; bottom:0; left:7em;" class="caption grey--text mb-1 btn more-shadow" x-small text :ripple="false" @click="viewMoreInfo">{{viewMore ? 'less' : 'more'}}</v-btn>
                <v-btn v-else-if="post.tags.length > 0 || post.languageName.length > 38" style="position: absolute; bottom:0; left:7em;" class="caption grey--text mb-1 btn more-shadow" x-small text :ripple="false" @click="viewMoreInfo">{{viewMore ? 'less' : 'more'}}</v-btn>
                <!-- END view more/less button for tags -->

                <router-link :to="post.postType === 'public' && (post.mediaType === 'video' || post.mediaType === 'audio') ? {name: 'view-post', query: { pid: post.postId, postType: post.postType, /*post: post, userData: userData*/ } /*, props: { post: post, userData: userData }*/}  : post.postType === 'group' && (post.mediaType === 'video' || post.mediaType === 'audio') ? {name: 'view-post', query: { pid: post.postId, postType: post.postType, /*gId: groupId, post: post, userData: userData */ }, /*props: { post: post, userData: userData }*/} : post.postType === 'public' && post.mediaType === 'photo' ? {name: 'view-photo-post', query: { id: post.postId, postType: post.postType, post: post } , props: { post: post }} : {name: 'view-photo-post', query: { id: post.postId, postType: post.postType, gId: groupId, post: post, userData: userData }, props: { post: post }}" class="text-decoration-none">
                    <v-icon style="position: absolute; top:0; left:0;" dark class="my-3 mx-3 text-shadow" small>{{post.mediaType === 'photo' ? 'mdi-camera' : post.mediaType === 'video' ? 'mdi-video' : 'mdi-waveform'/* mdi-waveform, mdi-microphone, mdi-microphone-variant, mdi-headphones, mdi-headset, mdi-volume-high, mdi-account-voice, */}}</v-icon>
                </router-link>
                <v-menu v-model="menu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" style="position: absolute; top:0; right:0;" class="py-1 px-1" close-on-content-click>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="menu = !menu" icon style="position: absolute; top:0; right:0;" class="py-1 px-1 button-shadow">
                            <v-icon :color="$vuetify.theme.dark ? 'amber' : 'primary'">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded>
                        <v-list-item @click.stop="reportDialog = true, menu = !menu"><v-icon class="pr-2">mdi-flag</v-icon>Report</v-list-item>
                        <v-list-item v-if="isUsersPost() && !groupsPage && !homefeed" @click.stop="changePostType = post.postType === 'group' ? dialogTypeEnum.TO_PUBLIC : dialogTypeEnum.TO_GROUP, changePostDialog = true, menu = !menu"><v-icon class="pr-2">mdi-swap-horizontal</v-icon>{{ post.postType === 'group' ? 'To Public' : 'To Group'}}</v-list-item>
                        <v-list-item v-if="isUsersPost() && post.postType === 'group'" @click.stop="changePostType = dialogTypeEnum.TO_ANOTHER_GROUP, changePostDialog = true, menu = !menu"><v-icon class="pr-2">mdi-account-switch</v-icon>Change Group</v-list-item>
                        <v-list-item v-if="isUsersPost() && !draftPost" @click.stop="$store.dispatch('downloadVttFile', {post: post, audio: null}), menu = !menu"><v-icon class="pr-2">mdi-file-download</v-icon>Download VTT</v-list-item>
                        <v-list-item v-if="isAdmin && post.postType === 'public' && homefeed" @click.stop="post.pinnedPost ? $emit('pinnedPost', {post: post, index: index, pin: false}) : $emit('pinnedPost', {post: post, index: index, pin: true}), menu = !menu"><v-icon class="pr-2">{{post.pinnedPost ? 'mdi-pin-off' : 'mdi-pin'}}</v-icon>{{post.pinnedPost ? 'Remove Pin' : 'Pin Post'}}</v-list-item>
                        <v-list-item v-if="permissionToDelete()" @click.stop="$emit('delete-selected-post', {post: post, index: index}), menu = !menu"><v-icon class="pr-2">mdi-delete</v-icon>Delete</v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-hover>

        <ChangePostDialog v-if="changePostDialog" :dialog="changePostDialog" :type="changePostType" :dialogTypeEnum="dialogTypeEnum"  :groupsPage="groupsPage" :post="post" @close-dialog="changePostDialog = false" @move-to-another-group="movePostIntoAnotherGroup" @retrieve-posts-again="$emit('retrieve-posts-again')"/>
        <Report v-if="reportDialog" :dialog="reportDialog" :page="homefeed ? 'home' : groupsPage ? 'groups' : 'profile'" :post="post" @close-report="reportDialog = false"/>
        <!-- <VTT v-if="vttDialog" :dialog="vttDialog" :post="post" :audioData="null" @cancelVttDownloadDialog="vttDialog = false"/> -->
    </div>
</template>

<script>
import firebase from "../../firebaseConfig.js";
import Report from "./Report.vue";
import ChangePostDialog from "./ChangePostDialog.vue";
// import VTT from "./VttDownload.vue";
// import axios from 'axios'

export default {
    name: "post",
    components: {
        Report,
        ChangePostDialog,
        // VTT,
    },
    props: ["post", "homefeed", "groupsPage", "index", "groupId", "groupAdmin",/* "userData",*/ "isAdmin", "draftPost", "groupLanguageSelected"],
    data: function() {
        return {
            menu: false,
            viewMore: false,
            thumb: this.post.thumbnail,
            reportDialog: false,
            changePostDialog: false,
            vttDialog: false,
            dialogTypeEnum: {
                TO_PUBLIC: 0,
                TO_GROUP: 1,
                TO_ANOTHER_GROUP: 2
            },
            clicks: 0,
        };
    },
    methods: {
        vttFile: function () {
            this.$store.dispatch('downloadVttFile', {post: this.post, audio: null});
        },
        viewMoreInfo: function() {
            // console.log(this.post.tags.length);
            this.viewMore = !this.viewMore;
        },
        addTag: function (tag) {
            if(tag != ""){
                if(tag.startsWith(" ")){
                    return tag = "#" + tag.split(" ")[1];
                }
                if(tag.startsWith("#")){
                    return tag
                } else {
                    return tag = "#" + tag;
                }
            }
        },
        thumbnailExists: function() {
            if (this.thumb === "" || this.thumb === null) {
                return false;
            } else {
                return true;
            }
        },
        isUsersPost: function() {
            return this.post.userId == firebase.auth.currentUser.uid;
        },
        permissionToDelete: function() {
            if (this.isUsersPost() && !this.homefeed) {
                return true;
            } else if ( this.post.postType === 'group' ) {
                if( this.groupAdmin ){
                    return true;
                }
                else {
                    return false;
                }
            } else if (this.homefeed && this.isAdmin) {
                return true;
            } else {
                return false;
            }
        },
        movePostIntoAnotherGroup: function(event){
            // console.log("postId to move into another group");
            this.$store.dispatch('movePostToAnotherGroup', { postId: event.postId, newGroup: event.newGroup, oldGroupId: event.oldGroupId, fromType: event.fromType, toType: event.toType  }).groupsPageStore;
            this.$emit('remove-post-from-list', this.index)
        },
        // checkImageExists: async function (url) {
        //     try {
        //         const response = await axios.head(url);
        //         console.log("response: ", response);
        //         return response.status === 200;
        //     } catch (error) {
        //         return false;
        //     }
        // },
    },
    computed: {
        getDefaultThumbnail (){
            if(this.post.videoType === 'video/youtube' && this.post.mediaType === 'video'){
                if (typeof this.post.videoUrl.split('v=')[1] != 'undefined'){
                    const videoId = this.post.videoUrl.split('v=')[1].split('&ab_channel')[0];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    // console.log("tumbnail: ", youtube_static_url);
                    // this.checkImageExists (youtube_static_url).then((exists) => {
                    //     console.log("exists: ", exists);
                    // });
                    return youtube_static_url;
                } else if (typeof this.post.videoUrl.split('be/')[1] != 'undefined'){
                    const videoId = this.post.videoUrl.split('be/')[1];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    // console.log("tumbnail: ", youtube_static_url);
                    // this.checkImageExists (youtube_static_url).then((exists) => {
                    //     console.log("exists: ", exists);
                    // });
                    return youtube_static_url;
                } else if (typeof this.post.videoUrl.split('embed/')[1] != 'undefined') {
                    const videoId = this.post.videoUrl.split('embed/')[1];
                    const youtube_static_url = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                    return youtube_static_url;
                } else {
                    console.log("post ", this.post.postVideoUrl, " didn't pass any split tests")
                    return require('../assets/no-thumbnail.png');
                }
            } else {
                if (this.post.videoType.split('/')[0] === 'audio') {
                    return require('../assets/audio-only.jpeg');
                } else {
                    return require('../assets/no-thumbnail.png');
                }
            }
        },
        userData(){
            return this.$store.state.userData;
        },
    },
    created: function() {
        this.post.groupLanguageSelected = this.groupLanguageSelected;
    },
};
</script>

<style>
.post-card-title {
    text-overflow: ellipsis;
    overflow-y: hidden;
    white-space: nowrap;
    word-break: normal;
    width: 100%;
    max-width: 100%;
}
.image-overlay {
    height: auto;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 0) 0%,rgba(0,0,0, .9) 100%);
}
.image-overlay-language {
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0, .3) 10%,rgba(0,0,0, .9) 100%);
}
.image-overlay-hover {
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0, .3) 10%,rgba(0,0,0, .8) 80%);
}
.title-drop {
    margin-top: 30%;
}
.text-shadow {
    text-shadow: 1px 1px 2px black;
}
.button-shadow:hover {
    text-shadow: 1px 1px 2px black;
}
.username-shadow:hover {
    text-shadow: 0px 0px 0.5px;
}
.more-shadow:hover {
    color: white;
    text-shadow: 0px 0px 0.5px white;
}
.title-text {
    -webkit-text-stroke: 1px black;
    /* background: transparent;
    background-color: transparent; */
}
.description-overflow {
    /* position: relative;
    height: 3.6em; exactly three lines */
    width: 100%;
    height: 50%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tag-overflow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
    /* align-content: flex-start; */
    /* line-height: 3em; */
    /* max-height: 20%; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}
/* .tag-class { 
    text-shadow: 1px 1px 5px black;
} */
.tag-class:hover {
    /* text-decoration: underline; */
    /* text-decoration-color: var(--v-primary-base); */
    text-shadow: 0px 0px .5px white;
}
.date-language {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.btn {
    text-transform: none;
}
/* .dots-more {
    position: absolute;
    top:0;
    right:0;
} */
</style>
