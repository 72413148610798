<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelNewFriend">
        <v-card class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Add a New Friend</span>
            </v-card-title>

            <v-row justify="center" class="mx-0">
                <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                    <v-text-field v-model="newSearch" type="text" label="Search by Username" color="primary" clearable outlined hide-details="auto" append-outer-icon="mdi-magnify" @click:append-outer="searchForUsers" @keydown.enter="searchForUsers" @click:clear="clearSearch" @keydown.delete="checkForEmptySearch"></v-text-field>
                </v-col>
            
                <v-col cols="12" xl="10" lg="10" md="10" sm="12" class="ma-0 pa-0">
                    <v-list v-if="searchResults.length > 0" rounded class="pt-0" two-line>
                        <v-divider class="justify-center"/>
                        <template v-for="(result, index) in searchResults">
                            <v-list-item :key="result.userId" selectable dense class="align-center">
                                <v-list-item-avatar style="cursor: pointer;" @click.stop="openSearchedUsersPage(result.userId)">
                                    <v-img v-if="result.picture" :src="result.picture" referrerpolicy="no-referrer" @error="result.picture=null"></v-img>
                                    <v-img v-else src="../../assets/no-user.png" :alt="result.username"></v-img>
                                </v-list-item-avatar>

                                <v-row class="px-3" justify="center" align="center">
                                    <v-col cols="8" class="pa-0 ma-0 text-truncate">
                                        <span @click.stop="openSearchedUsersPage(result.userId)" style="cursor: pointer;" class="font-weight-bold text-subtitle-1">{{result.username}}</span><br/>
                                        <span v-if="isFriend(result.userId) === true" class="pa-0 ma-0 font-weight-light text-caption" style="cursor: pointer;" @click.stop="openSearchedUsersPage(result.userId)">Friends</span>
                                        <span v-else-if="isFriend(result.userId) === false" class="pa-0 ma-0 font-weight-light text-caption" style="cursor: pointer;" @click.stop="openSearchedUsersPage(result.userId)">Request Sent</span>
                                        <span v-else-if="checkUserNotifications(result.userId)" class="font-weight-light text-caption">Waiting for Approval</span>
                                    </v-col>
                                    <v-col cols="4" align="end">
                                        <v-btn v-if="checkUserNotifications(result.userId)" color="primary" icon small :disabled="denyLoading" :loading="acceptLoading && result.userId === loadingFriendId" @click.stop="acceptFriendRequest(userNotifications.find((user) => {if(user.data.userId === result.userId)return true}), userNotifications.findIndex((user) =>user.data.userId === result.userId))"><v-icon>mdi-check</v-icon></v-btn>
                                        <v-btn v-if="checkUserNotifications(result.userId)" color="red" icon small :disabled="acceptLoading" :loading="denyLoading && result.userId === loadingFriendId" @click.stop="denyFriendRequest(userNotifications.find((user) => {if(user.data.userId === result.userId)return true}), userNotifications.findIndex((user) =>user.data.userId === result.userId))" class="pl-2"><v-icon>mdi-close</v-icon></v-btn>
                                        <v-btn v-else-if="!checkUserNotifications(result.userId) && isFriend(result.userId) === false" color="primary" text small :disabled="(loading || acceptLoading || denyLoading) && result.userId != loadingFriendId" :loading="loading && result.userId === loadingFriendId" @click.stop="cancelFriendRequest(result)">Cancel</v-btn>
                                        <v-btn v-else-if="!checkUserNotifications(result.userId) && isFriend(result.userId) === undefined" color="primary" text small :disabled="(loading || acceptLoading || denyLoading) && result.userId != loadingFriendId" :loading="loading && result.userId === loadingFriendId" @click.stop="sendFriendRequest(result)">Add</v-btn>
                                        <v-btn v-else-if="!checkUserNotifications(result.userId) && isFriend(result.userId) === true" color="primary" text small disabled>Add</v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="justify-center" :key="index"/>
                        </template>
                    </v-list>
                    <div v-else-if="emptySearchResults" class="text-caption text-center mt-2">We Can't Find Any Users Matching Your Search</div>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelNewFriend">Cancel</v-btn>
                </v-row>
            </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";

export default {
    name: "new-friend",
    props: ["dialog", "friends"],
    data: function () {
        return {
            newSearch: "",
            oldSearch: "",
            loading: false,
            acceptLoading: false,
            denyLoading: false,
            searchResults: [],
            emptySearchResults: false,
            loadingFriendId: null,
        };
    },
    methods: {
        clearSearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            }
            this.searchResults = [];
        },
        checkForEmptySearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            } else if (this.newSearch != null && this.newSearch.length <= 1) {
                this.searchResults = [];
            }
        },
        searchForUsers: function () {
            if (this.newSearch === null || this.newSearch === "" /*|| this.newSearch === " "*/) {
                if (this.emptySearchResults) {
                    this.emptySearchResults = false;
                }
                return;
            } else if(this.newSearch.length > 0 && this.newSearch != this.oldSearch || this.searchResults.length === 0){
                this.searchUsersByUsername();
            }
            this.oldSearch = this.newSearch;
        },
        searchUsersByUsername: function () {
            // 1 - create the strings to query off of
            const increasedLastLetter = String.fromCharCode( this.newSearch.charCodeAt(this.newSearch.length-1) + 1 ); // increase the last letter of the string by one 
            const endTerm = this.newSearch.slice(0,-1) + increasedLastLetter; // remove the last letter of the string then add the increased last letter
            // 2 - create the firebase reference || TODO: try with .where(IN) - if space in the string captialize each starting lettter. lowercase all of their string, uppercase all | also some with the reerse string. take all and concat results if not duplicates
            const usernameRef = firebase.db.collection("userData").where("username", ">=", this.newSearch).where("username", "<", endTerm);
            usernameRef.get().then((snapshot) => {
                this.searchResults = [];
                if (snapshot.empty){
                    this.runModifiedUsernameSearch();
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    if (doc.id != firebase.auth.currentUser.uid){
                        this.searchResults.push({
                            'fullname': doc.data().fullname,
                            'username': doc.data().username,
                            'picture': doc.data().profilePicture,
                            'email': doc.data().email,
                            'userId': doc.data().userId
                        });
                    }
                });
                this.runModifiedUsernameSearch();
            }).catch((err) => {
                console.log("Error finding user by username", err);
            });
        },
        runModifiedUsernameSearch () {
            let changeFirstLetterCasing;
            if (this.newSearch.slice(0,1) === this.newSearch.charAt(0).toUpperCase()){
                changeFirstLetterCasing = this.newSearch.charAt(0).toLowerCase() + this.newSearch.slice(1);
            } else {
                changeFirstLetterCasing = this.newSearch.charAt(0).toUpperCase() + this.newSearch.slice(1);
            }
            const modifiedEndTerm = changeFirstLetterCasing.slice(0,-1) + String.fromCharCode( changeFirstLetterCasing.charCodeAt(changeFirstLetterCasing.length-1) + 1 );
            const modifiedUsernameRef = firebase.db.collection("userData").where("username", ">=", changeFirstLetterCasing).where("username", "<", modifiedEndTerm);
            modifiedUsernameRef.get().then((snapshot) => {
                if (snapshot.empty) {
                    if (this.searchResults.length === 0) {
                        this.emptySearchResults = true;
                    }
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    if (doc.id != firebase.auth.currentUser.uid && (this.searchResults.findIndex((user) => user.userId === doc.id) === -1 )){ //If it isn't the user searching and it isn't already in the list
                        this.searchResults.push({
                            'fullname': doc.data().fullname,
                            'username': doc.data().username,
                            'picture': doc.data().profilePicture,
                            'email': doc.data().email,
                            'userId': doc.data().userId
                        });
                    }
                });
            }).catch((err) => {
                console.log("error retrieving the new ref", err);
            });
        },
        checkUserNotifications: function (userId) {
            const isInNotifs = this.userNotifications.find((user) => {if (user.data.userId === userId) return true});
            //   console.log(isInNotifs);
            if (isInNotifs) {
                return true;
            } else {
                return false;
            }
        },
        isFriend: function (userId) {
            // return this.friends.findIndex((friend) => friend.id === userId);
            const friend = this.friends.find((friend) => friend.id === userId);
            if (typeof friend != 'undefined') {
                if (friend.accepted) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        sendFriendRequest: async function(friend) {
            this.loading = true;
            this.loadingFriendId = friend.userId;
            let response = await this.$http.post(`friends/${firebase.auth.currentUser.uid}`, {user: {userId: firebase.auth.currentUser.uid, username: firebase.auth.currentUser.displayName, email: firebase.auth.currentUser.email, picture: firebase.auth.currentUser.photoURL,}, friend: friend,});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Sent", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.loading = false;
            this.loadingFriendId = null;
        },
        cancelFriendRequest: async function (result) {
            const friend = this.friends.find((friend) => friend.id === result.userId);
            this.loading = true;
            this.loadingFriendId = friend.userId;
            var notif = {
                id: friend.notifId,
                data: {
                email: friend.email,
                username: friend.username,
                type: 'friend',
                userId: firebase.auth.currentUser.uid,
                userProfilePhoto: friend.userProfilePhoto,
                }
            }
            let response = await this.$http.delete(`friends/${firebase.auth.currentUser.uid}/${friend.userId}`, {data: {notif: notif, accepted: friend.accepted}});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Canceled", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.loading = false;
            this.loadingFriendId = null;
        },
        acceptFriendRequest: async function(notif, index){
            this.userNotifications.splice(index, 1);
            this.acceptLoading = true;
            this.loadingFriendId = notif.data.userId;
            let response = await this.$http.put(`friends/${notif.data.userId}/${firebase.auth.currentUser.uid}`, {friend: notif,});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Accepted", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.acceptLoading = false;
            this.loadingFriendId = null;
        },
        denyFriendRequest: async function(notif, index){
            this.userNotifications.splice(index, 1);
            this.denyLoading = true;
            this.loadingFriendId = notif.data.userId;
            let response = await this.$http.delete(`friends/${notif.data.userId}/${firebase.auth.currentUser.uid}`, {data: {notif: notif, accepted: false}});
            if (response.status === 200) {
                this.$store.commit('alertUser', { show: true, text: "Friend Request Rejected", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.denyLoading = false;
            this.loadingFriendId = null;
        },
        openSearchedUsersPage: function (id) {
            window.open(`https://tawq.in/view-user-profile?id=${id}`, "_blank");
        },
        cancelNewFriend: function () {
            if (!this.loading && !this.acceptLoading && !this.denyLoading) {
                this.$emit("cancelNewFriend");
            }
        },
    },
    computed: {
        userNotifications: {
            get(){
                return this.$store.state.userNotifications.friendRequests;
            },
            set(){
                this.$store.commit('saveFriendRequestNotifications', this.userNotifications);
            },
        },
    },
};
</script>
