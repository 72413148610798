<template>
    <transition name="slideDown" enter-active-class="slideDown" leave-active-class="slideUp" appear>
        <div v-if="uploadingVideo" class="background px-4 py-4 d-flex flex-column justify-center align-center upload-bar rounded elevation-8">
            <div v-if="resumableProgress < 100" class="text-subtitle-1 pt-0 text-center">Uploading <br><span class="font-weight-bold">'{{createPost.title}}'</span></div>
            <div v-else class="text-subtitle-1 pt-0 text-center">Processing <br class="font-weight-medium">'{{createPost.title}}'</div>
            <!-- <v-progress-circular v-if="resumableProgress === 100" indeterminate size="18" color="primary" class="py-4"></v-progress-circular> -->
            <v-progress-linear
                class="px-4 mt-2"
                background-color="secondary"
                background-opacity="0.5"
                rounded
                :stream="resumableProgress != 100"
                :striped="resumableProgress === 100"
                :indeterminate="resumableProgress === 100"
                height="15"
                :value="resumableProgress"
            >{{ resumableProgress + "%" }}</v-progress-linear>
            <!-- <v-progress-linear
                background-color="secondary"
                background-opacity="0.3"
                rounded
                stream
                height="20"
                :value="resumableProgress"
            >{{ resumableProgress + "%" }}</v-progress-linear> -->
        </div>
        <div v-if="uploadingPhoto" class="background px-4 py-4 d-flex flex-column justify-center align-center upload-bar rounded elevation-8">
            <div v-if="resumablePhotoProgress < 100" class="text-subtitle-1 pt-0 text-center">Uploading <br><span class="font-weight-bold">'{{photoPost.title}}'</span></div>
            <div v-else class="text-subtitle-1 pt-0 text-center">Processing <br class="font-weight-medium">'{{photoPost.title}}'</div>
            <!-- <v-progress-circular v-if="resumableProgress === 100" indeterminate size="18" color="primary" class="py-4"></v-progress-circular> -->
            <v-progress-linear
                class="px-4 mt-2"
                background-color="secondary"
                background-opacity="0.5"
                rounded
                :stream="resumablePhotoProgress != 100"
                :striped="resumablePhotoProgress === 100"
                :indeterminate="resumablePhotoProgress === 100"
                height="15"
                :value="resumablePhotoProgress"
            >{{ resumablePhotoProgress + "%" }}</v-progress-linear>
            <!-- <v-progress-linear
                background-color="secondary"
                background-opacity="0.3"
                rounded
                stream
                height="20"
                :value="resumableProgress"
            >{{ resumableProgress + "%" }}</v-progress-linear> -->
        </div>
        <!-- <div v-else class="text-subtitle-1 pt-0 upload-bar text-center grey darken-4 px-4 py-4">No Current Uploads</div> -->
    </transition>
</template>
<script>
export default {
    name: 'UploadBar',
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {
        uploadingVideo(){
            return this.$store.state.newPost.uploading_video; /* && this.$store.state.newPostStore.resumable_progress > 0 */
        },
        resumableProgress(){
            return this.$store.state.newPost.resumable_progress;
        },
        createPost(){
            return this.$store.state.newPost.createPost;
        },
        uploadingPhoto(){
            return this.$store.state.newPhotoPost.uploading_photo;
        },
        resumablePhotoProgress(){
            return this.$store.state.newPhotoPost.resumable_progress;
        },
        photoPost(){
            return this.$store.state.newPhotoPost.photoPost;
        },
    },
}
</script>
<style scoped>
    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-30px);
            z-index: 0;
        }
        to {
            opacity: 1;
            z-index: 1;
        }
    }
    .slideDown {
        animation-name: slideDown;
        animation-duration: .3s;
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            z-index: 0;
        }
        to {
            opacity: 0;
            transform: translateY(-20px);
            z-index: 0;
        }
    }
    .slideUp {
        animation-name: slideUp;
        animation-duration: .2s;
    }

    .upload-bar {
        width: 30%;
        position: absolute;
        top: 65px;
        right: 0;
        z-index: 0;
    }

/* XS */
@media (max-width: 600px){
    .upload-bar {
        width: 100%
    }

}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    .upload-bar {
        width: 60%
    }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .upload-bar {
        width: 45%
    }
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .upload-bar {
        width: 30%
    }
}

/* XL */
@media (min-width: 1905px){
    .upload-bar {
        width: 20%
    }
}


</style>
