<template>
    <v-container>
    <!-- <v-row style="width: 90%;" justify="center" class="mx-0"> -->
    <!-- <div> -->
        <v-app-bar app flat fixed style="max-width: 100%; z-index: 8;" class="primary">

            <v-container class="px-0">
                <v-row class="justify-space-between px-0">
                    <v-col cols="4">
                            <v-img
                            max-height="50px"
                            contain
                            width="100px"
                            src="../assets/whitetransparent.png"
                            style="cursor: pointer;"
                            @click="$router.push({ path: 'intro'})"
                            ></v-img>
                    </v-col>
                    
                    <v-spacer></v-spacer>

                    <v-col cols="8" xs="8" sm="4" md="3" xl="2" align-self="center">
                    <div class="d-flex justify-end justify-md-space-between">
                        <v-btn width="47%" color="white" outlined class="mr-2 font-weight-normal"  :to="{name: 'login'}">Login</v-btn>
                        <v-btn width="47%" color="white" outlined class="font-weight-normal" :to="{name: 'sign-up'}">Sign Up</v-btn>
                    </div>
                    </v-col>

                </v-row>
            </v-container>

        </v-app-bar>

        <v-row v-if="letUserSeePost" justify="center" class="mt-15">
            <v-col :cols="isMobile ? '12' : '8'">
                <v-img
                    id="image"
                    class="image"
                    :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'"
                    style="position: relative;"
                    contain
                    :src="postData.thumbnail ? postData.thumbnail : require('../assets/no-thumbnail.png')"
                    @load="getAudioData">
                    <div v-if="audioData.length > 0">
                        <div
                            v-for="(text,i) in selectedAudio.text" :key='i'
                            :style="`color: ${text.style.textColor}; font-size: ${text.style.fontSize}vw; transform: ${text.style.transform};`"
                            class="move"
                            @click="textClicked(text, false)">
                            <v-hover v-slot="{hover}">
                                <span :style="hover ? `border: 1px solid ${text.style.textColor};` : selectedText == text ? `border: 1px solid ${text.style.textColor};` : null">
                                    {{text.text}}
                                </span>
                            </v-hover>
                        </div>
                    </div>
                    <span class="water-mark">tawq.in</span>
                </v-img>
                <!-- INFO UNDER PHOTO -->
                <!-- style="border: 1px solid red;" -->
                <v-col cols="12" class="px-5">
                    <v-row class="mx-0" justify="space-between">
                        <!-- <h1 v-if="audioData.length > 0">{{audioData[0].audioData.title}}</h1> -->
                        <h1 class="text-truncate">{{postData.title}}</h1>
                    </v-row>
                    <v-row justify="start" class="py-0 mb-0">
                        <!-- <router-link :to="{name: 'view-user-profile', query: {id: postData.userId}}" class="text-decoration-none accent-text" style="cursor: pointer;"> -->
                            <p class="subtitle-2 font-weight-light font-italic mb-0 py-0 px-2 accent--text"> @{{postData.username ? postData.username : 'Unknown' }}</p>
                        <!-- </router-link> -->
                        <span>|</span>
                        <p class="subtitle-2 font-weight-light mb-0 py-0 px-2 inverse--text">{{postData.postDate}}</p>
                    </v-row>
                    <v-row v-if="!isMobile" justify="start" class="pb-0 pt-4 px-0 mb-0">
                        <p style="max-width: 100%; overflow-x: hidden;" class="subtitle-1 font-weight-light mb-0 py-0 px-2">{{postData.description}}</p>
                    </v-row>
                    <v-divider v-if="!isMobile" class="mt-2"/>

                    <!-- Everything under the View More/View Less on Mobile -->
                    <v-row v-if="showDescription" justify="start" class="pb-0 pt-4 px-0 mb-0">
                        <p style="max-width: 100%; overflow-x: hidden;" class="subtitle-1 font-weight-light mb-0 py-0 px-2">{{postData.description}}</p>
                    </v-row>
                    <!--  -->

                    <!-- View More/View Less button on mobile devices -->
                    <v-row v-if="isMobile" justify="center" align="center" class="mx-0">
                        <v-divider></v-divider>
                        <p class="text-caption primary--text mb-0 px-2" @click="showDescription = !showDescription">{{ showDescription ? 'View Less' : 'View More'}}</p>
                        <v-divider></v-divider>
                    </v-row>
                    <!--  -->

                    <v-row :justify="isMobile ? 'center' : 'space-between'" align="center" class="py-2">
                        <!-- <div v-if="isMobile" class="d-flex flex-column align-self-start justify-space-between"> -->
                            <!-- <v-btn v-if="postData.canContribute" @click.stop="photoContribution" color="primary" :class="isMobile ? 'mb-4' : null">Contribute <v-icon>mdi-plus</v-icon></v-btn> -->
                            <div class="d-flex flex-row align-center">
                                <v-btn @click="sharePost('email')" depressed small text class="mt-xs-4 pl-0 no-background"><v-icon class="invert--text">mdi-email</v-icon></v-btn>
                                <v-btn @click="sharePost('gmail')" depressed small text class="mt-xs-4 pl-0"><v-icon class="invert--text">mdi-gmail</v-icon></v-btn>
                                <v-btn @click="sharePost('facebook')" depressed small text class="mt-xs-4 pl-0"><v-icon class="invert--text">mdi-facebook</v-icon></v-btn>
                                <v-btn @click="sharePost('whatsapp')" depressed small text class="mt-xs-4 pl-0 hidden-md-and-up"><v-icon class="invert--text">mdi-whatsapp</v-icon></v-btn>
                                <v-btn @click="sharePost('twitter')" depressed small text class="mt-xs-4 pl-0"><v-icon class="invert--text">mdi-twitter</v-icon></v-btn>
                                <v-btn @click="sharePost('linkedin')" depressed small text class="mt-xs-4 pl-0"><v-icon class="invert--text">mdi-linkedin</v-icon></v-btn>
                            </div>
                        <!-- </div> -->
                    </v-row>
                </v-col>
                <!-- END INFO UNDER PHOTO -->
            </v-col>

            <!-- THIS COL CONTAINS ALL THE CODE FOR THE SELECTED TEXT AND AUDIO DATA -->
            <!-- class="d-flex flex-column" -->
            <v-col :cols="isMobile ? '12' : '4'"  align="center" justify="space-between">
                <SelectedText :selectedText="selectedText"/>
                <!-- The following col should consist of all the audioData(contributions) to the post -->
                <v-row class="mx-0" style="max-height: 65vh; overflow-y: scroll; overflow-x: hidden;">
                    <v-col v-for="(audio,i) in audioData" :key="i" cols="12" :class="[$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4' ]">
                        <DataAudio :audio="audio" :selectedText="selectedText" :postAudioDataId="postData.audioDataId" @setSelectedAudio="setSelectedAudio" @textClicked="textClicked"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    <!-- </div> -->
    <!-- </v-row> -->
    </v-container>
</template>

<script>
import fb from "firebase/compat/app";
import firebase from "../../firebaseConfig.js";
// import Report from "./Report.vue";
import SelectedText from "./PhotoPost/SelectedText.vue";
import DataAudio from "./PhotoPost/DataAudio.vue";

export default {
    name: "photo-post-view",
    metaInfo() {
        return {
            title: typeof this.post.title != 'undefined' ? `${this.post.title} - tawq.in` : 'View Post - tawq.in',
        }
    },
    props: ["post", "postId", "postType", "groupId"],
    components: {
        // Report,
        SelectedText,
        DataAudio,
    },
    data: function () {
        return {
            letUserSeePost: true,
            showDescription: false,
            // currentUserId: firebase.auth.currentUser.uid,
            postData: this.post,
            audioData: [],
            audioText: null,
            selectedText: {},
            selectedAudio: {},
            newImageRect: {},
            resizeTimer: null,
            reportDialog: false,
        };
    },
    methods: {
        setSelectedAudio: function (audio) {
            // console.log(audio);
            this.newImageRect = this.getImageRect();

            audio.text.forEach((text) => {
                var l = (text.rect.left - this.postData.imageRect.left) / this.postData.imageRect.width;
                var t = (text.rect.top - this.postData.imageRect.top) / this.postData.imageRect.height;
                var newLeft = l * this.newImageRect.width;
                var newTop = t * this.newImageRect.height;
                var hp = text.rect.height / this.postData.imageRect.height;
                var wp = text.rect.width / this.postData.imageRect.width;
                var newTextHeight = hp * this.newImageRect.height;
                var newTextWidth = wp * this.newImageRect.width;

                text.style.transform = `translate(${newLeft}px, ${newTop}px)`;
                text.style.height = `${newTextHeight}px`;
                text.style.width = `${newTextWidth}px`;
            });
            if(this.selectedAudio != audio){
                this.selectedAudio = audio;
            }
            this.selectedText = {};
        },
        textClicked: function (text, audio) {
            // console.log(text);
            // audio is set to false when the text on the image is clicked,
            // this is so that the audio object does not get reloaded on the image
            if(audio != false){
                this.setSelectedAudio(audio);
            }
            if(this.selectedText != text){
                this.selectedText = text;
            } else {
                //Do we want user to be able to de-select the text????
                this.selectedText = {};
            }
            text.showMore = !text.showMore;
        },
        getGroupPostData: function () {
            const groups = [...this.userGroups]

            let groupPostRef = firebase.db.collection("groupPosts").doc(this.postId);
            groupPostRef.get().then((doc) => {
                if (!doc.exists) {
                    alert("No post with this ID exists.");
                    this.$store.commit('alertUser', { show: true, text: `This post does not exist.`, type: 'snackbar' });
                    return;
                }
                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                // this.getAudioData();
                var proceed = this.isUserPartOfGroup(groups);
                if(!proceed){
                    this.letUserSeePost = false;
                    this.$store.commit('alertUser', { show: true, text: `This post can only be seen members of the group it belongs to.`, type: 'snackbar' });
                } else {
                    this.incrementPostViewed();
                }
            }).catch((err) => {
                // alert("Error getting document: " + err);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        getPostData: function () {
            // this.viewLoading = true;
            let publicPostRef = firebase.db.collection("publicPosts").doc(this.postId);
            publicPostRef.get().then((doc) => {
                if (!doc.exists) {
                    this.$store.commit('alertUser', { show: true, text: `This post does not exist.`, type: 'snackbar' });
                    return;
                }
                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                // this.viewLoading = false;
                this.incrementPostViewed();
            }).catch((err) => {
                // this.viewLoading = false;
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        getAudioData: function () {
            if(typeof this.postData.title == "undefined"){
                return null;
            }
            // this.audioLoading = true;
            this.audioData = [];
            let audioRef = firebase.db.collection("audioSegments").where("postId", "==", this.postData.postId).orderBy("date", "desc");
            audioRef.get().then((snapshot) => {
                if (snapshot.empty) {
                    // this.audioLoading = false;
                    return;
                }
                snapshot.forEach((doc) => {
                    var data = {
                        audioData: doc.data(),
                        showMore: false,
                    }
                    data.audioData.text.forEach((text) => {
                        text.showMore = true;
                    });
                    /* first check to see that there is audio here, then if it is the main audioData that is associated with the post, add it to the front not the back */
                    if(data.audioData.audioDataId == this.postData.audioDataId){
                        this.audioData.unshift(data);
                    } else {
                        this.audioData.push(data);
                    }
                });

                // selectedAudio will be used to load the audioData onto the image
                // At first the selectedAudio will be the first audio in the list
                this.selectedAudio = this.audioData[0].audioData;
                // console.log(this.audioData[0].audioData);
                // console.log("AudioData: ", this.audioData);
                // console.log("selectedAudio: ", this.selectedAudio);
                this.imageLoaded();
            }).catch((err) => {
                console.error(err);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        getImageRect: function () {
            var rect = {
                height: '',
                width: '',
                top: '',
                left: '',
                x: '',
                y: '',
            };
            var image = document.getElementById("image");//.getBoundingClientRect();
            rect.height = image.clientHeight;
            rect.width = image.clientWidth;
            rect.top = image.offsetTop;
            rect.left = image.offsetLeft;
            rect.x = image.offsetLeft;
            rect.y = image.offsetTop;
            return rect;
        },
        imageLoaded: function () {
            this.newImageRect = this.getImageRect();
            // console.log(this.newImageRect);

            this.audioData[0].audioData.text.forEach((text) => {
                var l = (text.rect.left - this.postData.imageRect.left) / this.postData.imageRect.width;
                var t = (text.rect.top - this.postData.imageRect.top) / this.postData.imageRect.height;
                var newLeft = l * this.newImageRect.width;
                var newTop = t * this.newImageRect.height;
                var hp = text.rect.height / this.postData.imageRect.height;
                var wp = text.rect.width / this.postData.imageRect.width;
                var newTextHeight = hp * this.newImageRect.height;
                var newTextWidth = wp * this.newImageRect.width;

                text.style.transform = `translate(${newLeft}px, ${newTop}px)`;// skew(${skewX}, ${skewY}) scale(${scaleX},${scaleY})
                text.style.height = `${newTextHeight}px`;
                text.style.width = `${newTextWidth}px`;
            });
        },
        imageResize: function () {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                // Run code here, resizing has "stopped"
                this.imageLoaded();
            }, 300);
        },
        incrementPostViewed: function() {
            if (process.env.NODE_ENV === 'production'){
                let postRef;
                if (typeof this.post.postId === 'undefined' && typeof this.postData.postId === 'undefined' ){
                    return;
                }
                else if (typeof this.post.postId === 'undefined' && typeof this.postData.postId != 'undefined'){
                    postRef = firebase.db.collection(this.postData.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postData.postId);
                }
                else {
                    postRef = firebase.db.collection(this.post.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.post.postId);
                }
                postRef.update({postViewed: fb.firestore.FieldValue.increment(1),}).then(() => {
                    // console.log("postViewed has been incremented 1")
                })
                .catch((error) => {
                    console.error("error incrementing the postViewed:", error.message);
                })
            }
        },
        isUserPartOfGroup: function(groups) {
            if(groups.length <= 0){
                return false;
            }
            for ( var i = 0; i < groups.length; i++ ){
                if (groups[i].groupId === this.postData.groupId){
                    return true;
                }
            }
            return false;
        },
        sharePost: async function(type){
            const url = `https://tawq.in${this.$route.fullPath}`
            const message = `Take a look at this photo made with Tawqin: ${'%0D%0A'} ${encodeURIComponent(url)}`;
            const subject = encodeURIComponent("Take a look at this!");
            switch(type){
                case 'email':
                    document.location.href = `mailto:?subject=${subject}&body=${message}`
                    break;

                case 'facebook': {
                    const facebookShare = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
                    window.open(facebookShare, "_blank");
                    // console.log("facebook!");
                    break;
                }

                case 'whatsapp': {
                    const whatsappShare = `whatsapp://send?text=${encodeURIComponent(url)}`;
                    window.open(whatsappShare, "_blank");
                    // console.log("facebook!");
                    break;
                }

                case 'twitter': {
                    const tweet = "Take a look at this photo made with Tawqin!"
                    const twitterShare = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}${'%0D%0A'}&text=${encodeURIComponent(tweet)}&hashtags=Tawqin,Tawq,CaptionYourVideo,VoiceOver,VoiceOverVideo,KeepTawqin`;
                    window.open(twitterShare, "_blank");
                    // console.log('twitter!');
                    break;
                }

                case 'linkedin': {
                    const linkedinShare = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent("Tawqin")}&summary=${encodeURIComponent(this.postData.title)}&source=${encodeURIComponent("Tawqin")}`;
                    window.open(linkedinShare, "_blank");
                    // console.log('Instagram!');
                    break;
                }

                case 'gmail': {
                    const subject = encodeURIComponent("Take a look at this photo made with Tawqin!");
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                }

                // case 'share': {
                //   const shareData = {
                //     title: `Tawqin - ${this.postData.title}`,
                //     text: 'Take a look at this video made with Tawqin!',
                //     url: url,
                //   }
                //   try {
                //     await this.$navigator.share(shareData);
                //   } 
                //   catch(err) {
                //     console.error('Error', err);
                //   }
                //   break;
                // }
            } // end of switch
        },
    },
    watch: {},
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        userGroups(){
            return this.$store.state.userGroups;
        },
    },
    beforeCreate: function () {},
    created: function () {
        if (this.postType == "group") {
            this.$store.commit('alertUser', { show: true, text: `This post can only be seen by signed in members of the group it belongs to.`, type: 'snackbar' });
            // if(typeof this.postData.title === 'undefined'){
            //     this.getGroupPostData();
            // } else{
            //     var proceed = this.isUserPartOfGroup(this.userGroups);
            //     if(!proceed){
            //         this.letUserSeePost = false;
            //         this.snackbarText = "This post can only be seen members of the group it belongs to.";
            //         this.snackbar = true;
            //     }
            // }
        } else { // public post
            if(typeof this.postData.title === 'undefined'){
                this.getPostData();
            }
        }
        // console.log(this.postData);
    },
    mounted: function () {
        console.log(this.postId);
        this.$window.addEventListener("resize", this.imageResize);
        if (typeof this.postData.title != 'undefined') {
            this.postViewedCounter = setTimeout(() => {
                this.incrementPostViewed();
            }, 7000); // increment the posts viewed by one after 3 seconds
        }
    },
    updated: async function () {},
    beforeDestroy: function () {
        this.$window.removeEventListener("resize", this.imageResize);
        clearTimeout(this.postViewedCounter);
    },
    destroyed: function () {
        clearTimeout(this.resizeTimer);
    },
};
</script>

<style>
.image {
    position: relative;
    max-height: 75vh;
}
.move {
    display: inline-block !important;
    position: absolute !important;
    cursor: pointer;
    /* border: 1px solid var(--v-primary-base) !important; */
    /* font-family: "Verdana", sans-serif; */
    /* font-size: auto !important;
    width: auto !important;
    text-align: center; */
    /* top: 50% !important;
    left: 50% !important; */
}
.water-mark {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    /* font-size: 20px;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; */
}
</style>