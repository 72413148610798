<template>
    <v-row v-if="emptySearch && drafts.length === 0" justify="center" no-gutters class="mt-0">
        <span class="text-subtitle-1 text-center">You have finished creating all your posts and have no drafts.</span>
    </v-row>

    <v-row v-else-if="draftPostsLoading && !emptySearch" justify="start" class="pa-0">
        <v-col v-for="index in 16" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
            <v-skeleton-loader height="250" type="image" class="rounded-xl elevation-4"></v-skeleton-loader>
        </v-col>
    </v-row>

    <v-row v-else justify="start" class="pa-0">
        <Alert :key="NaN" v-if="deletePostDialog" :dialogVal="true" type="dialog" title="Are you sure you want to delete this post?" text="All your progress will be permanently lost. This action cannot be undone." @dialogClicked="deletePost"/>
        <v-col v-for="(post,index) in drafts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
            <DraftPost :post="post" :index="index" :userData="userData"  @delete-selected-post="deleteDialog" />
        </v-col>
    </v-row>
</template>

<script>
import DraftPost from "./DraftPost.vue";
import Alert from "./Alert.vue";
import firebase from "../../firebaseConfig.js";
export default {
    name: "Drafts",
    // metaInfo: {
    //     title: 'Drafts - tawqin'
    // },
    components: {
        DraftPost,
        Alert
    },
    data: function (){
        return {
            drafts: [],
            emptySearch: false,
            limit: 20,
            deletePostDialog: false,
            postPayloadToDelete: null,
            draftPostListener: null,
            draftPostsLoading: true,
        }
    },
    methods: {
        getDraftsForUser(){
            const dbRef = firebase.db.collection("drafts").where("userId", "==", this.userData.userId).orderBy("postDate", "desc").limit(this.limit);
            // this.draftPostsLoading = true;
            this.draftPostListener = dbRef.onSnapshot((snapshot) => {
                if (snapshot.empty){
                    this.emptySearch = true;
                    this.draftPostsLoading = false;
                    return;
                }
                this.drafts = [];
                snapshot.forEach((doc) => {
                    let day = doc.data().postDate.toDate().getDate();
                    let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                    let year = doc.data().postDate.toDate().getFullYear();
                    let formattedDate = `${day} ${month} ${year}`;
                    var post = doc.data();
                    post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                    this.drafts.push(post);
                });
                this.draftPostsLoading = false;
            }, (err) => {
                console.log("error retrieving drafts", err);
                this.draftPostsLoading = false;
                if(typeof this.draftPostListener != 'undefined' || this.draftPostListener != null){
                    this.draftPostListener();
                }
            });
        },
        deletePost: function(val) {
            if (!val) {
                this.deletePostDialog = false;
            } else {
                this.$store.dispatch('deletePostFromDb', { post: this.postPayloadToDelete.post, index: this.postPayloadToDelete.index, isDraft: this.postPayloadToDelete.isDraft, deleteFromStore: false } ); // profile posts are not saved in the store
                this.drafts.splice(this.postPayloadToDelete.index, 1);
                // this.$store.commit('alertUser', { show: true, text: `Successfully deleted post.`, type: 'snackbar' });
                this.deletePostDialog = false;
            }
        },
        deleteDialog: function (payload) {
            this.deletePostDialog = true;
            this.postPayloadToDelete = payload;
        },
    },
    computed: {
        userData(){
            return this.$store.state.userData
        }
    },
    created: function(){
        this.getDraftsForUser();
    },
    beforeDestroy: function () {
        this.drafts = [];
        if(typeof this.draftPostListener != 'undefined' || this.draftPostListener != null){
            this.draftPostListener();
        }
    },
}
</script>