<template>
    <v-card class="mx-0 py-1 rounded-xl" color="grey lighten-4">
        <v-row class="mx-2" align="center">
            <!-- increments every second -->
            <v-btn @click="playAudio" small icon color="black">
                <v-icon>{{!playing && !ended ? 'mdi-play' : playing && !ended ? 'mdi-pause' : 'mdi-replay'}}</v-icon>
            </v-btn>
            
            <h6 class="black--text">{{secondsToTime(time).h}}{{secondsToTime(time).m}}:{{secondsToTime(time).s}}</h6>
            <!-- <v-progress-linear color="primary" rounded height="8" :value="time / this.duration * 100" :buffer-value="duration * 100"></v-progress-linear> -->
            <v-slider v-on:end="audioSeeked" v-on:start="pauseAudio" v-model="time" track-color="secondary" color="primary" hide-details step="0.10" min="0.0" :max="duration"></v-slider>
            <h6 class="black--text">{{secondsToTime(duration).h}}{{secondsToTime(duration).m}}:{{secondsToTime(duration).s}}</h6>
            <v-menu top offset-x open-on-hover :close-on-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn @click.stop="volumeButtonClicked" v-on="on" small icon color="black">
                        <v-icon>{{ 1.0 >= volume && volume >= 0.7 ? 'mdi-volume-high' : 0.7 > volume && volume >= 0.4 ? 'mdi-volume-medium' : 0.4 > volume && volume > 0 ? 'mdi-volume-low' : 'mdi-volume-variant-off' }}</v-icon>
                    </v-btn>
                </template>
                <v-list color="grey lighten-4">
                    <v-slider v-model="volume" @click.stop="adjustVolume" vertical step=".10" min="0" max="1" color="primary" background-color="grey lighten-4"></v-slider>
                </v-list>
            </v-menu>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "AudioPlayer",
    props: ["src",],
    components: {},
    data: function () {
        return {
            playing: false,
            ended: false,
            time: 0.0,
            duration: 0.0,
            playTimer: null,
            audioPlayer: new Audio(this.src),
            volume: 1.0,
        }
    },
    methods: {
        playAudio: function () {
            this.playing = !this.playing
            if(!this.playing && !this.ended) {
                this.audioPlayer.pause();
            } else if (this.playing && !this.ended) {
                this.audioPlayer.play();
            } else {
                this.playing = true;
                this.ended = false;
                this.audioPlayer.play();
            }
        },
        secondsToTime: function (secs) {
            // secs = Math.round(secs);
            // secs = secs/1000;
            var hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            var seconds = Math.ceil(divisor_for_seconds);
            if(seconds < 10){
                seconds = "0" + seconds;
            }

            var obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
            return obj;
        },
        pauseAudio: function () {
            this.playing = false;
            this.audioPlayer.pause();
        },
        audioSeeked: function () {
            if(this.ended){
                this.ended = false;
                this.playing = false;
            }
            this.audioPlayer.currentTime = this.time;
            // this.playing = true;
            // this.audioPlayer.play();
        },
        volumeButtonClicked: function () {
            // check to see if mobile and dont mute on click!!!
            if (this.volume > 0) {
                this.volume = 0.0//mute audio if volume is above 0
            } else {
                
                this.volume = 1.0;//if audio muted set volume to full
            }
            this.audioPlayer.volume = this.volume;
        },
        adjustVolume: function () {
            this.audioPlayer.volume = this.volume
        },
        setDuration: function () {
            // console.log(this.audioPlayer.duration);
            this.duration = this.audioPlayer.duration;
            // this.duration = Math.round(this.audioPlayer.duration);
            // The duration variable now holds the duration (in seconds) of the audio clip
        },
        setTime: function () {
            this.time = this.audioPlayer.currentTime;
            // this.time = Math.round(this.audioPlayer.currentTime);
            // console.log(this.time);
        },
        setEnded: function () {
            this.ended = true;
        },
    },
    watch: {},
    computed: {},
    beforeCreate: function () {},
    created: function () {},
    mounted: function () {
        this.audioPlayer.addEventListener('loadeddata', () => {
            this.setDuration();
        });
        this.audioPlayer.addEventListener('timeupdate', () => {
            this.setTime();
        });
        this.audioPlayer.addEventListener('ended', () => {
            this.setEnded();
        });
    },
    updated: function () {},
    beforeDestroy: function () {
        this.audioPlayer.removeEventListener('loadeddata', this.setDuration);
        this.audioPlayer.removeEventListener('timeupdate', this.setTime);
        this.audioPlayer.removeEventListener('ended', this.setEnded);
        if(this.playing) {
            this.audioPlayer.pause();
            this.audioPlayer.src = null;
            this.audioPlayer.currentTime = 0;
            this.audioPlayer.remove();
        }
    },
    destroyed: function () {},
}
</script>

<style scoped>
</style>