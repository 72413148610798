<template>
    <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-md-h3 text-h4 font-weight-light">{{ profile.username ? profile.username : 'User' }}</span>
            </v-row>

            <v-row justify="center" no-gutters class="my-2">
                <v-col cols="12" align="center">
                    <div v-if="(lastActive.daysAgo > 0 || lastActive.hoursAgo > 0) && profile.isFriend" class="text-caption">
                        <span>Signed In: </span>
                        <!-- days handling -->
                        <span v-if="lastActive.daysAgo > 1">{{lastActive.daysAgo}} Days</span>
                        <span v-else-if="lastActive.daysAgo == 1 ">{{lastActive.daysAgo}} Day</span>
                        <span v-else-if="lastActive.daysAgo > 0 && lastActive.hoursAgo == 0"> Ago</span>
                        <!-- hours handling -->
                        <span v-if="lastActive.daysAgo > 0 && lastActive.hoursAgo > 0">,</span>
                        <span v-if="lastActive.hoursAgo == 1" > {{lastActive.hoursAgo}} Hour Ago</span>
                        <span v-else-if="lastActive.hoursAgo > 1"> {{lastActive.hoursAgo}} Hours Ago</span>
                    </div>
                    <div v-else-if="(lastActive.daysAgo === 0 && lastActive.hoursAgo === 0) && profile.isFriend" class="text-caption">
                        <span>Signed In: </span>
                        <span>Just Now</span>
                    </div>

                    <div v-if="!profile.isFriend">
                        <div v-if="!profile.friendRequestSentToCurrentUser">
                            <v-btn v-if="!friendRequestSentToProfile" color="accent" text small @click="sendFriendRequest()">Add Friend</v-btn>
                            <v-btn v-if="friendRequestSentToProfile" color="accent" text small @click.stop="cancelFriendDialog=true">Cancel Request</v-btn>
                        </div>
                        <div v-if="profile.friendRequestSentToCurrentUser">
                            <v-btn color="primary" text small @click="acceptFriendRequest()" :loading="acceptLoading" :disabled="denyLoading">Accept</v-btn>
                            <v-btn color="red" text small @click="denyFriendRequest()" :loading="denyLoading" :disabled="acceptLoading">Deny</v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-btn small color="accent" text @click.stop="removeFriendDialog=true">Remove Friend</v-btn>
                    </div>
                </v-col>
                <RemoveFriend :dialog="removeFriendDialog" :friend="profile" @removeFriend="removeFriend" @cancelRemoveFriend="removeFriendDialog = false"/>
                <CancelFriend :dialog="cancelFriendDialog" :friend="profile" @cancelFriendRequest="cancelFriend" @dismissCancelFriendRequest="cancelFriendDialog = false"/>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="mx-5 mx-sm-10">
                <v-divider class="primary"/>
                <!-- MD AND UP SCREENS -->
                <v-avatar class="hidden-sm-and-down" size="120" color="primary">
                    <v-img v-if="profile.picture != null" :src="profile.picture" :alt="profile.username" max-height="118" max-width="118" referrerpolicy="no-referrer" @error="profile.picture=null"></v-img>
                    <v-img v-else-if="(profile.picture === null || typeof profile.picture === 'undefined')" src="../assets/no-user.png" :alt="profile.username" max-height="118" max-width="118"></v-img>
                </v-avatar>

                <!-- SM SCREENS -->
                <v-avatar class="hidden-md-and-up hidden-xs-only" size="100" color="primary">
                    <v-img v-if="profile.picture != null" :src="profile.picture" :alt="profile.username" max-height="98" max-width="98" referrerpolicy="no-referrer" @error="profile.picture=null"></v-img>
                    <v-img v-else-if="(profile.picture === null || typeof profile.picture === 'undefined')" src="../assets/no-user.png" :alt="profile.username" max-height="98" max-width="98"></v-img>
                </v-avatar>

                <!-- XS SCREENS -->
                <v-avatar class="hidden-sm-and-up" size="80" color="primary">
                    <v-img v-if="profile.picture != null" :src="profile.picture" :alt="profile.username" max-height="78" max-width="78" referrerpolicy="no-referrer" @error="profile.picture=null"></v-img>
                    <v-img v-else-if="(profile.picture === null || typeof profile.picture === 'undefined')" src="../assets/no-user.png" :alt="profile.username" max-height="78" max-width="78"></v-img>
                </v-avatar>
                <v-divider class="primary"/>
            </v-row>

            <v-row v-if="profile.isFriend" justify="center" no-gutters class="my-5">
                <v-btn-toggle v-model="postsToDisplay" rounded dense mandatory active-class="primary white--text">
                    <!-- TODO: make sure the atbottom listener doesn't get turned off then not turned back on? -->
                    <v-btn small @click="switchPosts('public')">Public</v-btn>
                    <v-btn small @click="switchPosts('group')">Group</v-btn>
                </v-btn-toggle>
            </v-row>

            <v-row v-if="profile.isFriend && (publicPostsLoading || groupPostsLoading) && !emptySearch" justify="start" class="pa-0">
                <v-col v-for="index in 16" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
                    <v-skeleton-loader height="250" type="image" class="rounded-xl elevation-4"></v-skeleton-loader>
                </v-col>
            </v-row>

            <v-row v-if="profile.isFriend && emptySearch" justify="center" no-gutters class="mt-5">
                <span v-if="postsToDisplay === 0" class="text-subtitle-1 text-center">It seems that {{profile.username}} has not posted anything yet.</span>
                <span v-if="postsToDisplay === 1 && arePartOfSameGroups" class="text-subtitle-1 text-center">It seems that {{profile.username}} has not posted anything in your shared groups yet.</span>
                <span v-if="postsToDisplay === 1 && !arePartOfSameGroups" class="text-subtitle-1 text-center">It seems that you do not share any groups with {{profile.username}}</span>
            </v-row>

            <v-row v-if="!profile.isFriend" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">You will not be able to see any of {{profile.username}}'s posts until you are Friends.</span>
            </v-row>

            <v-row v-else-if="profile.isFriend && postsToDisplay === 0 && !emptySearch" justify="start" class="pa-0">
                <v-col v-for="post in publicPosts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6" xs="12">
                    <Post :post="post"/>
                </v-col>
            </v-row>

            <v-row v-else-if="profile.isFriend && postsToDisplay === 1 && arePartOfSameGroups && !emptySearch" justify="start" class="pa-0">
                <v-col v-for="post in groupPosts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6" xs="12">
                    <Post :post="post"/>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import Post from "./Post.vue";
import firebase from "../../firebaseConfig.js";
import RemoveFriend from "./Friends/RemoveFriend.vue";
import CancelFriend from "./Friends/CancelFriend.vue";

export default {
    name: "view-user-profile",
    metaInfo() {
        return {
            title: `${this.profile.username ? this.profile.username : 'User Profile'} - tawq.in`,
        }
    },
    props: ["propUserId"],
    components: {
        Post,
        RemoveFriend,
        CancelFriend,
    },
    data() {
        return {
            acceptLoading: false,
            denyLoading: false,
            removeFriendDialog: false,
            cancelFriendDialog: false,
            publicPosts: [],
            groupPosts: [],
            publicPostsLoading: true,
            groupPostsLoading: false,
            profile: {
                picture: null,
                username: "",
                email: "",
                lastActive: "",
                userId: "",
                isFriend: null,
                friendRequestSentToCurrentUser: null,
                notifId: "",
            },
            friendRequestData: null,
            lastActive: {
                daysAgo: 0,
                hoursAgo: 0,
            },
            limit: 20,
            lastVisiblePublic: null,
            lastVisibleGroup: null,
            publicPostReference: "",
            groupPostReference: "",
            emptySearch: null,
            friendRequestSentToProfile: null,
            postsToDisplay: 0, // 0 = public | 1 = group
            arePartOfSameGroups: false,
            sameGroups: [],
            relationSet: false,
        };
    },
    methods: {
        switchPosts: function (type) {
            this.emptySearch = false;
            // we have to turn the atBottom listener off whenever the postReference doesn't return any posts. But we must turn it back on here, so that if all the posts have been loaded
            // for one post reference but not the other, we still have a way to retrieve more posts
            if (type === 'public'){
                if (this.publicPosts.length == 0){
                    this.getPostsByUserID();
                } else {
                    window.addEventListener('scroll', this.atBottom);
                }
            } else {
                if (this.groupPosts.length == 0){
                    this.getGroupPostsByUserId();
                } else {
                    window.addEventListener('scroll', this.atBottom);
                }
            }
        },
        setProfileData: function () {
            this.$http.get(`user/${this.profile.userId}`, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                var user = response.data;
                if (response.status == 200){
                    if(user.photoURL != ""){
                        this.profile.picture = user.photoURL;
                    }
                    this.profile.username = user.displayName;
                    this.profile.email = user.email;
                    this.profile.lastActive = user.metadata.lastSignInTime;
                    this.setLastActive();
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        setLastActive: function () {
            var date = new Date();
            var lastActive = new Date(this.profile.lastActive);
            var diff = Math.abs(date.getTime() - lastActive.getTime());
            var days = Math.floor(diff / (1000 * 3600 * 24));
            var hours =  Math.floor(diff / (1000 * 3600)) - (days * 24);
            this.lastActive.daysAgo = days;
            this.lastActive.hoursAgo = hours;
        },
        setRelationToCurrentUser: async function () {
            // console.log('setRelationToCurrentUser();');
            const isFriend = this.userFriends.find((user) => user.userId === this.profile.userId);
            // console.log("isFriend: ", isFriend);
            if (isFriend) {
                this.profile.isFriend = isFriend.accepted;
                this.friendRequestSentToProfile = isFriend.accepted ? false : true;
                this.profile.notifId = isFriend.accepted ? "" : isFriend.notifId;
                /* because retrieving the posts is dependent on the user profile's relation to the current user, 
                we will wait until the Relations have been set to try and retrieve the posts */
                if (isFriend.accepted) {
                    this.getPostsByUserID();
                    await this.getSameGroups();
                }
            } else {
                this.profile.isFriend = false;
                this.friendRequestSentToProfile = false;
                this.checkFriendRequest(); // we check our notifications to see if the profile user has sent current user a friend request
            }
            this.relationSet = true;
        },
        checkFriendRequest: function () {
            const isInNotifs = this.userNotifications.friendRequests.find((user) => {if (user.data.userId === this.profile.userId) return true;});
            if (isInNotifs) {
                this.profile.friendRequestSentToCurrentUser = true;
                this.friendRequestData = isInNotifs;
                this.friendRequestData.index = this.userNotifications.friendRequests.findIndex((user) => {if (user.data.userId === this.profile.userId) return true;})
            } else {
                this.profile.friendRequestSentToCurrentUser = false;
            }
        },
        getSameGroups: function () {
            return new Promise((resolve) => {
                // 1. get the profile user's groups and add them to the groups array if they are part of the same groups
                const groupRef = firebase.db.collection("userData").doc(this.profile.userId).collection("groups");
                groupRef.get().then((snapshot) => {
                    if (snapshot.empty){
                        this.arePartOfSameGroups = false;
                        resolve();
                    }
                    snapshot.forEach((doc) => {
                        if (this.userGroups.findIndex( (g) => g.id === doc.id ) != -1 ){
                            this.sameGroups.push(doc.id);
                            this.arePartOfSameGroups = true;
                        }
                    });
                    resolve();
                }).catch((error) => {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
                });
            });
        },
        getPostsByUserID: function () {
            if (!this.profile.isFriend){
                return;
            }
            this.publicPostsLoading = true;
            if (this.lastVisiblePublic === null) {
                this.publicPostReference = firebase.db.collection("publicPosts").where("userId", "==", this.propUserId).orderBy("postDate", "desc").limit(this.limit);
            }
            this.publicPostReference.get().then((snapshot) => {
                if (!snapshot.empty) {
                    this.emptySearch = false;
                    this.lastVisiblePublic = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                        this.publicPosts.push(post);
                    });
                    window.addEventListener("scroll", this.atBottom);
                } else {
                    if (this.lastVisiblePublic == null){
                        this.emptySearch = true;
                    }
                    window.removeEventListener('scroll', this.atBottom);
                }
                this.publicPostsLoading = false;
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        getGroupPostsByUserId: function () {
            // console.log("getGroupPostsByUserId(): ", this.arePartOfSameGroups, " : ",  this.sameGroups);
            if (!this.profile.isFriend) {
                return;
            }
            if (this.groupPosts.length === 0) { // only show the skeleton loaders on the first time loading
                this.groupPostsLoading = true;
            }
            if (this.lastVisibleGroup === null) {
                if (this.sameGroups.length > 0) {
                    this.groupPostReference = firebase.db.collection("groupPosts").where('groupId', 'in', this.sameGroups).where("userId", "==", this.profile.userId).orderBy("postDate", "desc").limit(this.limit);
                } else {
                    this.emptySearch = true;
                    return;
                }
            }
            if (this.sameGroups.length > 0) {
                this.groupPostReference.get().then((snapshot) => {
                    if (!snapshot.empty) {
                        this.emptySearch = false;
                        this.lastVisibleGroup = snapshot.docs[snapshot.docs.length - 1];
                        snapshot.forEach((doc) => {
                            // var date = doc.data().postDate.toDate().toLocaleDateString();
                            let day = doc.data().postDate.toDate().getDate();
                            let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                            let year = doc.data().postDate.toDate().getFullYear();
                            let formattedDate = `${day} ${month} ${year}`;
                            var post = doc.data();
                            post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                            this.groupPosts.push(post);
                        });
                        window.addEventListener('scroll', this.atBottom);
                    } else {
                        if (this.lastVisibleGroup === null){
                            this.emptySearch = true;
                        }
                        window.removeEventListener('scroll', this.atBottom); // turns off listener once all posts have loaded
                    }
                    this.groupPostsLoading = false;
                }).catch((err) => {
                    console.log("error retrieving group posts", err);
                });
            }
        },
        atBottom: function () {
            if (!this.publicPostsLoading && !this.groupPostsLoading) {
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                if ( bottomOfWindow ) {
                    if (this.postsToDisplay == 0){
                        this.publicPostReference = firebase.db.collection("publicPosts").where("userId", "==", this.propUserId).orderBy("postDate", "desc").startAfter(this.lastVisiblePublic).limit(this.limit);
                        this.getPostsByUserID();
                    } else {
                        if(!this.arePartOfSameGroups){
                            window.removeEventListener('scroll', this.atBottom);
                            return;
                        }
                        this.groupPostReference = firebase.db.collection("groupPosts").where('groupId', 'in', this.sameGroups).where("userId", "==", this.profile.userId).orderBy("postDate", "desc").startAfter(this.lastVisibleGroup).limit(this.limit);
                        this.getGroupPostsByUserId();
                    }
                    window.removeEventListener('scroll', this.atBottom);
                }
            }
        },
        sendFriendRequest: async function() {
            let response = await this.$http.post(`friends/${firebase.auth.currentUser.uid}`, {user: { userId: firebase.auth.currentUser.uid, username: firebase.auth.currentUser.displayName, email: firebase.auth.currentUser.email, picture: firebase.auth.currentUser.photoURL, }, friend: this.profile,});
            if (response.status === 200) {
                this.friendRequestSentToProfile = true;
                this.relationSet = false;
                this.$store.commit('alertUser', { show: true, text: "Friend Request Sent.", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
        },
        removeFriend: async function () {
            var notif = {
                id: this.profile.notifId,
                data: {
                    email: this.profile.email,
                    username: this.profile.username,
                    type: 'friend',
                    userId: this.profile.userId, //firebase.auth.currentUser.uid,
                    userProfilePhoto: this.profile.picture,
                }
            }
            let response = await this.$http.delete(`friends/${this.profile.userId}/${firebase.auth.currentUser.uid}`, {data: {notif: notif, accepted: this.profile.isFriend}});
            if (response.status === 200) {
                this.relationSet = false;
                this.$store.commit('alertUser', { show: true, text: "Friend Removed", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.removeFriendDialog = false;
        },
        cancelFriend: async function () {
            var notif = {
                id: this.profile.notifId,
                data: {
                    email: this.profile.email,
                    username: this.profile.username,
                    type: 'friend',
                    userId: this.profile.userId, //firebase.auth.currentUser.uid,
                    userProfilePhoto: this.profile.picture,
                }
            }
            let response = await this.$http.delete(`friends/${this.profile.userId}/${firebase.auth.currentUser.uid}`, {data: {notif: notif, accepted: this.profile.isFriend}});
            if (response.status === 200) {
                this.friendRequestSentToProfile = false;
                this.relationSet = false;
                this.$store.commit('alertUser', { show: true, text: "Friend Request Canceled", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.cancelFriendDialog = false;
        },
        acceptFriendRequest: async function () {
            this.userNotifications.friendRequests.splice((this.friendRequestData.index), 1);
            this.acceptLoading = true;
            let response = await this.$http.put(`friends/${this.friendRequestData.data.userId}/${firebase.auth.currentUser.uid}`, {friend: this.friendRequestData,});
            if (response.status === 200) {
                this.relationSet = false;
                this.$store.commit('alertUser', { show: true, text: "Friend Request Accepted", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.acceptLoading = false;
        },
        denyFriendRequest: async function () {
            this.userNotifications.friendRequests.splice((this.friendRequestData.index), 1);
            this.denyLoading = true;
            let response = await this.$http.delete(`friends/${firebase.auth.currentUser.uid}/${this.friendRequestData.data.userId}`, {data: {notif: this.friendRequestData, accepted: false}});
            if (response.status === 200) {
                this.relationSet = false;
                this.$store.commit('alertUser', { show: true, text: "Friend Request Rejected", type: 'snackbar' });
            } else {
                this.$store.commit('alertUser', { show: true, text: "Something went wrong. Please try again!", type: 'snackbar' });
            }
            this.denyLoading = false;
        },
    },
    watch: {
        'userFriends' () {
            if (!this.relationSet) {
                this.setRelationToCurrentUser();
            }
        },
    },
    computed: {
        userGroups(){
            return this.$store.state.userGroups;
        },
        userNotifications: {
            get(){
                return this.$store.state.userNotifications;
            },
            set(){
                this.$store.commit('saveFriendRequestNotifications', this.userNotifications);
            },
        },
        userFriends() {
            return this.$store.state.userFriends;
        },
    },
    created: function() {
        this.profile.userId = this.propUserId;
        this.setProfileData();
        this.setRelationToCurrentUser();
    },
    mounted: function() {
        window.addEventListener('scroll', this.atBottom);
    },
    beforeDestroy: function(){
        window.removeEventListener('scroll', this.atBottom);
    },
};
</script>
    