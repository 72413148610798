<template>
    <v-row justify="center" align="center" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9; direction: rtl;" class="transparent">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'ar-intro'})"></v-img>
                </v-col>
            </v-app-bar>

            <v-row justify="center" align="center" class="ma-0 pa-0">
                <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                    <v-card v-if="mode == 'resetPassword'" light elevation="8" class="white rounded-xl pb-4">
                        <v-card-title v-if="!showHintOnly" style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">كلمة المرور الجديدة</span> <!-- New Password -->
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row v-if="!showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form v-if="passwordForm" @submit.prevent="setNewPassword()">
                                    <v-text-field v-model="newPassword" :type="showPassword ? 'text' : 'password'" label="كلمة المرور الجديدة" reverse outlined required validate-on-blur prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" :rules="newPasswordRules" @click:append="showPassword = !showPassword"></v-text-field><!-- New Password -->
                                    <v-text-field v-model="confirmNewPassword" :type="showConfirmPassword ? 'text' : 'password'" label="تأكيد كلمة المرور" reverse outlined required prepend-inner-icon="mdi-lock" :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" validate-on-blur :rules="confirmPasswordRules" @click:append="showConfirmPassword = !showConfirmPassword" @keypress.enter="setNewPassword()"></v-text-field><!-- Confirm Password -->

                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light" :class="needHelp ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> <!-- Need Help? Contact Us-->
                                </v-form>
                                <v-form v-else @submit.prevent="sendPasswordReset()">
                                    <v-text-field v-model="userEmail" label="بريد إلكتروني" type="email" reverse outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" :rules="emailRules"></v-text-field><!-- Email -->
                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mb-0">{{hintText}}</p>
                                    <!-- <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> Need Help? Contact Us -->
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>

                        <v-card-actions v-if="!showHintOnly" class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-btn v-if="passwordForm" rounded large depressed color="primary" class="my-5" @click="setNewPassword()">إعادة ضبط</v-btn> <!-- Re-Set (same as Reset?) -->
                                    <v-btn v-else rounded large depressed color="primary" class="my-5" @click="sendPasswordReset()">إعادة إرسال الرابط</v-btn> <!-- Resend the link -->
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else-if="mode == 'verifyEmail'" light elevation="8" class="white rounded-xl pb-4">
                        <v-card-title v-if="!showHintOnly" style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">جارٍ التحقق من بريدك الإلكتروني...</span> <!-- Verifying your email... -->
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row v-if="!showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light" :class="needHelp ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">تحتاج مساعدة؟ <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep()">ارسل لنا عبر البريد الإلكتروني</span></p> <!-- Need Help? Contact Us -->
                                <v-btn v-if="showRetrySendVerificationLink" rounded large depressed color="primary" class="my-5" @click="resendEmailVerification()">إعادة إرسال الرابط</v-btn> <!-- Resend the link -->
                            </v-col>
                        </v-row>

                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>

                        <v-card-actions v-if="!showHintOnly" class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-progress-circular v-if="!hint" :indeterminate="true" size="100" width="5" color="primary"></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else light elevation="8" class="white rounded-xl pb-4">
                        <v-row v-if="showHintOnly" justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light text-h6 mt-4 mb-0">{{hintText}}</p>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../../firebaseConfig.js";
export default {
    name: "ar-manage-account",
    metaInfo: {
        // title: 'Account Recovery - tawqin'
        title: 'استرجاع الحساب - tawqin'
    },
    props: ["code", "mode", "continueURL"],
    data() {
        return {
            userEmail: "",
            emailRules: [
                (v) => !!v || "البريد الإلكتروني (مطلوب", // Email is required
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "يجب أن يكون البريد الإلكتروني صالحا", // E-mail must be valid
            ],
            passwordForm: true,
            newPassword: "",
            newPasswordRules: [
                (v) => v.length >= 8 || "يجب أن تتكون كلمة المرور من 8 أحرف أو أكثر", // Password Must Be 8 Characters Or More
            ],
            showPassword: true,
            confirmNewPassword: "",
            confirmPasswordRules: [
                (v) => v == this.newPassword || "كلمة المرور غير مطابقة" // Passwords Do Not Match
            ],
            showConfirmPassword: false,
            showRetrySendVerificationLink: false,
            showHintOnly: false,
            hint: false,
            hintText: "",
            needHelp: false,
        };
    },
    methods: {
        setNewPassword: function() {
            if (this.newPassword == this.confirmNewPassword) { // double check passwords match
                firebase.auth.confirmPasswordReset(this.code, this.newPassword).then(() => {
                    // password has been reset successfully, go to login page
                    this.$store.commit('alertUser', { show: true, text: 'تم إعادة تعيين كلمة المرور الخاصة بك.', type: 'snackbar' }); // Your password has been reset.
                    this.$router.replace( {path: '/ar-login'} );
                }).catch((error) => {
                    this.hint = true;
                    this.hintText = `${this.setHintText(error.code)}`;
                });
            }
        },
        sendPasswordReset: function() {
            var actionCodeSettings = {
                url: 'https://tawq.in/login',
                handleCodeInApp: true,
            }
            firebase.auth.sendPasswordResetEmail(this.userEmail, actionCodeSettings).then(() => {
                this.showHintOnly = true;
                this.hint = true;
                this.hintText = "تم إرسال رابط جديد لإعادة تعيين كلمة المرور! يمكنك الآن إغلاق هذه الصفحة."; // A new password reset link has been sent! You can now close this page.
            }).catch((error)=> {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
            })
        },
        verifyEmail: function(){
            firebase.auth.checkActionCode( this.code ).then(() => {
                // is the correct code, switch the user to verified.
                firebase.auth.applyActionCode( this.code ).then( async () => {
                    /*  the verification hasn't set 'currentUser.emailVerified' true for a couple seconds (minimum)
                        this function has completed. So the router ends up sending the user to the login page instead of the home
                        page. therefore we will wait until it has been set, then re route them
                    */
                   window.location.href = this.continueURL;
                }).catch((error) => {
                    this.hint = true;
                    this.hintText = `${this.setHintText(error.code)}`;
                });
            }).catch((error) => {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
            });
        },
        resendEmailVerification: function(){
            var actionCodeSettings = {
                url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
            }
            firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(() => {
                this.showHintOnly = true;
                this.hintText = `تم إرسال رابط التحقق من البريد الإلكتروني الجديد! يمكنك الآن إغلاق هذه الصفحة.`; // New email verification link has been sent! You can now close this page.
                this.showRetrySendVerificationLink = false;
                // firebase.auth.signOut();
            }).catch((err) => {
                this.hint = true;
                this.hintText = `${this.setHintText(err.code)}`;
            })
        },
        emailKeep: async function(){
            var domain = this.userEmail.split('@')[1];
            const subject = "Manage Account";
            const message = `Hello tawq.in team, ${'%0D%0A'} I am having trouble accessing my account for tawq.in, please help me! ${'%0D%0A'} Thanks!`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com': {
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                }

                default: {
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                }
            } // end of email/gmail switch
            if (this.mode == "verifyEmail") {
                this.hintText = "يمكنك الآن إغلاق هذه الصفحة والمحاولة مرة أخرى."; // You can now close this page and try again.
                this.needHelp = false;
            } else { // this.mode = "resetPassword"
                this.hint = false;
                this.hintText = "";
                this.needHelp = false;
            }
        },
        setHintText: function(error) {
            if ( error == 'auth/expired-action-code' || error == 'auth/invalid-action-code' ){
                this.passwordForm = false;
                this.showRetrySendVerificationLink = true;
                return "هذا الرابط غير صالح أو منتهي الصلاحية. هل تحتاج إلى واحدة جديدة؟"; // This link is invalid or expired. Do you need a new one?
            } else if (error == 'auth/user-disabled') {
                this.needHelp = true;
                return "لقد تم تعطيل المستخدم من استخدام موقع Tawq.in. اتصل بالدعم للحصول على المساعدة."; // The user has been disabled from using the Tawq.in website. Contact support for assistance.
            } else if (error == 'auth/weak-password') {
                return "كلمة المرور التي أدخلتها ضعيفة للغاية. لأغراض أمنية، يرجى المحاولة مرة أخرى"; // The password you entered is too weak. For security purposes, please try again
            } else if (error == 'auth/user-not-found') {
                this.passwordForm = false;
                return "عنوان البريد الإلكتروني المقدم غير مسجل في tawq.in. حاول ثانية."; // The email address provided is not registered with tawq.in. Please try again.
            } else {
                this.passwordForm = false;
                this.showRetrySendVerificationLink = true;
                return "هناك شيء ليس صحيحا! احصل على رابط بريد إلكتروني آخر وحاول مرة أخرى."; // Something is not right! Get another email link and try again.
            }

        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: function() {
        if (this.mode == 'resetPassword') {
            firebase.auth.verifyPasswordResetCode(this.code).then(() => {
                // if confirmation code matches
                this.passwordForm = true;
            }).catch((error) => {
                this.hint = true;
                this.hintText = `${this.setHintText(error.code)}`;
                this.passwordForm = false;
            });
        } else if ( this.mode == 'verifyEmail') {
            this.verifyEmail();
        } else { // '/ar-manage-account' only
            this.showHintOnly = true;
            this.hint = true;
            this.hintText = "هناك شيء غير صحيح. الرجاء إغلاق الصفحة والمحاولة مرة أخرى."; // Something is not right. Please close the page and try again
        }
    },
}
</script>