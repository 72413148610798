<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelRemoveFriend">
        <v-card class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Are you sure you want to remove {{ friend.username }}</span>
                <!-- <p class="text-wrap text-h5 font-weight-bold">Are you sure you want to remove <span class="font-weight-light">{{ friend.username }}</span></p> -->
            </v-card-title>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click.stop="cancelRemoveFriend">Cancel</v-btn>
                    <v-btn color="primary" text @click.stop="removeFriend">Remove Friend</v-btn>
                </v-row>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
// import firebase from "../../../firebaseConfig.js";


export default {
    name: "remove-friend",
    props: ["dialog", "friend"],
    data() {
        return {};
    },
    methods: {
        cancelRemoveFriend: function() {
            this.$emit("cancelRemoveFriend");
        },
        removeFriend: function() {
            this.$emit("removeFriend");
        },
    },
};
</script>
