<template>
    <div>
        <v-row justify="space-around" align="center" no-gutters class="mx-0">
            <v-col cols="2" xl="1" lg="1" md="1" sm="2" align="center" class="my-0 py-2">
                <span class="text-wrap accent--text text-subtitle-1 font-weight-black">{{index}}.</span>
            </v-col>
            <v-col cols="10" xl="11" lg="11" md="11" sm="10" align="start" class="my-0 py-2">
                <v-textarea v-if="editState" v-model="tawqData[index].caption" rows="1" hide-details outlined no-resize auto-grow :disabled="!showCaptions" :placeholder="!showCaptions ? 'Captions Disabled' : null" @change="hasBeenEdited = true" @keydown.enter="emitSave" class="text-subtitle-1 font-weight-black pr-1"></v-textarea>
                <div v-if="loading" class="d-flex flex-row justify-start align-center">
                    <v-progress-circular size="15" color="primary" indeterminate></v-progress-circular>
                    <pre class="ml-2 invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                </div>
                <span v-if="!editState && !loading" :class="audioSegmentFailed ? 'red--text' : ''" class="text-wrap text-subtitle-1 font-weight-black">{{ showCaptions ? tawqData[index].caption : (!showCaptions && audioSegmentFailed) ? tawqData[index].caption : '(Auto Captioning Disabled)' }}</span>
            </v-col>
        </v-row>
        <v-row v-if="!loading" justify="space-around" align="center" no-gutters class="mx-0 mb-2">
            <v-col cols="9" class="my-0">
                <v-row v-if="!editState" justify="start" no-gutters class="mx-0">
                    <span class="text-caption font-weight-normal px-1">Start:</span>
                    <span class="text-caption font-weight-light pr-2"><span v-if="formattedStartTime.h > 0">{{formattedStartTime.h}}h:</span><span v-if="formattedStartTime.m > 0">{{formattedStartTime.m}}m:</span><span>{{formattedStartTime.s}}s</span></span>
                    <span class="text-caption font-weight-normal pr-1">Duration:</span>
                    <span class="text-caption font-weight-light">{{ tawqData[index].audio.audioDur.toFixed(1) }}s</span>
                </v-row>
                <v-row v-if="!editState" v-show="overlappingSegments.length > 0" justify="start" no-gutters class="mx-0">
                    <span class="text-caption red--text px-1">Overlaps: </span>
                    <span class="d-flex flex-wrap mr-2"><span v-for="seg in overlappingSegments" :key="seg" class="px-1 text-caption white--text red rounded-circle">{{seg}}</span></span>
                </v-row>
                <v-row v-if="editState" justify="center" no-gutters class="mx-0 mt-4">
                    <span class="text-caption">Start Time: {{formattedStartTime.h}}h:{{formattedStartTime.m}}m:{{formattedStartTime.s}}s / {{durationHours.toFixed(0)}}h:{{durationMinutes.toFixed(0)}}m:{{durationSeconds.toFixed(0)}}s</span>
                    <div class="d-flex flex-row align-center justify-center" style="width: 80%;">
                        <v-slider v-model="startTime" :max="videoDuration" :min=0 :step=0.1 color="primary" dense hide-details track-color="secondary--lighten" @change="hasBeenEdited = true">
                            <template v-slot:prepend>
                                <v-icon color="primary" @click="timeDecrement">mdi-minus</v-icon>
                            </template>
                            <template v-slot:append>
                                <v-icon color="primary" @click="timeIncrement">mdi-plus</v-icon>
                            </template>
                        </v-slider>
                    </div>
                </v-row>
            </v-col>
            <v-col cols="3" class="my-0">
                <v-row justify="end" no-gutters class="mx-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="emitSave" v-on="on" :disabled="audioSegmentFailed" icon small color="accent" class="hidden-sm-and-down mr-2">
                                <v-icon>{{editState ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                            <v-btn @click.stop="emitSave" :disabled="audioSegmentFailed" icon x-small color="accent" class="hidden-md-and-up mr-1">
                                <v-icon>{{editState ? 'mdi-content-save-edit' : 'mdi-pencil'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{editState ? 'Save caption' : 'Edit caption'}}</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="accent" small @click.stop="emitDelete" class="hidden-md-and-down mr-2">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn icon color="accent" x-small @click.stop="emitDelete" class="hidden-lg-and-up mr-1">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Delete this segment</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon outlined color="accent" small :disabled="audioSegmentFailed" @click.native.stop="emitAudioPlay" class="hidden-md-and-down">
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                            <v-btn icon outlined color="accent" x-small :disabled="audioSegmentFailed" @click.native.stop="emitAudioPlay" class="hidden-lg-and-up">
                                <v-icon>mdi-play</v-icon>
                            </v-btn>
                        </template>
                        <span>Play this segment</span>
                    </v-tooltip>
                </v-row>
            </v-col>
        </v-row>
        <v-divider/>
    </div>
</template>

<script>
export default {
    props: ['index', "showCaptions", 'loading', 'videoDuration',],
    data: function() {
        return {
            editState: false,
            hasBeenEdited: false,
            durationHours: 0,
            durationMinutes: 0,
            durationSeconds: 0,
            startTime: null,
            time: null,
            convertingCaption: "Converting   ",
            convertingInterval: null,
            audioSegmentFailed: false,
        }
    },
    methods: {
        emitSave: function () {
            // Send this.editState value to parent component, which in this case, and this case only, is PostContentCreation. Then write the value to realtime database, that way we can use this.editState to tell whether or not we should record using the spacebar or ignore if we are in an editing state.

            // January 10, 2024: uncomment the code below if there is an issue and see if it works, comment editState and emit at bottomw before running.
            // this.editState = !this.editState;
            // this.$emit("editState", this.editState);
            if (/*!*/this.editState && this.hasBeenEdited) {
                this.tawqData[this.index].audio.audioStart = this.startTime;
                this.tawqData[this.index].audio.audioEnd = this.tawqData[this.index].audio.audioStart + this.tawqData[this.index].audio.audioDur;
                const formattedCaption = this.tawqData[this.index].caption?.charAt(0).toUpperCase() + this.tawqData[this.index].caption?.slice(1) || "";
                // const formattedCaption = typeof this.tawqData[this.index].caption != 'undefined' ? this.tawqData[this.index].caption.charAt(0).toUpperCase() + this.tawqData[this.index].caption.slice(1) : "";
                this.tawqData[this.index].caption = formattedCaption;
                this.hasBeenEdited = false;
            }

            this.editState = !this.editState;
            this.$emit("editState", this.editState);
        },
        emitAudioPlay: function () {
            this.$emit('audioPlay', this.index);
        },
        emitDelete: function() {
            var deleteLocalOnly = this.tawqData[this.index].audio.error ? true : false;
            this.$emit('deleteAudioSegment', { audioSegId: this.index, audioSegmentFailed: deleteLocalOnly } );
        },
        secondsToTime: function(secs){
            secs = Math.round(secs);
            var hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            var seconds = Math.ceil(divisor_for_seconds);

            var obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
            return obj;
        },
        timeDecrement: function () {
            this.hasBeenEdited = true;
            this.startTime = (this.startTime - 1) || 0;
        },
        timeIncrement: function () {
            this.hasBeenEdited = true;
            this.startTime = (this.startTime + 1) || this.videoDuration;
        },
        
    },
    computed: {
        overlappingSegments(){
            return this.$store.getters.getOverlappingSegsForAudioSeg(this.index);
        },
        tawqData: {
            get(){
                return this.$store.state.newPost.tawqData;
            },
            set(value){
                this.$store.commit('saveTawqData', value);
            }
        },
        formattedStartTime(){
            let secs = Math.round(this.startTime);
            var hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            var seconds = Math.ceil(divisor_for_seconds);

            var obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
            return obj;
        },
        // contentCreationStep: {
        //     get() {
        //         return this.$store.state.newPost.contentCreationStep;
        //     },
        //     set(value){
        //         this.$store.commit('saveContentCreationStep', value);
        //     }
        // },
    },
    watch: {
        videoDuration(){
            this.time = this.secondsToTime(this.videoDuration);
            this.durationHours = this.time.h;
            this.durationMinutes = this.time.m;
            this.durationSeconds = this.time.s;
        },
        loading(newValue){
            if(!newValue){ // when it isn't loading anymore (the caption is ready to display/done loading)
                if (this.tawqData[this.index].audio.audioURL === 'failed') this.audioSegmentFailed = true;
                this.startTime = this.tawqData[this.index].audio.audioStart;
                clearInterval(this.convertingInterval);
                this.$emit('updateScroll');
            }
        }
    },
    created: function() {
        this.time = this.secondsToTime(this.videoDuration);
        // this.contentCreationStep.complete = false; // set to false so the user can't save while a caption is converting
        // console.log("cc video duration: ", this.videoDuration);
    },
    mounted: function() {
        this.$emit('updateScroll');
        if (!this.loading) {
            this.startTime = this.tawqData[this.index].audio.audioStart;
            if (this.tawqData[this.index].audio.audioURL === 'failed') this.audioSegmentFailed = true;
        } else {
            var dotCount = 1;
            this.convertingCaption = "Converting   ";
            this.convertingInterval = setInterval(() => {
                if (dotCount > 3) {
                    dotCount = 1;
                    this.convertingCaption = "Converting   ";
                    return;
                } 
                else {
                    dotCount++;
                    this.convertingCaption = this.convertingCaption.replace(" ", ".");
                    return;
                }
            }, 400);
        }
        this.durationHours = this.time.h;
        this.durationMinutes = this.time.m;
        this.durationSeconds = this.time.s;
    }
}
</script>

<style scoped>
select, textarea, input {
    font-size: inherit !important;
}

</style>