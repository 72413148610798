<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="8" lg="8" md="10" sm="10">

            <v-row justify="center" no-gutters>
                <span class="text-h6 text-sm-h5 text-center">Record Your Voice</span>
            </v-row>

            <!-- DUAL DEVICE CREATION ***REMOVED ON OCTOBER 28, 2022 AT MARTY'S REQUEST (last seen in code on January 2, 2024 in vue3 git branch)***  -->

            <v-row justify="center" no-gutters class="mt-4">
                <!-- <VideoPlayer
                    ref="vidPlayer"
                    :options="vidOptions"
                    :isCreating="true"
                    :videoLoading="videoLoading"
                    @playerReady="videoLoading = false"
                    @videoDuration="setVideoDuration"
                    @visibilityChanged="audioPlayer.pause()"
                    @videoPauseButtonClicked="pauseProgressBar"
                    @videoPlayButtonClicked="playProgressBar"
                /> -->

                <v-row justify="center" class="ma-0 pa-0">
                    <!-- OLD RECORDING PROGRESS BAR CODE (removed/updated by Scott Brady on March 20, 2024) -->
                        <!-- <v-col v-if="showProgressBar" cols="12" xl="10" lg="10" md="12" sm="12" class="my-0 mb-0 mt-1 pa-0">
                            <v-row justify="space-between" class="ma-0 pa-0 text-caption">
                                <span class="font-weight-bold">{{emitTime / 1000 + " seconds"}}</span>
                                <span class="font-weight-bold">{{ timePlaying / 1000 + " seconds" }}</span>
                            </v-row>

                            <v-progress-linear v-if="(timePlaying / 1000) >= (emitTime / 1000)" background-color="secondary" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>

                            <v-progress-linear v-else-if="(timePlaying / 1000) < (emitTime / 1000)" color="red" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>

                            <v-row justify="center" class="ma-0 pa-0 smallText text-center px-3">
                                <span v-if="(timePlaying / 1000) >= (emitTime / 1000)" class="font-weight-bold">This bar is used to track recommended recording size for a caption</span>
                                <span v-else-if="(timePlaying / 1000) < (emitTime / 1000)" class="font-weight-bold">Your audio and captions my not align correctly in the video</span>
                            </v-row>
                        </v-col> -->

                        <!-- <v-col cols="10" xl="8" lg="8" md="10" sm="10">
                            <v-row justify="center" align="start" style="z-index: 2; transform: translateY(50%);" >
                                <v-col cols="2" class="text-caption green-border">
                                    <v-row justify="center">
                                        <span class="font-weight-bold">{{emitTime / 1000 + " seconds"}}</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="10" class="green-border">
                                    <v-row>
                                        <v-progress-linear v-if="(timePlaying / 1000) >= (emitTime / 1000)" background-color="secondary" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>
                                        <v-progress-linear v-else-if="(timePlaying / 1000) < (emitTime / 1000)" color="red" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>
                                    </v-row>
                                    <v-row justify="center" class="smallText text-center">
                                        <span v-if="(timePlaying / 1000) >= (emitTime / 1000)" class="font-weight-bold">This bar is used to track recommended recording size for a caption</span>
                                        <span v-else-if="(timePlaying / 1000) < (emitTime / 1000)" class="font-weight-bold">Your audio and captions my not align correctly in the video</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="2" class="text-caption green-border">
                                    <v-row justify="center">
                                        <span class="font-weight-bold">{{ timePlaying / 1000 + " seconds" }}</span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="2" class="blue-border"> -->
                    <!-- END OLD RECORDING PROGRESS BAR CODE (removed/updated by Scott Brady on March 20, 2024) -->

                    <!-- <v-col cols="12" xl="10" lg="10" md="12" sm="12" >
                        <v-row v-if="isMobile" justify="end" align="center" class="full-width mx-0">
                            <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar" class="mt-0 mb-2"/>
                            <Push v-if="userData.pushToTawq" :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                            <Pickup v-else :isDraftPost="isDraftPost" :tawqin="tawqin" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                        </v-row>

                        <v-row v-else justify="space-between" align="center">
                            <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar"/>
                            <Push :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                        </v-row>
                    </v-col> -->

                    <v-col cols="12" xl="10" lg="10" md="12" sm="12" class="caption-card-container d-flex flex-column-reverse inverseGrey py-6">
                        <!-- <CaptionCard
                            v-for="(data, key) in tawqData"
                            :key="parseInt(key)"
                            :index="parseInt(key)"
                            :showCaptions="(postData.languageSelected.text === 'None (Disable Auto Captions)' ? false : true)"
                            :loading="data.caption === null || data.audio.audioURL === null"
                            :videoDuration="videoDuration"
                            @deleteAudioSegment="deleteAudioSegment"
                            @audioPlay="playAudioSegment"
                            @updateScroll="updateScroll"
                            @editState="sendEditStateToPush"
                        />
                        <v-divider v-if="hasAudio"/>
                        <div :class="(postData.languageSelected.text === 'None (Disable Auto Captions)' ? 'mb-2' : '')" class="text-lg-subtitle-2 text-caption text-center">{{((postData.languageSelected.text === 'None (Disable Auto Captions)' && !showCaptions) ? 'Automatic captioning has been disabled. To enable, go to back to step 1 and select the language you will be speaking. You must record at least once to move to the review process.' : (postData.languageSelected.text === 'None (Disable Auto Captions)' && showCaptions) ? 'Automatic captioning has been disabled. To enable, delete your current segments and go to back to step 1 and select the language you will be speaking.' : (!showCaptions ? 'You must record at least once to move to the review process.' : ''))}}</div> -->
                    </v-col>
                </v-row>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
// import firebase from "../../firebaseConfig";
import VideoPlayer from "../VideoPlayer";
import CaptionCard from "../CaptionCard";
import Push from "../Mobile/Push";
import Pickup from "../Mobile/Pickup";
import RecordProgressBar from '../RecordProgressBar';

export default {
    props: [],
    components: {
        VideoPlayer,
        CaptionCard,
        Push,
        Pickup,
        RecordProgressBar,
    },
    data: function () {
        return {};
    },
    methods: {},
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
    },
    mounted: function () {},
    created: function () {},
    beforeDestroy: function () {},
}
</script>

<style>
</style>
