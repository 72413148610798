<template>
    <v-container fluid>
        <!-- Nav Bar Code -->
        <v-app-bar v-bind:class="[$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3']" app>

            <v-row justify="space-between" align="center" no-gutters>
                <v-col cols="12">
                    <v-row align="center" no-gutters class="ma-0 pa-0">
                        <v-btn icon @click.stop="backDialog = true;">
                            <v-icon color="primary" x-large>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text text-truncate font-weight-light">Contribute {{Object.keys(postData).length === 0 ? '' : (postData.title.length >= (this.titleLength + 2) && isMobile) ? `- ${postData.title.match(regex)[0]}...` : `- ${postData.title}`}}</v-toolbar-title>
                    </v-row>
                </v-col>
                <!-- <v-col cols="2" align="end" class="ma-0">
                    <router-link class="pr-5" :to="{ name: 'help', query: {loadTopic: 'contribute'} }" target="_blank" style="text-decoration: none;">
                        <v-icon :class="[$vuetify.theme.dark ? 'white--text' : 'primary--text']" size="20">mdi-help</v-icon>
                    </router-link>
                </v-col> -->
            </v-row>

            <!-- HELP BUTTON -->
            <!-- <transition name="fade">
                <div v-if="userData.postsCreated < 3 && showHelpIndicator" class="d-flex flex-column px-8"  style="position: absolute; bottom: -60px; right: 0px;">
                    <v-icon class="align-self-end">mdi-arrow-up</v-icon>
                    <span class="rounded-xl primary py-1 px-3">Need help? Click here.</span> 
                </div>
            </transition> -->

        </v-app-bar>
        <v-dialog v-if="backDialog" v-model="backDialog" absolute persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
            <v-card class="rounded-xl pa-2">
                <v-card-title style="word-break: normal">
                    <span class="text-wrap text-h5 font-weight-bold">Are you sure you want to Exit?</span>
                    <p class="font-weight-light text-subtitle-1 mt-3">By selecting Delete, all your progress will be <span class="font-weight-bold">permanently</span> lost.</p>
                </v-card-title>

                <v-card-actions>
                    <v-row justify="end" no-gutters>
                        <v-btn color="primary" text @click="backDialog = false">Cancel</v-btn>
                        <v-btn color="red" text :disabled="checkDeleteButtonValidity" @click="goBack()">Delete</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Nav Bar Code -->

        <!-- LOADING OVERLAY -->
        <div v-if="loading" class="payment-loading-container ma-0 pa-0">
            <v-overlay absolute opacity="0.90">
                <v-row justify="center" align="center" class="ma-0 pa-5">
                    <v-progress-circular size="75" :width="7" indeterminate color="primary"></v-progress-circular>
                </v-row>
                <v-row justify="center" align="center" class="ma-0 pa-5">
                    <span class="primary--text">We are saving your Contribution. Please wait!</span>
                    <!-- style="textShadow: 0px 0px 1px white;" -->
                </v-row>
            </v-overlay>
        </div>
        <!-- END OF LOADING OVERLAY -->


        <!-- CREATION COMPONENTS -->
        <v-row justify="center" no-gutters class="mt-12 mt-md-15">
            <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                <!-- id="contributionMetadata" -->
                <ContributionMetadata
                    v-if="currentStep === newAudioStepEnum.CONTENT_METADATA"
                    :matchAudioStartTimesWithSelectedContribution="matchAudioStartTimesWithSelectedContribution"
                    @changeMatchAudioStartTimes="changeMatchAudioStartTimes"
                    @nextStep="nextStep()"
                />
                <ContributionCreation
                    v-if="currentStep === newAudioStepEnum.CONTENT_CREATION"
                    :previewVideo="previewVideo"
                    :propPostData="postData"
                    :propAudioData="audioData"
                    :matchAudioStartTimesWithSelectedContribution="matchAudioStartTimesWithSelectedContribution"
                    @setCloudFunctionTimeout="setCloudFunctionTimeout"
                    @clearCloudFunctionTimeout="clearCloudFunctionTimeout"
                    @clearAllCloudFunctionTimeouts="clearAllCloudFunctionTimeouts"
                />

                <!-- BUTTONS FOR MD AND UP -->
                <v-row justify="center" align="center" no-gutters class="hidden-sm-and-down mt-5">
                    <v-col cols="4">
                        <v-btn rounded outlined depressed color="primary" large :disabled="currentStep === 1" @click="previousStep()" class="font-weight-light">Previous</v-btn>
                    </v-col>
                    <v-col cols="4" align="end">
                        <v-btn v-if="currentStep != totalSteps" rounded depressed color="primary" large :disabled="checkButtonValidity" @click="nextStep()" class="font-weight-light">Next</v-btn>
                        <v-btn v-else rounded depressed color="primary" large :disabled="checkButtonValidity" @click="saveStep()" class="font-weight-light">Save</v-btn>
                    </v-col>
                </v-row>

                <!-- BUTTONS FOR SM AND DOWN -->
                <v-row justify="center" align="center" no-gutters class="hidden-md-and-up mt-5">
                    <v-col cols="6">
                        <v-btn rounded outlined depressed color="primary" medium :disabled="currentStep === 1" @click="previousStep()" class="font-weight-light">Previous</v-btn>
                    </v-col>
                    <v-col cols="6" align="end">
                        <v-btn v-if="currentStep != totalSteps" rounded depressed color="primary" medium :disabled="checkButtonValidity" @click="nextStep()" class="font-weight-light">Next</v-btn>
                        <v-btn v-else rounded depressed color="primary" medium :disabled="checkButtonValidity" @click="saveStep()" class="font-weight-light">Save</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import firebase from "../../firebaseConfig.js";
// import fb from "firebase/app";
import ContributionMetadata from '../components/Contributions/ContributionMetadata.vue'
import ContributionCreation from '../components/Contributions/ContributionCreation.vue'

export default {
    metaInfo: {
        title: "Contribute - tawqin"
    },
    name: "new-contribution",
    props: ["postId", "postType", "audioData",],
    components: {
        ContributionMetadata,
        ContributionCreation,
    },
    data: function() {
        return {
            loading: false,
            backDialog: false,
            totalSteps: Object.keys(this.$store.state.newContributionStore.newAudioStep).length,
            completed: false,
            previewVideo: [
                {
                    src: null,
                    type: null,
                },
            ],
            postData: {},
            cloudFunctionTimeoutIds: [],
            titleLength: 50,
            regex: null,
            matchAudioStartTimesWithSelectedContribution: true,
            // showHelpIndicator: true,
        };
    },
    methods: {
        goBack: function () {
            this.backDialog = false;
            this.clearAllCloudFunctionTimeouts();
            this.clearRTDB(firebase.auth.currentUser.uid);
            this.$router.go(-1);
        },
        changeMatchAudioStartTimes: function (value) {
            this.matchAudioStartTimesWithSelectedContribution = value;
        },
        nextStep: async function () {
            if (this.currentStep + 1 === this.newAudioStepEnum.CONTENT_CREATION) { // handle going on from MEDIA SELECTION STEP (step 1 to step 2)
                if (!this.userData.pushToTawq) { // get permissions if !pushToTawq (pickupToTawq = true)
                    // push to tawq -> will ask microphone permissions (not orientation) in push to tawq
                    // !push to tawq (pick up to tawq) -> asks orientation permissions
                    let proceed = await this.getPermissions();
                    if (!proceed) return; // dont proceed, didn't get permissions or user didn't allow
                }
            }
            this.currentStep += 1;
        },
        previousStep: function () {
            /*if (!this.matchAudioStartTimesWithSelectedContribution)*/ 
            if (this.$route.query.aid) this.$router.replace({ path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, } });
            this.currentStep -= 1;
        },
        saveStep: async function () {
            this.loading = true; // used to show a full screen overlay while saving contribution
            await this.filterUndefinedValues(); // ensure that this.tawqData object contains properly formatted data.

            let analytics = {
                pushToTawq: this.userData.pushToTawq ?? null,
                pickUpToTawq: this.userData.pushToTawq !== undefined && this.isMobile ? !this.userData.pushToTawq : null,
                isMobileDevice: this.isMobile,
                isIOS: this.isIOS,
                totalAudioTime: this.getTotalAudioTime(),
                stepOrder: this.newAudioStepEnum,
            };

            let success = await this.$store.dispatch('newContributionStore/saveContribution', { post: this.postData, isOwnersPost: this.isOwnersPost(), analytics: analytics });
            if (success) this.$store.commit('alertUser', { show: true, text: `Successfully saved your contribution.`, type: 'snackbar' });
            else this.$store.commit('alertUser', { show: true, text: `Something went wrong. Couldn't save your contribution.`, type: 'snackbar' });

            this.completed = success; // if this is not set to true here, in beforeDestroy it will delete the audio files. If failed success will be false and delete files from storage.
            this.loading = false; // remove saving contribution full screen overlay
            this.$router.go(-1);
            // this.$router.replace({ name: "view-post", query: { pid: this.postId, postType: this.postType, gId: this.groupId, post: this.post}}); // removed by Scott Brady on March 20, 2024 - This would cause a duplicate navigation and when pressing the back/previous webpage button it would navigate to the same page, forcing a user to have to press back/previous twice to return to expected page.
        },
        filterUndefinedValues: function () {
            return new Promise(async (resolve) => {
                for (const key in this.tawqData) {
                    const segment = this.tawqData[key];

                    if (typeof segment === 'undefined') {
                        this.$delete(this.tawqData, key);
                    } else {
                        this.ensureCaptionProperty(segment);
                        this.ensureAudioObjectProperties(key, segment);
                    }
                }
                resolve();
            });
        },
        ensureCaptionProperty: function (segment) {
            if (typeof segment.caption === 'undefined') segment.caption = "";
        },
        ensureAudioObjectProperties: function (key, segment) {
            const { audio, videoSeg } = segment;

            if (typeof audio === 'undefined') segment.audio = {};
            if (typeof audio !== 'undefined') this.ensureAudioProperty(key, audio, videoSeg);
        },
        ensureAudioProperty: function (key, audio, videoSeg) {
            if (typeof audio.audioDur === 'undefined' || audio.audioDur === null) audio.audioDur = (typeof videoSeg !== 'undefined') ? videoSeg.dt : 0;
            if (typeof audio.audioStart === 'undefined' || audio.audioStart === null) audio.audioStart = (typeof videoSeg !== 'undefined') ? Math.max(videoSeg.vStart - videoSeg.dt, 0) : 0;
            if (typeof audio.audioEnd === 'undefined' || audio.audioEnd === null) audio.audioEnd = (typeof videoSeg !== 'undefined') ? audio.audioStart + videoSeg.dt : 0;
            if (typeof audio.segment === 'undefined') audio.segment = parseInt(key);
            if (typeof audio.audioURL === 'undefined' || audio.audioURL === null) {
                const location = `audioSegments/${this.postData.postId}/${this.newAudioData.audioDataId}/${parseInt(key)}`;
                this.setAudioURL(audio, location);
            }
        },
        setAudioURL: function (audio, location) {
            firebase.storage.ref().child(location).getDownloadURL().then((url) => {
                audio.audioURL = url || "";
            }).catch((error) => {
                audio.audioURL = "";
                console.log("An error occurred when setting the audio segment URL. The error is: ", error);
                this.$store.commit('alertUser', { show: true, text: `Oops, Couldn't retrieve the URL for your audio. Error: ${error.message}`, type: 'snackbar' });
            });
        },
        getPostData: function() {
            let publicPostRef = firebase.db.collection("publicPosts").doc(this.postId);
            publicPostRef.get().then((doc) => {
                if (!doc.exists) {
                    this.$store.commit('alertUser', { show: true, text: `This post doesn't exist.`, type: 'snackbar' });
                    return;
                }

                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                this.$store.dispatch('saveCurrentPost', this.postData);
                this.previewVideo.src = this.postData.videoUrl;
                this.previewVideo.type = this.postData.videoType;
                this.newAudioData.postId = this.postData.postId;
                this.setAudioDataId();
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        getGroupPostData: function() {
            let groupPostRef = firebase.db.collection("groupPosts").doc(this.postId);
            groupPostRef.get().then((doc) => {
                if (!doc.exists) {
                    this.$store.commit('alertUser', { show: true, text: `This post doesn't exist.`, type: 'snackbar' });
                    return;
                }

                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                this.$store.dispatch('saveCurrentPost', this.postData);
                this.previewVideo.src = this.postData.videoUrl;
                this.previewVideo.type = this.postData.videoType;
                this.newAudioData.postId = this.postData.postId;

                let groupRef = firebase.db.collection("groups").doc(this.postData.groupId);
                groupRef.get().then((doc) => {
                    if (!doc.exists) {
                        this.$store.commit('alertUser', { show: true, text: `This group doesn't exist.`, type: 'snackbar' });
                        return;
                    }

                    if (typeof doc.data().languageSelected !== 'undefined') this.newAudioData.languageSelected = doc.data().languageSelected;
                    this.setAudioDataId();
                }).catch((err) => {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                });
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        setPost: function (timesCalled) {
            if (timesCalled > 5) { // base case: if we have tried this 5 times already, we fallback to getting the post info from firebase
                this.postType === "group" ? this.getGroupPostData() : this.getPostData();
                return;
            } else {
                this.$nextTick(() => {
                    if (this.currentPost.postId === this.postId) {
                        this.postData = this.currentPost;
                        this.newAudioData.postId = this.postId;
                        this.setAudioDataId();

                        if (this.postType === 'group') {
                            if (!this.isUserPartOfGroup(this.userGroups) && !this.userData.admin) { // if it is a group video we need to check to see if the user is a part of the group
                                this.$store.commit('alertUser', { show: true, text: `Cannot Create Contribution. You Don't Belong To This Group.`, type: 'snackbar' })
                                this.$router.push({ name: 'view-post', query: { pid: this.postId, postType: this.postType } });
                                return;
                            }
                            // groupPost so set the languageSelected to the groupLanguageSelected for contributions or default to english if the group hasn't set a default language yet.
                            this.newAudioData.languageSelected = (typeof this.postData.groupLanguageSelected === 'undefined' ? this.newAudioData.languageSelected : this.postData.groupLanguageSelected);
                        }

                        this.previewVideo = {
                            src: this.postData.videoUrl,
                            type: this.postData.videoType,
                        };
                    } else {
                        this.setPost(timesCalled + 1); // recursively call this function to see if the store has updated
                    }
                });
            }
        },
        setAudioDataId: function() {
            let newAudioRef = firebase.db.collection(`audioSegments`).doc();
            this.newAudioData.audioDataId = newAudioRef.id;
            firebase.rtDb.ref(`${firebase.auth.currentUser.uid}/currentAudioDataId`).set({ audioDataId: newAudioRef.id});
            console.log("audioDataId: ", this.newAudioData.audioDataId);
        },
        isOwnersPost: function() {
            return this.newAudioData.userId === this.postData.userId ? true : false;
        },
        isUserPartOfGroup: function(groups) {
            return groups.some(group => group.groupId === this.postData.groupId);
        },
        getPermissions: async function () {
            if (DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === "function") {
                try {
                    const response = await DeviceOrientationEvent.requestPermission();
                    if (response === "granted") {
                        return true;
                    } else if (response === 'denied') {
                        this.$store.commit('alertUser', { show: true, text: `Device Orientation Permission Denied. Clear Web Data then Accept Permissions.`, type: 'snackbar' });
                        return false;
                    } else {
                        this.$store.commit('alertUser', { show: true, text: `Please allow device orientation detection to record your audio, or change turn 'Push to tawq' on in settings.`, type: 'snackbar' });
                        return false;
                    }
                } catch (error) {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Error: ${error.message}`, type: 'snackbar' });
                    return false;
                }
            } else {
                return true
            }
        },
        getTotalAudioTime: function() {
            return Object.values(this.tawqData).reduce((totalTime, segment) => totalTime + (segment.audio?.audioDur || 0), 0);
        },
        setCloudFunctionTimeout: function (segment, timeoutId) {
            this.cloudFunctionTimeoutIds.push({segment, timeoutId});
        },
        clearCloudFunctionTimeout: function (segment, clear) {
            const matchingObject = this.cloudFunctionTimeoutIds.find(obj => obj.segment === segment);
            if (matchingObject) {
                if (clear) this.clearCloudFunctionTimeoutById(matchingObject);

                const index = this.cloudFunctionTimeoutIds.indexOf(matchingObject);
                if (index !== -1) this.cloudFunctionTimeoutIds.splice(index, 1);
            }
        },
        clearCloudFunctionTimeoutById: function (matchingObject) {
            clearTimeout(matchingObject.timeoutId);
        },
        clearAllCloudFunctionTimeouts: function () {
            this.cloudFunctionTimeoutIds.forEach(obj => clearTimeout(obj.timeoutId));
            this.cloudFunctionTimeoutIds.length = 0;
        },
        clearRTDB: async function (userId) {
            const allRefs = firebase.rtDb.ref(userId);
            allRefs.off();
            await allRefs.remove();
        },
        deleteIncompleteAudioFilesFromStorage: function () { // delete the audio files for the incomplete contribution
            this.$store.dispatch( 'deleteIncompletePostAudioFiles', { postId: this.newAudioData.postId, audioDataId: this.newAudioData.audioDataId } );
        },
    },
    computed: {
        isMobile () {
            return this.$store.state.isMobileDevice;
        },
        isIOS () {
            return this.$store.state.isIOSDevice;
        },
        userData () {
            return this.$store.state.userData;
        },
        currentPost () {
            return this.$store.state.currentPost;
        },
        userGroups () {
            return this.$store.state.userGroups;
        },
        languageSelected: {
            get(){
                return this.$store.state.newContributionStore.newAudioData.languageSelected;
            },
            set(value){
                if (value != undefined) {
                    this.$store.commit('newContributionStore/saveNewContributionLanguageSelected', value);
                }
            }
        },
        newAudioData: {
            get(){
                return this.$store.state.newContributionStore.newAudioData;
            },
            set(value){
                this.$store.commit('newContributionStore/saveNewAudioData', value);
            }
        },
        tawqData () {
            return this.$store.state.newContributionStore.tawqData;
        },
        newAudioStepEnum () {
            return this.$store.state.newContributionStore.newAudioStep;
        },
        currentStep: {
            get() {
                return this.$store.state.newContributionStore.currentStep;
            },
            set(value){
                this.$store.commit('newContributionStore/saveNewContributionCurrentStep', value);
            }
        },
        contributionMetadataStep () {
            return this.$store.state.newContributionStore.contributionMetadataStep;
        },
        contributionCreationStep () {
            return this.$store.state.newContributionStore.contributionCreationStep;
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
        checkDeleteButtonValidity() { // disables 'Save as Draft' and 'Delete' buttons while recording and converting
            return !(this.hasCaptions === this.hasAudio && this.hasAudio === this.contributionCreationStep.complete);
        },
        checkButtonValidity () { // return false if can proceed, true if button should be disabled
            if ((this.currentStep === this.newAudioStepEnum.CONTENT_METADATA && this.contributionMetadataStep.complete) || (this.currentStep === this.newAudioStepEnum.CONTENT_CREATION && this.contributionCreationStep.complete)) return false;
            return true;
        },
    },
    beforeCreate () {
        this.$store.dispatch('newContributionStore/resetStoreState');
    },
    created: async function() {
        const windowWidth = window.innerWidth;
        this.titleLength = Math.floor(windowWidth / 25);
        this.regex = new RegExp(`^.{0,${this.titleLength}}`);


        await this.clearRTDB(firebase.auth.currentUser.uid);
        this.setPost(1);
        // The below if, else if and else, is used to set createPost.languageSelected to have the key value pairs of location, model, recognizerEnum and recognizer, which were created on July 29, 2024.
        // Users who set a default languageSelected in SettingsPage.vue, and have not updated it, after July 29, 2024 will not have the above values so we will find the corresponding values
        // in this.languageCodes by using userData.languageSelected.value (language code). If a user does not have languageSelected value in userData (which shouldn't happen) we default to English (United States) - "en-US"
        if (this.userData.languageSelected && typeof this.userData.languageSelected.recognizer === "undefined") this.languageSelected = this.$languageCodes.find(obj => obj['value'] === this.userData.languageSelected.value);
        else if (this.userData.languageSelected && typeof this.userData.languageSelected.recognizer !== "undefined") this.languageSelected = this.userData.languageSelected;
        else this.languageSelected = this.$languageCodes[0]; // Default: English (United States) - "en-US"
    },
    // mounted: function() {
    //     // Uncomment when ready to show help indicator again
    //     // setTimeout( () => {
    //     //     this.showHelpIndicator = false;
    //     // }, 4000);
    // },
    beforeDestroy: async function() {
        if (!this.completed && this.hasAudio) {
            // We need to delete all audio files from storage, because they save as they are recorded.
            // And in this case, the contribution was not completed, therefore the audioFiles will be
            // in storage but absolutely useless.
            await this.deleteIncompleteAudioFilesFromStorage();
        }
        this.clearRTDB(firebase.auth.currentUser.uid);
        this.clearAllCloudFunctionTimeouts();
    },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
