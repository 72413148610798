import axios from "axios";
import firebase from "../../firebaseConfig";
// import fb from "firebase/app";
// import Jimp from 'jimp-compact';
// import Filter from 'bad-words';
// import firebase from 'firebase/app'


// TODO: WRITE A FILTER UNDEFINED VALUES FUNCTION HERE....


const newPostStore = {
    state: () => ({
        newPostStep: {
            MEDIA_SELECTION: 1, // THIS STEP MUST ALWAYS COME BEFORE CONTENT_CREATION
            CONTENT_CREATION: 2,
            CONTENT_METADATA: 3,
        },
        mediaSelectionStep: {
            complete: false,
            fileSelected: false,
            youtubeUrlSelected: false,
        },
        contentCreationStep: {
            complete: false,
            videoDuration: 0,
        },
        contentMetadataStep: {
            completed: false,
        },
        postAnalytics: {
            startTimes: [], // a user may start it, save it as a draft, then restart it.
            endTimes: [],
            pushToTawq: null,
            pickUpToTawq: null,
            isMobileDevice: null,
            isIOS: null,
            totalAudioTime: 0,
            stepOrder: {},
            postType: null,
            dualDevice: null,
            total_contributions: 0,
        },
        createPost: {
            currentStep: 1,
            video: {},
            videoUrl: "", // videoUrl for Youtube or earlyUploadForVideoCodec
            thumbnail: null,
            // date: "",
            title: "",
            // filteredTitle: "",
            description: "",
            // filteredDescription: "",
            group: "",
            groupLanguage: null,
            tags: [],
            // filteredTags: [],
            type: null,
            transcoded: false,
            userId: "",
            username: "",
            userProfilePhoto: "",
            postId: "",
            audioDataId: "",
            category: null,
            languageSelected: {
              text: "English (United States)",
              value: "en-US",
              model: 0,
              location: 0,
              recognizerEnum: 0,
              recognizer: "gl-en-us-en-in-en-gb",
            },
            noCaptionLanguageSelected: "",
            videoLanguageSelected: "",
        },
        earlyUpload: { // used for earlyUploadForVideoCodec to show dialog processing and allow for clicking of next button in mediaSelectionStep
            processingVideo: false,
            videoCodecCompatible: null,
            videoCodecProcessingDialog: false,
            saveAsDraft: false,
            cancelAndDelete: false,
        },
        gcp_video_url: "https://storage.googleapis.com/tawq-in-create.appspot.com/videos",
        gcp_audio_url: "https://storage.googleapis.com/tawq-in-create.appspot.com/audios",
        gcp_gsutil_uri:"gs://tawq-in-create.appspot.com/videos", // This is used for Google Transcode API
        postMetaDataUploadComplete: false, // the whole post has been completely uploaded
        audioCaptionsUploadComplete: false, // set to true when audio Captions have been uploaded
        videoFileUploadComplete: false,
        uploading_video: false, // set true during the upload process
        resumable_progress: 0, // the progress for the video file being uploaded to storage
        isPublicPost: false,
        fbUser: firebase.auth.currentUser,
        tawqData: {}, // tawqData will have keys that are audioSegId's. each key will be an object containing 3 arrays - videoSegArray, audio, caption
        processVideoAttempts: 0,
        retrySaveVideoPostAttempts: 0,
    }),
    mutations: {
        saveFirebaseUser(state){
            state.createPost.userId = firebase.auth.currentUser.uid;
            state.createPost.username = firebase.auth.currentUser.displayName;
            state.createPost.userProfilePhoto = firebase.auth.currentUser.photoURL;
            state.fbUser = firebase.auth.currentUser;
            // console.log(user.uid);
            // state.createPost.userId = user.uid;
            // state.createPost.username = user.displayName,
            // state.createPost.userProfilePhoto = user.profilePhoto;
            // state.fbUser = user;
        },
        saveEarlyUploadVideoCodecCompatible(state, payload){
            state.earlyUpload.videoCodecCompatible = payload;
            if(payload === false) {
                // commit('saveEarlyUploadVideoCodecProcessingDialog', true);
                state.earlyUpload.videoCodecProcessingDialog = true;
            }
        },
        saveEarlyUploadVideoCodecProcessingDialog(state, payload){
            state.earlyUpload.videoCodecProcessingDialog = payload;
        },
        saveEarlyUploadSaveAsDraft(state, payload){
            state.earlyUpload.saveAsDraft = payload;
        },
        // saveEarlyUpload(state, payload){
        //     state.earlyUpload = Object.assign(state.earlyUpload, payload);
        // },
        saveVideoFileUploadComplete(state, payload){
            state.videoFileUploadComplete = payload;
        },
        saveUploadingVideo(state, payload){
            state.uploading_video = payload;
        },
        saveIsPostPublic(state, isPublic){
            state.isPublicPost = isPublic;
        },
        saveResumableProgress(state, progress){
            state.resumable_progress = progress;
        },
        saveCreatePostVideo(state, payload){
            state.createPost.video = payload;
        },
        saveCreatePostTranscoded(state, payload){
            state.createPost.transcoded = payload;
        },
        saveCreatePostVideoUrl(state, payload){
            state.createPost.videoUrl = payload;
        },
        saveCreatePostLanguageSelected(state, payload){
            state.createPost.languageSelected.text = payload.text;
            state.createPost.languageSelected.value = payload.value;
            state.createPost.languageSelected.model = payload.model;
            state.createPost.languageSelected.location = payload.location;
            state.createPost.languageSelected.recognizerEnum = payload.recognizerEnum;
            state.createPost.languageSelected.recognizer = payload.recognizer;
        },
        saveCreatePostNoCaptionLanguageSelected(state, payload){
            // console.log("noCaptionLanguageSelected: ", payload);
            state.createPost.noCaptionLanguageSelected = payload;
        },
        saveCreatePostVideoLanguageSelected(state, payload){
            // console.log("videoLanguageSelected: ", payload);
            state.createPost.videoLanguageSelected = payload;
        },
        saveCreatePostCurrentStep(state, payload){
            state.createPost.currentStep = payload;
        },
        saveCreatePost(state, payload){
            state.createPost = Object.assign(state.createPost, payload);
        },
        saveMediaSelectionStep(state, payload){
            state.mediaSelectionStep = payload;
        },
        saveContentMetadataStep(state, payload){
            state.contentMetadataStep = payload;
        },
        saveContentCreationStep(state, payload){
            state.contentCreationStep = Object.assign(state.contentCreationStep, payload);
        },
        saveVideoSegArray(state,payload){
            state.contentCreationStep.videoSegArray = payload;
        },
        saveVideoDuration(state, payload){
            state.contentCreationStep.videoDuration = payload;
        },
        saveNewPostAnalytics(state, payload){
            state.postAnalytics = Object.assign(state.postAnalytics, payload);
        },
        saveTawqData(state, payload){
            // console.log("the payload in saveTawqData: ", payload);
            // state.tawqData = Object.assign({}, state.tawqData, { [payload.key]: payload.value } );
            state.tawqData = Object.assign({}, state.tawqData, payload );
        },
        deleteKeyFromTawqData(state, payload){
            // console.log("payload key: ", payload.key);
            delete state.tawqData[payload.key];
        },
        resetStoreState(state){
            state.postMetaDataUploadComplete = false;
            state.audioCaptionsUploadComplete = false;
            state.videoFileUploadComplete = false;
            state.uploading_video = false;
            state.resumable_progress = 0;
            state.isPublicPost = false;
            state.fbUser = firebase.auth.currentUser;
            state.processVideoAttempts = 0;
            state.retrySaveVideoPostAttempts = 0;
            state.createPost = {
                currentStep: 1,
                video: {},
                videoUrl: "", // videoUrl for Youtube
                thumbnail: null,
                // date: "",
                title: "",
                // filteredTitle: "",
                description: "",
                // filteredDescription: "",
                group: "",
                groupLanguage: null,
                tags: [],
                // filteredTags: [],
                type: null,
                transcoded: false,
                userId: "",
                username: "",
                userProfilePhoto: "",
                postId: "",
                audioDataId: "",
                category: null,
                languageSelected: {
                    text: "English (United States)",
                    value: "en-US",
                    model: 0,
                    location: 0,
                    recognizerEnum: 0,
                    recognizer: "gl-en-us-en-in-en-gb",
                },
                noCaptionLanguageSelected: "",
                videoLanguageSelected: "",
            };
            state.earlyUpload = {
                processingVideo: false,
                videoCodecCompatible: null,
                videoCodecProcessingDialog: false,
                saveAsDraft: false,
                cancelAndDelete: false,
            },
            state.mediaSelectionStep = {
                complete: false,
                fileSelected: false,
                youtubeUrlSelected: false,
            };
            state.contentCreationStep = {
                complete: false,
                videoDuration: 0,
            };
            state.contentMetadataStep = {
                completed: false,
            };
            state.postAnalytics = {
                startTimes: [], // a user may start it, save it as a draft, then restart it.
                endTimes: [],
                pushToTawq: null,
                pickUpToTawq: null,
                isMobileDevice: null,
                isIOS: null,
                totalAudioTime: null,
                stepOrder: {},
                postType: null,
                total_contributions: 0,
            };
            state.tawqData = {};
        },
    },
    actions: {
        setFirebaseUser({commit}, user) {
            commit('saveFirebaseUser', user);
        },
        setIsPostPublic({commit}, isPublic){
            commit('saveIsPostPublic', isPublic);
        },
        // filterUndefinedValues({state, dispatch}, payload){
        //     // TODO: WRITE THIS ** MAYBE EVEN WRITE THIS IN THE PCC COMPONENT SO WE CAN USE THOSE FUNCTIONS THE NECESSARY FUNCTIONS TO FIX THE UNDEFINED VALUES.
        //     if(Object.keys(payload).length > 0){
        //         for(var key in payload){
        //             dispatch('filterUndefinedValues', payload[key]);
        //         }
        //     }
        //     else {
        //         if (typeof payload === 'undefined'){
        //             // find out which property, then fix it...
        //         }
        //     }
        //     return;
        // },
        async earlyUploadForVideoCodec ({state, commit, dispatch}, payload) {
            state.earlyUpload.processingVideo = true;
            state.uploading_video = true; // uncomment this to show on the Appbar that the video is being uploaded and to show progress
            // We may also be able to put state.uploading_video into NewPost.vue so if the user decides to exit out of creating a post while
            // we are uploading/processing their video it will still show them the progress. we shouldn't need to worry about it until they exit!
            // This will get the resumable_uri for the next axios function to properly handle uploading the video

            // dispatch('createTranscodingJob', {inputUri: `${state.gcp_gsutil_uri}/${payload.post.userId}/${payload.post.postId}/${payload.post.video.name}`, outputUri: `${state.gcp_gsutil_uri}/${payload.post.userId}/${payload.post.postId}/transcode/`});

            var resumableResponse = await axios.get('resumable', {
                headers: {
                    userId: payload.post.userId,
                    postId: payload.post.postId,
                    filename: payload.post.video.name,
                    fileSize: payload.post.video.size,
                    filetype: 'video/mp4',
                }
            });

            var uri = resumableResponse.data;
            let file = payload.post.video;
            let loaded = 0;
            let chunkSize = 7680000; // = 7680KB || 7.68MB || TODO: get more efficient here
            let total = file.size;
            let reader = new FileReader();
            var slice = file.slice(0, chunkSize);
            // reader.readAsBinaryString(slice);
            reader.readAsArrayBuffer(slice);
            reader.onload = async () => {
                let contentRange;
                if (loaded + chunkSize - 1 > file.size) {
                    contentRange = `bytes ${loaded}-${file.size - 1}/${file.size}`;
                } else {
                    contentRange = `bytes ${loaded}-${loaded + chunkSize - 1}/${file.size}`;
                }
                let headers = {
                    "Content-Range": contentRange, // the bytes we are uploading in state request
                    "Content-Type": file.type,
                };
                var res = await axios({url: uri, method: 'put', data: slice, headers: headers});
                if (res.status == 200 || res.status == 201) {
                    reader = null; // allow the reader to be garbage collected;
                    if (!state.earlyUpload.saveAsDraft){// if saveAsDraft is false then they are still on the NewPost page so show progress
                        var seconds = 0;
                        var interval = setInterval(()=>{
                            if (state.resumable_progress < 100 && !state.earlyUpload.cancelAndDelete){
                                commit('saveResumableProgress', state.resumable_progress + seconds);
                                seconds+=10;
                            } else {
                                clearInterval(interval);
                                state.videoFileUploadComplete = true;
                                state.uploading_video = false;
                            }
                        }, 300);
                    } else { // saveAsDraft is true so we don't need to show any progress to the user
                        commit('saveResumableProgress', 100);
                        state.videoFileUploadComplete = true;
                    }
                    // Successfully uploaded the video to Google Cloud Storage!
                    // Create a Transcoding Job to have the file begin to transcode
                    dispatch('createTranscodingJob', {inputUri: `${state.gcp_gsutil_uri}/${payload.post.userId}/${payload.post.postId}/${payload.post.video.name}`, outputUri: `${state.gcp_gsutil_uri}/${payload.post.userId}/${payload.post.postId}/transcode/`});
                } else if (res.status == 500) {
                    state.videoFileUploadComplete = true;
                    // Setting the above value to true and NOT setting state.uploading_video && state.earlyUpload.processingVideo to false will put
                    // the ERROR message on the VideoCodecProcessingDialog, so when the user clicks 'Close' it will run the correct process.
                    console.log("500 response status, need to continue the upload once we have wifi")
                    console.log("interrupted upload. status = 500");
                } else if (res.status == 400) {
                    // Bad Request, the session URI has expired
                    state.videoFileUploadComplete = true;
                    // Setting the above value to true and NOT setting state.uploading_video && state.earlyUpload.processingVideo to false will put
                    // the ERROR message on the VideoCodecProcessingDialog, so when the user clicks 'Close' it will run the correct process.
                    console.log("Bad requests, get a new resumable uri");
                    // state.getResumableURI();
                } else if (res.status == 308) {
                    // file isn't complete, repeat steps
                    var range = parseInt(res.headers.range.split("-")[1]);
                    loaded = range;
                    commit('saveResumableProgress', Math.round((loaded / file.size) * 100));
                    if (loaded <= total) {
                        slice = file.slice(loaded, loaded + chunkSize);
                        // reader.readAsBinaryString(slice);
                        reader.readAsArrayBuffer(slice);
                    }
                } else if (
                    res.status == 408 ||
                    res.status == 502 ||
                    res.status == 503 ||
                    res.status == 504
                ) {
                    state.videoFileUploadComplete = true;
                    // Setting the above value to true and NOT setting state.uploading_video && state.earlyUpload.processingVideo to false will put
                    // the ERROR message on the VideoCodecProcessingDialog, so when the user clicks 'Close' it will run the correct process.
                    console.log("save the info to unfinished posts");
                    //TODO: save the info to unfinished posts
                } else if (res.status == 404) {
                    state.videoFileUploadComplete = true;
                    // Setting the above value to true and NOT setting state.uploading_video && state.earlyUpload.processingVideo to false will put
                    // the ERROR message on the VideoCodecProcessingDialog, so when the user clicks 'Close' it will run the correct process.
                    console.log("Something Went Wrong. Error Status = 404 (Not Found). Try Again Later!");
                    //TODO: completely start the upload process over
                    // dispatch('saveVideoPost', payload); // maybe take this out...
                }
            }; // end of 'onload' event

        },
        async cancelResumableUpload ({state, dispatch}) {
            console.log("cancelResumableUpload");
            state.earlyUpload.cancelAndDelete = true;
            if (state.resumable_progress > 0) {
                console.log("video is already uploaded, delete from database");
            } else {
                console.log("video is still being uploaded");
            }
        },
        async deleteTranscodingJob ({state, dispatch}) {
            console.log("deleteTranscodingJob");
            state.earlyUpload.cancelAndDelete = true;
        },
        async createTranscodingJob ({state, commit, dispatch}, payload) {
            let data = {
                inputUri: payload.inputUri,
                outputUri: payload.outputUri,
            }
            let job = await axios.put('createTranscodingJob', {data: data,});
            if (job.status === 200){
                var lastIndex = job.data.lastIndexOf("/");
                // if successful then set an interval function (or some type of while statement) that calls getTranscodingJob
                // on the server and returns a response of whether the transcoding job is finished or not
                // when value comes back as successfully transcoded set state.earlyUpload.processingVideo = false;
                dispatch('getTranscodingJob', {jobId: job.data.substr(lastIndex + 1)});

                // if (!state.earlyUpload.saveAsDraft){
                //     var lastIndex = job.data.lastIndexOf("/");
                //     // if successful then set an interval function (or some type of while statement) that calls getTranscodingJob
                //     // on the server and returns a response of whether the transcoding job is finished or not
                //     // when value comes back as successfully transcoded set state.earlyUpload.processingVideo = false;
                //     dispatch('getTranscodingJob', {jobId: job.data.substr(lastIndex + 1)});
                // } else {
                //     dispatch('transcodingComplete');// This action should only be called when the video has fully finished transcoding and is ready to be used
                // }
            } else {
                // Setting the below values to true will put the ERROR message on the VideoCodecProcessingDialog, so when the user clicks 'Close' it will run the correct process.
                state.videoFileUploadComplete = true;
                state.earlyUpload.processingVideo = true;
                state.uploading_video = true;
            }
        },
        async getTranscodingJob({state,commit, dispatch}, payload) {
            let data = {
                jobId: payload.jobId,
            }
            var interval;
            let jobState = await axios.put('getTranscodingJob', {data: data,});
            interval = setInterval(async()=>{
                if (jobState.data != 'SUCCEEDED' /*|| jobState === 200*/){
                    jobState = await axios.put('getTranscodingJob', {data: data,});
                } else {
                    clearInterval(interval);
                    dispatch('transcodingComplete');// This action should only be called when the video has fully finished transcoding and is ready to be used
                }
            }, 1000);// how long do we wanna wait before calling server again to get job status?!?!
        },
        async transcodingComplete ({state, dispatch}) {

            await axios.delete(`/unusableVideoFile/${state.createPost.userId}/${state.createPost.postId}/${state.createPost.video.name}`);

            let url = `${state.gcp_video_url}/${state.createPost.userId}/${state.createPost.postId}/transcode/hd.mp4`;
            state.createPost.videoUrl = url
            state.createPost.transcoded = true; // Setting this value to true will allow us to know in all the NewPost components that the video has been transcoded
            state.earlyUpload.videoCodecCompatible = true; // Setting the value to true will allow the Next button in NewPost to be clickable
            // The following two values will close the VideoCodecProcessingDialog without issues
            state.earlyUpload.processingVideo = false;
            state.earlyUpload.videoCodecProcessingDialog = false;

            // Once the old unusable video file is deleted we should have all the correct info saved to state.createPost and can check if earlyUploadSaveAsDraft needs to be called.
            if (state.earlyUpload.saveAsDraft) {
                // console.log("Should be on the home page waiting for upload to complete");
                // console.log("Saving As a Transcoded Draft Post");
                // Since we want the process of uploading and transcoding the video to continue, we wait until now to check the status of the earlyUpload.saveAsDraft
                // value. Since it is true and at this point the video file should already be uploaded and transcoded we can call a function now to save the info to
                // draftPosts in the firebase database. We should already have all the info we need in the store so we don't need to pass any data to the dispatched function
                dispatch('earlyUploadSaveAsDraft');
            }


            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // TECHNICALLY IT IS NOT NECESSARY TO DELETE THE 'UNUSABLEVIDEOFILE' IT WILL JUST TAKE UP SPACE IN GOOGLE CLOUD STORAGE. //
            //              FOR NOW IF THE FILE DOES NOT DELETE PROPERLY WE WILL JUST MOVE ON AND NOT WORRY ABOUT IT.               //
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            // let unusableVideoFileResponse = await axios.delete(`/unusableVideoFile/${state.createPost.userId}/${state.createPost.postId}/${state.createPost.video.name}`);
            // if (unusableVideoFileResponse.status === 200) {
            //     let url = `${state.gcp_video_url}/${state.createPost.userId}/${state.createPost.postId}/transcode/hd.mp4`;
            //     state.createPost.videoUrl = url
            //     state.createPost.transcoded = true; // Setting this value to true will allow us to know in all the NewPost components that the video has been transcoded
            //     // When we set compatibile to true we have to make sure the video receives the URL of the newly transcoded video
            //     state.earlyUpload.videoCodecCompatible = true; // Setting the value to true will allow the Next button in NewPost to be clickable
            //     // The following two values will close the VideoCodecProcessingDialog
            //     state.earlyUpload.processingVideo = false;
            //     state.earlyUpload.videoCodecProcessingDialog = false;
            // } else {
            //     // console.error("Something went wrong and we can't continue the process. Please exit out of Creation and  try again!");
            //     // state.earlyUpload.processingVideo = true;
            //     // state.videoFileUploadComplete = true;
            //     // state.uploading_video = true;
            //     let url = `${state.gcp_video_url}/${state.createPost.userId}/${state.createPost.postId}/transcode/hd.mp4`;
            //     state.createPost.videoUrl = url
            //     state.createPost.transcoded = true; // Setting this value to true will allow us to know in all the NewPost components that the video has been transcoded
            //     // When we set compatibile to true we have to make sure the video receives the URL of the newly transcoded video
            //     state.earlyUpload.videoCodecCompatible = true; // Setting the value to true will allow the Next button in NewPost to be clickable
            //     // The following two values will close the VideoCodecProcessingDialog
            //     state.earlyUpload.processingVideo = false;
            //     state.earlyUpload.videoCodecProcessingDialog = false;
            // }
        },
        async saveVideoPost({state, dispatch}, payload) {
            state.uploading_video = true;
            if (state.createPost.transcoded) { // video has been transcoded, so it is already uploaded. Immediatly move to setPostData
                dispatch("setPostData",  payload);
            } else { // video was the correct codec so we save as 'normal', which means we save the video/audio to cloud storage here instead of early upload
                try {
                    let uploadPayload = {
                        uri: (payload.retryUploadPayload?.uri) || await dispatch("getResumableURI"),
                        loaded: (payload.retryUploadPayload?.loaded) || 0,
                    };
                    let uploadResponse = await dispatch("uploadFileToURI", uploadPayload);
                    if (uploadResponse) dispatch('setPostData',  payload);
                } catch (error) {
                    console.log("***ERROR***\nError Code: ", error.code, "\nError Message: ", error.message);
                    if (error.retry && state.retrySaveVideoPostAttempts <= 5) { // retry is true and we have only tried resume the upload 5 times or less, continue retrying until either mentioned case is false
                        let checkResumableUploadStatusResponse = await dispatch('checkResumableUploadStatus', error.retryPayload); // check to see if/where(byte) we should resume the upload
                        if (checkResumableUploadStatusResponse === false) { // something went wrong during the upload and we can't save the post
                            state.uploading_video = false;
                            return;
                        } else if (checkResumableUploadStatusResponse === true) { // upload completed successfully! Continue the saving of the post!
                            state.videoFileUploadComplete = true;
                            dispatch('setPostData',  payload );
                        } else { // upload was interrupted, but we have a new byte to resume upload at
                            let retryUploadPayload = {
                                uri: error.retryPayload.uri,
                                loaded: checkResumableUploadStatusResponse, // the byte where we want to resume the upload from
                            };
                            let retrySaveVideoPostPayload = { ... payload, retryUploadPayload };
                            dispatch("saveVideoPost", retrySaveVideoPostPayload);
                        }
                        state.retrySaveVideoPostAttempts += 1;
                    } else { // either retry is false, or we have tried to resume upload more than 5 times, attempt to save as draft
                        dispatch('savePostAsDraft', {
                            post: state.createPost,
                            step: 3,
                            type: 'file',
                            tawqData: state.tawqData,
                            isDraft: false,
                            draftPost: null,
                        });
                    }
                }
            }
        },
        async getResumableURI({state}) {
            return new Promise(async (resolve) => {
                const response = await axios.get("resumable", {
                    headers: {
                        userId: state.createPost.userId,
                        postId: state.createPost.postId,
                        filename: state.createPost.video.name,
                        filesize: state.createPost.video.size,
                        filetype: state.createPost.video.type, // send the type so we can save to the correct folder in gcs (audios/ or videos/)
                    }
                });
                resolve(response.data);
            });
        },
        async uploadFileToURI({state, commit, dispatch}, payload) {
            return new Promise(async (resolve, reject) => { // April 30, 2024 - we should the sent values in resolve/reject to be the status codes, or some form of them, or even an error message with just the reject.
                const uri = payload.uri; // resumable uri - used to upload data to google cloud storage
                const file = state.createPost.video; // file selected by user to upload, can be video or audio
                const fileSize = file.size; // the total size of the above video or audio file
                const chunkSize = 7680000; // value in bytes - 7680KB || 7.68MB
                let loaded = payload.loaded; // the amount of data that has been uploaded to the resumable uri. This number will increase each time reader.onload is called, see case 308 below.
                let reader = new FileReader();
                var slice = file.slice(loaded/*will always be 0 (zero) here*/, chunkSize); // starting chunk/slice of data that will be sent and uploaded to resumable uri
                reader.readAsArrayBuffer(slice);

                let error = {
                    code: 0,
                    message: "",
                    retry: false,
                    retryPayload: {
                        uri: uri,
                        contentLength: 0, // chunkSize - documentation says send 0 (zero), but might need to change to chunkSize value in future.
                        contentRange: `bytes */${fileSize}`,
                    },
                };

                reader.onload = async () => {
                    let contentRange = (loaded + chunkSize - 1 > fileSize) ? `bytes ${loaded}-${fileSize - 1}/${fileSize}` : `bytes ${loaded}-${loaded + chunkSize - 1}/${fileSize}`;
                    let headers = {
                        "Content-Range": contentRange, // the bytes we are uploading in axios uri put request
                        "Content-Type": file.type, // the type of file, i.e. video/mp4 or audio/mp4
                    };
                    let response = await axios({url: uri, method: 'put', data: slice, headers: headers});
                    switch (response.status) {
                        case 200:
                        case 201: // file was successfully uploaded to google cloud storage
                            reader = null; // allow the reader to be garbage collected;
                            const simulateProgressPayload = {
                                progress: state.resumable_progress,
                                interval: 500,
                            };
                            await dispatch("simulateProgress", simulateProgressPayload); // update to show user that upload is complete
                            // commit('saveResumableProgress', 100); // update to show user that upload is complete
                            state.videoFileUploadComplete = true;
                            resolve(true);
                            break;
                        case 308: // file upload was incomplete, resume from last byte uploaded
                            loaded = parseInt(response.headers.range.split("-")[1]); // new value of data that has been uploaded to resumable uri, used to determine which byte of data we will start at for next reader.onload call
                            commit('saveResumableProgress', Math.round((loaded / fileSize) * 100)); // update to show to user the progress of their upload
                            slice = file.slice(loaded, loaded + chunkSize); // the next chunk/slice of data that will be uploaded to resumable uri
                            reader.readAsArrayBuffer(slice); // allows for reader.onload to be called again for next slice upload
                            break;
                        case 408:
                        case 500:
                        case 502:
                        case 503:
                        case 504: // file upload was interrupted, resend interrupted chunk or resume from where it left off -> https://cloud.google.com/storage/docs/performing-resumable-uploads
                            error.code = response.status;
                            error.message = "File upload was interrupted.";
                            error.retry = true;
                            reject(error);
                            break;
                        case 400:
                        case 404:
                        case 410: // file upload failed or session uri no longer available, either notify user of failure or get new uri and retry upload
                            // return error and retry upload in catch statement of saveVideoPost or instead savePostAsDraft in the before mentioned catch statement
                            error.code = response.status;
                            error.message = "File failed to upload.";
                            reject(error);
                            break;
                        default: // something went wrong during upload
                            error.code = response.status;
                            error.message = "Something went wrong uploading file.";
                            reject(error);
                            break;
                    }
                }; // end of 'onload' event
            });
        },
        async checkResumableUploadStatus({}, payload) {
            return new Promise(async (resolve) => {
                let headers = {
                    // 'Content-Length': payload.contentLength, // removed by Scott Brady on May 8, 2024 - this was done because this error: 'Refused to set unsafe header "Content-Length"' was being thrown, axios should now automatically set this value
                    'Content-Range': payload.contentRange,
                };
                let response = await axios({url: payload.uri, method: 'put', headers: headers});
                switch (response.status) {
                    case 200:
                    case 201: // upload has completed. We should not get this response status because we are only sending this request knowing that the upload was interrupted, but we will keep it here as a double check
                        resolve(true);
                        break;
                    case 308: // upload incomplete. This is the expected response status.
                        // if status code is 308, then we check to see if response also has any range headers. If no range header, we start file upload from beginning. If yes range header, we return this value to resume upload from that byte.
                        let startResumableUploadAtByte = (response.headers.range) ? parseInt(response.headers.range.split("-")[1]) : 0; // if it exists, send response.headers.range, else send 0 (zero)
                        resolve(startResumableUploadAtByte);
                        break;
                    default: // this case should never happen and if it does, then something went wrong.
                        resolve(false);
                        break;
                }
            });
        },
        async setPostData ({state, dispatch}, payload) {
            let imageUrl = "";
            if (state.createPost.thumbnail != null) { // there is a new thumbnail that needs to be uploaded
                if (payload.isDraft && payload.draftPost.thumbnail != null && payload.draftPost.thumbnail != "") { // a thumbnail already exists on the draft post, delete it
                    await axios.delete(`thumbnails/${firebase.auth.currentUser.uid}/${payload.draftPost.postId}`).catch((error) => console.log("error deleting the thumbnail already associated with the post", error.message))
                }
                imageUrl = await dispatch('uploadThumbnail');
            } else if (state.createPost.thumbnail == null && payload.isDraft) { // it's a draft post and there is no new thumbnail to upload, use draft post thumbnail
                imageUrl = payload.draftPost.thumbnail;
            } // else, do nothing and image url will remain null


            if (!state.createPost.transcoded) { // if the video has NOT been transcoded then we need to set the url
                const type = state.createPost.video.type.includes('audio') ? 'audio' : 'video';
                state.createPost.videoUrl = `${state[`gcp_${type}_url`]}/${state.createPost.userId}/${state.createPost.postId}/${state.createPost.video.name}`;
            }

            let newPostRef = firebase.db.collection( state.isPublicPost ? "publicPosts" : "groupPosts").doc(state.createPost.postId);
            newPostRef.set({
                mediaType:  state.createPost.video.type.includes('audio') ? "audio" : "video",
                videoLanguageSelected: state.createPost.videoLanguageSelected,
                noCaptionLanguageSelected: state.createPost.noCaptionLanguageSelected,
                languageName: state.createPost.languageSelected.text,
                description: state.createPost.description,
                postDate: new Date(),
                tags: state.createPost.tags,
                thumbnail: imageUrl,
                category: state.createPost.category,
                title: state.createPost.title,
                userId: state.createPost.userId,
                postId: state.createPost.postId,
                videoUrl: state.createPost.videoUrl,
                videoType: state.createPost.video.type.includes('audio') ? 'audio/mp4' : state.createPost.video.type,
                postAudioDataId: state.createPost.audioDataId,
                username: state.createPost.username,
                userProfilePhoto: state.createPost.userProfilePhoto,
                postType: state.createPost.type,
                transcoded: state.createPost.transcoded,
                groupId: state.createPost.group.groupId ? state.createPost.group.groupId : null,
                groupName: state.createPost.group.groupName ? state.createPost.group.groupName : null,
                groupThumbnail: state.createPost.group.groupThumbnail ? state.createPost.group.groupThumbnail : null,
                postAnalytics: state.postAnalytics,
            });

            let audioDataIds = [state.createPost.audioDataId];
            newPostRef.collection("contributions").doc(state.createPost.userId).set({audioDataId: audioDataIds,});

            state.postMetaDataUploadComplete = true;
            dispatch('setAudioCaptionData');
        },

        async saveYoutubePost ({state, /*commit,*/ dispatch}, payload) {
            state.uploading_video = true;

            const simulateProgressPayload = {
                progress: state.resumable_progress,
                interval: 500,
            };
            await dispatch("simulateProgress", simulateProgressPayload); // update to show user that upload is complete
            // commit('saveResumableProgress', 100); // update to show user that upload is complete
            state.videoFileUploadComplete = true;

            let imageUrl = "";
            if (state.createPost.thumbnail != null) { // there is a new thumbnail that needs to be uploaded
                if ((payload.draftPost.thumbnail != null && payload.draftPost.thumbnail != "") || payload.deleteDraftPostThumbnail) { // a thumbnail already exists on the draft post, delete it
                    await axios.delete(`thumbnails/${firebase.auth.currentUser.uid}/${payload.draftPost.postId}`).catch((error) => console.log("error deleting the thumbnail already associated with the post", error.message))
                }
                imageUrl = await dispatch('uploadThumbnail');
            } else if (state.createPost.thumbnail == null && payload.isDraft) { // it's a draft post and there is no new thumbnail to upload, use draft post thumbnail
                if (payload.deleteDraftPostThumbnail) { // is a youtube url and already had a thumbnail saved/associated with it but was removed to use default youtube thumbnail
                    await axios.delete(`thumbnails/${firebase.auth.currentUser.uid}/${payload.draftPost.postId}`).catch((error) => console.log("error deleting the thumbnail already associated with the post", error.message))
                } else {
                    imageUrl = payload.draftPost.thumbnail;
                }
            } // else, do nothing and image url will remain null

            let newPostRef = firebase.db.collection( state.isPublicPost ? "publicPosts" : "groupPosts").doc(state.createPost.postId);
            newPostRef.set({
                mediaType: "video",
                videoLanguageSelected: state.createPost.videoLanguageSelected,
                noCaptionLanguageSelected: state.createPost.noCaptionLanguageSelected,
                languageName: state.createPost.languageSelected.text,
                description: state.createPost.description,
                postDate: new Date(),
                tags: state.createPost.tags,
                thumbnail: imageUrl,
                category: state.createPost.category,
                title: state.createPost.title,
                userId: state.createPost.userId,
                postId: state.createPost.postId,
                videoUrl: state.createPost.videoUrl,
                videoType: "video/youtube",
                postAudioDataId: state.createPost.audioDataId,
                userProfilePhoto: state.createPost.userProfilePhoto,
                username: state.createPost.username,
                postType: state.createPost.type,
                transcoded: state.createPost.transcoded,
                groupId: state.createPost.group.groupId ? state.createPost.group.groupId : null,
                groupName: state.createPost.group.groupName ? state.createPost.group.groupName : null,
                groupThumbnail: state.createPost.group.groupThumbnail ? state.createPost.group.groupThumbnail : null,
                postAnalytics: state.postAnalytics,
            });

            let audioDataIds = [state.createPost.audioDataId];
            newPostRef.collection("contributions").doc(state.createPost.userId).set({audioDataId: audioDataIds,});

            state.postMetaDataUploadComplete = true;
            dispatch('setAudioCaptionData');
        },

        simulateProgress ({commit, dispatch}, payload) {
            const { progress, interval } = payload;

            if (progress >= 100) return; // exit function when progress has reached 100

            let randomValue = Math.floor(Math.random() * (21 - 10) + 10); // get random value between 10 and 20 inclusive
            const newProgress = Math.min(progress + randomValue, 100); // make sure newProgress doesn't exceed 100
            commit('saveResumableProgress', newProgress); // update state value of resumable_progress to display to the user

            setTimeout(() => {
                const simulateProgressPayload = {
                    progress: newProgress,
                    interval: interval,
                };
                dispatch("simulateProgress", simulateProgressPayload); // recursively call simulateProgress with the new progress
            }, interval);
        },
        async setAudioCaptionData ({state, dispatch}) {
            const { audio, captions } = await dispatch('bubbleSortByAudioStartTime');

            if (audio.length === 0) { // this should never happen because we require a user to add at least one audio/caption while creating
                state.audioCaptionsUploadComplete = true;
                dispatch('processVideo');
                return;
            }

            await firebase.db.collection("audioSegments").doc(state.createPost.audioDataId).set({
                noCaptionLanguageSelected: state.createPost.noCaptionLanguageSelected,
                languageName: state.createPost.languageSelected.text,
                languageCode: state.createPost.languageSelected.value,
                title: state.createPost.title,
                userId: firebase.auth.currentUser.uid,
                username: state.createPost.username,
                userProfilePhoto: firebase.auth.currentUser.photoURL,
                postId: state.createPost.postId,
                audio: audio,
                subtitles: captions,
                date: new Date(),
            }, { merge: true });

            state.audioCaptionsUploadComplete = true;
            dispatch('processVideo'); // because setAudioCaptionData is called last, it should be the last thing to finish
        },
        async uploadThumbnail({state}){
            const formData = new FormData();
            formData.append("file", state.createPost.thumbnail);
            formData.append("postId", state.createPost.postId);
            try {
                const response = await axios.post("/uploadpic", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                // console.log("Thumbnail uploaded successfully:", response.data);
                return response.data.url;
            } catch (error) {
                console.error("Error uploading thumbnail:", error);
                return null;
            }
        },
        bubbleSortByAudioStartTime({state}){
            return new Promise((resolve) => {
                const data = Object.values(state.tawqData).map((x, index) => {
                    const audioObj = typeof x.audio !== 'undefined' ? x.audio : {
                        audioURL: null,
                        audioStart: null,
                        audioDur: null,
                        segment: index + 1,
                    };

                    const captionObj = {
                        subtitle: typeof x.caption !== 'undefined' ? x.caption : "",
                        audioSegId: index + 1,
                    };

                    return { audio: audioObj, caption: captionObj };
                });

                data.sort((a, b) => a.audio.audioStart - b.audio.audioStart); // Sort the data array based on audioStart property

                const sortedAudio = data.map(obj => obj.audio);
                const sortedCaptions = data.map(obj => obj.caption);

                resolve({ audio: sortedAudio, captions: sortedCaptions });
            });
        },
        async processVideo({state, dispatch}){
            if (state.audioCaptionsUploadComplete && state.postMetaDataUploadComplete && state.videoFileUploadComplete) {
                if (state.createPost.hasDraft) await dispatch('deleteDraftPostMetaData', state.createPost.postId);
                setTimeout(() => {
                    dispatch('resetStoreState');
                }, 5000);
            } else { // if we hit this else statement then something went wrong in setting the above values
                console.error("Processing didn't work, saving post as draft.");
                dispatch('savePostAsDraft', {
                    post: state.createPost,
                    step: 3,
                    type: state.createPost.videoType === 'video/youtube' ? 'youtube' : 'file',
                    tawqData: state.tawqData,
                    isDraft: false,
                    draftPost: null,
                });
            }
        },

        deleteDraftPostMetaData({state},payload){
            return new Promise((resolve, reject) => {
                if (state.createPost.postId != payload) return; // create post and draft post don't have the same ids so we won't delete
                firebase.db.collection('drafts').doc(payload).delete().then(() => {
                    resolve();
                }).catch((err) => {
                    console.log("error deleting draft post", err.message);
                    reject();
                });
            });
        },
        async deleteUploadedAndTranscodedVideoFiles({}, payload){
            var response = await axios.delete(`/videos/${payload.post.userId}/${payload.post.postId}`); // send delete request to server
            if (response.status != 200) console.error("Error something went wrong deleting video files from storage");
        },
        async deleteIncompletePostAudioFiles({dispatch}, payload){ // below will delete the audioFiles for an incomplete post
            let audioDataRef = firebase.storage.ref(`audioSegments/${payload.postId}/${payload.audioDataId}`);

            let audioDataResponse = await audioDataRef.list(); // return a list of files (items) in the storage reference
            for (const itemRef of audioDataResponse.items) await audioDataRef.child(itemRef.name).delete(); // loops through each file (item) and deletes it
            dispatch('resetStoreState'); // reset the store state to default values
        },
        async deleteIncompletePostFromStorage({dispatch}, payload){ // this method is ran only if it is a draft post and we need to delete the draft post meta data from the DB. Delete from store is referring to deleting from the local store
            dispatch('deletePostFromDb', { post: payload.post, isDraft: payload.isDraft, index: null, deleteFromStore: false });
            dispatch('resetStoreState');
        },

        async earlyUploadSaveAsDraft ({state, dispatch}) {
            // This function is only called when a user is uploading and transcoding an useable video.
            // it will only need to save what the current state.createPost is and the correct state.createPost.videoUrl
            // console.log("earlyUploadSaveAsDraft NewPostStore");

            // Set post values to everything we need in drafts to load correct data when reopening and continuing to create
            let post = {
                ... state.createPost
            };
            post.postAnalytics = state.postAnalytics;
            post.postDate = new Date();
            post.videoType = 'video/mp4';
            post.draftVideo = {
                name: state.createPost.video.name,
                size: state.createPost.video.size,
                type: state.createPost.video.type,
            };
            post.tawqData = state.tawqData;
            delete post.type;
            delete post.video;

            // Save post to drafts in Firebase Database
            const draftRef = firebase.db.collection("drafts").doc(state.createPost.postId);
            await draftRef.set(post).catch((err) => console.log("Error saving draft", err));

            // reset store back to normal when done saving as a draft.
            dispatch('resetStoreState');
        },

        async savePostAsDraft ({state, dispatch}, payload) {
            let post = await dispatch("setPostDataForDraft", payload);

            const draftRef = firebase.db.collection("drafts").doc(state.createPost.postId);
            try {
                await draftRef.set(post);
                // const simulateProgressPayload = {
                //     progress: state.resumable_progress,
                //     interval: 500,
                // };
                // await dispatch("simulateProgress", simulateProgressPayload); // update to show user that upload is complete
                dispatch('resetStoreState');
                // dispatch('alertUser', { show: true, text: 'Post successfully saved as draft', type: 'snackbar' });
            } catch (error) {
                // console.log("Error saving draft", error);
                if ( state.processVideoAttempts >= 0 && state.processVideoAttempts <= 5) { // if we have tried processing already
                    // console.log("retrying savePostAsDraft");
                    dispatch('savePostAsDraft', {
                        post: state.createPost,
                        type: payload.type,
                        step: state.createPost.currentStep,
                        isDraft: payload.isDraft || false,
                        draftPost: payload.isDraft ? payload.draftPost : null,
                        deleteDraftPostThumbnail: payload.deleteDraftPostThumbnail || false,
                    });
                    // dispatch('deleteIncompletePostAudioFilesFromStorage', { isDraft: state.createPost.hasDraft, postId: state.createPost.postId, audioDataId: state.createPost.audioDataId });
                    state.processVideoAttempts += 1;
                } else {
                    console.log("Error saving draft", error);
                    console.log("Completely failed to savePostAsDraft");
                }
            }
        },
        async setPostDataForDraft({state, dispatch}, payload) {
            return new Promise(async (resolve) => {
                let post = {
                    ... state.createPost
                };
                post.postAnalytics = state.postAnalytics;
                post.postDate = new Date();
                if (payload.type === 'file') { // they are uploading their own vid
                    post.videoType = typeof state.createPost.video.type != 'undefined' ? (state.createPost.video.type.split('/')[0] === 'audio' ? 'audio/mp4' : 'video/mp4') : payload.draftPost.draftVideo.type;
                    if (typeof state.createPost.video.type != 'undefined') {
                        post.draftVideo = {
                            name: state.createPost.video.name,
                            size: state.createPost.video.size,
                            type: state.createPost.video.type,
                            videoDuration: state.contentCreationStep.videoDuration,
                        }
                    } else {
                        post.draftVideo = payload.draftPost.draftVideo;
                    }
                } else if (payload.type === 'youtube') { // it is a youtube video
                    post.videoType = 'video/youtube'
                    post.draftVideo = {
                        url: state.createPost.videoUrl,
                        type: 'video/youtube',
                        videoDuration: state.contentCreationStep.videoDuration,
                    }
                }
                // post.step = payload.step;
                post.postType = state.createPost.type;
                post.tawqData = state.tawqData;
                // post.mediaType = 'video';
                // post.mediaType = state.createPost.video.type.includes('audio') ? "audio" : "video",

                // Are the following 2 lines of code necessary? What happens if we don't delete them?
                delete post.type;
                delete post.video;

                let imageUrl = "";
                if (state.createPost.thumbnail != null) { // there is a new thumbnail that needs to be uploaded
                    if ((payload.draftPost.thumbnail != null && payload.draftPost.thumbnail != "") || payload.deleteDraftPostThumbnail) { // a thumbnail already exists on the draft post, delete it
                        await axios.delete(`thumbnails/${firebase.auth.currentUser.uid}/${payload.draftPost.postId}`).catch((error) => console.log("error deleting the thumbnail already associated with the post", error.message))
                    }
                    imageUrl = await dispatch('uploadThumbnail');
                } else if (state.createPost.thumbnail == null && payload.isDraft) { // it's a draft post and there is no new thumbnail to upload, use draft post thumbnail
                    if (payload.deleteDraftPostThumbnail) { // is a youtube url and already had a thumbnail saved/associated with it but was removed to use default youtube thumbnail
                        await axios.delete(`thumbnails/${firebase.auth.currentUser.uid}/${payload.draftPost.postId}`).catch((error) => console.log("error deleting the thumbnail already associated with the post", error.message))
                    } else {
                        imageUrl = payload.draftPost.thumbnail;
                    }
                } // else, do nothing and image url will remain null

                post.thumbnail = imageUrl;
                resolve(post);
            });
        },
        resetStoreState({commit}){
            /* this method will reset the data being stored for newPost */
            commit('resetStoreState');
        }
    }, // actions

    getters: {
        getOverlappingSegsForAudioSeg: (state) => (audioSegId) => {
            let overlappingSegs = [];
            for(const key in state.tawqData){
                if(typeof state.tawqData[key].audio === 'undefined' || state.tawqData[key].audio === null){
                    return overlappingSegs;
                }
                const audio = state.tawqData[audioSegId].audio;
                if ( (audio.segment != state.tawqData[key].audio.segment) // if it isn't the same audioSeg that this caption card is using 
                    && (((state.tawqData[key].audio.audioStart < audio.audioEnd) && (state.tawqData[key].audio.audioStart > audio.audioStart)) ||
                    ((state.tawqData[key].audio.audioEnd > audio.audioStart) && (state.tawqData[key].audio.audioStart < audio.audioStart)) ||
                    ((state.tawqData[key].audio.audioStart >= audio.audioStart) && (state.tawqData[key].audio.audioEnd >= audio.audioEnd) && (state.tawqData[key].audio.audioStart < audio.audioEnd) ) ||
                    ((state.tawqData[key].audio.audioStart == audio.audioStart) && (state.tawqData[key].audio.audioEnd <= audio.audioEnd))) )
                {
                    // if it overlaps, add it to the array
                    overlappingSegs.push( parseInt(key) );// this will add the INDEX number that the segment is overlapping
                }

            }
            return overlappingSegs;
        },
        getCaptionsForNewPost: (state) => {
            if(Object.keys(state.tawqData).length > 0){
               let captions = [];
               for (const key in state.tawqData){
                   if(typeof state.tawqData[key].caption != 'undefined' && state.tawqData[key].caption != null){
                       captions.push( {
                           subtitle: state.tawqData[key].caption,
                           audioSegId: key,
                       });
                   }
                   else if (typeof state.tawqData[key].caption != 'undefined'){
                    captions.push( {
                        // subtitle: state.tawqData[key].caption,
                        audioSegId: key,
                    });
                   }
               } 
               return captions;
            }
            return [];
        },
        getAudioForNewPost: (state) => {
            if(Object.keys(state.tawqData).length > 0){
               let audio = [];
               for (const key in state.tawqData){
                   if(typeof state.tawqData[key].audio != 'undefined' && state.tawqData[key].audio != null){
                       audio.push(
                            {
                                audioURL: state.tawqData[key].audio.audioURL,
                                audioStart: state.tawqData[key].audio.audioStart,
                                audioDur: state.tawqData[key].audio.audioDur,
                                segment: state.tawqData[key].audio.segment,
                            }
                        );
                   }
               } 
               return audio;
            }
            return [];
        }
    }
} // store

export default newPostStore