<template >
    <div class="px-2" style="overflow-x: hidden;">

        <v-row justify="center">
            <p class="text-md-h3 text-h4 font-weight-light">Help</p>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" xl="6" lg="8" md="9" sm="11" xs="12" >
                <div style="position: relative; padding-bottom: 56.25%; height: 0; max-width: 100%;">
                    <iframe :src="toWatchSrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; max-width: 100%; height: 100%;"></iframe>
                </div>
            </v-col>
            <v-btn class="align-self-end mb-4" text x-small @click="setNextVideo()">Next Video<v-icon class="ml-1" size="20">mdi-arrow-right</v-icon></v-btn>
        </v-row>

        <v-divider width="100%" class="my-8"></v-divider>

        <v-row class="justify-center justify-sm-start justify-xl-center">
            <v-col cols="12" xl="3" lg="4" md="4" sm="6" xs="12">
                <v-card class="pb-2" style="position: relative;">
                    <v-card-title>{{gettingStarted.topic}}</v-card-title>
                    <div style="position: absolute; top: 10px; right: 20px;"><v-icon size="40" class="primary--text">{{gettingStarted.icon}}</v-icon></div>
                    <v-list class="d-flex flex-column" rounded dense>
                        <div v-for="(item,i) in gettingStarted.subTopics" :key="i" class="px-4" >
                            <v-hover v-slot="{hover}">
                                <div style="cursor: pointer;" :class="{ 'text--lighten-1' : hover }" class="d-inline-block text-caption accent--text" @click="setCurrentVideo(i, 0)"><span class="mr-1">{{i+1}}.</span>{{item.title}}</div>
                            </v-hover>
                        </div>
                    </v-list>
                </v-card>
            </v-col>

             <v-col cols="12" xl="2" lg="3" md="4" sm="6" xs="12" v-for="(item,k) in topics" :key="k">
                <v-card class="pb-2" style="position: relative;">
                    <!-- <v-icon size="40" class="primary--text px-4 pt-1">{{item.icon}}</v-icon> -->
                    <v-card-title >{{item.topic}}</v-card-title>
                    <div style="position: absolute; top: 10px; right: 20px;"><v-icon size="40" class="primary--text">{{item.icon}}</v-icon></div>
                    <v-list class="d-flex flex-column" rounded dense>
                        <div v-for="(subItem,i) in item.subTopics" :key="i" class="px-4" >
                            <v-hover v-slot="{hover}">
                                <div style="cursor: pointer;" :class="{ 'text--lighten-1' : hover }" class="d-inline-block text-caption accent--text" @click="[toWatchSrc = subItem.url, setCurrentVideo(k,i)]"><span class="mr-1">{{i+1}}.</span>{{subItem.title}}</div>
                            </v-hover>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <v-divider width="100%" class="my-8"></v-divider>

        <v-row justify="center" align="center">
            <v-col cols="12" xl="7" lg="8" sm="11" xs="12" >
                <div class="text-center text-h6 mb-8">Walkthrough Tutorial</div>
                <VideoPlayer :options="vidOptions"></VideoPlayer>
            </v-col>
        </v-row>

        



        
    </div>
</template>
<script>
import VideoPlayer from "../components/VideoPlayer";
export default {
    name: 'help',
    metaInfo: {
        title: "Help - tawqin"
    },
    props: [ "loadTopic", "loadSubTopic" ],
    components: {
        VideoPlayer
    },
    data: function() {
        return {
            toWatchSrc: "https://www.loom.com/embed/ef34babade4b4290b90abcca970f3236?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true",
            videoPlaying: { topicIndex: 0, subTopicIndex: 0 }, // this var is set to keep track of which topic and subtopic is currently playing
            gettingStarted: {
                type: 'start',
                icon: 'mdi-power-standby',
                topic: "Getting Started",
                subTopics: [
                    { title: "Create a Post", type: "create" },
                    { title: "Groups", type: "group" },
                    { title: "Friends", type: "friend" },
                    { title: "Playback", type: "playback" },
                ],
            },
            topics: [
                {
                    type: 'create',
                    icon: 'mdi-folder-plus',
                    topic: "Create a Post",
                    subTopics: [
                        { title: "Name Post", url: "https://www.loom.com/embed/1fd42b867a834fa698aa21207670e452?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" },
                        { title: "Audience | Public vs. Group", url: "https://www.loom.com/embed/22381bee02244b8a80cf0a6832bea2a6?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" },
                        { title: "Record Your Voice", url: "https://www.loom.com/embed/ff29197963ff49d18d3a451efe94bce0?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" },
                        { title: "Post Review Process", url: "https://www.loom.com/embed/3d5685a36a694f378d31cf7deb92ec45?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" },
                        // { title: "Mobile Device as Microphone", url: "" },
                    ],
                },
                {
                    type: 'group',
                    topic: "Groups",
                    icon: 'mdi-account-group',
                    subTopics: [
                        { title: "Creating a group", url: "https://www.loom.com/embed/3b175a22db3a4d1a8a70d718b2900ddb?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                        { title: "Adding friends to existing group", url: "https://www.loom.com/embed/3c62986429ed4384b25db0427cd51249?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                        { title: "Deleting/Leaving an existing group", url: "https://www.loom.com/embed/47b19f410d51406a8dbb922bde4dae5d?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                    ],
                },
                {
                    type: 'friend',
                    topic: "Friends",
                    icon: 'mdi-account-star',
                    subTopics: [
                        { title: "Adding Friends", url: "https://www.loom.com/embed/8ef48cec84e244928362c3a440adb1b4?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                        // { title: "Share with your group", url: ""},
                        // { title: "Adding friends to your group", url: ""},
                    ],
                },
                {
                    type: 'playback',
                    topic: "Playback",
                    icon: 'mdi-view-carousel-outline',
                    subTopics: [
                        { title: "Watch Post with Your Voiceover", url: "https://www.loom.com/embed/999a6c319f2d48958bda27ffac5c5384?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                        { title: "Adding Contributions", url: "https://www.loom.com/embed/3c29901cd7994af29cc16075fe5cb9a6?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"},
                    ],
                },
            ],
            vidOptions: {
                autoplay: false,
                controls: true,
                responsive: true,
                sources: ["https://storage.googleapis.com/tawq-in-create.appspot.com/videos/tutorials/how-to-contribution-post.mp4"],
                // sources: ["https://storage.googleapis.com/tawq-in-create.appspot.com/videos/tutorials/tawq.in_tutorial.mp4"],
                techOrder: ["html5", "youtube"],
                textTrackSettings: false,
                html5: {
                nativeTextTracks: false,
                nativeControlsForTouch: false,
                nativeAudioTracks: false,
                },
                youtube: {
                enablejsapi: 1,
                fs: 0,
                playsinline: 1,
                disablekb: 1,
                ytControls: 0,
                origin: "https://tawq.in",
                modestbranding: 1,
                start: 0,
                },
            },
        }
    },
    methods: {
        setNextVideo(){
            /* the only way we increment the topic is if we are on the last subtopic in the selected topic.
                We incremenent the subTopic everytime, and reset it to 0 if the current video is the last subtopic in the selected topic */
            let topic = this.videoPlaying.topicIndex;
            let subTopic = this.videoPlaying.subTopicIndex + 1;
            if ( subTopic >= this.topics[this.videoPlaying.topicIndex].subTopics.length){ // we are on the last subtopic for this topic
                // console.log("got in the first if")
                topic = this.videoPlaying.topicIndex + 1; // go to the next topic
                subTopic = 0; // go to the starting subtopic 
                if ( topic >= this.topics.length) { // we are the on the very last topic
                    // just reset the topic and subtopic to 0 to go back to the very first video
                    topic = 0;
                    subTopic = 0;
                }
            }
            this.videoPlaying.topicIndex = topic;
            this.videoPlaying.subTopicIndex = subTopic;
            this.toWatchSrc = this.topics[topic].subTopics[subTopic].url;
        },
        setCurrentVideo(topicIndex, subTopicIndex){
            // console.log("got to current video", topicIndex, subTopicIndex)
            this.videoPlaying.topicIndex = topicIndex;
            this.videoPlaying.subTopicIndex = subTopicIndex;
            this.toWatchSrc = this.topics[topicIndex].subTopics[subTopicIndex].url;
            // console.log(this.videoPlaying)
        },
        determineTopicVideos(){
            if (typeof this.loadTopic === 'undefined'){
                return;
            }
            var topicIndex, subTopicIndex;
            switch(this.loadTopic){
                case 'createPost':
                    topicIndex = 0;
                    subTopicIndex = typeof this.loadSubTopic === 'undefined' ? 0 : this.loadSubTopic; // this means the loadTopic was set, but subTopic wasn't, so just load the first subTopic , this will be the step number, so minus 1 to equal the index
                    break;

                case 'contribute':
                    topicIndex = 3;
                    subTopicIndex = 1;
                    break;

                default:
                    topicIndex = 0;
                    subTopicIndex = 0;
                    break;
            }
            this.setCurrentVideo(topicIndex, subTopicIndex);
        },
    },
    created: function(){
        this.determineTopicVideos();
        // console.log("created the help page");
    }
}
</script>
<style >
 /* *{
     border: solid red 1px;
 } */
    
</style>