<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="closeDialog">
        <v-card style="overflow: hidden;" class="rounded-xl">
            <v-overlay v-if="saving" style="position: absolute;">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <v-card-title style="word-break: normal" class="text-center justify-center">
                <span class="font-weight-bold text-h5 mb-2">Upload thumbnail for {{group.groupName}}</span>
                <span v-if="existingThumbnail && previewImage === null" class="font-weight-light text-subtitle-2 mb-2">By uploading a thumbnail for <b>{{group.groupName}}</b>, you will overwrite the current thumbnail you have saved for the group.</span>
                <v-row v-if="previewImage === null" justify="center" no-gutters>
                    <v-btn text color="primary" @click.stop="triggerFileUpload">
                        <v-icon>mdi-upload</v-icon>Choose Image
                        <v-file-input ref="fileInput" v-model="newThumbnail" style="display: none !important;" accept="image/*" type="file" prepend-icon="" :rules="thumbnailRules" @change="setPreviewImage" single-line></v-file-input>
                    </v-btn>
                </v-row>
                <v-row v-else justify="center" no-gutters>
                    <div style="max-height: 300px;" class="d-flex flex-row justify-start align-start mt-3">
                        <v-icon @click="clearImageInput()" style="z-index: 1; position: absolute;" class="red--text text--darken-1 ml-n4 mt-n3">mdi-close-circle</v-icon>
                        <v-img cover max-width="220" height="300" :src="previewImage"></v-img>
                    </div>
                </v-row>
            </v-card-title>
            <div v-if="fileErrors" class="red--text mt-2 text-caption text-center font-italic">{{errorMessage}}</div>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="primary" text :disabled="fileErrors || newThumbnail === null" @click="uploadImageToExistingGroup">Upload</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// import firebase from "../../../firebaseConfig.js";
export default {
    name: "UploadGroupThumbnailDialog",
    props: [ "groupAdmin", "dialog", "existingThumbnail", "group"],
    data(){
        return{
            thumbnailFileLimit: 6000000, // 6MB
            thumbnailRules: [
                (value) =>
                !value ||
                value.size < this.thumbnailFileLimit ||
                "Thumbnail Must be less than 6 MB",
            ],
            newThumbnail: null,
            previewImage: null,
            fileErrors: false,
            errorMessage: "",
            saving: false,
        }
    },
    methods:{
        triggerFileUpload: function() {
            this.$refs.fileInput.$refs.input.click();
        },
        fileUploadError: function(event){
            console.log("error", event);
        },
        uploadImageToExistingGroup: async function(){
            this.saving = true;
            if (this.existingThumbnail) {
                // delete the existing thumbnail
                await this.$http.delete(`thumbnails/groups/${this.group.groupId}`).catch((err) => console.log(err));
            }
            let data = new FormData();
            data.append("file", this.newThumbnail);
            data.append("groupName", this.group.groupName);

            let response = await this.$http.post(`group/thumbnail/${this.group.groupId}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            const url = response.data.url;
            const updated = await this.$http.put(`groups/docs/${this.group.groupId}`, { url: url }).catch((err) => console.log(err));
            let text;
            if (updated.status === 201) {
                text = "Successfully updated group thumbnail.";
            } else {
                text = "Unsuccessful group thumbnail update.";
            }

            this.saving = false;
            this.$store.commit('alertUser', { show: true, text: text, type: 'snackbar' });
            this.$emit('closeDialog');
        },
        clearImageInput: function(){
            this.previewImage = null;
            this.newThumbnail = null;
        },
        setPreviewImage: function(event){
            this.fileErrors = false;
            this.errorMessage = "";
            if(event.size > this.thumbnailFileLimit){
                this.fileErrors = true;
                this.errorMessage = "Thumbnail Must be less than 6 MB.";
                return;
            }
            var reader = new FileReader();
            reader.onloadend = () => {
                this.previewImage = reader.result;
            }
            reader.readAsDataURL(this.newThumbnail);
        },
        closeDialog: function () {
            if (!this.saving) {
                this.$emit('closeDialog');
            }
        },
    },
}
</script>