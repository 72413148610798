<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="6" lg="6" md="8" sm="8">

            <v-row justify="center" no-gutters>
                <span class="text-h6 text-sm-h5 text-center">Enter Title and Select Language</span>
            </v-row>

            <v-form v-model="isFormValid">
                <!-- id="contributionMetadataForm" -->
                <v-row justify="center" no-gutters class="mt-8">
                    <v-col cols="12" xl="8" lg="8" md="10" sm="10">
                        <v-text-field
                            v-model="newAudioData.title"
                            clearable
                            required
                            flat
                            outlined
                            :rules="titleRules"
                            :counter="50"
                            label="Enter Title (required)"
                            @keydown.enter="emitNextStep()"
                        ></v-text-field>

                        <v-autocomplete
                            v-model="languageSelected"
                            :label="!hasAudio && !hasCaptions ? 'Select a language (required)' : 'Delete your tawq segments to change language.'"
                            :items="languageCodes"
                            item-text="text"
                            item-value="value"
                            return-object
                            outlined
                            :disabled="hasAudio || hasCaptions"
                            :hint="languageSelected.value === 'null' ? '' : '*This will be the langauge you speak while recording'"
                            persistent-hint
                            required
                        ></v-autocomplete>

                        <v-text-field
                            v-if="languageSelected.value === 'null'"
                            v-model="noCaptionLanguageSelected"
                            outlined
                            :label="!hasAudio && !hasCaptions ? 'Type in the language you will speak' : 'Delete your tawq segments to change language.'"
                            :disabled="hasAudio || hasCaptions"
                        ></v-text-field>
                        <!-- <v-switch v-model="matchStartTimesSwitch" label="Match Audio Start Times with Selected Contribution" @change="$emit('changeMatchAudioStartTimes', !matchAudioStartTimesWithSelectedContribution)"></v-switch> -->
                        <v-divider/>
                        <v-row justify="space-between" align="center" no-gutters class="pa-0 ma-0">
                            <span>Align Start Times with Selected:
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on">mdi-information-outline</v-icon>
                                    </template>
                                    <span>This option will align the start time of a recorded audio segment to the selected contribution's audio segment if they are within a specified time.</span>
                                </v-tooltip>
                            </span>
                            <v-switch v-model="matchStartTimesSwitch" inset color="primary" @change="$emit('changeMatchAudioStartTimes', !matchAudioStartTimesWithSelectedContribution)"></v-switch>
                        </v-row>
                        <v-divider/>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'ContrubitionMetadta',
    props: ["matchAudioStartTimesWithSelectedContribution",],
    data() {
        return {
            isFormValid: true,
            titleRules: [(v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be 50 characters or less",],
            languageCodes: [
                {
                    text: "None (Disable Auto Captions)",
                    value: "null",
                    model: "null",
                    location: "null",
                    recognizerEnum: "null",
                    recognizer: "null",
                },
            ],
            matchStartTimesSwitch: this.matchAudioStartTimesWithSelectedContribution,
        }
    },
    methods: {
        emitNextStep () {
            if(this.isFormValid){
                this.$emit('nextStep');
            }
        },
    },
    watch: {
        isFormValid(newValue){
            if(newValue){
                this.$store.commit('newContributionStore/saveContributionMetadataStep', { complete: true });
            }
            else {
                this.$store.commit('newContributionStore/saveContributionMetadataStep', { complete: false });
            }
        }
    },
    computed: {
        userData(){
            return this.$store.state.userData;
        },
        languageSelected: {
            get(){
                return this.$store.state.newContributionStore.newAudioData.languageSelected;
            },
            set(value){
                if (typeof value !== 'undefined') {
                    this.$store.commit('newContributionStore/saveNewContributionLanguageSelected', value);
                }
            }
        },
        tawqData: {
            get(){
                return this.$store.state.newContributionStore.tawqData;
            },
            set(value){
                this.$store.commit('newContributionStore/saveTawqData', value);
            }
        },
        newAudioData: {
            get(){
                return this.$store.state.newContributionStore.newAudioData;
            },
            set(value){
                this.$store.commit('newContributionStore/saveNewAudioData', value);
            }
        },
        noCaptionLanguageSelected: {
            get(){
                return this.$store.state.newContributionStore.newAudioData.noCaptionLanguageSelected;
            },
            set(value){
                // if (value != undefined) { 
                     this.$store.commit('newContributionStore/saveNewContributionNoLanguageSelected', value);
                // }
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
    },
    mounted(){
        this.languageCodes.push(...this.$languageCodes);
        // console.log('languageSelected: ', this.languageSelected)
    },
    created(){
        // The below if, else if and else, is used to set createPost.languageSelected to have the key value pairs of location, model, recognizerEnum and recognizer, which were created on July 29, 2024.
        // Users who set a default languageSelected in SettingsPage.vue, and have not updated it, after July 29, 2024 will not have the above values so we will find the corresponding values
        // in this.languageCodes by using userData.languageSelected.value (language code). If a user does not have languageSelected value in userData (which shouldn't happen) we default to English (United States) - "en-US"
        if (this.userData.languageSelected && typeof this.userData.languageSelected.recognizer === "undefined") this.languageSelected = this.languageCodes.find(obj => obj['value'] === this.userData.languageSelected.value);
        else if (this.userData.languageSelected && typeof this.userData.languageSelected.recognizer !== "undefined") this.languageSelected = this.userData.languageSelected
        else this.languageSelected = this.languageCodes[1] // Default: English (United States) - "en-US"
    },
}
</script>
