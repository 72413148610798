<template>
    <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-h4 text-md-h3 font-weight-light">{{tag}}</span>
            </v-row>

            <v-row v-if="emptySearch" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">No Posts with matching tag.</span>
            </v-row>

            <v-row v-if="postsLoading" justify="start" class="pa-0">
                <v-col v-for="(load,index) in posts.length > 0 ? posts.length : limit" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
                    <v-skeleton-loader height="250" type="image" class="rounded-xl"></v-skeleton-loader>
                </v-col>
            </v-row>

            <v-row v-else justify="start" class="pa-0">
                <v-col v-for="(post,index) in posts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                    <Post :post="post" :homefeed="true" :index="index" :isAdmin="$store.state.userData.admin"/>
                </v-col>
            </v-row>

      </v-col>
    </v-row>
</template>

<script>
import firebase from "../../firebaseConfig.js";
import Post from "./Post.vue";
// import Alert from "./Alert.vue";
export default {
    // metaInfo: {
    //     title: 'Tag - tawqin'
    // },
    name: "view-tag",
    props: ["tag", "postType",],
    components: {
        Post,
        // Alert,
        // Ad,
    },
    data: function () {
        return {
            posts: [],
            postsLoading: true,
            limit: 20,
            emptySearch: false,
            lastVisible: null,
        }
    },
    methods: {
        getPostsWithTag: function () {
            // Update this to order by date. It requires index (Ask Dillan for help with creating index)
            // this.postReference = firebase.db.collection("publicPosts").where("tags", "array-contains", this.tag);//.orderBy("postDate", "desc");
            this.postsLoading = true;
            this.postReference.get().then((snapshot) => {
                if(snapshot.empty){
                    if (this.lastVisible == null){
                        this.emptySearch = true;
                        this.postsLoading = false;
                    }
                    window.removeEventListener('scroll', this.atBottom);
                } else {
                    this.emptySearch = false;
                    this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                        // var post = doc.data();
                        // post.postDate = date;
                        this.posts.push(post);
                    });
                    this.postsLoading = false;
                    window.addEventListener("scroll", this.atBottom);
                }
            });
        },
        atBottom: function () {
            if(!this.postsLoading) {
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                if (bottomOfWindow) {
                    this.postReference = firebase.db.collection("publicPosts").where("tags", "array-contains", this.tag).startAfter(this.lastVisible).limit(this.limit);
                    this.getPostsWithTag();
                    window.removeEventListener("scroll", this.atBottom);
                }
            }
        },
    },
    watch: {
        'tag' (newValue, oldValue) {
            if (newValue != oldValue) {
                this.posts = [];
                this.postReference = firebase.db.collection("publicPosts").where("tags", "array-contains", this.tag).limit(this.limit);
                this.getPostsWithTag();
            }
        }
    },
    created: function () {
        this.postReference = firebase.db.collection("publicPosts").where("tags", "array-contains", this.tag).limit(this.limit);
        this.getPostsWithTag();
    },
    mounted: function () {
        window.addEventListener("scroll", this.atBottom);
    },
    beforeDestroy: function () {
        window.removeEventListener("scroll", this.atBottom);
    },
    // updated: function () {
    //     // console.log("UPDATED");
    //     // console.log(this.tag);
    //     // console.log(this.oldTag);
    //     if (this.tag != this.oldTag){
    //         this.postReference = firebase.db.collection("publicPosts")
    //             .where("tags", "array-contains", this.tag)//.orderBy("postDate", "desc");
    //             .limit(this.limit);
    //         this.oldTag = this.tag;
    //         this.posts = [];
    //         this.postsLoading = true
    //         this.getPostsWithTag();
    //     }
    // },
}
</script>