<template>
<v-row justify="center">
    <v-col xl="10" lg="12" md="10" sm="12" xs="12">

        <v-sheet outlined class="px-2 px-sm-8 py-4" style="position: relative;">

            <!-- MAKING CHANGES INDICATOR -->
            <div v-if="loading" class="payment-loading-container ma-0 pa-0">
                <v-overlay absolute>
                    <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                </v-overlay>
            </div>

            <!-- CONFIRMATION MODAL -->
            <v-row v-if="confirmationRequestModal && productRequestFinished" justify="center">
                <v-dialog v-model="confirmationRequestModal" :overlay-color="'$vuetify.theme.dark' ? 'grey' : ''"  persistent width="320" max-width="500" overlay-opacity=".9">
                    <v-card>
                        <v-card-title>Confirm Subscription Change</v-card-title>
                        <v-card-text>You are switching from <span class="font-italic font-weight-medium">{{stripeCustomer.product.name}}</span> to <span class="font-italic font-weight-medium">{{purchasePlan.name}}.</span></v-card-text>
                        <!-- <v-card-text v-if="stripeCustomer.subscription.plan.interval === 'year'">This will take your <span class="font-weight-medium">annual</span> cost from <span class="font-weight-medium">{{stripeCustomer.subscription.plan.amount / 100 === 0 ? 'Free' : `$${stripeCustomer.subscription.plan.amount / 100}` }}</span> to <span class="font-weight-medium">${{ purchasePlan.prices.length > 1 ? purchasePlan.prices[0].unit_amount / 100 : purchasePlan.prices[0].unit_amount / 100}}.</span></v-card-text>
                        <v-card-text v-else>This will take your <span class="font-weight-medium">monthly</span> cost from <span class="font-weight-medium">{{stripeCustomer.subscription.plan.amount / 100 === 0 ? 'Free' : `$${stripeCustomer.subscription.plan.amount / 100}` }}</span> to <span class="font-weight-medium">${{ purchasePlan.prices.length > 1 ? purchasePlan.prices[1].unit_amount / 100 : purchasePlan.prices[0].unit_amount / 100}}.</span></v-card-text>
                        <v-card-text v-if="!annual">You will be charged ${{ purchasePlan.prices.length > 1 ? purchasePlan.prices[1].unit_amount / 100 : purchasePlan.prices[0].unit_amount / 100}} as soon as the subscription is changed.</v-card-text>
                        <v-card-text v-if="annual">You will be charged ${{ purchasePlan.prices[0].unit_amount / 100 }} as soon as the subscription is changed.</v-card-text> -->
                        <v-card-text>You will be charged for the price of the plan as soon as the subscription is changed.</v-card-text>
                        <v-card-text>We will prorate what you haven't used from your current plan. You will see the refund to your account within 3-5 business days.</v-card-text>
                        <v-card-text>Are you sure you want to make the switch?</v-card-text>
                        <v-card-actions class="d-flex flex-row justify-space-between">
                            <v-btn depressed small text class="text-darken-2 red--text" @click="confirmationRequestModal = false">Cancel</v-btn>
                            <v-btn depressed small text class="primary--text" @click="changeSubscription(purchasePlan)">Change</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>


            <!-- AFTER CONFIRMATION -->
            <div class="text-center mb-4 text-h6">Change Subscription</div>
                <v-row justify="center" align="center">
                    <v-checkbox label="Annual Subscription" v-model="annual"></v-checkbox>
                </v-row>

                <v-row justify="center" align="center" class="py-4 mr-0 ml-0 flex-wrap-reverse flex-lg-wrap" style="width: 100%;">
                    <v-col xl="4" lg="4" md="6" sm="8" xs="12" class="mb-6 mb-md-0" v-for="product in products" :key="product.id" >
                        <PriceSheet :plan="product" :annual="annual" @click="changeSubscription(product)"/>
                        <v-hover v-slot="{hover}">
                            <v-btn v-if="stripeCustomer.subscription.status === 'active' && stripeCustomer.subscription.plan.product != product.id"  depressed block class="mt-4 action-button" :class="{ 'lighten-1' : hover }" :color="product.color" @click="requestConfirmationToChangePlans(product)">Switch to {{product.name}}</v-btn>
                            <v-btn v-else-if="stripeCustomer.subscription.plan.product === product.id" depressed disabled block class="mt-4">You have {{product.name}}</v-btn>
                        </v-hover>
                    </v-col>
                </v-row>
        </v-sheet>

        <Checkout v-if="addPaymentMethod" :plan="purchasePlan" :annual="annual" :dialog="addPaymentMethod" @updateSubscription="updateSubscription" @closeDialog="addPaymentMethod=false"/>
    </v-col>
</v-row>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from "../../firebaseConfig.js";
import Alert from "../components/Alert.vue";
import PriceSheet from "./PriceSheet.vue";
import Checkout from "./Checkout.vue";
export default {
    name: 'ChangeSubscription',
    components: { 
        Alert,
        PriceSheet,
        Checkout
    },
    data: function() {
        return {
            snackbar: false,
            snackbarText: "",
            products: null,
            annual: false,
            loading: false,
            addPaymentMethod: false,
            purchasePlan: null,
            confirmationRequestModal: false,
            productRequestFinished: false,
        }
    },
    methods: {
        getStripeProducts(){
            this.loading = true;
            this.productRequestFinished = false,
            this.$http.get(`stripe/products`)
            .then((response) => {
                if (response.data.error){
                    this.snackbar = true;
                    this.snackbarText = response.data.error + ", Try again.";
                }
                this.products = response.data.products.data;
                this.setProductPrices(response.data.prices.data);
                // console.log(this.products);
            })
        },
        setProductPrices(prices){
            for (var p = 0; p < this.products.length; p++) {
                if( this.products[p].name === 'Basic Plan'){
                    this.products[p].color = 'secondary lighten-1';
                } else if (this.products[p].name === 'Plus Plan') {
                    this.products[p].color = 'primary';
                }
                else if (this.products[p].name === 'Premium Plan'){
                    this.products[p].color = 'warning';
                }
                this.products[p].prices = [];
                for (var i =0; i < prices.length; i++){
                    if (prices[i].product === this.products[p].id){
                        this.products[p].prices.push(prices[i]);
                    } 
                }
            }
            this.loading = false;
            this.productRequestFinished = true;
        },
        requestConfirmationToChangePlans(product){
            this.purchasePlan = product;
            this.confirmationRequestModal = true;
        },
        changeSubscription(product){
            this.confirmationRequestModal = false;
            if (this.stripeCustomer.customer.invoice_settings.default_payment_method === null ){
                /* we do not need to add a payment method if they are switching to basic and somehow don't already have one (would have been deleted for whatever reason) */
                if (product.prices[0].amount != 0){
                    this.addPaymentMethod = true;
                    return;
                }
            }
            /* if they haven't added a payment method, the process above will allow them to add a payment method and checkout with their new plan */
            this.loading = true;
            let priceId;
            if(this.annual){
                // if annual is selected, make sure to grab the price is annual
                priceId = product.prices[0].recurring.interval === 'year' ? product.prices[0].id : product.prices[1].id;
            }
            else {
                // basic plan only has one price Id attached
                if(product.name === 'Basic Plan'){
                    priceId = product.prices[0].id; 
                }
                else {
                    // if annual isn't selected, grab the price that is the monthly recurring priceId
                    priceId = product.prices[0].recurring.interval === 'month' ? product.prices[0].id : product.prices[1].id;
                }
            }
            this.$http.put('stripe/update-subscription', {
                subscriptionId: this.stripeCustomer.subscription.id,
                newPriceId: priceId
            })
            .then((response) => {
                if (response.data.error){
                    this.snackbar = true;
                    this.snackbarText = response.data.error + ", Try again.";
                }
                //TODO: update firebase user doc info to reflect the new subscription... might have same id though
                this.$store.dispatch('setStripeSubscription', response.data.subscription);
                this.$store.dispatch('getStripeInvoices', this.stripeCustomer.customer.id);
                this.loading = false;
                this.$emit('updateSubscription', { subscription: response.data, type: 'edit' });
            })
        },
        updateSubscription(data){
            this.$emit('updateSubscription', data);
            this.$emit('updatePaymentMethod');
        },
    },
    computed: {
        stripeCustomer() {
            return this.$store.state.stripeCustomer;
        },
        userData() {
            return this.$store.state.userData;
        },
    },
    created: function(){
        this.getStripeProducts();
      
    },
    mounted: function() {

    },
}
</script>


    