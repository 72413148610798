<template>
<v-row class="py-0 px-4 ma-0">
<v-dialog v-model="dialog" persistent overlay-opacity=".85" :overlay-color="'$vuetify.theme.dark' ? 'grey' : ''">

    <!-- Checkout content -->
    <v-row justify="center" class="mr-0 ml-0">
            <v-col xl="5" lg="7" md="9" sm="11" xs="12" class="px-0">
                <v-btn icon text :disabled="checkout.state == 1 || checkout.state == 2" @click="closeDialog(true)"><v-icon class="icon--text pl-0 ml-0">mdi-close</v-icon></v-btn>
                <v-card max-height="600" min-height="350">
                    <!-- pending payment indicator -->
                    <div v-if="checkout.state === 1" class="payment-loading-container ma-0 pa-0">
                        <v-overlay absolute>
                            <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                        </v-overlay>
                    </div>
                    <!-- pending payment indicator -->


                    <!-- Payment collection details -->
                    <div v-if="checkout.state === 0 || checkout.state === 1" style="width: 100%;" class="px-4 px-sm-8 py-4 ma-0 d-flex flex-row flex-wrap align-stretch justify-space-between">
                        
                        <div style="width: 100%;" class="text-center text-h5 text-md-h4 mb-1 accent--text">{{plan.name}} Plan</div>
                        <v-row style="width: 100%;" justify="center">
                            <span class="text-caption mb-0 pb-0">Secured by</span>
                            <v-img v-if="$vuetify.theme.dark" height="30" contain src="../assets/stripe_white_sm.png" class="mt-0 pt-0"> </v-img>
                            <v-img v-else height="30" contain src="../assets/stripe_blurple_sm.png" class="mt-0 pt-0"> </v-img>
                        </v-row>
                        
                        <v-col xl="8" lg="7" md="7" sm="12" xs="12" class="px-0 pb-0 pt-3" style="min-height: 100%;">
                        
                            <v-form id="checkoutForm" v-model="paymentFormIsValid">

                                <div class="px-2" style="width: 100%;">
                                    <v-row align="center" justify="start">
                                        <v-col xl="9">
                                            <v-row class="px-3" align="end" justify="start">
                                                <!-- <input v-model="checkout.name" id="checkoutName" class="checkout-input flex-grow-1" :class="[$vuetify.theme.dark ? 'secondary--text text--lighten-4' : 'secondary--text text--darken-2']" type="text" placeholder="Full Name" required>
                                                <label for="checkoutname" class="checkout-label order-first accent--text">Name</label>
                                                <span class="input-border"></span> -->
                                                <v-text-field dense class="checkoutInput pt-0 mt-0" :class="[nameIsFocused ? 'primary--text' : 'accent--text']" validate-on-blur required prefix="Name" placeholder="Full Name (as it appears on card)" type="text" single-line v-model="checkout.name" @focus="nameIsFocused = true" @blur="nameIsFocused = false" :rules="nameRules"></v-text-field>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center" justify="start" class="mt-3">
                                        <v-col xl="9">
                                            <v-row class="px-3" align="end" justify="start">
                                                <v-text-field dense class="checkoutInput pt-0 mt-0" :class="[emailIsFocused ? 'primary--text' : 'accent--text']" validate-on-blur required prefix="Email" placeholder="Email" type="email" single-line v-model="checkout.email" @focus="emailIsFocused = true" @blur="emailIsFocused = false" :rules="emailRules"></v-text-field>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row justify="start" align="center" class="mt-3 px-2">
                                        <v-col xl="9" style="position: relative;" class="pb-0 px-0">
                                                <div ref="card" class="pb-2"></div>
                                                <span class="input-border"></span>
                                                <v-slide-y-transition>
                                                    <div style="width: 100%; position: absolute;" v-if="cardHasErrors" role="alert" class="py-1 text-left error--text card-errors">*{{cardErrors}}
                                                        <!-- <v-card-subtitle </v-card-subtitle> -->
                                                    </div>
                                                </v-slide-y-transition>
                                        </v-col>
                                        
                                    </v-row>
                                </div>


                                <div class="px-2 mt-8 mt-md-12 mt-lg-15" style="width: 100%;">
                                    <v-row justify="start" align="center">
                                        <v-checkbox class="mt-6" label="Annual One Time Charge" v-model="checkout.oneTimeCharge"></v-checkbox>
                                    </v-row>

                                    <v-row justify="start" align="center" class="mb-4">
                                        <v-btn  @click="purchase()" width="100%" :disabled="checkout.state === 1 || !validateInputs()" depressed color="primary">Pay ${{checkout.oneTimeCharge ? plan.prices[0].unit_amount / 100 : plan.prices.length > 1 ? plan.prices[1].unit_amount / 100 : plan.prices[0].unit_amount / 100}}</v-btn>
                                    </v-row>
                                </div>
            
                            </v-form>
                        </v-col>

                        <v-divider vertical class="hidden-sm-and-down" ></v-divider>

                        <v-col xl="3" lg="3" md="3" class="px-0 pt-0 hidden-sm-and-down">
                            <v-list class="pl-0 pt-0" >
                                <v-card-text v-for="(feature, key) in plan.metadata" :key="key" v-text="key" class="text-caption py-1 grey--text text--darken-1 px-0"></v-card-text>
                            </v-list>
                        </v-col>

                    </div>

                    <!-- Successful payment -->
                    <div v-else-if="checkout.state === 2" class="px-4 px-sm-8 py-4 d-flex flex-column justify-space-between">
                        <div style="width: 100%;" class="text-center text-h5 text-md-h4 mb-4 invert--text">Successfully purchased</div>

                        <v-row justify="center" class="mb-4">
                            <v-icon class="primary--text" size="80">mdi-shield-check</v-icon>
                        </v-row>

                        <v-row justify="center">
                            <p>You successfully purchased the {{plan.name}} plan.</p>
                        </v-row>
                        <v-row justify="center">
                            <p>We just sent a receipt to the email: {{checkout.email}}</p>
                        </v-row>
                        <v-row justify="center">
                            <v-btn class="primary" text depressed @click="closeDialog(false)">Done</v-btn>
                        </v-row>
                    </div>

                </v-card>
            </v-col>
            
    </v-row>


    

</v-dialog>
</v-row>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: 'Checkout',
    metaInfo: {
        title: 'Checkout - tawqin'
    },
    components: { 
    },
    props: ["plan", "dialog", "annual", "editPayment"],
    data: function() {
        return {
            stripe: null,
            stripeElements: null,
            cardElement: null,
            cardErrors: "",
            cardHasErrors: false,
            clientCode: "",
            styles: null,
            lightStyle: {
                base: {
                    fontSmoothing: 'antialiased',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                }
            },
            planRef: firebase.db.collection("plans"),
            paymentFormIsValid: false,
            cardIsValid: false,
            checkout: {
                name: null,
                email: null,
                // address: null,
                oneTimeCharge: this.annual,
                planUID: this.planUID,
                state: 0, // 0 = payment collection, 1 = pending payment, 2 = successful payment, 3 = failed payment (just goes to 0)
            },
            nameIsFocused: false,
            emailIsFocused: false,
            // addressIsFocused: false,
            nameRules: [
                (v) => !!v || "*Enter your name as it appears on the card."
            ],
            emailRules: [
                (v) => !!v || "*Email is required",
                (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid",
            ],
        }
    },
    methods: {
        initializeStripe: async function() {
            this.stripe = await Stripe('pk_test_51HtI2rC3oDEbxLPSnM56fK0ldrHxhS48Z2EbKStM51enTdrclGA9H9Ae1EzOiYHh9uzZ79Ox4oFNtQeQbslrrDWT0093vBQxra');
            // console.log("stripe object", this.stripe);
            this.stripeElements = this.stripe.elements({
                fonts: [ { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' } ],
                locale: 'auto'
            });
            // this.lightStyle.base.color = this.styles.getPropertyValue('--v-primary-base');
            if (this.$vuetify.theme.dark){
                this.lightStyle.base.color = this.styles.getPropertyValue('--v-secondary-lighten4');
            }
            else {
                this.lightStyle.base.color = this.styles.getPropertyValue('--v-secondary-darken1');
            }
            this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-accent-base');
            this.lightStyle.base['::placeholder'] = { color: this.styles.getPropertyValue('--v-inputPlaceholder-base') };
            this.cardElement = this.stripeElements.create('card', {style: this.lightStyle});
            this.cardElement.mount(this.$refs.card);
            this.cardElement.on('change', this.handleCardErrors);
            this.cardElement.on('focus', () => {
                this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-primary-base');
                this.cardElement.update({ style: this.lightStyle });
            });
            this.cardElement.on('blur', () => {
                this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-accent-base');
                this.cardElement.update({ style: this.lightStyle });
            });

        },
        handleCardErrors(event){
            // console.log("handle card errors")
            if (event.error){
                this.cardHasErrors = true;
                this.cardErrors = event.error.message;
                this.cardIsValid = false;
            } else {
                if (event.complete){
                    this.cardIsValid = true;
                    this.cardHasErrors = false;
                    this.cardErrors = "";
                } 
                else {
                    this.cardHasErrors = false;
                    this.cardErrors = "";
                }
            }
        },
        getPaymentIntent(){
            return new Promise((resolve, reject) => {
                this.$http.get(`/stripe/intent/${this.plan.uid}` , {
                    headers: {
                        email: this.checkout.email,
                        annualPlan: this.checkout.oneTimeCharge,
                    }
                })
                .then((response) => {
                    // console.log("response for intent: ", response.data);
                    resolve(response.data.client_secret);
                })
                .catch((error ) => {
                    console.error("error retrieving payment intent");
                    reject("Error");
                })
            })
        },
        getDefaultPaymentMethod(payment_id){
            this.$http.get(`stripe/payment-method/${payment_id}`)
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                }
                this.$store.dispatch('setStripePaymentMethod', response.data.payment_method);
            })
        },
        createCustomer(){
            if (this.$store.state.userData.stripeCustomerId){
                this.createPaymentMethod(this.$store.state.userData.stripeCustomerId)
            } 
            else {
                 this.$http.post(`/stripe/new-customer`, {
                    email: this.checkout.email,
                    name: this.checkout.name
                }).then((response) => {
                    if (response.data.error){
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                        return;
                    }
                    firebase.db.collection("userData").doc(firebase.auth.currentUser.uid).update({stripeCustomerId : response.data.customer.id});
                    this.createPaymentMethod(response.data.customer.id);
                    //TODO: update firebase if there isn't a full name
                })
            }
           
        },
        createPaymentMethod(customerId){
            this.stripe.createPaymentMethod({
                type: 'card',
                card: this.cardElement,
                billing_details: {
                    name: this.checkout.name,
                    email: this.checkout.email,
                    // address: this.checkout.address,
                }
            })
            .then((result) => {
                if(result.error){
                    this.cardHasErrors = true;
                    this.cardErrors = result.error.message;
                    this.checkout.state = 0;
                }
                else {
                    this.updateCustomer(result.paymentMethod.id); // attach new payment method to customer
                    this.getDefaultPaymentMethod(result.paymentMethod.id);
                }
            })
        },  
        updateCustomer(payment_method_id){
            this.$http.put(`stripe/customer/payment/${this.stripeCustomer.customer.id}`, { payment_method_id: payment_method_id })
            .then((response) => {
                if (response.data.error){
                    this.cardHasErrors = true;
                    this.cardErrors = response.data.error;
                    this.checkout.state = 0;
                    return;
                }
                // console.log('response', response.data);
                this.$store.dispatch('setStripeCustomer', response.data.customer);
                this.changeSubscription(this.plan); // update the subscription on the customer
            })
            .catch((error) => {
                console.error("ERROR attaching payment to customer", error);
            })
        },
        changeSubscription(product){
            this.loading = true;
            let priceId;
            if(this.checkout.oneTimeCharge){
                // if annual is selected, make sure to grab the price is annual
                priceId = product.prices[0].recurring.interval === 'year' ? product.prices[0].id : product.prices[1].id;
            }
            else {
                // basic plan only has one price Id attached
                if(product.name === 'Basic Plan'){
                    priceId = product.prices[0].id; 
                }
                else {
                    // if annual isn't selected, grab the price that is the monthly recurring priceId
                    priceId = product.prices[0].recurring.interval === 'month' ? product.prices[0].id : product.prices[1].id;
                }
            }
            this.$http.put('stripe/update-subscription', {
                subscriptionId: this.stripeCustomer.subscription.id,
                newPriceId: priceId
            })
            .then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                    this.loading = false;
                    this.checkout.state = 0;
                    return;
                }
                this.loading = false;
                this.checkout.state = 2;
                this.$store.dispatch('setStripeSubscription', response.data.subscription);
                this.$store.dispatch('getStripeInvoices', this.stripeCustomer.customer.id);
                // this.$store.dispatch()
            })
        },
        createSubscription(data){
            this.$http.post(`/stripe/new-paid-subscription`, {
                customerId: data.customerId,
                paymentMethodId: data.paymentMethodId,
                priceId: data.priceId,
            }).then((response) => {
                if (response.data.error){
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${response.data.error}`, type: 'snackbar' });
                    this.loading = false;
                    this.checkout.state = 0;
                    return;
                }
                this.checkout.state = 2;
            })
        },
        purchase: async function() {
            // console.log("purchase called");
            this.checkout.state = 1; // pending

            if (!this.validateInputs()){
                return;
            }
            // if not a customer create one...., this method will check to make sure there is a customer
            this.createCustomer();
        },
        closeDialog(exit){
            if (!exit){
                this.$emit('updateSubscription', { type: 'edit'} );
            }
            this.$emit('closeDialog');

        },
        validateInputs(){
            if (this.cardIsValid && this.paymentFormIsValid){
                return true;
            }
            return false;
        },
        isAdmin(){
            return firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.uid === 'ex18uSUvXReInwQRqaAZxO6Syer2';
        }
    },
    computed: {
        stripeCustomer(){
            return this.$store.state.stripeCustomer;
        }
    },
    created: async function(){
        // this.getPlan();
        // console.log("Checkout.vue plan:", this.plan);
        // TODO: get the product info from stripe API
        this.checkout.email = firebase.auth.currentUser.email;
        this.checkout.name = this.$store.state.userData.fullname;
        this.styles = window.getComputedStyle(document.body);
    },
    mounted: function(){
        this.initializeStripe(); 
        
        // console.log("user: ", this.$store.state.userData);
    },
    beforeDestroy: function() {
        // TODO: do we have to turn off the change listener?
        this.cardElement.destroy();
    }
}
</script>
<style>

.v-text-field__prefix {
    padding-right: 16px !important;
}

.payment-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

#checkoutForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

:focus{outline: none;}

.checkout-input {
    border: 0; 
    /* border-bottom: 1px solid #ccc; */
    padding-left: 14px;
    padding-bottom: 2px;
}

::placeholder {
    color: var(--v-inputPlaceholder-base);
}

.checkout-label {
    border: 0; 
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px;
}

.checkout-input ~ .input-border{
    position: absolute; 
    bottom: 0; 
    left: 50%; 
    width: 0; 
    height: 2px; 
    background-color: var(--v-primary-base); 
    transition: 0.4s;
}

.checkout-input:focus ~ .input-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
}

.StripeElement ~ .input-border {
    position: absolute; 
    bottom: 0; 
    left: 50%; 
    width: 0; 
    height: 2px; 
    background-color: var(--v-primary-base); 
    transition: 0.4s;
}

.StripeElement--focus ~ .input-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
}

.checkout-input:focus ~ .checkout-label {
    color: var(--v-primary-base) !important;
    transition: 0.4s;
}

.StripeElement{
    color: var(--v-inputColor-base) !important;
    position: relative;
    border: 0; 
    border-bottom: 1px solid #ccc;
}
.StripeElement--focus {
    transition: 0.4s !important;
    transition-property: color !important;
}

.card-errors {
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 12px;
}

</style>