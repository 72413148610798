<template>
    <v-dialog v-model="dialog" @click:outside="closeFeedback" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
        <transition enter-active-class="slideLeft" leave-active-class="noShow" appear>
            <v-card v-if="feedbackType === 0" class="rounded-xl">
                <v-card-title style="word-break: normal">
                    <span class="text-wrap text-h5 font-weight-bold pl-2 pt-3 pb-5">Give Feedback to tawq.in</span>
                </v-card-title>

                <v-card-text>
                    <v-hover v-slot="{hover}" style="cursor: pointer;" class="pa-1">
                        <v-row :class="{'help' : hover}" class="mx-0 rounded-xl" align="center" @click.stop="feedbackType = 1">
                            <v-col cols="2" class="my-0 rounded-xl" align="center">
                                <v-icon large >mdi-arm-flex</v-icon>
                            </v-col>
                            <v-col cols="10" class="my-0 rounded-xl">
                                <span class="subtitle-1 font-weight-bold" :class="$vuetify.theme.dark ? 'white--text' : ''">Help Us Improve tawq.in</span>
                                <br/>
                                <span class="font-weight-light caption">Give feedback about your experience with tawq.in<span class="font-weight-bold"></span></span>
                            </v-col>
                        </v-row>
                    </v-hover>
                    <v-hover v-slot="{hover}" style="cursor: pointer;" class="pa-1">
                        <v-row :class="{'help' : hover}" class="mx-0 rounded-xl" align="center" @click.stop="feedbackType = 2">
                            <v-col cols="2" class="my-0 rounded-xl" align="center">
                                <v-icon large>mdi-alert</v-icon>
                            </v-col>
                            <v-col cols="10" class="my-0 rounded-xl">
                                <span class="subtitle-1 font-weight-bold" :class="$vuetify.theme.dark ? 'white--text' : ''">Something Went Wrong</span>
                                <br/>
                                <span class="font-weight-light caption">Let us know about a broken feature<span class="font-weight-bold"></span></span>
                            </v-col>
                        </v-row>
                    </v-hover>
                    <v-hover v-slot="{hover}" style="cursor: pointer;" class="pa-1">
                        <v-row :class="{'help' : hover}" class="mx-0 rounded-xl" align="center" @click.stop="openDesignLink">
                            <v-col cols="2" class="my-0 rounded-xl" align="center">
                                <v-icon large>mdi-link</v-icon>
                            </v-col>
                            <v-col cols="10" class="my-0 rounded-xl">
                                <span class="subtitle-1 font-weight-bold" :class="$vuetify.theme.dark ? 'white--text' : ''">Design Survey</span>
                                <br/>
                                <span class="font-weight-light caption">Help us improve our design<span class="font-weight-bold"></span></span>
                            </v-col>
                        </v-row>
                    </v-hover>
                    <!-- <v-hover v-slot="{hover}" style="cursor: pointer;" class="pa-1">
                        <v-row :class="{'help' : hover}" class="mx-0 rounded-xl" align="center" @click.stop="openQualtricsLink">
                            <v-col cols="2" class="my-0 rounded-xl" align="center">
                                <v-icon large>mdi-link</v-icon>
                            </v-col>
                            <v-col cols="10" class="my-0 rounded-xl">
                                <span class="subtitle-1 font-weight-bold" :class="$vuetify.theme.dark ? 'white--text' : ''">Infinity Festival - Fort Worth</span>
                                <br/>
                                <span class="font-weight-light caption">Jibbali Technology Study<span class="font-weight-bold"></span></span>
                            </v-col>
                        </v-row>
                    </v-hover> -->
                </v-card-text>

                <v-card-actions>
                    <v-row justify="end" no-gutters>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeFeedback">Cancel</v-btn>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="sendFeedback" disabled>Submit</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </transition>


        <transition enter-active-class="slideRight" leave-active-class="noShow" appear>
            <v-card v-if="feedbackType === 1" class="rounded-xl">
                <!-- Help Us Improve tawq.in -->
                <v-card-title style="word-break: normal">
                    <v-row class="mx-0">
                        <v-col cols="12" class="ma-0">
                            <span class="text-wrap text-h6 font-weight-bold">Help Us Improve tawq.in</span>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <v-form v-model="isFormValid">
                                <span class="text-wrap text-subtitle-1 font-weight-bold">Where can we improve?</span>
                                <v-select
                                    v-model="select"
                                    :items="pages"
                                    :rules="selectRules"
                                    label="Select an area"
                                    required
                                    outlined
                                ></v-select>
                                <span class="text-wrap text-subtitle-1 font-weight-bold">Details:</span>
                                <v-textarea placeholder="Please include as much detail as possible..." counter="250" outlined v-model="details" :rules="detailsRules"></v-textarea>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click.stop="feedbackType = 0" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-row justify="end" no-gutters>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeFeedback">Cancel</v-btn>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="sendFeedback" :disabled="!isFormValid">Submit</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </transition>


        <transition enter-active-class="slideRight" leave-active-class="noShow" appear>
            <v-card v-if="feedbackType === 2" class="rounded-xl">
                <!-- Something Went Wrong -->
                <v-card-title style="word-break: normal">
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <span class="text-wrap text-h6 font-weight-bold">Something Went Wrong</span>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <v-form v-model="isFormValid">
                                <span class="text-wrap text-subtitle-1 font-weight-bold">Where can we improve?</span>
                                <v-select
                                    v-model="select"
                                    :items="pages"
                                    :rules="selectRules"
                                    label="Select an area"
                                    required
                                    outlined
                                ></v-select>
                                <span class="text-wrap text-subtitle-1 font-weight-bold">Details:</span>
                                <v-textarea placeholder="Please include as much detail as possible..." counter="250" outlined v-model="details" :rules="detailsRules"></v-textarea>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click.stop="feedbackType = 0" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-row justify="end" no-gutters>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeFeedback">Cancel</v-btn>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="sendFeedback" :disabled="!isFormValid">Submit</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </transition>

        <transition enter-active-class="slideRight" leave-active-class="noShow" appear>
            <v-card v-if="feedbackType === 3" class="rounded-xl">
                <!-- Infinity Festival - Fort Worth -->
                <v-card-title style="word-break: normal">
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <!-- <span class="text-wrap text-h6 font-weight-bold">Infinity Festival - Fort Worth</span> -->
                            <span class="text-wrap text-h6 font-weight-bold">Thanks for your Support!</span>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <p class="text-wrap text-subtitle-1 font-weight-bold">If a new tab did not open please click the link below:</p>
                            <a href="https://unt.az1.qualtrics.com/jfe/form/SV_79AldO3kwpGMdkG" target="_blank">https://unt.az1.qualtrics.com/jfe/form/SV_79AldO3kwpGMdkG</a>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <p class="text-wrap text-subtitle-1 font-weight-bold">If a new tab did not open please click the link below:</p>
                            <a href="https://unt.az1.qualtrics.com/jfe/form/SV_7O5UCfWJn9SSTTU" target="_blank">https://unt.az1.qualtrics.com/jfe/form/SV_7O5UCfWJn9SSTTU</a>
                        </v-col>
                    </v-row> -->
                </v-card-text>

                <v-card-actions>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click.stop="feedbackType = 0" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-row justify="end" no-gutters>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeFeedback">Cancel</v-btn>
                        <!-- <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="sendFeedback" :disabled="!isFormValid">Submit</v-btn> -->
                    </v-row>
                </v-card-actions>
            </v-card>
        </transition>


        <transition enter-active-class="slideUp" leave-active-class="noShow">
            <v-card v-if="feedbackType === 4" class="rounded-xl">
                <v-card-title style="word-break: normal">
                    <v-row class="mx-0">
                        <v-col cols="12" class="my-0">
                            <span class="text-wrap text-h5 font-weight-bold">Thanks for the Feedback!</span>
                            <!-- <p class="font-weight-light text-subtitle-1 mt-3">Thanks for helping keep our community safe! <span class="font-weight-bold"></span></p> -->
                            <p class="font-weight-light text-subtitle-1 mt-3">If you have any questions feel free to email us at</p>
                        </v-col>
                        <v-col cols="12" class="my-0 mt-n3">
                            <p @click.stop="emailKeep()" class="text-subtitle-1 font-weight-bold text-decoration-underline" :class="$vuetify.theme.dark ? 'amber--text' : 'primary--text'" style="cursor:pointer;">keep@tawq.in</p>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-actions>
                    <v-row justify="end" no-gutters>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="closeFeedback">Close</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </transition>
    </v-dialog>
</template>

<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: "feedback",
    // if we pass in the feedback type we can set up a new v-card to have info we want to collect from user about their experience with tawq.in
    // but we will also have to emit back to the parent to change the feedbackType in previousStep().
    props: ["dialog", /*"feedbackType"*/],
    data: () => ({
        isFormValid: false,
        // thanks: false, // this will now be feedbackType: 4
        thanksTimeout: null,
        feedbackType: 0,
        select: null,
        selectRules: [
            (v) => !!v || "Must select an area",
        ],
        pages: [
            // "Post Creation",
            "Login",
            "Sign Up",
            "Creating a Post",
            "Contributions",
            "Viewing a Post",
            // "Mobile Controller (mobile create)",
            "Home Feed",
            "Friends Feed",
            "Drafts",
            "About",
            "Profile",
            "Groups",
            "Group Settings",
            "Friends Page",
            "Settings",
            "Notifications",
            "Other",
        ],
        details: "",
        detailsRules: [
            (v) => !!v || "Must enter details",
            (v) => v.length <= 250 || "Details must be 250 characters or less",
        ],
    }),
    methods: {
        // openQualtricsLink: function () {
        //     this.feedbackType = 3;
        //     window.open("https://unt.az1.qualtrics.com/jfe/form/SV_7O5UCfWJn9SSTTU", "_blank");
        // },
        openDesignLink: function () {
            this.feedbackType = 3;
            window.open("https://unt.az1.qualtrics.com/jfe/form/SV_79AldO3kwpGMdkG", "_blank");
        },
        sendFeedback: function () {
            // TODO: add ability to send a screenshot as well. If we do this we need to send this to the server to upload
            // which means we will need a loadig dialog as well.
            firebase.db.collection("feedback").doc().set({
                userId: firebase.auth.currentUser.uid,
                name: firebase.auth.currentUser.displayName,
                email: firebase.auth.currentUser.email,
                area: this.select,
                details: this.details,
                date: new Date(),
            }, {message: true}).then(()=>{
                // this.thanks = true;
                this.feedbackType = 4;
                this.thanksTimeout = setTimeout(() =>{
                    this.$emit('closeFeedback');
                }, 3500);
            });
        },
        closeFeedback: function () {
            clearTimeout(this.thanksTimeout);
            this.$emit('closeFeedback');
        },
        previousStep: function () {
            // emit to parent if needed.
            this.feedbackType = 0;
        },
        emailKeep: async function(){
            var domain = firebase.auth.currentUser.email.split('@')[1];

            const message = `Feedback from: ${'%0D%0A'} ${encodeURIComponent(firebase.auth.currentUser.displayName)}`;
            const subject = `Feedback: ${encodeURIComponent(this.select)}`;
            switch(domain){
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com': {
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                }

                default: {
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
                }
            } // end of email/gmail switch
        },
    },
    created: function () {},
}
</script>

<style scoped>
.help {
    background: rgba(211, 211, 211, 0.09);
}

.noShow {
    opacity: 0;
}

@keyframes slideRight {
    from {
        opacity: 0;
        transform: translateX(-50px);
        /* z-index: 3; */
    }
    to {
        opacity: 1;
        /* z-index: 0; */
    }
}
.slideRight {
    animation-name: slideRight;
    animation-duration: .5s;
}

@keyframes slideLeft {
    from {
        opacity: 0;
        transform: translateX(50px);
        /* z-index: 3; */
    }
    to {
        opacity: 1;
        /* z-index: 0; */
    }
}
.slideLeft {
    animation-name: slideLeft;
    animation-duration: .5s;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(50px);
        z-index: 0;
    }
    to {
        opacity: 1;
        z-index: 1;
    }
}
.slideUp {
    animation-name: slideUp;
    animation-duration: .5s;
}
</style>