<template >
    <div>
        <v-dialog v-model="dialog" persistent max-width="500" overlay-color="grey darken-1" overlay-opacity=".5">
            <v-card style="overflow: hidden;">
                <v-overlay v-if="saving" style="position: absolute;">
                    <v-progress-circular indeterminate color="warning"></v-progress-circular>
                </v-overlay>
                <v-row justify="center">
                    <v-card-title style="word-break: normal;">Upload thumbnail for your post</v-card-title>
                </v-row>
                <v-divider/>
                <v-card-text class="py-4" style="max-width: 100%; width: 100%;">
                    <div v-if="existingThumbnail && previewImage === null" class="mb-2">By uploading an image for your post, you will overwrite the current image you have saved for your post.</div>
                    <v-row v-if="previewImage === null" justify="center" align="center" class="mx-0 fill-height">
                        <div class="d-flex flex-column align-center justify-center primary white--text px-4 py-2 rounded-lg" style="cursor: pointer;" @click.stop="triggerFileUpload">
                            <v-icon>mdi-upload</v-icon>Upload Image
                            <v-file-input ref="fileInput" v-model="newThumbnail" style="display: none !important;" accept="image/*" type="file" prepend-icon="" :rules="thumbnailRules" @change="setPreviewImage" single-line></v-file-input>
                        </div>
                    </v-row>


                    <v-row v-else justify="center" align="start" class="mx-0">
                        <div class="d-flex flex-row justify-start align-start" style="max-height: 300px;">
                            <v-icon class="red--text text--darken-1 ml-n4 mt-n3" style="z-index: 1; position: absolute;" @click="clearImageInput()">mdi-close-circle</v-icon>
                            <v-img cover max-width="220" height="300" :src="previewImage"></v-img>
                        </div>
                    </v-row>

                    <div v-if="fileErrors" class="red--text mt-2 text-caption text-center font-italic">{{errorMessage}}</div>


                </v-card-text>
                <v-divider/>
                    <v-card-actions>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="$emit('close-dialog')">Close</v-btn>
                        <v-spacer/>
                        <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text :disabled="fileErrors || newThumbnail === null" @click="uploadImageToExistingPost">Upload</v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: "UploadPhotoDialog",
    props: [ "userId", "postType", "postId", "dialog", "existingThumbnail" ],
    data(){
        return{
            thumbnailFileLimit: 6000000, // 6MB
            thumbnailRules: [
                (value) =>
                !value ||
                value.size < this.thumbnailFileLimit ||
                "Thumbnail Must be less than 6 MB",
            ],
            newThumbnail: null,
            fileErrors: false,
            previewImage: null,
            errorMessage: "",
            saving: false,
        }
    },
    methods:{
        triggerFileUpload: function() {
            this.$refs.fileInput.$refs.input.click();
        },
        fileUploadError: function(event){
            console.log("error", event);
        },
        uploadImageToExistingPost: async function(){
            this.saving = true;
            // if existing image, delete it.
            if(this.existingThumbnail){
                let deleted = await this.$http.delete(`thumbnails/${this.userId}/${this.postId}`).catch((err) => console.log(err));
                if (deleted.status != 200){
                    this.fileErrors = true;
                    this.errorMessage = "Problem Uploading Image, Please Try Again."
                    this.previewImage = null;
                    this.newThumbnail = null;
                    return;
                }
            }
            
            var data = new FormData();
            data.append("file", this.newThumbnail);
            data.append("postId", this.postId);
            let response = await this.$http.post(`uploadpic`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            // update the post data to reflect the new url!
            await firebase.db.collection(this.postType === 'group' ? 'groupPosts' : 'publicPosts').doc(this.postId).update({ thumbnail: response.data.url });
            this.saving = false;
            this.$emit('thumbnail-upload-success')
            this.$emit('close-dialog');
        },
        clearImageInput: function(){
            this.previewImage = null;
            this.newThumbnail = null;
        },
        setPreviewImage: function(event){
            this.fileErrors = false;
            this.errorMessage = "";
            if(event.size > this.thumbnailFileLimit){
                this.fileErrors = true;
                this.errorMessage = "Thumbnail Must be less than 6 MB.";
                return;
            }
            var reader = new FileReader();
            reader.onloadend = () => {
                this.previewImage = reader.result;
            }
            reader.readAsDataURL(this.newThumbnail);
        }

    },
    // destroyed: function () {
    //     // console.log("destroyed")
    // },
    // created: function () {
    // },
}
</script>
<style lang="">
    
</style>