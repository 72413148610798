import { render, staticRenderFns } from "./UserAnalytics.vue?vue&type=template&id=0ac65feb&scoped=true"
import script from "./UserAnalytics.vue?vue&type=script&lang=js"
export * from "./UserAnalytics.vue?vue&type=script&lang=js"
import style0 from "./UserAnalytics.vue?vue&type=style&index=0&id=0ac65feb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac65feb",
  null
  
)

export default component.exports