<template>

    <v-row justify="center" no-gutters class="px-lg-15">
        <Alert :key="NaN" v-if="deleteContributionDialog" :dialogVal="true" type="dialog" title="Are you sure you want to delete this contribution?" text="Your contribution will be permanently deleted. This action cannot be undone." @dialogClicked="deleteContribution"/>
        <v-col cols="12" xl="7" lg="8" md="12" sm="12" xs="12" class="pr-lg-5">
            <v-row v-if="viewLoading || !letUserSeePost" justify="center" no-gutters>
                <v-col cols="12">
                    <v-skeleton-loader height="600" type="image" class="hidden-md-and-down"></v-skeleton-loader>
                    <!-- <v-skeleton-loader height="600" type="image" class="hidden-xl-only hidden-md-and-down"></v-skeleton-loader> -->
                    <v-skeleton-loader height="450" type="image" class="hidden-lg-and-up hidden-xs-only"></v-skeleton-loader>
                    <v-skeleton-loader height="200" type="image" class="hidden-sm-and-up"></v-skeleton-loader>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12">
                    <!-- @ready="playerReady=true" -->
                    <!-- @seeked="[currentAudioSegment, nextAudioSegment] = [null, null]" -->
                    <!-- @firstPlay="firstPlay" -->
                    <!-- @markerReached="markerReached" -->
                    <!-- @markerClicked="markerClicked" -->
                    <VideoPlayer
                        v-if="postData.videoUrl && postData.videoType"
                        ref="vidPlayer"
                        :options="vidOptions"
                        :isCreating="false"
                        :videoLoading="videoLoading"
                        :anonymousMode="postData.anonymousMode || false"
                        :playbackStyle="playbackStyle"
                        :playbackStyleAudioFirst="playbackStyleAudioFirst"
                        :showCaptions="showCaptions"
                        @seeked="videoPlayerSeeked"
                        @hasBegunPlaying="onHasBegunPlaying"
                        @playAudioPlayer="playAudioPlayer"
                        @pauseAudioPlayer="pauseAudioPlayer"
                        @controlsEnabled="enableControls"
                        @playerReady="getAudioData"
                        @timeUpdate="timeUpdate"
                    />
                </v-col>
            </v-row>

            <!-- Show More/Less Button to view description, playback style, and caption audio -->
            <div v-if="letUserSeePost" style="position: relative; max-width: 100%;" class="full-width">
                <v-row justify="space-around" align="center" no-gutters class="mt-5">
                    <v-col cols="10" :class="viewFullDescription ? '' : 'd-inline-block text-truncate'">
                        <span v-if="!editPost" class="text-h5 text-xl-h4 text-lg-h4 font-weight-bold">{{postData.title}}</span>
                        <v-text-field v-else ref="editTitle" v-model="postData.title" @change="postMetadataHasChanged = true" :rules="titleRules" :counter="50" outlined class="text-subtitle-1 text-xl-h5 text-lg-h5 font-weight-bold"></v-text-field>
                    </v-col>

                    <v-col cols="2" align="end">
                        <v-menu v-model="menu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" close-on-content-click>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.stop="menu = !menu" icon :disabled="editPost">
                                    <v-icon :color="$vuetify.theme.dark ? 'amber' : 'primary'">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list rounded>
                                <v-list-item @click.stop="showShareDialog = true, menu = !menu">
                                    <v-icon class="pr-2">mdi-share</v-icon>Share<br>
                                </v-list-item>
                                <v-list-item @click.stop="reportDialog = true, menu = !menu">
                                    <v-icon class="pr-2">mdi-flag</v-icon>Report
                                </v-list-item>
                                <v-list-item v-if="isUsersPost() && postData.thumbnail != '' && postData.thumbnail != null" @click.stop="triggerFileUpload(), menu = !menu"> 
                                    <v-icon class="pr-2">mdi-image-plus</v-icon>Change Thumbnail<br>
                                </v-list-item>
                                <v-list-item v-if="isUsersPost() && (postData.thumbnail === '' || postData.thumbnail === null)" @click.stop="triggerFileUpload(), menu = !menu"> 
                                    <v-icon class="pr-2">mdi-image-plus</v-icon>Add Post Thumbnail<br>
                                </v-list-item>
                                <v-list-item v-if="isUsersPost()" @click.stop="editPost = true, viewFullDescription = true, menu = !menu"> 
                                    <v-icon class="pr-2">mdi-pencil</v-icon>Edit Title & Description<br>
                                </v-list-item>
                                <v-list-item v-if="isUsersPost()" @click.stop="$store.dispatch('downloadVttFile', {post: postData, audio: audioData}), menu = !menu"><v-icon class="pr-2">mdi-file-download</v-icon>Download VTT</v-list-item>
                                <v-list-item v-if="isUsersPost()" @click.stop="updateAnonymousMode(), menu = !menu">
                                    <v-icon class="pr-2">{{postData.anonymousMode ? 'mdi-incognito-circle' : 'mdi-incognito-circle-off'}}</v-icon>Anonymous Mode: {{postData.anonymousMode ? "ON" : "OFF"}}<br>
                                </v-list-item>
                                <!-- <v-list-item @click.stop="viewAdvancedControls = true, menu = !menu">
                                    <v-icon class="pr-2">mdi-tune</v-icon>Advanced Controls<br>
                                </v-list-item> -->
                            </v-list>
                        </v-menu>
                        <Report v-if="reportDialog" :dialog="reportDialog" page="viewPost" :post="postData" @close-report="reportDialog = false"/>
                        <UploadPhotoDialog v-if="uploadPhotoDialog" :userId="userData.userId" :dialog="uploadPhotoDialog" :postType="postData.postType" :postId="postData.postId" :existingThumbnail="(postData.thumbnail != '' && postData.thumbnail != null) " @close-dialog="uploadPhotoDialog = false" @thumbnail-upload-success="thumbnailUploadSuccess"/>
                        <ShareDialog v-if="showShareDialog" :dialog="showShareDialog" :postData="postData" @close-dialog="showShareDialog = false"/>
                    </v-col>
                </v-row>
                <div v-if="viewFullDescription" style="position: relative; max-width: 100%;" class="full-width">
                    <v-row v-if="letUserSeePost" justify="start" align="center" no-gutters class="my-3">
                        <router-link v-if="postData.userId === currentUserId" :to="{ path: 'profile' }" class="text-decoration-none accent--text">
                            <p class="subtitle-2 font-weight-light font-italic mb-0 py-0 accent--text"> @{{postData.username ? postData.username : 'Unknown' }}</p>
                        </router-link>
                        <router-link v-else :to="{name: 'view-user-profile', query: {id: postData.userId}}" class="text-decoration-none accent-text">
                            <p class="subtitle-2 font-weight-light font-italic mb-0 py-0 accent--text"> @{{postData.username ? postData.username : 'Unknown' }}</p>
                        </router-link>
                        <span class="px-2">|</span>
                        <p class="subtitle-2 font-weight-light mb-0 py-0">{{postData.postDate}}</p>
                    </v-row>

                    <v-row v-if="letUserSeePost" justify="start" align="center" no-gutters>
                        <span v-if="!editPost" style="max-width: 100%; overflow-x: hidden;" class="subtitle-1 text--secondary font-weight-light">{{postData.description}}</span>
                        <v-textarea v-else ref="editDescription" v-model="postData.description" outlined style="max-width: 100%; overflow-x: hidden;" class="subtitle-1 font-weight-light" :counter="750" :rules="descriptionRules" @update:error="postMetadataError = true" @change="postMetadataHasChanged = true"></v-textarea>
                    </v-row>


                    <v-row v-if="letUserSeePost && !editPost" justify="space-between" align="center" no-gutters class="my-2">
                        <v-col cols="12" xl="4" lg="5" md="5" sm="5" class="my-md-0 my-1">
                            <!-- @change="playbackStyleChanged()" -->
                            <!-- commented out by Scott Brady on May 9, 2023. Look at function below for details. -->
                            <v-radio-group v-model="playbackStyle" label="Playback:" row dense hide-details class="my-0">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-radio class="hidden-sm-and-down" v-on="on" label="Continuous" value="continuous"></v-radio>
                                        <v-radio class="hidden-md-and-up" label="Continuous" value="continuous"></v-radio>
                                    </template>
                                    <span>Play the captions and audio without the video pausing</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-radio class="hidden-sm-and-down" v-on="on" label="Pause/Play" value="pauseplay"></v-radio>
                                        <v-radio class="hidden-md-and-up" label="Pause/Play" value="pauseplay"></v-radio>
                                    </template>
                                    <span>The video will pause while the captions and audio play</span>
                                </v-tooltip>
                            </v-radio-group>
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="2" sm="2" class="my-md-0 my-1">
                            <v-row :justify="isMobile ? 'start' : 'center'" align="center" class="ma-0 pa-0">
                                <span class="mr-2 text-subtitle-2 font-weight-light">Captions:</span>
                                <v-switch v-model="showCaptions" color="primary" :label="showCaptions ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                        </v-col>

                        <v-col cols="12" xl="5" lg="4" md="5" sm="5" class="my-md-0 my-1">
                            <v-slider
                                v-model="captionVolume"
                                label="Caption Audio"
                                inverse-label
                                :prepend-icon="captionVolume > 0 ? 'mdi-volume-medium' : 'mdi-volume-variant-off'"
                                @click:prepend="adjustSegmentVolume('mute')"
                                @click:append="adjustSegmentVolume(1.0)"
                                min="0"
                                max="1"
                                step="0.1"
                                v-on:end="adjustSegmentVolume"
                                v-on:click="adjustSegmentVolume(captionVolume)"
                                dense
                                hide-details
                            ></v-slider>
                            <!-- <span class="text-caption">Caption Audio</span> -->
                        </v-col>
                    </v-row>
                    <v-row v-if="isMobile" justify="end" align="center" no-gutters class="mb-5 mt-2 px-5">
                        <v-col cols="12" xl="7" lg="11" md="3" sm="4" align="center">
                            <v-autocomplete v-if="filter.languageCodes.length > 1 && !audioLoading" v-model="filter.languageSelected" :items="filter.languageCodes" label="Filter by language" append-icon="mdi-filter-menu" hide-details class="children-cursor--pointer"/>
                            <v-skeleton-loader v-else type="text@2" class="pa-2"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="!viewFullDescription && !editPost" v-bind:class="[$vuetify.theme.dark ? 'faded-dark' : 'faded']"></div>
                <div v-if="!editPost" style="width: 100%;" class="d-flex justify-space-around align-center">
                    <v-divider/>
                    <v-btn text x-small :ripple="false" color="primary" @click="viewFullDescription = !viewFullDescription">
                        <v-icon color="primary">{{ viewFullDescription ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                        {{ viewFullDescription ? 'View Less' : 'View More'}}
                        <v-icon color="primary">{{ viewFullDescription ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                    </v-btn>
                    <v-divider/>
                </div>
                <v-row v-if="editPost && letUserSeePost" justify="center" no-gutters>
                    <v-btn text color="primary" :disabled="editPostMetadataLoading" :loading="editPostMetadataLoading" @click.stop="editPostMetadata">save</v-btn>
                    <v-btn text color="red" :disabled="editPostMetadataLoading" @click.stop="editPost = false">cancel</v-btn>
                </v-row>
            </div>
        </v-col>

        <!-- View Advanced Controls --> 
        <v-col v-if="viewAdvancedControls" cols="12" xl="3" lg="4" md="12" sm="12" :style="{maxHeight: getMaxHeightMainContainer}" style="max-height: 100vh; overflow-y: hidden; overflow-x: hidden;" class="bordered mt-5 mt-xl-0 mt-lg-0">
            <!-- Advanced Controls Title -->
            <v-row justify="space-between" align="center" no-gutters class="mx-0 py-2">
                <span style="font-size: 1.5em" class="ml-2"><v-icon class="mx-2">mdi-tune</v-icon>Advanced Controls</span>
                <v-btn icon :ripple="false" class="mr-2" @click="viewAdvancedControls = false"><v-icon size="2em">mdi-close</v-icon></v-btn>
            </v-row>
            <v-divider/>
            <!-- END Advanced Controls Title -->

            <!-- Advanced Controls Control Buttons -->
            <v-row justify="center" align="center" no-gutters class="mt-15 px-5 py-10 blue-border">
                <v-col cols="12" align="center" class="red-border">
                    <span style="font-size: 25px">{{ currentAudioElement != null ? currentAudioElement.audioData.title : 'Title' }}</span><br/>
                    <span style="font-size: 15px">@{{ currentAudioElement != null ? currentAudioElement.audioData.username : 'username' }}</span>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" no-gutters class="ma-0 px-5 py-10 blue-border">
                <v-col cols="12" align="center" class="red-border">
                    <v-btn icon x-large @click.stop="advancedControlsClick('previous')"><v-icon>mdi-skip-previous</v-icon></v-btn>
                    <v-btn icon x-large @click.stop="advancedControlsClick('replay')"><v-icon>mdi-skip-backward</v-icon></v-btn>
                    <!-- <v-btn icon large @click.stop="advancedControlsClick('replay')"><v-icon>mdi-replay</v-icon></v-btn> -->
                    <!-- <v-btn icon large @click.stop="advancedControlsClick('rewind')"><v-icon>mdi-undo</v-icon></v-btn> -->
                    <v-btn icon large @click.stop="advancedControlsClick('rewind')"><v-icon>mdi-rewind-10</v-icon></v-btn>
                    <v-btn icon x-large :ripple="false" @click.stop="advancedControlsClick(videoIsPlaying ? 'pause' : 'play')"><v-icon size="3.5em">{{videoIsPlaying ? 'mdi-pause' : 'mdi-play'}}</v-icon></v-btn>
                    <v-btn icon large @click.stop="advancedControlsClick('forward')"><v-icon>mdi-fast-forward-10</v-icon></v-btn>
                    <!-- <v-btn icon large @click.stop="advancedControlsClick('forward')"><v-icon>mdi-redo</v-icon></v-btn> -->
                    <!-- <v-btn icon large style="transform: scaleX(-1)" @click.stop="advancedControlsClick('skip')"><v-icon>mdi-replay</v-icon></v-btn> -->
                    <v-btn icon x-large @click.stop="advancedControlsClick('skip')"><v-icon>mdi-skip-forward</v-icon></v-btn>
                    <v-btn icon x-large @click.stop="advancedControlsClick('next')"><v-icon>mdi-skip-next</v-icon></v-btn>
                </v-col>
            </v-row>
            <!-- END Advanced Controls Control Buttons -->

            <!-- Advanced Controls Options -->
            <v-row v-if="!advancedControlsOptionsClicked" justify="center" align="center" no-gutters class="ma-0 px-5 py-10 blue-border">
                <v-col cols="2" align="center" class="red-border">
                    <v-btn icon large :ripple="false" @click.stop="advancedControlsClick('captions')"><v-icon>{{showCaptions ? 'mdi-closed-caption' : 'mdi-closed-caption-outline'}}</v-icon></v-btn><br/>
                    <span style="font-size: 0.70em" class="ma-0 pa-0">captions</span>
                </v-col>
                <v-col cols="2" align="center" class="red-border">
                    <v-btn icon large :ripple="false" @click.stop="advancedControlsClick('playbackStyle')"><v-icon>mdi-cog-play</v-icon></v-btn><br/>
                    <span style="font-size: 0.65em" class="ma-0 pa-0">playback style</span>
                </v-col>
                <v-col cols="2" align="center" class="red-border">
                    <v-btn icon large :ripple="false" @click.stop="advancedControlsClick('volume')">
                        <v-icon>{{ (captionVolume <= 0) ? 'mdi-volume-off' : (captionVolume > 0 && captionVolume <= 0.3) ? 'mdi-volume-low' : (captionVolume > 0.3 && captionVolume <= 0.7) ? 'mdi-volume-medium' : 'mdi-volume-high' }}</v-icon>
                    </v-btn><br/>
                    <span style="font-size: 0.70em" class="ma-0 pa-0">volume</span>
                </v-col>
                <v-col cols="2" align="center" class="red-border">
                    <v-btn icon large :ripple="false" @click.stop="advancedControlsClick('speed')"><v-icon>mdi-play-speed</v-icon></v-btn><br/>
                    <span style="font-size: 0.70em" class="ma-0 pa-0">speed</span>
                </v-col>
                <v-col cols="2" align="center" class="red-border">
                    <v-btn icon large :ripple="false" @click.stop="advancedControlsClick('replay')"><v-icon>mdi-replay</v-icon></v-btn><br/>
                    <!-- <br/><span style="font-size: 0.75em">replay</span> -->
                    <span style="font-size: 0.65em" class="ma-0 pa-0">replay segment</span>
                </v-col>
            </v-row>


            <v-row v-else justify="center" no-gutters style="position: relative;" class="ma-0 px-5 py-10 blue-border">
                <v-btn icon :ripple="false" style="position: absolute; top: 0; right: 0;" @click="closeAdvancedOptionsClicked"><v-icon size="2em">mdi-close</v-icon></v-btn>
                <v-col v-if="advancedControl == 'volume'" cols="12" align="center" class="red-border">
                    <span>Caption Audio Volume</span><br/>
                    <!-- :prepend-icon="captionVolume > 0 ? 'mdi-volume-medium' : 'mdi-volume-variant-off'" -->
                    <!-- v-on:click="adjustSegmentVolume(captionVolume)" -->
                    <v-slider
                        v-model="captionVolume"
                        v-on:end="adjustSegmentVolume"
                        dense
                        hide-details
                        min="0"
                        max="1"
                        step="0.1"
                        :ripple="false"
                        :prepend-icon="(captionVolume <= 0) ? 'mdi-volume-off' : (captionVolume > 0 && captionVolume <= 0.3) ? 'mdi-volume-low' : (captionVolume > 0.3 && captionVolume <= 0.7) ? 'mdi-volume-medium' : 'mdi-volume-high'"
                        class="px-2"
                        @click:prepend="adjustSegmentVolume('mute')"
                        @click:append="adjustSegmentVolume(1.0)"
                    ></v-slider>
                </v-col>

                <v-col v-else-if="advancedControl == 'playbackStyle'" cols="12" align="center" class="red-border">
                    <span>Caption Playback Style</span><br/>
                    <v-radio-group v-model="playbackStyle" row dense hide-details class="ma-0 green-border" @click.native.stop="delayAdvancedOptionsClick">
                        <v-row justify="space-around" no-gutters>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-radio class="hidden-sm-and-down" v-on="on" label="Continuous" value="continuous" on-icon="mdi-autorenew" off-icon="mdi-autorenew"></v-radio>
                                <v-radio class="hidden-md-and-up" label="Continuous" value="continuous"></v-radio>
                                <!-- <v-icon>mdi-dots-circle</v-icon> -->
                                <!-- <v-icon>mdi-rotate-right</v-icon> -->
                                <!-- <v-icon>mdi-autorenew</v-icon> -->
                            </template>
                            <span>Play the captions and audio without the video pausing</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-radio class="hidden-sm-and-down" v-on="on" label="Pause/Play" value="pauseplay" on-icon="mdi-play-pause" off-icon="mdi-play-pause"></v-radio>
                                <v-radio class="hidden-md-and-up" label="Pause/Play" value="pauseplay"></v-radio>
                            </template>
                            <span>The video will pause while the captions and audio play</span>
                        </v-tooltip>
                        </v-row>
                    </v-radio-group>
                </v-col>

                <v-col v-else-if="advancedControl == 'speed'" cols="12" align="center" class="red-border">
                    <span>Playback Speed</span><br/>
                    <v-radio-group v-model="playbackRate" row dense hide-details class="ma-0">
                        <v-row justify="space-around" no-gutters>
                            <v-radio label="0.5x" :value="0.50"></v-radio>
                            <v-radio label="0.75x" :value="0.75"></v-radio>
                            <v-radio label="1.0x" :value="1.0"></v-radio>
                            <v-radio label="1.5x" :value="1.5"></v-radio>
                            <v-radio label="2.0x" :value="2.0"></v-radio>
                        </v-row>
                    </v-radio-group>
                </v-col>
            </v-row>
            <!-- END Advanced Controls Options -->
        </v-col>
        <!-- END - View Advanced Controls -->

        <v-col v-else cols="12" xl="3" lg="4" md="12" sm="12" :style="{maxHeight: getMaxHeightMainContainer}" style="max-height: 100vh; overflow-y: hidden; overflow-x: hidden;" class="bordered mt-5 mt-xl-0 mt-lg-0">

            <!-- we want to see if there are more than 2 in the array because we start with one ('all'), and only want to filter if there are more than 1 language in the audiodata. -->
            <v-row justify="end" align="center" no-gutters class="mx-0 mt-2 px-5">
                <v-col v-if="!isMobile" cols="12" xl="7" lg="11" md="3" sm="4" align="center">
                    <v-autocomplete v-if="filter.languageCodes.length > 1 && !audioLoading" v-model="filter.languageSelected" :items="filter.languageCodes" label="Filter by language" append-icon="mdi-filter-menu" hide-details class="children-cursor--pointer"/>
                    <v-skeleton-loader v-else type="text@2" class="pa-2"></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mx-0 mb-2 px-5">
                <v-divider/>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="addNewAudio()" depressed color="primary" :disabled="audioLoading" class="hidden-sm-and-down">Contribute
                            <v-icon right>mdi-plus</v-icon>
                            <!-- <v-icon right>mdi-microphone-plus</v-icon> -->
                        </v-btn>
                        <v-btn @click.stop="addNewAudio()" depressed color="primary" :disabled="audioLoading" style="max-width: 60%;" class="hidden-md-and-up">Contribute
                            <v-icon right>mdi-plus</v-icon>
                            <!-- <v-icon right>mdi-microphone-plus</v-icon> -->
                        </v-btn>
                    </template>
                    <span>Add your own audio</span>
                </v-tooltip>
                <v-divider/>
            </v-row>

            <v-row :style="{maxHeight: getMaxHeightSubContainer}" style="max-height: 100vh; overflow-y: scroll;" class="bordered mx-0 px-5">
                <v-row v-if="audioLoading" class="my-0 py-0 hidden-lg-and-up hidden-xs-only">
                    <v-col v-for="i in 3" :key="i" cols="6" class="my-0 pt-0 px-2 pb-5 hidden-lg-and-up hidden-xs-only">
                        <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                    </v-col>
                    <v-col v-for="i in 3" :key="i+3" cols="6" class="my-0 pt-0 px-2 pb-5 hidden-lg-and-up hidden-xs-only">
                        <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                    </v-col>
                </v-row>

                <v-col v-if="leftAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pl-0 py-0 justify-end">
                    <v-container fluid v-for="audio in leftAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                        <AudioElement
                            v-if="audio.audioData.audio.length > 0 && !audioLoading"
                            :audioObject="audio"
                            :postAudioDataId="postData.postAudioDataId"
                            :postOwner="postData.userId"
                            :userData="userData"
                            :selectedAudioId="selectedAudioId"
                            :isGroupAdmin="isGroupAdmin"
                            :postType="postType"
                            :postData="postData"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="postData.anonymousMode || false"
                            @delete-selected-contribution="deleteDialog"
                            @audioChanged="changeAudioElement"
                            @changeAudioElement="changeAudioSegment"
                            @edit-caption="editCaption"
                            @saveEditContributionTitle="saveEditContributionTitle"
                        />
                    </v-container>
                </v-col>

                <v-col v-if="rightAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pr-0 py-0 justify-end">
                    <v-container fluid v-for="audio in rightAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                        <AudioElement
                            v-if="audio.audioData.audio.length > 0 && !audioLoading"
                            :audioObject="audio"
                            :postAudioDataId="postData.postAudioDataId"
                            :postOwner="postData.userId"
                            :userData="userData"
                            :selectedAudioId="selectedAudioId"
                            :isGroupAdmin="isGroupAdmin"
                            :postType="postType"
                            :postData="postData"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="postData.anonymousMode || false"
                            @delete-selected-contribution="deleteDialog"
                            @audioChanged="changeAudioElement"
                            @changeAudioElement="changeAudioSegment"
                            @edit-caption="editCaption"
                            @saveEditContributionTitle="saveEditContributionTitle"
                        />
                    </v-container>
                </v-col>

                <v-col v-if="audioLoading" cols="12" class="hidden-md-only hidden-sm-only">
                    <div v-for="i in 5" :key="i" class="my-0 pt-0 px-0 pb-5">
                        <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                    </div>
                </v-col>

                <!-- Commented out by Scott Brady on July 10, 2024, along with the corresponding code below (ie import statement, component registration and computed property). -->
                <!-- This code was used to test vue-virtual-scrollers functionality in our site. It did not work, but with more time and effort we may be able to get it to work. -->
                <!-- If not, we have to write our own virtual scroller for the situation of using our own component, but more specifically, have it extend to show more content. -->
                <!-- vue-virtual-scroller documentation: https://github.com/Akryum/vue-virtual-scroller/blob/master/packages/vue-virtual-scroller/README.md -->
                <!-- <DynamicScroller :items="items" :key-field="'audioDataId'" :min-item-size="125" v-slot="{ item, index }" @update:visible-items="onVisibleItemsChange">
                    <v-col :key="index" cols="12" class="hidden-md-only hidden-sm-only my-0 pt-0 px-0 pb-5">
                        <AudioElement
                            v-if="item.audioData.audio.length > 0 && !audioLoading"
                            :audioObject="item"
                            :postAudioDataId="postData.postAudioDataId"
                            :postOwner="postData.userId"
                            :userData="userData"
                            :selectedAudioId="selectedAudioId"
                            :isGroupAdmin="isGroupAdmin"
                            :postType="postType"
                            :postData="postData"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="postData.anonymousMode || false"
                            @delete-selected-contribution="deleteDialog"
                            @audioChanged="changeAudioElement"
                            @changeAudioElement="changeAudioSegment"
                            @edit-caption="editCaption"
                            @saveEditContributionTitle="saveEditContributionTitle"
                        />
                    </v-col>
                </DynamicScroller> -->

                <v-col v-for="audio in languageFilteredAudio" :key="audio.audioDataId" cols="12" class="hidden-md-only hidden-sm-only my-0 pt-0 px-0 pb-5">
                    <AudioElement
                        v-if="audio.audioData.audio.length > 0 && !audioLoading"
                        :audioObject="audio"
                        :postAudioDataId="postData.postAudioDataId"
                        :postOwner="postData.userId"
                        :userData="userData"
                        :selectedAudioId="selectedAudioId"
                        :isGroupAdmin="isGroupAdmin"
                        :postType="postType"
                        :postData="postData"
                        :videoLoading="videoLoading"
                        :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                        :anonymousMode="postData.anonymousMode || false"
                        @delete-selected-contribution="deleteDialog"
                        @audioChanged="changeAudioElement"
                        @changeAudioElement="changeAudioSegment"
                        @edit-caption="editCaption"
                        @saveEditContributionTitle="saveEditContributionTitle"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import fb from "firebase/compat/app";
import firebase from "../../firebaseConfig.js";
import Alert from "./Alert.vue";
import VideoPlayer from "./VideoPlayer";
import AudioElement from "./AudioElement.vue";
import Report from "./Report.vue";
import UploadPhotoDialog from "./UploadPhotoDialog.vue";
import ShareDialog from "./ShareDialog.vue";
// import VTT from "./VttDownload.vue";
// import { DynamicScroller, RecycleScroller } from 'vue-virtual-scroller';

export default {
    name: "view-post",
    metaInfo() {
        return {
            title: typeof this.postData.title != 'undefined' ? `${this.postData.title} - tawq.in` : 'View Post - tawq.in',
        };
    },
    props: ["postId", "postType"],
    components: {
        Alert,
        VideoPlayer,
        AudioElement,
        Report,
        UploadPhotoDialog,
        ShareDialog,
        // VTT,
        // DynamicScroller,
        // RecycleScroller,
    },
    data: function () {
        return {
            // Dialog pop ups
            deleteContributionDialog: false,
            showShareDialog: false,
            reportDialog: false,
            uploadPhotoDialog: false,
            vttDialog: false,
            // END Dialog pop ups
            audioToDelete: null,
            menu: false,
            titleRules: [(v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be 50 characters or less",],
            descriptionRules: [(v) => !!v || "Description is required", (v) => (v && v.length <= 750) || "Description must be 750 characters or less",],
            postMetadataHasChanged: false,
            postMetadataError: false,
            editPost: false,
            editPostMetadataLoading: false, // used to set editPostMetadata to a loading value so the user knows it is loading.
            letUserSeePost: false, // July 11, 2023: changed to false so that a user won't see a group post until we have confirmed they are a member of the group. This also mean a user will not see a public post until we have confirmed it is not a group post.
            viewLoading: true, // set to true on July 11, 2023. viewLoading will only be true on initial load, this solves the issue of VideoPlayer disappearing when updating metadata or anonymousMode.
            audioLoading: true,
            postData: {},
            selectedAudioId: null, // used to highlight (outline) the currently playing AudioElement
            currentUserId: firebase.auth.currentUser.uid,
            currentAudioElement: null,// this will be the currently selected AudioElement.
            currentAudioSegment: null,// this will be the current segment loaded into the audioPlayer.
            nextAudioSegment: null,// this will be the next audio segment to be loaded into the audioPlayer.
            vidOptions: {
                autoplay: false,
                controls: false,
                // controls: true,
                responsive: true,
                sources: {},
                techOrder: ["youtube", "html5"],
                textTrackSettings: false,
                html5: {
                    nativeTextTracks: false,
                    nativeControlsForTouch: false,
                    nativeAudioTracks: false,
                },
                youtube: {
                    enablejsapi: 1,
                    fs: 0,
                    playsinline: 1,
                    cc_load_policy: 0, // YouTube auto captions - OFF
                    // cc_load_policy: 1, // YouTube auto captions - ON
                    disablekb: 1,
                    ytControls: 0, // Disable YouTube control bar
                    // ytControls: 1, // Enable YouTube control bar
                    // origin: "https://tawq.in" || window.location.orgin, // maybe can use this instead of having to comment out below for testing?
                    // origin: "https://tawq.in", // uncomment for deploy to dev/main
                    origin: window.location.origin, // uncomment for testing
                    modestbranding: 1,
                    start: 0,
                    // widget_referrer: "https://tawq.in",
                },
            },
            audioData: [],
            // showCaptions was added to code on May 21, 2024 by Scott Brady. Any user who created an account before then will not have it in their userData unless they have opened their Settings.
            showCaptions: (this.$store.state.userData.showCaptions !== undefined && this.$store.state.userData.showCaptions !== null) ? this.$store.state.userData.showCaptions : true, // if showCaptions does not exist set to true. Only used in computed property of VideoPlayer.vue to show/hide captions
            playbackRate: 1.0,
            playbackStyle: this.$store.state.userData.playbackStyle ? 'continuous' : 'pauseplay',
            // playbackStyleAudioFirst: false,
            // When playbackStyle is 'pauseplay', this determines whether the audio plays before or after the video. IE if true, when audio segment start is reached, video will pause and audio will play. If false, when audio segment start + duration is reached, video will pause and audio will play.
            playbackStyleAudioFirst: (typeof this.$store.state.userData.playbackStyleAudioFirst === 'undefined') ? true : this.$store.state.userData.playbackStyleAudioFirst, // if audio first value does not exist set to default value of true.
            // playbackStyleSameStart: false,
            // When playbackStyle is 'continuous', this determines whether the audio will be cut off or not. IE if true, when audio segment duration overlaps next segment audio start, it will continue to play. If false, when audio segment duration overlaps next segment audio start, audio will cut off and play next audio segment.
            playbackStyleSameStart: (typeof this.$store.state.userData.playbackStyleSameStart === 'undefined') ? true : this.$store.state.userData.playbackStyleSameStart, // if same start value does not exist set to default value of true.
            audioPlayer: new Audio(),
            preloadAudioPlayer: new Audio(),

            // viewAdvancedControls: true, // this is used to pop up  the dialog that allows the user to use advanced controls (includes: playback style, turning on/off captions, caption audio volume, time skipping forward/backward, speed of video/audio playback, etc.). Added by Scott Brady on October 9, 2024
            viewAdvancedControls: false, // this is used to pop up  the dialog that allows the user to use advanced controls (includes: playback style, turning on/off captions, caption audio volume, time skipping forward/backward, speed of video/audio playback, etc.). Added by Scott Brady on October 9, 2024
            advancedControlsOptionsClicked: false, // this is used to show selectable options for advanced controls (playback style, volume, speed, etc.)
            delayAdvancedOptionsClickTimer: null,
            rewindForwardTimer: null,
            advancedControl: '',
            videoIsPlaying: false, //

            captionVolume: this.$store.state.userData.captionAudio ? 1.0 : 0.0,
            viewFullDescription: false,
            descriptionWordCount: 0,
            // scrollOptions: { // commented out on July 27, 2023. Not sure what this does!
            //     duration: 100,
            //     offset: 0,
            //     easing: 'easeInOutCubic'
            // },
            filter: {
                languageSelected: "All",
                languageCodes: ["All"],
            },
            postViewedCounter: null,
            isGroupAdmin: null,
            videoLoading: true, // Used to put the video player in, or take out of, a loading state. When true the vjs-loading-spinner will show, else it's hidden.
            audioElementHasBegunPlaying: false,
            audioCanPlay: false, // Used to make sure audio is ready to play when marker is reached.
            audioElementOrSegmentClicked: false,
        };
    },
    methods: {
        deleteDialog: function (audioObject) {
            this.deleteContributionDialog = true;
            this.audioToDelete = audioObject;
        },
        deleteContribution: async function(val){
            if(!val) {
                this.deleteContributionDialog = false;
            } else {
                /* delete metadata first, then the audio file */
                this.deleteAudioMetadata(this.audioToDelete.audioData.userId,this.audioToDelete.audioDataId);
                this.deleteAudioFilesFromStorage(this.audioToDelete.audioDataId);
                this.deleteContributionDialog = false;
            }
        },
        triggerFileUpload: function(){
            if (!this.isUsersPost()){
                this.$store.commit('alertUser', { show: true, text: `You do not have permissions to upload an image for this post.`, type: 'snackbar' });
                return;
            }
            this.uploadPhotoDialog = true;
        },
        updateAnonymousMode: async function () {
            firebase.db.collection(this.postType === 'group' ? 'groupPosts' : 'publicPosts').doc(this.postId).update({ anonymousMode: !this.postData.anonymousMode }, {merge: true}).then(()=>{
                this.postType === 'group' ? this.getGroupPostData() : this.getPostData();
            }).catch((error)=>{
                console.error("error: ", error);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong updating anonymousMode ${error}`, type: 'snackbar' });
            });
        },
        isUsersPost: function() {
            return this.postData.userId == firebase.auth.currentUser.uid;
        },
        editPostMetadata: function () {
            if (this.postMetadataHasChanged && this.$refs.editDescription.valid && this.$refs.editTitle.valid) {
                this.editPostMetadataLoading = true;
                this.audioLoading = true;
                // this.editPost = false; // October 25, 2023 - moved this further down in the code so that it will show loading while metadata updates
                this.$http.put(`/post/metadata/${this.postType}/${this.postId}`, {
                    description: this.postData.description,
                    title: this.postData.title,
                    audioDataId: this.postData.postAudioDataId,
                    pinnedPost: (this.postType == 'public' && this.postData.pinnedPost) ? this.postData.pinnedPost : false,
                }).then((/*response*/) => {
                    this.$store.commit('alertUser', { show: true, text: `Successfully Changed Post Metadata.`, type: 'snackbar' });
                    if(this.postData.postType == "public"){
                        this.getPostData();
                    } else {
                        this.getGroupPostData();
                    }
                    this.getPostAudioData(); // retrieve the updated postAudioData audioSegment (the main one for the post);
                    this.editPost = false;
                }).catch((error) => {
                    console.error("Error updating post metadata", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Error Changing Post.`, type: 'snackbar' });
                    this.editPostMetadataLoading = false;
                    this.audioLoading = false;
                    this.editPost = false;
                });
            } else {
                this.editPost = false;
            }
        },
        // permissionToDelete: function() { // commented out on July 27, 2023. Not sure this is being used.
        //     if (this.isUsersPost() && !this.homefeed){
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
        thumbnailUploadSuccess: function(){
            this.postType === 'group' ? this.getGroupPostData() : this.getPostData();
            this.$store.commit('alertUser', { show: true, text: `Successfully changed post thumbnail.`, type: 'snackbar' });
        },
        deleteAudioFilesFromStorage: function (contributionId) { // delete the audiofile from audioSegments/postId/audioDataId
            const audioDataRef = firebase.storage.ref(`audioSegments/${this.postData.postId}/${contributionId}`);
            audioDataRef.list().then((res) => {
                res.items.forEach(function (itemRef) {
                    /* retrieve the file names of each segment, so that we can delete each file
                    individually from the storage bucket. GCS doesn't allow us to delete the full bucket */
                    let filename = itemRef.name;
                    let audioRef = audioDataRef.child(filename);
                    audioRef.delete().then(() => {
                        return true;
                    }).catch(function (error) {
                        console.error(`Error Deleting Audio Segment File ${filename} from Storage: `, error);
                        return false;
                    });
                });
            });
        },
        deleteAudioMetadata: function (userId, contributionId) {
            const postRef = firebase.db.collection(this.postData.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postId);
            const contributionsRef = postRef.collection("contributions").doc(userId);
            const analyticsRef = firebase.db.collection("admin").doc("analytics");

            contributionsRef.update({audioDataId: fb.firestore.FieldValue.arrayRemove(contributionId),}).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Error Deleting Contribution.`, type: 'snackbar' });
                console.error("Error removing audio metadata in posts ref", error);
            });

            postRef.update({"postAnalytics.total_contributions": fb.firestore.FieldValue.increment(-1)});

            analyticsRef.update({total_contributions: fb.firestore.FieldValue.increment(-1)}).catch((err) => {
                console.log("Error updating the total contribution field in the analytics ref.", err);
            });

            // delete file from audioSegments/audioData
            const audioSegmentsRef = firebase.db.collection("audioSegments").doc(contributionId);
            audioSegmentsRef.delete().then(() => {
                this.$store.commit('alertUser', { show: true, text: `Successfully deleted contribution.`, type: 'snackbar' });
                this.getAudioData();
            }).catch((error) => {
                console.error("Error removing the audioSegments document: ", error);
            });
        },
        editCaption: function (caption) {
            let audioSegmentsRef = firebase.db.collection("audioSegments").doc(caption.audioSegId);
            audioSegmentsRef.get().then((snapshot) =>{
                if(snapshot.exists){
                    var subtitles = snapshot.data().subtitles;
                    snapshot.data().subtitles.forEach((subtitle, index) => {
                        if(subtitle.audioSegId == caption.caption.audioSegId){
                            if (this.postType === 'group') {
                                subtitles[index] = {subtitle: caption.caption.subtitle, audioSegId: subtitle.audioSegId, audioSegFeedback: caption.caption.audioSegFeedback};
                            } else {
                                subtitles[index] = {subtitle: caption.caption.subtitle, audioSegId: subtitle.audioSegId};
                            }
                        }
                    });
                    audioSegmentsRef.update({ subtitles :  subtitles}, {merge: true});
                }
            })
        },
        saveEditContributionTitle: async function (audioObject) {
            let audioSegmentsRef = firebase.db.collection("audioSegments").doc(audioObject.audioObject.audioDataId);
            let res = await audioSegmentsRef.update({title: audioObject.audioObject.audioData.title}, {merge: true});
            // The above response variable, res, returns undefined, but might need to be used to make sure the function
            // is done running and saves the data correctly. For now it should work just fine.
        },
        addNewAudio: function () {
            this.$router.push({
                name: "new-contribution",
                query: {
                    pid: this.postData.postId,
                    postType: this.postData.postType,
                },
                params: { audioData: this.audioData, },
            });
        },
        loadAndPreloadFirstAudioData: async function () {
            // This function is called on initial load of the page and anytime a new AudioElement is clicked.
            // It will load the first AudioSegment into the audioPlayer and if playbackStyle is pauseplay, it
            // will load in the second AudioSegment, otherwise, playbackStyle is continuous, and it will load
            // in the next, or one available after the overlapping segments.

            // load first AudioSegment into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[0].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[0];
            this.currentAudioSegment.index = 0;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[0].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[0].audioSegId;
            (this.currentAudioSegment.audioStart == 0) && (this.audioElementHasBegunPlaying = true);

            if (this.currentAudioElement.audioData.audio.length > 1) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous' && this.playbackStyleSameStart) {
                    // check for overlaps and return the next available AudioSegment index.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(0,1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                }/* else if (this.playbackStyle === 'continuous' && !this.playbackStyleSameStart) {
                    console.log("do the new stuff, without overlaps.");
                }*/ else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[1];
                    this.nextAudioSegment.index = 1;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[1].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[1].audioSegId;
                }
                // this.preloadAudioPlayer.src = this.nextAudioSegment != null ? this.nextAudioSegment.audioURL : null;
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }
            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        onHasBegunPlaying: function (playing) {
            // console.log("hasBegunPlaying: ", playing);
            this.videoIsPlaying = playing;
            if (playing) this.audioElementHasBegunPlaying = true;
        },
        loadAndPreloadNextAudioData: async function () {
            // set this.preloadAudioPlayer.src (this.nextAudioSegment) to this.audioPlayer.src, since it is already preloaded it should be ready to go when set.
            this.audioPlayer.src = this.preloadAudioPlayer.src;
            this.audioPlayer.load();
            this.currentAudioSegment = this.nextAudioSegment;
            var nextAvailableIndexAfterOverlap;

            if (this.nextAudioSegment != null && (this.currentAudioElement.audioData.audio.length-1) >= (this.nextAudioSegment.index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous' && this.playbackStyleSameStart) {
                    // check for overlaps and return the next available AudioSegment index.
                    nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(this.nextAudioSegment.index, this.nextAudioSegment.index+1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        return;
                    }
                }/* else if (this.playbackStyle === 'continuous' && !this.playbackStyleSameStart) {
                    console.log("do the new stuff, without overlaps.");
                }*/ else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    nextAvailableIndexAfterOverlap = this.nextAudioSegment.index+1; // Check to see if index+1 is out of range, if so set this.nextAudioSegment to null
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                }
                this.preloadAudioPlayer.src = this.nextAudioSegment.audioURL;
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }
        },
        loadAndPreloadAudioDataAfterClick: async function (index) {
            // load AudioSegment at index into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[index].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[index];
            this.currentAudioSegment.index = index;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[index].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[index].audioSegId;

            if ((this.currentAudioElement.audioData.audio.length-1) >= (index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous' && this.playbackStyleSameStart) {
                    // check for overlaps and return the next available AudioSegment index, if available.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(index,(index+1));
                    if (nextAvailableIndexAfterOverlap == null) {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                }/* else if (this.playbackStyle === 'continuous' && !this.playbackStyleSameStart) {
                    console.log("do the new stuff, without overlaps.");
                }*/ else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[(index+1)];
                    this.nextAudioSegment.index = (index+1);
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[(index+1)].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[(index+1)].audioSegId;
                }
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }
            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        getNextAvailableIndexAfterOverlap: function (index, indexToCheck) {
            return new Promise( async (resolve) => {
                if (this.currentAudioElement.audioData.audio.length <= indexToCheck) {
                    resolve(null);
                } else {
                    if (this.currentAudioElement.audioData.audio[index].audioEnd < this.currentAudioElement.audioData.audio[indexToCheck].audioStart) {
                        resolve(indexToCheck);
                    } else {
                        let recursiveResult = await this.getNextAvailableIndexAfterOverlap(index, indexToCheck+1);
                        resolve(recursiveResult);
                    }
                }
            });
        },
        enableControls: function () {
            this.$refs.vidPlayer.getMarkers().length <= 0 && this.$refs.vidPlayer.placeMarkers(this.currentAudioElement.audioData.audio, this.currentAudioElement.audioData.subtitles, this.currentAudioElement.audioData.username)
        },
        pauseAudioPlayer: function (val) {
            // TODO: Figure out a way to solve the issue of not being able to pause/play audio, when in pauseplay mode!!!
            // Idea: if video paused and audio playing, end segment when clicked.
            if(this.audioPlayer.duration > 0 && !this.audioPlayer.paused) {
                if (this.playbackStyle == "continuous") {
                    this.audioPlayer.pause();
                }
            }
        },
        playAudioPlayer: function (val) {
            if (!this.audioPlayer.ended && this.audioPlayer.duration > 0 && this.audioPlayer.paused) {
                if(this.playbackStyle == "continuous") {
                    this.audioPlayer.play();
                } else {
                    // this.$refs.vidPlayer.playerPlay();
                    this.$refs.vidPlayer.playerPause();
                    this.audioPlayer.play();
                }
            } else if (!this.audioPlayer.ended && this.audioPlayer.duration > 0 && !this.audioPlayer.paused) {
                if(this.playbackStyle == "continuous") {
                    this.audioPlayer.play();
                } else {
                    this.$refs.vidPlayer.playerPause();
                    this.audioPlayer.pause();
                }
            }
        },
        addAudioPlayerEventListeners: function () {
            this.audioPlayer.addEventListener('canplaythrough', this.audioCanPlayThrough/*, {once: true}*/);
            this.audioPlayer.addEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        removeAudioPlayerEventListeners: function () {
            this.audioPlayer.removeEventListener("canplaythrough", this.audioCanPlayThrough);
            this.audioPlayer.removeEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        audioCanPlayThrough: function () {
            this.audioCanPlay = true;

            if (this.videoLoading) this.videoLoading = false;
            // When videoLoading is set to false here, the function videoLoading() in VideoPlayer.vue's watch property
            // gets fired. Which in turn hides the loadingSpinner, shows the bigPlayButton, if necessary, updates the
            // markers and sets player controls to true. When player.controls(true) the .on("controlsenabled") listener
            // gets fired. This will playerPlay(), play the video, the video player in VideoPlayer.vue and $emit("controlsEnabled") back
            // here to ViewPost.vue, which will call $refs.vidPlayer.placeMarkers();
        },
        audioEnded: function (/*event*/) {
            this.audioCanPlay = false;

            (this.playbackStyle === "pauseplay") && this.$refs.vidPlayer.playerPlay();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true);
            this.nextAudioSegment && this.loadAndPreloadNextAudioData();
            // this.nextAudioSegment ?? this.removeAudioPlayerEventListeners(); // July 27, 2023: keeping this line of code incase there are issues and this becomes necessary to uncomment.
            if (this.nextAudioSegment == null && (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length)) {
                this.currentAudioSegment = null;
                this.removeAudioPlayerEventListeners();
            }
        },
        timeUpdate: function (time) {
            // if (this.videoLoading || this.currentAudioSegment == null /*|| !this.audioPlayer.paused*/ /*|| (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)*/) return;
            if (this.playbackStyle != "continuous" && (this.videoLoading || this.currentAudioSegment == null || !this.audioPlayer.paused)) return;
            if (this.playbackStyle === "continuous" && this.playbackStyleSameStart && (this.videoLoading || this.currentAudioSegment == null || !this.audioPlayer.paused)) return;
            if (this.playbackStyle === "continuous" && !this.playbackStyleSameStart && (this.videoLoading || this.currentAudioSegment == null /*|| this.nextAudioSegment == null*/ /*|| !this.audioPlayer.paused*/)) return;
            // if (this.videoLoading || this.currentAudioSegment == null || !this.audioPlayer.paused /*|| (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)*/) return;
            if ((this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)) this.currentAudioSegment.index += 1;
            // if (Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3) {
            //     console.log("Segment:, ", this.currentAudioSegment);
            // }
            if (this.playbackStyle === "pauseplay" && this.playbackStyleAudioFirst && Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3) { // timeUpdate is called every 0.2 - 0.3 seconds, so we check for anything + or - 0.3
                // Audio first mode
                // When time hits segments audioStart, pause video and play audio.
                // Base case for pauseplay, what we originally had.

                // console.log("Audio First Mode");

                this.$refs.vidPlayer.playerPause();
                /* if (this.showCaptions) */this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), (this.currentAudioSegment.audioEnd ? this.currentAudioSegment.audioEnd : (this.currentAudioSegment.audioStart+this.currentAudioSegment.audioDur)), this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            } else if (this.playbackStyle === "pauseplay" && !this.playbackStyleAudioFirst && Math.abs((this.currentAudioSegment.audioStart + this.currentAudioSegment.audioDur) - time) <= 0.3) { // timeUpdate is called every 0.2 - 0.3 seconds, so we check for anything + or - 0.3
                // Video first mode
                // When time hits segments audioStart + audioDur, pause video and play audio.

                // console.log("Video First Mode: ", time);
                // console.log("segment: ", this.currentAudioSegment);

                this.$refs.vidPlayer.playerPause();
                // this.currentAudioSegment.audioStart,
                /* if (this.showCaptions) */this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), (this.currentAudioSegment.audioEnd ? this.currentAudioSegment.audioEnd : (this.currentAudioSegment.audioStart+this.currentAudioSegment.audioDur)), this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            } else if (this.playbackStyle === "continuous" && this.playbackStyleSameStart && ((Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3))) {
                // Audio starts the same.
                // When time hits segments audioStart, we play audio and video together, and overlap next audio segment if necessary.
                // Base Case for continuous, what we originally had.

                // console.log("Audio Starts The Same");

                /* if (this.showCaptions) */this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), (this.currentAudioSegment.audioEnd ? this.currentAudioSegment.audioEnd : (this.currentAudioSegment.audioStart+this.currentAudioSegment.audioDur)), this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            } else if (this.playbackStyle === "continuous" && !this.playbackStyleSameStart && ((Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3)/* || (Math.abs(this.nextAudioSegment.audioStart - time) <= 0.3)*/)) {
                /* if (this.showCaptions) */this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), (this.currentAudioSegment.audioEnd ? this.currentAudioSegment.audioEnd : (this.currentAudioSegment.audioStart+this.currentAudioSegment.audioDur)), this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            } else if (this.playbackStyle === "continuous" && !this.playbackStyleSameStart && this.nextAudioSegment != null && (/*(Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3) ||*/ (Math.abs(this.nextAudioSegment.audioStart - time) <= 0.3))) {
                // Audio ends the same.
                // When time hits segments audioStart, we play audio and video together, and cut audio off if it overlaps next audio segment. So next audio segment can play.
                if (!this.audioPlayer.paused) {
                    this.audioPlayer.pause();
                    this.audioEnded();
                }
            }
        },
        videoPlayerSeeked: function (time) {
            if (this.videoLoading/*|| this.currentAudioSegment.audioStart == 0 && this.audioPlayer.paused*/) return;
            if (this.audioElementOrSegmentClicked) return this.audioElementOrSegmentClicked = false;
            this.videoLoading = true;
            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            [this.currentAudioSegment, this.nextAudioSegment] = [null, null];

            // let nextAudioSegmentIndex = this.currentAudioElement.audioData.audio.findIndex((data) => Math.ceil(data.audioStart) >= Math.floor(time));
            let nextAudioSegmentIndex = this.currentAudioElement.audioData.audio.findIndex((data) => {
                if (this.playbackStyle === "pauseplay" && !this.playbackStyleAudioFirst) {
                    if (Math.abs((data.audioStart + data.audioDur) - time) <= 0.3) return true;
                    if ((data.audioStart + data.audioDur) >= time) return true;
                } else {
                    if (Math.abs(data.audioStart - time) <= 0.3) return true;
                    if (data.audioStart >= time) return true;
                }
            });
            if (nextAudioSegmentIndex == -1) {
                this.videoLoading = false;
                return;
            }
            this.loadAndPreloadAudioDataAfterClick(nextAudioSegmentIndex);
        },
        changeAudioSegment: function (data) {
            //  this function is ran only when a audioSegment is clicked. It will update the markers if necessary and playSelectedAudioSegment
            this.audioElementOrSegmentClicked = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause(); // if the player is NOT paused then pause, else nothing;
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();


            if (this.selectedAudioId === data.audioId) {
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart); // set the start time then jump to loadAndPreloadAudioData function
            } else {
                // formatAE refers to the new AudioElement data, updated to the correct format, that will be set to this.currentAudioElement
                let formatAE = {
                    audioData: data.audioData,
                    audioDataId: data.audioId,
                };
                this.currentAudioElement = formatAE;
                this.selectedAudioId = data.audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart);
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});

                this.audioElementHasBegunPlaying = false; // Do I need this???
            }
            this.loadAndPreloadAudioDataAfterClick(data.audioSeg.index);
        },
        changeAudioElement: function (audioData, audioId) {
            // This function is called Everytime the play button on an AudioElement is clicked.

            if (this.selectedAudioId === audioId && !this.audioElementHasBegunPlaying) return; // Video player was set to auto play after initial loading is done. By Scott Brady on May 10, 2023. This if statement shouldn't/rarely happen.

            this.audioElementOrSegmentClicked = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            if (this.selectedAudioId === audioId && this.audioElementHasBegunPlaying) {
                this.audioElementHasBegunPlaying = false;
                this.$refs.vidPlayer.playerSetTime(0.0);
            } else if (this.selectedAudioId != audioId && !this.audioElementHasBegunPlaying) {
                // This else if statement should only happen if the user presses the play button of an AudioElement right after initial
                // loading is done, but before auto play has started. Shouldn't happen very often, if at all!!!
                this.$refs.vidPlayer.removeMarkers();
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});
            } else {
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(0.0);
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});

                this.audioElementHasBegunPlaying = false;
            }
            this.loadAndPreloadFirstAudioData();
        },
        // markerReached and markerClicked were phased on in June/July of 2023 for a more efficient loading type of timeUpdate and videoPlayerSeeked, respectively.
        markerReached: function (/*marker, time*/) { // , index, time){
            return false;
        },
        markerClicked: function (/*marker*/){ // index, time){
            return false;
        },
        advancedControlsClick: function (advancedControl) {
            console.log("clicked: ", advancedControl);
            // console.log("audioElement: ", this.currentAudioElement.audioData);
            switch (advancedControl) {
                case 'play':
                    // play video
                    this.$refs.vidPlayer.playerPlay();
                    return;
                case 'pause':
                    // pause video
                    this.$refs.vidPlayer.playerPause();
                    return;
                case 'previous':
                    // restart current audio element
                    return;
                case 'replay':
                    // replay current audio segemnt
                    this.replayAudioSegment();
                    return;
                case 'rewind':
                    // rewind 10 seconds
                    var rewindTime = this.$refs.vidPlayer.playerGetTime() - 10;
                    if (rewindTime < 0) rewindTime = 0;
                    this.rewindForwardClicked(rewindTime);
                    return;
                case 'forward':
                    // forward 10 seconds
                    var forwardTime = this.$refs.vidPlayer.playerGetTime() + 10;
                    this.rewindForwardClicked(forwardTime);
                    return;
                case 'skip':
                    this.skipAudioSegment();
                    // skip to next audio segment
                    return;
                case 'next':
                    // play next audio element
                    return;
                case 'captions':
                    this.showCaptions = !this.showCaptions;
                    // this.showCaptions ^= true;
                    return;
                case 'playbackStyle':
                    this.advancedControlsOptionsClicked = true;
                    this.advancedControl = advancedControl;
                    return;
                case 'volume':
                    this.advancedControlsOptionsClicked = true;
                    this.advancedControl = advancedControl;
                    return;
                case 'speed':
                    this.advancedControlsOptionsClicked = true;
                    this.advancedControl = advancedControl;
                    return;
                // case 'replay':
                //     this.replayAudioSegment();
                //     return;
                default:
                    return;
            }
        },
        rewindForwardClicked: function (time) {
            console.log("time: ", time);
            this.$refs.vidPlayer.playerSetTime(time);
            this.$refs.vidPlayer.playerPlay(); // on occasion, video player will play and immediately pause. I think this has something to do with hitting an audio segment
            // this.rewindForwardTimer = setTimeout(() => { console.log(""); }, 100); // this can be used to add up time to show. For example, if double clicked then time skip forward would show 20 sec
        },
        closeAdvancedOptionsClicked: function () {
            clearTimeout(this.delayAdvancedOptionsClickTimer);
            this.advancedControlsOptionsClicked = false
        },
        delayAdvancedOptionsClick: function () {
            this.delayAdvancedOptionsClickTimer = setTimeout(() => { this.advancedControlsOptionsClicked = false }, 250);
        },
        replayAudioSegment: function () {
            console.log("replayAudioSegment");
            console.log("this.currentAudioElement: ", this.currentAudioElement);
            console.log("this.currentAudioSegment: ", this.currentAudioSegment);
            if (!this.audioPlayer.paused) {
                // if audio player is currently playing, then restart the current audio segment
            } else if (this.currentAudioSegment.index == 0) {
                // play the current audio segment
                // otherwise if the index of the current segment is 0, play the segment
            } else {
                // else play the segment at index - 1
            }
        },
        skipAudioSegment: function () {
            console.log("skipAudioSegment");
            console.log("this.currentAudioElement: ", this.currentAudioElement);
            console.log("this.currentAudioSegment: ", this.currentAudioSegment);
            if (!this.audioPlayer.paused) {
                // if audio player is currently playing, then restart the current audio segment
            } else if (this.currentAudioSegment.index == 0) { // greater than length of currentAudioElement.segments - 1
                // play the current audio segment
                // otherwise if the index of the current segment is 0, play the segment
            } else {
                // else play the segment at index - 1
            }
        },
        getPostData: function () {
            firebase.db.collection("publicPosts").doc(this.postId).get().then((doc) => {
                if (!doc.exists) {
                    this.viewLoading = false;
                    this.$store.commit('alertUser', { show: true, text: `This post does not exist.`, type: 'snackbar' });
                    return;
                }
                // var date = doc.data().postDate.toDate().toLocaleDateString(); // should put date in format: MM/DD/YYYY;
                // var date = doc.data().postDate.toDate().toDateString().slice(4); // should put the date in the format: MMM DD YYYY;
                let day = doc.data().postDate.toDate().getDate();
                let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                let year = doc.data().postDate.toDate().getFullYear();
                let formattedDate = `${day} ${month} ${year}`;
                this.postData = doc.data();
                this.postData.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                // this.postData.postDate = date; // uncomment to use one of the date formats commented out above;

                // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                if (typeof this.postData.multipleLanguagesTitle === 'object' && this.postData.multipleLanguagesTitle !== null && this.postData.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                    this.postData.title = this.postData.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                    this.postData.postAudioDataId = this.postData.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                }

                this.descriptionWordCount = this.wordCount(this.postData.description);
                this.$store.dispatch('saveCurrentPost', this.postData);
                var newSrc = {
                    type: this.postData.videoType,
                    src: this.postData.videoUrl,
                };
                this.vidOptions.sources= newSrc;
                this.letUserSeePost = true;
                this.viewLoading = false;
                if(this.postMetadataHasChanged) {
                    this.$store.commit("updatePostData",this.postData); // this updates the postData in the store so that the homepage, and other pages, can get the changes aswell.
                    this.postMetadataHasChanged = false;
                    this.editPostMetadataLoading = false;
                }
                if (this.editPost) this.editPost = false;
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err}`, type: 'snackbar' });
                this.viewLoading = false;
            });
        },
        getGroupPostData: function () {
            firebase.db.collection("groupPosts").doc(this.postId).get().then((doc) => {
                if (!doc.exists) {
                    this.$store.commit('alertUser', { show: true, text: `This post does not exist.`, type: 'snackbar' });
                    this.viewLoading = false;
                    return;
                }
                this.postData = doc.data();
                if (this.isUserPartOfGroupFunc(this.userGroups) || this.userData.admin) {
                    let day = doc.data().postDate.toDate().getDate();
                    let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                    let year = doc.data().postDate.toDate().getFullYear();
                    let formattedDate = `${day} ${month} ${year}`;
                    this.postData.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                    this.descriptionWordCount = this.wordCount(this.postData.description);
                    this.$store.dispatch('saveCurrentPost', this.postData);
                    var newSrc = {
                        type: this.postData.videoType,
                        src: this.postData.videoUrl,
                    };
                    this.vidOptions.sources = newSrc;
                    this.letUserSeePost = true;
                    this.viewLoading = false;
                    if (this.postMetadataHasChanged) {
                        this.$store.commit("updatePostData",this.postData); // this updates the postData in the store so that the homepage, and other pages, can get the changes aswell.
                        this.postMetadataHasChanged = false;
                        this.editPostMetadataLoading = false;
                    }
                    if (this.editPost) this.editPost = false;
                } else {
                    this.$store.commit('alertUser', { show: true, text: `This post can only be seen members of the group it belongs to.`, type: 'snackbar' });
                    this.viewLoading = false;
                }
            }).catch((err) => {
                this.viewLoading = false;
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err}`, type: 'snackbar' });
            });
        },
        adjustSegmentVolume: function (value) {
            this.captionVolume = value == 'mute' ? this.captionVolume > 0.0 ? 0.0 : 1.0 : value
            if (this.playbackStyle == 'continuous') this.$refs.vidPlayer.playerSetVolume(1.0 - this.captionVolume);
            this.audioPlayer.volume = this.captionVolume;
        },
        visibilityChanged: function () {
            if (document.hidden) {
                this.$refs.vidPlayer.playerPause();
                this.audioPlayer.pause();
            }
        },
        setAudioPermissions: function () {
            // permissions: 0 = none, 1 = all,  2 = only current users and main one
            if (this.postData.postType === 'group') {
                const index = this.userGroups.findIndex((group) => group.groupId === this.postData.groupId);
                if ( index != -1 ) { // index exists in userGroups
                    this.isGroupAdmin = this.userGroups[index].admin || false;
                    if (this.isGroupAdmin) { // User is group admin.
                        return 1;
                    } else { // User is NOT admin.
                        if (this.userGroups[index].privateContributions) {
                            if (this.userData.admin) return 1; // user is tawq.in ADMIN
                            return 2; // private contributions are set on the group, and the user is not an admin.
                        } else {
                            return 1; // the user is not an admin and private contributions are not set
                        }
                    }
                } else { // index does NOT exist in userGroups
                    if (this.userData.admin) return 1; // tawq.in admin can see any post.
                    return 0;
                }
            }
            // Public post, allow all.
            return 1;
        },
        getPostAudioData: async function(){
            // this method retrieves the postAudioData (the main audio attached to the post) only. currently being used after editing a post... this way we aren't retrieving ALL of them when only one has been edited...
            let permissions = await this.setAudioPermissions();
            if (permissions === 0) {
                return;
            }
            const audioRef = firebase.db.collection('audioSegments').doc(this.postData.postAudioDataId);
            const audioDoc = await audioRef.get().catch((err) => {
                console.log("error retrieving the postAudioData document. ", err.message);
            })
            let data = {
                audioData: audioDoc.data(),
                audioDataId: audioDoc.id,
            };
            const index = this.audioData.findIndex( audio => audio.audioDataId === this.currentPost.postAudioDataId);
            this.audioData.splice(index != -1 ? index : 0, 1, data);
            this.audioLoading = false;
        },
        getAudioData: async function () {
            /* This function will retrieve the metadata for the audio segments, not the audio itself.
            We will query the owners audio segments first, adding them to our local array. after we will
            query every audio segment, and only add it to the array if it hasn't been added yet. Firebase Querying
            does not allow '!=', or for us to use where clause with orderby clause on different fields. */

            // July 13, 2023: the following incrementPostViewed if statement was moved here in hopes that it will get called everytime and not just in the $nextTick function of setPost();
            this.postViewedCounter = setTimeout(() => {
                this.incrementPostViewed(); // this only increments when on production. I.E. tawq.in NOT localhost or developer.tawq.in;
            }, 30000); // increment the posts viewed by one after 30 seconds.

            let permissions = await this.setAudioPermissions();
            if (permissions === 0) { return; }
            this.audioData = [];
            firebase.db.collection("audioSegments").where("postId", "==", this.postData.postId).orderBy("date", "desc").get().then((snapshot) => {
                snapshot.forEach((doc) => {
                    let data = {
                        audioData: doc.data(),
                        audioDataId: doc.id,
                    };
                    /* first check to see that there is audio here, then if it is the main audioData that is associated with the post, add it to the front not the back */
                    if (data.audioData.audio.length > 0) {
                        if (data.audioDataId === this.postData.postAudioDataId) {
                            this.audioData.unshift(data);
                        } else {
                            // permissions: 0 = none, 1 = all,  2 = only current users and main one
                            if(permissions === 1){
                                this.audioData.push(data);
                            }
                            else if (permissions === 2) {
                                if (doc.data().userId === firebase.auth.currentUser.uid){
                                    this.audioData.push(data);
                                } else {
                                    return;
                                }
                            }
                        }
                    }
                    if (typeof data.audioData.languageName != 'undefined' && !this.filter.languageCodes.includes(data.audioData.languageName)){
                        this.filter.languageCodes.push(data.audioData.languageName)
                    }
                });
                if (!this.$route.query.aid) {
                    this.selectedAudioId = this.audioData[0].audioDataId; // no aid in url, set first AudioElement to be highlighted.
                    this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});
                } else {
                    this.selectedAudioId = this.$route.query.aid; // set this so the AudioElement that will play when clicking video or big play button is highlighted.
                }
                this.currentAudioElement = this.audioData.find(data => data.audioDataId === this.selectedAudioId);
                this.loadAndPreloadFirstAudioData();
                this.audioLoading = false;
                // this.videoLoading = false; // July 27, 2023. This shouldn't be necessary, but is being kept commented out in case of error or issue that requires it uncommented.
            }).catch((err) => {
                this.audioLoading = false;
                this.videoLoading = false;
                console.error(err.message);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        wordCount: function () {
            var temp = this.postData.description;
            temp = temp.replace(/(^\s*)|(\s*$)/gi,"");
            temp = temp.replace(/[ ]{2,}/gi," ");
            temp = temp.replace(/\n /,"\n");
            return temp.split(' ').length;
        },
        incrementPostViewed: function() {
            if (process.env.NODE_ENV === 'production') {
                let postRef;
                if (typeof this.postData.postId === 'undefined') {
                    return;
                } else if (typeof this.postData.postId != 'undefined') {
                    postRef = firebase.db.collection(this.postData.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postData.postId);
                } else {
                    postRef = firebase.db.collection(this.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postId);
                }
                postRef.update({postViewed: fb.firestore.FieldValue.increment(1),}).catch((error) => {
                    console.error("error incrementing the postViewed:", error.message);
                })
            } else {
                return;
            }
        },
        isUserPartOfGroupFunc: function(groups) {
            if (!groups.length) return false;
            return groups.some(group => group.groupId === this.postData.groupId);
        },
        setPost (timesCalled) {
            // setPost() looks to see if we can use the current post from the store. if we can, then we don't have to fetch it from firebase. If we try through 5 ticks, with no success, then we will retrieve the post from firebase.
            // we have to wrap the code in the processNextTick() function because when viewPost is created, the store variable hasn't been set
            if (timesCalled > 5) { // base case: if we have tried this 5 times already, we fallback to getting the post info from firebase;
                this.postType === 'group' ? this.getGroupPostData() : this.getPostData();
                return;
            } else {
                this.$nextTick(() => {
                    if (this.currentPost.postId === this.postId) {
                        this.postData = { ...this.currentPost };
                        if (this.postType === 'group' && !this.isUserPartOfGroupFunc(this.userGroups) && !this.userData.admin) {
                            this.$store.commit('alertUser', { show: true, text: `This post can only be seen members of the group it belongs to.`, type: 'snackbar' });
                            this.viewLoading = false;
                            return;
                        }
                        this.descriptionWordCount = this.wordCount(this.postData.description);
                        this.vidOptions.sources = {
                            type: this.postData.videoType,
                            src: this.postData.videoUrl,
                        };
                        this.letUserSeePost = true;
                        this.viewLoading = false;
                    } else {
                        this.setPost(timesCalled + 1); // recursively call this function to see if the store has updated
                    }
                });
            }
        },
    },
    watch: {
        playbackRate: function (rate) {
            this.$refs.vidPlayer.playerSetPlaybackRate(rate);
            this.audioPlayer.playbackRate = rate;
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        isIOS(){
            return this.$store.state.isIOSDevice;
        },
        condensed() {
            return this.postData.description.substring(0,150);
        },
        getMaxHeightMainContainer() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return '60vh'; // Max height for small and down screens (sm/xs)
            } else {
                return '80vh'; // Max height for medium and up screen (md/lg/xl)
            }
        },
        getMaxHeightSubContainer() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return '50vh'; // Max height for small and down screens (sm/xs)
            } else {
                return '60vh'; // Max height for medium and up screen (md/lg/xl)
            }
        },
        languageFilteredAudio(){
            if(this.filter.languageSelected === "All"){
                return this.audioData;
            } else {
                return this.audioData.filter( audio => audio.audioData.languageName === this.filter.languageSelected );
            }
        },
        // items(){
        //     if(this.filter.languageSelected === "All"){
        //         console.log("AudioData: ", this.audioData);
        //         return this.audioData;
        //     } else {
        //         return this.audioData.filter( audio => audio.audioData.languageName === this.filter.languageSelected );
        //     }
        // },
        leftAudioData(){
            if(this.filter.languageSelected === "All"){
                return this.audioData.filter((num, index) => index%2 === 0);
            } else {
                return this.audioData.filter((audio,index) => audio.audioData.languageName === this.filter.languageSelected && index%2 === 0);
            }
        },
        rightAudioData(){
            if(this.filter.languageSelected === "All"){
                return this.audioData.filter((num, index) => index%2 != 0);
            } else {
                return this.audioData.filter( (audio,index) => audio.audioData.languageName === this.filter.languageSelected && index%2 != 0);
            }
        },
        userData(){
            return this.$store.state.userData;
        },
        userGroups(){
            return this.$store.state.userGroups;
        },
        userGroupLength(){
            return this.$store.getters.getUserGroupsLength
        },
        currentPost(){
            return this.$store.state.currentPost;
        },
        displayLang() {
            const langMap = {
                en: 'English',
                es: 'Spanish',
                ar: 'Arabic',
                // Add more language mappings as needed
            };

            return langMap[this.currentAudioElement.audioData.languageCode.slice(0,2)] || this.currentAudioElement.audioData.languageCode.slice(0,2);
        },
    },
    beforeDestroy: function () {
        this.audioPlayer.pause();
        document.removeEventListener("visibilitychange", this.visibilityChanged);
        clearTimeout(this.postViewedCounter); // if they haven't stayed on the page for 7 seconds the timeout will stay active unless being destroyed
        this.audioPlayer.removeEventListener("ended", this.audioEnded);
        this.audioPlayer.removeEventListener("canplaythrough", this.audioCanPlayThrough);
    },
    created: function () {
        // console.log("postId: ", this.postId);
        this.setPost(1);
    },
    mounted: function () {
        document.addEventListener("visibilitychange", this.visibilityChanged); // if the tab isn't being viewed we will pause the video
        if (this.postId === "rXm4nZfBwDP9DIeycLzS") { // this if statement is used to set the below values of the pinned post 'how to use tawq.in'
            this.captionVolume = 1.0;
            this.playbackStyle = "continuous";
        }
        this.audioPlayer.volume = this.captionVolume;
    },
  
};
</script>

<style scoped>
@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}
.dialog-bottom-transition-enter {
    opacity: 0;
    transform: translateY(100%);
}
.dialog-bottom-transition-leave-to {
    opacity: 0;
    transform: translateY(100%);
}

.v-input >>> .v-input__prepend-outer {
    margin-right: 0 !important;
}

.v-input >>> .v-input__append-outer {
    margin-left: 0 !important;
}

.expand {
    min-height: 105px !important;
    max-height: 600px !important;
    padding-bottom: 1em;
    /* overflow-y: scroll !important; */
}

.faded {
    position: absolute; 
    bottom: 1em;
    /* width: 100%; */
    width: 95%;
    height: 3em;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 75%);
}

.faded-dark {
    position: absolute; 
    bottom: 1em;
    width: 90%;
    height: 3em;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 0) 0%,rgba(18,18,18, 1) 75%);
    transition: display .4s;
}

.bordered {
    background-color: var(--v-background-base);
    /* border: 5px solid var(--v-background-base); */
    border-radius: 25px;
}

.v-skeleton-loader__image {
    height: 100%;
}
</style>