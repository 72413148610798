<template>
    <v-row justify="center" class="ma-0 pa-0" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">

        <!-- Welcome Dialog -->
        <div v-if="showWelcomeTour">
            <WelcomeModal @tour-complete="showWelcomeTour = false, $router.replace({ path: '/home' })"/>
        </div>
        <div v-if="showArabicWelcomeTour">
            <ArabicWelcomeModal @tour-complete="showArabicWelcomeTour = false, $router.replace({ path: '/home' })"/>
        </div>
        <!-- END Welcome Dialog -->

        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span :class="category === 'Home' ? 'text-md-h3 text-h4 font-weight-bold primary--text' : 'text-md-h3 text-h4 font-weight-thin'" style="cursor: pointer;" @click="changeCategory('Home')">
                    <v-icon v-if="homeRefresh || isRefreshing" large color="primary" :class="homeRefresh ? 'rotate-icon' : ''" class="text-decoration-none">{{homeRefresh ? 'mdi-refresh' : isRefreshing ? 'mdi-reload' : null}}</v-icon>{{homeRefresh || isRefreshing ? null : 'Home'}}
                </span>
                <!-- <span class="text-md-h3 text-h4 font-weight-light px-2">|</span>
                <span :class="category === 'Home' ? 'text-md-h3 text-h4 font-weight-thin' : 'text-md-h3 text-h4 font-weight-bold primary--text'" style="cursor: pointer;" @click="changeCategory('Friends')"><v-icon v-if="friendsLoading" large class="text-decoration-none" color="primary">mdi-reload</v-icon>{{friendsLoading ? null : 'Friends'}} </span> -->
                <!-- <span class="text-md-h3 text-h4 font-weight-light px-2">|</span>
                <span :class="category === 'Home' ? 'text-md-h3 text-h4 font-weight-thin' : 'text-md-h3 text-h4 font-weight-bold primary--text'" style="cursor: pointer;" @click="changeCategory('Favorites')"><v-icon v-if="friendsLoading" large class="text-decoration-none" color="primary">mdi-reload</v-icon>{{favoritesLoading ? null : 'Favorites'}} </span> -->
            </v-row>

            <v-row v-if="postsLoading" justify="start" class="pa-0">
                <v-col v-for="(load,index) in publicPosts.length > 0 ? publicPosts.length : limit" :key="index" cols="12" xl="3" lg="4" md="6" sm="6" xs="12">
                    <v-skeleton-loader
                        height="250"
                        type="image"
                        class="rounded-xl"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else-if="!postsLoading && noFriends != null" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">{{noFriends}}</span>
            </v-row>
            <v-row v-else justify="start" class="pa-0">
                <v-col v-for="(post,index) in category === 'Home' ? posts : friendsPosts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                    <!-- <Ad v-if="post == 'Ad'" :post="post"/> -->
                    <!-- v-else --> <!-- add to div below if using ads -->
                    <div style="position: relative;">
                        <v-icon v-if="post.pinnedPost" dark medium :color="$vuetify.theme.dark ? 'amber' : 'primary'" style="position: absolute; top:0; left:0; z-index: 1; transform: rotate(-30deg);" class="my-n3 mx-n3 text-shadow">mdi-pin</v-icon>
                        <Post :post="post" :homefeed="true" :index="index" :isAdmin="userData.admin" @pinnedPost="pinnedPost" @delete-selected-post="deletePost"/>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="reachedBottomPublic && category === 'Home'" justify="center" align="center" class="pa-0">
                <v-col cols="12" align="center">
                    <span @click.stop="scrollToTop" style="cursor: pointer;" class="pr-1">You've reached the bottom, click to scroll to the top</span>
                    <v-btn @click.stop="scrollToTop" small icon :ripple="false">
                        <v-icon>mdi-arrow-up-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="reachedBottomFriends && category != 'Home'" justify="center" align="center" class="pa-0">
                <v-col cols="12" align="center">
                    <span @click.stop="scrollToTop" style="cursor: pointer;" class="pr-1">You've reached the bottom of your friends posts, click to scroll to the top</span>
                    <v-btn @click.stop="scrollToTop" small icon :ripple="false">
                        <v-icon>mdi-arrow-up-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-show="scrollLoading" justify="center" align="end"  style="postion: fixed; bottom: 30px;">
                <v-progress-circular fixed size="30" color="primary" indeterminate></v-progress-circular>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import Post from "./Post.vue";
import firebase from "../../firebaseConfig.js";
import WelcomeModal from "./WelcomeTour.vue";
import ArabicWelcomeModal from "./WelcomeTourArabic.vue";
// import Ad from "./Ad.vue";
export default {
    metaInfo: {
        title: 'Home - tawqin'
    },
    name: "home",
    props: ["newUser"],
    components: {
        Post,
        WelcomeModal,
        ArabicWelcomeModal,
        // Ad,
    },
    data: function () {
        return {
            startY: 0,
            isRefreshing: false,
            homeRefresh: false,
            friendsLoading: false,
            favoritesLoading: false,
            postsLoading: true,
            posts: [],
            pinnedPosts: [],
            friendsPosts: [],
            friendIds: [],
            favoritesPosts: [],
            noFavorites: null,
            oldCategory: "",
            category: "Home",
            categories: [
                {
                text: "All Categories",
                value: "Home",
                },
                {
                text: "Sports",
                value: "Sports",
                },
                {
                text: "Outdoors",
                value: "Outdoors",
                },
                {
                text: "Cars",
                value: "Cars",
                },
                {
                text: "Educational",
                value: "Educational",
                },
                {
                text: "International",
                value: "International",
                },
                {
                text: "Tutorial",
                value: "Tutorial",
                },
                {
                text: "Technology",
                value: "Technology",
                },
                {
                text: "Religious",
                value: "Religious",
                },
                {
                text: "Fitness",
                value: "Fitness",
                },
            ],
            noFriends: null,
            limit: 20,
            lastVisible: null,
            lastVisibleFriends: null,
            lastVisibleFavorites: null,
            postReference: null,
            friendsPostReference: null,
            favoritesPostReference: null,
            showWelcomeTour: false,
            showArabicWelcomeTour: false,
            reachedBottomPublic: false,
            reachedBottomFriends: false,
            reachedBottomFavorites: false,
            scrollLoading: false,
        };
    },
    methods: {
        scrollToTop: function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        changeCategory: function (category) {
            this.oldCategory = this.category;
            this.category = category;
            if(this.category === "Home" && this.oldCategory != "Home"){
                // if the new category is "Home" and the old is "Friends"
                // then clear noFriends data member and display the homefeed posts.
                if (this.reachedBottomFriends && !this.reachedBottomPublic) {
                    window.addEventListener("scroll", this.handleScroll);
                }
                if (this.noFriends != null) {
                    this.noFriends = null;
                }
            } else if (this.category === "Home" && this.oldCategory === "Home") {
                // if the new category is "Home" and the old is "Home",
                // this means Home was clicked, so we need to refresh the posts.
                // set posts to empty list and re-get the homefeed posts.
                // this will allow a user to refresh the homefeed by clicking "Home".
                this.reachedBottomPublic = false;
                this.homeRefresh = true;
                this.postsLoading = true;
                this.posts = [];
                this.lastVisible = null;
                firebase.db.collection("pinnedPosts").get().then((snapshot) => {
                    if (snapshot.empty) {
                        this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                        this.getPostsByCategory();
                    } else {
                        snapshot.forEach((doc) => {
                            let day = doc.data().postDate.toDate().getDate();
                            let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                            let year = doc.data().postDate.toDate().getFullYear();
                            let formattedDate = `${day} ${month} ${year}`;
                            let post = doc.data();
                            post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;

                            // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                            let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                            if (typeof post.multipleLanguagesTitle === 'object' && post.multipleLanguagesTitle !== null && post.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                                post.title = post.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                                post.postAudioDataId = post.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                            }

                            this.pinnedPosts.push(post);
                        });
                        // console.log(this.pinnedPosts);
                        this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                        this.getPostsByCategory();
                    }
                });
            } else if (this.category === "Friends" && this.noFriends != "None of your friends have created post yet.") {
                this.reachedBottomFriends = false;
                this.friendsLoading = true; 
                this.postsLoading = true;
                this.friendIds = [];
                this.friendsPosts = [];
                if (this.userFriends.length <= 0) {
                    // This can be changed to show a message that says add friends to see posts
                    this.noFriends = "Add friends to see posts. When friends accept requests their posts will show here.";
                    this.postsLoading = false;
                    this.friendsLoading = false; 
                    return;
                } else if (this.userFriends.length <= 10) {
                    // user has 10 friends or less, so no need to do anything but set friendIds
                    this.friendIds = this.userFriends;
                } else {
                    // friends list is larger than 10
                    this.friendIds = this.getRandom(this.userFriends, 10);
                }
                this.friendsPostReference = firebase.db.collection("publicPosts").where("userId", 'in', this.friendIds).orderBy("postDate", "desc")/*.startAfter(this.lastVisibleFriends)*/.limit(this.limit);
                this.getFriendsPosts();
            } else if (this.category === "Favorites" && this.noFavorites == null /*!= "You have not saved any posts to your Favorites list."*/) {
                this.reachedBottomFavorites = false;
                this.favoritesLoading = true;
                this.postsLoading = true;
                if (this.userFavorites.length <= 0) {
                    this.noFavorites = "You have not saved any posts to your Favorites list.";
                    this.favoritesLoading = false;
                    this.postsLoading = false;
                }
                // Save only the postId in the favorites collection of userData.
                // this.favoritesList = firebase.db.collection("userData").doc(this.userData.userId).collection("favorites")
            }
        },
        deletePost: function(payload) {
            // console.log("delete Post ", payload);
            this.$store.dispatch('deletePostFromDb', { post: payload.post, index: payload.index, deleteFromStore: true, isDraft: false } );
        },
        pinnedPost: function(payload) {
            if (payload.pin) { // pin post to home feed.
                const formattedDate = payload.post.postDate; // Replace with your formatted date
                // Split the formatted date into parts
                const parts = formattedDate.split(' ');
                // Extract day, month, and year
                const day = parseInt(parts[0], 10);
                const monthName = parts[1];
                const year = parseInt(parts[2], 10);
                // Create a JavaScript Date object
                const unformattedDate = new Date(Date.parse(`${monthName} ${day}, ${year}`));
                firebase.db.collection("pinnedPosts").doc(payload.post.postId).set({
                    anonymousMode: payload.post.anonymousMode || false,
                    pinnedPost: true,
                    mediaType:  payload.post.mediaType,
                    // filteredDescription: payload.post.filteredDescription,
                    // filteredTitle: payload.post.filteredTitle,
                    // filteredTags: payload.post.filteredTags,
                    videoLanguageSelected: payload.post.videoLanguageSelected || "",
                    noCaptionLanguageSelected: payload.post.noCaptionLanguageSelected || "",
                    languageName: payload.post.languageName,
                    description: payload.post.description,
                    postDate: unformattedDate,
                    tags: payload.post.tags,
                    thumbnail: payload.post.thumbnail,
                    category: payload.post.category,
                    title: payload.post.title,
                    userId: payload.post.userId,
                    postId: payload.post.postId,
                    videoUrl: payload.post.videoUrl,
                    videoType: payload.post.videoType,
                    postAudioDataId: payload.post.postAudioDataId,
                    username: payload.post.username,
                    userProfilePhoto: payload.post.userProfilePhoto,
                    postType: payload.post.postType,
                    transcoded: payload.post.transcoded || false,
                    // groupLanguage: payload.post.groupLanguage,
                    groupId: payload.post.groupId,
                    groupName: payload.post.groupName,
                    groupThumbnail: payload.post.groupThumbnail,
                    postAnalytics: payload.post.postAnalytics,
                }, {merge: true}).then(() => {
                    firebase.db.collection("publicPosts").doc(payload.post.postId).update({
                        pinnedPost: true,
                    },{merge: true}).then(()=>{
                        this.postsLoading = true;
                        this.pinnedPosts = [],
                        this.posts = [],
                        firebase.db.collection("pinnedPosts").get().then((snapshot) => {
                            if (snapshot.empty) {
                                this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                                this.getPostsByCategory();
                            } else {
                                snapshot.forEach((doc) => {
                                    // console.log("doc.data(): ", doc.data());
                                    let day = doc.data().postDate.toDate().getDate();
                                    let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                                    let year = doc.data().postDate.toDate().getFullYear();
                                    let formattedDate = `${day} ${month} ${year}`;
                                    let post = doc.data();
                                    post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;

                                    // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                                    let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                                    if (typeof post.multipleLanguagesTitle === 'object' && post.multipleLanguagesTitle !== null && post.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                                        post.title = post.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                                        post.postAudioDataId = post.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                                    }

                                    this.pinnedPosts.push(post);
                                });
                                // console.log(this.pinnedPosts);
                                this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                                this.getPostsByCategory();
                            }
                        });
                    }).catch((err) => {
                        console.error("Error", err.message);
                    });
                });
            } else { // unpin post from home feed.
                firebase.db.collection("pinnedPosts").doc(payload.post.postId).delete().then(() => {
                    firebase.db.collection("publicPosts").doc(payload.post.postId).update({
                        pinnedPost: false,
                    },{merge: true}).then(() => {
                        // Do something here to reload the posts
                        // remove from local lists?
                        this.postsLoading = true;
                        this.pinnedPosts = [],
                        this.posts = [],
                        firebase.db.collection("pinnedPosts").get().then((snapshot) => {
                            if (snapshot.empty) {
                                this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                                this.getPostsByCategory();
                            } else {
                                snapshot.forEach((doc) => {
                                    // console.log("doc.data(): ", doc.data());
                                    let day = doc.data().postDate.toDate().getDate();
                                    let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                                    let year = doc.data().postDate.toDate().getFullYear();
                                    let formattedDate = `${day} ${month} ${year}`;
                                    let post = doc.data();
                                    post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;

                                    // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                                    let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                                    if (typeof post.multipleLanguagesTitle === 'object' && post.multipleLanguagesTitle !== null && post.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                                        post.title = post.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                                        post.postAudioDataId = post.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                                    }

                                    this.pinnedPosts.push(post);
                                });
                                // console.log(this.pinnedPosts);
                                this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                                this.getPostsByCategory();
                            }
                        });
                    }).catch((err) => {
                        console.error("Error", err.message);
                    });
                });
            }
        },
        getFriendsPosts: function () {
            this.friendsPostReference.get().then((snapshot) => {
                if (snapshot.empty){
                    // show message that says selected friends have not created any posts
                    if (this.userFriends.length <= 10 && this.friendsPosts.length == 0) {
                        this.noFriends = "None of your friends have created a post yet.";
                    } else if (this.friendsPosts.length == 0) {
                        this.noFriends = "The friends we selected have not posted anything yet, click on 'Friends' above to reload.";
                    }
                    if (this.lastVisibleFriends != null) {
                        this.reachedBottomFriends = true;
                    }
                    window.removeEventListener('scroll', this.handleScroll);
                    this.postsLoading = false;
                    this.friendsLoading = false;
                    return;
                } else {
                    this.lastVisibleFriends = snapshot.docs[snapshot.docs.length - 1];
                    // counter is used for ads
                    // var counter = 1;
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        // var date = doc.data().postDate.toDate().toDateString().slice(4); // this should give the date in the format: MMM DD YYYY
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;;
                        this.friendsPosts.push(post);
                        // if (counter%15 == 0){
                        //   this.friendsPosts.push("Ad");
                        // }
                        // counter++;
                    })
                    window.addEventListener("scroll", this.handleScroll);
                    this.friendsLoading = false;
                    this.postsLoading = false;
                }
                // this.$store.dispatch('savePublicPosts', this.posts);
                // this.$store.dispatch('saveLastLoadedPublicPost', this.lastVisible);
            });
        },
        getRandom: function (arr, n) {
            // // Shuffle array
            // const shuffled = arr.sort(() => 0.5 - Math.random());
            // // Get sub-array of first n elements after shuffled
            // let selected = shuffled.slice(0, n);
            // return selected;
            // console.log(selected);

            // Double check this code and make sure there isn't a better way to accomplish this task
            var result = new Array(n),
                len = arr.length,
                taken = new Array(len);
            if (n > len)
                throw new RangeError("getRandom: more elements taken than available");
            while (n--) {
                var x = Math.floor(Math.random() * len);
                result[n] = arr[x in taken ? taken[x] : x];
                taken[x] = --len in taken ? taken[len] : len;
            }
            return result;
        },
        getPostsByCategory: function () {
            if (this.category === "Home") {
                this.postReference.get().then((snapshot) => {
                    if (!snapshot.empty) {
                        this.scrollLoading = true;
                        this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                        // counter is used for ads
                        // var counter = 1;
                        snapshot.forEach((doc) => {
                            // var date = doc.data().postDate.toDate().toLocaleDateString();
                            if (doc.data().pinnedPost) {
                                // do nothing. We don't want this post in the list of other posts, because we want it pinned to the start, which happens after the forEach function.
                            } else {
                                let day = doc.data().postDate.toDate().getDate();
                                let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                                let year = doc.data().postDate.toDate().getFullYear();
                                let formattedDate = `${day} ${month} ${year}`;
                                let post = doc.data();
                                post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;

                                // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                                let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                                if (typeof post.multipleLanguagesTitle === 'object' && post.multipleLanguagesTitle !== null && post.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                                    post.title = post.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                                    post.postAudioDataId = post.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                                }

                                this.posts.push(post);
                            }
                            // if (counter%15 == 0) {
                            //   this.posts.push("Ad");
                            // }
                            // counter++;
                        });
                        if (this.pinnedPosts.length >= 1) {
                            var combinedList = [...this.pinnedPosts, ...this.posts];
                            this.posts = combinedList;
                            this.pinnedPosts = [];
                        }
                        window.addEventListener("scroll", this.handleScroll);
                    } else {
                        if (this.lastVisible != null) this.reachedBottomPublic = true;
                        window.removeEventListener('scroll', this.handleScroll);
                    }
                    this.homeRefresh = false;
                    this.scrollLoading = false;
                    this.postsLoading = false;
                    this.$store.dispatch('savePublicPosts', this.posts);
                    this.$store.dispatch('saveLastLoadedPublicPost', this.lastVisible);
                }).catch((err) => {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong while fetching posts.`+ err, type: 'snackbar' });
                });
            }
        },
        handleScroll: function () {
            if(!this.postsLoading) {
                // let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.body.clientHeight;
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                // console.log("document.documentElement.scrollTop: ", document.documentElement.scrollTop);
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                // let bottomOfWindow = window.scrollY + windowHeight >= documentHeight - offset;
                // let bottomOfWindow = window.scrollY + windowHeight >= document.body.scrollHeight - offset;
                if (bottomOfWindow) {
                    this.scrollLoading = true;
                    if (this.category === "Home") {
                        this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").startAfter(this.lastVisible).limit(this.limit);
                        window.removeEventListener("scroll", this.handleScroll);
                        this.getPostsByCategory();
                    } else if (this.category === "Friends") {
                        this.friendsPostReference = firebase.db.collection("publicPosts").where("userId", 'in', this.friendIds).orderBy("postDate", "desc").startAfter(this.lastVisibleFriends).limit(this.limit);
                        window.removeEventListener("scroll", this.handleScroll);
                        this.getFriendsPosts();
                    } else { // this.category === "Favorites"
                        window.removeEventListener("scroll", this.handleScroll);
                        // this.getFavoritesPosts();
                    }
                }
            }
        },
        onTouchStart(event) {
            if (window.scrollY === 0) this.startY = event.touches[0].clientY;
        },
        onTouchMove(event) {
            if (window.scrollY === 0 && !this.isRefreshing) {
                const currentY = event.touches[0].clientY;
                if (currentY - this.startY > 300) {
                    this.isRefreshing = true;
                }
            }  else if (window.scrollY > 10 && this.isRefreshing) {
                const currentY = event.touches[0].clientY;
                if (currentY + this.startY > 300) {
                    this.isRefreshing = false
                };
            }
        },
        onTouchEnd() {
            if (this.isRefreshing) {
                this.changeCategory("Home");
                this.isRefreshing = false;
            }
        },
    },
    computed: {
        userData() {
            return this.$store.state.userData;
        },
        publicPosts() {
            return this.$store.state.publicPosts;
        },
        lastLoadedPublicPost() {
            return this.$store.state.lastLoadedPublicPost;
        },
        userFriends() {
            var friends = [];
            this.$store.state.userFriends.forEach((friend) => {
                if (friend.accepted){
                friends.push(friend.userId);
                }
            })
            return friends;
        },
        userFavorites() {
            // Add code to store for user favorites.
            // Maybe this will take to much to store, look into this.
            return [];
        },
    },
    created: function () {
        // clear the groups page store state to clear up mem
        this.$store.dispatch('resetGroupsPageStoreState').groupsPageStore;
        if (typeof this.publicPosts != 'undefined' && this.publicPosts.length > 0){
            this.posts = this.publicPosts;
        }
        if(this.lastLoadedPublicPost != null){
            this.lastVisible = this.lastLoadedPublicPost;
        }
        if (this.newUser) {
            firebase.db.collection("userData").doc(firebase.auth.currentUser.uid).update({
                emailVerified: true,
            }).catch((err) => {
                console.error("Didn't set email verified in userData docs", err.message);
            });
            const userLang = navigator.userLanguage || navigator.language;
            if (userLang.includes('ar')) this.showArabicWelcomeTour = true;
            else this.showWelcomeTour = true;
        }
        if (this.posts.length == 0) {
            firebase.db.collection("pinnedPosts").get().then((snapshot) => {
                if (snapshot.empty) {
                    this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                    this.getPostsByCategory();
                } else {
                    snapshot.forEach((doc) => {
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        let post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;

                        // the following code block will check to see if a post has 'multipleLanguagesTitle' object in the database, and if so, will set the title and audioDataId to the corresponding default browser language or to default (english)
                        let userDefaultBrowserLanguage = (navigator.userLanguage || navigator.language).split('-')[0];
                        if (typeof post.multipleLanguagesTitle === 'object' && post.multipleLanguagesTitle !== null && post.multipleLanguagesTitle.hasOwnProperty(userDefaultBrowserLanguage)) {
                            post.title = post.multipleLanguagesTitle[userDefaultBrowserLanguage].title;
                            post.postAudioDataId = post.multipleLanguagesTitle[userDefaultBrowserLanguage].audioId;
                        }

                        this.pinnedPosts.push(post);
                    });
                    // console.log(this.pinnedPosts);
                    this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").limit(this.limit);
                    this.getPostsByCategory();
                }
            });
        } else {
            this.postReference = firebase.db.collection("publicPosts").orderBy("postDate", "desc").startAfter(this.lastVisible).limit(this.limit);
            this.getPostsByCategory();
        }

    },
    mounted: function () {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy: function () {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style>
.home-feed {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    height: 100vh; /* Ensure the container is scrollable */
}
.v-skeleton-loader__image {
    height: 100%;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rotate-icon {
  animation: rotate 1s infinite linear;
}
</style>