<template>
    <div>
        <!-- WRAPPER - GRID ELEMENT -->
        <div id="post-analytics-grid" class="full-width px-2 px-sm-4 px-md-0">
            <!-- TOTAL POSTS 1ST ITEM-->
            <div class="pa-4 d-flex flex-row flex-lg-column justify-space-around align-center grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-col xl="4" lg="2" md="2" sm="2" xs="2" class="d-flex justify-md-center justify-start align-center px-0 mr-5 mr-sm-0">
                    <v-icon class="accent pa-1 rounded-circle smoke--text" medium>mdi-play-protected-content</v-icon>
                </v-col>
                <div class="d-flex flex-row justify-space-between justify-md-center justify-lg-space-between align-center full-width">
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Total Posts</div>
                        <div v-if="adminAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getTotalPosts.total }}</div>
                    </div>
                    <v-divider vertical class="mx-0 mx-sm-6 mx-md-12 mx-lg-2"></v-divider>
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Since {{new Date(dateFilter.startDate).toLocaleDateString()}}</div>
                        <div v-if="postAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getTotalPosts.dateFilterTotal }}</div>
                    </div>
                </div>
                
            </div>

            <!-- POSTS PER USER 2ND ITEM-->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <v-row justify="center" class="full-width align-self-start mx-0">
                    <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Posts Per User</div>
                </v-row>

                <div v-if="userAnalytics.isLoading" class="loading-data"></div>

                <div v-else class="d-flex flex-row justify-space-between justify-md-center justify-lg-space-between align-center full-width">
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Total</div>
                        <div v-if="adminAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getAveragePostsPerUserTotal }}</div>
                    </div>
                    <v-divider vertical class="mx-0 mx-sm-6 mx-md-12 mx-lg-2"></v-divider>
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Since {{new Date(dateFilter.startDate).toLocaleDateString()}}</div>
                        <div v-if="userAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getAveragePostsPerUserByDate }}</div>
                    </div>
                </div>
               
            </div>

            <!-- TOTAL DRAFTS 3RD ITEM-->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-row class="full-width align-self-start mx-0">
                    <div class="text-subtitle-1 font-weight-medium">Drafts</div>
                </v-row>
               
                <div v-if="userAnalytics.isLoading" class="loading-data"></div>
                <div v-else class="text-h4 primary--text">{{ getAverageDraftsPerUser }}</div>
            </div>

            <!-- PICK UP VS PUSH 4TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--posts-pickup-vs-push"></canvas>
            </div>

            <!-- POSTS: GROUP VS PUBLIC BY DATE 5TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--posts-type-line"></canvas>
            </div>

            <!-- POSTS: TOTAL POSTS BY DATE GRAPH 6TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--posts-total-line"></canvas>
            </div>

            <!-- POSTS: VID VS PHOTO TOTALS 7TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--posts-video-vs-photo"></canvas>
            </div>

            <!-- POSTS: YOUTUBE VS FILE TOTALS 8TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--file-vs-youtube"></canvas>
            </div>

            <!-- POSTS: MOBILE VS DESKTOP TOTALS 9TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--desktop-vs-mobile"></canvas>
            </div>

            <!-- POSTS: CONTRIBUTIONS PER POST  10TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <v-row class="full-width align-self-start mx-0 justify-center">
                    <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Average Contributions Per Post</div>
                </v-row>

                <div v-if="postAnalytics.isLoading" class="loading-data"></div>

                <div v-else class="d-flex flex-row justify-space-between justify-md-center justify-lg-space-between align-center full-width">
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Total</div>
                        <div v-if="adminAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getAverageContributionsPerPostTotal}}</div>
                    </div>
                    <v-divider vertical class="mx-0 mx-sm-6 mx-md-12 mx-lg-2"></v-divider>
                    <div class="half-width">
                        <div class="text-subtitle-2 font-weight-medium mb-2 text-center">Since {{new Date(dateFilter.startDate).toLocaleDateString()}}</div>
                        <div v-if="postAnalytics.isLoading" class="text-h5 primary--text text-center">---</div>
                        <div v-else class="text-h5 primary--text text-center">{{ getAverageContributionsPerPostByDate }}</div>
                    </div>
                </div>

                <v-spacer></v-spacer>
            </div>

            <!-- POSTS: AVERAGE TOTAL TIME PER POST 11TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <v-row class="full-width align-self-start mx-0 justify-center">
                    <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Average Creation Time Per Post</div>
                </v-row>
              
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-else class="text-h5 primary--text text-center">
                    <span>{{ getAveragePostTime.h }}h </span>
                    <span> {{ getAveragePostTime.m }}m </span>
                    <span> {{ getAveragePostTime.s }}s </span>
                </div>
                <v-spacer></v-spacer>
            </div>

            <!-- POSTS: MOBILE - IOS VS OTHER PER POST 12TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <canvas v-show="!postAnalytics.isLoading" id="graph--ios-vs-other"></canvas>
            </div>

            <!-- POSTS: AVG AUDIO TIME PER POST 13TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <v-row class="full-width align-self-start mx-0 justify-center">
                    <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Average Audio Time Per Post</div>
                </v-row>
                
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-else class="text-h5 primary--text text-center">
                    <span>{{ getAverageAudioTimePerPost.h }}h </span>
                    <span> {{ getAverageAudioTimePerPost.m }}m </span>
                    <span> {{ getAverageAudioTimePerPost.s }}s </span>
                </div>
                <v-spacer></v-spacer>
            </div>

            <!-- POSTS: POSTS PER LANGUAGE 14TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Posts Per Language</div>
           
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-show="!postAnalytics.isLoading" class="full-width pt-lg-8" style="overflowY: scroll;">
                    <ul class="full-width px-0 full-height d-flex flex-column justify-space-between align-start">
                        <li v-for="(lang, index) in getPostsPerLanguage.labels" :key="index" class="full-width px-2 py-2" :class="{ 'post-item' : index != 0}">
                            <div class="d-flex flex-row justify-space-between align-center full-width">
                                <span class="accent--text text-truncate" style="width: 80%;">{{lang}}</span>
                                <span style="width: 10%; max-width: 15%;" class="border--left px-2 text-right">{{getPostsPerLanguage.totals[index]}}</span>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <!-- POSTS: VIEWS PER POST 15TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <v-row class="full-width align-self-start mx-0 justify-center">
                    <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Views Per Post</div>
                </v-row>
                <!-- <v-skeleton-loader v-if="userAnalytics.isLoading" type="image"></v-skeleton-loader> -->
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-else class="text-h5 primary--text text-center">{{getAverageViewsPerPost}}</div>
                <v-spacer></v-spacer>
            </div>

            <!-- POSTS: PUBLIC POSTS WITH TOP VIEWS 16TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text">Public Top Viewed</div>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-show="!postAnalytics.isLoading" class="full-width pt-lg-8" style="overflowY: scroll;">
                    <ul class="full-width px-0 full-height d-flex flex-column justify-space-between align-start">
                        <li v-for="(post, index) in getTopViewedPublicPosts.totals" :key="index" class="full-width px-2 py-2 cursor--pointer" :class="{ 'post-item' : index != 0}" @click="$store.commit('setCurrentPost', post)">
                            <router-link class="full-width text-decoration-none smoke--text" :to="{ name: 'view-post', query: { pid: post.postId, postType: post.postType, post: post, userData: userData } , props: { post: post, userData: userData }}" target="_blank">
                                <div class="d-flex flex-row flex-no-wrap justify-space-between align-center full-width">
                                    <div class="flex-grow-1" style="width: 20%; max-width: 25%;">
                                        <span class="mr-4 accent--text">{{index + 1}}.</span>   
                                        <v-avatar class="grey darken2" size="20">
                                            <v-img v-if="(post.thumbnail != null || typeof post.thumbnail != 'undefined')"  :src="post.thumbnail" contain></v-img>
                                        </v-avatar>
                                    </div>

                                    <div class="text-truncate flex-shrink-1" style="width: 65%; max-width: 70%;">
                                        {{post.title}}
                                    </div>

                                    <div class="border--left px-2 text-right flex-grow-1" style="width: 10%; max-width: 15%;">
                                        {{post.postViewed}}
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- POSTS: GROUP POSTS WITH TOP VIEWS 17TH ITEM -->
            <div class="pa-2 pa-md-4 grid-item" :class="[ $vuetify.theme.isDark ? 'grid-item-dark' : 'grid-item-light']">
                <v-icon class="smoke--text graph-type-icon" small>mdi-calendar-range</v-icon>
                <div class="text-subtitle-2 text-center full-width font-weight-medium invert--text align-self-start">Group Top Viewed</div>
                <div v-if="postAnalytics.isLoading" class="loading-data"></div>
                <div v-show="!postAnalytics.isLoading" class="full-width full-height pt-lg-8" style="overflowY: scroll;">
                    <ul class="full-width px-0 full-height d-flex flex-column justify-space-between align-start">
                        <li v-for="(post, index) in getTopViewedGroupPosts.totals" :key="index" class="full-width px-2 py-2 cursor--pointer" :class="{ 'post-item' : index != 0}" @click="$store.commit('setCurrentPost', post)">
                            <!-- <v-divider class="full-width"></v-divider> -->
                            <router-link class="full-width text-decoration-none smoke--text" :to="{ name: 'view-post', query: { pid: post.postId, postType: post.postType, post: post, gId: post.groupId, userData: userData } , props: { post: post, userData: userData }}" target="_blank">
                                <div class="d-flex flex-row justify-space-between align-center full-width">
                                    <div class="flex-grow-1" style="width: 20%; max-width: 25%;">
                                        <span class="mr-4 accent--text">{{index + 1}}.</span>   
                                        <v-avatar class="grey darken2" size="20">
                                            <v-img v-if="(post.thumbnail != null || typeof post.thumbnail != 'undefined')"  :src="post.thumbnail" contain></v-img>
                                        </v-avatar>
                                    </div>

                                    <div class="text-truncate flex-shrink-1" style="width: 65%; max-width: 70%;">
                                        {{post.title}}
                                    </div>

                                    <div class="border--left px-2 text-right flex-grow-1" style="width: 10%; max-width: 15%;">
                                        {{post.postViewed}}
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- <div id="filler-item" class="grid-item pa-2 pa-md-4">
                <v-img src="../../assets/whitetransparent.png" cover></v-img>
            </div> -->
        </div>
        
    </div>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.defaults.plugins.title.font.size = 14;
Chart.defaults.plugins.title.font.lineHeight = 1.375;
Chart.defaults.plugins.title.font.weight = 500;
// Chart.defaults.plugins.title.font.color = window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base');
import {mapGetters, mapState} from 'vuex';
export default {
    name: "PostAnalytics",
    props: ["postAnalytics", "userAnalytics", "adminAnalytics"],
    components: {},
    data(){
        return {
            colors: {
                primary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphPrimary-base'),
                secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphSecondary-darken1'),
                accent: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphAccent-base'),
                success: window.getComputedStyle(document.documentElement).getPropertyValue('--v-success-base'),
                invert: window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base'),
                smoke: '#F5F5F5',
                Google: " #E53935",
            },
            charts: [],
        }
    },
    methods: {
        setColors(){ // this will reset the colors if the theme is changed....
            this.colors = {
                primary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphPrimary-base'),
                secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphSecondary-darken1'),
                accent: window.getComputedStyle(document.documentElement).getPropertyValue('--v-graphAccent-base'),
                success: window.getComputedStyle(document.documentElement).getPropertyValue('--v-success-base'),
                invert: window.getComputedStyle(document.documentElement).getPropertyValue('--v-invert-base'),
                smoke: '#F5F5F5',
                Google: " #E53935"
            }
        },
        hexToRGB(h, opacity) {
            h = h.replace(' ', '');
            let r = 0, g = 0, b = 0;
            // 3 digits
            if (h.length == 4) {
                r = "0x" + h[1] + h[1];
                g = "0x" + h[2] + h[2];
                b = "0x" + h[3] + h[3];

            // 6 digits
            } else if (h.length == 7) {
                r = "0x" + h[1] + h[2];
                g = "0x" + h[3] + h[4];
                b = "0x" + h[5] + h[6];
            }
            // return "rgb("+ +r + "," + +g + "," + +b + ")";
            return "rgb("+ +r + "," + +g + "," + +b + "," + +opacity + ")";
        },
        destroyCharts(){
            // console.log("destroying charts")
            this.charts.forEach((chart) => {
                chart.destroy();
            })
            this.charts = [];
        },
        drawGraph(context, graphType, graphData, graphOptions){
            // console.log("drawing graph", graphOptions ? graphOptions : null)
            const chart = new Chart(context, {
                type: graphType,
                data: graphData,
                plugins: [ChartDataLabels],
                options: graphOptions ? graphOptions : {}
            })
            this.charts.push(chart);
        },
        drawPostAnalyticsGraphs(){
            // console.log("draw dem graphs")
            // getGroupPostsDataByDate
            
            // PICKUP VS PUSH BAR GRAPH
            const pickupVsPushGraph = { 
                context: document.getElementById('graph--posts-pickup-vs-push').getContext('2d'),
                type: 'bar',
                data: {
                    labels: [ "Push", "Pickup" ],
                    datasets: [
                        {
                            minBarThickness: 10,
                            maxBarThickness: 30,
                            label: "Group",
                            data: [ this.getPostPickupVsPushData.group.push, this.getPostPickupVsPushData.group.pickup],
                            backgroundColor: [ this.colors.secondary, this.colors.secondary ],
                        },
                        {
                            minBarThickness: 10,
                            maxBarThickness: 30,
                            label: "Public",
                            data: [ this.getPostPickupVsPushData.public.push, this.getPostPickupVsPushData.public.pickup],
                            backgroundColor: [ this.colors.primary, this.colors.primary ],
                        },
                    ]
                },
                options: {
                    responsive: true,
                    indexAxis: 'y',
                    animation: {
                        duration: 1400,
                        easing: 'linear',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            color: this.colors.smoke,
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                        },
                        legend: {
                            position: 'top',
                            labels: {
                                color: this.colors.invert
                            }
                        },
                        title: {
                            display: true,
                            text: 'Pickup vs Push',
                            color: this.colors.invert,
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                           
                        }
                    }
                },
            }
            this.drawGraph(pickupVsPushGraph.context, pickupVsPushGraph.type, pickupVsPushGraph.data, pickupVsPushGraph.options );

            // GROUP VS PUBLIC POST TYPE GRAPH
            const postTypeLineGraph = {
                context: document.getElementById('graph--posts-type-line').getContext('2d'),
                type: 'line',
                data: {
                    labels: [ ...this.getGroupPostsDataByDate.labels ],
                    datasets: [
                        {
                            label: `Group - ${this.postTotals.group.total_posts}`,
                            data: [ ...this.getGroupPostsDataByDate.totals ],
                            fill: true,
                            borderColor: this.colors.primary,
                            backgroundColor: this.hexToRGB(this.colors.primary, 0.1),
                            tension: 0.3
                        },
                        {
                            label: `Public - ${this.postTotals.public.total_posts}`,
                            data: [ ...this.getPublicPostsDataByDate.totals ],
                            fill: true,
                            borderColor: this.colors.secondary,
                            backgroundColor: this.hexToRGB(this.colors.secondary, 0.1),
                            tension: 0.3
                        }
                    ]
                },
                options: {
                    animation: {
                        duration: 1200,
                        easing: 'ease-out',
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                        legend: {
                            position: 'top',
                            labels: {
                                color: this.colors.invert
                            }
                        },
                        title: {
                            display: true,
                            text: 'Posts By Type',
                            color: this.colors.invert,
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                            title: {
                                display: false,
                                text: 'Date'
                            }
                        },
                        y: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                            title: {
                                display: false,
                                text: 'Total'
                            }
                        }
                    }
                }
            }

            this.drawGraph(postTypeLineGraph.context, postTypeLineGraph.type, postTypeLineGraph.data, postTypeLineGraph.options);
            
            // POSTS TOTAL GRAPH
             const postsTotalGraph = { 
                context: document.getElementById('graph--posts-total-line').getContext('2d'),
                type: 'line',
                data: {
                    labels: [ ...this.getTotalPostsByDateData.labels ],
                    datasets: [{
                        label: "Group & Public",
                        data: [ ...this.getTotalPostsByDateData.totals ],
                        fill: true,
                        borderColor: this.colors.primary,
                        backgroundColor: this.hexToRGB(this.colors.primary, 0.2),
                        tension: 0.3
                    }]
                },
                options: {
                    responsive: true,
                    animation: {
                        duration: 1500,
                        easing: 'ease-out',
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                        legend: {
                            labels: {
                                color: this.colors.invert
                            }
                        },
                        title: {
                            display: true,
                            text: 'Total Posts',
                            color: this.colors.invert
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                            title: {
                                display: false,
                                text: 'Date'
                            }
                        },
                        y: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                            title: {
                                display: false,
                                text: 'Total'
                            }
                        }
                    }
                },
            }
            this.drawGraph(postsTotalGraph.context, postsTotalGraph.type, postsTotalGraph.data, postsTotalGraph.options );

            // VID VS PHOTO MEDIATYPE GRAPH
            const postVideoVsPhotoGraph = { 
                context: document.getElementById('graph--posts-video-vs-photo').getContext('2d'),
                type: 'doughnut',
                data: {
                    // labels: [ ...this.getPostVideoVsPhotoData.labels ], // video, photo
                    labels: [ "Video", "Photo" ], // video, photo
                    datasets: [
                        {
                            label: "Media Type",
                            data: [ this.getPostVideoVsPhotoData.totals.public.video + this.getPostVideoVsPhotoData.totals.group.video, this.getPostVideoVsPhotoData.totals.public.photo + this.getPostVideoVsPhotoData.totals.group.photo  ],
                            borderColor: this.hexToRGB(this.colors.invert, 0.5),
                            backgroundColor: [ this.colors.primary, this.colors.secondary ],
                            hoverOffset: 2,
                        },
                    ]
                },
                options: {
                    responsive: true,
                    animation: {
                        duration: 1500,
                        easing: 'ease-out',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            formatter: (value, context) => {
                                const total = context.chart.data.datasets[0].data.reduce( (accumulator, curr) => accumulator + curr)
                                const percentage = (value / total) * 100;
                                return percentage.toFixed(2) + '%';
                            },
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke,
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                color: this.colors.invert
                            }
                        },
                        title: {
                            display: true,
                            text: 'Post Media Type',
                            color: this.colors.invert
                        },
                    },
                }
            }

            this.drawGraph(postVideoVsPhotoGraph.context, postVideoVsPhotoGraph.type, postVideoVsPhotoGraph.data, postVideoVsPhotoGraph.options);

            // YOUTUBE VS FILE GRAPH
            const youtubeVsFileGraph = { 
                context: document.getElementById('graph--file-vs-youtube').getContext('2d'),
                type: 'bar',
                data: {
                    labels: this.getYoutubeVsFileData.labels,
                    datasets: [
                        {
                            minBarThickness: 20,
                            maxBarThickness: 60,
                            data: [ this.getYoutubeVsFileData.totals.youtube, this.getYoutubeVsFileData.totals.file ],
                            backgroundColor: [ this.colors.Google, this.colors.primary ],
                        },
                    ]
                },
                options: {
                    responsive: true,
                    animation: {
                        duration: 900,
                        easing: 'linear',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke,
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Youtube vs File',
                            color: this.colors.invert
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                             color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                            title: {
                                display: false,
                                text: 'Total'
                            }
                        }
                    }
                },
            }
            this.drawGraph(youtubeVsFileGraph.context, youtubeVsFileGraph.type, youtubeVsFileGraph.data, youtubeVsFileGraph.options );

             // DESKTOP VS MOBILE GRAPH
            const desktopVsMobileGraph = { 
                context: document.getElementById('graph--desktop-vs-mobile').getContext('2d'),
                type: 'bar',
                data: {
                    labels: this.getDesktopVsMobileData.labels,
                    datasets: [
                        {
                            minBarThickness: 20,
                            maxBarThickness: 60,
                            label: "Device Type",
                            data: [ this.getDesktopVsMobileData.totals.desktop, this.getDesktopVsMobileData.totals.mobile ],
                            backgroundColor: [ this.colors.secondary, this.colors.primary ],
                        },
                    ]
                },
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    animation: {
                        duration: 900,
                        easing: 'linear',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke,
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Desktop vs Mobile',
                            color: this.colors.invert
                        },
                        label:{
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        }
                    }
                },
            }
            this.drawGraph(desktopVsMobileGraph.context, desktopVsMobileGraph.type, desktopVsMobileGraph.data, desktopVsMobileGraph.options );

            // IOS VS OTHER MOBILE DEVICE GRAPH
            const iosVsOtherGraph = { 
                context: document.getElementById('graph--ios-vs-other').getContext('2d'),
                type: 'bar',
                data: {
                    labels: this.getMobileDeviceData.labels,
                    datasets: [
                        {
                            barThickness: 30,
                            maxBarThickness: 60,
                            label: "Mobile OS",
                            data: [ this.getMobileDeviceData.totals.ios, this.getMobileDeviceData.totals.other ],
                            backgroundColor: [ this.colors.secondary, this.colors.primary ],
                        },
                    ]
                },
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    animation: {
                        duration: 1200,
                        easing: 'linear',
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            textShadowBlur: 5,
                            textShadowColor: this.hexToRGB('#000', 0.8),
                            color: this.colors.smoke,
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'iOS vs Other',
                            color: this.colors.invert
                        },
                        label:{
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        },
                        y: {
                            grid: {
                                color: this.hexToRGB(this.colors.invert, 0.1),
                            },
                        }
                    }
                },
            }
            this.drawGraph(iosVsOtherGraph.context, iosVsOtherGraph.type, iosVsOtherGraph.data, iosVsOtherGraph.options );
        }

    },
    computed: {
        ...mapGetters('adminAnalyticsStore', [
            'getTotalPosts',
            'getAveragePostsPerUserByDate',
            'getAveragePostsPerUserTotal',
            'getAverageDraftsPerUser',
            'getGroupPostsDataByDate',
            'getPublicPostsDataByDate',
            'getPostPickupVsPushData',
            'getTotalPostsByDateData',
            'getPostVideoVsPhotoData',
            'getYoutubeVsFileData',
            'getDesktopVsMobileData',
            'getAverageContributionsPerPostByDate',
            'getAverageContributionsPerPostTotal',
            'getAveragePostTime',
            'getAverageAudioTimePerPost',
            'getMobileDeviceData',
            'getAverageViewsPerPost',
            'getPostsPerLanguage',
            'getTopViewedGroupPosts',
            'getTopViewedPublicPosts'
        ]),
        ...mapState('adminAnalyticsStore', {
            dateFilter: state => state.dateFilter,
            randomData: state => state.randomData,
            postTotals: state => state.postTotals,
        }),
        userData(){
            return this.$store.state.userData;
        },
    },
    watch: {
        "postAnalytics.isLoading": {
            handler: function (newValue) {
                if(!newValue){
                    // console.log("load the analytics, group data is: ", this.getGroupPostsDataByDate)
                    /* IF THE CHARTS HAVEN'T BEEN CREATED YET, CREATE AND DRAW THEM. IF THEY HAVE, JUST UPDATE THEM */
                    if(this.charts.length === 0){
                        this.drawPostAnalyticsGraphs();
                    }
                    else {
                        this.destroyCharts();
                        this.drawPostAnalyticsGraphs();
                    }
                }
            }
        },
    },
    updated() {
       
    },
    mounted(){
        if(this.randomData){
            this.drawPostAnalyticsGraphs();
        }
        this.setColors();
    }
}
</script>
<style lang="scss" scoped>

.post-item {
    border-top: solid rgba(245, 245, 245, 0.1) 1px;
}

.border--left {
    border-left:  solid rgba(245, 245, 245, 0.1) 1px;
}

.graph-type-icon{
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.8;
    padding: 12px;
}



canvas {
    object-fit: contain;
    // fill their container
    height: 100%;
    max-height: 100%;
    // width: 100%;
    // max-width: 100%;
}

#post-analytics-grid {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
    height: 1200px;
    column-gap: 1em;
    row-gap: 1em;
    * {
        box-sizing: border-box !important;
    }
    #filler-item {
        display: none;
        background-color: rgba(43, 214, 27, 0.25) !important;
    }
    .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        border-radius: 12px;

        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: var(--v-inverseGrey-darken2);
        border: 1px solid rgba(255, 255, 255, 0.125);

        &:nth-child(1){
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
        }
        &:nth-child(2){
            grid-column: 3 / span 2;
            grid-row: 1 / span 2;
        }

        &:nth-child(3){
            display: none;
            grid-column: 3 / span 2;
            grid-row: 3 / span 1;
        }

        &:nth-child(4){
            grid-column: 1 / span 4;
            grid-row: 3 / span 3;
        }

        &:nth-child(5){
            grid-column: 5 / span 4;
            grid-row: 1 / span 5;
        }

        &:nth-child(6){
            grid-column: 9 / span 4;
            grid-row: 1 / span 5;
        }

        &:nth-child(7){
            grid-column: 1 / span 4;
            grid-row: 6 / span 3;
        }

        &:nth-child(8){
            grid-column: 5 / span 4;
            grid-row: 6 / span 3;
        }

        &:nth-child(9){
            grid-column: 9 / span 4;
            grid-row: 6 / span 3;
        }

        &:nth-child(10){
            grid-column: 1 / span 2;
            grid-row: 9 / span 2;
        }

        &:nth-child(11){
            grid-column: 3 / span 2;
            grid-row: 9 / span 2;
        }

        &:nth-child(12){
            grid-column: 5 / span 5;
            grid-row: 9 / span 2;
        }

        &:nth-child(13){
            grid-column: 10 / span 3;
            grid-row: 9 / span 2;
        }

        &:nth-child(14){
            grid-column: 1 / span 3;
            grid-row: 11 / span 2;
        }

        &:nth-child(15){
            grid-column: 4 / span 2;
            grid-row: 11 / span 2;
        }

        &:nth-child(16){
            grid-column: 6 / span 3;
            grid-row: 11 / span 2;
        }

        &:nth-child(17){
            grid-column: 9 / span 3;
            grid-row: 11 / span 2;
        }

    }
}


/* XS */
@media (max-width: 600px){
    
    #post-analytics-grid {
        height: 3000px;
        grid-template-rows: repeat(27, 1fr);
        column-gap: .6em;
        row-gap: .6em;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / 13;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 2 / span 1;
            }

            &:nth-child(3){ // drafts graph
                display: none;
                grid-column: 6 / span 2;
                grid-row: 1 / span 1;
            }

            &:nth-child(4){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 3 / span 2;
            }

            &:nth-child(5){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 5 / span 2;
            }

            &:nth-child(6){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 7 / span 2;
            }

            &:nth-child(7){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 9 / span 2;
            }

            &:nth-child(8){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 11 / span 2;
            }

            &:nth-child(9){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 13 / span 2;
            }

            &:nth-child(10){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 15 / span 1;
            }

            &:nth-child(11){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 16 / span 1;
            }

            &:nth-child(12){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 17 / span 2;
            }

            &:nth-child(13){
                // display: none;
                grid-column: 1 / span 6;
                grid-row: 19 / span 1;
            }

            &:nth-child(14){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 20 / span 2;
            }

            &:nth-child(15){
                // display: none;
                grid-column: 7 / span 6;
                grid-row: 19 / span 1;
            }

            &:nth-child(16){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 22 / span 3;
            }

            &:nth-child(17){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 25 / span 3;
            }

        }
        #filler-item {
            display: block;
        }
    }
    
    
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
     #post-analytics-grid {
        height: 2200px;
        grid-template-rows: repeat(18, 1fr);
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 5;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 1 / span 1;
            }

            &:nth-child(3){ // drafts graph
                display: none;
                grid-column: 6 / span 2;
                grid-row: 1 / span 1;
            }

            &:nth-child(4){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 4 / span 2;
            }

            &:nth-child(5){
                // display: none;
                grid-column: 1 / span 7;
                grid-row: 2 / span 2;
            }

            &:nth-child(6){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 7 / span 2;
            }

            &:nth-child(7){
                // display: none;
                grid-column: 8 / span 5;
                grid-row: 2 / span 2;
            }

            &:nth-child(8){
                // display: none;
                grid-column: 1 / span 6;
                grid-row: 9 / span 2;
            }

            &:nth-child(9){
                // display: none;
                grid-column: 7 / span 6;
                grid-row: 9 / span 2;
            }

            &:nth-child(10){
                // display: none;
                grid-column: 7 / span 6;
                grid-row: 16 / span 2;
            }

            &:nth-child(11){
                // display: none;
                grid-column: 1 / span 4;
                grid-row: 6 / span 1;
            }

            &:nth-child(12){
                // display: none;
                grid-column: 1 / span 12;
                grid-row: 11 / span 2;
            }

            &:nth-child(13){
                // display: none;
                grid-column: 5 / span 4;
                grid-row: 6 / span 1;
            }

            &:nth-child(14){
                // display: none;
                grid-column: 1 / span 6;
                grid-row: 16 / span 3;
            }

            &:nth-child(15){
                // display: none;
                grid-column: 9 / span 4;
                grid-row: 6/ span 1;
            }

            &:nth-child(16){
                // display: none;
                grid-column: 7 / span 6;
                grid-row: 13 / span 3;
            }

            &:nth-child(17){
                // display: none;
                grid-column: 1 / span 6;
                grid-row: 13 / span 3;
            }

        }

    }
    
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    #post-analytics-grid {
        height: 1800px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 5;
                grid-row: 1 / span 1;
            }
            &:nth-child(2){
                grid-column: 1 / span 5;
                grid-row: 2 / span 1;
            }

            &:nth-child(3){
                // display: none;
                grid-column: 3 / span 2;
                grid-row: 3 / span 1;
            }

            &:nth-child(4){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 5 / span 2;
            }

            &:nth-child(5){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 1 / span 2;
            }

            &:nth-child(6){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 3 / span 2;
            }

            &:nth-child(7){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 3 / span 2;
            }

            &:nth-child(8){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 5 / span 2;
            }

            &:nth-child(9){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 7 / span 2;
            }

            &:nth-child(10){
                // display: none;
                grid-column: 1 / span 4;
                grid-row: 12 / span 1;
            }

            &:nth-child(11){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 7 / span 1;
            }

            &:nth-child(12){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 9 / span 2;
            }

            &:nth-child(13){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 8 / span 1;
            }

            &:nth-child(14){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 9 / span 2;
            }

            &:nth-child(15){
                // display: none;
                grid-column: 1 / span 4;
                grid-row: 11 / span 1;
            }

            &:nth-child(16){
                // display: none;
                grid-column: 5 / span 4;
                grid-row: 11 / span 3;
            }

            &:nth-child(17){
                // display: none;
                grid-column: 9 / span 4;
                grid-row: 11 / span 3;
            }

        }

    }
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    #post-analytics-grid {
        height: 1500px;
        .grid-item {
            &:nth-child(1){
                grid-column: 1 / span 3;
                grid-row: 1 / span 2;
            }
            &:nth-child(2){
                // display: none;
                grid-column: 4 / span 2;
                grid-row: 1 / span 2;
            }

            &:nth-child(3){
                // display: none;
                grid-column: 3 / span 2;
                grid-row: 3 / span 1;
            }

            &:nth-child(4){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 3 / span 3;
            }

            &:nth-child(5){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 1 / span 3;
            }

            &:nth-child(6){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 4 / span 3;
            }

            &:nth-child(7){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 6 / span 2;
            }

            &:nth-child(8){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 8 / span 2;
            }

            &:nth-child(9){
                // display: none;
                grid-column: 6 / span 7;
                grid-row: 7 / span 2;
            }

            &:nth-child(10){
                // display: none;
                grid-column: 10 / span 3;
                grid-row: 9 / span 1;
            }

            &:nth-child(11){
                // display: none;
                grid-column: 10 / span 3;
                grid-row: 10 / span 1;
            }

            &:nth-child(12){
                // display: none;
                grid-column: 1 / span 5;
                grid-row: 10 / span 2;
            }

            &:nth-child(13){
                // display: none;
                grid-column: 1 / span 3;
                grid-row: 12 / span 1;
            }

            &:nth-child(14){
                // display: none;
                grid-column: 6 / span 4;
                grid-row: 9 / span 2;
            }

            &:nth-child(15){
                // display: none;
                grid-column: 4 / span 2;
                grid-row: 12 / span 1;
            }

            &:nth-child(16){
                // display: none;
                grid-column: 6 / span 4;
                grid-row: 11 / span 2;
            }

            &:nth-child(17){
                // display: none;
                grid-column: 10 / span 3;
                grid-row: 11 / span 2;
            }

        }

    }
}
.grid-item-dark {
     background-color: rgba(17, 25, 40, 0.75) !important;
}

.grid-item-light {
    background-color: rgba(218, 218, 218, 0.75) !important;
}
  
    
</style>