<template>
    <v-row v-if="type == 'alert'" justify="center" no-gutters>
        <v-alert app type="error">{{ text }}</v-alert>
    </v-row>

    <v-dialog v-else-if="type == 'dialog'" v-model="dialog" absolute persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
        <v-card class="rounded-xl pa-2">

            <v-card-title style="word-break: normal">
                <span class="text-wrap text-h5 font-weight-bold">{{title}}</span>
            </v-card-title>

            <v-card-text style="word-break: normal">
                <span class="font-weight-light text-subtitle-1">{{text}}</span>
            </v-card-text>

            <v-card-actions>
                <v-row justify="end" no-gutters>
                    <v-btn color="primary" text @click="dialogClicked('cancel')">Cancel</v-btn>
                    <v-btn v-if="action === 'home'" color="primary" text @click="dialogClicked('delete')">Delete</v-btn>
                    <v-btn v-else-if="action === 'done'" color="primary" text @click="dialogClicked('delete')">Continue</v-btn>
                    <v-btn v-else color="red" text @click="dialogClicked('delete')">Delete</v-btn>
                </v-row>
            </v-card-actions>

        </v-card>
    </v-dialog>


    <v-snackbar app v-else-if="text.length <= 66 && type == 'snackbar' || type == 'ar-snackbar'" v-model="snackbar" centered top timeout="5000" multi-line :style="type=='ar-snackbar' ? 'z-index: 9999; direction: rtl;' : 'z-index:9999;'">
        {{ text }}
        <!-- {{ truncatedText[0] + "..." }} -->
        <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="snackbarClicked">{{type == 'snackbar' ? 'Close' : 'يغلق'}}</v-btn>
        </template>
    </v-snackbar>
    <v-snackbar app v-else-if="text.length > 66 && type == 'snackbar' || type == 'ar-snackbar'" v-model="snackbar" top centered timeout="5000" multi-line :style="type=='ar-snackbar' ? 'z-index: 9999; direction: rtl;' : 'z-index:9999;'">
        {{ truncatedText[0] + "..." }}
        <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="snackbarClicked">{{type == 'snackbar' ? 'Close' : 'يغلق'}}</v-btn>
        </template>
    </v-snackbar>

    <!-- <v-snackbar app v-else-if="text.length <= 66 && type == 'ar-snackbar'" v-model="snackbar" centered top timeout="5000" multi-line style="z-index: 9999; direction: rtl;">
        {{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="snackbarClicked">Close</v-btn>
        </template>
    </v-snackbar>
    <v-snackbar app v-else-if="text.length > 66 && type == 'ar-snackbar'" v-model="snackbar" top centered timeout="5000" multi-line style="z-index: 9999; direction: rtl;">
        {{ truncatedText[0] + "..." }}
        <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="snackbarClicked">Close</v-btn>
        </template>
    </v-snackbar> -->

  
</template>

<script>
export default {
    name: "Alert",
    props: ['type', 'text', 'title', 'action', 'dialogVal', 'alertVal', 'alertTypeVal', 'snackbarVal',  'snackbarTimeoutVal', 'dontShowCloseButtonVal',],
    data: function () {
        return {
            dialog: this.dialogVal,
            alert: this.alertVal,
            snackbar: this.snackbarVal,
            snackbarTimeout: this.snackbarTimeoutVal,
            dontShowCloseButton: this.dontShowCloseButtonVal,
            alertType: this.alertType,
            truncatedText: '',
        };
    },
    methods: {
        dialogClicked: function (value) {
            if (value === "delete") {
                this.$emit("dialogClicked", true);
                this.dialog = false;
            } else {
                this.$emit("dialogClicked", false);
                this.dialog = false;
            }
        },
        snackbarClicked: function () {
            this.$emit("snackbarClicked", false);
            this.snackbar = false;
        },
    },
    watch: {
        snackbar(newVal, oldVal){
            if(!newVal){
                this.$store.commit('alertUser', { show: false, text: '', type: 'snackbar' });
            }
        },
        dialog(newVal, oldVal){
            if(!newVal){
                this.$store.commit('alertUser', { show: false, text: '', type: 'dialog' });
            }
        },
    },
    created: function () {
        // this.truncatedText = this.text.match(/.{37}/);
        if (this.text.length >= 67){
            this.truncatedText = this.text.match(/.{67}/);
        }
    },
};
</script>

<style>
.v-card-title.v-card-text {
    word-break: normal !important;
}
</style>