<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="12" lg="12" md="10" sm="10">

            <v-row justify="center" no-gutters>
                <span class="text-h6 text-sm-h5 text-center">Record Your Voice</span>
            </v-row>

            <!-- DUAL DEVICE CREATION ***REMOVED ON OCTOBER 28, 2022 AT MARTY'S REQUEST (last seen in code on January 2, 2024 in vue3 git branch)***  -->

            <v-row justify="center" no-gutters class="mt-4">
                <v-col cols="12" xl="8" lg="8" md="12" sm="12" xs="12" class="pr-lg-5">
                    <v-row id="vidPlayerContainer" justify="center" class="ma-0 pa-0">
                        <VideoPlayer
                            ref="vidPlayer"
                            :options="vidOptions"
                            :isCreating="true"
                            :videoLoading="videoLoading"
                            :anonymousMode="propPostData.anonymousMode || false"
                            :playbackStyleAudioFirst="playbackStyleAudioFirst"
                            :audioElementOrSegmentClicked="audioElementOrSegmentClicked"
                            @videoDuration="setVideoDuration"
                            @seeked="videoPlayerSeeked"
                            @controlsEnabled="($refs.vidPlayer.getMarkers().length <= 0 && audioElementOrSegmentClicked) ? $refs.vidPlayer.placeMarkers(currentAudioElement.audioData.audio, currentAudioElement.audioData.subtitles, currentAudioElement.audioData.username): null;"
                            @playerReady="getAudioData"
                            @timeUpdate="timeUpdate"
                            @visibilityChanged="audioPlayer.pause()"
                            @videoPauseButtonClicked="pauseProgressBar"
                            @videoPlayButtonClicked="playProgressBar"
                        />

                        <v-row id="durationBarContainer" justify="center" class="ma-0 pa-0">
                            <v-col cols="12" xl="10" lg="10" md="12" sm="12" class="ma-0 pa-0">
                                <v-row v-if="isMobile" justify="end" align="center" class="full-width ma-0 pa-0">
                                    <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar" class="mt-n4 mb-2"/>
                                    <v-btn v-if="!audioLoading && !isRecording" depressed fab small raised style="z-index: 2; transform: translateY(50%);" class="primary hidden-lg-and-up mr-2" @click.stop="showContributionDialogForMobile = true">
                                        <v-icon large justify="center" align="center">mdi-waveform</v-icon>
                                    </v-btn>
                                    <Push v-if="userData.pushToTawq" :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                                    <Pickup v-else :isDraftPost="isDraftPost" :tawqin="tawqin" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                                    <v-dialog v-if="!audioLoading && !isRecording" v-model="showContributionDialogForMobile" transition="dialog-bottom-transition" fullscreen scrollable overlay-opacity="0.9" class="hidden-md-and-up">
                                        <!-- <template v-slot:activator="{ on }">
                                            <Push v-if="userData.pushToTawq" :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                                            <Pickup v-else :isDraftPost="isDraftPost" :tawqin="tawqin" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                                            <v-btn v-on="on" depressed fab small raised style="z-index: 2; transform: translateY(50%);" class="primary hidden-lg-and-up mr-2">
                                                <v-icon large justify="center" align="center">mdi-waveform</v-icon>
                                            </v-btn>
                                        </template> -->

                                        <v-card>
                                            <v-toolbar top dense class="full-width d-flex flex-row justify-start align-center primary pa-2">
                                                <!-- <v-btn icon small @click="showContributionDialogForMobile = false"> -->
                                                <v-btn icon small @click="closeMobileContributionCollection">
                                                    <v-icon color="icon--text">mdi-close</v-icon>
                                                </v-btn>
                                                <v-toolbar-title>Contributions</v-toolbar-title>
                                            </v-toolbar>
                                            <ContributionCollection
                                                v-if="showContributionDialogForMobile && showContributions"
                                                :audioData="audioData"
                                                :filter="filter"
                                                :selectedAudioId="selectedAudioId"
                                                :audioLoading="audioLoading"
                                                :videoLoading="videoLoading"
                                                :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                                                :isCreating="true"
                                                @audioChanged="changeAudioElement"
                                                @changeAudioElement="changeAudioSegment" />
                                        </v-card>
                                    </v-dialog>
                                </v-row>

                                <v-row v-else justify="space-between" align="center">
                                    <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar"/>
                                    <Push :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                                </v-row>
                            </v-col>

                            <v-col cols="12" xl="10" lg="10" md="12" sm="12" class="caption-card-container d-flex flex-column-reverse inverseGrey py-6">
                                <ContributionCaptionCard
                                    v-for="(data, key) in tawqData"
                                    :key="parseInt(key)"
                                    :index="parseInt(key)"
                                    :showCaptions="(newAudioData.languageSelected.text === 'None (Disable Auto Captions)' ? false : true)"
                                    :loading="data.caption === null || data.audio === null"
                                    :videoDuration="videoDuration"
                                    @deleteAudioSegment="deleteAudioSegment"
                                    @audioPlay="playAudioSegment"
                                    @updateScroll="updateScroll"
                                    @editState="sendEditStateToPush"
                                />
                                <v-divider v-if="hasAudio"/>
                                <!-- <div v-if="!showCaptions" class="text-lg-subtitle-2 text-caption text-center">Automatic captioning has been disabled. To enable, go to back to step 1 and select the language you will be speaking. You must record at least once to move to the review process.</div> -->
                                <div :class="(newAudioData.languageSelected.text === 'None (Disable Auto Captions)' ? 'mb-2' : !isMobile ? 'mt-8' : '')" class="text-lg-subtitle-2 text-caption text-center">{{((newAudioData.languageSelected.text === 'None (Disable Auto Captions)' && !showCaptions) ? 'Automatic captioning has been disabled. To enable, go back to step 1 and select the language you will be speaking. You must record at least once to move to save.' : (newAudioData.languageSelected.text === 'None (Disable Auto Captions)' && showCaptions) ? 'Automatic captioning has been disabled. To enable, delete your current segments and go to back to step 1 and select the language you will be speaking.' : (!showCaptions ? 'You must record at least once to move to save.' : ''))}}</div>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
                <v-col id="contributionCollectionContainer" cols="12" xl="3" lg="4" md="12" sm="12" style="overflow-y: scroll;" class="hidden-md-and-down">
                    <ContributionCollection
                        :audioData="audioData"
                        :filter="filter"
                        :selectedAudioId="selectedAudioId"
                        :audioLoading="audioLoading"
                        :videoLoading="videoLoading"
                        :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                        :isCreating="true"
                        @audioChanged="changeAudioElement"
                        @changeAudioElement="changeAudioSegment" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../../firebaseConfig";
import VideoPlayer from "../VideoPlayer";
import ContributionCaptionCard from "./ContributionCaptionCard";
import ContributionCollection from "./ContributionCollection";
import Push from "../Mobile/Push";
import Pickup from "../Mobile/Pickup";
import RecordProgressBar from "../RecordProgressBar.vue";

export default {
    props: ["previewVideo", "propPostData", "propAudioData", "matchAudioStartTimesWithSelectedContribution"],
    components: {
        VideoPlayer,
        ContributionCaptionCard,
        ContributionCollection,
        Push,
        Pickup,
        RecordProgressBar,
    },
    data: function () {
        return {
            isDraftPost: false, // in contribution this will always be false. We send value to push/pickup, so we need to set it to false here.
            videoLoading: true, // Used to put the video player in, or take out of, a loading state. When true the vjs-loading-spinner will show, else it's hidden.
            currentMobile: {
                state: "@pause",
                prevState: "@play",
            },
            segStart: "",
            videoSeg: {
                segment: 0,
                vStart: null,
            },
            vIgnore: 0.5,
            tawqin: {},
            dbRefs: {
                subtitleRef: null,
                conversionRef: null,
            },
            vidOptions: {
                autoplay: false,
                // aspectRatio: "16:9",
                controls: false,
                // controls: true,
                responsive: true,
                // fluid: true,
                sources: {
                    src: this.previewVideo.src,
                    type: this.previewVideo.type,
                },
                techOrder: ["html5", "youtube"],
                textTrackSettings: false,
                html5: {
                    nativeTextTracks: false,
                    nativeControlsForTouch: false,
                    nativeAudioTracks: false,
                },
                youtube: {
                    enablejsapi: 1,
                    fs: 0,
                    playsinline: 1,
                    cc_load_policy: 3,
                    // cc_load_policy: 0,
                    disablekb: 1,
                    ytControls: 0,
                    // origin: "https://tawq.in" || window.location.orgin, // maybe can use this instead of having to comment out below for testing?
                    // origin: "https://tawq.in", // uncomment for deploy to dev/main
                    origin: window.location.origin, // uncomment for testing only
                    modestbranding: 1,
                    start: 0,
                    // widget_referrer: "https://tawq.in",
                },
            },
            emitTime: 0,
            timePlaying: 0,
            INTERVAL: 1000,
            captionTimer: null,
            audioTimer: null,
            showProgressBar: false,
            editState: false,
            showCaptions: false,
            showContributions: false,
            showContributionDialogForMobile: false, // this is for mobile screens, and will show the dialog so that the user can select contributions
            showSelectedContributionTimeout: null,
            mobileAudioElementSelected: null, // true if play button on audioElement selected, false if play button on audioSegment selected.
            changeValueForDatabaseCapture: 0,
            isRecording: false,
            isPlayingOwnCreatedSegment: false,

            /* new data for seeing contributions while creating is below */
            audioLoading: true,
            audioData: [], // this is used for saved contributions, not the new contributions!
            selectedAudioId: null,
            currentAudioElement: null, // this will be the currently selected AudioElement.
            currentAudioSegment: null, // this will be the current segment loaded into the audioPlayer.
            previousAudioSegment: null,
            nextAudioSegment: null, // this will be the next audio segment to be loaded into the audioPlayer.
            audioElementOrSegmentClicked: false,
            audioCanPlay: false, // Used to make sure audio is ready to play when marker is reached.
            audioPlayer: new Audio(),
            preloadAudioPlayer: new Audio(),
            audioElementHasBegunPlaying: false,
            filter: {
                languageSelected: "All",
                languageCodes: ["All"],
            },
            playbackStyle: "pauseplay", // this.$store.state.userData.playbackStyle ? 'continuous' : 'pauseplay', // old code that was removed by Scott Brady on February 27, 2024 - always 'pauseplay' for ease in matching of audio start times
            playbackStyleAudioFirst: false, // always false, so the audio of a contribution will play at the end of the segment. I.E. if a contribution starts at 2 seconds and has a duration of 1.5 seconds, the audio will play at 3.5 seconds
            vidPlayerVolume: null,
            continuePlayingAudioSegmentsTimeout: null,
            // captionVolume: 1.0, // look into this and see if we need to add this code so the caption volume will update.
            VID_PLAYER_PLAY_DELAY: 1500, // 1.5 seconds

        };
    },
    methods: {
        turnOnRefs: function (userId) {
            this.dbRefs.subtitleRef = firebase.rtDb.ref(`${userId}/${this.newAudioData.audioDataId}/subtitle`);
            this.dbRefs.conversionRef = firebase.rtDb.ref(`${userId}/${this.newAudioData.audioDataId}/conversion`);
        },
        turnOffRefs: function() {
            this.dbRefs.subtitleRef.off();
            this.dbRefs.conversionRef.off();
        },
        setVideoDuration: function (duration) {
            this.videoDuration = duration;
        },
        closeMobileContributionCollection: function () {
            if (this.showSelectedContributionTimeout) {
                clearTimeout(this.showSelectedContributionTimeout);
                this.showSelectedContributionTimeout = null;
                this.mobileAudioElementSelected.isAudioElement ? this.loadAndPreloadFirstAudioData() : this.loadAndPreloadAudioDataAfterClick(this.mobileAudioElementSelected.segmentToPlay);
            }
            this.showContributionDialogForMobile = false
        },
        pauseProgressBar: function () { // this value is used to send to Push.vue so that it will update the play/pause value of the button on screen
            clearInterval(this.audioTimer);

            if (this.currentMobile.state !== "@record") this.showProgressBar = false;
        },
        playProgressBar: function () { // this value is used to send to Push.vue so that it will update the play/pause value of the button on screen
            if (this.isPlayingOwnCreatedSegment) return; // added so that when a user is playing their own created segment we wont start the recording progress bar
            clearInterval(this.audioTimer);
            this.showProgressBar = true;

            this.audioTimer = setInterval(() => {
                this.timePlaying += this.INTERVAL;
            }, this.INTERVAL);
        },
        controlVideoState: function (currState, prevState) {
            if (this.continuePlayingAudioSegmentsTimeout) {clearTimeout(this.continuePlayingAudioSegmentsTimeout)}; // clear this timeout so the video player will play after a user is done recording.
            const vidPlayer = this.$refs.vidPlayer;
            if (!vidPlayer) return;

            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.$refs.vidPlayer.playerSetVolume(this.vidPlayerVolume); // sets the volume back to what it was before playing audio seg

            this.currentMobile.state = currState;
            this.currentMobile.prevState = prevState;

            if (currState === "@pause") vidPlayer.playerPause();

            if (currState === "@play") {
                vidPlayer.playerPlay();
                if (prevState === "@record") { // reset progress bar timer
                    this.isRecording = false;
                    clearInterval(this.captionTimer);
                    this.timePlaying = 0;
                    this.emitTime = 0;

                    const segEnd = Date.now();
                    var dt = (Math.abs(segEnd - this.segStart) / 1000) % 60; // get seconds from vid segment difference
                    if (dt > this.vIgnore) {
                        this.videoSeg.segment = this.findLargestSegId + 1;
                        this.videoSeg.dt = dt;
                        var videoSegValue = JSON.parse(JSON.stringify(this.videoSeg));
                        this.$set(this.tawqData, this.videoSeg.segment, {
                            'videoSeg': videoSegValue,
                            'audio': (typeof this.tawqData[this.videoSeg.segment] != 'undefined') ? this.tawqData[this.videoSeg.segment].audio : null,
                            'caption': (typeof this.tawqData[this.videoSeg.segment] != 'undefined') ? this.tawqData[this.videoSeg.segment].caption : null,
                        });
                        this.videoSeg.vStart = null;
                    } else { // This case only happens when a recording is not long enough, that is to say, less than half a second. So this should only be called when the record/stop button(s) are pressed in rapid succession. When this happens the 'Next' button become disabled and will not reenable unless the below function is called
                        this.checkForErrorInTawqData(this.tawqData);
                    }
                }
            }

            if (currState === "@record") {
                this.contentCreationStep.complete = false;
                this.isRecording = true;
                if (!this.showProgressBar) this.showProgressBar = true; // if user clicks on record before video enters play state

                vidPlayer.playerPause();
                clearInterval(this.audioTimer);
                this.segStart = Date.now();
                this.videoSeg.vStart = vidPlayer.playerGetTime();
                this.emitTime = 0;

                this.captionTimer = setInterval(() => {
                    this.emitTime += this.INTERVAL;
                }, this.INTERVAL);
            }
        },
        updateTawqDataAfterUploadAudioRec: function (audioSeg) {
            if (audioSeg.error) { // occurs in Push.vue or Pickup.vue, when audio file fails to upload to firebase cloud storage
                this.tawqData[audioSeg.segment].error = audioSeg.error;
                this.tawqData[audioSeg.segment].audio = audioSeg;
                this.tawqData[audioSeg.segment].caption = "*This Audio Segment Failed. Delete it to Continue*";
                this.contentCreationStep.complete = false;
            } else {
                let timeout = 570000; // 9.5 minutes, just to make sure the cloud function times out.
                const timeoutId = setTimeout(() => { // because there is a timeout of 9 minutes for the cloud function, and there is no error sent if the cloud function times out, we will set a timeout function here (since this is the first place we know the audio file has been uploaded).
                    this.$emit("clearCloudFunctionTimeout", audioSeg.segment, false); // timeout function completed, which means the cloud function reached timeout limit of 9 minutes. only clear timeoutId from parent (NewPost.vue) components cloudFunctionTimeoutIds
                }, timeout);
                this.$emit("setCloudFunctionTimeout", audioSeg.segment, timeoutId);

                if (typeof this.tawqData[audioSeg.segment] === "undefined") {
                    // we can't create a new audio object here because we won't have the video seg data for it...return;
                    return;
                } else if (typeof this.tawqData[audioSeg.segment].audio === 'undefined' || this.tawqData[audioSeg.segment].audio === null) { // saving the audio object for the first time
                    let startTime = this.tawqData[audioSeg.segment].videoSeg.vStart // time of video when record is pressed
                    // let startTime = Math.max(0, this.tawqData[audioSeg.segment].videoSeg.vStart - this.tawqData[audioSeg.segment].videoSeg.dt); // time of video when record is pressed minus length of recording
                    if (this.currentAudioElement !== null && this.previousAudioSegment !== null) {
                        let withinSpecifiedTime = (Math.abs((this.currentAudioElement.audioData.audio[this.previousAudioSegment.segment - 1].audioStart + this.currentAudioElement.audioData.audio[this.previousAudioSegment.segment - 1].audioDur) - this.tawqData[audioSeg.segment].videoSeg.vStart) <= 1.0);
                        let isGreaterThanCurrentSegment = (this.tawqData[audioSeg.segment].videoSeg.vStart >= this.currentAudioElement.audioData.audio[this.previousAudioSegment.segment - 1].audioStart);
                        startTime = (this.matchAudioStartTimesWithSelectedContribution && isGreaterThanCurrentSegment && withinSpecifiedTime) ? this.previousAudioSegment.audioStart : startTime; // this.currentAudioElement.audioData.audio[this.previousAudioSegment.segment - 1].audioStart;
                    }

                    audioSeg.audioStart = startTime;
                    audioSeg.audioDur = this.tawqData[audioSeg.segment].videoSeg.dt;
                    audioSeg.audioEnd = audioSeg.audioStart + audioSeg.audioDur;

                    this.tawqData[audioSeg.segment].audio = audioSeg;
                } else { // we already have an audio object for this audio seg id (happens if they go back and forth between steps after already creating captions)
                    return;
                }
            }
        },
        pushTawqinData: function () {
            this.changeValueForDatabaseCapture++ // if we do not update this value when tawqinRef.set() is called below it will not recognize an update and there is a potential we will enter an error state that the user cannot get out of.
            var startSegId = this.findLargestSegId;
            var data = {
                languageSelected : this.newAudioData.languageSelected,
                postId : this.newAudioData.postId,
                audioDataId : this.newAudioData.audioDataId,
                startSegId: startSegId,
                changeValueForDatabaseCapture: this.changeValueForDatabaseCapture,
            };
            this.tawqin = data;
        },
        checkForErrorInTawqData: function (data) { // This function is used to make sure there is not an error in any of the completed AudioSegments. If there is we don't allow the user to move on in the creation process.
            if (this.isRecording && !this.contentCreationStep.complete) return;
            this.contentCreationStep.complete = !Object.values(data).some(item => (item.error || (item.caption === null)));
        },
        setAudioSegmentURL: function (filename, location) {
            let storageRef = firebase.storage.ref();
            storageRef.child(`${location}`).getDownloadURL().then((url) => {
                this.tawqData[parseInt(filename)].audio.audioURL = url;
                this.checkForErrorInTawqData(this.tawqData);
                this.$emit("clearCloudFunctionTimeout", parseInt(filename), true); // cloud function completed, clear timeout function and timeoutId from parent (NewPost.vue) components cloudFunctionTimeoutIds
            }).catch((error) => {
                // console.log("An error occured when setting the audio segment url. The error is: ", error);
                console.log("An error occured when setting the audio segment url. The error is: ", error);
                this.$store.commit('alertUser', { show: true, text: `Oops, Couldn't retrieve your audio segments at this time`, type: 'snackbar' });
            });
        },
        playAudioSegment: function(audioSegId) {
            if (this.continuePlayingAudioSegmentsTimeout) {clearTimeout(this.continuePlayingAudioSegmentsTimeout)}; // clear this timer so it doesn't try to play the video if user is playing their own segment
            this.pauseProgressBar(); // called here so that the recording progress bar does not continue to play while user is listening to their own created segment
            !this.audioPlayer.paused && this.audioPlayer.pause(); // if the player is NOT paused then pause, else nothing;
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true);

            this.isPlayingOwnCreatedSegment = true;
            this.creationMode = true;
            this.$refs.vidPlayer.playerPause();

            let videoTimeAtPlay = this.$refs.vidPlayer.playerGetTime();
            let audioToPlay = this.tawqData[audioSegId].audio;
            this.audioPlayer.src = audioToPlay.audioURL;
            this.$refs.vidPlayer.playerSetVolume(0);
            this.audioPlayer.play();

            this.audioPlayer.addEventListener("ended", () => {
                this.$refs.vidPlayer.playerSetVolume(1);
                this.isPlayingOwnCreatedSegment = false;
                this.$refs.vidPlayer.playerSetTime(videoTimeAtPlay - 0.1);
                this.$refs.vidPlayer.playerPlay();
            }, { once: true });
        },
        deleteAudioSegment: function(data) { // this function deletes the segment from the firestore database
            if (data.audioSegmentFailed) { this.deleteAudioSegmentLocally(data); return; } // segment failed to upload, only delete locally

            var storageRef = firebase.storage.ref("audioSegments");
            var audioRef = storageRef.child(`/${this.propPostData.postId}/${this.newAudioData.audioDataId}/${data.audioSegId}`);

            audioRef.delete().then(() => {
                    this.deleteAudioSegmentLocally(data); // go delete the local list of audioData
            }).catch((error) => {
                console.log("error deleting audio file: ", error.message);
                this.$store.commit('alertUser', { show: true, text: `Couldn't delete the audio file. Error: ${error.message}`, type: 'snackbar' });
            });
        },
        deleteAudioSegmentLocally: function (data) {
            this.creationMode = true;
            this.$delete(this.tawqData, data.audioSegId);
            this.pushTawqinData();
            this.checkForErrorInTawqData(this.tawqData);
            if (!this.hasAudio) this.contentCreationStep.complete = false;
        },
        updateScroll: function() {
            var element = document.getElementsByClassName("caption-card-container")[0];
            let height = element.scrollHeight;
            element.scrollTop = ( 0 - height );
        },
        sendEditStateToPush: function (editState) {
            this.editState = editState;
        },
        getAudioData: async function () {
            /* This function will retrieve the metadata for the audio segments, not the audio itself.
            We will query the owners audio segments first, adding them to our local array. after we will
            query every audio segment, and only add it to the array if it hasn't been added yet. Firebase Querying
            does not allow '!= ', or for us to use where clause with orderby clause on different fields. */
            this.audioLoading = true;

            let permissions = await this.setAudioPermissions(); 
            if (permissions === 0) return;

            this.audioData = [];
            let audioRef = firebase.db.collection("audioSegments").where("postId", "==", this.currentPost.postId).orderBy("date", "desc");

            try {
                const snapshot = await audioRef.get();

                if (snapshot.empty) { this.audioLoading = false; return; }

                snapshot.forEach((doc) => {
                    let data = {
                        audioData: doc.data(),
                        audioDataId: doc.id,
                    };

                    if (data.audioData.audio.length > 0) {
                        if (data.audioDataId == this.currentPost.postAudioDataId) { // it is the main audioData that is associated with the post, add it to the front
                            this.audioData.unshift(data);
                        } else { // not main audio, add to back of list
                            if (permissions === 1 || (permissions === 2 && doc.data().userId === firebase.auth.currentUser.uid)) {
                                this.audioData.push(data);
                            }
                        }
                    }

                    if (typeof data.audioData.languageName !== 'undefined' && !this.filter.languageCodes.includes(data.audioData.languageName)) {
                        this.filter.languageCodes.push(data.audioData.languageName);
                    }
                });

                this.audioLoading = false;
                this.videoLoading = false;
                this.showContributions = true;
            } catch (error) {
                console.error(error);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong: ${error.message}`, type: 'snackbar' });
            }
        },
        setAudioPermissions: async function () { // permisions: 0 = none, 1 = all,  2 = only current users and main one
            if (this.currentPost.postType !== 'group') return 1; // public post, allow to see all

            const gId = this.currentPost.groupId;
            const index = this.userGroups.findIndex((group) => group.groupId === gId);

            if (index === -1) { // gId does not exist in userGroups
                if (this.userData.admin) return 1; // user is admin, allow to see all

                this.letUserSeePost = false; // it is a group video, and they aren't part of that group....
                return 0;
            }

            let isAdmin = this.userGroups[index].admin;
            if (isAdmin || this.userData.admin) return 1; // user is admin or group admin, allow to see all

            if (this.userGroups[index].privateContributions) return 2; // group post has privateContributions set, only show the main/original audio and any audio created by the current user

            return 1;
        },
        setContributionCollectionContainerHeight(){
            // this is used to set the height on the contributioncollection container height for large and x-large screens (when it isn't in the dialog)
            const h1 = document.getElementById('vidPlayerContainer').offsetHeight;
            const h2 = document.getElementById('durationBarContainer').offsetHeight;
            const container = document.getElementById('contributionCollectionContainer');
            // container.style.maxHeight = `${Math.abs(h1 + h2 + 20)}px`;
            container.style.maxHeight = `${Math.abs(h1)}px`;
        },


        //// CONTRIBUTION PLAYING FUNCTIONALITY ////
        loadAndPreloadFirstAudioData: async function () {
            // This function is called on initial load of the page and anytime a new AudioElement is clicked.
            // It will load the first AudioSegment into the audioPlayer and if playbackStyle is pauseplay, it
            // will load in the second AudioSegment, otherwise, playbackStyle is continuous, and it will load
            // in the next, or one available after the overlapping segments.

            // load first AudioSegment into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[0].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[0];
            this.currentAudioSegment.index = 0;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[0].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[0].audioSegId;

            if (this.currentAudioElement.audioData.audio.length > 1) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(0,1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[1];
                    this.nextAudioSegment.index = 1;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[1].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[1].audioSegId;
                }
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }

            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        loadAndPreloadNextAudioData: async function () {
            // set this.preloadAudioPlayer.src (this.nextAudioSegment) to this.audioPlayer.src, since it is already preloaded it should be ready to go when set.
            this.audioPlayer.src = this.preloadAudioPlayer.src;
            this.audioPlayer.load();
            this.currentAudioSegment = this.nextAudioSegment;
            var nextAvailableIndexAfterOverlap;

            if (this.nextAudioSegment != null && (this.currentAudioElement.audioData.audio.length-1) >= (this.nextAudioSegment.index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index.
                    nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(this.nextAudioSegment.index, this.nextAudioSegment.index+1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        return;
                    }
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    nextAvailableIndexAfterOverlap = this.nextAudioSegment.index+1; // Check to see if index+1 is out of range, if so set this.nextAudioSegment to null
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                }
                this.preloadAudioPlayer.src = this.nextAudioSegment.audioURL;
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }
        },
        loadAndPreloadAudioDataAfterClick: async function (index) {
            // load AudioSegment at index into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[index].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[index];
            this.currentAudioSegment.index = index;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[index].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[index].audioSegId;
            // update the url to include the hash of the first AudioSegment?

            if ((this.currentAudioElement.audioData.audio.length-1) >= (index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index, if available.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(index,(index+1));
                    if (nextAvailableIndexAfterOverlap == null) {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[(index+1)];
                    this.nextAudioSegment.index = (index+1);
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[(index+1)].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[(index+1)].audioSegId;
                }
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }

            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        getNextAvailableIndexAfterOverlap: function (index, indexToCheck) {
            return new Promise( async (resolve) => {
                if (this.currentAudioElement.audioData.audio.length <= indexToCheck) {
                    resolve(null);
                } else {
                    if (this.currentAudioElement.audioData.audio[index].audioEnd < this.currentAudioElement.audioData.audio[indexToCheck].audioStart) {
                        resolve(indexToCheck);
                    } else {
                        let recursiveResult = await this.getNextAvailableIndexAfterOverlap(index, indexToCheck+1);
                        resolve(recursiveResult);
                    }
                }
            });
        },
        addAudioPlayerEventListeners: function () {
            this.audioPlayer.addEventListener('canplaythrough', this.audioCanPlayThrough/*, {once: true}*/);
            this.audioPlayer.addEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        removeAudioPlayerEventListeners: function () {
            this.audioPlayer.removeEventListener("canplaythrough", this.audioCanPlayThrough);
            this.audioPlayer.removeEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        audioCanPlayThrough: function () {
            this.audioCanPlay = true;
            if (this.videoLoading) this.videoLoading = false;
            // When videoLoading is set to false here, the function videoLoading() in VideoPlayer.vue's watch property
            // gets fired. Which in turn hides the loadingSpinner, shows the bigPlayButton, if necessary, updates the
            // markers and sets player controls to true. When player.controls(true) the .on("controlsenabled") listener
            // gets fired. This will playerPlay() the video player in VideoPlayer.vue and $emit("controlsEnabled") back
            // here to ViewPost.vue, which will call $refs.vidPlayer.placeMarkers();
        },
        audioEnded: function (/*event*/) {
            this.audioCanPlay = false;

            if (this.playbackStyle === "pauseplay" && this.matchAudioStartTimesWithSelectedContribution) { // Contribution Creation will always (as of February 28, 2024) be set to 'pauseplay' and user wants to match audio start times, give them time to press record before continuing playing video
                this.continuePlayingAudioSegmentsTimeout = setTimeout(()=> { // this timeout is used to delay playing the video player so the user has time to press record
                    this.$refs.vidPlayer.playerPlay();
                }, this.VID_PLAYER_PLAY_DELAY); // the amount of time may need to be adjusted
            } else { // user does not want to match start times, immediately continue playing video
                this.$refs.vidPlayer.playerPlay();
            }
            this.$refs.vidPlayer.removeCue(false);

            this.previousAudioSegment = this.currentAudioSegment;
            this.nextAudioSegment && this.loadAndPreloadNextAudioData();
            if (this.nextAudioSegment == null && (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length)) {
                this.currentAudioSegment = null;
                this.removeAudioPlayerEventListeners();
            }
        },
        timeUpdate: function (time) {
            if (this.isPlayingOwnCreatedSegment) return;
            if (this.videoLoading || this.currentAudioSegment == null || !this.audioPlayer.paused /*|| (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)*/) return;
            if ((this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)) this.currentAudioSegment.index += 1;
            if ((this.playbackStyle === "pauseplay" && !this.playbackStyleAudioFirst && Math.abs((this.currentAudioSegment.audioStart + this.currentAudioSegment.audioDur) - time) <= 0.2) && this.audioElementOrSegmentClicked) { // timeUpdate is called every 0.2 - 0.3 seconds, so we check for anything + or - 0.3
                this.playbackStyle === "pauseplay" && this.$refs.vidPlayer.playerPause();
                this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), this.currentAudioSegment.audioEnd, this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            }
        },
        videoPlayerSeeked: function (time) {
            if (this.isPlayingOwnCreatedSegment) return;
            if (this.videoLoading/*|| this.currentAudioSegment.audioStart == 0 && this.audioPlayer.paused*/) return;
            if (this.audioElementOrSegmentClicked) {
                this.videoLoading = true;
                !this.audioPlayer.paused && this.audioPlayer.pause();
                this.removeAudioPlayerEventListeners();
                this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

                [this.currentAudioSegment, this.nextAudioSegment] = [null, null];

                let nextAudioSegmentIndex = this.currentAudioElement.audioData.audio.findIndex((data) => {
                    if (Math.abs(data.audioStart - time) <= 0.3) {
                        return true;
                    }
                    if (data.audioStart >= time) {
                        return true;
                    }
                });

                if (nextAudioSegmentIndex == -1) {
                    this.videoLoading = false;
                    return;
                }
                this.loadAndPreloadAudioDataAfterClick(nextAudioSegmentIndex);
            }
        },
        changeAudioSegment: function (data) {
            //  this function is ran only when a audioSegment is clicked. It will update the markers if necessary and playSelectedAudioSegment
            if (this.isRecording) { this.$store.commit('alertUser', { show: true, text: "Cannot select a Contribution while recording.", type: 'snackbar' }); return; } // alerts user and does not allow them to select an AudioElement/Segment while recording. Mostly used for desktop size because the button to show the contributions dialog should not be visible while recording
            // this.showContributionDialogForMobile = !this.showContributionDialogForMobile; // if a user is using a mobile device and selects an AudioSegment then close the fullscreen dialog

            this.audioElementOrSegmentClicked = true;
            this.audioElementHasBegunPlaying = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause(); // if the player is NOT paused then pause, else nothing;
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();


            if (this.selectedAudioId === data.audioId) {
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart);
            } else {
                // formatAE refers to the new AudioElement data, updated to the correct format, that will be set to this.currentAudioElement
                let formatAE = {
                    audioData: data.audioData,
                    audioDataId: data.audioId,
                };
                this.currentAudioElement = formatAE;
                this.selectedAudioId = data.audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart);
                this.$router.replace({path: this.$route.name, query: { pid: this.propPostData.postId, postType: this.propPostData.postType, aid: this.selectedAudioId}});
            }
            if (this.isMobile) {
                this.mobileAudioElementSelected = {
                    isAudioElement: false,
                    segmentToPlay: data.audioSeg.index,
                };
                this.showSelectedContributionTimeout = setTimeout(() => {
                    this.showContributionDialogForMobile = !this.showContributionDialogForMobile; // if a user is using a mobile device and selects an AudioElement then close the fullscreen dialog
                    this.loadAndPreloadAudioDataAfterClick(data.audioSeg.index);
                }, 1000);
            } else {
                this.loadAndPreloadAudioDataAfterClick(data.audioSeg.index);
            }
        },
        changeAudioElement: function (audioData, audioId) {
            // This function is called Everytime the play button on an AudioElement is clicked.
            // Therefore we must reset the audioSegment number, or else it can/will increment higher
            // than the actual length of the audioData.

            if (this.isRecording) { this.$store.commit('alertUser', { show: true, text: "Cannot select a Contribution while recording.", type: 'snackbar' }); return; } // alerts user and does not allow them to select an AudioElement/Segment while recording. Mostly used for desktop size because the button to show the contributions dialog should not be visible while recording
            // this.showContributionDialogForMobile = !this.showContributionDialogForMobile; // if a user is using a mobile device and selects an AudioElement then close the fullscreen dialog

            this.audioElementOrSegmentClicked = true;
            this.audioElementHasBegunPlaying = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            if (this.selectedAudioId === audioId && this.audioElementHasBegunPlaying) {
                this.$refs.vidPlayer.playerSetTime(0.0);
            } else if (this.selectedAudioId != audioId && !this.audioElementHasBegunPlaying) {
                // This else if statement should only happen if the user presses the play button of an AudioElement right after initial
                // loading is done, but before auto play has started. Shouldn't happen very often!
                this.$refs.vidPlayer.removeMarkers();
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;
                this.$router.replace({path: this.$route.name, query: { pid: this.propPostData.postId, postType: this.propPostData.postType, aid: this.selectedAudioId}});
            } else {
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(0.0);
                this.$router.replace({path: this.$route.name, query: { pid: this.propPostData.postId, postType: this.propPostData.postType, aid: this.selectedAudioId}});
            }
            if (this.isMobile) {
                this.mobileAudioElementSelected = {
                    isAudioElement: true,
                    segmentToPlay: null,
                };
                this.showSelectedContributionTimeout = setTimeout(() => {
                    this.showContributionDialogForMobile = !this.showContributionDialogForMobile; // if a user is using a mobile device and selects an AudioElement then close the fullscreen dialog
                    this.loadAndPreloadFirstAudioData();
                }, 1000);
            } else {
                this.loadAndPreloadFirstAudioData();
            }
        },
        //// END CONTRIBUTION PLAYING FUNCTIONALITY ////
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        isIOS(){
            return this.$store.state.isIOSDevice;
        },
        currentPost(){
            return this.$store.state.currentPost;
        },
        userData(){
            return this.$store.state.userData;
        },
        userGroups(){
            return this.$store.state.userGroups;
        },
        creationMode: {
            get(){
                return this.$store.state.newContributionStore.creationMode;
            },
            set(value){
                this.$store.commit('newContributionStore/setCreationMode', value);
            }
        },
        contentCreationStep: {
            get(){
                return this.$store.state.newContributionStore.contributionCreationStep;
            },
            set(value){
                this.$store.commit('newContributionStore/saveContributionCreationStep', value);
            }
        },
        tawqData: {
            get(){
                let data = this.$store.state.newContributionStore.tawqData;
                if (Object.keys(data).length > 0) this.showCaptions = true;
                else this.showCaptions = false;
                return data;
            },
            set(value){
                this.$store.commit('newContributionStore/saveTawqData', value);
            }
        },
        findLargestSegId: function() {
            const tawqDataKeys = Object.keys(this.tawqData);

            if (tawqDataKeys.length <= 0) return 0;

            return tawqDataKeys.reduce((largest, key) => {
                const parsedKey = parseInt(key);
                return parsedKey > largest ? parsedKey : largest;
            }, 0);
        },
        newAudioData: {
            get(){
                return this.$store.state.newContributionStore.newAudioData;
            },
            set(value){
                this.$store.commit('newContributionStore/saveNewAudioData', value);
            }
        },
        videoDuration: {
            get() {
                return this.$store.state.newContributionStore.contributionCreationStep.videoDuration;
            },
            set(value){
                this.$store.commit('newContributionStore/saveVideoDuration', value);
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
    },
    watch: {
        creationMode: function (newValue) {
            if (newValue) {
                // We might be able to just remove the video controls so we dont need to remove the markers and then replace them when done recording.
                this.$refs.vidPlayer.removeMarkers(); // we don't want any markers during creation mode
            }
        },
    },
    created: function () {
        if (this.isIOS) this.playbackStyle = 'pauseplay';
        this.turnOnRefs(firebase.auth.currentUser.uid); //update realtime refs with current user Id to allow connection to mobile
        this.pushTawqinData();
    },
    mounted: function () {
        if (this.vidOptions.sources.type === "video/youtube") this.vidOptions.sources.src = this.previewVideo.src + `?controls="0"`;
        this.setContributionCollectionContainerHeight();
        window.addEventListener('resize', this.setContributionCollectionContainerHeight);

        this.dbRefs.subtitleRef.on("child_added", (snapshot) => {
            if (snapshot.val() === null) return;

            if (typeof this.tawqData[parseInt(snapshot.val().audioSegId)] === "undefined") {
                // console.log('ERROR: NOT SAVING CAPTION OBJECT BECAUSE THIS AUDIO ID IS UNDEFINED IN TAWQ DATA. audioSegId = ', snapshot.val().audioSegId); // FIGURE OUT HOW TO HANDLE THIS ERROR STATE!!!
                this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                this.tawqData[parseInt(snapshot.val().idToDelete)].error = true;
                this.tawqData[parseInt(snapshot.val().idToDelete)].caption = "*This Audio Segment Failed. Delete it to Continue*";
                this.contentCreationStep.complete = false;
                return;
            } else if (typeof this.tawqData[parseInt(snapshot.val().audioSegId)].caption === 'undefined' || this.tawqData[parseInt(snapshot.val().audioSegId)].caption === null) { // Ran the first time we are adding a caption for the specified audioSegId.
                const formattedCaption = typeof snapshot.val().subtitle !== 'undefined' ? snapshot.val().subtitle.charAt(0).toUpperCase() + snapshot.val().subtitle.slice(1) : "";
                this.tawqData[parseInt(snapshot.val().audioSegId)].caption = formattedCaption || snapshot.val().subtitle || "";
            } else { // we already have a caption for this audio seg id (happens if they go back and forth between steps after already creating captions)
                return;
            }
        },(error) => {
            console.error(error.message);
            this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
        });

        this.dbRefs.conversionRef.on("child_added", (snapshot) => {
            if (snapshot.val() === null) return;

            if (typeof this.tawqData[parseInt(snapshot.val().filename)] === "undefined") {
                this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                this.tawqData[parseInt(snapshot.val().idToDelete)].error = true;
                this.tawqData[parseInt(snapshot.val().idToDelete)].audio.audioURL = "failed";
                this.contentCreationStep.complete = false;
                return;
            } else {
                if (typeof this.tawqData[parseInt(snapshot.val().filename)].audio.audioURL != 'undefined' && this.tawqData[parseInt(snapshot.val().filename)].audio.audioURL != null) { // we have already retrieved the url for the audio (they go back and forth)
                    console.log("already retrieved the url");
                    // FIGURE OUT HOW TO HANDLE THIS ERROR STATE!!!
                    // do we need to clear the timeout here?
                    // this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                    return;
                }
                let filename = snapshot.val().filename;
                let location = snapshot.val().location;
                // we need to retrieve the url's for the audioSegments here, because the cloud function converts them to mp4 which will change the the download URL that they originally have when uploaded to storage
                this.setAudioSegmentURL(filename, location);
            }
        },(error) => {
            console.error(error.message);
            this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
        });
    },
    beforeDestroy: function () {
        this.creationMode = true;
        if (this.audioPlayer) {
            this.audioPlayer.pause();
            this.audioPlayer.remove();
        }
        clearInterval(this.captionTimer);
        clearInterval(this.audioTimer);
        this.turnOffRefs();
        // we remove the three refs below because if not, then it does a lot of unneccessary work anytime switching between steps while creating a post,
        // and this is the only component that needs that info
        this.dbRefs.subtitleRef.remove();
        this.dbRefs.conversionRef.remove();
        window.removeEventListener('resize', this.setContributionCollectionContainerHeight);
    },
};
</script>

<style>
.smallText {
    font-size: 10px;
}

.height--80 {
    height: 80vh !important;
    max-height: 80vh !important;
}

.caption-card-container {
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 10px;
}

.caption-card-container::-webkit-scrollbar {
    width: 7px;
    height: 100%;
}

/* Handle */
.caption-card-container::-webkit-scrollbar-thumb {
    background: #86c232 !important;
    height: 20% !important;
}

.contribution-collection-menu-container {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60vh;
    width: 100%;
    overflow-y: scroll;
}

.dialog-slide-up-enter-active {
    animation: dialog-slide-up 0.2s ease-out;
}

.dialog-slide-up-leave-active {
    animation: dialog-slide-up 0.2s ease-out reverse;
}

@keyframes dialog-slide-up {
    from{
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

/* XS */
@media (max-width: 600px){
    .caption-card-container {
        max-height: 35vh;
        min-height: 15vh;
    }
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    .caption-card-container {
        max-height: 28vh;
        min-height: 12vh;
    }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .caption-card-container {
        max-height: 40vh;
        min-height: 10vh;
    }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .caption-card-container {
        max-height: 22vh;
    }
}

/* XL */
@media (min-width: 1905px){
    .caption-card-container {
        max-height: 23vh;
    }
}
</style>
