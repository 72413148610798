<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelRemoveMember">
        <v-card v-if="!loading" class="rounded-xl">
            <v-card-title v-if="member.userId === currentUser" style="word-break: normal" class="text-center justify-center">
                <span class="font-weight-bold text-h5">To remove yourself from this group press the 'Leave Group' button in the top right corner of the group settings page.</span>
            </v-card-title>

            <v-card-title v-else style="word-break: normal" class="text-center justify-center">
                <span class="font-weight-bold text-h5">Remove or Delete <span class="font-weight-black">{{ member.username }}</span>?</span>
                <span class="font-weight-light text-subtitle-2 mt-3">Removing a user will <span class="font-weight-bold">ONLY</span> remove them from the group.
                    All of the content posted by that user will be still be available to view and contribute on. If you only remove a user, an admin will have to individually
                    delete all content posted by that user.
                </span>
                <span class="font-weight-light text-subtitle-2 my-0">Deleting a user from a group both removes the user and all of the content posted by that user.</span>
            </v-card-title>

            <v-card-actions>
                <v-btn color="primary" text @click="cancelRemoveMember">Cancel</v-btn>
                <v-row justify="end" no-gutters>
                    <v-btn v-if="member.userId != currentUser" color="primary" text @click="deleteMember">Delete</v-btn>
                    <v-btn v-if="member.userId != currentUser" color="primary" text @click="removeMember">Remove</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="font-weight-bold text-h5">{{ remove ? 'Removing' : 'Deleting'}} <span class="font-weight-black">{{ member.username }}</span></span>
            </v-card-title>
            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-light text-subtitle-2">*Do not refresh the page*</span><br/>
                    <span class="font-weight-light text-subtitle-2">This could take a while...</span><br/>
                    <!-- <span class="font-weight-light text-subtitle-2"></span><br/> -->
                    <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";


export default {
    name: "remove-member",
    props: ["dialog", "groupId", "member"],
    data() {
        return {
            currentUser: firebase.auth.currentUser.uid,
        };
    },
    methods: {
        removeMember: function () {
            var userId = this.member.userId;
            var group = {
                requestToJoin: this.member.requested,
                id : this.member.notifId,
                member: {
                    accepted: this.member.accepted,
                    role: this.member.role,
                },
            };
            this.$store.dispatch('removeUserDataFromGroup', {groupId: this.groupId, userId: userId, group: group, action: 'remove'});
        },
        deleteMember: function () {
            // the parameter, action, that is being passed indicates that we are deleting a member from the group so load the correct
            // dialog info in the groupsPageStore.
            this.$store.dispatch('deleteMemberPostAndContributionData', {groupId: this.groupId, member: this.member, action: 'delete', component: 'removeMember',});
        },
        cancelRemoveMember: function () {
            this.$store.commit('saveRemoveMemberDialog', false);
        },
    },
    computed: {
        loading(){
            return this.$store.state.groupsPageStore.deleteOrRemoveMember.loading;
        },
        remove(){
            return this.$store.state.groupsPageStore.deleteOrRemoveMember.remove;
        },
    },
};
</script>
