<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="closeDialog">
        <v-card class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Add or Remove Editors</span>
            </v-card-title>

            <v-row justify="center" class="mx-0">
                <v-col cols="12" xl="10" lg="10" md="10" sm="12" class="ma-0 pa-0">
                    <!-- START: if public post OR all your friends are part of the group -->
                    <v-list v-if="friendsList.length > 0 && friendsNotInGroupList.length <= 0" rounded class="pt-0" two-line>
                        <v-divider class="justify-center"/>
                        <template v-for="(friend,index) in friendsList">
                            <v-list-item :key="friend.userId" selectable dense class="align-center">
                                <v-list-item-avatar>
                                    <v-img v-if="friend.userProfilePhoto" :src="friend.userProfilePhoto" referrerpolicy="no-referrer" @error="friend.userProfilePhoto=null"></v-img>
                                    <v-img v-else src="../assets/no-user.png" :alt="friend.username"></v-img>
                                </v-list-item-avatar>

                                <v-row justify="center" align="center" class="px-1">
                                    <v-col cols="8" class="pa-0 ma-0 text-truncate">
                                        <span class="font-weight-bold text-subtitle-1">{{friend.username}}</span>
                                    </v-col>
                                    <v-col cols="4" align="end" class="hidden-sm-and-down">
                                        <v-btn v-if="!friend.editor" color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" :disabled="!friend.groupMember" @click.stop="addFriendEditor(friend,index)">Add
                                            <!-- <v-icon>mdi-account-plus</v-icon> -->
                                        </v-btn>
                                        <v-btn v-else color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" @click.stop="removeFriendEditor(friend,index)">Remove
                                            <!-- <v-icon>mdi-account-minus</v-icon> -->
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="4" align="end" class="hidden-md-and-up">
                                        <v-btn v-if="!friend.editor" color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" :disabled="!friend.groupMember" @click.stop="addFriendEditor(friend,index)">
                                            <v-icon>mdi-account-plus</v-icon>
                                        </v-btn>
                                        <v-btn v-else color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" @click.stop="removeFriendEditor(friend,index)">
                                            <v-icon>mdi-account-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="justify-center" :key="index"/>
                        </template>
                    </v-list>
                    <!-- END: if public post OR all your friends are part of the group -->

                    <!-- START: if you have friends who are and are not part of the group -->
                    <v-list v-else-if="friendsList.length > 0 && friendsNotInGroupList.length > 0" rounded class="pt-0" two-line>
                        <v-divider class="justify-center"/>
                        <template v-for="(friend,index) in friendsList">
                            <v-list-item :key="friend.userId" selectable dense class="align-center">
                                <v-list-item-avatar>
                                    <v-img v-if="friend.userProfilePhoto" :src="friend.userProfilePhoto" referrerpolicy="no-referrer" @error="friend.userProfilePhoto=null"></v-img>
                                    <v-img v-else src="../assets/no-user.png" :alt="friend.username"></v-img>
                                </v-list-item-avatar>

                                <v-row justify="center" align="center" class="mx-0">
                                    <v-col cols="8" class="text-truncate">
                                        <span class="font-weight-bold text-subtitle-1">{{friend.username}}</span>
                                    </v-col>
                                    <v-col cols="4" align="end" class="hidden-sm-and-down">
                                        <v-btn v-if="!friend.editor" color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" :disabled="!friend.groupMember" @click.stop="addFriendEditor(friend,index)">Add
                                            <!-- <v-icon>mdi-account-plus</v-icon> -->
                                        </v-btn>
                                        <v-btn v-else color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" @click.stop="removeFriendEditor(friend,index)">Remove
                                            <!-- <v-icon>mdi-account-minus</v-icon> -->
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="4" align="end" class="hidden-md-and-up">
                                        <v-btn v-if="!friend.editor" color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" :disabled="!friend.groupMember" @click.stop="addFriendEditor(friend,index)">
                                            <v-icon>mdi-account-plus</v-icon>
                                        </v-btn>
                                        <v-btn v-else color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" @click.stop="removeFriendEditor(friend,index)">
                                            <v-icon>mdi-account-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="justify-center" :key="index"/>
                        </template>
                        <div class="text-caption text-center mt-2 mb-2">Some of your friends are not members of this group</div>
                        <v-divider class="justify-center"/>
                        <!-- uncomment below to show the friends that are not part of the group -->
                        <!-- <template v-for="(friend,index) in friendsNotInGroupList">
                            <v-list-item :key="friend.userId" selectable dense class="align-center">
                                <v-list-item-avatar>
                                    <v-img v-if="friend.userProfilePhoto" :src="friend.userProfilePhoto"></v-img>
                                    <v-img v-else src="../assets/no-user.png" :alt="friend.username"></v-img>
                                </v-list-item-avatar>

                                <v-row justify="center" align="center" class="mx-0">
                                    <v-col cols="8" class="pa-0 ma-0 text-truncate">
                                        <span class="font-weight-bold text-subtitle-1">{{friend.username}}</span>
                                        <br/><span v-if="!friend.groupMember" class="font-weight-light text-caption">Not Member of Group</span>
                                    </v-col>
                                    <v-col cols="4" align="end" class="">
                                        <v-btn v-if="!friend.editor" color="primary" icon small :loading="loadingGroupMembers || loadingFriends || friend.loading" :disabled="!friend.groupMember" @click.stop="addFriendEditor(friend,index)">Add</v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="justify-center" :key="index+friendsList.length"/>
                        </template> -->
                    </v-list>
                    <!-- END: if you have friends who are and are not part of the group -->

                    <!-- START: if all your friends are not part of the group -->
                    <div v-else-if="friendsList.length <= 0 && friendsNotInGroupList.length > 0" class="text-caption text-center mt-2">Looks like none of your friends are part of this group.</div>
                    <!-- END: if all your friends are not part of the group -->

                    <!-- START: if you have no friends -->
                    <div v-else class="text-caption text-center mt-2">Looks like you have no friends.</div>
                    <!-- END: if you have no friends -->
                </v-col>
            </v-row>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
                </v-row>
            </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../firebaseConfig.js";

export default {
    name: "addOrRemoveEditor",
    props: ["dialog", "audioObject", "postData", "addingFailedList", "removingFailedList",],
    data: function () {
        return {
            groupMembersListener: null,
            groupMembers: [],
            loadingGroupMembers: false,
            friendsList: [],
            loadingFriends: false,
            friendsNotInGroupList: [],
        };
    },
    methods: {
        closeDialog: function () {
            this.$emit("closeDialog");
        },
        addFriendEditor: function (friend, index) {
            friend.loading = true;
            this.friendsList.splice(index, 1);
            friend.editor = true;
            this.friendsList.unshift(friend); // Puts the newest editor at the top, or front, of the LOCAL list. When closing and reopening dialog the order is different
            // uncomment below to change where new editors are placed when added.
            // const insertIndex = this.audioObject.audioData.editors ? this.audioObject.audioData.editors.length : 0;
            // this.friendsList.splice(insertIndex, 0, friend); // Puts the newest editor at the end of the LOCAL list of editors, ie if 2 editors in list then newest will be 3rd in list. Will change order same as above.
            this.$emit("addEditor", friend);
        },
        removeFriendEditor: function (friend, index) {
            friend.loading = true;
            const insertIndex = this.audioObject.audioData.editors ? this.audioObject.audioData.editors.length : 0;
            this.friendsList.splice(index, 1);
            friend.editor = false;
            this.friendsList.splice(insertIndex-1, 0, friend);
            this.$emit("removeEditor", friend);
        },
        resetAfterFail: function (val) {
            // console.log("Adding or Removing Editor Failed. Reset local list.");
            if (val == 'add') {
                const index = this.friendsList.findIndex(friend => friend.userId === this.addingFailedList[this.addingFailedList.length-1].userId);
                const insertIndex = this.audioObject.audioData.editors ? this.audioObject.audioData.editors.length : 0;
                this.friendsList.splice(index, 1);
                this.addingFailedList[this.addingFailedList.length-1].editor = false;
                this.friendsList.splice(insertIndex, 0, this.addingFailedList[this.addingFailedList.length-1]);
            } else {
                // val == 'remove'
                const index = this.friendsList.findIndex(friend => friend.userId === this.removingFailedList[this.removingFailedList.length-1].userId);
                const insertIndex = this.audioObject.audioData.editors ? this.audioObject.audioData.editors.length-1 : 0;
                this.friendsList.splice(index, 1);
                this.removingFailedList[this.removingFailedList.length-1].editor = true;
                this.friendsList.splice(insertIndex, 0, this.removingFailedList[this.removingFailedList.length-1]);
            }
            this.$emit("removeFromFailedList", val);
        },
        prepFriends: function () {
            this.friendsList = [];
            this.loadingFriends = true;
            this.friends.forEach((friend) => {
                let f = {
                    accepted: friend.accepted,
                    email: friend.email,
                    userId: friend.userId,
                    userProfilePhoto: friend.userProfilePhoto,
                    username: friend.username,
                    loading: false,
                    editor: false,
                    groupMember: this.postData.postType === 'group' ? (this.groupMembers.includes(friend.userId) ? true : false) : true,
                }
                if (!f.accepted) {
                    return;
                } else {
                    if (!f.groupMember) {
                        this.friendsNotInGroupList.push(f);
                    } else {
                        if (this.audioObject.audioData.editors && this.audioObject.audioData.editors.includes(friend.userId)) {
                            f.editor = true;
                            this.friendsList.unshift(f);
                        } else {
                            this.friendsList.push(f);
                        }
                    }
                }
            });
            this.loadingFriends = false;
            return this.friendsList;
        },
        getGroupMembers: function () {
            this.loadingGroupMembers = true;
            this.groupMembersListener = firebase.db.collection("groups").doc(this.postData.groupId).collection("members").onSnapshot((snapshot) => {
                this.groupMembers = [];
                snapshot.forEach((doc) => {
                    if (doc.data().accepted) {
                        this.groupMembers.push(doc.data().userId);
                    }
                });
                this.loadingGroupMembers = false;
                this.prepFriends();
            });
        },
    },
    watch: {
        addingFailedList(value) {
            // console.log("value: ", value.length);
            if (value.length > 0) {
                this.resetAfterFail('add');
            }
        },
        removingFailedList(value) {
            // console.log("value: ", value);
            if (value.length > 0) {
                this.resetAfterFail('remove');
            }
        },
        // friendIfFailed: {
        //     handler(newItems, oldItems) {
        //         console.log("newItems: ", newItems);
        //         console.log("oldItems: ", oldItems);
        //         if (newItems.failed) {
        //             // this.resetAfterFail();
        //             console.log(newItems.username, " failed!");
        //         }
        //     },
        //     deep: true,
        // },
    },
    computed: {
        friends() {
            return this.$store.state.userFriends;
        },
        // friendIfFailed() {
        //     if (this.friend != null) {
        //         return this.friendsList.find(friend => friend.userId === this.friend.userId);
        //     } else {
        //         return null;
        //     }
        // },
    },
    mounted: function () {},
    created: function () {
        if (this.postData.postType === 'group') {
            // if group post we get all the members of the group, then call prepFriends() inside getGroupMembers().
            // This way we know for sure we have all the group members before we prep the friends.
            this.getGroupMembers();
        } else {
            this.prepFriends();
        }
    },
    beforeDestroy: function () {
        if (this.groupMembersListener != null) {
            this.groupMembersListener();
        }
    },
};
</script>