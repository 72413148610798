<template>
    <v-row justify="center" no-gutters>
        <v-col cols="12" xl="11" lg="11" md="12" sm="12" >

            <div :style="{ backgroundImage: groups.length > 0 && groups[savedGroupIndex].groupThumbnail != null ? `url(${groups[savedGroupIndex].groupThumbnail})` : '' /* `url(${ require('../../assets/no-user.png') })`*/ }" style="background-size: cover; background-position: center;" class="rounded-xl">
                <v-container fluid>
                    <v-row justify="center" align="center" no-gutters>
                        <v-col cols="12" xl="6" lg="6" md="8" sm="10">
                            <v-row justify="center" align="center" dense :class="$vuetify.theme.dark ? 'groupInfoContainerBorder' : ''" class="groupInfoContainer rounded-xl mb-1">
                                <v-col cols="2" align="end" style="min-width: 10%;" class="flex-grow-1 flex-shrink-1">
                                    <v-menu absolute rounded="lg" offset-y bottom origin="top" transition="scale-transition" close-on-content-click close-on-click>
                                        <template v-slot:activator="{ on }">
                                            <v-avatar v-on="on" size="50" color="primary" style="cursor: pointer;">
                                                <v-img v-if="groups.length > 0 && savedGroupIndex != null && groups[savedGroupIndex].groupThumbnail != null" cover :src="groups[savedGroupIndex].groupThumbnail" :alt="groups[savedGroupIndex].groupName" referrerpolicy="no-referrer" @error="groups[savedGroupIndex].groupThumbnail=null"></v-img>
                                                <v-img v-else cover src="../../assets/no-user.png" alt="No Thumbnail For Group"></v-img>
                                            </v-avatar>
                                        </template>
                                        <v-list rounded>
                                            <v-list-item v-for="(group, index) in groups" :key="index" style="cursor: pointer" @click="setSelectedGroupInStore(group.groupId, index),  selectedGroupModel = index">
                                            <v-list-item-avatar>
                                                <v-img :src="group.groupThumbnail ? group.groupThumbnail : require('../../assets/no-user.png')" alt="No Thumbnail For Group"></v-img>
                                            </v-list-item-avatar>
                                                <v-list-item-title>{{group.groupName}}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4" align="center" style="max-width: 80%;" class="flex-grow-1 flex-shrink-1">
                                    <v-menu absolute rounded="lg" offset-y bottom origin="top" transition="scale-transition" close-on-content-click close-on-click>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on" style="cursor: pointer;" class="text-h4 text-md-h3 font-weight-light white--text">{{ groups.length > 0 ? groups[savedGroupIndex].groupName : "No Groups" }}</span>
                                        </template>
                                        <v-list rounded>
                                            <v-list-item v-for="(group, index) in groups" :key="index" style="cursor: pointer" @click="setSelectedGroupInStore(group.groupId, index),  selectedGroupModel = index">
                                            <v-list-item-avatar>
                                                <v-img :src="group.groupThumbnail ? group.groupThumbnail : require('../../assets/no-user.png')" alt="No Thumbnail For Group"></v-img>
                                            </v-list-item-avatar>
                                                <v-list-item-title>{{group.groupName}}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col v-if="groups.length > 0" cols="2" align="start" style="min-width: 10%;" class="flex-grow-1 flex-shrink-1">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="openGroupSettingsDialog(group)" :ripple="false" x-small icon rounded class="hidden-xs-only">
                                                <v-icon class="white--text">mdi-cogs</v-icon>
                                            </v-btn>
                                            <v-btn @click="openGroupSettingsDialog(group)" :ripple="false" x-small depressed icon rounded class="hidden-sm-and-up">
                                                <v-icon class="white--text">mdi-cogs</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Group Settings</span>
                                    </v-tooltip>
                                    <GroupSettings v-if="settingsDialog" :dialog="settingsDialog" :friends="friends" :group="groups[savedGroupIndex]" @getUserGroups="(settingsDialog = false)"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="groups.length > 0" justify="center" align="center" dense class="rounded-xl">
                        <v-col cols="12" xl="5" lg="5" md="8" sm="8" :class="$vuetify.theme.dark ? 'groupInfoContainerBorder' : ''" class="groupInfoContainer rounded-xl">
                            <v-slide-group class=" rounded-xl "  dark show-arrows center-active mandatory v-model="selectedGroupModel">
                                <v-slide-item  class="rounded-xl" v-for="(group, index) in groups" :key="index" v-slot="{ active, toggle }">
                                    <v-btn @click="toggle" @click.stop="setSelectedGroupInStore(group.groupId, index)" light small :color=" active ? 'primary white--text' : '' " depressed rounded class="mx-2">{{group.groupName}}</v-btn>
                                </v-slide-item>
                            </v-slide-group>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <v-row justify="end" no-gutters class="my-2 mx-5 mx-sm-10">
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="newGroupDialog = true" small fab color="primary" class="hidden-xs-only">
                            <v-icon>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                        <v-btn @click.stop="newGroupDialog = true" x-small fab color="primary" class="hidden-sm-and-up">
                            <v-icon>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Create a New Group</span>
                </v-tooltip>
                <NewGroup v-if="newGroupDialog" :dialog="newGroupDialog" @cancelNewGroup="newGroupDialog = false"/>

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="existingGroupDialog = true" small fab color="primary" class="hidden-xs-only ml-1">
                            <v-icon>mdi-account-search</v-icon>
                        </v-btn>
                        <v-btn @click.stop="existingGroupDialog = true" x-small fab color="primary" class="hidden-sm-and-up ml-1">
                            <v-icon>mdi-account-search</v-icon>
                        </v-btn>
                    </template>
                    <span>Join an Existing Group</span>
                </v-tooltip>
                <ExistingGroup v-if="existingGroupDialog" :dialog="existingGroupDialog" :groups="groups" @cancelExistingGroup="existingGroupDialog = false;"/>
            </v-row>

            <v-divider class="primary mx-5 mx-sm-10"/>

            <!-- POSTS CONTAINER -->
            <v-row v-if="isLeaving && savedGroupId === leavingGroupId" justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">You are being removed...</span>
                <v-col cols="12" align="center" class="mt-5">
                    <v-progress-circular color="primary" indeterminate :size="100" :width="7"></v-progress-circular>
                </v-col>
            </v-row>
            <Group v-else-if="!isLeaving && groups.length > 0" :group="groups[savedGroupIndex]" :savedGroupIndex="savedGroupIndex" />
            <v-row v-else justify="center" no-gutters class="mt-5">
                <span class="text-subtitle-1 text-center">Groups you create or join will appear here.</span>
            </v-row>
            <!-- END POSTS CONTAINER -->

        </v-col>
    </v-row>
</template>

<script>
// import firebase from "../../../firebaseConfig.js";
import Group from "./Group.vue";
import NewGroup from "./NewGroup.vue";
import ExistingGroup from "./ExistingGroup.vue";
import GroupSettings from "./GroupSettings.vue";

export default {
    name: "GroupsPage",
    metaInfo: {
        title: 'Groups - tawq.in'
    },
    components: {
        Group,
        NewGroup,
        ExistingGroup,
        GroupSettings,
    },
    data: function () {
        return {
            selectedGroupModel: 0, // this is only used for the v-model, because it was messing up when we were using the savedGroupIndex and not updating it correctly within the list. The order that v-slide-group display was different than what the v-model's index was
            newGroupDialog: false,
            existingGroupDialog: false,
            group: null,
        };
    },
    methods: {
        setSelectedGroupInStore (groupId, index){
            if (groupId === null && index === null) {
                // all groups deleted
                this.$store.commit('setSelectedGroupIndex', 0);
                this.$store.commit('setSelectedGroupId', '');
                this.$router.replace({path: "groups",});
                return;
            } else if (groupId === this.savedGroupId && index === this.savedGroupIndex && typeof this.$route.query.id != 'undefined' && this.$route.query.id === groupId) {
                // clicking the same group
                return;
            } else if (groupId != this.savedGroupId && index === this.savedGroupIndex && typeof this.$route.query.id != 'undefined' && this.$route.query.id === groupId) {
                // page refresh or first group deleted
                index = this.groups.findIndex((group) => {if(group.groupId === groupId)return true;});
                this.$store.commit('setSelectedGroupIndex', index);
                this.$store.commit('setSelectedGroupId', groupId);
                return;
            } else if (groupId != this.savedGroupId && index === this.savedGroupIndex) {
                // first item deleted set to new first item
                this.$router.replace({path: "groups", query: { id: groupId },});
                this.$store.commit('setSelectedGroupId', groupId); 
                return;
            } else {
                this.$router.replace({path: "groups", query: { id: groupId },});
                this.$store.commit('setSelectedGroupIndex', index);
                this.$store.commit('setSelectedGroupId', groupId);
                this.selectedGroupModel = index;
            }
        },
        openGroupSettingsDialog: function () {
            if (this.group === null || this.groups[this.savedGroupIndex].groupId != this.group.groupId) {
                this.group = this.groups[this.savedGroupIndex];
            }
            this.settingsDialog = true;
        },
        setSelectedGroupModel(id){
            let index = this.groups.findIndex( (group) => {
                return group.groupId === id;
            })
            this.selectedGroupModel = index != -1 ? index : 0;
        },
    },
    computed: {
        isLeaving(){
            return this.$store.state.groupsPageStore.isLeavingGroup;
        },
        leavingGroupId(){
            return this.$store.state.groupsPageStore.leavingGroupId;
        },
        uploadingVideo(){
            return this.$store.state.newPost.uploading_video;
        },
        createPost(){
            return this.$store.state.newPost.createPost;
        },
        friends(){
            return this.$store.state.userFriends;
        },
        savedGroupIndex(){
            return this.$store.state.groupsPageStore.selectedGroupIndex;
        },
        savedGroupId(){
            return this.$store.state.groupsPageStore.selectedGroupId;
        },
        groups (){
            return JSON.parse(JSON.stringify(this.$store.state.userGroups));
        },
        settingsDialog: {
            get(){
                return this.$store.state.groupsPageStore.groupSettingsDialog
            },
            set(value){
                this.$store.commit("saveGroupSettingsDialog", value);
            }
        },
    },
    watch: {
        '$store.state.userGroups' (value){
            let groups = JSON.parse(JSON.stringify(value));
            if (groups.length != 0 && this.savedGroupId === this.$route.query.id) {
                // page refresh or deleted Group
                return;
            } else if (groups.length != 0) {
                let index = -1;
                if (this.savedGroupId != this.$route.query.id) {
                    index = groups.findIndex((group) => {return group.groupId === this.savedGroupId;});
                } else if (index != -1) {
                    // should get here when adding a new group, requesting to join a group or accepting a join request for a group
                    this.setSelectedGroupInStore(this.savedGroupId, index);
                    this.selectedGroupModel = index;
                    this.newGroupDialog = false;
                } else {
                    this.setSelectedGroupInStore(this.$route.query.id , this.savedGroupIndex);
                }
            }
        },
        '$store.state.groupsPageStore.isLeavingGroup' (value){
            if (!value) {
                if (this.groups.length > 1) {
                    if (this.savedGroupIndex != 0 ) {
                        this.$router.replace({path: "groups", query: { id: this.groups[0].groupId },});
                    } else {
                        this.$router.replace({path: "groups", query: { id: this.groups[1].groupId },});
                    }
                } else {
                    this.setSelectedGroupInStore(null, null);
                }
            }
        },
        '$store.state.groupsPageStore.selectedGroupId' (newValue){
            this.setSelectedGroupModel(newValue);
        },
    },
    created: function () {
        if (this.uploadingVideo && this.createPost.type === 'group') {
            let index = this.groups.findIndex((group) => {if(group.groupId === this.createPost.group.groupId)return true;});
            this.setSelectedGroupInStore(this.createPost.group.groupId, index);
            this.selectedGroupModel = index;
        } else if ( this.savedGroupId != null || this.savedGroupIndex != null) {
            if (this.groups.length === 0) {
                if (typeof this.$route.query.id != "undefined") {
                    this.$router.replace({path: "groups",});
                }
                return;
            }
            this.setSelectedGroupInStore(this.savedGroupId, this.savedGroupIndex);
            this.setSelectedGroupModel(this.savedGroupId);
            if (this.$route.hash) {
                this.openGroupSettingsDialog();
            }
        } else {
            if(this.groups.length === 0){
                if (typeof this.$route.query.id != "undefined") {
                    this.$router.replace({path: "groups",});
                }
                return;
            } else if (typeof this.$route.query.id != "undefined") {
                this.setSelectedGroupInStore(this.$route.query.id, null);
                this.setSelectedGroupModel(this.$route.query.id);
                if (this.$route.hash) {
                    this.openGroupSettingsDialog();
                }
            } else {
                this.setSelectedGroupInStore(this.groups[0].groupId, 0);
                this.setSelectedGroupModel(this.groups[0].groupId);
            }
        }
    },
};
</script>
<style scoped>
.odd {
    background: hsl(0, 0%, 87%);
}

.groupInfoContainer {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
}

.groupInfoContainerBorder {
    border: thin inset grey !important;
}
</style>