<template>
  <v-container class="pa-0" style="height: 100%;">

  <v-row class="pa-0 ma-0 mb-16" v-if="!fullScreenDialog">
    <!-- <v-toolbar flat color="primary" class="d-flex flex-row justify-space-between align-center" min-width="100%" width="100%"> -->
    <v-app-bar class="primary mb-4" v-bind:class="[$vuetify.theme.dark ? '' : 'grey lighten-3']" fixed flat>
      <v-row justify="space-between" align="center">
          <div class="flex-grow-1 d-flex flex-row align-center">
            <v-btn icon @click.stop="$router.go(-1)">
              <v-icon color="white" size="30">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text ml-1" >Back</v-toolbar-title>
          </div>
          <div>
            <router-link class="pr-8" :to="{ name: 'help', query: {loadTopic: 'createPost', loadSubTopic: 4}  }" target="_blank" style="text-decoration: none;">
              <v-icon class="white--text" size="20">mdi-help</v-icon>
            </router-link>
        </div>
      </v-row>
      <!-- HELP BUTTON -->
      <transition name="fade">
        <div v-if="userData.postsCreated < 3 && showHelpIndicator" class="d-flex flex-column px-8"  style="position: absolute; bottom: -60px; right: 0px;">
          <v-icon class="align-self-end">mdi-arrow-up</v-icon>
          <span class="rounded-xl primary py-1 px-3 white--text">Need help? Tap here.</span> 
        </div>
      </transition>
    </v-app-bar>
    <!-- </v-toolbar> -->

  </v-row>

    <v-row style="max-width: 100%; height: calc(100% - 56px);" class="pa-0 ma-0" v-if="!fullScreenDialog">

     <!-- <v-row align="center" justify="start" style="min-height: 5%; max-height: 7%; max-width: 100%;" class="px-2 py-0 ma-0 primary">
        <v-btn icon @click.stop="goBackHomePage()">
          <v-icon color="white" size="35">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <h3 class="white--text font-weight-light pl-5">tawq.in Create</h3>
      </v-row> --> 

      <v-row align="center" justify="center" class="ma-0" style="max-width: 95%;">
        <v-btn
          width="45%"
          min-height="70"
          max-height="90"
          @click.stop="getOrientationPermissions()"
          >Pickup to Tawq</v-btn
        >
      </v-row>

      <!-- Carousels Code -->
      <v-row justify="center" align="end" class="ma-0 px-0" style="max-width: 100%;">
        <v-col cols="12" xs="12" class="pa-0 fill-height">
          <v-card flat depressed style="height: 100%;" v-bind:class="[$vuetify.theme.dark ? 'primary' : 'accent']">
            <!-- Carousel for iPhone Users -->
            <v-carousel
              class="custom-carousel"
              v-if="isIOS"
              hide-delimiter-background
              continuous
              cycle
              :interval="8000"
              :show-arrows="false"
            >
              <v-carousel-item v-for="(slide, i) in iPhoneSlides" :key="i" :transition="false" :reverse-transition="false">
                <v-row justify="center" align="center" style="height: 50%; width: 100%;" class="ma-0 pb-0 secondary lighten-3">
                  <v-icon v-if="i == 0" class="red--text text-darken-4" size="100">mdi-alert-outline</v-icon>
                  <v-img v-else contain position="center bottom" height="100%" :src="slide.src"></v-img>
                </v-row>
                <v-row justify="start" align="center" class="d-flex flex-column ma-0 pt-4" style="width: 100%;">
                  <v-card-title class="text-lg-h4 text-sm-h5 text-h5 font-weight-bold warning--text text-center pt-0 justify-start" style="word-break: normal;">{{slide.title}}</v-card-title>
                  <v-card-subtitle class="text-subtitle-2 font-weight-medium white--text text-center justify-end pt-1">{{slide.text}}</v-card-subtitle>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <!-- Carousel for Android Users -->
            <v-carousel
              class="custom-carousel"
              v-else
              hide-delimiter-background
              continuous
              cycle
              :interval="8000"
              :show-arrows="false"
            >
              <v-carousel-item v-for="(slide, i) in androidSlides" :key="i" :transition="false" :reverse-transition="false">
                <v-row justify="center" align="center" style="height: 50%; width: 100%;" class="ma-0 pb-0 secondary lighten-3">
                  <v-img contain position="center bottom" height="100%" :src="slide.src"></v-img>
                </v-row>
                <v-row justify="start" align="center" class="d-flex flex-column ma-0 pt-4" style="width: 100%;">
                  <v-card-title class="text-lg-h4 text-sm-h5 text-h5 font-weight-bold warning--text text-center pt-0 justify-start" style="word-break: normal;">{{slide.title}}</v-card-title>
                  <v-card-subtitle class="text-subtitle-2 font-weight-medium white--text text-center justify-end">{{slide.text}}</v-card-subtitle>
                </v-row>
              </v-carousel-item>
            </v-carousel>

          </v-card>
        </v-col>
      </v-row>

    </v-row>

      <FullScreenMobile
        v-else
        ref="fullscreen"
        :dialog="fullScreenDialog"
        :dbRefs="dbRefs"
        @notConnectedCancelFullscreenMobile="fullScreenDialog = false"
        @cancelFullscreenMobile="cancelFullscreenMobile"
      />

  </v-container>
</template>

<script>
import firebase from "../../firebaseConfig";
import FullScreenMobile from "../components/Mobile/FullScreenMobile.vue";
export default {
  name: "newPostMobile",
  metaInfo: {
    title: "Create Post - tawqin"
  },
  components: {
    FullScreenMobile,
  },
  data: function() {
    return {
      iPhoneSlides: [
        // {
        //   title: "iOS Users.",
        //   text: "The MediaRecorder API is required. If you don't have the latest iOS, you must enable this feature. To enable, Go to Settings > Safari > Advanced > Experimental Features > MediaRecorder.",
        //   src: require('../assets/tour/welcome.png'),
        // },
        {
          title: "How To Tawq",
          // text: "Tawqin allows users to choose between 'Push To Tawq' and 'Pickup To Tawq'. 'Push To Tawq' allows the user to control by pressing buttons. 'Pickup To Tawq' lets the user record by tilting their mobile device.",
          // text: "Select 'Push To Tawq' for play, pause, and record buttons. Select 'Pick Up To Tawq' to use motion for play, pause, and record",
          text: "Select 'Pick Up To Tawq' to use motion for play, pause, and record",
          src: require('../assets/tour/5.png'),
        },
        { 
          title: "Play",
          text: "When your mobile device is resting, the video will play. Indicated by green background.",
          src: require('../assets/tour/6.png'),
        },
        { 
          title: "Pause",
          // text: "When the mobile device is tilted at a slight angle, the device is in a pause state; which will pause the video. This is indicated by a yellow background.",
          text: "When your mobile device is tilted, both the video your audio caption will pause. Indicated by yellow background.",
          src: require('../assets/tour/7.png'),
        },
        { 
          title: "Record",
          // text: "Lift the mobile device like you do when talking on the phone and the device will enter a record state; which will let the user record audio for the video. This is indicated by a red background.",
          text: "When your mobile phone is at your ear, your speech will be recorded for your audio caption. Indicated by red background.",
          src: require('../assets/tour/8.png'),
        },
      ],
      androidSlides: [
        {
          title: "How To Tawq",
          // text: "Tawqin allows users to choose between 'Push To Tawq' and 'Pickup To Tawq'. 'Push To Tawq' allows the user to control by pressing buttons. 'Pickup To Tawq' lets the user record by tilting their mobile device.",
          text: "Select 'Push To Tawq' for play, pause, and record buttons. Select 'Pick Up To Tawq' to use motion for play, pause, and record",
          src: require('../assets/tour/5.png'),
        },
        { 
          title: "Play",
          // text: "When the mobile device is lying flat, the phone is in a play state; which will play the video. This is indicated by a green background.",
          text: "When your mobile device is resting, the video will play. Indicated by green background.",
          src: require('../assets/tour/6.png'),
        },
        { 
          title: "Pause",
          // text: "When the mobile device is tilted at a slight angle, the device is in a pause state; which will pause the video. This is indicated by a yellow background.",
          text: "When your mobile device is tilted, both the video your audio caption will pause. Indicated by yellow background.",
          src: require('../assets/tour/7.png'),
        },
        { 
          title: "Record",
          // text: "Lift the mobile device like you do when talking on the phone and the device will enter a record state; which will let the user record audio for the video. This is indicated by a red background.",
          text: "When your mobile phone is at your ear, your speech will be recorded for your audio caption. Indicated by red background.",
          src: require('../assets/tour/8.png'),
        },
      ],
      fullScreenDialog: false,
      screen: "",
      desktop: false,
      dbRefs: {
        matchRef: null,
        gammaRef: null,
        stateRef: null,
        tawqinRef: null,
        videoSegRef: null,
        idRef: null,
        segmentSeqRef: null,
        audioSegRef: null,
        subtitleRef: null,
        tmpConsoleRef: null,
        storageRef: null,
        conversionRef: null,
      },
      showHelpIndicator: true,
      audioDataId: ""
    };
  },
  methods: {
    cancelFullscreenMobile: function(/*postId, audioDataId*/) {
      // this.deleteIncompletePostAudioFilesFromStorage(postId, audioDataId);
      this.fullScreenDialog = false;
    },
    clearRTDB: function(userId) {
      const allRefs = firebase.rtDb.ref(userId);
      allRefs.off();
      allRefs.remove();
    },
    deleteIncompletePostAudioFilesFromStorage: function(postId, audioDataId) {
      // delete the audiofile from audioSegments/postId/audioDataId
      let audioDataRef = firebase.storage.ref(
        `audioSegments/${postId}/${audioDataId}`
      );
      audioDataRef.list().then((res) => {
        if (res.items.length > 0) {
          var deleteOn = {
            delete: true,
          }
          this.dbRefs.matchRef.update(deleteOn);
        }
        res.items.forEach(function(itemRef) {
          /* retrieve the file names of each segment, so that we can delete each file
            individually from the storage bucket. GCS doesn't allow us to delete the full bucket */
          let filename = itemRef.name;
          let audioRef = audioDataRef.child(filename);
          audioRef
            .delete()
            .then(() => {
              return;
            })
            .catch((error) => {
              console.error(
                `Error Deleting Audio Segment File ${filename} from Storage:`,
                error
              );
              return;
            });
        });
      }).catch((error) => {
        console.log("Error Deleting Audio Files: ", error);
      });/*.finally(() => {
        alert("Got to finally statement");
      });*/
    },
    updateRefs: function(userId) {
      this.dbRefs.matchRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/match`);
      this.dbRefs.gammaRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/current/gamma`);
      this.dbRefs.stateRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/current/state`);
      this.dbRefs.tawqinRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/tawqin`);
      this.dbRefs.videoSegRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/videoSeg`);
      this.dbRefs.segmentSeqRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/segSeq`);
      this.dbRefs.audioSegRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/audioSeg`);
      this.dbRefs.subtitleRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/subtitle`);
      this.dbRefs.tmpConsoleRef = firebase.rtDb.ref(`${userId}/${this.audioDataId}/mobileConsole`);
      this.dbRefs.idRef = firebase.rtDb.ref(`${userId}/id`);
      this.dbRefs.storageRef = firebase.storage.ref();
    },
    getOrientationPermissions: function () {
      if (
        DeviceOrientationEvent &&
        typeof DeviceOrientationEvent.requestPermission === "function"
      ) {
        DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              this.fullScreenDialog = true;
              // window.addEventListener(
              //   "deviceorientation",
              //   this.handleOrientation
              // );
            } else {
              this.fullScreenDialog = false;
              // alert(
              //   "Please allow device orientation detection to record your audio."
              // );
              this.snackbar = true;
              this.snackbarText =
                "Please allow device orientation detection to record your audio.";
            }
          })
          .catch((err) => {
            // alert("Error: " + err);
            this.snackbar = true;
            this.snackbarText = "Something went wrong. " + err.message;
          });
      } else {
        //not an iOS device, add event normally
        this.fullScreenDialog = true;
        // window.addEventListener("deviceorientation", this.handleOrientation);
      }
    },
  },
  computed: {
    isIOS(){
      return this.$store.state.isIOSDevice;
    },
    userData() {
      return this.$store.state.userData;
    }
  },
  created: function() {
    //update realtime database references to allow mobile/homebase connection for user based on uid
    // firebase.rtDb.ref(`${firebase.auth.currentUser.uid}/currentAudioDataId`).get()
    // .then((snapshot) => {
    //   console.log("snapshot for current audio data id: ", snapshot.val());
    //   this.audioDataId = snapshot.val().audioDataId;
    //   this.updateRefs(firebase.auth.currentUser.uid);
    // })
  },
  mounted: async function() {
    setTimeout( () => {
      this.showHelpIndicator = false;
    }, 4000);
    // send connection to RTDB that mobile is ready for connection
    var mobileOn = {
      mobileConnect: true,
    }
    const snapshot = await firebase.rtDb.ref(`${firebase.auth.currentUser.uid}/currentAudioDataId`).get();
    this.audioDataId = snapshot.val().audioDataId;
    this.updateRefs(firebase.auth.currentUser.uid);
    this.dbRefs.matchRef.update(mobileOn);

    this.dbRefs.matchRef.get().then((snapshot) => {
      // If the user refreshes while in FullscreenMobile or Pickup to Tawq
      // this check will get called and reset the RTDB connected value to false
      // so that the desktop will disconnect.
      if (snapshot.val() != null){
        if (snapshot.val().connected){
          var connectedOff = {
            connected: false,
          }
          this.dbRefs.matchRef.update(connectedOff);
        }
      }
    });

    // this.dbRefs.matchRef.once("value", (snapshot) => {
    //   if (snapshot.val() != null){
    //     if (snapshot.val().connected){// && snapshot.val().mobileConnect) {
    //       console.log("Connected Still");
    //       var connectedOff = {
    //         connected: false,
    //       }
    //       this.dbRefs.matchRef.update(connectedOff);
    //     }
    //   }
    // });
  },
  beforeDestroy: function() {
    // before we destroy this component we want to make sure we send a matchRef to
    // the RTDB to let it know that the mobile device is now disconnected
    var mobileOff = {
      mobileConnect: false,
    };
    this.dbRefs.matchRef.update(mobileOff);
  },
  destroyed: function() {},
};
</script>
<style scoped>
html {
  overflow-y: auto;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* .custom-carousel .v-carousel__controls {
  
} */

.custom-carousel {
  min-height: 95% !important;
}

</style>
