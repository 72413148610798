<template>
    <v-row justify="center" align="center" class="animated-gradient ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">

            <v-app-bar app flat style="max-width: 100%; z-index: 9;" class="transparent">
                <v-col cols="4" class="ma-0">
                    <v-img max-height="60px" :max-width="isMobile ? '100px' : '125px'" contain position="left center" src="../../assets/whitetransparent.png" style="cursor: pointer;" @click.stop="$router.push({ path: 'intro'})"></v-img>
                </v-col>
                <v-spacer/>
                <v-col cols="8" align="end" class="ma-0 pa-0">
                    <v-btn color="white" outlined class="mr-2 font-weight-normal" :to="{name: 'login'}">Login</v-btn>
                    <v-btn color="white" outlined class="font-weight-normal" :to="{name: 'sign-up'}">Sign Up</v-btn>
                </v-col>
            </v-app-bar>

            <v-row justify="center" align="center" class="ma-0 pa-0">

                <v-col cols="11" xl="3" lg="4" md="8" sm="10" class="ma-0 pa-0">
                    <v-card v-if="!resetPassword" elevation="8" rounded light style="position: relative;" class="white rounded-xl">

                        <v-overlay v-if="loading" absolute>
                            <v-progress-circular size="100" width="5" indeterminate color="primary"></v-progress-circular>
                        </v-overlay>

                        <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h4 text-md-h3 text-lg-h3 font-weight-light">Login</span>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form>
                                    <!-- <v-text-field v-model="userEmail" label="Email" type="email" outlined required validate-on-blur prepend-inner-icon="mdi-email" color="primary" :rules="emailRules"></v-text-field> -->
                                    <v-text-field v-model="userEmail" label="Email" type="email" outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" :rules="emailRules" @change="removeHintAndHelpIfNecessary()"></v-text-field>
                                    <v-text-field v-model="userPassword" label="Password" :type="showPassword ? 'text' : 'password'" outlined required validate-on-blur prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" color="primary" hide-details :rules="passwordRules" @change="removeHintAndHelpIfNecessary()" @click:append="showPassword = !showPassword" @keypress.enter="login()"></v-text-field>
                                    <p class="text-start font-italic font-weight-light text-decoration-underline primary--text my-1"><span style="cursor: pointer;" @click.stop="resetPassword = true">Forgot Password?</span></p>

                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mt-2" :class="(!resendVerification && !needHelp) ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                    <p v-if="resendVerification" class="darken-1 text-center font-italic font-weight-light mb-2">Can't find it? <span class="primary--text text-decoration-underline" style="cursor: pointer;" @click.stop="resendVerificationEmail">Resend Verification Email.</span></p>
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">Need Help? <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep">Contact Us</span></p>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-card-actions>
                            <v-row justify="center" no-gutters>
                                <v-col cols="10" align="center" class="pb-1">
                                    <v-btn rounded large color="primary" @click.stop="login" class="">Log In</v-btn>
                                    <v-row align="center" no-gutters class="my-0 mx-10 pb-1">
                                        <v-divider class="primary"/>
                                            <span class="pa-1 mx-2 mt-2 rounded-xl">OR</span>
                                        <v-divider class="primary"/>
                                    </v-row>
                                    <v-btn icon @click="signInWithGoogle()">
                                        <img src="../../assets/google-icon.svg" alt="google-logo" width="35">
                                    </v-btn>
                                    <!-- <v-btn icon class="ml-2" @click="signInWithFacebook()">
                                        <v-icon color="blue darken-2" size="45">mdi-facebook</v-icon>
                                    </v-btn> -->
                                </v-col>

                                <v-layout column align-center justify-center class="mt-2">
                                    <p class="text-subtitle-1 text-lg-h6 font-weight-light mb-2">Don't have an Account?
                                        <router-link to="/sign-up" style="color:primary;">Sign Up</router-link>
                                    </p>
                                </v-layout>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else elevation="8" rounded light style="position: relative;" class="white rounded-xl">
                        <v-card-title style="word-break: normal" class="mb-2 mb-lg-5">
                            <v-row justify="center" no-gutters>
                                <v-col cols="8" align="center">
                                    <span class="text-wrap text-h5 text-md-h4 text-lg-h4 font-weight-light">Forgot Password?</span>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row justify="center" no-gutters class="ma-0 pa-0">
                            <v-col cols="10" lg="8" md="8" align="center" class="ma-0 pa-0">
                                <v-form v-model="formIsValid" @submit.prevent="sendPasswordReset">
                                    <v-text-field v-model="userEmail" label="Email" type="email" outlined required validate-on-blur prepend-inner-icon="mdi-at" color="primary" :rules="emailRules"></v-text-field>
                                    <p v-if="hint" class="red--text darken-1 text-center font-italic font-weight-light mt-2" :class="(!resendVerification && !needHelp) ? 'mb-2' : 'mb-0'">{{hintText}}</p>
                                    <p v-if="needHelp" class="darken-1 text-center font-italic font-weight-light mb-2">Need Help? <span style="cursor: pointer;" class="primary--text text-decoration-underline" @click.stop="emailKeep">Contact Us</span></p>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-card-actions class="ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <v-col cols="10" align="center" class="ma-0 pa-0">
                                    <v-btn rounded large color="primary" @click.stop="sendPasswordReset">reset password</v-btn>
                                </v-col>

                                <v-col cols="10" align="start" class="ma-0 pa-0">
                                    <p style="cursor: pointer;" class="primary--text text-subtitle-1 text-lg-h6 font-weight-light" @click.stop="resetLoginState">{{'< Back'}}</p>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script src="https://js.stripe.com/v3/" defer></script>
<script>
import firebase from "../../../firebaseConfig.js";
// import fb from 'firebase/compat/app'
export default {
    name: "login",
    metaInfo: {
        title: 'Login - tawqin'
    },
    props: ["alertEmailSent"], // FIND OUT WHERE IS PROP IS COMING FROM!!!
    components: {},
    data() {
        return {
            loading: false,
            formIsValid: false,
            userEmail: "",
            emailRules: [
                (v) => !!v || "Email is required",
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
            ],
            userPassword: "",
            passwordRules: [
                (v) => !!v || "Password is required",
                // (v) => v.length >= 8 || "Password Must Be 8 Characters Or More",
            ],
            showPassword: false,
            resetPassword: false,
            resendVerification: false,
            hint: false,
            hintText: "",
            needHelp: false,
        };
    },
    methods: {
        login: async function() {
            try {
                const { user } = await firebase.auth.signInWithEmailAndPassword(this.userEmail, this.userPassword);
                const userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists && userInDatabase.data().emailVerified && user.emailVerified) { // user exists in database and has verified email, send to /home
                    this.$router.replace({ path: "/home" });
                } else { // email is NOT verified, notify user and allow for verification email to be sent
                    this.resendVerification = true;
                    this.hint = true;
                    this.hintText = "This email has not been verified.";
                }
            } catch (error) {
                console.error(error);
                switch (error.code) {
                    case "auth/invalid-email": // thrown if the email address is not valid
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The email you entered is invalid. Please try again.*";
                        this.userEmail = "";
                        this.userPassword = "";
                        break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*User has been disabled from using tawq.in. Please contact support for help.*";
                        break;
                    case "auth/user-not-found": // thrown if there is no user corresponding to the given email
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*User with this email and password does not exist. Click Sign Up below.*";
                        break;
                    case "auth/wrong-password": // thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set
                        this.loading = false;
                        this.needHelp = true;
                        this.hint = true;
                        // this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, Google or Facebook, please select the one you used below.*"; // uncomment when ready to use Facebook signup/login.
                        this.hintText = "*The password you entered is wrong. If you have previously signed in with a third party account, I.E. Google, please select it below.*";
                        this.userPassword = "";
                        break;
                    default:
                        console.error("error logging in with email and password! DEFAULT: ", error.message);
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetLoginState();
                        break;
                }
            }
        },
        signInWithGoogle: function () {
            this.loading = true;
            var provider = new firebase.authentication.GoogleAuthProvider();
            firebase.auth.useDeviceLanguage(); // will open the popup in the language the user has selected as their default browser language.
            this.signInWithPopup(provider);
        },
        signInWithFacebook: function () {
            this.loading = true;
            var provider = new firebase.authentication.FacebookAuthProvider();
            // provider.addScope('user_email');
            this.signInWithPopup(provider)
        },
        signInWithPopup: async function (provider) {
            try {
                const { user } = await firebase.auth.signInWithPopup(provider);
                const userInDatabase = await firebase.db.collection("userData").doc(user.uid).get();
                if (userInDatabase.exists) { // user has already signed up
                    if (!userInDatabase.data().emailVerified || !user.emailVerified) { // check if user has verified email, if not, set to verified
                        await firebase.db.collection("userData").doc(user.uid).update({ emailVerified: true }, { merge: true });
                    }
                    this.$router.replace({ path: "/home" }); // user is logged in and email is verified, set route to /home
                } else { // user does not exist, create them
                    const responseFromServerForVerifiedEmail = await this.$http.put(`emailVerified/${user.uid}`);
                    const userData = await this.setUserData(user, responseFromServerForVerifiedEmail.data.emailVerified);
                    await this.setFirebaseUserData(user.uid, userData);
                    this.loading = false;
                    this.$router.replace({ path: "/home", query: { newUser: true} });
                }
            } catch (error) {
                // ALL COMMENTED OUT CASES SHOULD BE CAUGHT IN DEFAULT CASE
                console.error(error);
                this.loading = false;
                switch (error.code) {
                    case "auth/account-exists-with-different-credential": // thrown when a user tries to sign in with Facebook or Google when they have already signed in with one or the other.
                        // console.log("auth/account-exists-with-different-credential");
                        // https://firebase.google.com/docs/auth/web/facebook-login#handling-account-exists-with-different-credential-errors
                        // https://cloud.google.com/identity-platform/docs/reference/rest/v1/accounts/signUp
                        // https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection#overview
                        // let pendingCred = error.credential; // 1. store this credential in temporary storage.
                        // 2. notify user that they have already linked this email with another credential.
                        // let result = await firebase.auth.signinWithPopup(userSelectedProvider); // 3. signin the user with their prefered provider (email and password, google, facebook).
                        // At this point the user has signed in and firebase.auth.currentUser should return the signed in user.
                        // 4. retrieve the pendingCred from temporary storage.
                        // let user = await linkWithCredential(result.user, pendingCred); // 5. link the accounts.
                        // 6. continue to app.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*An account has already been created with this email. If you have already created an account using Google, please select it below. Otherwise, try signing in with your email and password. If you feel this is a mistake please contact us below.*";
                        break;
                    // case "auth/auth-domain-config-required": // thrown if authDomain configuration is not provided when calling firebase.initializeApp().
                    //     break;
                    case "auth/cancelled-popup-request": // thrown if multiple popup operations are triggered, only one allowed at a time. All fail if thrown.
                        // console.log("auth/cancelled-popup-request");
                        this.loading = true;
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*You have opened multiple popups. Please close them and retry.*";
                        break;
                    // case "auth/operation-not-allowed": // thrown if the type of account corresponding to the credential is not enabled, ie if user tries to signin with Apple when we have not allowed Apple signin.
                    //     break;
                    // case "auth/operation-not-supported-in-this-environment": // thrown if this operation is not supported in the environment your application is running on.
                    //     break;
                    case "auth/popup-blocked": // thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*Popup was blocked by the browser. Please allow popups to sign up with Google.*";
                        break;
                    case "auth/popup-closed-by-user": // thrown if the popup window is closed by the user without completing the sign in to the provider.
                        this.resetLoginState();
                        break;
                    // case "auth/unauthorized-domain": // thrown if the app domain is not authorized for OAuth operations for your Firebase project.
                    //     break;
                    case "auth/user-disabled": // thrown if the user corresponding to the given email has been disabled
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*User has been disabled from using tawq.in. Please contact support for help.*";
                        // this.helpMessage = `Hello tawq.in team, ${'%0D%0A'} My name is ${this.newFullname}. I am having trouble signing up for tawq.in, please help me! ${'%0D%0A'} Thanks, ${'%0D%0A'} ${this.newFullname}`;
                        break;
                    default:
                        // this.hintText = "**";
                        // console.error("error signing in with third party!", error);
                        // console.error("error signing in with third party!");
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetLoginState();
                        break;
                }
            }
        },
        setUserData: async function (user, emailVerified) {
            const userData = {
                userId: user.uid,
                fullname: user.displayName,
                email: user.email,
                username: user.displayName,
                profilePicture: user.photoURL,
                profanityFilter: false,
                darkTheme: true,
                emailVerified: emailVerified,
                pushToTawq: true,
                postsCreated: 0,
                playbackStyle: false, // default to pauseplay
                captionAudio: true,
                dualDevice: false,
                signUpMethod: user.providerData[0].providerId,
                signUpDate: new Date(),
            }
            return userData;
        },
        setFirebaseUserData: function (docId, userData) {
            return new Promise((resolve, reject) => {
                firebase.db.collection("userData").doc(docId).set(userData).then(() => {
                    resolve(true);
                }).catch((error) => {
                    console.error("error creating firebase userData: ", error.message);
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                    this.resetLoginState(); // reset the login state
                    return reject(false);
                });
            });
        },
        sendPasswordReset: async function() {
            if (this.userEmail == "" || !this.formIsValid) return;
            let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
            firebase.auth.languageCode = userLang;
            var actionCodeSettings = {
                // url: 'https://tawq.in/login',
                url: process.env.NODE_ENV === 'production' ? 'https://tawq.in/login' : 'http://localhost:8080/login',
                handleCodeInApp: true,
            }

            try {
                await firebase.auth.sendPasswordResetEmail(this.userEmail, actionCodeSettings);
                this.$store.commit('alertUser', { show: true, text: `Password reset email sent.`, type: 'snackbar' });
                this.resetLoginState();
            } catch (error) {
                console.error("ERROR: ", error);
                // this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'snackbar' });
                switch (error.code) {
                    // case "auth/invalid-email": // thrown if the email address is not valid.
                    //     break;
                    // case "auth/missing-continue-uri": // a continue URL must be provided in the request.
                    //     break;
                    // case "auth/invalid-continue-uri": // the continue URL provided in the request is invalid.
                    //     break;
                    // case "auth/unauthorized-continue-uri": // the domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
                    //     break;
                    case "auth/user-not-found": // thrown if there is no user corresponding to the email address.
                        this.needHelp = true;
                        this.hint = true;
                        this.hintText = "*The email entered does not exist in tawq.in. Please try again.*";
                        this.userEmail = "";
                        break;
                    default:
                        console.error("Error sending password reset: ", error);
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Please try again!`, type: 'snackbar' });
                        this.resetLoginState();
                        break;
                }
            }
        },
        resendVerificationEmail: function() {
            const user = firebase.auth.currentUser;
            if (user) {
                let userLang = (navigator.userLanguage || navigator.language).split('-')[0];
                firebase.auth.languageCode = userLang;
                var actionCodeSettings = {
                    url: process.env.NODE_ENV === 'production' ? `https://tawq.in/home?newUser=true` : 'http://localhost:8080/home?newUser=true',
                }
                user.sendEmailVerification(actionCodeSettings).then(() => {
                    this.$store.commit('alertUser', { show: true, text: `Verification email has been sent.`, type: 'snackbar' });
                    this.userEmail = "";
                    this.userPassword = "";
                    this.hint = false;
                    this.hintText = "";
                    this.resendVerification = false;
                }).catch((err) => {
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong sending the verification email. Please try again.`, type: 'snackbar' });
                });
            } else {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. Refresh the page and try again.`, type: 'snackbar' });
                console.error("No user! Refresh the page and try again.");
            }
        },
        emailKeep: async function(){
            var domain = this.userEmail.split('@')[1];
            const subject = "Login Help";
            const message = `Hello tawq.in team, ${'%0D%0A'} I am having trouble accessing my account for tawq.in, please help me! ${'%0D%0A'} Thanks!`;
            switch (domain) {
                case 'email':
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;

                case 'gmail.com':
                    const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=keep@tawq.in&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                    window.open(gmailShare, "_blank");
                    break;
                default:
                    document.location.href = `mailto:keep@tawq.in?subject=${subject}&body=${message}`
                    break;
            } // end of email/gmail switch
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
        },
        removeHintAndHelpIfNecessary: function () {
            if (this.hint || this.needHelp) {
                this.needHelp = false;
                this.hint = false;
                this.hintText = "";
            }
        },
        resetLoginState: function () {
            if (this.resetPassword) this.resetPassword = false;
            this.userEmail = "";
            this.userPassword = "";
            this.loading = false;
            this.needHelp = false;
            this.hint = false;
            this.hintText = "";
        },
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobileDevice;
        },
    },
    created: function() {
        if (this.alertEmailSent) this.$store.commit('alertUser', { show: true, text: `Verification email sent. Please verify your email to get started.`, type: 'snackbar' });
    },
};
</script>

<style>
</style>
