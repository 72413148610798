<template>
<v-row justify="center">
    <v-col cols="12" xl="4" lg="7" md="8" sm="9" xs="12">
        <v-sheet outlined class="px-8 py-4" style="position: relative;">
            <!-- pending payment indicator -->
            <div v-if="checkout.state === 1" class="payment-loading-container ma-0 pa-0">
                <v-overlay absolute>
                    <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                </v-overlay>
            </div>
            <!-- pending payment indicator -->
            <div v-if="type === 'edit'" class="text-center mb-1 text-h6">Change Payment Method</div>
            <div v-else-if="type === 'add'" class="text-center mb-1 text-h6">Add Payment Method</div>
            <v-row class="mb-8" style="width: 100%;" justify="center">
                <span class="text-caption mb-0 pb-0">Secured by</span>
                <v-img v-if="$vuetify.theme.dark" height="30" contain src="../assets/stripe_white_sm.png" class="mt-0 pt-0"> </v-img>
                <v-img v-else height="30" contain src="../assets/stripe_blurple_sm.png" class="mt-0 pt-0"> </v-img>
            </v-row>
            <v-form id="checkoutForm" v-model="paymentFormIsValid" style="max-width: 100%;">
                    <v-row align="center" justify="start">
                        <v-text-field dense class="checkoutInput pt-0 mt-0" :class="[nameIsFocused ? 'primary--text' : 'accent--text']" validate-on-blur required prefix="Name" placeholder="Full Name (as it appears on card)" type="text" single-line v-model="checkout.name" @focus="nameIsFocused = true" @blur="nameIsFocused = false" :rules="nameRules"></v-text-field>
                    </v-row>
                    <v-row align="center" justify="start" class="mt-2">
                        <v-text-field dense class="checkoutInput pt-0 mt-0" :class="[emailIsFocused ? 'primary--text' : 'accent--text']" validate-on-blur required prefix="Email" placeholder="example@example.com" type="email" single-line v-model="checkout.email" @focus="emailIsFocused = true" @blur="emailIsFocused = false" :rules="emailRules"></v-text-field>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-3 px-0">
                        <v-col class="px-0 mx-0 py-0" xl="12" style="position: relative;">
                            <div ref="card" class="pb-2 pt-0"></div>
                            <span class="input-border"></span>
                            <v-slide-y-transition>
                                <div style="width: 100%; position: absolute;" v-if="cardHasErrors" role="alert" class="py-1 text-left error--text card-errors">*{{cardErrors}}
                                </div>
                            </v-slide-y-transition>
                        </v-col>
                    </v-row>

                    <v-row justify="start" align="center" class="mt-10">
                        <v-btn  @click="updatePaymentMethod()" width="100%" :disabled="!validateInputs() || checkout.state === 1" depressed color="primary">Update</v-btn>
                    </v-row>

            </v-form>
        </v-sheet>
    </v-col>
</v-row>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from "../../firebaseConfig.js";
import Alert from "../components/Alert.vue";
export default {
    name: 'EditPaymentMethod',
    components: { 
        Alert,
    },
    props: ["type"],
    data: function() {
        return {
            snackbar: false,
            snackbarText: "",
            // userData: this.$store.state.getUserData,
            loading: true,
            stripe: null,
            stripeElements: null,
            cardElement: null,
            cardErrors: "",
            cardHasErrors: false,
            cardIsValid: false,
            clientCode: "",
            styles: null,
            lightStyle: {
                base: {
                    fontSmoothing: 'antialiased',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                }
            },
            checkout: {
                name: null,
                email: null,
                // address: null,
                planUID: this.planUID,
                state: 0, // 0 = payment collection, 1 = pending payment, 2 = successful payment, 3 = failed payment
            },
            nameIsFocused: false,
            emailIsFocused: false,
            // addressIsFocused: false,
            // planRef: firebase.db.collection("plans"),
            paymentFormIsValid: false,
            nameRules: [
                (v) => !!v || "*Enter your name as it appears on the card."
            ],
             emailRules: [
                (v) => !!v || "Email is required",
                (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid",
            ],
        }
    },
    methods: {
        initializeStripe: async function() {
            this.stripe = await Stripe('pk_test_51HtI2rC3oDEbxLPSnM56fK0ldrHxhS48Z2EbKStM51enTdrclGA9H9Ae1EzOiYHh9uzZ79Ox4oFNtQeQbslrrDWT0093vBQxra');
            // console.log("stripe object", this.stripe);
            this.stripeElements = this.stripe.elements({
                fonts: [ { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' } ],
                locale: 'auto'
            });
            if (this.$vuetify.theme.dark){
                this.lightStyle.base.color = this.styles.getPropertyValue('--v-secondary-lighten4');
            }
            else {
                this.lightStyle.base.color = this.styles.getPropertyValue('--v-secondary-darken1');
            }
            this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-accent-base');
            this.lightStyle.base['::placeholder'] = { color: this.styles.getPropertyValue('--v-inputPlaceholder-base') };
            this.cardElement = this.stripeElements.create('card', {style: this.lightStyle});
            this.cardElement.mount(this.$refs.card);
            this.cardElement.on('change', this.handleCardErrors);
            this.cardElement.on('focus', () => {
                this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-primary-base');
                this.cardElement.update({ style: this.lightStyle });
            });
            this.cardElement.on('blur', () => {
                this.lightStyle.base.iconColor = this.styles.getPropertyValue('--v-accent-base');
                this.cardElement.update({ style: this.lightStyle });
            });

        },
        createPaymentMethod(customerId){
            this.checkout.state = 1;
            this.stripe.createPaymentMethod({
                type: 'card',
                card: this.cardElement,
                billing_details: {
                    name: this.checkout.name,
                    email: this.checkout.email,
                    // address: this.checkout.address,
                },
            })
            .then((result) => {
                if(result.error){
                    this.cardHasErrors = true;
                    this.cardErrors = result.error.message;
                    this.checkout.state = 0;
                    return;
                }
                else {
                    this.updateCustomer(result.paymentMethod.id);
                }
            })
        }, 
        getDefaultPaymentMethod(payment_id){
            this.$http.get(`stripe/payment-method/${payment_id}`)
            .then((response) => {
                if (response.data.error){
                    this.cardErrors = response.data.error;
                    this.cardHasErrors = true;
                    this.checkout.state = 0;
                    return;
                }
                this.$store.dispatch('setStripePaymentMethod', response.data.payment_method);
            })
        },
        updateCustomer(payment_method_id){
            this.$http.put(`stripe/customer/payment/${this.stripeCustomer.customer.id}`, { payment_method_id: payment_method_id })
            .then((response) => {
                if (response.data.error){
                    this.cardErrors = response.data.error;
                    this.cardHasErrors = true;
                    this.checkout.state = 0;
                    return;
                }
                this.getDefaultPaymentMethod(payment_method_id);
                this.$store.dispatch('setStripePaymentMethod', response.data.payment_method);
                this.$emit('updateCustomer', {customer: response.data.customer, payment_method: response.data.payment_method});
            })
        },
        validateInputs(){
            if (this.cardIsValid && this.paymentFormIsValid){
                return true;
            }
            return false;
        },
        handleCardErrors(event){
            // console.log("handle card errors")
            if (event.error){
                this.cardHasErrors = true;
                this.cardErrors = event.error.message;
                this.cardIsValid = false;
            } else {
                if (event.complete){
                    this.cardIsValid = true;
                    this.cardHasErrors = false;
                    this.cardErrors = "";
                } 
                else {
                    this.cardHasErrors = false;
                    this.cardErrors = "";
                }
            }
        },
        updatePaymentMethod(){
            this.createPaymentMethod(this.stripeCustomer.customer.id);
        },
    },
    computed: {
        stripeCustomer(){
            return this.$store.state.stripeCustomer;
        },
        userData() {
            return this.$store.state.userData
        },
    },
    created: function(){
        // console.log("stripe customer", this.stripeCustomer);
        this.checkout.name = this.userData.fullname;
        if (typeof this.userData.email === 'undefined'){
            this.checkout.email = firebase.auth.currentUser.email;
        }
        else {
            this.checkout.email = this.userData.email;
        }
        this.styles = window.getComputedStyle(document.body);
        this.initializeStripe();
    },
    mounted: function() {

    },
}
</script>


    