<template>
    <div class="d-flex flex-row justify-end" style="z-index: 2; transform: translateY(50%);">
        <div v-if="show == 'Pause'" class="d-flex flex-column align-center justify-space-around">
            <v-btn depressed fab color="invert" small @click="audioStateAction('@play', '@pause')">
                <v-icon class="reverseInvert--text">mdi-play</v-icon>
            </v-btn>
        </div>

        <div v-else-if="show == 'Play'" class="d-flex flex-column align-center">
            <v-btn depressed fab color="invert" small @click="audioStateAction('@pause', '@play')">
                <v-icon class="reverseInvert--text">mdi-pause</v-icon>
            </v-btn>
        </div>

        <div v-if="show != 'Record'" class="d-flex flex-column align-center justify-space-around ml-2">
            <v-btn depressed fab color="red" small @click="audioStateAction('@record', '@play')">
                <v-icon class="white--text">mdi-microphone</v-icon>
            </v-btn>
        </div>

        <div v-if="show == 'Record'" class="d-flex d-flex flex-column align-center justify-space-around">
            <v-btn depressed fab color="red" small @click="audioStateAction('@play', '@record')">
                <v-icon class="white--text">mdi-stop</v-icon>
            </v-btn>
        </div>
  </div>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
// import { uploadBytesResumable } from "firebase/storage"
import AudioRecorder from 'audio-recorder-polyfill';
window.MediaRecorder = AudioRecorder; /* comment this line out to test the regular media recorder, once it works we will uninstall the polyfill */

export default {
    name: "push-mobile",
    components: {},
    props: ["isDraftPost", "tawqin", "showProgressBar", "editState",],
    data() {
        return {
            currState: "@pause",
            prevState: "@play",
            show: "Pause", // if show == 'Pause', video is playing (allow for pause); if show == 'Play', video is paused (allow for play); if show != 'Record', audio can record (record button can be pressed); if show == 'Record', audio is recording (stop button can be pressed);
            buttonPressed: false,
            audioSeg: {
                segment: 0,
                audioStart: 0,
                audioEnd: 0,
                audioDur: 0,
                audioURL: null,
                error: false,
            },
            ignore: 500,
            recorder: null,
            recordedChunks: [],
            audioStorageReference: null,
            languageInfo: {},
        };
    },
    methods: {
        spacebarPressed: function (event) {
            if (this.editState || event.isComposing || event.keyCode === 229) return;
            if (event.key === ' ' && this.show !== 'Record') {
                this.audioStateAction('@record', '@play');
            } else if (event.key === ' ' && this.show === 'Record') {
                this.audioStateAction('@play', '@record');
            }
        },
        audioStateAction: function(currState, prevState) {
            switch (`${prevState}-${currState}`) {
                case '@play-@record':
                    this.show = 'Record';
                    this.startRecording();
                    break;
                case '@record-@play':
                    this.show = 'Play';
                    this.stopRecording();
                    break;
                case '@play-@pause':
                    this.show = 'Pause';
                    break;
                case '@pause-@play':
                    this.show = 'Play';
                    break;
                default: // Handle unexpected state transitions or do nothing
                    break;
            }

            this.buttonPressed = (prevState == "@record") ? false : true;
            this.$emit('controlVideoState', currState, prevState);
        },
        startRecording: function () {
            this.audioSeg.audioStart = Number(new Date());
            this.recorder.start();
        },
        stopRecording: function () {
            if (this.recorder.state === "recording") {
                this.recorder.stop();
                this.audioSeg.audioEnd = Number(new Date());
                this.audioSeg.audioDur = this.audioSeg.audioEnd - this.audioSeg.audioStart;
            }
        },
        handleDataAvailable: function (event) {
            const isValidSize = event.data.size > 0;
            const isDurationValid = this.audioSeg.audioDur > this.ignore;

            if (isValidSize && isDurationValid) {
                this.recordedChunks.push(event.data);
                this.uploadAudioRec();
            } else {
                const alertMessage = isDurationValid ? `Audio segment ignored. Please try again!` : `Recording is not long enough. Please try again!`;
                this.$store.commit('alertUser', {show: true, text: alertMessage, type: 'snackbar'});
            }
        },
        uploadAudioRec: async function () {
            this.audioSeg.segment++; // so the segments start at 1 and increments each additional time we upload an audio recording
            var audioSeg = { ...this.audioSeg };
            const audioChunks = [...this.recordedChunks];
            this.recordedChunks = []; // clear audio buffer for next recording

            const blob = new Blob(audioChunks, { type: "audio/webm" });
            var metaData = {
                contentType: "audio/webm",
                customMetadata: {
                    aStart: audioSeg.audioStart,
                    aDuration: audioSeg.audioDur,
                    userId: firebase.auth.currentUser.uid,
                    langCode: this.languageInfo.value,
                    location: this.languageInfo.location,
                    model: this.languageInfo.model,
                    recognizer: this.languageInfo.recognizer,
                    isDev: process.env.NODE_ENV === 'development' ? true : false,
                },
            };
            var storageRef = firebase.storage.ref();

            /***FOR TESTING ONLY***/
            // var rand = Math.random(2);
            // var coinToss = rand < 0.5 ? await storageRef.child(this.audioStorageReference + "/" + audioSeg.segment).put(blob, metaData) : "fail";
            // // console.log("coinToss: ", coinToss);
            // let audioUploaded = coinToss;
            /***END FOR TESTING ONLY***/

            let audioUploaded = await storageRef.child(this.audioStorageReference + "/" + audioSeg.segment).put(blob, metaData);
            audioSeg.error = audioUploaded.state !== "success";
            audioSeg.audioURL = (audioUploaded.state !== "success") ? "failed" : null;
            audioSeg.audioStart = (audioUploaded.state !== "success") ? 0 : audioSeg.audioStart;
            audioSeg.audioEnd = (audioUploaded.state !== "success") ? 0 : audioSeg.audioEnd;
            audioSeg.audioDur = (audioUploaded.state !== "success") ? 0 : audioSeg.audioDur;
            this.$emit("emitAudioSeg", audioSeg);
        },
        disableMicrophone: function () {
            if (this.recorder && this.recorder.stream != undefined) {
                this.recorder.stream.getTracks().forEach((track) => track.stop());
            }
            delete this.recorder;
        },
    },
    watch: {
        tawqin: function (value) {
            this.audioSeg.segment = value.startSegId;
        },
        showProgressBar: function (value) {
            if (this.buttonPressed) {
                this.buttonPressed = false;
                return;
            }
            this.show = value ? "Play" : "Pause";
        },
    },
    created: function () {
        window.addEventListener('keyup', this.spacebarPressed);
        this.audioSeg.segment = this.tawqin.startSegId;
        this.languageInfo = this.tawqin.languageSelected;
        this.audioStorageReference = `audioSegments/${this.tawqin.postId}/${this.tawqin.audioDataId}`;
    },
    mounted: function () {
        if (this.$navigator.mediaDevices) { // if the user device allows us, get their media
            var constraints = { audio: true };
            this.$navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                var options = {
                    mimeType: "audio/webm",
                    audioBitsPerSecond: 128000,
                };
                this.recorder = new MediaRecorder(stream, options);
                this.recorder.addEventListener('dataavailable', this.handleDataAvailable);
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Error: We cannot access your microphone. ${error.message}`, type: 'snackbar' });
            });
        } // January 17, 2024 - if the user does NOT allow for device, what happens? Do we need to implement error or retry functionality?
    },
    beforeDestroy: function () {
        if (this.recorder) this.recorder.removeEventListener('dataavailable', this.handleDataAvailable);
        window.removeEventListener('keyup', this.spacebarPressed);
        this.disableMicrophone();
    },
};
</script>

<style>
.v-btn::before {
    background-color: transparent !important;
}
</style>