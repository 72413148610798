<template>
    <div class="d-flex flex-column">
        <div class="primary py-2 px-4">
            <v-btn icon color="white" outlined medium class="ml-n3" @click="closeDialog(false)"><v-icon>mdi-close</v-icon></v-btn>
            <!-- <span>Terms and Condtions</span> -->
        </div>
        <div class="px-4 py-5 terms-and-conditions white" style="overflow-y: scroll; max-height: 70vh;">
            <TC :agreeTerms="false"/>
        </div>
        <div class="primary lighten d-flex justify-self-end py-2">
            <v-spacer/>
            <v-btn color="white" outlined class="mr-3" @click="closeDialog(true)">Agree</v-btn>
        </div>
    </div>
</template>

<script>
import TC from "../TC.vue";

export default {
  name: "TCModal",
  components: {
      TC,
  },
  props: ['dialog'], 
  data: function() {
    return {
    };
  },
  methods: {
    closeDialog: function(agree){
        this.$emit('setAgree', agree);
    }
  },
};
</script>

<style scoped>
.terms-and-conditions h1,h2,h3,h4,h5,h6 {
    width: 100%;
}

.terms-and-conditions h2 {
    color: #61892F;
}
.terms-and-conditions p {
    width: 100%;
}
</style>