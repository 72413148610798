<template>
    <v-card class="rounded-lg mb-2">
        <v-row class="mx-2 my-2" justify="center">
            <h2 class="text-no-wrap text-truncate">{{selectedText.text ? selectedText.text : "Selected Text"}}</h2>
        </v-row>
        <AudioPlayer class="mx-2" v-if="selectedText.audioURL" :src="selectedText.audioURL"/>
        <v-row class="mx-2 pt-2" justify="space-between" align="center">
            <span v-if="selectedText.languageSelected" class="text-caption text-truncate">{{selectedText.languageSelected.text}}</span>
            <v-btn @click="selectedText.showMore = !selectedText.showMore" :ripple="false" icon>
                <v-icon large>{{ selectedText.showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-row>
        <v-row v-if="selectedText.showMore" justify="center" class="mx-5 py-2">
            <span>{{selectedText.caption ? selectedText.caption : 'no caption'}}</span>
        </v-row>
    </v-card>
</template>

<script>
import AudioPlayer from "../AudioPlayer.vue";

export default {
    name: "selected-text",
    props: ['selectedText'],
    components: {
        AudioPlayer,
    },
    data: function () {
        return {};
    },
    methods: {},
    watch: {},
    computed: {},
    beforeCreate: function () {},
    created: function () {},
    mounted: function () {},
    updated: async function () {},
    beforeDestroy: function () {},
    destroyed: function () {},
}
</script>

<style scoped>
</style>