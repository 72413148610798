<template>
    <v-app>
        <Alert v-if="alertUser.show" :key="NaN" :type="alertUser.type" :text="alertUser.text" :snackbarVal="alertUser.show" @snackbarClicked="$store.commit('alertUser', { show: false, text: '', type: '' })"/>
        <router-view />
    </v-app>
</template>

<script>
import firebase from '../firebaseConfig.js'
import { bootstrap, setOptions } from 'vue-gtag'
import Alert from "./components/Alert.vue";
export default {
    name: 'App',
    components: {
        Alert,
    },
    metaInfo: {
        title: 'tawq.in',
        // titleTemplate: '%s - tawq.in',
        htmlAttrs: {
            lang: 'en-US'
        },
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: 'tawq.in gives you the capability to record your voice over any video, while simultaneously creating subtitles for your newly created audio. You are able to playback the video with the new audio while seeing subtitles.' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    },
    data: function () {
        return {
            unsubscribeFromAuth: null,
        }
    },
    methods:{
        enableGoogleAnalytics: function(){
            /* enable google analytics if it isn't me, scott, marty or carrie */
            if (firebase.auth.currentUser) {
                const excludeList = [
                    "lXVXV6nvtLTRWJQbtvg9zH8C1hL2", // kins
                    "376kKAMyuSQL5NKMk6urj24nJqo2", // kins
                    "qiNyZs8bAyRQchABOfcrRh9I7Z43", // kins // REMOVE
                    "hEgrszkQBKRfUdI2iVy9XgL0A8p2", // kins
                    "yzBy9SStgyNr1Ncjf9pkQjfJUO03", // dillan
                    "ex18uSUvXReInwQRqaAZxO6Syer2", // dillan
                    "9hg1CKOXMFefAWLkUMaBGv7j40u1", // scott
                    "7pkXTmbCOfZdiDYfSCmTSrv8OdA2", // scott
                    "bdhTiBv1rtgs5f3QrT1f8GL614K3", // carrie
                    "mEiUvmKgUNgQyJ3EAcAvqanUdyU2", // moesha
                    "Im3HI27YnecPmP8FW3kZy1rfFT43", // keep@tawq.in
                ]
                if (excludeList.includes(firebase.auth.currentUser.uid)) {
                    /* DISABLE IT BECAUSE IT IS ALREADY ON IF ONE OF THE EXCLUDED USERS ISN'T SIGNED IN WHEN FIRST LOADING THE PAGE!! */
                    setOptions({
                        config: { id: "UA-59943727-3" },
                        enabled: false,
                        bootstrap: false,
                    })
                    // console.log("gtag", this.$gtag);
                    // this.$gtag.optOut();
                    // this.$window['ga-disable-UA-59943727-3'] = true;
                    bootstrap().then(() => {});
                    // console.log("not enabling google analytics because you are part of the exclude list.");
                    return;
                } else if (window.location.host === '0-0-0-dot-tawq-in-www.appspot.com' || process.env.NODE_ENV === 'development') {
                    setOptions({
                        config: { id: "UA-59943727-3" },
                        enabled: false,
                        bootstrap: false,
                    })
                    bootstrap().then(() => {});
                    // console.log("not enabling google analytics because you are on the development site.");
                    return;
                } else {
                    setOptions({
                        config: { id: "UA-59943727-3" },
                        enabled: process.env.NODE_ENV === 'production' ? true : false,
                        // enabled: process.env.NODE_ENV === 'development' ? true : false,
                        bootstrap: false,
                    })
                    bootstrap().then(() => {
                        // console.log("here");
                    });
                }
            } else {
                if (window.location.host === '0-0-0-dot-tawq-in-www.appspot.com' || process.env.NODE_ENV === 'development') {
                    setOptions({
                        config: { id: "UA-59943727-3" },
                        enabled: false,
                        bootstrap: false,
                    })
                    bootstrap().then(() => {});
                    // console.log("not enabling google analytics because you are on the development site.");
                    return;
                } else {
                    setOptions({
                        config: { id: "UA-59943727-3" },
                        enabled: process.env.NODE_ENV === 'production' ? true : false,
                        // enabled: process.env.NODE_ENV === 'development' ? true : false,
                        bootstrap: false,
                    })
                    bootstrap().then(() => {});
                }
            }
        },
        toggleDrawer: function (value) {
            this.$store.commit("changeDrawer", value);
        },
        createUserDataInFirebaseFirestore: async function () {
            let currentUser = firebase.auth.currentUser;
            const user = {
                uid: currentUser.uid,
                displayName: currentUser.displayName,
                email: currentUser.email,
                emailVerified: false,
                signUpMethod: "email",
                profilePicture: "",
            }
            const userData = {
                    userId: user.uid,
                    fullname: user.displayName,
                    email: user.email,
                    username: user.displayName,
                    profanityFilter: false,
                    darkTheme: true,
                    emailVerified: user.emailVerified,
                    pushToTawq: true,
                    postsCreated: 0,
                    playbackStyle: false, // default to pauseplay
                    captionAudio: true,
                    dualDevice: false,
                    signUpMethod: user.signUpMethod,
                    signUpDate: new Date(),
                    profilePicture: user.profilePicture,
                }
            await firebase.db.collection("userData").doc(userData.userId).set(userData);
            await this.$store.dispatch('initializeStore', firebase.auth.currentUser);
        },
    },
    computed: {
        stripeCustomer() {
            return this.$store.state.stripeCustomer;
        },
        alertUser(){
            return this.$store.state.alertUser;
        },
        userData(){
            return this.$store.state.userData;
        },
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        isViewPost(){
            return (this.$route.path === '/view-post') ? true : false;
        },
        isAboutPage(){
            return (this.$route.path === '/about' || this.$route.path === '/ar-about') ? true : false;
        },
        isNewPostOrContribution(){
            return (this.$route.path === '/new-post' || this.$route.path === '/new-contribution') ? true : false;
        },
        isIntroLoginSignupAccountManagePostView(){
            return (this.$route.path === '/intro' || this.$route.path === '/ar-intro' || this.$route.path === 'login' || this.$route.path === 'ar-login' || this.$route.path === 'sign-up' || this.$route.path === 'ar-sign-up' || this.$route.path === 'manage-account' || this.$route.path === 'ar-manage-account' || this.$route.path === '/post-view') ? true : false;
        },
    },
    updated: function() {
        if (!this.isMobile && !this.isViewPost || !this.isAboutPage) this.toggleDrawer(true);
        if (this.isViewPost || this.isNewPostOrContribution || this.isIntroLoginSignupAccountManagePostView || this.isAboutPage) this.toggleDrawer(false);
    },
    watch:  {
        "$store.state.userData": {
            handler: async function (newUserData , oldUserData) {
                if (typeof oldUserData != 'undefined' && oldUserData != null && typeof newUserData != 'undefined' && newUserData != null) {
                    if (oldUserData.userId == newUserData.userId) {
                        // console.log("same, returning!");
                        return;
                    }
                    const isEmpty = Object.keys(newUserData).length === 0;
                    // console.log("isEmpty: ", isEmpty);
                    // console.log("route.path: ", this.$route.path);
                    if(this.$route.path != '/intro' && this.$route.path != '/login' && this.$route.path != '/sign-up' && this.$route.path != '/manage-account' && this.$route.path != '/post-view'){
                        if(typeof newUserData != 'undefined' && newUserData != null && !isEmpty){
                            if(typeof this.stripeCustomer.product != 'undefined' && this.stripeCustomer.product.id != newUserData.stripeProductId){
                                // console.log("dispatching and making a request in userData handler!")
                                this.$store.dispatch('setAccountType', { product: newUserData.stripeProductId, active: newUserData.stripeIsActive });
                                this.$store.dispatch('getStripeSubscription', this.stripeCustomer.subscription.id);
                            }
                        }
                    }
                    if(typeof newUserData != 'undefined' && newUserData != null && !isEmpty && newUserData.darkTheme != this.$vuetify.theme.dark){
                        // This function is run fast enough that if we dont 'await' or have a timeout function it will set the theme to dark
                        // by default and will not update parts of the app with the correct theme.
                        var dark = await newUserData.darkTheme;
                        this.$vuetify.theme.dark = dark;
                    }
                }
            }, immediate: true
        },
        '$vuetify.theme.dark': {
            handler: function(newData){
                this.$vuetify.theme.dark = newData;
                // console.log("vuetify theme changed!");
            }
        }
    },
    created: function() {
        this.$store.commit('setIsIOSDevice');
        this.$store.commit('setIsMobileDevice');
        if (this.isViewPost || this.isIntroLoginSignupAccountManagePostView || this.isAboutPage) this.toggleDrawer(false);
    },
    beforeMount: function() {
        /*  WHEN AUTH STATE CHANGES: 
            Right after the listener has been registered
            When a user is signed in
            When the current user is signed out
            When the current user changes

            when the user comes to login/signup, the app.vue will be initialized. Once they 
            login or signup their authState will change. if there is a current user at this point
            we will initialize the store, which retrieves the user data (and sets a listener on it), 
            then uses the user's stripeCustomerId to set the stripe data globally in the store */

        this.unsubscribeFromAuth = firebase.auth.onAuthStateChanged(async() => {
            if (firebase.auth.currentUser) {
                firebase.db.collection("userData").doc(firebase.auth.currentUser.uid).get().then(async(doc) => {
                    if (!doc.exists) { // something went wrong and the user was created but nothing was created for them in the store. Create that here!
                        this.createUserDataInFirebaseFirestore();
                    } else { // everything is working as intended, proceed as normal
                        await this.$store.dispatch('initializeStore', firebase.auth.currentUser);
                    }
                });
            } else {
                // this.$vuetify.theme.dark = false;
                this.$vuetify.theme.dark = true; // This will force the app to be in dark mode when a user is not signed up.
                // Dark theme by default added on August 30, 2023 by Scott Brady. This was added because I test almost all code with dark theme.
            }
            this.enableGoogleAnalytics();
        });
    },
    beforeDestroy: function() {
        this.unsubscribeFromAuth();
    }
};
</script>

<style>
.app-background{
    background-color: var(--v-background-base);
}

.border--primary {
    border: solid var(--v-primary-base) 1px;
}
</style>