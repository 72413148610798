<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="8" lg="8" md="10" sm="10">

            <v-row justify="center" no-gutters>
                <span class="text-h6 text-sm-h5 text-center">Record Your Voice</span>
            </v-row>

            <!-- DUAL DEVICE CREATION ***REMOVED ON OCTOBER 28, 2022 AT MARTY'S REQUEST (last seen in code on January 2, 2024 in vue3 git branch)***  -->

            <v-row justify="center" no-gutters class="mt-4">
                <VideoPlayer
                    ref="vidPlayer"
                    :options="vidOptions"
                    :isCreating="true"
                    :videoLoading="videoLoading"
                    @playerReady="videoLoading = false"
                    @videoDuration="setVideoDuration"
                    @visibilityChanged="audioPlayer.pause()"
                    @videoPauseButtonClicked="pauseProgressBar"
                    @videoPlayButtonClicked="playProgressBar"
                />

                <v-row justify="center" class="ma-0 pa-0">
                    <!-- OLD RECORDING PROGRESS BAR CODE (removed/updated by Scott Brady on March 20, 2024) -->
                        <!-- <v-col v-if="showProgressBar" cols="12" xl="10" lg="10" md="12" sm="12" class="my-0 mb-0 mt-1 pa-0">
                            <v-row justify="space-between" class="ma-0 pa-0 text-caption">
                                <span class="font-weight-bold">{{emitTime / 1000 + " seconds"}}</span>
                                <span class="font-weight-bold">{{ timePlaying / 1000 + " seconds" }}</span>
                            </v-row>

                            <v-progress-linear v-if="(timePlaying / 1000) >= (emitTime / 1000)" background-color="secondary" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>

                            <v-progress-linear v-else-if="(timePlaying / 1000) < (emitTime / 1000)" color="red" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>

                            <v-row justify="center" class="ma-0 pa-0 smallText text-center px-3">
                                <span v-if="(timePlaying / 1000) >= (emitTime / 1000)" class="font-weight-bold">This bar is used to track recommended recording size for a caption</span>
                                <span v-else-if="(timePlaying / 1000) < (emitTime / 1000)" class="font-weight-bold">Your audio and captions my not align correctly in the video</span>
                            </v-row>
                        </v-col> -->

                        <!-- <v-col cols="10" xl="8" lg="8" md="10" sm="10">
                            <v-row justify="center" align="start" style="z-index: 2; transform: translateY(50%);" >
                                <v-col cols="2" class="text-caption green-border">
                                    <v-row justify="center">
                                        <span class="font-weight-bold">{{emitTime / 1000 + " seconds"}}</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="10" class="green-border">
                                    <v-row>
                                        <v-progress-linear v-if="(timePlaying / 1000) >= (emitTime / 1000)" background-color="secondary" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>
                                        <v-progress-linear v-else-if="(timePlaying / 1000) < (emitTime / 1000)" color="red" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" class="my-1"></v-progress-linear>
                                    </v-row>
                                    <v-row justify="center" class="smallText text-center">
                                        <span v-if="(timePlaying / 1000) >= (emitTime / 1000)" class="font-weight-bold">This bar is used to track recommended recording size for a caption</span>
                                        <span v-else-if="(timePlaying / 1000) < (emitTime / 1000)" class="font-weight-bold">Your audio and captions my not align correctly in the video</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="2" class="text-caption green-border">
                                    <v-row justify="center">
                                        <span class="font-weight-bold">{{ timePlaying / 1000 + " seconds" }}</span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="2" class="blue-border"> -->
                    <!-- END OLD RECORDING PROGRESS BAR CODE (removed/updated by Scott Brady on March 20, 2024) -->

                    <v-col cols="12" xl="10" lg="10" md="12" sm="12" >
                        <v-row v-if="isMobile" justify="end" align="center" class="full-width mx-0">
                            <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar" class="mt-0 mb-2"/>
                            <Push v-if="userData.pushToTawq" :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                            <Pickup v-else :isDraftPost="isDraftPost" :tawqin="tawqin" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                        </v-row>

                        <v-row v-else justify="space-between" align="center">
                        <!-- <v-row v-else justify="end" align="center"> -->
                            <RecordProgressBar :emitTime="emitTime" :timePlaying="timePlaying" :showProgressBar="showProgressBar"/>
                            <Push :isDraftPost="isDraftPost" :tawqin="tawqin" :showProgressBar="showProgressBar" :editState="editState" @controlVideoState="controlVideoState" @emitAudioSeg="updateTawqDataAfterUploadAudioRec"/>
                        </v-row>
                    </v-col>

                    <v-col cols="12" xl="10" lg="10" md="12" sm="12" class="caption-card-container d-flex flex-column-reverse inverseGrey py-6">
                        <CaptionCard
                            v-for="(data, key) in tawqData"
                            :key="parseInt(key)"
                            :index="parseInt(key)"
                            :showCaptions="(postData.languageSelected.text === 'None (Disable Auto Captions)' ? false : true)"
                            :loading="data.caption === null || data.audio.audioURL === null"
                            :videoDuration="videoDuration"
                            @deleteAudioSegment="deleteAudioSegment"
                            @audioPlay="playAudioSegment"
                            @updateScroll="updateScroll"
                            @editState="sendEditStateToPush"
                        />
                        <v-divider v-if="hasAudio"/>
                        <div :class="(postData.languageSelected.text === 'None (Disable Auto Captions)' ? 'mb-2' : '')" class="text-lg-subtitle-2 text-caption text-center">{{((postData.languageSelected.text === 'None (Disable Auto Captions)' && !showCaptions) ? 'Automatic captioning has been disabled. To enable, go to back to step 1 and select the language you will be speaking. You must record at least once to move to the review process.' : (postData.languageSelected.text === 'None (Disable Auto Captions)' && showCaptions) ? 'Automatic captioning has been disabled. To enable, delete your current segments and go to back to step 1 and select the language you will be speaking.' : (!showCaptions ? 'You must record at least once to move to the review process.' : ''))}}</div>
                    </v-col>
                </v-row>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../firebaseConfig";
import VideoPlayer from "./VideoPlayer";
import CaptionCard from "./CaptionCard";
import Push from "./Mobile/Push";
import Pickup from "./Mobile/Pickup";
import RecordProgressBar from './RecordProgressBar';

export default {
    props: ["previewVideo", "isDraftPost"],
    components: {
        VideoPlayer,
        CaptionCard,
        Push,
        Pickup,
        RecordProgressBar,
    },
    data: function () {
        return {
            videoLoading: true,
            currentMobile: {
                state: "@pause",
                prevState: "@play",
            },
            segStart: "",
            videoSeg: {
                segment: 0,
                vStart: null,
            },
            vIgnore: 0.5,
            tawqin: {},
            dbRefs: {
                subtitleRef: null,
                conversionRef: null,
            },
            vidOptions: {
                autoplay: false,
                controls: false,
                responsive: true,
                sources: {
                    src: this.previewVideo.type === "video/youtube" ? this.previewVideo.src + `?controls="0"` : this.previewVideo.src,
                    type: this.previewVideo.type,
                },
                techOrder: ["html5", "youtube"],
                textTrackSettings: false,
                html5: {
                    nativeTextTracks: false,
                    nativeControlsForTouch: false,
                    nativeAudioTracks: false,
                },
                youtube: {
                    enablejsapi: 1,
                    fs: 0,
                    playsinline: 1,
                    cc_load_policy: 3,
                    // cc_load_policy: 0,
                    disablekb: 1,
                    ytControls: 0,
                    // origin: "https://tawq.in" || window.location.orgin, // maybe can use this instead of having to comment out below for testing?
                    // origin: "https://tawq.in", // uncomment for deploy to dev/main
                    origin: window.location.origin, // uncomment for testing only
                    modestbranding: 1,
                    start: 0,
                    // widget_referrer: "https://tawq.in",
                },
            },
            emitTime: 0,
            timePlaying: 0,
            INTERVAL: 1000,
            captionTimer: null,
            audioTimer: null,
            showProgressBar: false,
            editState: false,
            showCaptions: false,
            changeValueForDatabaseCapture: 0,
            isRecording: false,
            isPlayingOwnCreatedSegment: false,
            audioPlayer: new Audio(),
        };
    },
    methods: {
        turnOnRefs: function (userId) {
            // the following 2 rtdb refs are the only ones used for sending info from cloud function to PCC
            this.dbRefs.subtitleRef = firebase.rtDb.ref(`${userId}/${this.postData.audioDataId}/subtitle`);
            this.dbRefs.conversionRef = firebase.rtDb.ref(`${userId}/${this.postData.audioDataId}/conversion`);
        },
        turnOffRefs: function() { // refs with listeners on this component
            this.dbRefs.subtitleRef.off();
            this.dbRefs.conversionRef.off();
        },
        setVideoDuration: function (duration) {
            this.videoDuration = duration;
        },
        pauseProgressBar: function () {
            // console.log("pauseProgressBar");
            if (this.currentMobile.state !== "@record") this.showProgressBar = false;

            clearInterval(this.audioTimer);
        },
        playProgressBar: function () {
            // console.log("playProgressBar");
            if (this.isPlayingOwnCreatedSegment) return; // added so that when a user is playing their own created segment we wont start the recording progress bar
            clearInterval(this.audioTimer);
            this.audioTimer = setInterval(() => {
                this.timePlaying += this.INTERVAL;
            }, this.INTERVAL);

            this.showProgressBar = true;
        },
        controlVideoState: function (currState, prevState) {
            // console.log("controlVideoState: ", currState, prevState);
            const vidPlayer = this.$refs.vidPlayer;
            if (!vidPlayer) return;

            this.currentMobile.state = currState;
            this.currentMobile.prevState = prevState;

            if (currState === "@pause") vidPlayer.playerPause();

            if (currState === "@play") {
                vidPlayer.playerPlay();
                if (prevState === "@record") { // reset progress bar timer
                    this.isRecording = false;
                    clearInterval(this.captionTimer);
                    this.timePlaying = 0;
                    this.emitTime = 0;

                    const segEnd = Date.now();
                    var dt = (Math.abs(segEnd - this.segStart) / 1000) % 60; // get seconds from vid segment difference
                    if (dt > this.vIgnore) {
                        this.videoSeg.segment = this.findLargestSegId + 1;
                        this.videoSeg.dt = dt;
                        var videoSegValue = JSON.parse(JSON.stringify(this.videoSeg));
                        this.$set(this.tawqData, this.videoSeg.segment, {
                            'videoSeg': videoSegValue,
                            'audio': (typeof this.tawqData[this.videoSeg.segment] != 'undefined') ? this.tawqData[this.videoSeg.segment].audio : null,
                            'caption': (typeof this.tawqData[this.videoSeg.segment] != 'undefined') ? this.tawqData[this.videoSeg.segment].caption : null,
                        });
                        this.videoSeg.vStart = null;
                    } else { // This case only happens when a recording is not long enough, that is to say, less than half a second. So this should only be called when the record/stop button(s) are pressed in rapid succession. When this happens the 'Next' button become disabled and will not reenable unless the below function is called
                        this.checkForErrorInTawqData(this.tawqData);
                    }
                }
            }

            if (currState === "@record") {
                this.contentCreationStep.complete = false;
                this.isRecording = true;
                if (!this.showProgressBar) this.showProgressBar = true; // if user clicks on record while vidPlayer is paused or before video enters play state

                vidPlayer.playerPause();
                clearInterval(this.audioTimer);
                this.segStart = Date.now();
                this.videoSeg.vStart = vidPlayer.playerGetTime();
                this.emitTime = 0;

                this.captionTimer = setInterval(() => {
                    this.emitTime += this.INTERVAL;
                }, this.INTERVAL);
            }
        },
        updateTawqDataAfterUploadAudioRec: function (audioSeg) {
            if (audioSeg.error) { // occurs in Push.vue or Pickup.vue, when audio file fails to upload to firebase cloud storage
                this.tawqData[audioSeg.segment].error = audioSeg.error;
                this.tawqData[audioSeg.segment].audio = audioSeg;
                this.tawqData[audioSeg.segment].caption = "*This Audio Segment Failed. Delete it to Continue*";
                this.contentCreationStep.complete = false;
            } else {
                let timeout = 570000; // 9.5 minutes, just to make sure the cloud function times out.
                const timeoutId = setTimeout(() => { // because there is a timeout of 9 minutes for the cloud function, and there is no error sent if the cloud function times out, we will set a timeout function here (since this is the first place we know the audio file has been uploaded).
                    // console.log("cloud function timed out. Go into error state, so we can stop showing 'converting...'");
                    this.$emit("clearCloudFunctionTimeout", audioSeg.segment, false); // timeout function completed, which means the cloud function reached timeout limit of 9 minutes. only clear timeoutId from parent (NewPost.vue) components cloudFunctionTimeoutIds
                }, timeout);
                this.$emit("setCloudFunctionTimeout", audioSeg.segment, timeoutId);

                if (typeof this.tawqData[audioSeg.segment] === "undefined") {
                    // we can't create a new audio object here because we won't have the video seg data for it....
                    // I am not sure when this ever happens!!!
                    // console.log("when audio segment came back, this.tawqData[audioSeg.segment] is undefined, so we can't set start times.... segment = ", audioSeg.segment);
                    return;
                } else if (typeof this.tawqData[audioSeg.segment].audio === 'undefined' || this.tawqData[audioSeg.segment].audio === null) { // saving the audio object for the first time
                    audioSeg.audioStart = Math.max(0, this.tawqData[audioSeg.segment].videoSeg.vStart - this.tawqData[audioSeg.segment].videoSeg.dt);
                    audioSeg.audioDur = this.tawqData[audioSeg.segment].videoSeg.dt;
                    audioSeg.audioEnd = audioSeg.audioStart + audioSeg.audioDur;

                    this.tawqData[audioSeg.segment].audio = audioSeg;
                } else { // we already have an audio object for this audio seg id (happens if they go back and forth between steps after already creating captions)
                    return;
                }
            }
        },
        pushTawqinData: function () {
            this.changeValueForDatabaseCapture++ // if we do not update this value when tawqinRef.set() is called below it will not recognize an update and there is a potential we will enter an error state that the user cannot get out of.
            var startSegId = this.findLargestSegId;
            var data = {
                languageSelected : this.postData.languageSelected,
                postId : this.postData.postId,
                audioDataId : this.postData.audioDataId,
                startSegId: startSegId,
                changeValueForDatabaseCapture: this.changeValueForDatabaseCapture,
            };
            this.tawqin = data;
            // console.log("tawqin data: ", this.tawqin);
        },
        checkForErrorInTawqData: function (data) { // This function is used to make sure there is not an error in any of the completed AudioSegments. If there is we don't allow the user to move on in the creation process.
            if (this.isRecording && !this.contentCreationStep.complete) return;
            this.contentCreationStep.complete = !Object.values(data).some(item => (item.error || (item.caption === null)));
        },
        setAudioSegmentURL: function (filename, location) {
            let storageRef = firebase.storage.ref();
            storageRef.child(`${location}`).getDownloadURL().then((url) => {
                this.tawqData[parseInt(filename)].audio.audioURL = url;
                this.checkForErrorInTawqData(this.tawqData);
                this.$emit("clearCloudFunctionTimeout", parseInt(filename), true); // cloud function completed, clear timeout function and timeoutId from parent (NewPost.vue) components cloudFunctionTimeoutIds
            }).catch((error) => {
                // this.$store.commit('alertUser', { show: true, text: `Oops, Couldn't retrieve your audio segments at this time. Error: ${error.message}`, type: 'snackbar' });
                console.log("An error occured when setting the audio segment url. The error is: ", error);
                this.$store.commit('alertUser', { show: true, text: `Oops, Couldn't retrieve your audio segments at this time`, type: 'snackbar' });
            });
        },
        playAudioSegment: function(audioSegId) {
            this.pauseProgressBar(); // called here so that the recording progress bar does not continue to play while user is listening to their own created segment
            !this.audioPlayer.paused && this.audioPlayer.pause(); // if the player is NOT paused then pause, else nothing;
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true);

            this.isPlayingOwnCreatedSegment = true;
            this.$refs.vidPlayer.playerPause();
            let videoTimeAtPlay = this.$refs.vidPlayer.playerGetTime();

            let audioToPlay = this.tawqData[audioSegId].audio;
            this.audioPlayer.src = audioToPlay.audioURL;
            this.$refs.vidPlayer.playerSetVolume(0);
            this.$refs.vidPlayer.playerSetTime(audioToPlay.audioStart);
            this.audioPlayer.play();

            this.audioPlayer.addEventListener("ended", () => {
                this.$refs.vidPlayer.playerSetVolume(1);
                this.isPlayingOwnCreatedSegment = false;
                this.$refs.vidPlayer.playerSetTime(videoTimeAtPlay - 0.1);
                this.$refs.vidPlayer.playerPlay();
            }, { once: true });
        },
        deleteAudioSegment: function(data) { // this function deletes the segment from the firestore database
            if (data.audioSegmentFailed) { this.deleteAudioSegmentLocally(data); return; } // segment failed to upload, only delete locally

            var storageRef = firebase.storage.ref("audioSegments");
            var audioRef = storageRef.child(`/${this.postData.postId}/${this.postData.audioDataId}/${data.audioSegId}`);

            audioRef.delete().then(() => {
                    this.deleteAudioSegmentLocally(data); // go delete the local list of audioData
            }).catch((error) => {
                console.log("error deleting audio file: ", error.message);
                this.$store.commit('alertUser', { show: true, text: `Couldn't delete the audio file. Error: ${error.message}`, type: 'snackbar' });
            });
        },
        deleteAudioSegmentLocally: function (data) {
            this.$delete(this.tawqData, data.audioSegId);
            this.pushTawqinData();
            this.checkForErrorInTawqData(this.tawqData);
            if (!this.hasAudio) this.contentCreationStep.complete = false;
        },
        updateScroll: function() { // Caption has been converted and pushed to top of list, scroll to the top of the list.
            var element = document.getElementsByClassName("caption-card-container")[0];
            let height = element.scrollHeight;
            element.scrollTop = ( 0 - height );
        },
        sendEditStateToPush: function (editState) {
            this.editState = editState;
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        isIOS(){
            return this.$store.state.isIOSDevice;
        },
        userData(){
            return this.$store.state.userData;
        },
        tawqData: {
            get(){
                let data = this.$store.state.newPost.tawqData
                if (Object.keys(data).length > 0) this.showCaptions = true;
                else this.showCaptions = false;
                return data;
            },
            set(value){
                this.$store.commit('saveTawqData', value);
            }
        },
        findLargestSegId: function() {
            const tawqDataKeys = Object.keys(this.tawqData);

            if (tawqDataKeys.length <= 0) return 0;

            return tawqDataKeys.reduce((largest, key) => {
                const parsedKey = parseInt(key);
                return parsedKey > largest ? parsedKey : largest;
            }, 0);
        },
        contentCreationStep: {
            get() {
                return this.$store.state.newPost.contentCreationStep;
            },
            set(value){
                this.$store.commit('saveContentCreationStep', value);
            }
        },
        postData:{
            get(){
                return this.$store.state.newPost.createPost;
            },
            set(value){
                this.$store.commit('saveCreatePost', value);
            }
        },
        videoDuration: {
            get() {
                return this.$store.state.newPost.contentCreationStep.videoDuration;
            },
            set(value){
                this.$store.commit('saveVideoDuration', value);
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
    },
    created: function () {
        if (this.isIOS) this.playbackStyle = 'pauseplay';
        this.turnOnRefs(firebase.auth.currentUser.uid); //update realtime refs with current user Id to allow connection to mobile
        this.pushTawqinData();
    },
    mounted: function () {
        this.dbRefs.subtitleRef.on("child_added", (snapshot) => {
            if (snapshot.val() === null) return;

            if (typeof this.tawqData[parseInt(snapshot.val().audioSegId)] === "undefined") {
                // console.log('ERROR: NOT SAVING CAPTION OBJECT BECAUSE THIS AUDIO ID IS UNDEFINED IN TAWQ DATA. audioSegId = ', snapshot.val().audioSegId); // FIGURE OUT HOW TO HANDLE THIS ERROR STATE!!!
                this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                this.tawqData[parseInt(snapshot.val().idToDelete)].error = true;
                this.tawqData[parseInt(snapshot.val().idToDelete)].caption = "*This Audio Segment Failed. Delete it to Continue*";
                this.contentCreationStep.complete = false;
                return;
            } else if (typeof this.tawqData[parseInt(snapshot.val().audioSegId)].caption === 'undefined' || this.tawqData[parseInt(snapshot.val().audioSegId)].caption === null) { // Ran the first time we are adding a caption for the specified audioSegId.
                const formattedCaption = typeof snapshot.val().subtitle !== 'undefined' ? snapshot.val().subtitle.charAt(0).toUpperCase() + snapshot.val().subtitle.slice(1) : "";
                this.tawqData[parseInt(snapshot.val().audioSegId)].caption = formattedCaption || snapshot.val().subtitle || "";
            } else { // we already have a caption for this audio seg id (happens if they go back and forth between steps after already creating captions)
                return;
            }
        },(error) => {
            console.error(error.message);
            this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
        });

        this.dbRefs.conversionRef.on("child_added", (snapshot) => {
            if (snapshot.val() === null) return;

            if (typeof this.tawqData[parseInt(snapshot.val().filename)] === "undefined") {
                this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                this.tawqData[parseInt(snapshot.val().idToDelete)].error = true;
                this.tawqData[parseInt(snapshot.val().idToDelete)].audio.audioURL = "failed";
                this.contentCreationStep.complete = false;
                return;
            } else {
                if (typeof this.tawqData[parseInt(snapshot.val().filename)].audio.audioURL != 'undefined' && this.tawqData[parseInt(snapshot.val().filename)].audio.audioURL != null) { // we have already retrieved the url for the audio (they go back and forth between steps)
                    console.log("already retrieved the url");
                    // FIGURE OUT HOW TO HANDLE THIS ERROR STATE!!!
                    // do we need to clear the timeout here?
                    // this.$emit("clearCloudFunctionTimeout", parseInt(snapshot.val().idToDelete), true); // cloud function failed, clear timeout function and timeoutId from parent (NewPost.vue) component
                    return;
                }
                let filename = snapshot.val().filename;
                let location = snapshot.val().location;
                // we need to retrieve the url's for the audioSegments here, because the cloud function converts them to mp4 which will change the the download URL that they originally have when uploaded to storage
                this.setAudioSegmentURL(filename, location);
            }
        },(error) => {
            console.error(error.message);
            this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
        });
    },
    beforeDestroy: function () {
        if (this.audioPlayer) {
            this.audioPlayer.pause();
            this.audioPlayer.remove();
        }
        clearInterval(this.captionTimer);
        clearInterval(this.audioTimer);
        this.turnOffRefs();
        // we remove the two refs below because if not then it does a lot of unneccessary work anytime switching between steps while creating a post. Plus, this is the only component that needs that info
        this.dbRefs.subtitleRef.remove();
        this.dbRefs.conversionRef.remove();
    },
};
</script>

<style lang="scss">
.smallText {
    font-size: 10px;
}
.caption-card-container {
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.caption-card-container::-webkit-scrollbar {
    width: 7px;
    height: 100%;
}

/* Handle */
.caption-card-container::-webkit-scrollbar-thumb {
    background: #86c232 !important;
    height: 20% !important;
}



/* XS */
@media (max-width: 600px){
    .caption-card-container {
        max-height: 35vh;
        min-height: 15vh;
    }
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    .caption-card-container {
        max-height: 28vh;
        min-height: 12vh;
    }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .caption-card-container {
        max-height: 40vh;
        min-height: 10vh;
    }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .caption-card-container {
        max-height: 22vh;
    }
}

/* XL */
@media (min-width: 1905px){
    .caption-card-container {
        max-height: 23vh;
    }
}
</style>
