<template>
    <v-dialog v-model="dialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition" origin="bottom center">
        <v-card v-if="typeof group === 'undefined'">
            <v-toolbar flat color="primary">
                <v-btn icon @click.stop="$store.commit('saveGroupSettingsDialog', false);"><v-icon class="white--text">mdi-close</v-icon></v-btn>
                <v-toolbar-title class="white--text">Settings</v-toolbar-title>
            </v-toolbar>

            <v-card-title class="justify-center text-h5">Loading Group...</v-card-title>
            <v-divider/>
      
            <v-row justify="center" align="center" no-gutters>
                <v-col cols="12" align="center" class="mt-5">
                    <v-progress-circular color="primary" indeterminate :size="100" :width="7"/>
                </v-col>
            </v-row>
        </v-card>

        <v-card v-else>
            <v-toolbar flat color="primary">
                <v-btn icon @click.stop="$store.commit('saveGroupSettingsDialog', false);"><v-icon class="white--text">mdi-close</v-icon></v-btn>
                <v-toolbar-title class="white--text">Settings</v-toolbar-title>
            </v-toolbar>

            <v-card-title class="justify-center text-h5">{{ group.groupName }}</v-card-title>
            <v-divider/>

            <v-row v-if="!('accepted' in group)" no-gutters class="mt-5">
                <v-col cols="12" xl="6" lg="6" md="8" sm="12" class="mt-5 mt-lg-0 order-last order-lg-first">
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" xl="6" lg="8" md="10" sm="8">
                            <v-row justify="center" align="center" no-gutters>
                                <v-col cols="7" align="end">
                                    <span class="text-h6 text-center">Members</span>
                                </v-col>
                                <v-col cols="5" align="end">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-show="group.admin" v-on="on" small fab color="primary" @click.stop="newMemberDialog = true" class="hidden-sm-and-down">
                                                <v-icon class="white--text">mdi-account-plus</v-icon>
                                            </v-btn>
                                            <v-btn v-show="group.admin" rounded fab top right color="primary" small @click.stop="newMemberDialog = true" class="hidden-md-and-up">
                                                <v-icon class="white--text">mdi-account-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add new member</span>
                                    </v-tooltip>
                                </v-col>
                                <NewMember v-if="newMemberDialog" :dialog="newMemberDialog" :groupMembers="groupMembers" :groupId="group.groupId" :groupName="group.groupName" :groupThumbnail="group.groupThumbnail" @cancelNewMember="newMemberDialog = false"/>
                            </v-row>
                            <v-list align="center" rounded>
                                <v-progress-circular v-if="loadingGroupMembers" indeterminate color="primary" :size="100"></v-progress-circular>
                                <!-- <div v-if="loadingGroupMembers">
                                    <div v-for="index in 5" :key="index">
                                        <v-skeleton-loader height="60" type="image" v-bind:class="[!isOdd(index) ? 'odd' : $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" class="rounded-xl"></v-skeleton-loader>
                                    </div>
                                </div> -->
                                <v-list-item v-else v-for="(member, index) in groupMembers" :key="member.userId" v-bind:class="[isOdd(index) ? 'odd' : $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" class="rounded-xl">
                                    <v-col cols="2" class="flex-grow-0 flex-shrink-1 pl-0">
                                        <router-link v-if="member.userId == currentUser" :to="{path: 'profile'}" class="text-decoration-none">
                                            <v-avatar size="35">
                                                <v-img v-if="member.userProfilePhoto" :src="member.userProfilePhoto" referrerpolicy="no-referrer" @error="member.userProfilePhoto=null"></v-img>
                                                <v-img v-else src="../../assets/no-user.png"></v-img>
                                            </v-avatar>
                                        </router-link>

                                        <router-link v-else :to="{name: 'view-user-profile', query: { id: member.userId }}" class="text-decoration-none">
                                            <v-avatar size="35">
                                                <v-img v-if="member.userProfilePhoto" :src="member.userProfilePhoto" referrerpolicy="no-referrer" @error="member.userProfilePhoto=null"></v-img>
                                                <v-img v-else src="../../assets/no-user.png"></v-img>
                                            </v-avatar>
                                        </router-link>
                                    </v-col>

                                    <v-col xs="4" sm="6" class="flex-grow-1 flex-shrink-1 px-md-0">
                                        <div class="font-weight-medium text-caption text-md-subtitle-1 text-truncate text-left invert--text text--lighten-3">
                                            <router-link v-if="member.userId == currentUser" :to="{path: 'profile'}" class="text-decoration-none" v-bind:class="[isOdd(index) ? 'black--text' : $vuetify.theme.dark ? 'white--text' : 'black--text']">
                                                <span >{{ member.username }}</span>
                                                <v-icon v-if="member.role === 'admin'" size="15" color="primary" class="ml-2">mdi-account-key</v-icon>
                                            </router-link>
                                            <router-link v-else :to="{name: 'view-user-profile', query: { id: member.userId }}" class="text-decoration-none" v-bind:class="[isOdd(index) ? 'black--text' : $vuetify.theme.dark ? 'white--text' : 'black--text']">
                                                <span>{{ member.username }}</span>
                                                <v-icon v-if="member.role === 'admin'" size="15" color="primary" class="ml-2">mdi-account-key</v-icon>
                                            </router-link>
                                        </div>
                                    </v-col>

                                    <v-col xs="6" sm="4" class="pa-0 flex-grow-0 flex-shrink-1">

                                        <!-- if group admin show promote/demote buttons and remove member button -->
                                        <div v-if="group.admin && member.accepted && (member.userId != currentUser)" class="justify-end d-flex">
                                            <!-- PROMOTE/DEMOTE MEMBER BUTTONS -->
                                            <v-btn v-if="member.role != 'admin'" text x-small color="primary" @click.stop="openPromoteMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-md-and-up mr-1">promote</v-btn>
                                            <v-btn v-if="member.role != 'admin'" text color="primary" @click.stop="openPromoteMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-sm-and-down mr-1">promote</v-btn>

                                            <v-btn v-if="member.role === 'admin'" text x-small color="primary" @click.stop="openPromoteMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-md-and-up mr-1">demote</v-btn>
                                            <v-btn v-if="member.role === 'admin'" text color="primary" @click.stop="openPromoteMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-sm-and-down mr-1">demote</v-btn>
                                            <!-- END PROMOTE/DEMOTE MEMBER BUTTONS -->

                                            <!-- REMOVE MEMBER BUTTON -->
                                            <v-btn text x-small color="primary" @click.stop="openRemoveMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-md-and-up">remove</v-btn>
                                            <v-btn text color="primary" @click.stop="openRemoveMemberDialog(member)" class="font-weight-light text-caption px-0 hidden-sm-and-down">remove</v-btn>
                                            <!-- END REMOVE MEMBER BUTTON -->
                                        </div>
                                        <!-- END if group admin show promote/demote buttons and remove member button -->

                                        <!-- if group admin, and user had been invited to group but hasn't accepted show cancel request button -->
                                        <div v-if="group.admin && !member.accepted && !('requested' in member)" class="justify-end d-flex">
                                            <v-btn  text x-small color="primary" @click.stop="removeUserDataFromGroup(member, 'cancel')" class="font-weight-light text-caption px-0 hidden-md-and-up">Cancel Request</v-btn>
                                            <v-btn text color="primary" @click.stop="removeUserDataFromGroup(member, 'cancel')" class="font-weight-light text-caption px-0 hidden-sm-and-down">Cancel Request</v-btn>
                                        </div>
                                        <!-- END if group admin, and user had been invited to group but hasn't accepted show cancel request button -->

                                        <!-- if group admin, and user had requested to join group show accept or reject request buttons -->
                                        <div v-if="group.admin && !member.accepted && ('requested' in member)" class="justify-end d-flex">
                                            <v-btn text x-small color="primary" @click.stop="acceptJoinRequest(member)" class="font-weight-light text-caption px-0 hidden-md-and-up mr-1">Accept</v-btn>
                                            <v-btn text color="primary" @click.stop="acceptJoinRequest(member)" class="font-weight-light text-caption px-0 hidden-sm-and-down mr-1">Accept</v-btn>
                                            <v-btn text x-small color="primary" @click.stop="removeUserDataFromGroup(member, 'reject')" class="font-weight-light text-caption px-0 hidden-md-and-up">Reject</v-btn>
                                            <v-btn text color="primary" @click.stop="removeUserDataFromGroup(member, 'reject')" class="font-weight-light text-caption px-0 hidden-sm-and-down">Reject</v-btn>
                                        </div>
                                        <!-- END if group admin, and user had requested to join group show accept or rejecte request buttons -->
                                    </v-col>

                                </v-list-item>
                                <PromoteMember v-if="promoteMemberDialog" :dialog="promoteMemberDialog" :member="member" :groupId="group.groupId" :groupName="group.groupName" @cancelPromoteMember="promoteMemberDialog = false"/>
                                <RemoveMember v-if="removeMemberDialog" :dialog="removeMemberDialog" :member="member" :groupId="group.groupId"/>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="8" sm="12" class="order-first order-lg-last">
                    <EditGroupSettings :group="group" @uploadThumbnailDialog="uploadGroupThumbnail = true" @addGroupMember="newMemberDialog = true" @leaveGroup="openLeaveGroupDialog"/>
                    <UploadGroupThumbnailDialog v-if="uploadGroupThumbnail" :dialog="uploadGroupThumbnail" :groupAdmin="group.admin" :group="group" :existingThumbnail="group.groupThumbnail != null" @closeDialog="uploadGroupThumbnail = false"/>
                    <LeaveGroup v-if="leaveGroupDialog" :groupId="group.groupId" :groupName="group.groupName" :hasMultipleAdminUsers="hasMultipleAdminUsers" @userLeavingGroup="userLeavingGroup" @adminDeleteGroup="adminDeleteGroup"/>
                </v-col>
            </v-row>

            <v-row v-else justify="center" no-gutters class="mt-5">
                <v-col cols="12" align="center">
                    <!-- This is to cancel a join request -->
                    <v-btn color="red darken-2" text @click.stop="removeUserDataFromGroup(null, 'request')">Cancel Request</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
import NewMember from "./NewMember.vue";
import PromoteMember from "./PromoteMember.vue";
import RemoveMember from "./RemoveMember.vue";
import LeaveGroup from "./LeaveGroup.vue";
import UploadGroupThumbnailDialog from "./UploadGroupThumbnailDialog.vue";
import EditGroupSettings from "./EditSettings.vue";


export default {
    name: "group-settings",
    components: {
        NewMember,
        PromoteMember,
        LeaveGroup,
        RemoveMember,
        UploadGroupThumbnailDialog,
        EditGroupSettings,
    },
    props: ["dialog", "friends", "group",],
    data() {
        return {
            loadingGroupMembers: false,
            groupMembers: [],
            groupMembersListener: null,
            currentUser: firebase.auth.currentUser.uid,
            promoteMemberDialog: false,
            newMemberDialog: false,
            audioDataIds: [],
            allContributorsIds: [],
            member: {},
            uploadGroupThumbnail: false,
            privateContributions: false,
            hasMultipleAdminUsers: false,
        };
    },
    methods: {
        isOdd: function (index) {
            return index % 2 == 1;
        },
        getUserGroups: function () {
            this.$emit("getUserGroups");
        },
        openPromoteMemberDialog: function (member) {
            this.member = member;
            this.promoteMemberDialog = true;
        },
        openRemoveMemberDialog: function (member) {
            this.member = member;
            this.removeMemberDialog = true;
        },
        openLeaveGroupDialog: function () {
            this.leaveGroupDialog = true;
            this.$store.commit('saveModelLeavingAndLoading', {loading: false, model: (this.group.admin ? 'admin' : 'user'), leaving: null});
            this.hasMultipleAdminUsers = (this.groupMembers.find((member) => {if(member.userId != this.currentUser && member.accepted && member.role === "admin")return true})) ? true : false;
        },
        removeUserDataFromGroup: function (member, action) {
            // This is for canceling a join request
            if (member === null){
                member = this.groupMembers.find(member => {
                    if(member.userId === this.currentUser){
                        return member;
                    }
                });
            }
            var userId = member.userId;
            var group = {
                requestToJoin: member.requested,
                id : member.notifId,
                member: {
                accepted: member.accepted,
                role: member.role,
                },
            };
            if (action === 'request') {
                this.$store.commit('saveLeavingGroupId', this.group.groupId);
                this.$store.commit('saveIsLeavingGroup', true);
            }
            this.$store.dispatch('removeUserDataFromGroup', {groupId: this.group.groupId, userId: userId, group: group, action: action});
        },
        userLeavingGroup: function () {
            this.$store.commit('saveLeavingGroupId', this.group.groupId);
            this.$store.commit('saveIsLeavingGroup', true);
            this.member = this.groupMembers.find(member => {if(member.userId === this.currentUser)return member;});
            this.$store.dispatch('deleteMemberPostAndContributionData', {groupId: this.group.groupId, member: this.member, action: 'leaving', component: 'leaveGroup',});
        },
        removeAndDeleteUsersFromGroup: function () {
            // This will remove and delete all the users data from the group data and remove all of the users userData associated with the group
            this.groupMembers.forEach((member) => {
                this.removeUserDataFromGroup(member);
            });
            // firebase.db
            //   .collection("groups")
            //   .doc(this.groupId)
            //   .collection("members")
            //   .get()
            //   .then((snapshot) => {
            //     snapshot.forEach((info) => {
            //       var userId = info.data().userId;
            //       var member = {
            //         accepted: info.data().accepted,
            //         role: info.data().role,
            //       };
            //       this.loadingDialog = true;
            //       this.$http.delete(`groups/${this.groupId}/${userId}`, {
            //         data: {
            //           member: member
            //         }
            //       })
            //       .then((response) => {
            //         if (response.status == 200) {
            //           // console.log("Successfully deleted member.");
            //         }
            //       })
            //       .catch((err) => {
            //         console.log(err);
            //       })
            //       .finally(() => {
            //         this.$http.delete(`groups/${this.groupId}`)
            //         .then((response) => {
            //           if (response.status == 200) {
            //             this.loadingDialog = false;
            //             this.userDialog = false;
            //             this.adminDialog = false;
            //             this.getUserGroups();
            //           } else {
            //             this.$store.commit('alertUser', { show: true, text: `Something went wrong.`, type: 'snackbar' });
            //           }
            //         });
            //       });
            //     });
            //   });
        },
        getAudioDataIds: function (postData) {
            return new Promise((resolve, reject) => {
                this.audioDataIds = [];
                this.allContributorsIds = [];
                firebase.db.collection("groupPosts").doc(postData.postId).collection("contributions").get().then((snapshot) => {
                    if (snapshot.docs.length == 0) {
                        resolve();
                    } else {
                        snapshot.forEach((doc) => {
                                this.allContributorsIds.push(doc.id);
                                doc.data().audioDataId.forEach((id) => {
                                this.audioDataIds.push(id);
                            });
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                    reject();
                });
                resolve();
            });
        },
        deleteGroupThumbnail: async function(){
            if(this.group.groupThumbnail != null){
                await this.$http.delete(`thumbnails/groups/${this.group.groupId}`).catch((err) => console.log(err));
            }
            return;
        },
        adminDeleteGroup: function () {
            console.log("adminDeleteGroup");
            this.leaveGroupLoading = true;
            this.leavingGroup = false;
            this.cancelLeaveGroup();
            // this.removeAndDeleteUsersFromGroup();
            // this.deleteDialog = true;
            // this.userDialog = false;
            // this.adminDialog = false;
            // // delete the group on server...
            // // including all posts...
            // firebase.db
            //   .collection("groupPosts")
            //   .where("groupId", "==", this.groupId)
            //   .get()
            //   .then((snapshot) => {
            //     if (snapshot.docs.length == 0) {
            //       // console.log('not posts');
            //       return;
            //     } else {
            //       snapshot.forEach( async (doc) => {
            //         var postData = doc.data();
            //         // delete all contributions for each post in the group
            //         await this.getAudioDataIds(postData);
            //         this.deleteGroupPost(postData);
            //       });
            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error.message);
            //     this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            //   })
            //   .finally(() => {
            //     this.deleteDialog = false;
            //     this.deleteGroupThumbnail();
            //     // Calls every member and deletes them from group and/or their notification.
            //     this.removeAndDeleteUsersFromGroup();
            //   });
        },
        acceptJoinRequest: function (member) {
            var group = {
                id: member.notifId,
                groupId: this.group.groupId,
                groupName: this.group.groupName,
                groupThumbnail: this.group.groupThumbnail,
                requestToJoin: true,
                userId: member.userId,
            };
            this.$http.put(`groups/${group.groupId}/${group.userId}`, {
                group: group,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("accepted group request");
                }
            }).catch((err) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err}`, type: 'snackbar' });
            });
        },
        getGroupMembers: function () {
            this.loadingGroupMembers = true;
            this.groupMembersListener = firebase.db.collection("groups").doc(this.group.groupId).collection("members").orderBy("role").onSnapshot((snapshot) => {
                this.groupMembers = [];
                snapshot.forEach((doc) => {
                    this.groupMembers.push(doc.data());
                    this.loadingGroupMembers = false;
                });
            });
        },
    },
    computed: {
        removeMemberDialog: {
            get(){
                return this.$store.state.groupsPageStore.deleteOrRemoveMember.dialog;
            },
            set(value){
                this.$store.commit('saveRemoveMemberDialog', value);
            },
        },
        leaveGroupDialog: {
            get(){
                return this.$store.state.groupsPageStore.deleteOrLeaveGroup.dialog;
            },
            set(value){
                this.$store.commit('saveLeaveGroupDialog', value);
            },
        },
    },
    created: function () {
        if (this.group) {
            if (this.$route.query.id === this.group.groupId){
                if (!this.$route.hash){
                    this.$router.replace({path: "groups",query: { id: this.group.groupId },hash: 'settings'});
                }
            }
        }
        this.getGroupMembers();
    },
    beforeDestroy: function () {
        if (this.$route.hash){
            this.$router.replace({path: "groups",query: { id: this.group.groupId },});
        }
        if (this.groupMembersListener != null) {
            this.groupMembersListener();
        }
    },
};
</script>

<style>
.odd {
    background: #dddddd;
}
</style>
