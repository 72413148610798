<template>
    <v-card class="rounded-lg" :class="isPostAudioData() ? 'originalAudio black--text' : $vuetify.theme.dark ? 'allOtherAudioDarkTheme' : ''" :style="selectedAudioId === audioObject.audioDataId ? isPostAudioData() && $vuetify.theme.dark ? 'border: 3px solid var(--v-accent-base);' : 'border: 2px solid var(--v-accent-base);' : ''">

        <v-row justify="space-around" align="center" no-gutters class="mx-0 pt-2">
            <v-col v-if="anonymousMode" cols="2" align="center" class="my-0">
                <v-avatar class="hidden-sm-and-down" size="55">
                    <v-img v-if="isPostAudioData()" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" referrerpolicy="no-referrer" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>

                <v-avatar class="hidden-md-and-up" size="50">
                    <v-img v-if="isPostAudioData()" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" referrerpolicy="no-referrer" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>
            </v-col>

            <v-col v-else cols="2" align="center" class="my-0">
                <v-avatar class="hidden-sm-and-down" size="55">
                    <v-img v-if="audioObject.audioData.userProfilePhoto != null" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" referrerpolicy="no-referrer" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>

                <v-avatar class="hidden-md-and-up" size="50">
                    <v-img v-if="audioObject.audioData.userProfilePhoto != null" lazy-src="../assets/no-user.png" :src="audioObject.audioData.userProfilePhoto" referrerpolicy="no-referrer" @error="audioObject.audioData.userProfilePhoto = null" :alt="audioObject.audioData.username"></v-img>
                    <v-img v-else src="../assets/no-user.png" :alt="audioObject.audioData.username"></v-img>
                </v-avatar>
            </v-col>

            <v-col cols="6" class="my-0">
                <v-row v-if="anonymousMode" dense class="mx-0 ml-1">
                    <span v-if="isPostAudioData()" class="text-truncate text-subtitle-1 cursor--pointer" @click="$emit('usernameClicked', 'name')">@{{audioObject.audioData.username}}</span>
                    <span v-else class="text-truncate text-subtitle-2 cursor--pointer" @click="$emit('usernameClicked', 'name')">@{{audioObject.audioDataId}}</span>
                </v-row>
                <v-row v-else dense class="mx-0 ml-1">
                    <span class="text-truncate text-subtitle-1 cursor--pointer" @click="$emit('usernameClicked', 'name')">@{{audioObject.audioData.username}}</span>
                </v-row>
                <v-row dense class="mx-0 ml-1">
                    <span class="font-weight-light font-italic date" v-bind:class="[ isPostAudioData() ? 'black--text' : $vuetify.theme.dark ? 'inverse--text' : 'inverse--text']">{{audioObject.audioData.date.toDate().toLocaleDateString()}}</span>
                </v-row>
                <v-row dense class="mx-0 ml-1">
                    <div class="font-weight-light text-truncate language">{{audioObject.audioData.languageName}}</div>
                </v-row>
            </v-col>

            <v-col cols="3" align="end" align-self="start" class="my-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <!-- <v-btn class="hidden-sm-and-down " color="accent" icon small outlined v-on="on" @click.native.stop="setAudioTrack(), incrementAudioPlays()"> -->
                        <v-btn v-on="on" color="accent" icon small outlined :loading="videoLoading && (selectedAudioId === audioObject.audioDataId)" :disabled="videoLoading && (selectedAudioId != audioObject.audioDataId)" @click.native.stop="setAudioTrack(), incrementAudioPlays()" class="hidden-md-and-down">
                            <v-icon v-if="selectedAudioId === audioObject.audioDataId && audioElementHasBegunPlaying" medium>mdi-restart</v-icon>
                            <v-icon v-else medium>mdi-play</v-icon>
                        </v-btn>
                        <!-- <v-btn class="hidden-md-and-up" color="accent" icon small outlined v-on="on" @click.native.stop="setAudioTrack(), incrementAudioPlays()"> -->
                        <v-btn v-on="on" color="accent" icon small outlined :loading="videoLoading && (selectedAudioId === audioObject.audioDataId)" :disabled="videoLoading && (selectedAudioId != audioObject.audioDataId)" @click.native.stop="setAudioTrack(), incrementAudioPlays()" class="hidden-lg-and-up">
                            <v-icon v-if="selectedAudioId === audioObject.audioDataId && audioElementHasBegunPlaying" medium>mdi-restart</v-icon>
                            <v-icon v-else>mdi-play</v-icon>
                        </v-btn>
                    </template>
                    <span>Play Audio</span>
                </v-tooltip>
                <v-menu v-model="menu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" close-on-content-click>
                    <template v-slot:activator="{ on }">
                        <v-btn :ripple="false" v-on="on" @click.stop="menu = !menu" color="accent" small icon>
                            <v-icon medium>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded>
                        <v-list-item @click.stop="reportDialog = true, menu = !menu"><v-icon class="pr-2">mdi-flag</v-icon>Report</v-list-item>
                    </v-list>
                </v-menu>
                <Report v-if="reportDialog" :dialog="reportDialog" page="audioElement" :post="audioObject" @close-report="reportDialog = false"/>
            </v-col>
        </v-row>

        <v-row justify="space-around" align="center" no-gutters class="mx-0 py-1">
            <v-col cols="1" align="center" class="my-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="hidden-sm-and-down" icon @click="show = !show" v-on="on" small>
                            <v-icon class="grey--text text--darken-1" large>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn>
                        <v-btn class="hidden-md-and-up" icon @click="show = !show" small>
                            <v-icon class="grey--text text--darken-1" large>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn>
                    </template>
                    <span justify="center">Show Audio Segments</span>
                </v-tooltip>
            </v-col>
            <v-col cols="10" align="start" class="my-0 text-truncate">
                <span class="text-h6 font-weight-bold ml-2">{{audioObject.audioData.title}}</span>
            </v-col>
            <v-expand-transition>
                <v-container v-show="show" fluid>
                    <v-divider :light="isPostAudioData()" :dark="!isPostAudioData() && $vuetify.theme.dark"/>
                    <SegmentAudio
                        v-for="(obj, index) in audioObject.audioData.audio"
                        :key="index"
                        :index="index"
                        :audioSeg="obj"
                        :caption="audioObject.audioData.subtitles[index]"
                        :isPostAudioData="isPostAudioData()"
                        :videoLoading="videoLoading"
                        @setAudioSegment="emitSegment"
                    />
                </v-container>
            </v-expand-transition>
        </v-row>

    </v-card>
</template>

<script>
import fb from "firebase/compat/app";
import firebase from "../../firebaseConfig.js";
import SegmentAudio from "./SegmentAudio.vue";
import Report from "./Report.vue";

export default {
    name: "ElementAudio",
    props: ["audioObject", "postAudioDataId", "selectedAudioId", "postType", "postData", "videoLoading", "audioElementHasBegunPlaying", "anonymousMode"],
    components: {
        SegmentAudio,
        Report,
    },
    data: function() {
        return {
            menu:false,
            reportDialog: false,
            show: false,
        };
    },
  methods: {
        setAudioTrack: function() {
            this.$emit("audioChanged", this.audioObject.audioData, this.audioObject.audioDataId);
        },
        isPostAudioData: function() {
            if (this.audioObject.audioDataId == this.postAudioDataId) {
                return true;
            }
            return false;
        },
        emitSegment: function(audioSeg) {
            audioSeg.username = this.audioObject.audioData.username;
            this.$emit("changeElementAudio", { audioData: this.audioObject.audioData, audioSeg: audioSeg, audioId: this.audioObject.audioDataId});
        },
        incrementAudioPlays: function() {
            if (process.env.NODE_ENV === 'production'){
                const segRef = firebase.db.collection("audioSegments").doc(this.audioObject.audioDataId)
                segRef.update({audioViewed: fb.firestore.FieldValue.increment(1),}).then(() => {
                    // console.log("audioViewed has been incremented 1")
                }).catch((error) => {
                    console.error("error incrementing the postViewed:", error.message);
                })
            }
        },
    },
    // created: function() {
    //     console.log("anonymousMode: ", this.anonymousMode);
    // }
};
</script>

<style>
.originalAudio {
    background-color: #e3e3e3 !important;
    /* background-color: #bdbdbd !important; */
    /* background-color: #424242 !important; */
}
.allOtherAudioDarkTheme {
    background-color: #424242 !important;
}
.selectedAudioId {
    border: 2px solid var(--v-accent);
}
.date {
    font-size: .60em;
}
.language {
    font-size: .70em;
}
</style>
