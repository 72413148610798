<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelNewGroup">
        <v-card v-if="!loading" class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center align-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Create a New Group</span>
            </v-card-title>
            <v-form @submit.prevent="createNewGroup" v-model="formIsValid">
                <v-row justify="center" class="mx-0">
                    <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                        <v-text-field v-model="newGroupName" label="Group Name" :rules="groupNameRules" hide-details="auto" color="primary" required outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                        <v-file-input v-model="newGroupImage" :rules="thumbnailRules" :show-size="true" accept="image/*" outlined hide-details="auto" flat prepend-icon append-icon="mdi-camera" label="Select and Upload a Thumbnail (optional)"></v-file-input>
                    </v-col>

                    <v-col cols="12" xl="10" lg="10" md="10" sm="12" justify="center" align="center">
                        <v-row justify="space-between" align="center" no-gutters class="mx-0 privateContributionBorder">
                            <span class="ml-2 font-weight-light">Private Contributions: 
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on">mdi-information-outline</v-icon>
                                    </template>
                                    <span>When turned on this option allows <b>only</b> the<br/> admin user(s) of the group to see contributions</span>
                                </v-tooltip>
                            </span>
                            <v-switch v-model="privateContributionsSwitch" inset color="primary"></v-switch>
                        </v-row>
                    </v-col>

                    <v-col cols="12" xl="10" lg="10" md="10" sm="12" class="pb-0">
                        <v-autocomplete v-model="languageSelected" label="Select a default contribution language" :items="languageCodes" item-text="text" item-value="value" return-object outlined hint="*This will be the langauge preselected when contributing to a group post" persistent-hint></v-autocomplete>
                    </v-col>

                    <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                        <v-text-field v-model="newSearch" type="text" label="Add Members by Username" color="primary" append-outer-icon="mdi-magnify" outlined hide-details="auto" clearable @click:clear="clearSearch" @click:append-outer="searchForUsers" @keydown.enter="searchForUsers" @keydown.delete="checkForEmptySearch"></v-text-field>
                        <v-list v-if="searchResults.length > 0" rounded two-line>
                            <v-divider class="justify-center"/>
                            <template v-for="(result, index) in searchResults">
                                <v-list-item :key="result.userId" selectable dense class="align-center">
                                    <v-list-item-avatar style="cursor: pointer;" @click.stop="openSearchedUsersPage(result.userId)">
                                        <v-img v-if="result.picture" :src="result.picture" referrerpolicy="no-referrer" @error="result.picture=null"></v-img>
                                        <v-img v-else src="../../assets/no-user.png" :alt="result.username"></v-img>
                                    </v-list-item-avatar>

                                    <v-row justify="space-between" align="center" no-gutters>
                                        <v-col cols="10">
                                            <span @click.stop="openSearchedUsersPage(result.userId)" style="cursor: pointer;" class="font-weight-bold text-subtitle-1">{{result.username}}</span><br/>
                                            <span v-if="isFriend(result.userId) != -1" @click.stop="openSearchedUsersPage(result.userId)" style="cursor: pointer;" class="font-weight-light text-caption">friends</span>
                                        </v-col>
                                        <v-checkbox v-model="result.checkbox" @change="addNewGroupMember(result)"></v-checkbox>
                                    </v-row>

                                </v-list-item>
                                <v-divider class="justify-center" :key="index"/>
                            </template>
                        </v-list>
                        <div v-if="emptySearchResults" class="text-caption text-center mt-2">We Can't Find Any Users Matching Your Search</div>
                    </v-col>
                    <v-col v-if="newGroupMembers.length > 0" cols="12" xl="10" lg="10" md="10" sm="12" class="my-0">
                        <v-chip v-for="user in newGroupMembers" :key="user.indexOf" close small color="primary" @click:close="removeChip(user)" class="ma-2">{{ user.username }}</v-chip>
                    </v-col>
                </v-row>
            </v-form>
            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelNewGroup">Cancel</v-btn>
                    <v-btn color="primary" :disabled="!formIsValid" text @click.stop="createNewGroup">Create</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="font-weight-bold text-h5 text-center">Creating <b>{{newGroupName}}</b></span>
            </v-card-title>
            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-bold text-subtitle-2 text-center">Please wait...</span><br/>
                    <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";

export default {
    name: "new-group",
    props: ["dialog"],
    data() {
        return {
            formIsValid: false,
            privateContributionsSwitch: false,
            languageSelected: {
                text: "English (United States)",
                value: "en-US",
            },
            languageCodes: [{
                    text: "None (Disable Auto Captions)",
                    value: "null",
                },
            ],
            groupNameRules: [
                (v) => !!v || "Group Name is required",
                (v) => (v && v.length <= 30) || "Group Name must be 30 characters or less",
                v => v && !!v.trim() || 'Group Name Cannot Be Blank',
            ],
            newGroupName: "",
            loading: false,
            newGroupImage: null,
            thumbnailRules: [
                (value) => !value || value.size < 6000000 || "Thumbnail Must be less than 6 MB",
            ],
            newGroupMembers: [],
            newSearch: "",
            oldSearch: "",
            searchResults: [],
            emptySearchResults: false,
        };
    },
    methods: {
        clearSearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            }
            this.searchResults = [];
        },
        checkForEmptySearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            } else if (this.newSearch != null && this.newSearch.length <= 1) {
                this.searchResults = [];
            }
        },
        searchForUsers: function () {
            if (this.newSearch === null || this.newSearch === "" /*|| this.newSearch === " "*/) {
                if (this.emptySearchResults) {
                    this.emptySearchResults = false;
                }
                return;
            } else if(this.newSearch.length > 0 && this.newSearch != this.oldSearch || this.searchResults.length === 0){
                this.searchUsersByUsername();
            }
            this.oldSearch = this.newSearch;
        },
        searchUsersByUsername: function () {
            // 1 - create the strings to query off of
            const increasedLastLetter = String.fromCharCode( this.newSearch.charCodeAt(this.newSearch.length-1) + 1 ); // increase the last letter of the string by one 
            const endTerm = this.newSearch.slice(0,-1) + increasedLastLetter; // remove the last letter of the string then add the increased last letter
            // 2 - create the firebase reference || TODO: try with .where(IN) - if space in the string captialize each starting lettter. lowercase all of their string, uppercase all | also some with the reerse string. take all and concat results if not duplicates
            const usernameRef = firebase.db.collection("userData").where("username", ">=", this.newSearch).where("username", "<", endTerm);
            usernameRef.get().then((snapshot) => {
                this.searchResults = [];
                if (snapshot.empty){
                    this.runModifiedUsernameSearch();
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    if (doc.id != firebase.auth.currentUser.uid && this.isInNewGroupMembers(doc.id) != -1) {
                        if (this.isFriend(doc.id) != -1){
                            this.searchResults.unshift({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': true,
                            });
                        } else {
                            this.searchResults.push({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': true,
                            });
                        }
                    } else if (doc.id != firebase.auth.currentUser.uid && this.isInNewGroupMembers(doc.id) === -1) {
                        if (this.isFriend(doc.id) != -1){
                            this.searchResults.unshift({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': false,
                            });
                        } else {
                            this.searchResults.push({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': false,
                            });
                        }
                    }
                });
                this.runModifiedUsernameSearch();
            }).catch((err) => {
                console.log("Error finding user by username", err.message);
            });
        },
        runModifiedUsernameSearch(){
            let changeFirstLetterCasing;
            if (this.newSearch.slice(0,1) === this.newSearch.charAt(0).toUpperCase()) {
                changeFirstLetterCasing = this.newSearch.charAt(0).toLowerCase() + this.newSearch.slice(1);
            } else {
                changeFirstLetterCasing = this.newSearch.charAt(0).toUpperCase() + this.newSearch.slice(1);
            }
            const modifiedEndTerm = changeFirstLetterCasing.slice(0,-1) + String.fromCharCode( changeFirstLetterCasing.charCodeAt(changeFirstLetterCasing.length-1) + 1 );
            const modifiedUsernameRef = firebase.db.collection("userData").where("username", ">=", changeFirstLetterCasing).where("username", "<", modifiedEndTerm);

            modifiedUsernameRef.get().then((snapshot) => {
                if (snapshot.empty) {
                    if (this.searchResults.length === 0) {
                        this.emptySearchResults = true;
                    }
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    if (doc.id != firebase.auth.currentUser.uid && this.isInNewGroupMembers(doc.id) != -1 && (this.searchResults.findIndex((user) => user.userId === doc.id) === -1 )) {
                        if (this.isFriend(doc.id) != -1){
                            this.searchResults.unshift({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': true,
                            });
                        } else {
                            this.searchResults.push({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': true,
                            });
                        }
                    } else if (doc.id != firebase.auth.currentUser.uid && this.isInNewGroupMembers(doc.id) === -1 && (this.searchResults.findIndex((user) => user.userId === doc.id) === -1 )) {
                        if (this.isFriend(doc.id) != -1){
                            this.searchResults.unshift({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': false,
                            });
                        } else {
                            this.searchResults.push({
                                'fullname': doc.data().fullname,
                                'username': doc.data().username,
                                'picture': doc.data().profilePicture,
                                'email': doc.data().email,
                                'userId': doc.data().userId,
                                'checkbox': false,
                            });
                        }
                    }
                });
            }).catch((err) => {
                console.log("error retrieving the new ref", err.message);
            })
        },
        isFriend (userId) {
            return this.friends.findIndex((friend) => friend.id === userId);
        },
        isInNewGroupMembers (userId) {
            return this.newGroupMembers.findIndex((member) => member.userId === userId);
        },
        addNewGroupMember: function (result) {
            if (result.checkbox) {
                this.newGroupMembers.push(result);
            } else {
                this.newGroupMembers.splice(this.newGroupMembers.indexOf(result), 1);
            }
        },
        openSearchedUsersPage: function (id) {
            window.open(`https://tawq.in/view-user-profile?id=${id}`, "_blank");
        },
        createNewGroup: async function () {
            this.loading = true;
            // 1 - generate the group id
            const newGroupId = firebase.db.collection("groups").doc().id;
            let thumbnailUrl = null;
            // 2 - upload a thumbnail if they set one. save the url
            if (this.newGroupImage != null) {
                var data = new FormData();
                data.append("file", this.newGroupImage);
                data.append("groupName", this.newGroupName);
                thumbnailUrl = await this.$http.post(`group/thumbnail/${newGroupId}`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((response) => {
                    return response.data.url;
                }).catch((err) => {
                    console.log("Error uploading the thumbnail. ", err.message);
                });
            }
            // 3 - call the server to create the group, passing in the thumbnail url and the groupId
            // data.append("adminName", firebase.auth.currentUser.displayName);
            // data.append("groupName", this.newGroupName);
            // data.append("userId", currentUserId);
            // data.append("userProfilePhoto", firebase.auth.currentUser.photoURL)
            // data.append("groupMembers", groupMembers);
            //  this.$http.post(`groups`, data)
            this.$http.post(`groups`, {
                adminName: firebase.auth.currentUser.displayName,
                groupId: newGroupId,
                groupName: this.newGroupName,
                privateContributions: this.privateContributionsSwitch,
                languageSelected: this.languageSelected,
                userId: firebase.auth.currentUser.uid,
                userProfilePhoto: firebase.auth.currentUser.photoURL,
                groupMembers: this.newGroupMembers,
                groupThumbnail: thumbnailUrl,
            }).then((response) => {
                if (response.status == 201) {
                    this.loading = false;
                    this.newGroupMembers = [];
                    this.newGroupName = "";
                    this.$store.commit('setSelectedGroupId', newGroupId);
                    this.cancelNewGroup();
                }
            }).catch((err) => {
                this.loading = false;
                this.cancelNewGroup();
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        cancelNewGroup: function () {
            if (!this.loading) {
                this.$emit("cancelNewGroup");
            }
        },
        removeChip: function (user) {
            this.newGroupMembers.splice(this.newGroupMembers.indexOf(user), 1);
            var index = this.searchResults.findIndex((result) => result.userId === user.userId);
            if (index != -1) {
                this.searchResults[index].checkbox = false;
            }
        },
    },
    computed: {
        friends () {
            var allFriends = this.$store.state.userFriends;
            let friends = [];
            allFriends.forEach((friend) => {
                if (friend.accepted) {
                    friends.push(friend);
                }
            })
            return friends;
        },
        userData () {
            return this.$store.state.userData;
        },
    },
    mounted: function () {
        this.languageCodes.push(...this.$languageCodes);
    },
    created: function () {
        if (typeof this.userData.languageSelected != 'undefined') {
            this.languageSelected = this.userData.languageSelected;
        }
    },
};
</script>

<style>
.privateContributionBorder {
    border-radius: 5px;
    border: thin solid grey;
    /* border: thin solid rgba(160, 160, 160, 1); */
}
</style>