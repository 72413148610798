<template >
    <v-dialog v-model="dialog" @click:outside="$emit('close-dialog')" app persistent max-width="500" overlay-color="grey darken-1" overlay-opacity=".5">
        <!-- <v-row v-if="snackbar"> -->
            <v-snackbar v-model="snackbar" app centered top timeout="4000" style='z-index: 9;'>
                <div class="text-center">{{snackbarText}}</div>
            </v-snackbar>
        <!-- </v-row> -->
        <v-card style="overflow: hidden;" class="rounded-xl">
            <!-- <v-overlay v-if="saving" style="position: absolute;">
                <v-progress-circular indeterminate color="warning"></v-progress-circular>
            </v-overlay> -->
            <v-row justify="center" align="center" class="mx-0">
                <v-btn icon style="position: absolute; left: 5px;">
                    <v-icon class="grey--text text--lighten-1" @click="$emit('close-dialog')">mdi-close</v-icon>
                </v-btn>
                <v-card-title  style="word-break: normal;">Share Post</v-card-title>
            </v-row>
            <v-divider/>
            <div style="overflow-x: scroll;" class="d-flex flex-row justify-start justify-sm-space-around align-center hide-scroll-bar mx-0 my-2">
                <div class="d-flex flex-column align-center">
                    <v-btn @click="sharePost('email')" link depressed fab class="blue lighten-1 mx-2 mx-sm-0" ><v-icon class="white--text">mdi-email</v-icon></v-btn>
                    <div class="text-caption mt-1">Mail</div>
                </div>
                <div class="d-flex flex-column align-center">
                    <v-btn @click="sharePost('gmail')" link depressed fab color="#EA4335" class="mx-2 mx-sm-0" ><v-icon class="white--text">mdi-gmail</v-icon></v-btn>
                    <div class="text-caption mt-1">Gmail</div>
                </div>
                <div class="d-flex flex-column align-center">
                    <v-btn @click="sharePost('facebook')" link depressed fab color="#4267B2" class="mx-2 mx-sm-0" ><v-icon class="white--text">mdi-facebook</v-icon></v-btn>
                    <div class="text-caption mt-1">Facebook</div>
                </div>
                <div class="d-flex flex-column align-center hidden-md-and-up">
                    <v-btn @click="sharePost('whatsapp')" link depressed fab color="#25D366" class="mx-2 mx-sm-0"><v-icon class="white--text">mdi-whatsapp</v-icon></v-btn>
                    <div class="text-caption mt-1">Whatsapp</div>
                </div>
                <div class="d-flex flex-column align-center">
                    <v-btn @click="sharePost('twitter')" link depressed fab color="#1DA1F2" class="mx-2 mx-sm-0" ><v-icon class="white--text">mdi-twitter</v-icon></v-btn>
                    <div class="text-caption mt-1">Twitter</div>
                </div>
                <div class="d-flex flex-column align-center">
                    <v-btn @click="sharePost('linkedin')" link depressed fab color="#0e76a8" class="mx-2 mx-sm-0" ><v-icon class="white--text">mdi-linkedin</v-icon></v-btn>
                    <div class="text-caption mt-1">Linkedin</div>
                </div>
            </div>
            <v-divider/>
            <v-row justify="space-around" align="center"  class="mx-0 my-2">
                <v-text-field id="url-path"  class="text-caption" style="max-width: 75%;" readonly color="transparent" :value="getFullRoute" persistent-placeholder></v-text-field>
                <v-btn icon small depressed @click="copyFullRoute()">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
            </v-row>
            <!-- <v-card-text class="py-4" style="max-width: 100%; width: 100%;"> -->
                
                <!-- <div v-if="existingThumbnail && previewImage === null" class="mb-2">By uploading an image for your post, you will overwrite the current image you have saved for your post.</div>
                <v-row v-if="previewImage === null" justify="center" align="center" class="mx-0 fill-height">
                    <div class="d-flex flex-column align-center justify-center primary white--text red-border px-4 py-2 rounded-lg" style="cursor: pointer;" @click.stop="triggerFileUpload">
                        <v-icon>mdi-upload</v-icon>Upload Image
                        <v-file-input ref="fileInput" v-model="newThumbnail" style="display: none !important;" accept="image/*" type="file" prepend-icon="" :rules="thumbnailRules" @change="setPreviewImage" single-line></v-file-input>
                    </div>
                </v-row>


                <v-row v-else justify="center" align="start" class="mx-0">
                    <div class="d-flex flex-row justify-start align-start" style="max-height: 300px;">
                        <v-icon class="red--text text--darken-1 ml-n4 mt-n3" style="z-index: 1; position: absolute;" @click="clearImageInput()">mdi-close-circle</v-icon>
                        <v-img cover max-width="220" height="300" :src="previewImage"></v-img>
                    </div>
                </v-row>

                <div v-if="fileErrors" class="red--text mt-2 text-caption text-center font-italic">{{errorMessage}}</div> -->


            <!-- </v-card-text> -->
            <v-divider/>
                <!-- <v-card-actions>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text @click="$emit('close-dialog')">Close</v-btn>
                    <v-spacer/>
                    <v-btn :color="$vuetify.theme.dark ? 'amber' : 'primary'" text :disabled="fileErrors || newThumbnail === null" @click="uploadImageToExistingPost">Upload</v-btn>
                </v-card-actions> -->
        </v-card>

    </v-dialog>
</template>
<script>
export default {
    name: "ShareDialog",
    props: [ "dialog", "postData"],
    components: {
    },
    data(){
        return {
            snackbar: false,
            snackbarText: "",
        }
    },
    computed:{
        getFullRoute(){
            return `https://tawq.in${this.$route.fullPath}`;
        }
    },
    methods: {
        copyFullRoute(){
            var copyText = document.getElementById("url-path");
            copyText.select();
            copyText.setSelectionRange(0, this.getFullRoute.length); /* for mobile devices */
            /*$*/document.execCommand("copy");
            this.snackbar = true;
            this.snackbarText = "Copied to clipboard.";
        },
        sharePost: async function(type){
            const url = `https://tawq.in${this.$route.fullPath}`
            const message = `Take a look at this video made with Tawqin: ${'%0D%0A'} ${encodeURIComponent(url)}`;
            const subject = encodeURIComponent("Take a look at this!");
            switch(type){
                case 'email': 
                document.location.href = `mailto:?subject=${subject}&body=${message}`
                break;

                case 'facebook': {
                const facebookShare = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
                window.open(facebookShare, "_blank");
                // console.log("facebook!");
                break;
                }

                case 'whatsapp': {
                const whatsappShare = `whatsapp://send?text=${encodeURIComponent(url)}`;
                window.open(whatsappShare, "_blank");
                // console.log("facebook!");
                break;
                }

                case 'twitter': {
                const tweet = "Take a look at this video made with Tawqin!"
                const twitterShare = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}${'%0D%0A'}&text=${encodeURIComponent(tweet)}&hashtags=Tawqin,Tawq,CaptionYourVideo,VoiceOver,VoiceOverVideo,KeepTawqin`;
                window.open(twitterShare, "_blank");
                // console.log('twitter!');
                break;
                }

                case 'linkedin': {
                const linkedinShare = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent("Tawqin")}&summary=${encodeURIComponent(this.postData.title)}&source=${encodeURIComponent("Tawqin")}`;
                window.open(linkedinShare, "_blank");
                // console.log('Instagram!');
                break;
                }

                case 'gmail': {
                const subject = encodeURIComponent("Take a look at this video made with Tawqin!");
                const gmailShare = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${message}&ui=2&tf=1&pli=1`;
                window.open(gmailShare, "_blank");
                break;
                }

                // case 'share': {
                //   const shareData = {
                //     title: `Tawqin - ${this.postData.title}`,
                //     text: 'Take a look at this video made with Tawqin!',
                //     url: url,
                //   }
                //   try {
                //     await this.$navigator.share(shareData);
                //   } 
                //   catch(err) {
                //     console.error('Error', err);
                //   }
                //   break;
                // }
            } // end of switch
        },
    },
}
</script>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
    
</style>