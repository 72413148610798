<template>
    <v-container fluid class="mt-5">
        <Alert :key="NaN" v-if="deletePostDialog" :dialogVal="true" type="dialog" title="Are you sure you want to delete this post?" text="Your post will be permanently deleted. This action cannot be undone." @dialogClicked="deletePost"/>
        <v-row v-if="group.accepted===false" justify="center">
            <span class="text-subtitle-1 text-center">Waiting for Approval...</span>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon class="text-decoration-none ml-1 mb-1" small v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>To cancel the Join Request, press the group settings<br/> button at the right of the group name above. The settings<br/> page will open and display a <b>Cancel Request</b> button.</span>
            </v-tooltip>
        </v-row>

        <v-row v-else-if="postsLoading">
            <v-col v-for="index in 16" :key="index" cols="12" xl="3" lg="4" md="6" sm="6">
                <v-skeleton-loader height="250" type="image" class="rounded-xl elevation-4"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row v-else-if="empty && posts.length < 1 && !uploadingVideo" justify="center">
            <span class="text-subtitle-1 text-center">This group has no posts.</span>
        </v-row>

        <v-row v-else justify="start" class="pa-0">
            <v-col v-if="uploadingVideo && createPost.type === 'group' && createPost.group.groupId === group.groupId  && !newPostAlreadyExists()" cols="12" xl="3" lg="4" md="6" sm="6">
                <div style="position: relative;">
                    <div class="milky-film d-flex flex-column justify-center align-center rounded-xl elevation-4" :class="[ $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2']">
                        <v-row align="end" justify="center" class="mb-n8"><v-progress-circular indeterminate size="30" :class="[ $vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--lighten-4']" ></v-progress-circular></v-row>
                        <v-row align="center" justify="start" style="width: 100%; opacity: 0.85;"><v-card-title class="text-h5 font-weight-bold d-block text-truncate white--text" style="z-index: 1; max-width: 100%;">{{createPost.title}}</v-card-title></v-row>
                    </div>
                    <v-img height="250" class="rounded-xl" :src="createPost.thumbnail && typeof createPost.thumbnail === 'object' ? $window.URL.createObjectURL(createPost.thumbnail) : createPost.thumbnail && typeof createPost.thumbnail != 'object' ? createPost.thumbnail : require('../../assets/no-thumbnail.png')"></v-img>
                </div>
            </v-col>

            <v-col v-if="uploadingPhoto && photoPost.type === 'group' && !newPostAlreadyExists()" cols="12" xl="3" lg="4" md="6" sm="6">
                <div style="position: relative;">
                    <div class="milky-film d-flex flex-column justify-center align-center rounded-xl elevation-4" :class="[ $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2']">
                        <v-row align="end" justify="center" class="mb-n8"><v-progress-circular indeterminate size="30" :class="[ $vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--lighten-4']" ></v-progress-circular></v-row>
                        <v-row align="center" justify="start" style="width: 100%; opacity: 0.85;"><v-card-title class="text-h5 font-weight-bold d-block text-truncate white--text" style="z-index: 1; max-width: 100%;">{{photoPost.title}}</v-card-title></v-row>
                    </div>
                    <v-img height="250" class="rounded-xl" :src="photoPost.thumbnail"></v-img>
                </div>
            </v-col>

            <v-col v-for="(post, index) in posts" :key="post.postId" cols="12" xl="3" lg="4" md="6" sm="6">
                <Post :post="post" :index="index" :groupsPage="true" :groupId="group.groupId" :groupAdmin="group.admin" :groupLanguageSelected="group.languageSelected" @delete-selected-post="deleteDialog" @remove-post-from-list="removePostFromList"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Alert from "../../components/Alert.vue";
import Post from "../../components/Post.vue";
import firebase from "../../../firebaseConfig.js";

export default {
    name: "group",
    components: {
        Alert,
        Post,
    },
    props: ["group", "selectedGroupIndex"],
    data() {
        return {
            posts: [],
            postsLoading: true,
            limit: 20,
            lastVisible: null,
            groupPostsRef: null,
            empty: false,
            deletePostDialog: false,
            postToDelete: null,
            uploadingPhotoPostId: this.photoPost ? this.photoPost.postId : null,
            uploadingPostId: this.createPost ? this.createPost.postId : null,
        };
    },
    methods: {
        deleteDialog: function (post) {
            this.deletePostDialog = true;
            this.postToDelete = post;
        },
        deletePost: async function(val){
            if(!val) {
                this.deletePostDialog = false;
            } else {
                let post = this.postToDelete.post;
                let index = this.postToDelete.index;
                this.$store.dispatch('deletePostFromDb', { post: post, index: index, deleteFromStore: true } );
                this.posts.splice(index, 1);
                this.$store.commit('alertUser', { show: true, text: `Successfully deleted post.`, type: 'snackbar' });
                this.deletePostDialog = false;
            }
        },
        getGroupPosts: function() {
            this.groupPostsRef.get().then((snapshot) => {
                if(snapshot.empty){
                    this.empty = true;
                    this.postsLoading = false;
                    window.removeEventListener('scroll', this.atBottom);
                } else {
                    this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                        this.posts.push(post);
                    });
                    window.addEventListener("scroll", this.atBottom);
                    this.postsLoading = false;
                }
            }).catch((error) => {
                console.log("Something went wrong while fetching group posts: " + error);
            });
        },
        getGroupPostsMinusDeletedMember: function(userId) {
            this.posts = [];
            this.groupPostsRef.get().then((snapshot) => {
                if(snapshot.empty){
                    this.empty = true;
                    this.postsLoading = false;
                    window.removeEventListener('scroll', this.atBottom);
                } else {
                    this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                    snapshot.forEach((doc) => {
                        if(doc.data().userId != userId) {
                        // var date = doc.data().postDate.toDate().toLocaleDateString();
                        let day = doc.data().postDate.toDate().getDate();
                        let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                        let year = doc.data().postDate.toDate().getFullYear();
                        let formattedDate = `${day} ${month} ${year}`;
                        var post = doc.data();
                        post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                        this.posts.push(post);
                        }
                    });
                    if (this.posts.length <= 0) {
                        this.empty = true;
                    }
                    window.addEventListener("scroll", this.atBottom);
                    this.postsLoading = false;
                    this.$store.dispatch('setPostsForGroup', { posts: this.posts, groupId: this.group.groupId });
                    this.$store.dispatch('setLastVisibleForGroup', { lastVisible: this.lastVisible, groupId: this.group.groupId });
                }
            }).catch((error) => {
                console.log("Something went wrong while fetching group posts: " + error);
            });
        },
        newPostAlreadyExists: function() {
            let exists = this.posts.find( post => {
                return post.postId === this.uploadingPostId;
            })
            return exists;
        },
        removePostFromList: function(event) {
            this.posts.splice(event, 1);
            this.$store.commit('alertUser', { show: true, text: `Successfully moved post.`, type: 'snackbar' });
        },
        atBottom: function () {
            if(!this.postsLoading) {
                const bottomOffset = 200; // this will ensure that we're accurately measuring the vertical scroll position regardless of any fixed elements in the browser
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                let bottomOfWindow = windowHeight + currentScrollTop >= documentHeight - bottomOffset;
                if (bottomOfWindow) {
                this.groupPostsRef = firebase.db.collection("groupPosts").where("groupId", "==", this.group.groupId).orderBy("postDate", "desc").startAfter(this.lastVisible).limit(this.limit);
                window.removeEventListener("scroll", this.atBottom);
                this.getGroupPosts();
                }
            }
        },
    },
    computed: {
        uploadingVideo(){
            return this.$store.state.newPost.uploading_video;
        },
        uploadingPhoto(){
            return this.$store.state.newPhotoPost.uploading_photo;
        },
        createPost(){
            return this.$store.state.newPost.createPost;
        },
        photoPost(){
            return this.$store.state.newPhotoPost.photoPost;
        },
        retrievedPosts(){
            if (typeof this.$store.state.groupsPageStore.groupPosts != 'undefined'){
                return this.$store.state.groupsPageStore.groupPosts[this.group.groupId];
            }
            return 0;
        },
        lastVisibleForSelectedGroup(){
            return this.$store.state.groupsPageStore.lastVisibles[this.group.groupId];
        }
    },
    watch: {
        '$store.state.newPost.uploading_video' (newValue, oldValue){
            if (oldValue === true && newValue === false) { // this means that the upload is complete, re request the vids... TODO: set a store module for profile posts...
                // retrieve the post that is being uploaded, and add it to the front of the publicPosts list. this will keep us from reading all the posts again and from getting duplicate keys
                // if it is a youtube video, sometimes the post has been uploaded by the time the user is sent back to profile page. Therefore we need to check to see if the post is already in the list
                if ( !this.newPostAlreadyExists() ) {
                    firebase.db.collection("groupPosts").doc(this.uploadingPostId).get().then((doc) => {
                        if(!doc.exists){
                            return;
                        } else if (doc.data().postType != 'group' && doc.data().groupId != this.group.groupId){
                            return;
                        } else {
                            // var date = doc.data().postDate.toDate().toLocaleDateString();
                            let day = doc.data().postDate.toDate().getDate();
                            let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                            let year = doc.data().postDate.toDate().getFullYear();
                            let formattedDate = `${day} ${month} ${year}`;
                            var post = doc.data();
                            post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                            this.posts.unshift(post);
                            this.empty = false;
                        }
                    }).catch((error) => {
                        console.log("error retrieving the newly uploaded post", error);
                    });
                }
            }
        },
        '$store.state.newPost.audioCaptionsUploadComplete' (newValue, oldValue) {
            // this value will change, then have 8 seconds before createPost will be reset.
            if (oldValue === false && newValue === true) {
                this.uploadingPostId = this.createPost.postId;
            }
        },
        '$store.state.newPhotoPost.uploading_photo' (newValue, oldValue){
            if (oldValue === true && newValue === false) { // this means that the upload is complete, re request the vids... TODO: set a store module for profile posts...
                // retrieve the post that is being uploaded, and add it to the front of the publicPosts list. this will keep us from reading all the posts again and from getting duplicate keys
                // if it is a youtube video, sometimes the post has been uploaded by the time the user is sent back to profile page. Therefore we need to check to see if the post is already in the list
                if ( !this.newPostAlreadyExists() ) {
                    firebase.db.collection("groupPosts").doc(this.uploadingPhotoPostId).get().then((doc) => {
                        if(!doc.exists){
                            return;
                        } else if (doc.data().postType != 'group' && doc.data().groupId != this.group.groupId){
                            return;
                        } else {
                            // var date = doc.data().postDate.toDate().toLocaleDateString();
                            let day = doc.data().postDate.toDate().getDate();
                            let month = doc.data().postDate.toDate().toLocaleDateString('default', {month: 'short'});
                            let year = doc.data().postDate.toDate().getFullYear();
                            let formattedDate = `${day} ${month} ${year}`;
                            var post = doc.data();
                            post.postDate = formattedDate; // should put the date in the format: DD MMM YYYY;
                            this.posts.unshift(post);
                            this.empty = false;
                        }
                    }).catch((error) => {
                        console.log("error retrieving the newly uploaded post", error);
                    });
                }
            }
        },
        '$store.state.newPhotoPost.audioCaptionsUploadComplete' (newValue, oldValue) {
            // this value will change, then have 8 seconds before createPost will be reset.
            if (oldValue === false && newValue === true){
                this.uploadingPhotoPostId = this.photoPost.postId;
            }
        },
        'group' (newValue, oldValue){
            // save the current posts to the store
            if (oldValue.groupId != newValue.groupId) {
                if (this.posts.length > 0) {
                    // set posts and last visible for group once we selected a new group
                    this.$store.dispatch('setPostsForGroup', { posts: this.posts, groupId: oldValue.groupId });
                    this.$store.dispatch('setLastVisibleForGroup', { lastVisible: this.lastVisible, groupId: oldValue.groupId });
                }
                // clear the posts
                this.posts = [];
                this.postsLoading = true;
                // reload the posts. Check store to see if we already have saved posts for this group before though.
                this.groupPostsRef = firebase.db.collection("groupPosts").where("groupId", "==", this.group.groupId).orderBy("postDate", "desc").limit(this.limit);
                /* if we haven't loaded posts for the selectedGroupId yet, it will be undefined, in which case we just load posts. */
                if (typeof this.$store.state.groupsPageStore.groupPosts[this.group.groupId] === 'undefined'){
                    this.getGroupPosts();
                } else if (this.retrievedPosts.length > 0) {
                    this.retrievedPosts.forEach((post) => {
                        if (post.groupId === this.group.groupId){
                            this.posts.push(post);
                        }
                    });
                    this.lastVisible = this.lastVisibleForSelectedGroup;
                    this.postsLoading = false;
                    window.addEventListener("scroll", this.atBottom);
                } else {
                    /* we have tried to load the posts before but there weren't any. The user goes back to this selectedGroupId and there may have been a post uploaded so we retrieve if so */
                    // console.log("once there was 0 posts, now there may be some so we are trying to retreive them")
                    this.getGroupPosts();
                }
            }
        },
        '$store.state.groupsPageStore.deleteOrRemoveMember.deletedMember' (value) {
            // this should remove all posts from the member deleted on the client side
            if (value) {
                this.groupPostsRef = firebase.db.collection("groupPosts").where("groupId", "==", this.group.groupId).orderBy("postDate", "desc").limit(this.limit);
                this.getGroupPostsMinusDeletedMember(value.userId);
            }
        },
    },
    created: function() {
        if (typeof this.retrievedPosts != 'undefined' && this.retrievedPosts.length > 0) {
            this.retrievedPosts.forEach((post) => {
                if (post.groupId === this.group.groupId){
                    this.posts.push(post);
                }
            });
            this.postsLoading = false;
            if(this.lastVisibleForSelectedGroup != null){
                this.lastVisible = this.lastVisibleForSelectedGroup;
            }
            window.addEventListener("scroll", this.atBottom);
        } else {
            this.groupPostsRef = firebase.db.collection("groupPosts").where("groupId", "==", this.group.groupId).orderBy("postDate", "desc").limit(this.limit);
            this.getGroupPosts();
        }
    },
    beforeDestroy() {
        this.$store.dispatch('setPostsForGroup', { posts: this.posts, groupId: this.group.groupId });
        this.$store.dispatch('setLastVisibleForGroup', { lastVisible: this.lastVisible, groupId: this.group.groupId });
        window.removeEventListener('scroll', this.atBottom);
    },
};
</script>

<style>
.group-row {
    overflow-x: scroll;
}
.v-skeleton-loader__image {
    height: 100%;
}
</style>
