<template>
    <v-dialog v-model="dialog" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelExistingGroup">
        <v-card v-if="!loading" class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="mb-2 font-weight-bold text-h5 text-center">Search for Existing Group</span>
            </v-card-title>

            <v-row justify="center" class="mx-0">
                <v-col cols="12" xl="10" lg="10" md="10" sm="12">
                    <v-text-field v-model="newSearch" type="text" label="Search for Group by Name"  color="primary" clearable outlined hide-details="auto" append-outer-icon="mdi-magnify" @click:append-outer="searchForGroups" @keydown.enter="searchForGroups" @click:clear="clearSearch" @keydown.delete="checkForEmptySearch"></v-text-field>
                </v-col>
                <v-col cols="12" xl="10" lg="10" md="10" sm="12" class="ma-0 pa-0">
                    <v-list v-if="searchResults.length > 0" rounded class="pt-0" two-line>
                        <v-divider class="justify-center"/>
                        <template v-for="(result, index) in searchResults">
                            <v-list-item :key="result.groupId" selectable dense class="align-center">
                                <v-list-item-avatar>
                                    <v-img v-if="result.groupThumbnail" :src="result.groupThumbnail" referrerpolicy="no-referrer" @error="result.groupThumbnail=null"></v-img>
                                    <v-img v-else src="../../assets/no-user.png" :alt="result.groupName"></v-img>
                                </v-list-item-avatar>
                                <v-row class="px-3" justify="center" align="center">
                                    <v-col cols="8" class="pa-0 ma-0 text-truncate">
                                        <span style="word-break: normal" class="font-weight-bold text-subtitle-1">{{result.groupName}}</span><br/>
                                        <span v-if="requestSentToGroup(result)" class="font-weight-light text-caption">Waiting for Approval</span>
                                        <span v-else-if="alreadyInGroup(result) != -1" class="font-weight-light text-caption">Already a Member</span>
                                        <span v-else-if="invitedToGroup(result.groupId) != -1" class="font-weight-light text-caption">Invited to Join</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-btn color="primary" text small :disabled="requestSentToGroup(result) || alreadyInGroup(result) != -1 || invitedToGroup(result.groupId) != -1" @click="requestToJoinGroup(result)" class="hidden-xs-only">Join Group</v-btn>
                                        <v-btn color="primary" text small :disabled="requestSentToGroup(result) || alreadyInGroup(result) != -1 || invitedToGroup(result.groupId) != -1" @click="requestToJoinGroup(result)" class="hidden-sm-and-up">Join</v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="justify-center" :key="index"/>
                        </template>
                    </v-list>
                    <div v-else-if="emptySearchResults" class="text-caption text-center mt-2">We Can't Find Any Groups Matching Your Search</div>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-row justify="end" no-gutters class="ma-0 pt-3">
                    <v-btn color="primary" text @click="cancelExistingGroup">Cancel</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-else class="rounded-xl">
            <v-card-title style="word-break: normal" class="justify-center">
                <span class="font-weight-bold text-h5 text-center">Joining <b>{{joiningGroupName}}</b></span>
            </v-card-title>
            <v-row justify="center" no-gutters>
                <v-col align="center">
                    <span class="font-weight-bold text-subtitle-2 text-center">Please wait...</span><br/>
                    <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "../../../firebaseConfig.js";


export default {
    name: "join-group",
    components: {},
    props: ["dialog", "groups"],
    data() {
        return {
            loading: false,
            newSearch: "",
            oldSearch: "",
            joiningGroupName: "",
            searchResults: [],
            emptySearchResults: false,
        };
    },
    methods: {
        clearSearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            }
            this.searchResults = [];
        },
        checkForEmptySearch: function () {
            if (this.emptySearchResults) {
                this.emptySearchResults = false;
            } else if (this.newSearch != null && this.newSearch.length <= 1) {
                this.searchResults = [];
            }
        },
        searchForGroups: function () {
            if (this.newSearch === null || this.newSearch === "" /*|| this.newSearch === " "*/) {
                if (this.emptySearchResults) {
                    this.emptySearchResults = false;
                }
                return;
            } else if(this.newSearch.length > 0 && this.newSearch != this.oldSearch || this.searchResults.length === 0){
                this.searchGroupByName();
            }
            this.oldSearch = this.newSearch;
        },
        searchGroupByName: function () {
            // 1 - create the strings to query off of
            const increasedLastLetter = String.fromCharCode( this.newSearch.charCodeAt(this.newSearch.length-1) + 1 ); // increase the last letter of the string by one 
            const endTerm = this.newSearch.slice(0,-1) + increasedLastLetter; // remove the last letter of the string then add the increased last letter
            // 2 - create the firebase reference || TODO: try with .where(IN) - if space in the string captialize each starting lettter. lowercase all of their string, uppercase all | also some with the reerse string. take all and concat results if not duplicates
            const groupNameRef = firebase.db.collection("groups").where("groupName", ">=", this.newSearch).where("groupName", "<", endTerm);
            groupNameRef.get().then((snapshot) => {
                this.searchResults = [];
                if (snapshot.empty){
                    this.runModifiedGroupNameSearch();
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    this.searchResults.push({
                        'groupName':doc.data().groupName,
                        'groupId':doc.data().groupId,
                        'groupThumbnail': doc.data().groupThumbnail,
                        'admins': doc.data().admin,
                        'privateContributions': doc.data().privateContributions,
                    })
                })
                this.runModifiedGroupNameSearch();
            })
            .catch((err) => {
                console.log("Error finding group by name", err.message);
            })
        },
        runModifiedGroupNameSearch(){
            let changeFirstLetterCasing;
            if (this.newSearch.slice(0,1) === this.newSearch.charAt(0).toUpperCase()){
                changeFirstLetterCasing = this.newSearch.charAt(0).toLowerCase() + this.newSearch.slice(1);
            }
            else {
                changeFirstLetterCasing = this.newSearch.charAt(0).toUpperCase() + this.newSearch.slice(1);
            }
            const modifiedEndTerm = changeFirstLetterCasing.slice(0,-1) + String.fromCharCode( changeFirstLetterCasing.charCodeAt(changeFirstLetterCasing.length-1) + 1 );
            const modifiedGroupRef = firebase.db.collection("groups").where("groupName", ">=", changeFirstLetterCasing).where("groupName", "<", modifiedEndTerm);
            modifiedGroupRef.get().then((snapshot) => {
                if (snapshot.empty) {
                    if (this.searchResults.length === 0) {
                        this.emptySearchResults = true;
                    }
                    return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    this.searchResults.push({
                        'groupName': doc.data().groupName,
                        'groupId': doc.data().groupId,
                        'groupThumbnail': doc.data().groupThumbnail,
                        'admins': doc.data().admin,
                        'privateContributions': doc.data().privateContributions,
                    })
                })
            }).catch((err) => {
                console.log("error retrieving the new ref", err.message);
            })
        },
        requestToJoinGroup: function (group) {
            if (this.requestSentToGroup(group) || this.alreadyInGroup(group) != -1 || this.invitedToGroup(group.groupId) != -1) {
                this.$store.commit('alertUser', { show: true, text: `already part of, requested to join or invited to group`, type: 'snackbar' });
                return;
            } else {
                this.joiningGroupName = group.groupName;
                this.loading = true;
                this.$http.post(`groups/request`, {
                    admins: group.admins,
                    groupId: group.groupId,
                    groupName: group.groupName,
                    userId: firebase.auth.currentUser.uid,
                    userProfilePhoto: firebase.auth.currentUser.photoURL,
                    username: firebase.auth.currentUser.displayName,
                    email: firebase.auth.currentUser.email,
                    groupThumbnail: group.groupThumbnail,
                }).then((response) => {
                    if (response.status == 201) {
                        this.$store.commit('setSelectedGroupId', group.groupId);
                        this.loading = false;
                        this.joiningGroupName = "";
                    }
                }).catch((err) => {
                    this.loading = false;
                    this.cancelExistingGroup();
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                });
            }
        },
        cancelExistingGroup: function () {
            if (!this.loading) {
                this.$emit("cancelExistingGroup");
            }
        },
        requestSentToGroup: function (result) {
            var index = this.groups.findIndex((group) => {if(group.groupId === result.groupId)return true})
            if (index != -1) {
                if ('accepted' in this.groups[index]) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        alreadyInGroup(result){
            return this.groups.findIndex((group) => {if(group.groupId === result.groupId)return true});
        },
        invitedToGroup: function (resultGroupId) {
            return this.userNotifications.findIndex((group) => {if(group.data.groupId === resultGroupId)return true});
        },
    },
    computed: {
        userNotifications(){
            return JSON.parse(JSON.stringify(this.$store.state.userNotifications.groupRequests));
        },
    },
};
</script>
