<template>
    <div class="pb-10">
         <!-- DATE PICKER AND SEARCH BAR -->
        <div class="admin-dashboard-toolbar full-width mx-0 d-flex flex-row flex-wrap justify-sm-space-between align-sm-center">

            <v-col cols="4" xl="4" lg="3" md="3" sm="3" xs="0" class="hidden-xs-only">
                <div class="text-caption text-sm-subtitle-2">Admin: {{userData.username}}</div>
            </v-col>

            <v-spacer class="hidden-lg-and-up"></v-spacer>
            <!-- SEARCH BAR FOR SPECIFIC USER DATA -->
            <v-col cols="12" xl="4" lg="4" md="5" sm="5" xs="12" class="d-flex justify-center order-last order-sm-0 pt-0 pt-sm-3">
                <!-- <div class="full-width d-flex justify-center"> -->
                    <!-- <v-divider></v-divider> -->
                    
                    <v-menu transition="slide-y-transition" rounded="lg" offset-y>
                        <template v-slot:activator="{on}">
                        <v-form @submit.prevent="searchUser()" v-model="formIsValid" class="full-width">
                            <v-text-field
                            clearable
                            dense
                            hide-details
                            v-model="userSearch"
                            type="text"
                            outlined
                            label="Search User"
                            color="primary"
                            v-on="on"
                            append-icon="mdi-magnify"
                            @click:clear="searchResults = [], emptySearchResults = false"
                            :value="selectedUser.username"
                            ></v-text-field>
                        </v-form>
                        </template>

                        <v-list v-if="searchResults.length > 0" rounded class="pt-0" two-line>
                            <template v-for="(result, index) in searchResults">
                                <v-list-item :key="result.userId" selectable dense class="align-center" style="cursor: pointer;" @click="selectedUser = result, userSearch = result.fullname">
                                    <v-list-item-avatar style="cursor: pointer;" @click="selectedUser = result">
                                        <v-img v-if="result.profilePicture" :src="result.profilePicture" referrerpolicy="no-referrer" @error="result.profilePicture=null"></v-img>
                                        <v-img v-else src="../assets/no-user.png" :alt="result.username"></v-img>
                                    </v-list-item-avatar>
                                    <!-- <v-list-item-content> -->
                                    <!-- <v-list-item-title v-if="result.fullname" v-html="result.fullname"></v-list-item-title> -->
                                    <v-row class="px-3" justify="space-between" align="center">
                                        <div style="cursor: pointer;" v-html="result.username" @click="selectedUser = result"></div>
                                    </v-row>
                                    <!-- </v-list-item-content> -->
                                </v-list-item>
                                <v-divider class="justify-center" :key="index"></v-divider>
                            </template>
                        </v-list>
                    <div v-else-if="emptySearchResults" class="text-caption text-center mt-2">We Can't Find Any Users Matching Your Search</div>
                </v-menu>


                <!-- </div> -->
            </v-col>
            <v-spacer class="hidden-xs-only"></v-spacer>

            <!-- DATE FILTER  -->
            <v-col cols="12" xl="2" lg="3" md="4" sm="4" xs="12" class="d-flex justify-end">
                <v-select v-model="selectedDateRange" :items="datePickerOptions" item-text="text" :placeholder="formatDateDisplay" append-icon="mdi-chevron-down" hide-details solo return-object dense @change="saveDateFilter(selectedDateRange), updateAnalytics()" :menu-props="{offsetY: true,closeOnContentClick: true,}">
                    <template slot="selection" slot-scope>
                        <div class="invert--text">{{formatDateDisplay}}</div>
                    </template>
                </v-select>
            </v-col>
        </div>
        

        <!-- USER DATA ANALYTICS -->
        <UserAnalytics ref="userAnalytics" :adminAnalytics="adminAnalytics" :userAnalytics="userAnalytics" :selectedUser="selectedUser" />

        <v-divider class="full-width accent my-5 my-md-8"></v-divider>

        <!-- POST DATA ANALYTICS -->
        <PostAnalytics ref="postAnalytics" :postAnalytics="postAnalytics" :userAnalytics="userAnalytics" :adminAnalytics="adminAnalytics"/>
    </div>
     <!-- <div class="wrapper red-border" style="height: 300px; width: 300px;">
            <canvas class="blue-border" id="graph"></canvas>
        </div> -->
</template>
<script>
// import fb from "firebase/app";
import firebase from "../../firebaseConfig";
import UserAnalytics from '../components/AdminAnalytics/UserAnalytics.vue'
import PostAnalytics from '../components/AdminAnalytics/PostAnalytics.vue'
import {mapState, mapActions} from 'vuex';
export default {
    name: 'AdminDashboard',
    components: {
        UserAnalytics,
        PostAnalytics,
    },
    data(){
        return {
            dates: [], // the local variable to control the way that the date picker handles dates (ISOFormat())
            closeDatePickerMenu: false,
            datePickerOptions: [
                {
                    text: "Last 7 Days",
                    days: 7,
                },
                {
                    text: "Last 30 Days",
                    days: 30,
                },
                {
                    text: "Last 90 Days",
                    days: 90,
                },
                {
                    text: "Last 180 Days",
                    days: 180,
                },
                {
                    text: "Last 365 Days",
                    days: 365,
                },
                {
                    text: "All Time",
                    days: null,
                },
            ],
            selectedDateRange: {},
            formIsValid: false,
            selectedUser: {},
            userSearch: "",
            searchResults: [],
            emptySearchResults: false,
            
        }
    },
    methods: {
        ...mapActions('adminAnalyticsStore', [
            'saveDateFilter',
            'resetAnalyticsState',
        ]),
        formatDatePickerTitle: val => {
            if(val.length === 2){
                return new Date(val[0]).toLocaleDateString() + " - " + new Date(val[1]).toLocaleDateString()
            }
            else if(val.length === 1){
                return new Date(val[0]).toLocaleDateString()
            }
            else {
                return "Select Dates"
            }
        },
        updateAnalytics(){
            if(!this.userData.admin){

            }
            // console.log("Admmin.Dashboard called update analytics")
            if(!this.randomData){
                // this.$refs.userAnalytics.destroyCharts();
                this.resetAnalyticsState();
                this.$store.dispatch('adminAnalyticsStore/fetchUserDataCollection');
                this.$store.dispatch('adminAnalyticsStore/fetchPublicPostsCollection');
                this.$store.dispatch('adminAnalyticsStore/fetchGroupPostsCollection');
            }
        },
        searchUser(){
            this.searchByUsername();
        },
        searchByUsername: function(){
            if (this.userSearch === "" || this.userSearch === null) {
                this.$store.commit('alertUser', { show: true, text: `Must enter a username to search.`, type: 'snackbar' });
                return;
            }
            // 1 - create the strings to query off of
            const increasedLastLetter = String.fromCharCode( this.userSearch.charCodeAt(this.userSearch.length-1) + 1 ); // increase the last letter of the string by one 
            const endTerm = this.userSearch.slice(0,-1) + increasedLastLetter; // remove the last letter of the string then add the increased last letter
            const usernameRef = firebase.db.collection("userData").where("username", ">=", this.userSearch).where("username", "<", endTerm);
            usernameRef.get()
            .then((snapshot) => {
                this.searchResults = [];
                if (snapshot.empty){
                this.runModifiedUsernameSearch();
                return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                    this.searchResults.push(
                        doc.data()
                    )
                })
                this.runModifiedUsernameSearch();
            })
            .catch((err) => {
                console.log("Error finding user by username", err);
            })
        },
        runModifiedUsernameSearch(){
            let changeFirstLetterCasing;
            if (this.userSearch.slice(0,1) === this.userSearch.charAt(0).toUpperCase()){
                changeFirstLetterCasing = this.userSearch.charAt(0).toLowerCase() + this.userSearch.slice(1);
            }
            else {
                changeFirstLetterCasing = this.userSearch.charAt(0).toUpperCase() + this.userSearch.slice(1);
            }
            const modifiedEndTerm = changeFirstLetterCasing.slice(0,-1) + String.fromCharCode( changeFirstLetterCasing.charCodeAt(changeFirstLetterCasing.length-1) + 1 );
            const modifiedUsernameRef = firebase.db.collection("userData").where("username", ">=", changeFirstLetterCasing).where("username", "<", modifiedEndTerm);
            modifiedUsernameRef.get()
            .then((snapshot) => {
                if ( snapshot.empty ){
                this.emptySearchResults = true;
                return;
                }
                this.emptySearchResults = false;
                snapshot.forEach((doc) => {
                if (this.searchResults.findIndex((user) => user.userId === doc.id) === -1 ){ //If it isn't the user searching and it isn't already in the list
                    this.searchResults.push(
                        doc.data()
                    )
                    }
                })

            })
            .catch((err) => {
                console.log("error retrieving the new ref", err);
            })
        },
        getUserFromFirebaseAuth(userId){ // this method is used to retrieve a user by user id from firebase auth
            this.$http.get(`/user/${userId}`)
            .then((response) => {
                console.log("The user from firebase auth is: ", response);
            })
        },
        createUserAccountManually(){
            // this method is used to create a user account with manual info, just in case we need to do it for people that have errors signing up
           const userData = {
                age: new Date(),
                captionAudio: true,
                darkTheme: true,
                dualDevice: false,
                // email: "@indians.sangerisd.net",
                email: "rodjohnsonpainting@gmail.com",
                emailVerified: true,
                fullname: "Dillan Johnson Test",
                playbackStyle: true,
                postsCreated: 0,
                profanityFilter: false,
                profilePicture: "",
                pushToTawq: true,
                signUpDate: new Date(),
                signUpMethod: "google.com",
                stripeCustomerId: "cus_L04aM05QzfpLVt",
                stripeIsActive: true,
                stripeProductId: "prod_IajkUya9uO1dPu",
                stripeSubscriptionId: "sub_1KK4RLC3oDEbxLPSYdtP7hF2",
                userId: "h1bpvYxDpcSuvsk0RprtddbEKsJ2",
                username: "dilllllllan"
           };
           const userRef = firebase.db.collection("userData");
           userRef.doc(userData.userId).set(userData)
           .then(() => {
               console.log("success")
           })
           .catch((err) => {
               console.log("error creating user account, ", err);
           })

        }
    },
    computed: {
        userData() {
            return this.$store.state.userData;
        },
        formatDateDisplay(){
            return new Date(this.dateFilter.startDate).toLocaleDateString() + " - " + new Date(this.dateFilter.endDate).toLocaleDateString();
        },
        ...mapState('adminAnalyticsStore', {
            adminAnalytics: state => state.adminAnalytics,
            userAnalytics: state => state.userAnalytics,
            postAnalytics: state => state.postAnalytics,
            dateFilter: state => state.dateFilter,
            randomData: state => state.randomData,
        })
    },
    mounted(){
        if(!this.randomData && this.userData.admin){ // no random data, and we are admin, fetch the collections!
            if(this.userAnalytics.data === null){
                this.$store.dispatch('adminAnalyticsStore/fetchUserDataCollection');
            } else { 
                // console.log("userAnalytics already fetched")
                this.$refs['userAnalytics'].drawUserAnalyticsGraphs();
            }
            if(this.postAnalytics.publicData.length === 0 || this.postAnalytics.groupData.length === 0){
                this.$store.dispatch('adminAnalyticsStore/fetchPublicPostsCollection');
                this.$store.dispatch('adminAnalyticsStore/fetchGroupPostsCollection');
            }
            else { 
                // console.log("postAnalytics Posts already fetched")
                this.$refs.postAnalytics.drawPostAnalyticsGraphs();
            }
        }
        
    },
    beforeMount() {
        if(!this.$vuetify.theme.isDark){
            this.$vuetify.theme.isDark = true;
            this.$forceUpdate();
        }
    },
    created: function() {
        if(!this.userData.admin){
            console.log("not an admin. you CANNOT see this page");
            this.$router.push({ path: '/home'});
        }
        else {
            if(!this.randomData){
                if(this.adminAnalytics.data === null){
                    this.$store.dispatch('adminAnalyticsStore/fetchAdminAnalyticsCollection');
                }
                if(this.dateFilter.startDate === null || this.dateFilter.endDate === null){ // analytics have already been loaded and the date filter set if it isn't null
                    this.selectedDateRange = this.datePickerOptions[1];
                    this.saveDateFilter(this.selectedDateRange); // itinitalizes to 30 days ago to now
                }
            }
            this.selectedUser = this.userData;
        }
        const run = false; // this code below is to alter documents (was used to retrieve all the data we have for totals on posts)
        if(run){
            // this.getUserFromFirebaseAuth('BYu5jKRlFcRiPjNaRD8gNlNBysH3');
            // this.createUserAccountManually();
            // let a = {
            //     g: 0,
            //     e: 0,
            //     f: 0,
            //     u: 0,
            // }
            // const userRef = firebase.db.collection("userData");
            // userRef.get()
            // .then((snapshot) => {
            //     snapshot.forEach((doc) => {
            //         const method = doc.data().signUpMethod;
            //         if(method === 'email'){ 
            //             a.e += 1;
            //         }
            //         else if (method === 'facebook.com'){
            //             a.f +=1;
            //         }
            //         else if (method === 'google.com'){
            //             a.g +=1 ;
            //         }
            //         else {
            //             console.log("user: ", doc.data().userId, "has unknown signupmethod of: ", method);
            //             a.u += 1;
            //         }
            //      });
            //      console.log("analytic totals: ", a);
            //     console.log("total users = ", snapshot.size)

            // });

                    
        }
        // console.log("created admin dashboard page", this.userData.admin)
        // this.getGroupPosts();
        // this.getTotalAnalytics();
    }

    
}
</script>
<style lang="scss">
.searchResultsMenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.datePickerItem {
    cursor: pointer;
    &:hover{
        background-color: var(--v-primary-base);
    }
}
.loading-data {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.loading-data::before {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, transparent, rgba(35, 46, 66, 0.75), transparent);
    width: 60%;
    height: 100%;
    top: 0;
    left: 0;
    animation: loading 1.5s infinite;
}

 @keyframes loading {
    0% {
        transform: skewX(0deg) translateX(-100%);
    }
    100% {
        transform: skewX(0deg) translateX(200%);
    }
}

@media (max-width: 600px){ 
    .admin-dashboard-toolbar {
        background-color: var(--v-inverseGrey-darken1);
        position: fixed;
        top: 56px;
        z-index: 5;
    }

}
</style>