<template>
    <v-col cols="12" xl="10" lg="10" md="12" sm="12" :style="{transform: 'translateY(50%)', background: '#424242', borderRadius: '10px', border: !isMobile ? '1px solid #61892F': ''}" style="z-index: 2; position: relative;" class="ma-0 py-1 px-1">
    <!-- <v-col cols="12" xl="10" lg="10" md="12" sm="12" :style="{transform: 'translateY(50%)', background: $vuetify.theme.isDark ? $vuetify.theme.themes.dark.inverseGrey : $vuetify.theme.themes.light.inverseGrey, borderRadius: '8px', border: '1px solid #61892F'}" class="ma-0 py-0 px-1"> -->
        
        <!-- my-0 mb-0 mt-1 -->
        <!-- v-if="showProgressBar" -->
        <v-row justify="space-between" class="ma-0 pa-0 smallText">
            <!-- text-caption -->
            <span class="font-weight-bold">{{emitTime / 1000 + " seconds"}}</span>
            <span class="font-weight-bold">{{ timePlaying / 1000 + " seconds" }}</span>
        </v-row>

        <v-progress-linear v-if="(timePlaying / 1000) >= (emitTime / 1000)" background-color="secondary" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" ></v-progress-linear>
        <!-- class="my-1" -->

        <v-progress-linear v-else-if="(timePlaying / 1000) < (emitTime / 1000)" color="red" background-opacity="0.3" :buffer-value="(timePlaying / 1000) * 2" rounded stream height="10" :value="(emitTime / 1000) * 2" ></v-progress-linear>
        <!-- class="my-1" -->

        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-icon small v-on="on" style="z-index: 9; position: absolute; top: -10px; right: -10px;">mdi-information-outline</v-icon>
            </template>
            <v-row justify="center" class="ma-0 py-0 smallText text-center px-3">
                <span v-if="(timePlaying / 1000) >= (emitTime / 1000)" class="font-weight-bold">This bar is used to track recommended recording size for a caption</span>
                <span v-else-if="(timePlaying / 1000) < (emitTime / 1000)" class="font-weight-bold">Your audio and captions my not align correctly in the video</span>
            </v-row>
        </v-tooltip>
    </v-col>
</template>

<script>
export default {
    name: "progressbar",
    components: {},
    props: ["emitTime", "timePlaying", "showProgressBar", "",],
    data: function () {
        return {};
    },
    methods: {},
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
    },
};
</script>

<style></style>