<template>
    <v-row justify="center">
        <v-dialog v-model="user" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelLeaveGroup">
            <v-card class="rounded-xl">
                <v-card-title style="word-break: normal" class="text-center justify-center">
                    <span class="font-weight-bold text-h5">Leave <span class="font-weight-black">{{ groupName }}</span>?</span>
                    <span class="font-weight-light text-subtitle-2 mt-3">Leaving a group will permanently delete all of your content from this group. This includes all posts, and the associated post data, and all contributions you have created.</span>
                    <span class="font-weight-normal text-subtitle-1 mt-3">The following action is permanent and <u>CANNOT</u> be undone. Are you sure you want to continue?</span>
                </v-card-title>

                <v-card-actions>
                    <v-row justify="end" no-gutters class="ma-0 pt-3">
                        <v-btn color="primary" text @click="cancelLeaveGroup">Cancel</v-btn>
                        <v-btn color="primary" text @click="userLeavingGroup">Leave</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="admin" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelLeaveGroup">
            <v-card class="rounded-xl">
                <v-card-title style="word-break: normal" class="text-center justify-center">
                    <span class="font-weight-bold text-h5">Leave <span class="font-weight-black">{{ groupName }}</span>?</span>
                    <!-- <span class="font-weight-bold text-h5">{{!hasMultipleAdminUsers ? 'Delete' : 'Leave or Delete'}} <span class="font-weight-black">{{ groupName }}</span>?</span> -->

                    <span v-if="!hasMultipleAdminUsers" class="font-weight-light text-subtitle-2 mt-3"><span class="font-weight-bold text-subtitle-2 mt-3">*You are the ONLY admin for this group*</span><br/>In order to <span class="font-weight-bold">Leave</span> there must be at least one other admin.
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small class="text-decoration-none ml-1 mb-1">mdi-information-outline</v-icon>
                            </template>
                            <span>Under the <b>Members</b> section of group settings,<br/> choose a member, who has been accepted,<br/> that you would like to be the new admin.<br/> Press the <b>Promote</b> button and confirm.</span>
                        </v-tooltip>
                    </span>

                    <!-- <span class="font-weight-light text-subtitle-2 mt-3"><b>Deleting</b> a group will permanently remove all of the members of this group and delete all posts, thumbnails, audio files and contributions associated with this group.</span> -->
                    <span v-if="hasMultipleAdminUsers" class="font-weight-light text-subtitle-2 mt-3"><b>Leaving</b> a group will <span class="font-weight-bold">permanently</span> delete all of your content from this group. This includes all posts, and the associated post data, and all contributions you have created.</span>

                    <span v-if="hasMultipleAdminUsers" class="font-weight-normal text-subtitle-1 mt-3">The following action is permanent and <u>CANNOT</u> be undone. Are you sure you want to continue?</span>
                </v-card-title>

                <v-card-actions>
                    <!-- <v-btn color="primary" text @click="cancelLeaveGroup">Cancel</v-btn> -->
                    <v-row justify="end" no-gutters>
                        <!-- <v-btn color="primary" text @click="adminDeleteGroup" disabled>Delete</v-btn> -->
                        <v-btn color="primary" text @click="cancelLeaveGroup">Cancel</v-btn>
                        <v-btn color="primary" text @click="userLeavingGroup" :disabled="!hasMultipleAdminUsers">Leave</v-btn>
                    </v-row>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="loading" persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5" @click:outside="cancelLeaveGroupWhileLeaving">
            <v-card class="rounded-xl">
                <v-card-title style="word-break: normal" class="justify-center">
                    <span class="font-weight-bold text-h5">{{ leaving ? 'You are being Removed from ' : 'Deleting '}} <span class="font-weight-black"></span>{{groupName}}</span>
                </v-card-title>
                <v-row justify="center" no-gutters>
                    <v-col align="center">
                        <span class="font-weight-light text-subtitle-2 mt-3">This could take a while...</span><br/>
                        <v-progress-circular indeterminate color="primary" :size="80" class="my-5"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
// import firebase from "../../../firebaseConfig.js";


export default {
    name: "leave-group",
    props: ["groupName", "groupId", "hasMultipleAdminUsers",],
    data() {
        return {};
    },
    methods: {
        cancelLeaveGroup: function () {
            this.$store.commit('saveLeaveGroupDialog', false);
            this.$store.commit('saveModelLeavingAndLoading', {loading: false, model: null, leaving: null});
        },
        cancelLeaveGroupWhileLeaving: function () {
            this.$store.commit('saveModelLeavingAndLoading', {loading: false, model: null, leaving: null});
            this.$store.commit('saveLeaveGroupDialog', false);
            this.$store.commit('saveGroupSettingsDialog', false);
        },
        userLeavingGroup: function () {
            // emit to GroupSettings so we can get the current users info to send to the store
            this.$emit("userLeavingGroup");
        },
        adminDeleteGroup: function () {
            this.$emit("adminDeleteGroup");
        },
    },
    computed: {
        loading(){
            return this.$store.state.groupsPageStore.deleteOrLeaveGroup.loading;
        },
        leaving(){
            return this.$store.state.groupsPageStore.deleteOrLeaveGroup.leaving;
        },
        user(){
            return this.$store.state.groupsPageStore.deleteOrLeaveGroup.model === 'user' ? true : false;
        },
        admin(){
            return this.$store.state.groupsPageStore.deleteOrLeaveGroup.model === 'admin' ? true : false;
        },
    },
};
</script>
