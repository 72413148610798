import axios from "axios";
import firebase from "../../firebaseConfig";
import fb from "firebase/compat/app";

const newPhotoPostStore = {
    namespaced: true,
    state: () => ({
        photoPost: {},
        textAdded: [],
        postMetaDataUploadComplete: false, // the whole post has been completely uploaded
        audioCaptionsUploadComplete: false, // set to true when audio Captions have been uploaded
        photoFileUploadComplete: false,
        uploading_photo: false, // set true during the upload process
        resumable_progress: 0, // the progress for the video file being uploaded to storage
        isPublic: false,
        fbUser: firebase.auth.currentUser,
        processPhotoAttempts: 0,
    }),
    mutations: {
        saveUser(state){
            state.photoPost.userId = firebase.auth.currentUser.uid;
            state.photoPost.username = firebase.auth.currentUser.displayName;
            state.photoPost.userProfilePhoto = firebase.auth.currentUser.profilePhoto;
            state.fbUser = firebase.auth.currentUser;
            // console.log(user.uid);
        },
        saveIsPublic(state, isPublic){
            state.isPublic = isPublic;
        },
        saveResumableProgress(state, progress){
            state.resumable_progress = progress;
        },
        saveTextAdded(state, data){
            state.textAdded = data;
        },
        resetStoreState(state){
            state.postMetaDataUploadComplete = false;
            state.audioCaptionsUploadComplete = false;
            state.photoFileUploadComplete = false;
            state.uploading_photo = false;
            state.resumable_progress = 0;
            state.isPublic = false;
            state.fbUser = firebase.auth.currentUser;
            state.photoPost = {};
            state.textAdded = [];
            state.processPhotoAttempts = 0;
        }
    },
    actions: {
        setUser({commit}, user) {
            commit('saveUser', user);
        },
        setIsPublic({commit}, isPublic){
            commit('saveIsPublic', isPublic);
        },
        async savePhotoPost({state, commit, dispatch}, post){
            state.photoPost = post;
            state.photoFileUploadComplete = true;
            state.processing_photo = true;
            commit('saveResumableProgress', 100);
            state.uploading_photo = true;

            let audioDataIds = [];
            audioDataIds.push(state.photoPost.audioDataId);

            dispatch('setTextData');
            let imageUrl = await dispatch('uploadPhotoThumbnail');

            let newPostRef = firebase.db.collection( state.isPublic ? "publicPosts" : "groupPosts").doc(state.photoPost.postId);
            newPostRef.set({
                mediaType: "photo",
                title: state.photoPost.title,
                description: state.photoPost.description,
                postDate: state.photoPost.date,
                postType: state.photoPost.type,
                tags: state.photoPost.tags,
                postId: state.photoPost.postId,
                audioDataId: state.photoPost.audioDataId,
                userId: state.photoPost.userId,
                username: state.photoPost.username,
                userProfilePhoto: state.photoPost.userProfilePhoto,
                thumbnail: imageUrl,
                languageName: state.photoPost.languageSelected.text,
                imageRect: state.photoPost.imageRect,
                groupId: state.photoPost.group ? state.photoPost.group.value : null,
                groupName: state.photoPost.group ? state.photoPost.group.text : null,
                canContribute: state.photoPost.canContribute,
            },{merge: true})
            .catch((err) => {
                console.error("Error saving the post metadata", err.message);
            })

            newPostRef.collection("contributions").doc(state.photoPost.userId).set({
                audioDataId: audioDataIds,
            },{merge: true})
            .catch((err) => {
                console.error("Error setting contributions for the post.", err.message);
            });
            state.postMetaDataUploadComplete = true;
            dispatch('setTextData');
        },
        async setTextData({state, dispatch}) {
            state.audioCaptionsUploadComplete = false;
            var textAdded = await dispatch('createTextObjects');
            // let date = new Date();
            if (textAdded.length === 0) {
                console.log("audo.length is = 0");
                state.audioCaptionsUploadComplete = true;
                dispatch('processPhoto');
                // state.setTimeoutForProcessing();
                return;
            }
            if(typeof state.photoPost.audioDataId === 'undefined' || typeof state.photoPost.userId === 'undefined'){
                console.log("either state.photoPost.audioDataId or .userId is undefined");
                console.log("audioDataId: ", state.photoPost.audioDataId);
                console.log("userId: ", state.photoPost.userId);
            }
            var newAudioRef = firebase.db.collection("audioSegments").doc(state.photoPost.audioDataId);
            newAudioRef.set({
                  title: state.photoPost.title,
                  userId: state.photoPost.userId,
                  username: state.photoPost.username,
                  userProfilePhoto: state.photoPost.userProfilePhoto,
                  postId: state.photoPost.postId,
                  text: textAdded,
                  date: state.photoPost.date,
                  languageSelected: state.photoPost.languageSelected,
                  audioDataId: state.photoPost.audioDataId,
            },{ merge: true })
              .then(() => {
                state.audioCaptionsUploadComplete = true;
                dispatch('processPhoto'); // because setAudioCaptionData is called last, it should be the last thing to finish
              })
              .catch((error) => {
                console.error("Error uploading audio metadata", error);
                state.audioCaptionsUploadComplete = true;
                dispatch('processPhoto');
              });
        },
        updateTextAdded({commit}, data){
            commit('saveTextAdded', data);
        },
        createTextObjects({state}){
             // state creates metadata for the actual audio file. state is not creating
            // the audio file itself.
            return new Promise((resolve) => {
                var objects = [];
                // console.log(state.textAdded);
                state.textAdded.forEach((text) => {
                    var object = {
                        text: text.text,
                        date: text.date,
                        languageSelected: text.languageSelected,
                        recordingTime: text.recordingTime,
                        audioURL: text.audioURL,
                        segment: text.segment,
                        caption: text.caption,
                        rect: text.rect,
                        style: text.style,
                    };
                    objects.push(object);
                });
                resolve(objects);
            });
            // return new Promise((resolve) => {
            //     var objects = [];
            //     state.audioCaptionData.audio.forEach((audio) => {
            //         var newObj = {
            //         audioURL: audio.audioURL,
            //         audioStart: audio.audioStart,
            //         audioDur: audio.audioDur,
            //         segment: audio.segment,
            //         };
            //         objects.push(newObj);
            //     });
            //     resolve(objects);
            // });
            
        },
        async uploadPhotoThumbnail({state}){
            // get the thumbnail from the file input
            var data = new FormData();
            data.append("file", state.photoPost.photoURL);
            data.append("postId", state.photoPost.postId);
            data.append("mediaType", true);
            let response = await axios.post(`uploadpic`, data);
            return response.data.url;
        },
        processPhoto({state, dispatch}){
            /* state function will be called when PostUploadComplete = true, unless the image hasn't been resized yet,
            in which case it will be called once the image is done being resized */
            if (state.audioCaptionsUploadComplete && state.postMetaDataUploadComplete && state.photoFileUploadComplete) {
                setTimeout(() => {
                    dispatch('resetStoreState');
                  }, 8000);
            } else {
                console.error("Processing didn't work, if this is the first time, we will try again.");
                if ( state.processPhotoAttempts > 0 ){ // if we have tried processing already
                    dispatch('deleteIncompletePostAudioFilesFromStorage');
                }
            }
            state.processPhotoAttempts += 1;
        },
        deleteIncompletePostAudioFilesFromStorage({state}){
            let audioDataRef = firebase.storage.ref(
                `audioSegments/${state.photoPost.postId}/${state.photoPost.audioDataId}`
              );
              audioDataRef.list().then((res) => {
                res.items.forEach(function (itemRef) {
                  /* retrieve the file names of each segment, so that we can delete each file
                    individually from the storage bucket. GCS doesn't allow us to delete the full bucket */
        
                  let filename = itemRef.name;
                  let audioRef = audioDataRef.child(filename);
                  audioRef
                    .delete()
                    .then(() => {
                      return;
                    })
                    .catch(function (error) {
                      console.error(
                        `Error Deleting Audio Segment File ${filename} from Storage:`,
                        error
                      );
                      return;
                    });
                });
            });
        },
        resetStoreState({commit}){
            /* this method will reset the data being stored for newPost */
            commit('resetStoreState');
        }
    } // actions
} // store

export default newPhotoPostStore