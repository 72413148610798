import firebase from "../../firebaseConfig";

const totalTimeCalculator = (seconds) => {
    // console.log("seconds in time calc : ", seconds)
    let timeObject = {
        d: null,
        h: null,
        m: null,
        s: null,
    }
    seconds = Math.abs(seconds);
    const days = Math.floor( seconds / 86400 ); // 86400 seconds = 1 day
    seconds -= days * 86400;

    const hours = Math.floor( seconds / 3600 ) % 24; // 3600 seconds = 1 hour. we % by 24 because 
    seconds -= hours * 3600;

    const minutes = Math.floor( seconds / 60 ) % 60;
    seconds -= minutes * 60;


    timeObject.d = days;
    timeObject.h = hours;
    timeObject.m = minutes;
    timeObject.s = seconds;
    // console.log("total time: ", timeObject);
    return timeObject;
};

const getDefaultState = () => {
    return {
        randomData: false, // CHANGE THIS TO TRUE WHEN WE DON'T WANT TO LOAD ACTUAL DATA. FOR GRAPH CONFIG PURPOSES
        searchLimit: 500, /* THIS FIELD IS USED TO CONTROL HOW MANY DOCUMENTS COME BACK WHEN QUERYING FIREBASE */
        dateFilter: {
            startDate: null, // start value will be null until they chose a date filter. in which case we grab all data
            endDate: null, // // end value will be null until they chose a date filter. in which case we grab all data
            breakIntoDays: true, // will be true if the date range is <= 30 days. we use this for some of the labels on the graphs that show dates, when true it will show days, when false it will show months and year
            allTime: false, // will be set to true if the admin selects to see all time data for the date range
        },
        adminAnalytics: {
            isLoading: false,
            data: null,
        },
        userAnalytics: {
            isLoading: false,
            data: null, // data will be a list of all the users
        },
        postAnalytics: {
            isLoading: false,
            groupDataLoading: true,
            publicDataLoading: true,
            groupData: [], // groupData will be all groupPosts
            publicData: [],
        },
        postTotals: {
            group: {
                total_posts: 0,
                total_audio_time: 0,
                total_views: 0,
                total_post_creation_time: 0, // in seconds
                total_contributions: 0,
                createMethod: {
                    pickup: 0,
                    push: 0,
                },
                mediaType: {
                    video: 0,
                    photo: 0,
                },
                videoType: {
                    youtube: 0,
                    file: 0,
                },
                deviceType: {
                    mobile: 0,
                    desktop: 0,
                    ios: 0,
                    other: 0,
                },
                languages: {

                },
                top_viewed_posts: [], // a list of posts
            },
            public: {
                total_posts: 0,
                total_audio_time: 0,
                total_views: 0,
                total_post_creation_time: 0, // in seconds
                total_contributions: 0,
                createMethod: {
                    pickup: 0,
                    push: 0,
                },
                mediaType: {
                    video: 0,
                    photo: 0,
                },
                videoType: {
                    youtube: 0,
                    file: 0,
                },
                deviceType: {
                    mobile: 0,
                    desktop: 0,
                    ios: 0,
                    other: 0,
                },
                languages: {

                },
                top_viewed_posts: [], // a list of posts
            }
        }
    }
}

const adminAnalyticsStore = {
    namespaced: true,
    state: () => getDefaultState(),
    mutations: {
        setAdminAnalytics(state, payload){
            state.adminAnalytics.data = payload.data;
            state.adminAnalytics.isLoading = payload.isLoading;
        },
        setUserAnalytics(state, payload){
            state.userAnalytics.isLoading = payload.isLoading;
            state.userAnalytics.data = payload.data;
        },
        setPostAnalytics(state, payload){
            if(payload.isPublic){
                state.postAnalytics.publicDataLoading = payload.publicDataLoading;
                state.postAnalytics.publicData = payload.data;
            }
            else {
                state.postAnalytics.groupDataLoading = payload.groupDataLoading;
                // console.log("setting group data")
                state.postAnalytics.groupData = payload.data;
            }

            if(!state.postAnalytics.publicDataLoading && !state.postAnalytics.groupDataLoading){
                state.postAnalytics.isLoading = false;
            }
            else {
                state.postAnalytics.isLoading = true;
            }
        },
        setDateFilter(state, payload){
            if(payload.days != null){
                const startDate = new Date();
                startDate.setDate(startDate.getDate() - payload.days);
                state.dateFilter.startDate = startDate;
                state.dateFilter.allTime = false;
            }
            else {
                state.dateFilter.startDate = new Date('January 1, 2020 00:00:00'); // a date before tawqin started (or at least our database has data for)
                state.dateFilter.allTime = true;
            }
            state.dateFilter.endDate = new Date(); // now - today's date
            const oneDay = 1000 * 60 * 60 * 24;
            const diffInTime = state.dateFilter.endDate - state.dateFilter.startDate;
            const diffInDays = Math.round(diffInTime / oneDay)
            state.dateFilter.breakIntoDays = diffInDays <= 30;

            // if( payload[0] > payload[1] ){
            //     state.dateFilter.startDate = payload[1];
            //     state.dateFilter.endDate = payload[0];
            // }
            // else {
            //     state.dateFilter.startDate = payload[0];
            //     state.dateFilter.endDate = payload[1];
            // }
            // console.log("the date filter is now: ", state.dateFilter);
        },
        savePostTotals(state, payload){
            state.postTotals = Object.assign({}, state.postTotals, payload);
            state.postTotals = payload;
            // console.log("the payload in commit.savePostTotals: ", payload);
        },
        // clears just the analytics we use with date filters
        clearAnalyticsState(state){
            state.userAnalytics = {
                isLoading: false,
                data: null, // data will be a list of all the users
            },
            state.postAnalytics = {
                isLoading: false,
                groupDataLoading: true,
                publicDataLoading: true,
                groupData: [], // groupData will be all groupPosts
                publicData: [],
            },
            state.postTotals =  {
                group: {
                    total_posts: 0,
                    total_audio_time: 0,
                    total_views: 0,
                    total_post_creation_time: 0, // in seconds
                    total_contributions: 0,
                    createMethod: {
                        pickup: 0,
                        push: 0,
                    },
                    mediaType: {
                        video: 0,
                        photo: 0,
                    },
                    videoType: {
                        youtube: 0,
                        file: 0,
                    },
                    deviceType: {
                        mobile: 0,
                        desktop: 0,
                        ios: 0,
                        other: 0,
                    },
                    languages: {
    
                    },
                    top_viewed_posts: [], // a list of posts
                },
                public: {
                    total_posts: 0,
                    total_audio_time: 0,
                    total_views: 0,
                    total_post_creation_time: 0, // in seconds
                    total_contributions: 0,
                    createMethod: {
                        pickup: 0,
                        push: 0,
                    },
                    mediaType: {
                        video: 0,
                        photo: 0,
                    },
                    videoType: {
                        youtube: 0,
                        file: 0,
                    },
                    deviceType: {
                        mobile: 0,
                        desktop: 0,
                        ios: 0,
                        other: 0,
                    },
                    languages: {
    
                    },
                    top_viewed_posts: [], // a list of posts
                }
            }
        },
        // clears the whole store
        clearAdminStoreState(state){
            let newState = getDefaultState();
            Object.assign(state, newState);
        }
    },
    actions: {
        /* this method will fetch the admin collection in our DB */
        fetchAdminAnalyticsCollection({commit}){
            commit('setAdminAnalytics', { data: null, isLoading: true } );
            const collectionRef = firebase.db.collection("admin").doc("analytics");
            collectionRef.get()
            .then((doc) => {
                if (!doc.exists){
                    return;
                }
                commit('setAdminAnalytics', { data: doc.data(), isLoading: false } );
            })
            .catch((err) => {
                commit('setAdminAnalytics', { data: null, isLoading: false } );
                console.log("error getting admin analytics", err);
            })
        },
        /* this method will fetch all the user docs from our userData collection in the DB */
        fetchUserDataCollection({commit, state}){
            commit('setUserAnalytics', { data: null, isLoading: true } );
            const collectionRef = firebase.db.collection("userData").where("signUpDate", ">=", new Date(state.dateFilter.startDate) ).where("signUpDate", "<=", new Date(state.dateFilter.endDate) ).orderBy("signUpDate").limit(state.searchLimit);
            collectionRef.get()
            .then((snapshot) => {
                if (snapshot.size === 0){
                    // console.log("no users within that date")
                    commit('setUserAnalytics', { data: null, isLoading: false } );
                    return;
                }
                // console.log("snapshot.size", snapshot.size)
                let allUsers = [];
                snapshot.forEach((doc) => {
                    allUsers.push(doc.data());
                })
                commit('setUserAnalytics', { data: allUsers, isLoading: false } );
            })
            .catch((err) => {
                commit('setUserAnalytics', { data: null, isLoading: false } );
                console.log("error getting user data for analytics", err);
            })
        },
        /* this method will get all group post data */
        fetchGroupPostsCollection({commit, dispatch, state}){
            commit('setPostAnalytics', { data: null, groupDataLoading: true, isPublic: false } );
            const collectionRef = firebase.db.collection("groupPosts").where("postDate", ">=", new Date(state.dateFilter.startDate) ).where("postDate", "<=", new Date(state.dateFilter.endDate) ).orderBy("postDate").limit(state.searchLimit);
            collectionRef.get()
            .then((snapshot) => {
                if (snapshot.size === 0){
                    // console.log("no group posts within that date")
                    commit('setPostAnalytics', { data: null, groupDataLoading: false, isPublic: false } );
                    return;
                }
                // console.log("snapshot.size", snapshot.size)
                let posts = [];
                snapshot.forEach((doc) => {
                    posts.push(doc.data());
                })
                commit('setPostAnalytics', { data: posts, groupDataLoading: false, isPublic: false, } );
                dispatch('setPostAnalyticMetrics', { group: true });
            })
            .catch((err) => {
                commit('setPostAnalytics', { data: null, groupDataLoading: false, isPublic: false } );
                console.log("error getting group post data for analytics", err);
            })

        },
        /* this method will get all public post data */
        fetchPublicPostsCollection({commit, dispatch, state}){
            commit('setPostAnalytics', { data: null, publicDataLoading: true, isPublic: true } );
            // console.log("when fetching posts, start date is: ", new Date(state.dateFilter.startDate))
            const collectionRef = firebase.db.collection("publicPosts").where("postDate", ">=", new Date(state.dateFilter.startDate) ).where("postDate", "<=", new Date(state.dateFilter.endDate) ).orderBy("postDate").limit(state.searchLimit);
            collectionRef.get()
            .then((snapshot) => {
                if (snapshot.size === 0){
                    // console.log("no public posts within that date")
                    commit('setPostAnalytics', { data: null, publicDataLoading: false, isPublic: true, } );
                    return;
                }
                // console.log("snapshot.size", snapshot.size)
                let posts = [];
                snapshot.forEach((doc) => {
                    posts.push(doc.data());
                })
                commit('setPostAnalytics', { data: posts, publicDataLoading: false, isPublic: true, } );
                dispatch('setPostAnalyticMetrics', { group: false } );
            })
            .catch((err) => {
                commit('setPostAnalytics', { data: null, publicDataLoading: false, isPublic: true, } );
                console.log("error getting post post data for analytics", err);
            })

        },
        /* AFTER all the data is received, this method will run through the post lists (group and public) and set each of the state variables
            that will allow for getters to retrieve what they are needing. By using one method to loop through the posts, the getters won't each need
            to loop through the data.
        */
        setPostAnalyticMetrics({commit, dispatch, state}, payload){
            // console.log("setPostAnalyticsMetrics()")
            let posts = state.postTotals;
            // console.log("post totals", posts);
            if(payload.group){
                if( state.postAnalytics.groupData === null  || state.postAnalytics.groupData.length === 0 ){
                    posts.group.total_posts = 0;
                }
                else {
                    posts.group.total_posts = state.postAnalytics.groupData.length;
                    // loop through group
                    for(let i = 0; i < state.postAnalytics.groupData.length; i++){
                        dispatch('organizePost', { posts: posts.group, data: state.postAnalytics.groupData[i] } );
                    }
                }
            }
            else {
                if ( state.postAnalytics.publicData === null  || state.postAnalytics.publicData.length === 0 ){
                    posts.public.total_posts = 0;
                }
                else {
                    posts.public.total_posts = state.postAnalytics.publicData.length;
                    // loop through public
                    for(let i = 0; i < state.postAnalytics.publicData.length; i++){
                        dispatch('organizePost', { posts: posts.public, data: state.postAnalytics.publicData[i] } );
                    }
                }
                // save it to the state variables
            }
            commit('savePostTotals', posts);
        },
        organizePost({state}, payload){
            // console.log("for public posts dispatching organizePost()", payload)

            // console.log("state", state);
            if(typeof state.postTotals === 'undefined'){
                // console.log("state.postTotals === undefined", state.postTotals)
                return;
            } // just putting this if statement here because we have to use state 
            // set media type
            if(typeof payload.data.mediaType != 'undefined'){
                if(payload.data.mediaType === 'video'){
                    payload.posts.mediaType.video += 1;
                }
                else if (payload.data.mediaType === 'photo'){
                    payload.posts.mediaType.photo += 1;
                }
            }

            // set video type
            if(typeof payload.data.videoType != 'undefined'){
                if(payload.data.videoType === 'video/mp4'){
                    payload.posts.videoType.file += 1;
                }
                else if (payload.data.videoType === 'video/youtube'){
                    payload.posts.videoType.youtube += 1;
                }
            }

            // set create method & device type & total audio times

            if(typeof payload.data.postAnalytics != 'undefined'){
                // create method
                if(payload.data.postAnalytics.pushToTawq){
                    payload.posts.createMethod.push += 1;
                }
                else if (payload.data.postAnalytics.pickUpToTawq){
                    payload.posts.createMethod.pickup += 1;
                }
                // device type
                if(payload.data.postAnalytics.isMobileDevice){
                    payload.posts.deviceType.mobile += 1;
                    if(payload.data.postAnalytics.isIOS){
                        payload.posts.deviceType.ios += 1;
                    }
                    else {
                        payload.posts.deviceType.other += 1;
                    }
                }
                else {
                    payload.posts.deviceType.desktop += 1;
                }

                // get the post total audio time and  add it to the total post audio time
                if(typeof payload.data.postAnalytics.totalAudioTime != 'undefined'){
                    payload.posts.total_audio_time += payload.data.postAnalytics.totalAudioTime;
                }

                // add to the total post time
                if(payload.data.postAnalytics.startTimes.length > 0 && payload.data.postAnalytics.endTimes.length > 0){
                    const endIndex = payload.data.postAnalytics.startTimes.length >= payload.data.postAnalytics.endTimes.length ? 
                                        payload.data.postAnalytics.startTimes.length : payload.data.postAnalytics.endTimes.length;
                    let total_post_time = 0;
                    for(let i = 0; i < endIndex; i++){
                        if(typeof payload.data.postAnalytics.startTimes[i] === 'undefined' || typeof payload.data.postAnalytics.endTimes[i] === 'undefined'){
                            continue;
                        }
                        else {
                            let t1 = payload.data.postAnalytics.startTimes[i].toDate();
                            let t2 = payload.data.postAnalytics.endTimes[i].toDate();
                            total_post_time += Math.floor( (t2.getTime() - t1.getTime()) / 1000 ); // seconds of time
                        }
                    }
                    // have calculated how long this post took to create in seconds, now just add it to the total_post_creation_time
                    payload.posts.total_post_creation_time += total_post_time;
                }
            }


            // add up the total views
            if(typeof payload.data.postViewed != 'undefined'){
                payload.posts.total_views += payload.data.postViewed;
            }

            // see if it belongs in the top 5 most viewed posts!
            // console.log("postViwed", payload.data.postViewed)
            if(payload.posts.top_viewed_posts.length > 0 && typeof payload.data.postViewed != 'undefined'){
                for(let i = 0; i < payload.posts.top_viewed_posts.length; i++){
                    const viewed = payload.posts.top_viewed_posts[i].postViewed
                    // console.log("viewed: ", viewed);
                    if(payload.data.postViewed > viewed){
                        // if the list is now greater than 5, we will pop the last one off
                        if(payload.posts.top_viewed_posts.length >= 5){
                            payload.posts.top_viewed_posts.pop();
                        }
                        // insert it into the list at position i
                        // console.log("adding to the list of top viewed, with views: ", payload.data, "at positon", i)
                        payload.posts.top_viewed_posts.splice(i, 0, payload.data);
                        // since we inserted it and know it's place amongst the top 5, we can break out of the loop
                        break;
                    }
                    // if we have gotten to the end and it isn't greater than any others, we need to see if the list is full yet (5 posts). if not then just add it.
                    else {
                        if (payload.posts.top_viewed_posts.length < 5){
                            payload.posts.top_viewed_posts.push(payload.data);
                            break;
                        }
                    }
                    
                }
            }
            else { // the first 1 we will just add to the list
                if(typeof payload.data.postViewed != 'undefined'){
                    // console.log("adding to the list of top viewed, with views: ", payload.data.postViewed)
                    payload.posts.top_viewed_posts.push(payload.data);
                }
            }
            
            // get the language of the post and increment the total for that language 
            if(typeof payload.data.languageName != 'undefined'){
                const langName = payload.data.languageName;
                if(typeof payload.posts.languages[`${langName}`] != 'undefined'){ // we have added this language, just increment it
                    payload.posts.languages[`${langName}`] += 1;
                }
                else {
                    payload.posts.languages[`${langName}`] = 1;
                }
            }

            // add the total contributions
            if(typeof payload.data.postAnalytics != 'undefined' && typeof payload.data.postAnalytics.total_contributions != 'undefined'){
                payload.posts.total_contributions += payload.data.postAnalytics.total_contributions;
            }

            return
        },
        /* this method saves the date filter from the admin panel */
        saveDateFilter({commit}, payload){
            commit('setDateFilter', payload);
        },
        resetAnalyticsState({commit}){
            // we refetch the userAnalytics, postAnalytics and will need to reset postTotals
            commit('clearAnalyticsState');
        },
        resetAdminAnalyticsStore({commit}){
            /* this method will reset the data being stored for the admin analytics store */
            commit('clearAdminStoreState');
        },
    },
    getters: {
        /*  dateWithinFilter will return true if the date falls within the filtered date, false if it does not */
        dateWithinFilter: (state) => (date) => {
            // console.log("state.dateFilter", state.dateFilter);
            // console.log("comparing dates with filters, date: ", new Date(date.toDate()) )
            // console.log("filterdate start: ", new Date(state.dateFilter.startDate))
            // console.log("filterdate end: ", new Date(state.dateFilter.endDate))
            date = date.toDate();
            const start = new Date(state.dateFilter.startDate);
            const end = new Date(state.dateFilter.endDate);
            if (date >= start && date <= end) {
                return true;
            }
            return false;
        },
        getUserCountWithinDate (state) {
            if(state.randomData){
                return  Math.floor(Math.random() * 800)
            }
            if(state.userAnalytics.data != null){
                return state.userAnalytics.data.length;
            }
            else {
                return 0;
            }
        },
        getTotalPosts(state){
            if(state.randomData){
                return { total: Math.floor(Math.random() * 100), dateFilterTotal: Math.floor(Math.random() * 20) }
            }
            const groupDateFilterTotal = typeof state.postAnalytics.groupData != 'undefined' && state.postAnalytics.groupData != null ? state.postAnalytics.groupData.length : 0;
            const publicDateFilterTotal = typeof state.postAnalytics.publicData != 'undefined' && state.postAnalytics.publicData != null ? state.postAnalytics.publicData.length : 0;
            if(state.adminAnalytics.data === null || state.adminAnalytics.isLoading){
                return { total: 0, dateFilterTotal: groupDateFilterTotal + publicDateFilterTotal }
            }
            return { total: (state.adminAnalytics.data.total_group_picture_posts + state.adminAnalytics.data.total_group_video_posts) + (state.adminAnalytics.data.total_public_picture_posts + state.adminAnalytics.data.total_public_video_posts), dateFilterTotal: groupDateFilterTotal + publicDateFilterTotal }
        },
        /*  getUserSubscriptionTypes will return an object with keys that are the subscription types (tiers) that we offer,
            with values that are the total amount of that type of subscription 
        */
        getUserSubscriptionTypes(state){
            if(state.randomData){
                let randData = [];
                for(let i = 0; i < 3; i++){
                    randData[i] = Math.floor(Math.random() * 100); // random num between 1 and 100;
                }
                return {
                    labels: ["Basic", "Plus", "Premium"],
                    totals: randData,
                    colors: ["#61892F", "#6B6E70",  "#FFC107"]
                }
            }
            // TODO: CHANGE WHEN WE GO LIVE WITH SUBSCRIPTIONS BECAUSE THEY WILL BE DIFFERENT IDS*/
            let productEnum = {
                "prod_IajkUya9uO1dPu": 0, // BASIC MEMBERSHIP
                "prod_IZbAIZAAdOP5RZ": 1, // PLUS MEMBERSHIP
                "prod_IZb2wxixqzmdO5": 2, // PREMIUM MEMBERSHIP
            }
            let totals = new Array( Object.keys(productEnum).length ).fill(0);
            if(state.userAnalytics.data === null || state.userAnalytics.data.length === 0){
                    return {
                        labels: ["Basic", "Plus", "Premium"],
                        totals: totals,
                        colors: ["#6B6E70", "#61892F", "#FFC107"]
                    }
            }
            else {
                for(let i = 0; i < state.userAnalytics.data.length; i++ ){
                    let key = typeof state.userAnalytics.data[i].stripeProductId != 'undefined' && state.userAnalytics.data[i].stripeIsActive ? state.userAnalytics.data[i].stripeProductId : null;
                    if(key == null) {
                        continue; // only increment the total if they have an active id and the productId isn't undefined
                    }
                    // if(state.userAnalytics.data[i].userId === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03'){
                    //     console.log("found dillan, ", )
                    //     console.log(state.userAnalytics.data[i].stripeProductId)
                    // }
                    totals[ productEnum[state.userAnalytics.data[i].stripeProductId] ] += 1;
                }
            }
            return { 
                labels: ["Basic", "Plus", "Premium"],
                totals: totals,
                colors: ["#61892F", "#6B6E70",  "#FFC107"]
            }
        },
        /*  getSignUpMethodData returns all the signup methods and the  total amount of users that signed up via that method */
        getSignUpMethodData(state){
            if(state.randomData){
                let randData = [];
                for(var i = 0; i < 3; i++){
                    randData[i] = Math.floor(Math.random() * 400); // random num between 1 and 100;
                }
                return {
                    labels: ["Email", "Facebook", "Google"],
                    totals: randData,
                    colors: ["#61892F", "#1976D2",  "#E53935"]
                }
            }
            let labels = ["Email", "Facebook", "Google"];
            let totals = [ state.adminAnalytics.data.signUpMethod.email, state.adminAnalytics.data.signUpMethod.facebook, state.adminAnalytics.data.signUpMethod.google ];
            return { 
                labels: labels,
                totals: totals,
                colors: ["#61892F", "#1976D2",  "#E53935"]
            } 
        },
        /*  getSignUpDateData returns how many users signed up in a particular month and year */ 
        getSignUpDateData(state, getters) {
            if(state.randomData){
                let randData = [];
                for(let i = 0; i < 9; i++){
                    randData[i] = Math.floor(Math.random() * 100); // random num between 1 and 100;
                }
                return {
                    labels: ["Apr/20", "May/20", "Jun/20", "Jul/20", "Aug/20", "Sept/20", "Oct/20", "Nov/20", "Dec/20"],
                    totals: randData,
                }
            }
            let clone = getters.getAllowedDatesObject;
            let allowedDates = {};
            for(let k in clone){
                if(typeof clone[k] != 'undefined'){
                    allowedDates[k] = clone[k];
                }
            } 
            if(state.userAnalytics.data === null || state.userAnalytics.data.length == 0){
                return { labels: Object.keys(allowedDates), totals: Object.values(allowedDates) };
            }
            state.userAnalytics.data.forEach((user) => {
                let key;
                if(typeof user.signUpDate === 'undefined'){
                    return;
                }
                if(state.dateFilter.breakIntoDays){
                    key = `${user.signUpDate.toDate().getMonth() + 1}/${user.signUpDate.toDate().getDate()}`;
                }
                else {
                    key = `${user.signUpDate.toDate().getMonth() + 1}/${user.signUpDate.toDate().getFullYear()}`;
                }
                if (typeof allowedDates[key] != 'undefined'){
                    allowedDates[key] += 1;
                }
            })
            // if it is set to all time, we want to start at the first data point that isn't zero...
            if( state.dateFilter.allTime ){
                for ( let k in allowedDates ){
                    if (allowedDates[k] === 0){
                        delete allowedDates[k];
                    }
                    else {
                        break;
                    }
                }
            }
            
            return {
                labels: Object.keys(allowedDates),
                totals: Object.values(allowedDates)
            }
        },
        getAveragePostsPerUserByDate(state) {
            if(state.randomData){
                return Math.floor(Math.random() * 100);
            }
            if(state.userAnalytics.data === null || state.userAnalytics.data.length == 0){
                return 0;
            }
            let totalPosts = 0;
            for( let i = 0; i < state.userAnalytics.data.length; i++ ){
                if(typeof state.userAnalytics.data[i].postsCreated != 'undefined'){
                    totalPosts += state.userAnalytics.data[i].postsCreated;
                }
            }
            // console.log("the total posts: ", totalPosts / state.userAnalytics.data.length);
            return (totalPosts / state.userAnalytics.data.length).toFixed(2);
        },
        getAveragePostsPerUserTotal(state) {
            if(state.randomData){
                return Math.floor(Math.random() * 100);
            }
            if(state.adminAnalytics.data === null || typeof state.adminAnalytics.data === 'undefined'){
                return 0;
            }
            let totalPosts = state.adminAnalytics.data.total_group_picture_posts + state.adminAnalytics.data.total_group_video_posts + state.adminAnalytics.data.total_public_picture_posts + state.adminAnalytics.data.total_public_video_posts;
            return ( totalPosts / state.adminAnalytics.data.totalUsers ).toFixed(2);

        },
        getAverageDraftsPerUser(state){
            if(state.randomData){
                return 1.54;
            }
            if(state.userAnalytics.data === null || state.userAnalytics.data.length == 0){
                return null;
            }
            // TODO: FIX TO GET THE ACTUAL DRAFT POST NUMBERS
            // let totalPosts = 0;
            // for( let i = 0; i < state.userAnalytics.data.length; i++ ){
            //     if(typeof state.userAnalytics.data[i].postsCreated != 'undefined'){
            //         totalPosts += state.userAnalytics.data[i].postsCreated;
            //     }
            // }
            // // console.log("the total posts: ", totalPosts / state.userAnalytics.data.length);
            // return (totalPosts / state.userAnalytics.data.length).toFixed(2);

        },
        getGroupPostsDataByDate(state,getters) {
            if(state.randomData){
                let randData = [];
                for(let i = 0; i < 12; i++){
                    randData[i] = Math.floor(Math.random() * 100); // random num between 1 and 100;
                }
                return {
                    labels: ["Apr/20", "May/20", "Jun/20", "Jul/20", "Aug/20", "Sept/20", "Oct/20", "Nov/20", "Dec/20", "Jan/21", "Feb/21", "Mar/21"],
                    totals: randData,
                }
            }
            if( state.postAnalytics.groupData === null || state.postAnalytics.groupData.length == 0 ){
                return {
                    labels: Object.keys(getters.getFormattedDatesWithPosts(false, false)),
                    totals: Object.values(getters.getFormattedDatesWithPosts(false, false)),
                };
            }
            const data = getters.getFormattedDatesWithPosts(false, true);
            return {
                labels: Object.keys(data),
                totals: Object.values(data),
            }
        },
        getPublicPostsDataByDate(state, getters) {
            if(state.randomData){
                let randData = [];
                for(let i = 0; i < 12; i++){
                    randData[i] = Math.floor(Math.random() * 100); // random num between 1 and 100;
                }
                return {
                    labels: ["Apr/20", "May/20", "Jun/20", "Jul/20", "Aug/20", "Sept/20", "Oct/20", "Nov/20", "Dec/20", "Jan/21", "Feb/21", "Mar/21"],
                    totals: randData,
                }
            }
            if( state.postAnalytics.publicData === null || state.postAnalytics.publicData.length == 0 ){
                return {
                    labels: Object.keys(getters.getFormattedDatesWithPosts(false, false)),
                    totals: Object.values(getters.getFormattedDatesWithPosts(false, false)),
                };
            }
            const data = getters.getFormattedDatesWithPosts(true, false);
            return {
                labels: Object.keys(data),
                totals: Object.values(data),
            }
        },
        getTotalPostsByDateData(state, getters){
            if(state.randomData){
                let randData = [];
                for(let i = 0; i < 12; i++){
                    randData[i] = Math.floor(Math.random() * 100); // random num between 1 and 100;
                }
                return {
                    labels: ["Apr/20", "May/20", "Jun/20", "Jul/20", "Aug/20", "Sept/20", "Oct/20", "Nov/20", "Dec/20", "Jan/21", "Feb/21", "Mar/21"],
                    totals: randData,
                }
            }
            const data = getters.getFormattedDatesWithPosts(true, true);
            return {
                labels: Object.keys(data),
                totals: Object.values(data),
            }
        },
        getFormattedDatesWithPosts: (state, getters) => (searchPublic, searchGroup) =>{
            // we don't need to handle random data here because the two getters that we get the info from will supply random data if we are retrieving random data.
            // let postData = getters.getPublicPostsDataByDate;
            // let groupData = getters.getGroupPostsDataByDate;
            let clone = getters.getAllowedDatesObject;
            // console.log("allowedDates clone object : ", clone);
            let allowedDates = {};
            for(let k in clone){
                if(typeof clone[k] != 'undefined'){
                    allowedDates[k] = clone[k];
                }
            } 
            // console.log("allowed dates object that should be empty: ", allowedDates)
            
            if(searchGroup && state.postAnalytics.groupData != null){
                state.postAnalytics.groupData.forEach((post) => {
                    let key;
                    if(state.dateFilter.breakIntoDays){
                        key = `${post.postDate.toDate().getMonth() + 1}/${post.postDate.toDate().getDate()}`;
                    }
                    else {
                        key = `${post.postDate.toDate().getMonth() + 1}/${post.postDate.toDate().getFullYear()}`
                    }
                    if (typeof allowedDates[key] != 'undefined'){
                        allowedDates[key] += 1;
                    }
                })
            }

            if(searchPublic && state.postAnalytics.publicData != null){
                state.postAnalytics.publicData.forEach((post) => {
                    let key;
                    if(state.dateFilter.breakIntoDays){
                        key = `${post.postDate.toDate().getMonth() + 1}/${post.postDate.toDate().getDate()}`;
                    }
                    else {
                        key = `${post.postDate.toDate().getMonth() + 1}/${post.postDate.toDate().getFullYear()}`
                    }
                    if (typeof allowedDates[key] != 'undefined'){ // it is in the allowed date range
                        allowedDates[key] += 1;
                    }
                })
            }
             // if it is set to all time, we want to start at the first data point that isn't zero...
             if( state.dateFilter.allTime ){
                for ( let k in allowedDates ){
                    if (allowedDates[k] === 0){
                        delete allowedDates[k];
                    }
                    else {
                        break;
                    }
                }
            }
            // console.log(allowedDates)
            return allowedDates;
        },
        getAllowedDatesObject(state){
            // console.log("start date - end date", diffInDays);
            // console.log("start date month", state.dateFilter.startDate.getMonth())
            // console.log("end date month", state.dateFilter.endDate.getMonth())
            let daysBetweenDates = {};
            if(state.dateFilter.breakIntoDays){
                // console.log("breaking into days...")
                const theDate = new Date(state.dateFilter.startDate);
                while(theDate <= state.dateFilter.endDate){
                    const d = new Date(theDate);
                    daysBetweenDates[`${d.getMonth() + 1}/${d.getDate()}`] = 0;
                    theDate.setDate(theDate.getDate() + 1);
                }
            }
            else {
                // console.log("breaking into months...")
                const theDate = new Date(state.dateFilter.startDate);
                const endDate = new Date(state.dateFilter.endDate);
                // console.log("start: ", theDate)
                // console.log("end:", endDate)
                // daysBetweenDates[`${theDate.getMonth() + 1}/${theDate.getFullYear()}`] = 0;
                while( theDate <= endDate || ( theDate.getMonth() <= endDate.getMonth() ) ){ // the second check just allows us to add the current month
                    // if( ( theDate.getMonth() <= endDate.getMonth() ) && ( theDate.getFullYear() <= endDate.getFullYear() ) ){
                    //     iterate = false;
                    // }
                    // console.log("1st", theDate <= endDate)
                    // console.log("2nd",  theDate.getMonth() <= endDate.getMonth() )
                    const d = new Date(theDate);
                    // console.log("adding to valid dates: ", `${d.getMonth() + 1}/${d.getFullYear()}`);
                    daysBetweenDates[`${d.getMonth() + 1}/${d.getFullYear()}`] = 0;
                    theDate.setMonth(theDate.getMonth() + 1);
                }
                // console.log("done with while loop")
            }
            return daysBetweenDates;
        },
        getPostPickupVsPushData(state) {
            if(state.randomData){
                return {
                    public: {
                        push: Math.floor(Math.random() * 100),
                        pickup: Math.floor(Math.random() * 100),
                    },
                    group: {
                        push: Math.floor(Math.random() * 100),
                        pickup: Math.floor(Math.random() * 100),
                    }
                }
            }
            
            return {
                group: {
                    pickup: state.postTotals.group.createMethod.pickup,
                    push: state.postTotals.group.createMethod.push,
                },
                public: {
                    pickup: state.postTotals.public.createMethod.pickup,
                    push: state.postTotals.public.createMethod.push,
                }
            }
        },
        getPostVideoVsPhotoData(state){
            let totals = {
                public: {
                    video: 0,
                    photo: 0,
                },
                group: {
                    video: 0,
                    photo: 0,
                },
            };
            if(state.randomData){
                totals = {
                    public: {
                        video: Math.floor(Math.random() * 100),
                        photo: Math.floor(Math.random() * 100),
                    },
                    group: {
                        video: Math.floor(Math.random() * 100),
                        photo: Math.floor(Math.random() * 100),
                    },
                };
                return {
                    labels: ["Video", "Photo"],
                    totals: totals,
                }
            }
            if( state.postAnalytics.publicData != null && state.postAnalytics.publicData.length != 0 ){
                for(let i = 0; i < state.postAnalytics.publicData.length; i++ ){
                    if(typeof state.postAnalytics.publicData[i].mediaType != 'undefined'){
                        if(state.postAnalytics.publicData[i].mediaType == 'video'){
                            totals.public.video += 1;
                        }
                        else if (state.postAnalytics.publicData[i].mediaType == 'photo'){
                            totals.public.photo += 1;
                        }

                    }
                }
            }
            if( state.postAnalytics.groupData != null && state.postAnalytics.groupData.length != 0 ){
                for(let i = 0; i < state.postAnalytics.groupData.length; i++ ){
                    if(typeof state.postAnalytics.groupData[i].mediaType != 'undefined'){
                        if(state.postAnalytics.groupData[i].mediaType == 'video'){
                            totals.group.video += 1;
                        }
                        else if (state.postAnalytics.groupData[i].mediaType == 'photo'){
                            totals.group.photo += 1;
                        }
                    }
                }
            }
            let labels = ["Video", "Photo"];
            return { 
                labels: labels,
                totals: totals,
            } 

        },
        getYoutubeVsFileData(state){
            let totals = {
                youtube: 0,
                file: 0,
            };
            if(state.randomData){
                totals = {
                    youtube: Math.floor(Math.random() * 100),
                    file: Math.floor(Math.random() * 100),
                };
                return {
                    labels: ["Youtube", "File"],
                    totals: totals,
                }
            }
            let labels = ["Youtube", "File"];
            totals.youtube = state.postTotals.group.videoType.youtube + state.postTotals.public.videoType.youtube;
            totals.file = state.postTotals.group.videoType.file + state.postTotals.public.videoType.file;
            return { 
                labels: labels,
                totals: totals,
            } 

        },
        getDesktopVsMobileData(state){
            let totals = {
                desktop: 0,
                mobile: 0,
            };
            if(state.randomData){
                totals = {
                    desktop: Math.floor(Math.random() * 100),
                    mobile: Math.floor(Math.random() * 100),
                };
                return {
                    labels: ["Desktop", "Mobile"],
                    totals: totals,
                }
            }
            let labels = ["Desktop", "Mobile"];
            totals.desktop = state.postTotals.group.deviceType.desktop + state.postTotals.public.deviceType.desktop;
            totals.mobile = state.postTotals.group.deviceType.mobile + state.postTotals.public.deviceType.mobile;
            return { 
                labels: labels,
                totals: totals,
            } 

        },
        getAverageContributionsPerPostTotal(state){
            if(state.randomData){
                return Math.floor(Math.random() * 100).toFixed(2)
            }
            let totalPosts = state.adminAnalytics.data.total_group_video_posts + state.adminAnalytics.data.total_public_video_posts + state.adminAnalytics.data.total_group_picture_posts + state.adminAnalytics.data.total_public_picture_posts;
            let totalContributions = state.adminAnalytics.data.total_contributions;
            return ( totalContributions / totalPosts ).toFixed(2);
        },
        getAverageContributionsPerPostByDate(state){
            if(state.randomData){
                return Math.floor(Math.random() * 10).toFixed(2)
            }
            let totalPosts = state.postTotals.public.total_posts + state.postTotals.group.total_posts;
            let totalContributions = state.postTotals.public.total_contributions + state.postTotals.group.total_contributions;
            return ( totalContributions / totalPosts ).toFixed(2);
        },
        getAveragePostTime(state){
            if(state.randomData){
                const time = totalTimeCalculator( Math.floor(Math.random() * 10000) )
                return {
                    d: time.d,
                    h: time.h,
                    m: time.m,
                    s: time.s.toFixed(1)
                }
            }
            const time = totalTimeCalculator( (state.postTotals.group.total_post_creation_time + state.postTotals.public.total_post_creation_time) / (state.postTotals.group.total_posts + state.postTotals.public.total_posts) ) ;
            return {
                d: time.d,
                h: time.h,
                m: time.m,
                s: time.s.toFixed(1)
            };
        },
        getAverageAudioTimePerPost(state){
            if(state.randomData){
                const time = totalTimeCalculator( Math.floor(Math.random() * 10000) )
                return {
                    d: time.d,
                    h: time.h,
                    m: time.m,
                    s: time.s.toFixed(1)
                }
            }
            if(state.adminAnalytics.data === null || state.adminAnalytics.isLoading){
                return{
                    d: 0,
                    h: 0,
                    m: 0,
                    s: 0
                }
            }
            const time = totalTimeCalculator( (state.postTotals.group.total_audio_time + state.postTotals.public.total_audio_time) / (state.adminAnalytics.data.total_group_video_posts + state.adminAnalytics.data.total_public_video_posts) );
            // total audio time for the posts / the total posts
            return {
                d: time.d,
                h: time.h,
                m: time.m,
                s: time.s.toFixed(1)
            };
        },
        getMobileDeviceData(state){
            let totals = {
                ios: 0,
                other: 0,
            };
            if(state.randomData){
                totals = {
                    ios: Math.floor(Math.random() * 100),
                    other: Math.floor(Math.random() * 100),
                };
                return {
                    labels: ["iOS", "Other"],
                    totals: totals,
                }
            }
            totals.ios = state.postTotals.group.deviceType.ios + state.postTotals.public.deviceType.ios;
            totals.other = state.postTotals.group.deviceType.other + state.postTotals.public.deviceType.other;
            return {
                labels: ["iOS", "Other"],
                totals: totals
            }
        },
        getAverageViewsPerPost(state){
            if(state.randomData){
                return Math.floor(Math.random() * 100).toFixed(2)
            }
            return ( ( state.postTotals.group.total_views + state.postTotals.group.total_views ) / (state.postTotals.group.total_posts + state.postTotals.public.total_posts) ).toFixed(2);
        },
        getPostsPerLanguage(state){
            if(state.randomData){
                return {
                    labels: ["English", "Spanish", "German"],
                    totals: [ Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)]
                }
            }
            let languages = {};
            for(let key in state.postTotals.public.languages){
                languages[key] = state.postTotals.public.languages[key];
            }
            for(let key in state.postTotals.group.languages){
                if(typeof languages[key] != 'undefined'){ // this languages was also in public
                    languages[key] += state.postTotals.group.languages[key]
                }
                else {
                    languages[key] = state.postTotals.group.languages[key]
                }
            }
            let sorted = Object.entries(languages).sort((a,b) => b[1] - a[1]);
            sorted = Object.fromEntries(sorted);
            return {
                labels: Object.keys(sorted),
                totals: Object.values(sorted)
            }
        },
        getTopViewedGroupPosts(state){
            if(state.randomData){
                return {
                    labels: "Group",
                    // labels: ["Post 1", "Post 2", "Post 3", "Post 4", "Post 5"],
                    totals: [ Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100) ],
                }
            }
            return {
                labels: "Group",
                totals: state.postTotals.group.top_viewed_posts,
            }
        },
        getTopViewedPublicPosts(state){
            if(state.randomData){
                return {
                    labels: "Public",
                    // labels: ["Post 1", "Post 2", "Post 3", "Post 4", "Post 5"],
                    totals: [ Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100) ],
                }
            }
            return {
                labels: "Public",
                totals: state.postTotals.public.top_viewed_posts,
            }
        }
       
    }
}

export default adminAnalyticsStore